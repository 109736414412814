interface IGeneralViewCircuit {
    id: string;
    identifier: string;
    capacidad: number;
    totalWatts: number;
    current: number;
    voltage: number;
    meter_id: string;
    meter_identifier: string;
    mounting_type: number;
    comment: string;
    location: LocationInterface
}

interface LocationInterface {
    latitude: number;
    longitude: number;
  }
  export class Location implements LocationInterface {
    latitude: number;
    longitude: number;

    constructor(attrs: any) {
        this.latitude = attrs.latitude;
        this.longitude = attrs.longitude;
    }
  }


export class GeneralViewCircuit implements IGeneralViewCircuit {

    id: string;
    identifier: string;
    capacidad: number;
    totalWatts: number;
    current: number;
    voltage: number;
    meter_id: string;
    meter_identifier: string;
    mounting_type: number;
    location: { latitude: number; longitude: number; };
    event: string;
    linkedSituation: string;
    marker: google.maps.Marker;
    isSelected: Boolean;
    comment: string;

    readonly markerIcons = {
      linked: {
        hover: 'assets/img/svg/ic_link_hover.svg',
        normal: 'assets/img/svg/link.svg',
        selected: 'assets/img/svg/lamppost_selected-0.svg',
        circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
      },
      single: {
        normal: 'assets/img/svg/circuit_normal.svg',
        selected: 'assets/img/svg/circuit_selected.svg',
        circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
      },
      assign: {
        selected: 'assets/img/svg/ic_install_selected-',
        circuitShowing: 'assets/img/svg/ic_install_linked-',
        normal: 'assets/img/svg/ic_install_pin-',
        hover: 'assets/img/svg/ic_install_hover-',
      }
    }

  constructor(attrs, linkedSituation: string = 'single', event: string = 'normal') {
    this.id = attrs.id;
    this.identifier = attrs.identifier;
    this.capacidad = attrs.capacidad;
    this.location = new Location(attrs.location);
    this.linkedSituation = linkedSituation;
    this.event = event;
    this.isSelected = false;
    this.totalWatts = attrs.totalWatts;
    this.current = attrs.current;
    this.voltage = attrs.voltage;
    this.meter_id = attrs.meter_id;
    this.meter_identifier = attrs.meter_identifier;
    this.mounting_type = attrs.mounting_type;
    this.comment = attrs.comment;
  }



  get_icon(): object {
    return  this.linkedSituation === 'assign'
    ?
      { url: `${this.markerIcons['assign'][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}`,
      scaledSize: new google.maps.Size(15, 20) }
    :
      {
        url: this.markerIcons[this.linkedSituation][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event],
        scaledSize: new google.maps.Size(15, 20)
      };
  }

  get_iconForSearch(): object {
    return  this.linkedSituation === 'assign'
    ?
      { url: `${this.markerIcons['assign'][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}`,
      scaledSize: new google.maps.Size(20, 25) }
    :
      {
        url: this.markerIcons[this.linkedSituation][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event],
        scaledSize: new google.maps.Size(20, 25)
      };
  }

  upadeIcon(event: string): void {
    this.event = event;
    this.marker.setIcon(this.get_icon());
  }

  paintMarker(map: any): void {
      const latitude = this.location.latitude;
      const longitude = this.location.longitude;
      this.event = this.isSelected ? 'selected' : 'normal';
      this.marker = new google.maps.Marker({
          title: this.identifier == null ? this.id : this.identifier.toString(),
          position: new google.maps.LatLng(latitude, longitude),
          icon: this.get_icon(),
          map: map
      });
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }

  setMap(map: any): void {
    this.marker.setMap(map);
  }
}



export enum ProgressEnum {
  'Censo rápido',
  'Censo infraestructura',
  'Instalación',
}

enum ProgressIconrEnum {
  '485_C',
  '190_C',
  '139_C',
}
