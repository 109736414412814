import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { GetProjectsResponse } from '@models/get-projects-response';
import { GetProjectResponse } from '@models/get-project-response';

@Injectable()

export class ProjectsModalService {
  constructor(private httpClient: HttpClient) {}

  getProjects(): Observable<GetProjectsResponse> {
    return this.httpClient.get<GetProjectsResponse>(
      `${environment.SERVER_URL}/projects`
    );
  }

  getProjectById(id:string): Observable<GetProjectResponse> {
    return this.httpClient.get<GetProjectResponse>(
      `${environment.SERVER_URL}/projects/${id}`
    );
  }
}