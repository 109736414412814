import { FormGroup } from '@angular/forms';
import { SurveyArea } from './survey-area';

export class NewQuickTaskPayload {
  type: number;
  sector: string;
  deadline: string;
  user_id: string;
  surveyAreas: SurveyArea[];
  formattedDeadline: string;

  constructor(newQuickTaskPayload: FormGroup) {
    this.type = newQuickTaskPayload.get('type').value;
    this.sector = newQuickTaskPayload.get('sector').value;
    this.formattedDeadline = newQuickTaskPayload.get('formattedDeadline').value;
    this.user_id = newQuickTaskPayload.get('user_id').value;
    this.surveyAreas =
      this.deserializeSurveyAreas(newQuickTaskPayload.get('survey_areas').value);
  }

  toJson(): Object {
    let json = {
      task: {
        type: this.type,
        sector: this.sector,
        deadline: this.formattedDeadline,
        user: null,
        survey_areas: this.surveyAreas
      }
    };

    if(this.user_id){
      json.task['user'] = {
        id: this.user_id
      }
    }

    return json;
  }

  private deserializeDeadline(deadline: any): string {
    return `${deadline.year}-${deadline.month}-${deadline.day}`;
  }

  private deserializeSurveyAreas(surveyAreas: any): SurveyArea[] {
    return surveyAreas.map(surveyArea => new SurveyArea(surveyArea));
  }
}
