import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SmartAlerts } from '@app/models/smart-alerts';
import { EditUserService } from '@app/pages/users/edit-user/edit-user.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EEXIST } from 'constants';
import { SmartAlertsService } from './smart-alerts.service';
import { IgetSectores, SendSector } from '../../../models/smart/sectors.model'

@Component({
  selector: 'app-smart-alerts',
  templateUrl: './smart-alerts.component.html',
  styleUrls: ['./smart-alerts.component.scss'],
  providers: [EditUserService]
})
export class SmartAlertsComponent implements OnInit, OnChanges {

  title = 'push-notification';
  message;

  @ViewChild('contentMaterialModal') materialModal: any;
  @ViewChild('copyModal') copyModal: any;
  alerts: SmartAlerts[];
  alertsSearchForm: FormGroup;
  alertForm: FormGroup;
  keyword = 'deviceName';
  solved: number;
  unsolved: number;
  selectSectores: IgetSectores[];
  sendSector: SendSector;
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toaster: Toastr,
    private alertsService: SmartAlertsService,
    // private messagingService: MessagingService
  ) {

    this.alertsSearchForm = fb.group({
      deviceName: [''],
      reference: [''],
      alertType: [''],
      orderBy: ['date'],
      alertStatus: [''],
      sector: ['']
    });
  }

  ngOnChanges(): void {
  }

  ngOnInit() {
    this.alertsService.getSectores().subscribe(resp => {
      this.selectSectores = resp;
    }, error => {
      console.log(error);
    })
    // this.alertsService.getAlertsList(this.alertsSearchForm.getRawValue()).subscribe(res  => {
    //   this.autoCompelete = res;
    //   console.log(this.alerts)
    //  })

    // this.messagingService.requestPermission()
    // this.messagingService.receiveMessage()
    // this.message = this.messagingService.currentMessage

    this.alertForm = this.fb.group({
      id: [null],
      comment: [null],
      solveDate: [null],
      userName: [null],
    })
    this.onSearch();
  }

  onEdit(alert) {
    console.log(alert)
    this.alertForm.patchValue({
      id: alert.id,
      comment: alert.comment,
      solveDate: alert.solveDate,
      userName: alert.userName,
    });
    // this.scheduleOffName = this.groupsForm.value.scheduleOffName;
    // console.log(this.scheduleOffName)

    this.modalService.open(this.materialModal, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  saveAlert() {

    const data = {
      id: this.alertForm.value.id,
      fixedAlert: true,
      comment: this.alertForm.value.comment,
    }

    this.alertsService.changeDeviceStatus(data).subscribe(response => {
      this.toaster.success('Se agrego correctamente');
    }, error => {
      this.toaster.error('Error al agregar la group');
    })
    this.onSearch();
    this.alertForm.reset();
    this.modalService.dismissAll();
  }

  onSearch() {
    const filtro = this.alertsSearchForm.getRawValue();
    filtro.sector = this.sendSector;


    this.alertsService.getAlertsList(filtro).subscribe(res => {
      this.alerts = res;
      this.solved = this.alerts.filter(x => x.alertStatus === true).length;
      this.unsolved = this.alerts.filter(x => x.alertStatus === false).length;

    })
  }

  onClose() {
    this.alertForm.reset();
    this.modalService.dismissAll();
  }

  prueba2(prueba, array: IgetSectores[]) {

    array.forEach(element => {
      element.listado.forEach(elemn => {
        if (elemn.id === prueba) {
          this.sendSector = {
            type: elemn.type,
            id: elemn.id
          }
        }

      })
    });

  }
}
