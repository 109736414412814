import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder, FormGroup, Validators
} from '@angular/forms';

import { AuthService } from '@shared/auth/auth.service';
import { LoginService } from '@pages/login/login.service';
import { Toastr } from '@shared/toastr/toastr.service';

import { SessionService } from './session.service';
import { LoginPayload } from './login-payload';
import { LoginResponse } from '@models/login-response';

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  providers: [SessionService]
})

export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  private forgotForm: FormGroup;

  constructor(
    private _login: LoginService,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private authService: AuthService,
    private toastr: Toastr,
    private modalService: NgbModal,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'body__login-background');
    this.loginForm = this.emptyLoginForm();
    this.forgotForm = this.emptyForgotForm();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'body__login-background');
  }

  // TODO: Show request errors
  login(): void {
    if (this.loginForm.valid) {
      const requestPayload = new LoginPayload(this.loginForm);

      this.sessionService
        .createSession(requestPayload)
        .subscribe(
          (loginResponse: LoginResponse) => {
            const jwt = loginResponse.token;
            this.authService.signinUser(jwt);
          },
          (error: any) => {
            this.toastr.error(error);
          }
        );
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  onSubmit({ value, invalid }: { value: { email: string }, invalid: boolean }) {
    if (invalid) { return; }
    this._login.forgotPassword(value.email).subscribe(response => {
      this.toastr.success('Correo enviado');
      this.modalService.dismissAll('forgotPasswordModal');
    }, (error) => {
      this.toastr.error(error);
    })
  }

  openForgotPasswordModal(content):void {
    this.modalService.open(content, { ariaLabelledBy: 'Modal Forgot Password' });
  }

  private emptyLoginForm(): FormGroup {
    return this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
  }

  private emptyForgotForm(): FormGroup {
    return this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }
}
