export interface ISmartAlerts {
    id: string,
    alert_type: string,
    deviceName: string,
    reference: string,
    alert_count: number,
    alertStatus: boolean,
    deviceID: string,
    solveDate: string,
    userName: string,
    comment: string,
}

export class SmartAlerts implements ISmartAlerts {

    id: string;
    alert_type: string;
    deviceName: string;
    reference: string;
    alert_count: number;
    alertStatus : boolean;
    deviceID : string;
    solveDate: string;
    userName: string;
    comment: string;

    constructor(attrs: any) {
        this.id = attrs.id;
        this.alert_type = attrs.alert_type;
        this.deviceName = attrs.deviceName;
        this.reference = attrs.reference;
        this.alert_count = attrs.alert_count;
        this.alertStatus = attrs.alertStatus;
        this.deviceID = attrs.deviceID;
        this.solveDate = attrs.solveDate;
        this.userName = attrs.userName;
        this.comment = attrs.comment;

    }
}



export class SmartAlertInfo {

    type: string;
    alert_type: string;
    deviceName: string;
    alarmDetailsList :SmartAlertListDetails[]

    constructor(attrs: any) {
        this.type = attrs.type;
        this.alert_type = attrs.alert_type;
        this.deviceName = attrs.deviceName;
        this.alarmDetailsList = attrs.alarmDetailsList != null ? attrs.alarmDetailsList.map(x=> new SmartAlertListDetails(x)) : [];
    }
}

export class SmartAlertListDetails {

    id: string;
    volts: number;
    watts: number;
    current1: number;
    current2: number;
    temperature: number;
    humidity: number;
    light: number;
    alertDate: string;

    constructor(attrs: any) {
        this.id = attrs.id;
        this.volts = attrs.volts;
        this.watts = attrs.watts;
        this.current1 = attrs.current1;
        this.current2 = attrs.current2;
        this.temperature = attrs.temperature;
        this.humidity = attrs.humidity;
        this.light = attrs.light;
        this.alertDate = attrs.alertDate;
    }
}