import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from '@pages/profile/profile.component';
import { UsersComponent } from '@pages/users/users.component';
import { NewUserComponent } from '@pages/users/new-user/new-user.component';
import { EditUserComponent } from '@pages/users/edit-user/edit-user.component';
import { TasksComponent } from '@pages/tasks/tasks.component';
import { TaskComponent } from '@pages/tasks/task/task.component';
import { NewQuickTaskComponent } from '@pages/tasks/new-quick-task/new-quick-task.component';
import { SelectProjectComponent } from '@pages/projects/select-project/select-project.component';
import { TroopsComponent } from '@pages/troops/troops.component';
import { NewTroopComponent } from '@pages/troops/new-troop/new-troop.component';
import { EditTroopComponent } from '@pages/troops/edit-troop/edit-troop.component';
import { NewInfrastructureTaskComponent } from '@pages/tasks/new-infrastructure-task/new-infrastructure-task.component';
import { EditCircuitComponent } from '@pages/circuits/edit-circuit/edit-circuit.component';
import { EditLuminaireComponent } from '@pages/luminaire/edit-luminaire/edit-luminaire.component';
import { GeneralViewComponent } from '@pages/general-view/general-view.component';
import { SurveyAreasComponent } from '@pages/survey-areas/survey-areas.component';
import { IncidentsComponent } from '@pages/call-center/incidents/incidents.component';
import { RepairTasksTableComponent } from '@pages/call-center/repair-tasks-table/repair-tasks-table.component';
import { AdminComponent } from '@pages/admin/admin.component';
import { ReportByUserComponent } from '@pages/call-center/report-by-user/report-by-user.component';
import { TicketsComponent } from '@pages/call-center/tickets/tickets.component';
import { EditTicketComponent } from '@pages/call-center/tickets/edit-ticket/edit-ticket.component';
import { ChargedValuesComponent } from '@pages/charged-values/charged-values.component';
import { CategoryComponent } from '@pages/charged-values/category/category.component';
import { InventoryComponent } from '@pages/inventory/inventory.component';
import { InventoryCategoryComponent } from '@pages/inventory/inventory-category/inventory-category.component';
import { CartComponent } from '@pages/inventory/cart/cart.component';
import { RequisitionsComponent } from '@pages/inventory/requisitions/requisitions.component';
import { EditRepairComponent } from '@pages/repairs/edit-repair/edit-repair.component';
import { RecoveredMaterialsComponent } from '@pages/inventory/recovered-materials/recovered-materials.component';
// tslint:disable-next-line:max-line-length
import { RecoveredMaterialCategoryComponent } from '@pages/inventory/recovered-materials/recovered-material-category/recovered-material-category.component';
import { VersionComponent } from '@pages/admin/versions/version/version.component';
import { AdministerPowerComponent } from '@pages/powers/administer-power/administer-power.component';
import { AssignPowerComponent } from '@pages/powers/assign-power/assign-power.component';
import { NewInstallationTaskComponent } from '@pages/tasks/new-installation-task/new-installation-task.component';
import { InstallationComponent } from '@pages/tasks/task/type/installation-survey/installation/installation.component';
import { LuminairesPendingInstallationComponent } from '@pages/luminaires-pending-installation/luminaires-pending-installation.component';
import { SummaryComponent } from '@pages/inventory/summary/summary.component';
import { KpiQuickTaskComponent } from '@pages/kpis/kpi-quick-task/kpi-quick-task.component';
import { KpiInfrastructureTaskComponent } from '@pages/kpis/kpi-infrastructure-task/kpi-infrastructure-task.component';
import { KpiInstallationTaskComponent } from '@pages/kpis/kpi-installation-task/kpi-installation-task.component';
import { TicketsStatsComponent } from '@app/pages/call-center/tickets-stats/tickets-stats.component';
import { RepairTasksPhaseComponent } from '@app/pages/call-center/repair-tasks-phase/repair-tasks-phase.component';
import { RepairsPhaseComponent } from '@app/pages/repairs-phase/repairs-phase.component';
import { LogMaintanenceComponent } from '@app/pages/reports/log-maintanence/log-maintanence.component';
import { CfeFilesImportComponent } from '@app/pages/energy/cfe-files-import/cfe-files-import.component';
import { UsersActiveTasksComponent } from '@app/pages/reports/users-active-tasks/users-active-tasks.component';
import { TarrifsComponent } from '@app/pages/energy/tarrifs/tarrifs.component';
import { UserEfficiencyComponent } from '@app/pages/reports/user-efficiency/user-efficiency.component';
import { ImportDetailComponent } from '@app/pages/energy/import-detail/import-detail.component';
import { RpusComponent } from '@app/pages/energy/rpus/rpus.component';
import { IndividualLogComponent } from '@app/pages/reports/individual-log/individual-log.component';
import { DashboardCfeComponent } from '@app/pages/energy/dashboard-cfe/dashboard-cfe.component';
import { RpuDetailComponent } from '@app/pages/energy/rpu-detail/rpu-detail.component';
import { AlertComponent } from '@app/pages/energy/alert/alert.component';
import { AlertsTypeComponent } from '@app/pages/energy/alerts-type/alerts-type.component';
import { InstallationsComponent } from '@app/pages/installations/installations.component';
import { MovementsLogComponent } from '@app/pages/inventory/movements-log/movements-log.component';
import { OperationDashboardComponent } from '@app/pages/reports/operation-dashboard/operation-dashboard.component';
import { IndividualDashboardComponent } from '@app/pages/reports/operation-dashboard/individual-dashboard/individual-dashboard.component';
import { TrackMaterialComponent } from '@app/pages/inventory/track-material/track-material.component';
import { GuarantyClaimsComponent } from '@app/pages/guaranties/guaranty_claims/guaranty-claims/guaranty-claims.component';
import { JudmentComponent } from '@app/pages/guaranties/judment_claims/judment/judment.component';
import { GuarantyluminaireComponent } from '@app/pages/opinion/guarantyphotocells/guarantyluminaire/guarantyluminaire.component';
import { GuarantyphotocellsComponent } from '@app/pages/opinion/guarantyphotocells/guarantyphotocells.component';

import { CallCenterDashboardComponent } from '@app/pages/call-center/call-center-dashboard/call-center-dashboard.component';
import { LuminaireStatusComponent } from '@app/pages/luminaire-status/luminaire-status.component';
import { ReportRpuComponent } from '@app/pages/reports/report-rpu/report-rpu.component';
import { SchedulesComponent } from '@app/pages/smart/schedules/schedules.component';
import { GroupsComponent } from '@app/pages/smart/groups/groups.component';
import { DevicesComponent } from '@app/pages/smart/devices/devices.component';
import { DeviceDetailsComponent } from '@app/pages/smart/devices/device-details/device-details.component';
import { VirtualCircuitsComponent } from '@app/pages/virtual-circuits/virtual-circuits.component';
import { LuminairesVirtualCircuitComponent } from '@app/pages/virtual-circuits/luminaires-virtual-circuit/luminaires-virtual-circuit.component';
import { AddCircuitComponent } from '@app/pages/virtual-circuits/add-circuit/add-circuit.component';
import { GroupDetailsComponent } from '@app/pages/smart/groups/group-details/group-details.component';
import { SmartAlertsComponent } from '@app/pages/smart/smart-alerts/smart-alerts.component';
import { SmartAlertsDetailsComponent } from '@app/pages/smart/smart-alerts/smart-alerts-details/smart-alerts-details.component';
import { SurveyAreaComponent } from '@app/pages/survey-area/survey-area.component';
import { SmartDashboardComponent } from '@app/pages/smart/smart-dashboard/smart-dashboard.component';
import { MarkerColorComponent } from '@app/pages/powers/marker-color/marker-color.component';
import { GuarantyReportComponent } from '@app/pages/guaranties/guaranty-report/guaranty-report.component';
import { DetailGuarantyReportComponent } from '@app/pages/guaranties/guaranty-report/detail-guaranty-report/detail-guaranty-report.component';
import { GuarantyOpinionComponent } from '@app/pages/guaranties/guaranty-opinion/guaranty-opinion.component';
import { AssignatedOpinionComponent } from '@app/pages/guaranties/guaranty-opinion/assignated-opinion/assignated-opinion.component';
import { SectoresComponent } from '@app/pages/sectores/sectores.component';
import { IncidentsLuminairesRoute4meComponent } from '@app/pages/call-center/incidents/incidents-luminaires-route4me/incidents-luminaires-route4me.component';
import { CensosComponent } from '@app/pages/censos/censos.component';
import { IncidentsLuminairesRoutesRoute4meComponent } from '@app/pages/call-center/incidents/incidents-luminaires-routes-route4me/incidents-luminaires-routes-route4me.component';
import { RepairTasksComponent } from '@app/pages/reports/repair-tasks/repair-tasks.component';
import { StatusComponent } from '@app/pages/status/status.component';
import { CategoryAlertsComponent } from '@app/pages/smart/category-alerts/category-alerts.component';
import { AlertsByTypeComponent } from '@app/pages/smart/alerts-by-type/alerts-by-type.component';
import { TableauDashboardViewComponent } from '@app/pages/tableau/dashboard/tableau-dashboard-view.component';
import { TableauDashboardIpadViewComponent } from '@app/pages/tableau/ipad/tableau-dashboard-ipad-view.component';

// Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'users',
    component: UsersComponent
  },
  {
    path: 'users/new-user',
    component: NewUserComponent
  },
  {
    path: 'users/:id',
    component: EditUserComponent
  },
  {
    path: 'tasks',
    component: TasksComponent
  },
  {
    path: 'tasks/new-quick-task',
    component: NewQuickTaskComponent
  },
  {
    path: 'tasks/new-infrastructure-task',
    component: NewInfrastructureTaskComponent
  },
  {
    path: 'tasks/new-infrastructure-task/:id',
    component: NewInfrastructureTaskComponent
  },
  {
    path: 'tasks/new-installation-task',
    component: NewInstallationTaskComponent
  },
  {
    path: 'tasks/:id/:type',
    component: TaskComponent
  },
  {
    path: 'select-project',
    component: SelectProjectComponent
  },
  {
    path: 'troops',
    component: TroopsComponent
  },
  {
    path: 'troops/new-troop',
    component: NewTroopComponent
  },
  {
    path: 'guaranties/guaranty-opinion/opinion/luminaires/:id',
    component: GuarantyluminaireComponent
  },
  {
    path: 'guaranties/guaranty-opinion/opinion/photocells/:id',
    component: GuarantyphotocellsComponent

  },
  {
    path: 'troops/:id',
    component: EditTroopComponent
  },
  {
    path: 'circuits/:id',
    component: EditCircuitComponent
  },
  {
    path: 'luminaires/:id',
    component: EditLuminaireComponent
  },
  {
    path: 'general-view',
    component: GeneralViewComponent
  },
  {
    path: 'survey-areas',
    component: SurveyAreasComponent
  },
  {
    path: 'areas/supermanzanas',
    component: SurveyAreaComponent
  },
  {
    path: 'areas/sectores',
    component: SectoresComponent
  },
  {
    path: 'call-center/incidents',
    component: IncidentsComponent
  },
  {
    path: 'call-center/incidents/incidents-luminaires-route4me',
    component: IncidentsLuminairesRoute4meComponent
  },
  {
    path: 'call-center/incidents/incidents-luminaires-routes-route4me',
    component: IncidentsLuminairesRoutesRoute4meComponent
  },
  {
    path: 'call-center/repair-tasks',
    component: RepairTasksTableComponent
  },
  {
    path: 'call-center/repair-task',
    component: RepairTasksPhaseComponent
  },
  {
    path: 'call-center/repair-tasks/:id',
    component: RepairTasksPhaseComponent
  },
  {
    path: 'call-center/report-luminaire',
    component: ReportByUserComponent
  },
  {
    path: 'call-center/tickets',
    component: TicketsComponent
  },
  {
    path: 'call-center/tickets/:id',
    component: EditTicketComponent
  },
  {
    path: 'call-center/dashboard',
    component: CallCenterDashboardComponent
  },
  {
    path: 'reports/tickets-stats',
    component: TicketsStatsComponent
  },
  {
    path: 'admin',
    component: AdminComponent
  },
  {
    path: 'loaded-values',
    component: ChargedValuesComponent
  },
  {
    path: 'loaded-values/category/:id',
    component: CategoryComponent
  },
  {
    path: 'inventory/category',
    component: InventoryComponent
  },
  {
    path: 'inventory/category/:id',
    component: InventoryCategoryComponent
  },
  {
    path: 'inventory/cart',
    component: CartComponent
  },
  {
    path: 'inventory/requisitions',
    component: RequisitionsComponent
  },
  {
    path: 'inventory/logs',
    component: MovementsLogComponent
  },
  {
    path: 'repairs/:id',
    component: EditRepairComponent
  },
  {
    path: 'repair/:id',
    component: RepairsPhaseComponent
  },
  {
    path: 'inventory/summary',
    component: SummaryComponent
  },
  {
    path: 'inventory/recovered-materials',
    component: RecoveredMaterialsComponent
  },
  {
    path: 'inventory/recovered-materials/category/:id',
    component: RecoveredMaterialCategoryComponent
  },
  {
    path: 'inventory/track-matrial',
    component: TrackMaterialComponent
  },
  {
    path: 'admin/new',
    component: VersionComponent
  },
  {
    path: 'admin/:id',
    component: VersionComponent
  },
  {
    path: 'power/administration',
    component: AdministerPowerComponent
  },
  {
    path: 'power/assignment',
    component: AssignPowerComponent
  },
  {
    path: 'power/marker-color',
    component: MarkerColorComponent
  },
  {
    path: 'installation/pending',
    component: LuminairesPendingInstallationComponent
  },
  {
    path: 'installations',
    component: InstallationsComponent
  },
  {
    path: 'installation/:id',
    component: InstallationComponent
  },
  {
    path: 'kpis/kpi-quick-task',
    component: KpiQuickTaskComponent
  },
  {
    path: 'kpis/kpi-infrastructure-task',
    component: KpiInfrastructureTaskComponent
  },
  {
    path: 'kpis/kpi-installation-task',
    component: KpiInstallationTaskComponent
  },
  {
    path: 'reports/log-maintenance',
    component: LogMaintanenceComponent
  },
  {
    path: 'reports/individual-log',
    component: IndividualLogComponent
  },
  {
    path: 'reports/oficial-active-tasks',
    component: UsersActiveTasksComponent
  },
  {
    path: 'reports/tasks-repairs',
    component: RepairTasksComponent
  },
  {
    path: 'reports/oficial-efficienty',
    component: UserEfficiencyComponent
  },
  {
    path: 'reports/operational-dashboard',
    component: OperationDashboardComponent
  },
  {
    path: 'energy/cfe-import-files',
    component: CfeFilesImportComponent
  },
  {
    path: 'energy/cfe-tariffs',
    component: TarrifsComponent
  },
  {
    path: 'energy/import-detail/:id',
    component: ImportDetailComponent
  },
  {
    path: 'energy/rpus',
    component: RpusComponent
  },
  {
    path: 'energy/dashboard-cfe',
    component: DashboardCfeComponent
  },
  {
    path: 'energy/rpu-detail/:rpu',
    component: RpuDetailComponent
  },
  {
    path: 'energy/alerts',
    component: AlertComponent
  },
  {
    path: 'energy/alerts-type',
    component: AlertsTypeComponent
  },
  {
    path: 'reports/operation-dashboard/indiviual-dashboard/:id',
    component: IndividualDashboardComponent
  },
  {
    path: 'guaranties/guaranty_claims',
    component: GuarantyClaimsComponent
  },
  {
    path: 'guaranties/guaranty-report',
    component: GuarantyReportComponent
  },
  {
    path: 'guaranties/guaranty-report/detail-guaranty-report/:id',
    component: DetailGuarantyReportComponent
  },
  {
    path: 'guaranties/guaranty-opinion',
    component: GuarantyOpinionComponent
  },
  {
    path: 'guaranties/guaranty-opinion/assignated-opinion/:id',
    component: AssignatedOpinionComponent
  },
  {
    path: 'guaranties/judment_claims',
    component: JudmentComponent
  },
  {
    path: 'virtual-circuits',
    component: VirtualCircuitsComponent
  },
  {
    path: 'virtual-circuits/luminaires-virtual-circuit/:id',
    component: LuminairesVirtualCircuitComponent
  },
  {
    path: 'virtual-circuits/add-circuit',
    component: AddCircuitComponent
  },
  {
    path: 'luminaire-status',
    component: LuminaireStatusComponent
  },
  {
    path: 'reports/report-rpu',
    component: ReportRpuComponent
  },
  {
    path: 'smart/schedules',
    component: SchedulesComponent
  },
  {
    path: 'smart/groups',
    component: GroupsComponent
  },
  {
    path: 'smart/devices',
    component: DevicesComponent
  },
  {
    path: 'smart/devices/device-details/:id',
    component: DeviceDetailsComponent
  },
  {
    path: 'smart/groups/group-details/:id',
    component: GroupDetailsComponent
  },
  {
    path: 'smart/alerts',
    component: SmartAlertsComponent 
  },
  {
    path: 'smart/alerts/alert-details/:id',
    component: SmartAlertsDetailsComponent
  },
  {
    path: 'smart/dashboard',
    component: SmartDashboardComponent
  },
  {
    path: 'smart/category-alerts',
    component: CategoryAlertsComponent
  },
  {
    path: 'smart/alerts/:type',
    component: AlertsByTypeComponent
  },
  {
    path: 'censos',
    component: CensosComponent
  },
  {
    path: 'status',
    component: StatusComponent
  },
  {
    path: 'tableau-dashboard',
    component: TableauDashboardViewComponent
  },
  {
    path: 'tableau-dashboard-ipad-view',
    component: TableauDashboardIpadViewComponent
  }
];
