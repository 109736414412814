import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { Project } from '@models/project';

import { EditProjectPayload } from './edit-project-payload';
import { EditProjectResponse } from '@models/edit-project-response';

@Injectable()

export class EditProjectModalService {
  constructor(private httpClient: HttpClient) {}

  editProject(project: Project, projectPayload: EditProjectPayload): Observable<EditProjectResponse> {
    return this.httpClient.put<EditProjectResponse>(
      `${environment.SERVER_URL}/projects/${project.id}`,
      projectPayload.toJson()
    );
  }
}