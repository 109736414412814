import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DetailSurveyArea, SingleSurveyArea } from './survey-area';

@Injectable({
  providedIn: 'root'
})
export class SurveyAreaService {

  constructor(private httpClient: HttpClient) { }

  getSurveyAreas(survey_area_name) {
    const httpParams = new HttpParams()
      .set('survey_area_name', survey_area_name);
    return this.httpClient.get<SingleSurveyArea[]>(
      `${environment.SERVER_TASKS}/api/SurveyArea/get-list-survey-areas`, { params: httpParams })
      .pipe(map(res => res.map(mp => new SingleSurveyArea(mp))));
  }

  updateSurveyArea(update) {
    console.log(update, 'send values of the service');
    return this.httpClient.post(
      `${environment.SERVER_TASKS}/api/SurveyArea/update-survey-areas`, update);
  }

  deletSurveyArea(surveyarea_id) {
    const parametter = new HttpParams()
      .set('survey_area_id', surveyarea_id);
    return this.httpClient.delete(`${environment.SERVER_TASKS}/api/SurveyArea/delete-survey-areas`, { params: parametter });
  }

  createSurveyArea(insert) {
    return this.httpClient.post<string>(
      `${environment.SERVER_TASKS}/api/SurveyArea/register-survey-areas`, insert);
  }

  exportXLSSurveyAreaDetails(survey_area_id) {
    const httpParams = new HttpParams()
      .set('survey_area_id', survey_area_id);

    this.httpClient.get(
      // tslint:disable-next-line:max-line-length
      `${environment.SERVER_TASKS}/api/SurveyArea/get-xls-survey-areas`, { responseType: 'blob', params: httpParams }
    ).subscribe(file => {
      this.downloadProcess(file, `Detalle del Polígono.XLSX`);
    });
  }


  exportXLSALlLuminairesDetails() {
    this.httpClient.get(
      // tslint:disable-next-line:max-line-length
      `${environment.SERVER_TASKS}/api/SurveyArea/get-xls-luminaires`, { responseType: 'blob' }
    ).subscribe(file => {
      this.downloadProcess(file, `Detalle del Luminaires.XLSX`);
    });
  }

  updateNameSurveyArea(value) {
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/SurveyArea/update-name-survey-areas`, value);
  }


  detailSurveyArea(surveyarea_id) {
    const parametter = new HttpParams()
      .set('survey_area_id', surveyarea_id);
    return this.httpClient.get<DetailSurveyArea>(
      `${environment.SERVER_TASKS}/api/SurveyArea/get-luminaires-survey-areas`, { params: parametter })
      .pipe(map(element => new DetailSurveyArea(element)));
  }


  private downloadProcess(file, fileName) {
    const newBlob = new Blob([file], { type: 'application/vnd.ms-excel' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }


}
