import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CurrentAndUsedMaterials, OficialDashboard, OperationDashboard,
  OperationOficialsDashboard, TaskMarkers, TaskMaterial
} from '@app/models/operation-dashboard';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { GenericChart } from '@app/models/generic-chart';
import { FormGroup } from '@angular/forms';
import { FusionChartModel } from '@app/models/FusionChartModel';

@Injectable({
  providedIn: 'root'
})
export class OperationDasboardService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getGeneralOperation() {
    return this.httpClient.get<OperationDashboard>(`${environment.SERVER_TASKS}/api/OperationReports/GetGeneralOperation`)
      .pipe(map(dashboard => new OperationDashboard(dashboard)));
  }

  getGeneralOficialOperation() {
    return this.httpClient.get<OperationOficialsDashboard[]>(`${environment.SERVER_TASKS}/api/OperationReports/GetGeneralOficialsOperation`)
      .pipe(map(oficials => oficials.map(oficial => new OperationOficialsDashboard(oficial))));
  }

  getDetailsOfiicer_Charts() {
    const params = new HttpParams();
    return this.httpClient.get<FusionChartModel>(`${environment.SERVER_TASKS}/api/OperationReports/get-values-graph-operation-dashboard`)
    .pipe(map(response => new FusionChartModel(response)));
  }


  getOficialDashboard(user_id, startDate) {
    const httpParams = new HttpParams().set('user_id', user_id)
    // .set('turno', turno)
    .set('startDate', startDate);

    //   .set('dateEnd', search.value.endDate === null ? '' : search.value.endDate);
    // if (search.value.startDate !== null) {
    //   httpParams.set('dateStart', search.value.startDate)
    // }

    // if (search.value.startDate !== null) {
    //   httpParams.set('dateStart', search.value.startDate)
    // }

    return this.httpClient.get<OficialDashboard>(
      `${environment.SERVER_TASKS}/api/OperationReports/GetOficialDashboard`, { params: httpParams }
    ).pipe(map(oficial => new OficialDashboard(oficial)));
  }

  getTaskMarkers(task_id) {
    const httpParams = new HttpParams().set('task_id', task_id);
    return this.httpClient.get<{ taskMarker: TaskMarkers[], currentMaterial: TaskMaterial[] }>(
      `${environment.SERVER_TASKS}/api/OperationReports/GetTaskMarkers`, { params: httpParams });
  }

  GetCurrentAndUsedMaterials(user_id) {
    const httpParams = new HttpParams().set('user_id', user_id);
    return this.httpClient.get<CurrentAndUsedMaterials>(
      `${environment.SERVER_TASKS}/api/OperationReports/GetCurrentAndUsedMaterials`, { params: httpParams }
    ).pipe(
      map(rm => new CurrentAndUsedMaterials(rm)
      ))
  }

  getAllMarkers(user_id) {
    const httpParams = new HttpParams().set('user_id', user_id);
    return this.httpClient.get<TaskMarkers[]>(
      `${environment.SERVER_TASKS}/api/OperationReports/GetAllMarkers`, { params: httpParams }
    );
  }

  saveTurnOfficial(user_id, turno) {
    const httpParams = new HttpParams().set('user_id', user_id).set('turno', turno);

    return this.httpClient.get<OficialDashboard>(
      `${environment.SERVER_TASKS}/api/OperationReports/SaveTurnIntoOfficial`, { params: httpParams }
    )
  }
}
