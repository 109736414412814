export enum ContactorsCurrentCapacityEnum {
    '  9',
    '  10',
    '  12',
    '  18',
    '  20',
    '  25',
    '  27',
    '  30',
    '  32',
    '  38',
    '  40',
    '  45',
    '  50',
    '  60',
    '  65',
    '  70',
    '  75',
    '  80',
    '  90',
    '  95',
    '  100',
    '  115',
    '  120',
    '  125',
    '  135',
    '  150',
    '  160',
    '  200',
    '  250',
    '  270',
    '  300'
}