import { FormGroup } from '@angular/forms';

interface LoginPayloadInterface {
  email: string;
  password: string;
}

export class LoginPayload implements LoginPayloadInterface {
  email: string;
  password: string;

  constructor(loginForm: FormGroup) {
    this.email = loginForm.get('email').value;
    this.password = loginForm.get('password').value;
  }

  toJson(): Object {
    return { session: { email: this.email, password: this.password } };
  }
}