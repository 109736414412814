import { Item } from "./item";
import { Inventory } from "./inventory";

interface TroopInterface {
  id: string
  number: number
  plates: string
  ipad_serial_number: string
  inventory: Inventory;
}

export class Troop implements TroopInterface {
  id: string
  number: number
  plates: string
  ipad_serial_number: string
  inventory: Inventory

  constructor(attrs) {
    this.id = attrs.id;
    this.number = attrs.number;
    this.plates = attrs.plates;
    this.ipad_serial_number = attrs.ipad_serial_number;
    this.inventory = attrs.inventory != null ? new Inventory(attrs.inventory) : null;
  }
}