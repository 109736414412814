import { Component, OnInit, ViewChild } from '@angular/core';
import { ChargedValuesService } from './charged-values.service';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { MaterialCategory } from 'app/models/material_category';
import { MaterialCategoriesResponse } from 'app/models/material_categories-response';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialPayload } from '@models/material-payload';
import { TechnologyEnum } from '@models/technology-enum';
import { LuminaireEnum } from '@models/luminaire-enum';
import { GenericTable } from '@app/models/generic-table';
import { ProjectWattage } from '@app/models/project_wattage';
import { Material_Categorys } from './material_categorys';
import { ProjectMaterialPayload } from '@app/models/project_material-payload';
import { ProjectMaterial } from '@app/models/project_material';
import { Material_Project } from './material_project';
import { Material } from '@app/models/material';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
  selector: 'app-charged-values',
  templateUrl: './charged-values.component.html',
  providers: [ChargedValuesService]
})
export class ChargedValuesComponent implements OnInit {
  userRoles: number[];
  material_categories: MaterialCategory[] = [];
  MaterialsSearchForm: FormGroup;
  pagination: GenericTable<Material> = new GenericTable<Material>({});
  categorys: Material_Categorys[] = [];
  technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));
  luminariaTypeEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  @ViewChild('contentMaterialModal') materialModal: any;
  currentId: string;
  project_materials: Material[] = [];
  materialForm: FormGroup;
  emptyMaterial: Material;
  projectName = 'Material';
  isLuminaire = false;

  constructor(
    private chargedValuesService: ChargedValuesService,
    private authService: AuthService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastr: Toastr
  ) {
    this.MaterialsSearchForm = fb.group({
      id: "",
    });

    this.materialForm = this.fb.group(
      {
        id: [null],
        name: [null, Validators.required],
        minimum: [null, Validators.required],
        image_url: [null],
        is_active: [null],
        image: [null],
        stock: [null],
        type: [null],
        technology: [null],
        material_code: [null],
        cost: [null]
      }
    );

    this.emptyMaterial = new Material({ material: {}, is_active: true });

  }

  ngOnInit() {
    this.getUserRole();
    this.chargedValuesService.getMaterialsCategorys().subscribe(
      response => { this.categorys = response; }
    )
    // this.getMaterialsProject(0);
  }

  pageChange(event) {
    this.getMaterialsProject(event - 1)
  }

  getUserRole() {
    const helper = new JwtHelperService();
    let token = this.authService.getToken();
    let user = helper.decodeToken(token);
    this.userRoles = user['role_name_enums'];    
  }

  getMaterialsProject(page) {
    this.chargedValuesService.getMaterialsProject(this.MaterialsSearchForm.getRawValue(), page)
      .subscribe(response => {
        this.pagination = response;
        this.project_materials = this.pagination.elements;
        console.log(this.pagination)
      }, error => {
        this.toastr.error(error);
      })
  }

  showMaterialModal(projectMaterial: Material) {
    this.isLuminaire = false;
    this.setForm(projectMaterial);
    if (projectMaterial.material_attributes) {
      this.isLuminaire = true
    }
    this.modalService.open(this.materialModal, { size: 'lg' }).result.then(result => {
    }, reason => { });
  }

  createMaterial() {
    if (this.materialForm.valid && this.materialForm.get('minimum').value >= 0) {

      if (this.MaterialsSearchForm.get('id').value == null) {
        return this.toastr.singleError('Elija una categoria.');

      }
      const requestPayload = new MaterialPayload(this.materialForm);
      if (!this.validateLuminaire()) {
        return this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
      }

      this.chargedValuesService.createMaterial(requestPayload, this.MaterialsSearchForm.get('id').value)
        .subscribe(result => {
          window.location.reload();
          this.toastr.success('Material creado.');
        }, error => {
          this.toastr.error(error);
        })
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  private validateLuminaire() {
    let flag = true;
    if (this.projectName.toLocaleLowerCase() != 'luminarias' && this.projectName.toLocaleLowerCase() != 'lámparas y balastros para tecnología hid')
      return flag;

    let type = this.materialForm.get('type').value;
    let technology = this.materialForm.get('technology').value;
    if (type < 0 || type == undefined || technology < 0 || technology == undefined) {
      flag = false;
      this.materialForm.controls.type.setErrors([Validators.required]);
      this.materialForm.controls.technology.setErrors([Validators.required]);
      this.materialForm.controls.type.markAsTouched();
      this.materialForm.controls.technology.markAsTouched();
    }

    return flag;
  }

  updateMaterial() {
    if (this.materialForm.valid && this.materialForm.get('minimum').value >= 0) {
      const requestPayload = new ProjectMaterialPayload(this.materialForm);
      console.log(this.materialForm.value); // Log form value
      console.log(requestPayload); // Log request payload
      if (!this.validateLuminaire())
        return this.toastr.singleError('Operación fallida. Revisar campos requeridos.');

        let body = {
          project_material: {
            image: this.materialForm.get('image').value,
            stock: this.materialForm.get('stock').value,
            troop_minimum: this.materialForm.get('minimum').value,
            is_active: this.materialForm.get('is_active').value,
            material: {
              name: this.materialForm.get('name').value,              
              material_code: this.materialForm.get('material_code').value,
              cost: this.materialForm.get('cost').value
            },
            material_attributes: {
              luminaire_type: this.materialForm.get('type').value,
              technology: this.materialForm.get('technology').value
            }
          }
        };
      this.chargedValuesService.updateMaterial(body, requestPayload.id)
        .subscribe(result => {          
          this.toastr.success('Material actualizado.');          
          let _material = this.project_materials.find(mat__ => mat__.id == requestPayload.id);
          if (_material) {
            _material.name = requestPayload.name;
            _material.minimum = requestPayload.minimum;
            _material.is_active = requestPayload.is_active;
            _material.cost = requestPayload.cost;
            _material.material_code = requestPayload.material_code;          
            _material.image_url = this.materialForm.get('image_url').value;
            let material_attributes = {
              luminaire_type: this.materialForm.get('type').value,
              technology: this.materialForm.get('technology').value
            }
            _material.material_attributes = material_attributes;
            _material.is_active = requestPayload.is_active;
          } else {
            console.error('No material found with id:', this.currentId);
          }          
          this.modalService.dismissAll();
        }, error => {
          this.toastr.error(error);
        })
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  setForm(projectMaterial: Material) {    
    this.materialForm.patchValue({
      id: projectMaterial.id,
      name: projectMaterial.name,
      minimum: projectMaterial.minimum,
      is_active: projectMaterial.is_active,
      image_url: projectMaterial.image_url,
      type: projectMaterial.material_attributes ? projectMaterial.material_attributes.luminaire_type : null,
      technology: projectMaterial.material_attributes ? projectMaterial.material_attributes.technology : null,
      material_code: projectMaterial.material_code,
      cost: projectMaterial.cost
    });
  }

  setImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.materialForm.patchValue({ image_url: reader.result })
        this.materialForm.patchValue({ image: file })
      };

      reader.readAsDataURL(file);
    }
  }

  onChange(event: boolean, projectMaterial: Material) {
    projectMaterial.is_active = event;
    this.setForm(projectMaterial);
    this.updateMaterial();
  }

}
