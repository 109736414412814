import { Item } from "./item";
import { User } from "./user";

interface RequisitionInterface {
    id: string,
    items: Item[]
    receiver: User
    sender: User
    created_at: string
    updated_at: string
    status: number
    destination: number
}

export class Requisition implements RequisitionInterface {
    id: string;
    items: Item[];
    receiver: User;
    sender: User;
    created_at: string;
    updated_at: string;
    isCollapsed: boolean;
    status: number;
    statusName: string;
    destination: number;
    destinationName: string;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.items = attrs.items != null ? attrs.items.map( item => new Item(item)) : [];
    this.receiver = new User(attrs.receiver);
    this.sender = attrs.sender != null ? new User(attrs.sender) : null;
    this.created_at = attrs.created_at;
    this.updated_at = attrs.updated_at;
    this.isCollapsed = true;
    this.status = attrs.status;
    this.statusName = attrs.status != null ? statusEnum[attrs.status] : '';
    this.destination = attrs.destination;
    this.destinationName = attrs.destination != null ? destinationEnum[attrs.destination] : '';
  }
}

export interface GetRequisitionsResponse {
    requisitions: Requisition[]
}

export interface GetRequisitionResponse {
    requisition: Requisition
}

enum statusEnum {
    'Pendiente',
    'Aceptada',
    'Rechazada',
    'Cancelada'
}
enum destinationEnum {
    'Inventario',
    'Material recuperado'
}