import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-tableau-dashboard-view',  
  templateUrl: './tableau-dashboard-view.component.html',
  styleUrls: ['./tableau-dashboard-view.component.scss']
})
export class TableauDashboardViewComponent implements OnInit {
  selectedDashboardUrl: string;

  private dashboardUrls = {
    day: 'https://us-east-1.online.tableau.com/t/optimaenergia/views/DashboardMunicipioCancun/TicketDashboard3',
    evening: 'https://us-east-1.online.tableau.com/#/site/optimaenergia/views/DashboardMunicipioDesktopAYER/TicketDashboard3?:iid=1'
  }
  constructor() { }

  ngOnInit() {
    this.selectedDashboardUrl = this.setSelectedDashboardUrl();
  }

  private setSelectedDashboardUrl() {
    const cancunTimezone = 'America/Cancun';
    const currentHour = moment().tz(cancunTimezone).hour();
    
    return this.selectedDashboardUrl = currentHour >= 18 ? this.dashboardUrls.evening : this.dashboardUrls.day;
  }
}