import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { NewTroopResponse } from '@models/new-troop-response';
import { NewTroopPayload } from '@models/new-troop-payload';

@Injectable()

export class NewTroopService {
  constructor(private httpClient: HttpClient) {}

 /*solo falta agregar la ruta de registro de troop, el servicio ya esta construido*/
  registerTroop(insert) {
    return this.httpClient.post<NewTroopResponse>(
      `${environment.SERVER_URL}/troops`,
      insert
    );
  }

  createTroop(troopPayload: NewTroopPayload): Observable<NewTroopResponse> {
    return this.httpClient.post<NewTroopResponse>(
      `${environment.SERVER_URL}/troops`,
      troopPayload.toJson()
    );
  }
}
