import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Task } from 'app/models/task';
import { Pagination } from 'app/models/pagination';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: "siNo" })
export class SiNoPipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? 'Sí' : 'No';
  }
}

@Component({
  selector: 'app-tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: ['./tasks-table.component.scss']
})
export class TasksTableComponent {
  @Input() tasks: Array<Task>;
  @Input() pagination: Pagination;
  @Input() page: number;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  changeCurrentPage(page) {
    this.pageChange.emit(page);
  }

}
