import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '@app/shared/auth/auth.service';
import { GenericTable } from '@app/models/generic-table';
import { DetailRpusImport, IImportsDetail, ImportsDetail, RpusImportDetail } from '@app/models/import-detail';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { PLATFORM_WORKER_UI_ID } from '@angular/common/src/platform_id';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ImportDetailService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  getList_RpusImportDetails(import_id, page) {
    const httpParams = new HttpParams()
      .set('import_id', import_id)
      .set('page', page);
    return this.httpClient.get<GenericTable<DetailRpusImport>>(
      `${environment.SERVER_ENERGY}/api/ImportRPU/GetList_RpusImportDetail`, { params: httpParams }
    ).pipe(
      map(elements => new GenericTable<DetailRpusImport>(elements))
    );

  }

  getFilter_RpusImportDetails(import_id, rpu, month, year, is_imported, page) {

    const httpParams = new HttpParams()
      .set('import_id', import_id)
      .set('rpu', rpu)
      .set('month', month)
      .set('year', year)
      .set('is_imported', is_imported)
      .set('page', page);
    return this.httpClient.get<GenericTable<DetailRpusImport>>(
      `${environment.SERVER_ENERGY}/api/ImportRPU/GetFilter_RpusImportDetail`, { params: httpParams }
    ).pipe(
      map(elements => new GenericTable<DetailRpusImport>(elements))
    );

  }

  downloadXLS(import_id, rpu, month, year, is_imported) {
    const httpParams = new HttpParams()
    .set('import_id', import_id)
    .set('rpu', rpu)
    .set('month', month)
    .set('year', year)
    .set('is_imported', is_imported);

    this.httpClient.get(
      `${environment.SERVER_ENERGY}/api/ExportRPUSXLS/Download_Excel_RPUS`, { responseType: 'blob', params: httpParams }
    ).subscribe(file => {
      this.downloadProcess(file, `CFE.XLSX`);
    })
  }

  private downloadProcess(file, fileName) {
    const newBlob = new Blob([file], { type: 'application/vnd.ms-excel' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {

      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

}
