import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetDevices } from '@app/models/devices';
import { Group, GroupDevices } from '@app/models/groups';
import { StyleMap } from '@app/models/styleMap';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { DevicesService } from '../../devices/devices.service';
import { GroupsService } from '../groups.service';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
  providers: [MapFuncAuxService, CurrentProjectService],
})
export class GroupDetailsComponent implements OnInit {

  groupID: string;
  group: Group;
  @ViewChild("gmap") gmapElement: any;
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  lstInfowindows = [];
  SchedulesList: [];
  keyword = 'scheduleName';

  formGroup: FormGroup;

  constructor(private activatedRoute: ActivatedRoute,
    private groupService: GroupsService,
    private mapFuncAux: MapFuncAuxService,
    private currentProjectService: CurrentProjectService,
    private devicesService: DevicesService,
    private toaster: Toastr,
    private router: Router,
    private fb:  FormBuilder
  ) {
    this.formGroup = this.fb.group({
      id: [null, Validators.required],
      name: [null, Validators.required],
      schedule_id: [null],
      description: [null]
    });
  }

  ngOnInit() {
    this.groupID = this.activatedRoute.snapshot.paramMap.get('id');
    this.groupService.getGroup(this.groupID).subscribe((res: Group) => {
      this.group = new Group(res);
      console.log(this.group)
      this.paintCircuits(this.group.devices);
      this.formGroup.patchValue({
        id: res.id,
        name: res.name,
        schedule_id: !res.schedule_id ? "" : res.schedule_id,
        description: res.description
      })
    })

    this.groupService.getSchedulesList().subscribe(res  => {
      this.SchedulesList = res;
     })



    this.paintMap();
  }




  private paintMap() {
    const project = this.currentProjectService.getCurrentProject();
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ["name", "geometry"],
    };
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    this.map = new google.maps.Map(
      this.gmapElement.nativeElement,
      mapProperties
    );
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    });
    this.map.addListener("click", () => {
      if (this.group.devices.find(x => x.isSelected == true)) {
        let selected = this.group.devices.find(x => x.isSelected == true)
        selected.isSelected = false;
        selected.marker.setAnimation(null);
      }
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    });
  }
  paintCircuits(circuit) {
    circuit.forEach(element => {
      element.paintMarker(this.map);
      element.marker.addListener("click", () => {
        this.showInfoMarker(element);
      });
    });
  }

  circuitClick(item: GroupDevices) {
    if (this.group.devices.find(x => x.isSelected == true)) {
      let selected = this.group.devices.find(x => x.isSelected == true)
      selected.isSelected = false;
      selected.marker.setAnimation(null);
    }
    item.isSelected = true;
    item.marker.setAnimation(google.maps.Animation.BOUNCE);

  }

  onEditGroup() {
    if (this.formGroup.valid) {
      this.groupService.updateGroup(this.formGroup.value).subscribe(response => {
        this.toaster.success("Grupo actualizado")
      })

    }
  }

  onChange(event, item: GroupDevices) {

    this.devicesService.ActiveMeter(item.deviceName.replace('G', ''), event).subscribe(res => {
      // tslint:disable-next-line:radix
      if (parseInt(res['status']['code']) == 200) {
        this.toaster.success(`Se ${event == true ? 'Encendio' : 'Apago'} correctamente`);
      } else {
        this.toaster.singleError('El dispositivo no se encendio. Intente nuevamente')
      }
    }, error => {
      this.toaster.error('Error Server');
    });

  }

  masterChange(event, group: GroupDevices[]) {
    group.forEach(element => {
      this.devicesService.ActiveMeter(element.deviceName.replace('G', ''), event).subscribe(res => {
        // tslint:disable-next-line:radix
        if (parseInt(res['status']['code']) == 200) {
          this.toaster.success(`Se ${event == true ? 'Encendio' : 'Apago'} correctamente`);
        } else {
          this.toaster.singleError('El dispositivo no se encendio. Intente nuevamente')
        }
      }, error => {
        this.toaster.error('Error Server');
      });
    });
  }
  goToDevice(id) {
    this.router.navigate(['/u/smart/devices/device-details/', id])
  }

  public seeMoreDetailCircuit(circuit_id: string) {
    this.router.navigate([`/u/circuits/${circuit_id}`]);
  }

  private showInfoMarker(device: GroupDevices) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    let strInfo = this.getStringInfo(device);
    let infowindow = new google.maps.InfoWindow({ content: strInfo });
    infowindow.open(this.map, device.marker);
    window.setTimeout(() => {
      const seeDetailDevice = document.getElementById('seeDetailDevice');
      const seeDetailCircuit = document.getElementById('seeDetailCircuit');
      seeDetailDevice.addEventListener('click', () =>
        this.goToDevice(device.device_id));
      seeDetailCircuit.addEventListener('click', () =>
        this.seeMoreDetailCircuit(device.circuit_id));
    }, 500);
    this.lstInfowindows.push(infowindow);
  }

  private getStringInfo(device: GroupDevices): string {
    return `
    <div class='container my-0 mx-0 py-0 px-0' style="width: 300px;">
      <div class='row mx-1 my-0 mt-1'>
          <div class='col-7'>
              <div class='my-0'>
                  <strong class="mb-0"> No. Dispositivo: </strong>
              </div>
              <div>
                  ${device.deviceName}
              </div>
              <br>
              <div class='my-0'>
                  <strong class="mb-0"> No. Circuito: </strong>
              </div>
              <div>
                  ${device.circuit_name}
              </div>
              <br>
              <div class='my-0'>
                  <strong class="mb-0"> Estatus: </strong>
              </div>
              <div>
                  ${device.device_status}
              </div>
              <br>
              <div class='mr-1'>
                <a href='u/smart/devices/device-details/${device.device_id}' onclick='return false;'>
                  <input id='seeDetailDevice' type='button' style=' cursor:pointer;' value='Ver Dispositivo' class='oe--button--info'>
                </a>
              </div>
          </div>
          <div class='col-4'>
              <div class='my-0'>
                  <strong class="mb-0"> Voltaje: </strong>
              </div>
              <div>
                  ${Math.round(device.voltage)} V.
              </div>
              <br>
              <div class='my-0'>
                  <strong class="mb-0"> Coriiente: </strong>
              </div>
              <div>
                  ${Math.round(device.current)} A.
              </div>
              <br>
              <div class='my-0'>
                  <strong class="mb-0"> Consumo: </strong>
              </div>
              <div>
                  ${Math.round(device.kwh)} kWh
              </div>
              <br>
              <div class='mr-1'>
                <a href='u/circuits/${device.circuit_id}' onclick='return false;'>
                  <input id='seeDetailCircuit' type='button' style=' cursor:pointer;' value='Ver Circuito' class='oe--button--info'>
                </a>
              </div>
          </div>
      </div>
  </div>
    `;
  }

}
