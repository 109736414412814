import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GenericTable } from '@app/models/generic-table';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Color } from 'ng2-charts';
import { MarkerColor } from './marker-color';
import { MarkerColorService } from './marker-color.service';

@Component({
  selector: 'app-marker-color',
  templateUrl: './marker-color.component.html',
  styleUrls: ['./marker-color.component.scss']
})
export class MarkerColorComponent implements OnInit {
  @ViewChild('contentRegisterMarkerColorModal') registerMakerColorModal: any;
  @ViewChild('contentUpdateMarkerColorModal') updateMakerColorModal: any;
  markerColorItems: GenericTable<MarkerColor>;
  typeMarker = '';
  is_Single = true;
  is_AvailableSave = false;
  binding: any;
  binding_: any;
  svgDesing: any;
  svgDesing_: any;
  update_MarkerColor_id = '';

  constructor(
    private markerColorService: MarkerColorService,
    // private formBuilderMakerColor: FormBuilder,
    private toastr: Toastr,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.getInitialValues(0);
  }

  private getInitialValues(page) {
    this.markerColorService.getListMarkerColor(page).subscribe((result: GenericTable<MarkerColor>) => {
      this.markerColorItems = result;
    });
  }

  pageChange(page) {
    this.getInitialValues(page - 1);
  }

  onValidateSVG(svgValue) {
    if (svgValue === '0') {
      this.svgDesing_ = '';
      this.binding_ = '';
      this.binding = '#000000';
      this.is_AvailableSave = true;
      this.is_Single = true;
      this.svgDesing = 'm 25 50 a 25 25 0 0 0 50 0 a 25 25 0 0 0 -50 0 z';
    } else if (svgValue === '1') {
      this.is_Single = false;
      this.is_AvailableSave = true;
      this.binding_ = '#000000';
      this.svgDesing = 'm 25 50 a 25 25 0 0 0 50 0 a 25 25 0 0 0 -50 0 z';
      this.svgDesing_ = 'm 40 50 a 1 1 0 0 0 20 0 a 1 1 0 0 0 -20 0 z';
    } else {
      this.binding_ = '';
      this.svgDesing = '';
      this.svgDesing_ = '';
      this.is_AvailableSave = false;
      this.is_Single = false;
    }
  }


  registerMakerColor() {
    const insert = {
      fillColor: this.binding + (this.binding_ === '' ? '' : '|' + this.binding_),
      path: this.svgDesing + (this.svgDesing_ === '' ? '' : ('|' + this.svgDesing_)),
      stroke: this.svgDesing_ === '' ? '0' : '5'
      };
      this.markerColorService.registerMakerColor(insert).subscribe(result => {
        this.toastr.success('El registro se creo correctamente');
        this.modalService.dismissAll();
        this.getInitialValues(0);
      }, error => {
        this.binding = '';
        this.binding_ = '';
        this.svgDesing = '';
        this.svgDesing_ = '';
        this.is_AvailableSave = false;
        this.toastr.error(error);
        this.modalService.dismissAll();
      });
  }

  onEdit(markerColor: MarkerColor) {
    console.log(markerColor);
    this.binding = markerColor.fill_color;
    this.typeMarker = markerColor.path2 === '' ? '0' : '1';
    this.is_Single = markerColor.path2 === '' ? true : false;
    this.binding_ = markerColor.fill_color2;
    this.svgDesing = markerColor.path;
    this.svgDesing_ = markerColor.path2;
    this.update_MarkerColor_id = markerColor.id;
    this.modalService.open(this.updateMakerColorModal, {size: 'lg'}).result.then(result => {
      // console.log(result);
    }, reason => {
      this.binding = '';
      this.binding_ = '';
      this.svgDesing = '';
      this.svgDesing_ = '';
      this.typeMarker = '';
      this.is_Single = true;
      this.update_MarkerColor_id = '';
      this.is_AvailableSave = false;
    });
  }

  updateMakerColor() {
    const updateMarkerColor = {
      id: this.update_MarkerColor_id,
      fillColor: this.binding + (this.binding_ === '' ? '' : '|' + this.binding_),
      path: this.svgDesing + (this.svgDesing_ === '' ? '' : ('|' + this.svgDesing_)),
      stroke: this.svgDesing_ === '' ? '0' : '5'
      };
      this.markerColorService.updateMarkerColor(updateMarkerColor).subscribe(result => {
        this.toastr.success('Se actualizo correctamente');
        this.modalService.dismissAll();
        this.getInitialValues(0);
      }, error => {
        this.binding = '';
        this.binding_ = '';
        this.svgDesing = '';
        this.svgDesing_ = '';
        this.is_AvailableSave = false;
        this.toastr.error(error);
        this.modalService.dismissAll();
      });
  }


  showRegisterMakerColorModal() {
    this.modalService.open(this.registerMakerColorModal, { size: 'lg' }).result.then(result => {
    }, reason => {
      this.binding = '';
      this.binding_ = '';
      this.svgDesing = '';
      this.svgDesing_ = '';
      this.is_AvailableSave = false;
    });
  }


}
