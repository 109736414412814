import { NumberOfPhotocellsEnum } from "./number_of_photocells-enum";
import { NumberOfDamagedPhotocellsEnum } from "./number_of_damaged_photocells-enum";
import { NumberOfReceptacleInputsEnum } from "./number_of_receptacle_inputs-enum";

interface PhotocellGroupInterface {
    id: string,
      is_present: boolean,
      number_of_photocells: number,
      number_of_photocells_name: string,
      number_of_damaged_photocells: number,
      number_of_damaged_photocells_name: string,
      photocells_image_url: string,
      has_receptacle: boolean,
      number_of_receptacle_inputs: number,
      number_of_receptacle_inputs_name: string,
      receptacle_is_inside_cabinet: boolean
}

export class PhotocellGroup implements PhotocellGroupInterface {
    id: string;
    is_present: boolean;
    number_of_photocells: number;
    number_of_photocells_name: string;
    number_of_damaged_photocells: number;
    number_of_damaged_photocells_name: string;
    photocells_image_url: string;
    has_receptacle: boolean;
    number_of_receptacle_inputs: number;
    number_of_receptacle_inputs_name: string;
    receptacle_is_inside_cabinet: boolean;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.is_present = attrs.is_present;
    this.number_of_photocells = attrs.number_of_photocells;
    this.number_of_photocells_name = NumberOfPhotocellsEnum[attrs.number_of_photocells_name];
    this.number_of_damaged_photocells = attrs.number_of_damaged_photocells;
    this.number_of_damaged_photocells_name = NumberOfDamagedPhotocellsEnum[attrs.number_of_damaged_photocells];
    this.photocells_image_url = attrs.photocells_image_url;
    this.has_receptacle = attrs.has_receptacle ? true : false;
    this.number_of_receptacle_inputs = attrs.number_of_receptacle_inputs;
    this.number_of_receptacle_inputs_name = NumberOfReceptacleInputsEnum[attrs.number_of_receptacle_inputs];
    this.receptacle_is_inside_cabinet = attrs.receptacle_is_inside_cabinet ? true: false;
  }
}
