export interface IGuarantyClaim {
    id_guaranty_claim: string,
    id_guaranty_reports: string,
    gReference_id: string,
    lum_ref: number,
    created_at: Date,
    task_reference_id: number,
    is_accepted: number,
    is_luminaire: boolean,
    selected: boolean;
}

export class GuarantyClaim implements IGuarantyClaim {
    id_guaranty_claim: string;
    id_guaranty_reports: string;
    gReference_id: string;
    lum_ref: number;
    created_at: Date;
    task_reference_id: number;
    is_accepted: number;
    is_luminaire: boolean;
    selected: boolean;

    constructor(attrs) {
        this.id_guaranty_claim = attrs.id_guaranty_claim;
        this.id_guaranty_reports = attrs.id_guaranty_reports;
        this.gReference_id = attrs.gReference_id;
        this.lum_ref = attrs.lum_ref;
        this.created_at = new Date(attrs.created_at);
        this.task_reference_id = attrs.task_reference_id;
        this.is_accepted = attrs.is_accepted;
        this.is_luminaire = attrs.is_luminaire;
        this.selected = false;
    }
}


export interface IDetailGuarantyClaim {
    id_guaranty_claims?: string,
    lum_type?: number,
    model_lum?: string,
    comment?: string,
    failure_evidence?: number,
    damage_location?: number,
    serial_number?: string,
    question?: boolean[],
    is_luminaire?: boolean,
    is_accepted?: boolean,
    is_on?: boolean
}

export class DetailGuarantyClaim implements IDetailGuarantyClaim {
    id_guaranty_claims?: string;
    lum_type?: number;
    model_lum?: string;
    comment?: string;
    failure_evidence?: number;
    damage_location?: number;
    serial_number?: string;
    question?: boolean[];
    is_luminaire?: boolean;
    is_accepted?: boolean;
    is_on?: boolean;

    constructor(attrs) {
        this.id_guaranty_claims = attrs.id_guaranty_claims;
        this.lum_type = attrs.lum_type;
        this.model_lum = attrs.model_lum;
        this.comment = attrs.comment;
        this.failure_evidence = attrs.failure_evidence;
        this.damage_location = attrs.damage_location;
        this.serial_number = attrs.serial_number;
        this.question = attrs.question;
        this.is_luminaire = attrs.is_luminaire;
        this.is_accepted = attrs.is_accepted;
        this.is_on = attrs.is_on;
    }

}

