import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GuarantyClaimService } from './GuarantyClaimService.service';
import { GenericTable } from '@app/models/generic-table';
import { GuarantyClaim, DetailGuarantyClaim } from '@app/models/GuarantyClaim';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { DELEGATE_CTOR } from '@angular/core/src/reflection/reflection_capabilities';
import { LuminaireEnum } from '@app/models/luminaire-enum';
import { FailureEvidenceEnum } from '@app/models/failure-evidence-enum';
import { LuminaireDamageLocation } from '@app/models/luminaire-damage-location-enum';

@Component({
  selector: 'app-guaranty-claims',
  templateUrl: './guaranty-claims.component.html',
  styleUrls: ['./guaranty-claims.component.scss']
})

export class GuarantyClaimsComponent implements OnInit {
  @ViewChild('detailGuarantyModal') detailGuarantyModal: any;
  @ViewChild('modalConfirm') ngModalConfirm: any;
  luminaireTypeEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  failureEvidenceEnum = Object.keys(FailureEvidenceEnum).filter(key => !isNaN(Number(FailureEvidenceEnum[key])));
  luminaireDamageLocation = Object.keys(LuminaireDamageLocation).filter(key => !isNaN(Number(LuminaireDamageLocation[key])));
  // statusReport1 = 'true';
  // statusReport2 = 'true';
  // statusReport3 = 'true';
  referenceReportNumber = '';
  model_luminaire = '';
  referenceLuminaireNumber = '';
  startDate;
  endDate;
  is_Luminarie = true;
  pagination: GenericTable<GuarantyClaim>;
  filterForm: FormGroup;
  formGroup: FormGroup;
  detailGClaim: DetailGuarantyClaim;


  constructor(
    private guarantyService: GuarantyClaimService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toastr: Toastr,
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id_guaranty_claims: [null],
      lum_type: [null],
      model_lum: [null],
      comment: [null],
      failure_evidence: [null],
      damage_location: [null],
      serial_number: [null],
      question1: [null],
      question2: [null],
      question3: [null],
      is_luminaire: [null],
      is_accepted: [null],
      is_on: [null]
    });
    this.getElementsPaginated(0);
  }


  onSearch() {
    // tslint:disable-next-line:max-line-length
    this.guarantyService.filter_GuarantyClaim(this.referenceReportNumber, this.model_luminaire, this.referenceLuminaireNumber,
      //  this.statusReport1, this.statusReport2, this.statusReport3,
      // tslint:disable-next-line:max-line-length
      this.startDate === null || this.startDate === undefined ? '' : (this.startDate.month + '/' + this.startDate.day + '/' + this.startDate.year),
      this.endDate === null || this.endDate === undefined ? '' : (this.endDate.month + '/' + this.endDate.day + '/' + this.endDate.year)).subscribe(
        response => {
          if (response.elements.length === 0) {
            this.toastr.singleError('No hay datos para mostrar.');
            return;
          } else {
            this.pagination = response
          }
        }, error => {
          this.toastr.error(error);
        }
      );

  }

  getElementsPaginated(page: number) {
    this.guarantyService.getList_GuarantyClaim(page).subscribe(
      response => {
        if (response.elements.length === 0) {
          this.toastr.singleError('No hay datos para mostrar.');
          return;
        } else {
          this.pagination = response;
        }
      }
    );

  }

  onModalConfirm() {
    this.modalService.open(this.ngModalConfirm, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
      result => {

      },
      reason => {
      });

  }

  onAssingMaterial(id_guaranty_claims: string, is_luminaire: boolean) {
    this.formGroup = this.fb.group({
      id_guaranty_claims: [null],
      lum_type: [null],
      model_lum: [null],
      comment: [null],
      failure_evidence: [null],
      damage_location: [null],
      serial_number: [null],
      question1: [null],
      question2: [null],
      question3: [null],
      is_luminaire: [null],
      is_accepted: [null],
      is_on: [null]
    });
    this.is_Luminarie = is_luminaire;
    this.guarantyService.get_GuarantyClaim(id_guaranty_claims, is_luminaire === true ? 'true' : 'false').subscribe(
      response => {
        this.populateForm(response);
      }
    );
    this.modalService.open(this.detailGuarantyModal, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
      result => {
      },
      reason => {
      });

  }

  private populateForm(detailGuarantyClaim: DetailGuarantyClaim) {
    this.formGroup.patchValue({
      id_guaranty_claims: detailGuarantyClaim.id_guaranty_claims,
      lum_type: detailGuarantyClaim.lum_type,
      model_lum: detailGuarantyClaim.model_lum,
      comment: detailGuarantyClaim.comment,
      failure_evidence: detailGuarantyClaim.failure_evidence,
      damage_location: detailGuarantyClaim.damage_location,
      serial_number: detailGuarantyClaim.serial_number,
      question1: detailGuarantyClaim.question[0],
      question2: detailGuarantyClaim.question[1],
      question3: detailGuarantyClaim.question[2],
      is_accepted: detailGuarantyClaim.is_accepted,
      is_luminaire: detailGuarantyClaim.is_luminaire,
      is_on: detailGuarantyClaim.is_on
    });
  }

  pageChange(page: number) {
    this.getElementsPaginated(page - 1);
  }

  update_GuarantyClaim(val: FormGroup, is_accepted: boolean) {
    const question = [val.get('question1').value, val.get('question2').value, val.get('question3').value]
    const insert = {
      id_guaranty_claims: val.value.id_guaranty_claims,
      serial_number: val.value.serial_number,
      question: question,
      is_accepted: is_accepted,
      comment: val.value.comment,
      is_luminaire: this.is_Luminarie,
      damage_Location: val.value.damage_location,
      failure_Evidence: val.value.failure_evidence,
      lum_type: val.value.lum_type,
      is_on: val.value.is_on
    }
    this.guarantyService.update_Claim(insert).subscribe(
      response => {
        this.toastr.success('Se Actualiso Correctamente.');
        this.getElementsPaginated(0);
      }
    );
  }

  printSelected() {

    if (this.pagination !== undefined) {
      // tslint:disable-next-line:max-line-length
      const reports_luminaire = this.pagination.elements.filter(luminaire => luminaire.selected === true && luminaire.is_luminaire === true);
      const reports_photocell = this.pagination.elements.filter(photocell => photocell.selected === true && photocell.is_luminaire === false);
      if (reports_luminaire.length === 0 && reports_photocell.length === 0) {
        this.toastr.singleError('Se debe seleccionar al menos un registro habilitado para reporte');
        return;
      }
      if (reports_luminaire.length > 0) {
        const values = [];
        reports_luminaire.forEach(lum => {
          values.push(lum.id_guaranty_claim);
        });
        this.guarantyService.downloadLuminairesReport(values);
      }



      if (reports_photocell.length > 0) {
        const values_ = [];
        reports_photocell.forEach(photocells => {
          values_.push(photocells.id_guaranty_claim);
        });
        this.guarantyService.downloadPhotocellsReport(values_);
      }


    } else {
      this.toastr.singleError('No existen datos para generar un reporte');
      return;
    }


  }



}
