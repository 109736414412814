export enum RoleEnum {
  'Administrador',
  'Ing. de proyecto',
  'Residente',
  'Diseño',
  'Almacén',
  'Call Center',
  'Supervisor de alumbrado público',
  'Cliente',
  'Supervisor de cuadrilla',
  'Oficial de cuadrilla',
  'Compras',
  'Analista' //Tienen los mismos permisos de Call Center, ademas de poder asignar potencias y visualizar tareas
}