interface ITicketInformation {
    id: string,
    specification: string,
    folio: string,
    address: string,
    comment: string,
    reference: string,
    disclaimer_was_accepted: boolean,
    problem: string,
    source: string,
    responsible_party: string,
    status: number,
    user: UserTicket,
    citizen: Citizen,
    repairs: Repairs[],
    luminaires: LuminairesInfo[]


}

export class TicketInformation implements ITicketInformation {
    id: string;
    specification: string;
    folio: string;
    address: string;
    comment: string;
    reference: string;
    disclaimer_was_accepted: boolean;
    problem: string;
    source: string;
    responsible_party: string;
    status: number;
    user: UserTicket;
    citizen: Citizen;
    repairs: Repairs[];
    luminaires: LuminairesInfo[];

    constructor(attr) {
        this.id = attr.id;
        this.folio = attr.folio;
        this.comment = attr.comment;
        this.reference = attr.reference;
        this.disclaimer_was_accepted = attr.disclaimer_was_accepted;
        this.problem = attr.problem;
        this.source = attr.source;
        this.responsible_party = attr.responsible_party;
        this.status = attr.status;
        this.specification = attr.specification;
        this.user = attr.user;
        this.citizen = attr.citizen;
        this.repairs = attr.repairs;
        this.luminaires = attr.luminaires;
    }

}

export class UserTicket implements IUserTicket {
    id: string;
    last_name: string;
    first_name: string;
    name: string;
    email: string;
    phone_number: string;

    constructor(attrs) {
        this.id = attrs.id;
        this.last_name = attrs.last_name;
        this.first_name = attrs.first_name;
        this.name = attrs.name;
        this.phone_number = attrs.phone_number;
        this.email = attrs.email;
    }

}


interface IUserTicket {
    id: string,
    last_name: string,
    first_name: string,
    name: string,
    email: string,
    phone_number: string
}


export class Citizen implements ICitizen {
    id: string;
    name: string;
    first_last_name: string;
    phone_number: string;
    second_last_name: string;
    email: string;

    constructor(attrs) {
        this.id = attrs.id;
        this.name = attrs.name;
        this.first_last_name = attrs.first_last_name;
        this.phone_number = attrs.phone_number;
        this.second_last_name = attrs.second_last_name;
    }
}

interface ICitizen {
    id: string,
    name: string,
    first_last_name: string,
    phone_number: string,
    second_last_name: string,

}

export class Repairs implements IRepairs {
    id: string;
    luminaire_id: string;
    problem: string;
    reference: string;
    comment: string;
    diagnosis: number;
    repairable_type: string;
    status: string;
    userName: string;
    reference_luminaire: number;


    constructor(attrs) {
        this.id = attrs.id;
        this.luminaire_id = attrs.luminaire_id;
        this.problem = attrs.problem;
        this.reference = attrs.reference;
        this.comment = attrs.comment;
        this.diagnosis = attrs.diagnosis;
        this.repairable_type = attrs.repairable_type;
        this.status = attrs.status;
        this.userName = attrs.userName;
        this.reference_luminaire = attrs.reference_luminaire;
    }
}

interface IRepairs {
    id: string,
    luminaire_id: string,
    problem: string,
    repairable_type: string,
    status: string,
    userName: string,
    reference_luminaire: number
}

export class LuminairesInfo implements ILuminairesInfo {
    id: string;
    type: string;
    typeName: string;
    reference_id: number;
    technology: string;
    status: number;
    statusName: string;
    location: Location;
    marker: google.maps.Marker;
    isSelected: boolean;
    event: string;

    // readonly markerIcon = {
    //     linked: {
    //         hover: 'assets/img/svg/ic_link_hover.svg',
    //         normal: 'assets/img/svg/link.svg',
    //         selected: 'assets/img/svg/ic_link_circuit.svg',
    //         circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
    //     }
    // }


    constructor(attrs) {
        this.id = attrs.id;
        this.type = attrs.type;
        this.typeName = attrs.typeName;
        this.reference_id = attrs.reference_id;
        this.technology = attrs.technology;
        this.status = attrs.status;
        this.statusName = attrs.statusName;
        this.location = attrs.location;
        this.isSelected = false;
    }

    // get_icon(): object {
    //     return {
    //         url: this.markerIcon['linked'][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
    //     };
    // }


    paintMarker(map: any): void {
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.id,
            position: new google.maps.LatLng(this.location.latitude, this.location.longitude),
            icon: 'assets/img/svg/luminaire_normal-' + this.status + '.svg',
            map: map,
        });
    }




    // setMap(map: any): void {
    //     this.marker.setMap(map);
    // }


}

interface ILuminairesInfo {
    id: string,
    type: string,
    typeName: string,
    reference_id: number,
    technology: string,
    status: number,
    statusName: string,
    location: Location
}

export class Location implements ILocation {
    latitude: number;
    longitude: number;
    constructor(attrs) {
        this.latitude = attrs.latitude;
        this.longitude = attrs.longitude;
    }
}

interface ILocation {
    latitude: number,
    longitude: number
}

