import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstallationService {

  constructor(private httpClient: HttpClient) { }

  // updateInstall(installationID: string, formData: FormData): Observable<any> {
  //   return this.httpClient.put<any>(
  //     `${environment.SERVER_URL}/installations/${ installationID }`, formData);
  // }

  updateInstall(file: FormData) {
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/Task_Installation/UpdateInstallation`, file);


  }




  getNextInstallation(is_next, install_id) {
    const httpParams = new HttpParams()
      .set('is_next', is_next)
      .set('install_id', install_id);
    return this.httpClient.get<any>(`${environment.SERVER_TASKS}/api/Task_Installation/get-next-installation`, { params: httpParams });
  }
}
