import { Component, OnInit, ViewChild } from '@angular/core';
import { InventoryService } from '../inventory.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { Material } from 'app/models/material';
import { Location } from '@angular/common';
// import { ShoppingCart, ShoppingCartResponse } from 'app/models/shopping_cart';
import { GetShoppingCartResponse } from 'app/models/get-shopping-cart-response';
import { ShoppingCartItemPayload } from 'app/models/shopping_cart_item-payload';
import { GetTroopersResponse } from 'app/models/get-troopers-response';
import { Trooper } from 'app/models/trooper';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ShoppingCart, TroopShoppingCart } from './Cart';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html'
})
export class CartComponent implements OnInit {
  @ViewChild('contentModal') contentModal: any;
  // shopping_cart: ShoppingCart = null;
  shopping_cart: ShoppingCart;
  shoppingForm: FormGroup;
  troopers_: TroopShoppingCart[] = [];

  troopers: Trooper[] = [];
  troopShoppingCart: TroopShoppingCart[] = [];
  txtModalAdv = '';
  idUserSelected: string = null;

  constructor(
    private inventoryService: InventoryService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: Toastr,
    private authService: AuthService,
    private router: Router,
    private location: Location
  ) {
    this.shoppingForm = this.fb.group(
      {
        id: [null, Validators.required],
        quantity: [null, Validators.required],
        action: ['subtract', Validators.required],
      }
    );
  }

  ngOnInit() {
    this.getCategory();
    this.getTroopers();
    this.getTroopers_();
    // console.log( this.route.queryParamMap.subscribe(queries => {
    //   this.idUserSelected = queries.get('user') ? queries.get('user') : null;
    // }));
    this.route.queryParamMap.subscribe(queries => {
      this.idUserSelected = queries.get('user') ? queries.get('user') : null;
    });
  }

  private getCategory() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);
    console.log(decodeToken['id']);
    this.inventoryService.getShoppingCart_(decodeToken['id'])
      .subscribe(result => {
        console.log(result);
        this.shopping_cart = new ShoppingCart(result);
      }, error => {
        this.toastr.error(error);
      })
  }






  // private getCategory() {
  //   this.inventoryService.getShoppingCart()
  //     .subscribe((shoppingCartResponse: GetShoppingCartResponse) => {
  //       this.shopping_cart = new ShoppingCart(shoppingCartResponse.shopping_cart);
  //     }, error => {
  //       this.toastr.error(error);
  //     })
  // }

  private getTroopers_() {
    this.inventoryService.getTroopers_().subscribe(result => {
      this.troopShoppingCart = result;
      this.troopers_ = result;
      console.log(result, 'Servicio nuevo');

    }, error => {
      this.toastr.error(error);
    });
  }



  private getTroopers() {
    this.inventoryService.getTroopers()
      .subscribe((troopers: Trooper[]) => {
        this.troopers = troopers.filter(x => x.is_active == true);
        console.log(troopers, 'datos del servicio del drop down troop');
      }, error => {
        this.toastr.error(error);
      })
  }

  // createRequisition_() {
  //   if (!this.shopping_cart.items.length)
  //     return this.toastr.singleError('Operación fallida. No hay material.');
  //   if (!this.idUserSelected)
  //     return this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
  //   this.inventoryService.createRequisition(this.idUserSelected, 0)
  //     .subscribe(result => {
  //       this.toastr.success('Se creó la solicitud.');
  //       this.router.navigate(['/u/inventory/requisitions']);
  //     }, error => {
  //       this.toastr.error(error);
  //     })
  // }
















  
  clickDelete(item: any) {
    this.txtModalAdv = item.material.name;
    this.modalService.open(this.contentModal).result.then(result => {
      this.shoppingForm.patchValue({
        id: item.material.id,
        name: item.material.name,
        quantity: item.quantity
      });
      this.deleteMaterial(item.material.id, item.id);
    }, reason => { });
  }

  deleteMaterial(material_id: string, shoppingCartItem_id: string) {
    //  const requestPayload = new ShoppingCartItemPayload(this.shoppingForm);
    //  console.log(this.shoppingForm.value, 'delete');
    console.log(shoppingCartItem_id);
    this.inventoryService.updateShoppingCart_(shoppingCartItem_id)
      .subscribe(result => {
        this.toastr.success('Se eliminó del carrito.');
        this.shopping_cart.items = this.shopping_cart.items.filter(item_ => item_.material.id !== material_id);
      }, error => {
        this.toastr.error(error);
      });
  }

  // METODOS ANTIGUOS
  // deleteMaterial(id: string) {
  //   const requestPayload = new ShoppingCartItemPayload(this.shoppingForm);
  //   this.inventoryService.updateShoppingCart(requestPayload)
  //     .subscribe(result => {
  //       this.toastr.success('Se eliminó del carrito.');
  //       this.shopping_cart.items = this.shopping_cart.items.filter(item_ => item_.material.id != id);
  //     }, error => {
  //       this.toastr.error(error);
  //     });
  // }

  createRequisition() {
    if (!this.shopping_cart.items.length)
      return this.toastr.singleError('Operación fallida. No hay material.');
    if (!this.idUserSelected)
      return this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    this.inventoryService.createRequisition(this.idUserSelected, 0)
      .subscribe(result => {
        this.toastr.success('Se creó la solicitud.');
        this.router.navigate(['/u/inventory/requisitions']);
      }, error => {
        this.toastr.error(error);
      })
  }

  goBack() {
    this.location.back();
  }

}
