import { Component, OnInit, Input } from '@angular/core';
import { Troop } from 'app/models/troop';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { Item } from 'app/models/item';
import { TroopsService } from '../troops.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@app/shared/auth/auth.service';
import { InventoryService } from '@app/pages/inventory/inventory.service';

@Component({
  selector: 'app-troop-form',
  templateUrl: './troop-form.component.html',
  styleUrls: ['./troop-form.component.scss'],
  providers: [TroopsService]
})
export class TroopFormComponent implements OnInit {
  confirmationIsActive: boolean = true;
  editableTroop: IEditTroop;
  isActive: boolean = true;
  troopForm: FormGroup;
  totalRef: number = 0;

  @Input() buttonText: String;
  @Input() parent: any;
  @Input() isCreateAction: boolean;
  @Input() disableButtonsForm: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private troopsService: TroopsService,
    private toastr: Toastr,
    private authService: AuthService,
    private inventoryService: InventoryService
  ) { }

  ngOnInit() {
    this.troopForm = this.formBuilder.group({
      id: [{ value: '1', disabled: true }, Validators.required],
      number: [{ value: null, disabled: !this.disableButtonsForm }, Validators.required],
      ipad_serial_number: [{ value: null, disabled: !this.disableButtonsForm }],
      plates: [{ value: null, disabled: !this.disableButtonsForm }],
      geotab_id: [{ value: null, disabled: !this.disableButtonsForm }]
    });
  }


  initializeUserForm(troop: IEditTroop): void {
    this.troopForm.patchValue({
      id: troop.id,
      number: troop.number,
      ipad_serial_number: troop.ipad_serial_number,
      plates: troop.plates,
      geotab_id : troop.geotab_id
    });
  }

  setEditableTroop(troop: IEditTroop): void {
    this.editableTroop = troop;
  }

  performFormAction(): void {
    this.parent.performAction(this.troopForm);
  }

 

}
