export interface GeolocationPointInterface {
  id: string;
  latitude: number;
  longitude: number;
}

export class GeolocationPoint implements GeolocationPointInterface {
  id: string;
  latitude: number;
  longitude: number;

  constructor(lat: number, lng: number) {
    this.latitude = lat;
    this.longitude = lng;
  }
}