import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../auth/auth.service';

@Injectable()

export class AuthenticationHeaderInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isHeaderNeeded(request.url)) {
      request = request.clone({
        headers: request.headers.append('Authorization', this.authService.getToken())
      });
    }

    return next.handle(request);
  }

  isHeaderNeeded(url: string) {
    if (url.startsWith('https://apis.forcsec.com/api/codigos-postales')) {
      return false;
    } else {
      return true;
    }
  }
}


