import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as FusionCharts from 'fusioncharts';
import { DevicesService } from '../../devices.service';

@Component({
  selector: 'app-device-sensors',
  templateUrl: './device-sensors.component.html',
  styleUrls: ['./device-sensors.component.scss']
})
export class DeviceSensorsComponent implements OnInit {

  sensorsParametersSource: any;

  type: string;
  width: string;
  height: string;

  constructor(
    private deviceService: DevicesService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.type = 'timeseries';
    this.width = '100%';
    this.height = '800';

    this.sensorsParametersSource = {
      chart: {},
      caption: {
        text: 'Lecturas de sensors (30 días)'
      },
      subcaption: {
        text: 'Light, Temperature, Humidity, Pressure'
      },
      yaxis: [
        {
          plot: [
            {
              value: 'Light',
              connectnulldata: true
            }
          ],
          title: 'Light'
        },
        {
          plot: [
            {
              value: 'Temperature',
              connectnulldata: true
            }
          ],
          title: 'Temperature'
        },
        {
          plot: [
            {
              value: 'Humidity',
              connectnulldata: true
            }
          ],
          title: 'Humidity'
        },

        {
          plot: [
            {
              value: 'Pressure',
              connectnulldata: true
            }
          ],
          title: 'Pressure'
        }
      ]
    }
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (!params.has('id')) {
        return;
      }

      this.deviceService.getDeviceSensors(params.get('id')).subscribe(readings => {
        this.sensorsParametersData(readings)
      })
    })
  }

  sensorsParametersData(sensorsReadings: any[]) {
    const dataFetch = sensorsReadings;
    const schemaFetch = [{
      'name': 'Time',
      'type': 'date',
      'format': '%Y-%m-%d %H:%M'
    }, {
      'name': 'Light',
      'type': 'number'
    }, {
      'name': 'Temperature',
      'type': 'number'
    }, {
      'name': 'Humidity',
      'type': 'number'
    },
    {
      'name': 'Pressure',
      'type': 'number'
    }];
    // const schemaFetch = [{
    //   'name': 'Time',
    //   'type': 'date',
    //   'format': '%b %-d, %Y, %-I:%-M'
    // }, {
    //   'name': 'Light',
    //   'type': 'number'
    // }, {
    //   'name': 'Temperature',
    //   'type': 'number'
    // }, {
    //   'name': 'Humidity',
    //   'type': 'number'
    // },
    // {
    //   'name': 'Pressure',
    //   'type': 'number'
    // }];

    Promise.all([dataFetch, schemaFetch]).then(res => {
      const [data, schema] = res;
      const fusionDataStore = new FusionCharts.DataStore();
      const fusionTable = fusionDataStore.createDataTable(data, schema);
      console.log(fusionTable);
      this.sensorsParametersSource.data = fusionTable;
    });
  }

}
