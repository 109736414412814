export enum NumberOfDamagedPhotocellsEnum {
    'd_0',
    'd_1',
    'd_2',
    'd_3',
    'd_4',
    'd_5',
    'd_6',
    'd_7',
    'd_8',
    'd_9',
    'd_10',
    'd_Otro'
}