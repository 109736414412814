import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { MaterialPayload } from '@models/material-payload';
import { MaterialCategoriesResponse } from '@models/material_categories-response';
import { MaterialCategoryResponse } from '@models/material_category-response';
import { GenericTable } from '@app/models/generic-table';
import { Material_Project } from './material_project';
import { map } from 'rxjs/operators';
import { Material_Categorys } from './material_categorys';
import { Material } from '@app/models/material';

@Injectable({
  providedIn: 'root'
})
export class ChargedValuesService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getChargedValue(): Observable<MaterialCategoriesResponse> {
    return this.httpClient
      .get<MaterialCategoriesResponse>(`${environment.SERVER_URL}/material_categories`);
  }

  getCategory(id: string): Observable<MaterialCategoryResponse> {
    return this.httpClient
      .get<MaterialCategoryResponse>(`${environment.SERVER_URL}/material_categories/${id}/project_materials`);
  }

  getMaterialsCategorys() {
    return this.httpClient.get<Material_Categorys[]>(
      `${environment.SERVER_CONFIG}/api/Materials/GetMaterialsCategorys`
    ).pipe(
      map(elements => elements.map(category => new Material_Categorys(category)))
    );
  }

  getMaterialsProject(form, page) {
    const httpParams = new HttpParams().set('page', page);
    return this.httpClient.post<GenericTable<Material>>(
      `${environment.SERVER_CONFIG}/api/Materials/GetMaterialsProject`, form, { params: httpParams }
    ).pipe(
      map(data => new GenericTable<Material>(data))
    );
  }

  createMaterial(materialPayload: MaterialPayload, materialID: string): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_URL}/material_categories/${materialID}/materials`,
      materialPayload.toFormData()
    );
  }

  updateMaterial(materialPayload: any, materialID: string): Observable<any> {
    console.log(materialPayload)
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/project_materials/${materialID}`,
      materialPayload
    );
  }
}
