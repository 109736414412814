export interface ISummaryTomorrow {
   image: string,
   material: string,
   totalQuantity: number,
   name: string,
   lastName: string
   devices_list: string;
}

export class SummaryTomorrow  implements ISummaryTomorrow  {
    image: string;
    material: string;
    totalQuantity: number;
    name: string;
    lastName: string;
    devices_list: string;


    constructor(attrs: any) {
        this.image = attrs.image;
        this.material = attrs.material;
        this.totalQuantity = attrs.totalQuantity;
        this.name = attrs.name;
        this.lastName = attrs.lastName;
        this.devices_list = attrs.devices_list
    }
}
