import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SmartDashboardService {

  constructor(private httpClient: HttpClient) { }

  getDashboard(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.SERVER_SMART}/api/SmartDashboard/dashboard`);
  }
}
