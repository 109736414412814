import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { NewInfrastructureTaskPayload } from '@models/new-infrastructure-task-payload';


@Injectable()

export class NewInfrastructureTaskService {
  constructor(private httpClient: HttpClient) {}

  getProjectResources(page: number):Observable<object> {
    const params: HttpParams = new HttpParams()
      .set('page', page.toString());
    return this.httpClient
      .get<object>(`${environment.SERVER_URL}/project_resources`, { params: params });
  }

  createInfrastructureTask(newInfrastructureTaskPayload: NewInfrastructureTaskPayload):Observable<Object>{
    return this.httpClient.post<object>(
      `${environment.SERVER_URL}/tasks`,
      newInfrastructureTaskPayload.toJson()
    );
  }
}
