export enum WorkDoneEnum {
  'Reconexión',
  'Cableado subterráneo',
  'Cambio de receptáculo',
  'Cambio de fotocelda',
  'Ajuste de fotocelda',
  'Cambio de contactor',
  'Cambio bobina contactor',
  'Cambio de interruptor',
  'Cambio de base de medidor',
  'Cambio de tubo mufa de 1-1/2"',
  'Cambio/colocación de tablero completo',
  'Cambio de gabinete de banco',
  'Cambio de canilla',
  'Cambio de transformador',
  'Bajante acometida',
  'Cambio de lampara vsap',
  'Cambio de balastro',
  'Cambio de lampara LED',
  'Otro',
  'Reconexión luminaria',
  'Reconexión línea aerea',
  'Reconexión línea subterránea',
  'Reemplazo de cableado subterraneo',
  'Reemplazo de cableado aéreo',
  'Reemplazo/colocación de tapa-cortocircuito',
  'Reemplazo de brazo de luminaria',
  'Ajuste de inclinación de brazo',
  'Ajuste del nivel de luminaria',
  'Colocación de poste',
  'Retiro de poste',
  'Reemplazo de poste',
  'Colocación de base para poste',
  'Demolición y retiro de base de poste',
  'Colocación de bastidor y aislador',
  'Colocación de espaciadores linea aerea',
  'Sanjeo para colocacion de poliducto',
  'Colocación de poliducto',
  'Reemplazo de registro subterráneo',
  'Colocación de tapa de registro subterráneo',
  'Ajuste de terminales interruptor/contactor',
  'Cambio de interruptor principal',
  'Cambio de interruptor secundario',
  'Cambio de centro de carga',
  'Colocación de centro de carga',
  'Retiro de medidor dañado',
  'Reemplazo de reelevador de estado sólido',
  'Cambio de reelevador de estado sólido',
  'Cambio de chapa de gabinete',
  'Colocacion de bajante acometida',
  'Cambio de listón fusible',
  'Ajuste de TAP de transformador',
  'Ajuste de terminales transformador',
  'Colocacion de conector perico',
  'Reemplazo de apartarayo',
  'Colocación de sistema de tierra',
  'Retiro de controlador neurolink',
  'Cambio de controlador neurolink',
  'Retiro de donas de medición',
  'Cambio de donas de medición',
  'Retiro de antena GPS',
  'Cambio de antena GPS',
  'Retiro de antena GSM',
  'Cambio de antena GSM',
  'Otros/Detallar en comentarios'
}