import { Component, OnInit } from '@angular/core';
import { TaskService } from '@pages/tasks/task/task.service';
import { Installation } from '@models/installation';
import { Toastr } from '@shared/toastr/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InstallationService } from './installation.service';

@Component({
  selector: 'app-installation',
  templateUrl: './installation.component.html',
  providers: [TaskService]
})
export class InstallationComponent implements OnInit {
  installation: Installation;
  commentResident = '';
  first_image: any;
  second_image: any;
  third_image: any;
  fourth_image: any;
  fifth_image: any;
  sixth_image: any;
  seventh_image: any;
  eigth_image: any;
  installationID: any;

  constructor(
    private installationService: InstallationService,
    private taskService: TaskService,
    private toastr: Toastr,
    private route: ActivatedRoute,
    private navigati: Router,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.installationID = params.get('id');
      this.getInstallation(this.installationID);
    });
  }

  private getInstallation(id: string): void {
    this.taskService
      .getInstallationNew(id)
      .subscribe(
        (installation: Installation) => {
          this.installation = installation;
          this.commentResident = this.installation.resident_comment;
          console.log(installation);
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  setImagePreview(event: any, number_image: string): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();

      console.log()
      reader.onload = e => {
        if (number_image === 'first_image') {
          this.installation.first_image_url = reader.result.toString();
          this.first_image = file;
        } else if (number_image === 'second_image') {
          this.installation.second_image_url = reader.result.toString();
          this.second_image = file
        } else if (number_image === 'third_image') {
          this.installation.third_image_url = reader.result.toString();
          this.third_image = file
        } else if (number_image === 'fourth_image') {
          this.installation.fourth_image_url = reader.result.toString();
          this.fourth_image = file
        } else if (number_image === 'fifth_image') {
          this.installation.fifth_image_url = reader.result.toString();
          this.fifth_image = file
        } else if (number_image === 'sixth_image') {
          this.installation.sixth_image_url = reader.result.toString();
          this.sixth_image = file
        } else if (number_image === 'seventh_image') {
          this.installation.seventh_image_url = reader.result.toString();
          this.seventh_image = file
        } else if (number_image === 'eigth_image') {
          this.installation.eigth_image_url = reader.result.toString();
          this.eigth_image = file
        }
        this.saveInstallation();
      };
      reader.readAsDataURL(file);
    }
  }

  changeStatus(value: boolean) {
    this.installation.is_finished = value;
    this.saveInstallation();
  }

  public saveComment() {
    this.saveInstallation();
  }

  private saveInstallation() {
    const formData = new FormData();
    formData.append('id', this.installation.id);
    formData.append('is_finished', this.installation.is_finished ? 'true' : 'false');
    formData.append('first_image', this.first_image);
    formData.append('second_image', this.second_image);
    formData.append('third_image', this.third_image);
    formData.append('fourth_image', this.fourth_image);
    formData.append('fifth_image', this.fifth_image);
    formData.append('sixth_image', this.sixth_image);
    formData.append('seventh_image', this.seventh_image);
    formData.append('eigth_image', this.eigth_image);

    if (this.commentResident !== undefined) {
      formData.append('resident_comment', this.commentResident);
    }

    this.installationService.updateInstall(formData)
      .subscribe(result => {
        this.toastr.success('Instalación actualizada.');
      }, error => {
        this.toastr.error(error);
      });
  }

  getActionArrow(is_next: boolean) {
    this.installationService.getNextInstallation(is_next, this.installationID).subscribe(resp => {
      console.log(resp)
this.navigati.navigate([`/u/installation/${resp.task_id}`]);
    }, error => {
      console.error(error);

    })
  }
}
