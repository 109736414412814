import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Toastr } from '@shared/toastr/toastr.service';
import { ReportLuminaireService } from './report-luminaire.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectResponse } from '@models/project-response';
import { Luminaire } from '@models/luminaire';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProblemEnum } from '@models/problem-enum';
import { ReportLuminairePayload } from '@models/report-luminaire-payload';
import { TicketResponse } from '@models/ticket-response';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from '@models/project';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SourceEnum } from '@models/source-enum';
import { ResponsiblePartyEnum as Responsible } from '@models/responsible_party-enum';
import { COUNTRIES } from './countries'
import { CitizenLuminaire } from './citizen-luminaire';

@Component({
  selector: 'app-report-luminaire',
  templateUrl: './report-luminaire.component.html',
  styleUrls: ['./report-luminaire.component.scss'],
  providers: [ReportLuminaireService]
})
export class ReportLuminaireComponent implements OnInit, OnDestroy {
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('sectionMap') sectionMap: any;
  @ViewChild('modalAviso') modalAviso: any;
  autocomplete: google.maps.places.Autocomplete;
  btnSendDisabled = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  folio = '';
  map: google.maps.Map;
  markers: google.maps.Marker[] = [];
  slug: string;
  luminaires: CitizenLuminaire[] = [];
  problemEnum = Object.keys(ProblemEnum).filter(key => !isNaN(Number(ProblemEnum[key])));
  project: Project;
  project_max_page = 0;
  selectedLuminaires: Luminaire[] = [];
  showMessageRequiredFields = false;
  showMessageSelectedLuminaires = false;
  reportForm: FormGroup = this.formBuilder.group({
    problem: [ProblemEnum.Apagada, Validators.required],
    name: [null, Validators.required],
    first_last_name: [null, Validators.required],
    specification: [null],
    second_last_name: [null],
    email: [null, [Validators.email]],
    phone_number: [null],
    disclaimer_was_accepted: [null],
    whatsapp_disclaimer_was_accepted: [null],
    comment: [null],
    address: [''],
    responsibleParty: [Responsible.Concesión],
    source: [SourceEnum["Sitio Web"]],
    country: [{ value: "MX", disabled: true }],
    dial_code: [{ value: "+521", disabled: true }],
    luminaires: this.formBuilder.array([])
  });
  warningMessage = '';
  zoomToShowMarkes = 16;
  countries = COUNTRIES;
  lumRef: string;
  currentZoomMap = 0;

  constructor(
    private toastr: Toastr,
    private router: Router,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private reportLuminaireService: ReportLuminaireService
  ) { }

  ngOnInit() {
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug');

    if (this.slug) {
      this.reportLuminaireService.getCurrentProject(this.slug)
        .subscribe((project: Project) => {
          this.project = project;
          this.paintMap();
        }, error => {
          this.toastr.error(error);
        });
    }

    this.reportForm
      .get('country')
      .valueChanges
      .subscribe((val) => {
        this.countryChanged(val);
      });

    document.body.style.backgroundColor = "#FAFAFA";
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  clickLogo() {
    location.reload();
  }

  get countOfLuminaries() {
    return this.luminaires.filter(luminaire => luminaire.isSelected).length;
  }

  getLuminaires() {
    let location = `${this.map.getCenter().lng()},${this.map.getCenter().lat()}`;
    let lumnsSelected = this.luminaires.filter(luminaire => luminaire.isSelected);
    let idsSelected = lumnsSelected.map(luminaire => { return luminaire.id });
    this.reportLuminaireService
      .getLuminairesOfProject(this.slug, location, idsSelected)
      .pipe(takeUntil(this.destroy$))
      .subscribe((luminaires: CitizenLuminaire[]) => {
        let backLuminaires = this.luminaires.filter(luminaire => !luminaire.isSelected);
        lumnsSelected = this.luminaires.filter(luminaire => luminaire.isSelected);
        luminaires = luminaires.filter(lum => !lumnsSelected.some(_lum => _lum.id === lum.id));

        if (!this.luminaires.length) {
          this.addLuminairesToMap(luminaires);
          this.luminaires = luminaires;
          this.setMarkerRef();
          return;
        }

        let iqualLums = backLuminaires.filter(lum => luminaires.some(_lum => _lum.id === lum.id))
        let diffHideLums = backLuminaires.filter(lum => !iqualLums.some(_lum => _lum.id === lum.id))
        this.setMarkersToMap(diffHideLums, null);

        let diffNewLums = luminaires.filter(lum => !backLuminaires.some(_lum => _lum.id === lum.id));
        this.addLuminairesToMap(diffNewLums);

        this.luminaires = lumnsSelected;

        this.luminaires = this.luminaires.concat(iqualLums);
        this.luminaires = this.luminaires.concat(diffNewLums);
        this.setMarkerRef();
      }, (error) => {
        this.toastr.error(error);
      });
  }

  performFormAction(toltip: any, toltip2: any) {
    if (this.validateFormManually(toltip, toltip2)) {
      let luminaires = this.luminaires.filter(luminaire => luminaire.isSelected);
      for (let luminaire of luminaires) {
        this.reportForm.get('luminaires').value.push({ id: luminaire.id });
      }
      const requestPayload = new ReportLuminairePayload(this.reportForm, null);
      this.btnSendDisabled = true;
      this.reportLuminaireService
        .createReportLuminaires(requestPayload, this.slug)
        .subscribe(
          (response: TicketResponse) => {
            this.btnSendDisabled = false;
            this.showMessageRequiredFields = false;
            this.folio = response.reference;
            this.reportForm.patchValue({
              problem: null,
              comment: null,
              disclaimer_was_accepted: null,
              whatsapp_disclaimer_was_accepted: null,
              address: null,
              luminaires: []
            });
            for (const luminaire of luminaires) {
              luminaire.isSelected = false;
              luminaire.upadeIcon();
            }
            this.modalService.open(this.modalAviso).result.then(result => {
              this.redirect();
            }, reason => { });
            this.reportForm.markAsUntouched();
          },
          (error) => {
            this.toastr.error(error);
          }
        );
    }
  }

  redirect() {
    this.router.navigate([`/revisar-reporte/${this.slug}/${this.folio}`]);
  }

  private addLuminairesToMap(luminaires: CitizenLuminaire[]) {
    for (let luminaire of luminaires) {
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => this.clickLuminaire(luminaire.id));
    }
  }

  private changeZoom(): void {
    this.currentZoomMap = this.map.getZoom();
    if (this.map.getZoom() > this.zoomToShowMarkes)
      this.getLuminaires();
    else {
      this.setMarkersToMap(this.luminaires, null);
      this.luminaires = this.luminaires.filter(lum => lum.isSelected);
    }
  }

  private centerMapOnTheProject() {
    let request = {
      query: `${this.project.municipality}, ${this.project.state}, ${this.project.country}`,
      fields: ['name', 'geometry'],
    };
    let service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    });
  }

  private countryChanged(val): void {
    let country = this.countries.find(_country => _country.code == val);
    this.reportForm.patchValue({ dial_code: country.dial_code });
  }

  private clickLuminaire(luminaireID: string): void {

    let luminaire = this.luminaires.find(luminaire => luminaire.id == luminaireID);
    luminaire.marker.setAnimation(null);

    luminaire.toggleSelect();
    this.showMessageSelectedLuminaires = false;

  }

  private paintMap(): void {
    let mexico = {
      center: { lat: 23.6, lng: -102.5 },
      zoom: 4
    }
    let mapProperties: any = {
      center: new google.maps.LatLng(mexico.center.lat, mexico.center.lng),
      zoom: mexico.zoom,
      disableDefaultUI: true,
      zoomControl: true,
      streetViewControl: true,
      fullscreenControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    this.map.addListener('zoom_changed', () => this.changeZoom())
    this.centerMapOnTheProject();
    this.paintTheSearchBoxOfMap();

    this.map.addListener('dragend', () => {
      if (this.map.getZoom() > this.zoomToShowMarkes) {
        this.getLuminaires();
      }
    })
  }

  private paintTheSearchBoxOfMap() {
    let input: any = document.getElementById('pac-input');
    var searchBox = new google.maps.places.SearchBox(input);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds());
    });


    searchBox.addListener('places_changed', () => {
      let data: any = input.value;
      data = data.trim();

      if (!isNaN(data)) {
        this.searchReferenceId(data);
        return;
      }

      let places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      let bounds = new google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.reportForm.patchValue({ address: places[0].formatted_address });
      this.map.fitBounds(bounds);
    });

  }

  private searchReferenceId(value) {
    let flag = false;
    this.reportLuminaireService.searchLuminaireInProject(this.slug, value)
      .subscribe((luminaires: CitizenLuminaire) => {
        if (!luminaires)
          return flag;
        let luminaire = luminaires;
        let position = new google.maps.LatLng(luminaire.location.latitude, luminaire.location.longitude);
        this.map.setCenter(position);
        this.map.setZoom(this.zoomToShowMarkes + 1);
        this.lumRef = luminaire.reference_id;

        return true;
      }, (error) => {
        this.toastr.error(error);
      });
  }

  private setMarkersToMap(luminaires: CitizenLuminaire[], map: google.maps.Map): void {
    for (let luminaire of luminaires) {
      if (!luminaire.isSelected)
        luminaire.setMap(null);
    }
  }

  private setMarkerRef() {
    if (!this.lumRef)
      return;

    let luminaire = this.luminaires.find(lum => lum.reference_id === this.lumRef);
    luminaire.isSelected = true;
    luminaire.marker.setAnimation(google.maps.Animation.BOUNCE);
    luminaire.upadeIcon();
    this.lumRef = null;
  }

  private validateFormManually(toltip: any, toltip2: any) {
    let flag = true;
    if (!this.countOfLuminaries) {
      this.showMessageSelectedLuminaires = true;
      let element = document.getElementById('sectionMap');
      element.scrollIntoView();
      return false;
    }
    if (this.reportForm.get('problem').value != ProblemEnum.Otra) {
      this.reportForm.controls.specification.updateValueAndValidity()
    }
    if (this.reportForm.valid) {
      if (!this.reportForm.get('email').value && !this.reportForm.get('phone_number').value) {
        this.warningMessage = 'Operación fallida. Revisar campos requeridos (Correo ó Whatsapp)'
        this.showMessageRequiredFields = true;
        return;
      }
      if (!this.reportForm.get('disclaimer_was_accepted').value) {
        this.warningMessage = 'Por favor acepte el campo de Aviso de privacidad';
        this.showMessageRequiredFields = true;
        setTimeout(() => toltip.open(), 0);
        return false;
      }
      if (this.reportForm.get('phone_number').value && !this.reportForm.get('whatsapp_disclaimer_was_accepted').value) {
        this.warningMessage = 'Por favor acepte el campo de requerido';
        this.showMessageRequiredFields = true;
        setTimeout(() => toltip2.open(), 0);
        return false;
      }
      if (this.reportForm.get('problem').value == ProblemEnum.Otra && !this.reportForm.get('specification').value) {
        this.reportForm.controls.specification.setErrors([Validators.required]);
        this.reportForm.controls.specification.markAsTouched();
        this.warningMessage = 'Por favor especifique cual es la falla'
        this.showMessageRequiredFields = true;
        return false;
      }
    } else {
      flag = false;
      this.reportForm.controls.problem.markAsTouched();
      this.reportForm.controls.name.markAsTouched();
      this.reportForm.controls.first_last_name.markAsTouched();
      this.reportForm.controls.email.markAsTouched();
      this.reportForm.controls.phone_number.markAsTouched();
      this.reportForm.controls.comment.markAsTouched();
      this.reportForm.controls.disclaimer_was_accepted.markAsTouched();
      this.warningMessage = 'Operación fallida. Revisar campos requeridos';
      this.showMessageRequiredFields = true;
    };
    return flag;
  }

}
