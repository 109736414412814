import { FormGroup } from "@angular/forms";


export class ShoppingCartItemPayload {
    id: string;
    quantity: string;
    action: string;

    constructor(shoppingForm: FormGroup){
        this.id = shoppingForm.get('id').value;
        this.quantity = shoppingForm.get('quantity').value;
        this.action = shoppingForm.get('action').value;
    }


  toJson(): Object {
    const json = {
        shopping_cart_item: {
            quantity: this.quantity,
            action: this.action
        }
    };

    return json;
  }
}
