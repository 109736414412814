import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { GenericTable } from '@app/models/generic-table';
import { SingleReportRpu } from './report-rpu';
import { ReportRpuService } from './ReportRpu.service';
import { Toastr } from '@app/shared/toastr/toastr.service';

@Component({
  selector: 'app-report-rpu',
  templateUrl: './report-rpu.component.html',
  styleUrls: ['./report-rpu.component.scss']
})

export class ReportRpuComponent implements OnInit {
  fechaInicial;
  fechaFinal;
  rpu;
  pagination: GenericTable<SingleReportRpu>;
  months
  constructor(
    private toastr: Toastr,
    private reportRpuservice: ReportRpuService
  ) { }

  ngOnInit() {
    this.getElementsPaginated(0);
  }

  getElementsPaginated(page: number) {
    this.reportRpuservice.Get_InfotmationRpus(this.rpu === null || this.rpu === undefined ? '' : this.rpu,
      // tslint:disable-next-line:max-line-length
      this.fechaInicial === null || this.fechaInicial === undefined ? '' : (this.fechaInicial.month + '/' + '1' + '/' + this.fechaInicial.year),
      this.fechaFinal === null || this.fechaFinal === undefined ? '' : (this.fechaFinal.month + '/' + '1' + '/' + this.fechaFinal.year), page).subscribe(
        response => {
          if (response.elements.length === 0) {
            this.toastr.singleError('No hay datos para mostrar.');
            return;
            // this.pagination = new GenericTable<SingleReportRpu>(
            //   res => res
            // );
          } else {
            this.pagination = response
            this.months = response.elements.map(x => x.valuesmonth)
          }
        }
      );
  }

  onSearch(page: number) {
    this.reportRpuservice.Get_InfotmationRpus(this.rpu === null || this.rpu === undefined ? '' : this.rpu,
      // tslint:disable-next-line:max-line-length
      this.fechaInicial === null || this.fechaInicial === '' || this.fechaInicial === undefined ? '' : (this.fechaInicial + '-01'),
      this.fechaFinal === null || this.fechaFinal === '' || this.fechaFinal === undefined ? '' : (this.fechaFinal + '-01'), page).subscribe(
        response => {
          if (response.elements.length === 0) {
            this.toastr.singleError('No hay datos para mostrar.');
            return;
          } else {
            this.pagination = response
            this.months = response.elements.map(x => x.valuesmonth)
          }
        }
      );

  }

  exportXLSX(type: number) {
    switch (type) {
      case 1:
        this.reportRpuservice.downloadReportHistoricRpusXLS(this.rpu === null || this.rpu === undefined ? '' : this.rpu,
          // tslint:disable-next-line:max-line-length
          this.fechaInicial === null || this.fechaInicial === '' || this.fechaInicial === undefined ? '' : (this.fechaInicial + '-01'),
          this.fechaFinal === null || this.fechaFinal === '' || this.fechaFinal === undefined ? '' : (this.fechaFinal + '-01'));
        break;
      case 2:
        this.reportRpuservice.downloadReportPeriodicRpusXLS(this.rpu === null || this.rpu === undefined ? '' : this.rpu,
          // tslint:disable-next-line:max-line-length
          this.fechaInicial === null || this.fechaInicial === '' || this.fechaInicial === undefined ? '' : (this.fechaInicial + '-01'),
          this.fechaFinal === null || this.fechaFinal === '' || this.fechaFinal === undefined ? '' : (this.fechaFinal + '-01'));
        break;
        default:
          break;
    }

  }
  pageChange(page: number) {
    this.getElementsPaginated(page);
  }

}
