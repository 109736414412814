export interface Pagination {
  current_page: number;
  next_page: number;
  prev_page: number;
  total_count: number;
  total_pages: number;
  from: number;
  to: number;
}

export class PaginationAttributes {
  current_page: number;
  next_page: number;
  prev_page: number;
  total_count: number;
  total_pages: number;
  from: number;
  to: number;
  
  constructor(current_page, next_page, prev_page, total_count, total_pages, from, to) {
    this.current_page = current_page;
    this.next_page = next_page;
    this.prev_page = prev_page;
    this.total_count = total_count;
    this.total_pages = total_pages;
    this.from = from;
    this.to = to;
  }
}
