import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, Form } from '@angular/forms';

import { UserFormService } from './user-form.service';
import { Role } from '@models/role';
import { RoleEnum } from '@models/role-enum';
import { GetRolesResponse } from '@models/get-roles-response';
import { GetTroopsResponse } from '@models/get-troops-response';
import { Toastr } from '@shared/toastr/toastr.service'
import { Troop } from '@models/troop';
import { User } from '@models/user';

@Component({
  selector: 'user-form-component',
  templateUrl: './user-form.component.html',
  providers: [UserFormService]
})

export class UserFormComponent implements OnInit {
  confirmationIsActive: boolean = true;
  editableUser: User;
  isActive: boolean = true;
  showTroops: boolean = false;
  isTroopRole: Boolean;
  roles: Role[] = [];
  troops: Troop[] = [];
  user_troops: Troop[] = [];
  userForm: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    last_name: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    role_id: [null, Validators.required],
    cell_phone_number: [""],
    address: [""],
    assigned_tools: [""],
    troops: [this.formBuilder.array([])],
    troop_id: [null],
    password: [null],
    password_confirmation: [null],
    image: [null],
    image_url: [null]
  });

  passwordForm: FormGroup = this.formBuilder.group({
    password: [null, Validators.required],
    password_confirmation: [null, Validators.required],
  });

  @Input() buttonText: String;
  @Input() parent: any;
  @Input() isCreateAction: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: Toastr,
    private userFormService: UserFormService
  ) {}

  ngOnInit() {
    this.getRoles();
    this.getTroops();

    this.userForm
      .get('role_id')
      .valueChanges
      .subscribe((val) => {
        this.roleChanged(val);
      });
    
    if (this.isCreateAction) {
      this.setPasswordValidators();
    }
  }

  initializeUserForm(user: User): void {
    this.userForm.patchValue({
      name: user.name,
      last_name: user.last_name,
      email: user.email,
      role_id: user.roles[0].id,
      cell_phone_number: user.cell_phone_number,
      address: user.address,
      assigned_tools: user.assigned_tools,
      troop_id: user.troops.length > 0 ? user.troops[0].id : null,
      image_url: user.image_url
    });
    this.user_troops = user.troops;
    this.roleChanged(this.userForm.value.role_id);
  }

  performFormAction(): void {
    this.parent.performAction(this.userForm);
  }
  
  updatePassword():void {
    this.parent.updatePassword(this.passwordForm);
  }

  setEditableUser(user: User): void {
    this.editableUser = user;
  }

  setImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.userForm.patchValue({ image_url: reader.result })
        this.userForm.patchValue({ image: file })
      };

      reader.readAsDataURL(file);
    }
  }

  setPasswordValidators(): void {
    if(!this.isCreateAction)
      return;
    this.userForm.get('password').setValidators([Validators.required]);
    this.userForm.get('password').updateValueAndValidity();

    this.userForm.get('password_confirmation').setValidators([Validators.required]);
    this.userForm.get('password_confirmation').updateValueAndValidity();
  }

  updatePasswordValidators(): void {
    if (!this.isCreateAction && this.userForm.get('password').value != '') {
      this.setPasswordValidators();
    }
  }

  private getRoles(): void {
    this.userFormService
      .getRoles()
      .subscribe(
        (getRolesResponse: GetRolesResponse) => {
          this.roles = getRolesResponse.roles.map(role => new Role(role));

          if (this.editableUser) this.roleChanged(this.editableUser.roles[0].id);
        },
        (error) => {
          this.toastr.error(error);
        }
      );
  }

  getTroops(): void {
    this.userFormService
      .getTroops()
      .subscribe(
        (getTroopsResponse: GetTroopsResponse) => {
          this.troops = getTroopsResponse.troops;

          if (this.editableUser && this.editableUser.troops.length) this.userForm.patchValue({ troop_id: this.editableUser.troops[0].id });
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }
  
  addTroop(){
    if(this.userForm.get('troop_id').value == null)
      return
    let troop = this.troops.find( _troop => _troop.id == this.userForm.get('troop_id').value );
    if(!this.user_troops.find( _troop => _troop.id == troop.id))
      this.user_troops.push(troop);
  }

  onRemove(troop: Troop): void {
    this.user_troops = this.user_troops.filter( _troop => _troop.id != troop.id );
  }

  private roleChanged(role_id: string): void {
    const selectedRole: Role = this.roles.find((role) => { return role.id === role_id });
    this.userForm.patchValue({ troop_id: null });

    if (selectedRole && selectedRole.enumValue === RoleEnum["Oficial de cuadrilla"]) {
      this.isTroopRole = true;
      this.showTroops = false;
      this.user_troops = [];
      this.userForm.get('troop_id').setValidators([Validators.required]);
      this.userForm.get('troop_id').updateValueAndValidity();
    } else if (selectedRole && selectedRole.enumValue === RoleEnum["Supervisor de cuadrilla"]) {
      this.showTroops = true;
      this.isTroopRole = false;
      this.userForm.get('troop_id').setValidators([Validators.required]);
      this.userForm.get('troop_id').updateValueAndValidity();
    } else {
      this.showTroops = false;
      this.isTroopRole = false;
      this.user_troops = [];
      this.userForm.get('troop_id').clearValidators();
      this.userForm.get('troop_id').updateValueAndValidity();
    }
  }
}