import { Component, OnInit, ViewChild, Pipe } from '@angular/core';
import { AlertService } from './alert.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GenericTable } from '@app/models/generic-table';
import { Alerts } from './alert';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsTypeService } from '../alerts-type/alerts-type.service';
import { ListAlertsType } from '../alerts-type/alerts-type';
import { splitNsName } from '@angular/compiler';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @ViewChild('UpdateAlertModal') UpdateAlertModal: any;

  alertsSearchForm: FormGroup;
  updateAlert: FormGroup;
  alerts: GenericTable<Alerts>;
  ListAlertsType: ListAlertsType[];
  ListAlertsTypeComplete: ListAlertsType[];
  dropDownAlertTypes: [string] = ['Todos'];

  constructor(
    private alertTypeService: AlertsTypeService,
    private alertService: AlertService,
    private toastr: Toastr,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {
    this.alertsSearchForm = fb.group({
      code: '',
      rpu: '',
      type_alert: 'Todos',
      priority: -1,
      resolved: -1,
      month: 0,
      year: 0
    });
  }

  ngOnInit() {
    this.paginatedTable(0);
    this.updateAlert = this.fb.group({
      id: [null],
      resolved: [null],
      comments: [null],
      updated: [null],
      resolved_by: [null]
    })
  }

  paginatedTable(page: number) {
    this.alertService.getAlerts(this.alertsSearchForm.getRawValue(), page).subscribe(response => {
      this.alerts = response;
      let name = '';
      console.log(this.alerts.elements, 'elementos');
      this.alerts.elements.forEach(elements => {
        name = elements.type_alert;
        if (this.dropDownAlertTypes.filter(x => x === name).length === 0) {
          this.dropDownAlertTypes.push(elements.type_alert);
        }
      });
    });
  }


  selectedTypeAlertChanged() {
    const alert = this.alertsSearchForm.controls['type_alert'];
    if (alert.value !== '') {
      const alertselect = this.ListAlertsType.find(x => x.name === alert.value);
      this.alertsSearchForm.patchValue({ priority: alertselect.priority });
      this.alertsSearchForm.controls['priority'].disable();
    } else {
      this.alertsSearchForm.controls['priority'].enable();
    }
  }
  selectedPriorityAlertChanged() {
    let alertsSelected: ListAlertsType[] = [];
    const priority = this.alertsSearchForm.controls['priority'];
    if (priority.value !== 'null') {
      this.ListAlertsTypeComplete.forEach(function (alert) {
        if (alert.priority === priority.value) {
          alertsSelected.push(JSON.parse(JSON.stringify(alert)))
        }
      });
    } else {
      alertsSelected = JSON.parse(JSON.stringify(this.ListAlertsTypeComplete));
    }
    this.ListAlertsType = JSON.parse(JSON.stringify(alertsSelected));
  }

  onClose() {
    this.modalService.dismissAll();
  }

  onEdit(alert: Alerts) {
    console.log(alert);
    let date = '';
    if (alert.updated != null) {
      const year = alert.updated.toString().substr(0, 4);
      const month = alert.updated.toString().substr(4, 4);
      const day = alert.updated.toString().substr(8, 2);
      date = day + month + year;
    }
    this.updateAlert.patchValue({
      id: alert.id,
      resolved: alert.resolved,
      comments: alert.comments,
      updated: date,
      resolved_by: alert.resolved_by
    });
    this.modalService.open(this.UpdateAlertModal, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  pageChange(page: number) {
    this.paginatedTable(page - 1);
  }

  updateAlerts() {
    this.alertService.UpdateAlerts(this.updateAlert.getRawValue())
      .subscribe(response => {
        this.paginatedTable(0);
        this.toastr.success('Se actualizo correctamente.');
      }, error => {
        this.paginatedTable(0);
        this.toastr.error(error);
      });
    this.modalService.dismissAll();
  }

}
