import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { TicketsService } from '@pages/call-center/tickets/tickets.service';
import { CitizenPayload } from 'app/models/citizen-payload';
import { ProblemEnum } from 'app/models/problem-enum';
import { ResponsiblePartyEnum } from 'app/models/responsible_party-enum';
import { SourceEnum } from 'app/models/source-enum';
import { TicketInformation, Repairs } from './ticket';
import { AuthService } from '@shared/auth/auth.service';

@Component({
  selector: 'app-edit-ticket',
  templateUrl: './edit-ticket.component.html',
  styleUrls: ['./edit-ticket.component.scss']
})
export class EditTicketComponent implements OnInit {
  problemEnum = Object.keys(ProblemEnum).filter(key => !isNaN(Number(ProblemEnum[key])));
  responsiblePartyEnum = Object.keys(ResponsiblePartyEnum).filter(key => !isNaN(Number(ResponsiblePartyEnum[key])));
  sourceEnum = Object.keys(SourceEnum).filter(key => !isNaN(Number(SourceEnum[key])));
  showMap: boolean;
  isClient : Boolean;
  // ticket: Ticket;
  ticketForm: FormGroup;
  ticketInformation: TicketInformation;


  constructor(
    private authService: AuthService,
    private ticketsService: TicketsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: Toastr,
    private router: Router,
  ) {
    this.isClient = this.authService.isClient();
    this.showMap = false;
    this.ticketForm = fb.group({
      citizen_id: [''],
      name: ['', Validators.required],
      first_last_name: ['', Validators.required],
      second_last_name: [''],
      email: [''],
      phone_number: [''],
      comment: [{ value: '', disabled: true }],
      source: [''],
      problem: [{ value: '', disabled: true }],
      specification: [{ value: '', disabled: true }],
      responsible_party: [{ value: '', disabled: true }]
    });
  }

  ngOnInit() {
    // this.route.paramMap.subscribe(params => {
    //   const currentID = params.get('id');
    //   this.getTicketById(currentID);
    // });

    this.route.paramMap.subscribe(params => {
      const ticket_id = params.get('id');
      this.getInformationTicket(ticket_id);
    });
  }

  getInformationTicket(ticket_id) {
    this.ticketsService.getTicketById(ticket_id).subscribe(response => {
      this.ticketInformation = new TicketInformation(response);
      this.ticketInformation.repairs = response.repairs.map(lums => new Repairs(lums));
      this.assingValuesFormGroup(response);
    }, error => {
      this.toastr.error(error);
    });
  }

  private assingValuesFormGroup(ticketInformation: TicketInformation) {
    this.ticketForm.patchValue({
      source: ticketInformation.source,
      problem: ticketInformation.problem,
      specification: ticketInformation.specification,
      responsible_party: ticketInformation.responsible_party
    });
    if (ticketInformation.source === 'Generado desde iPad') {
      this.ticketForm.patchValue({
        name: ticketInformation.user.name,
        first_last_name: ticketInformation.user.last_name,
        email: ticketInformation.user.email
      });
      this.ticketForm.get('name').disable();
      this.ticketForm.get('first_last_name').disable();
      this.ticketForm.get('email').disable();
    } else {
      this.ticketForm.patchValue({
        citizen_id: ticketInformation.citizen.id,
        name: ticketInformation.citizen.name,
        first_last_name: ticketInformation.citizen.first_last_name,
        second_last_name: ticketInformation.citizen.second_last_name,
        email: ticketInformation.citizen.email,
        phone_number: ticketInformation.citizen.phone_number,
        comment: ticketInformation.comment
      });
    }

    if(this.isClient){
      this.ticketForm.get('name').disable();
      this.ticketForm.get('first_last_name').disable();
      this.ticketForm.get('second_last_name').disable();
      this.ticketForm.get('email').disable();
      this.ticketForm.get('phone_number').disable();
    }
  }

































  // getTicketById(id: string) {
  //   this.ticketsService.getTicketByIdOff(id).subscribe(response => {
  //     console.log(response);
  //     this.ticket = new Ticket(response.ticket);
  //     this.populateForm();
  //   }, error => {
  //     this.toastr.error(error);
  //   });
  // }

  performFormAction(): void {
    if (this.ticketForm.valid) {
      if (!this.ticketForm.get('email').value && !this.ticketForm.get('phone_number').value) {
        this.toastr.singleError('Operación fallida. Revisar campos requeridos (Correo ó Whatsapp)');
        return;
      }
      // const requestPayload = new CitizenPayload(this.ticketForm);
      const update = {
        citizen_id: this.ticketForm.value.citizen_id,
        email: this.ticketForm.value.email,
        first_last_name: this.ticketForm.value.first_last_name,
        name: this.ticketForm.value.name,
        second_last_name: this.ticketForm.value.second_last_name,
        phone_number: this.ticketForm.value.phone_number
      }
      this.ticketsService.editTicket_(update).subscribe(() => {
        this.toastr.success('Ciudadano Actualizado correctamente');
      }, (error) => {
        this.toastr.error(error);
      });
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  // private populateForm() {
  //   const ticket = this.ticket;
  //   this.ticketForm.patchValue({
  //     source: ticket.source,
  //     problem: ticket.problem,
  //     specification: ticket.specification,
  //     responsible_party: ticket.responsible_party
  //   });
  //   if (ticket.source === 7) {
  //     this.ticketForm.patchValue({
  //       name: ticket.user.name,
  //       first_last_name: ticket.user.last_name,
  //       email: ticket.user.email
  //     });
  //     this.ticketForm.get('name').disable();
  //     this.ticketForm.get('first_last_name').disable();
  //     this.ticketForm.get('email').disable();
  //   } else {
  //     this.ticketForm.patchValue({
  //       citizen_id: ticket.citizen.id,
  //       name: ticket.citizen.name,
  //       first_last_name: ticket.citizen.first_last_name,
  //       second_last_name: ticket.citizen.second_last_name,
  //       email: ticket.citizen.email,
  //       phone_number: ticket.citizen.phone_number,
  //       comment: ticket.comment
  //     });
  //   }
  // }

  getClass(value: number) {
    const classes = {
      'oe__status-0': value === 0,
      'oe__status-1': value === 1,
      'oe__status-2': value === 2,
      'oe__status-3': value === 3,
      'oe__status-4': value === 4
    }
    return classes
  }
}
