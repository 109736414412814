import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import {
  FormBuilder, FormGroup, Validators, FormControl
} from '@angular/forms';
import { } from 'googlemaps';
import { DeleteMenu } from '@shared/components/map-delete-node.component';
import { Toastr } from '@shared/toastr/toastr.service';
import * as Driver from 'driver.js';
import { NewQuickTaskService } from './new-quick-task.service';
import { TaskService } from '@pages/tasks/task/task.service';
import { NewQuickTaskPayload } from '@models/new-quick-task-payload';
import { NewQuickTaskResponse } from '@models/new-quick-task-response';
import * as MomentTz from 'moment-timezone';
import { StyleMap } from '@models/styleMap';
import { Trooper } from '@models/trooper';
import { CurrentProjectService } from '@shared/cookies/current-project.service';
import { TroopsSurveyArea, UpdateNewQuickSurveyArea } from './QuickSurveyArea';
import { Console } from 'console';
import { SurveyArea } from '@app/models/survey-area';
// import { SurveyArea } from '@models/survey-area';

@Component({
  selector: 'new-quick-task-component',
  templateUrl: './new-quick-task.component.html',
  providers: [NewQuickTaskService, TaskService]
})

// TODO: Move google maps to a different component.
export class NewQuickTaskComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;
  driver: Driver = new Driver({
    animate: true,
    allowClose: false,
    keyboardControl: false
  });
  editingPolygon: google.maps.Polygon;
  editMode = '';

  readonly mapProperties = {
    center: new google.maps.LatLng(25.6729784, -100.3779675),
    zoom: 15,
    mapTypeId: google.maps.MapTypeId.ROADMAP
  };
  // TODO: Create task enum
  newQuickTaskForm: FormGroup = this.fromBuilder.group({
    type: [0],
    sector: [null, Validators.required],
    deadline: [null],
    user_id: [null],
    survey_areas: this.fromBuilder.array([]),
    formattedDeadline: [null]
  });
  styleMap: StyleMap = new StyleMap();
  // troopSupervisors: Trooper[] = [];
  troopers: Trooper[] = [];
  // troopers_: TroopsSurveyArea[] = [];
  map: google.maps.Map;
  survey_areas = [];
  // survey_areas_ = [];
  polygons = [];
  disabledBtn = false;

  constructor(
    private fromBuilder: FormBuilder,
    private newQuickTaskService: NewQuickTaskService,
    private currentProjectService: CurrentProjectService,
    private taskService: TaskService,
    private toastr: Toastr,
    private router: Router
  ) { }

  // TODO: Prevent map pin drop.
  ngOnInit() {
    const project = this.currentProjectService.getCurrentProject();
    this.getTroopers();
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    // Google Maps thingy
    this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    });

    var drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: {
        editable: true,
        draggable: true,
        fillOpacity: 0.25
      }
    });

    /*Se quito la funcionalidad de agregar poligonos */
    google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
      let deleteMenu = new DeleteMenu();
      let polygonPath = polygon.getPath();
      this.addPointToForm(polygon);

      google.maps.event.addListener(polygon, 'rightclick', function (e) {
        // Check if click was on a vertex control point
        if (e.vertex == undefined) {
          return;
        }

        deleteMenu.open(this.map, polygon.getPath(), e.vertex);
      });

      google.maps.event.addListener(polygonPath, 'set_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'insert_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'remove_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygon, 'dragend', (_) => {
        this.addPointToForm(polygon);
      })
    });

    drawingManager.setMap(this.map);

    // this.addPolygon_();
    this.addPolygon();
  }


  // ngOnInit() {
  //   this.getTroopers_();
  //   this.getSurveyArea_();
  // }

  // private getTroopers_(): void {
  //   this.newQuickTaskService
  //     .getTroopers_()
  //     .subscribe(result => {
  //       this.troopers_ = result;
  //     },
  //       (error) => {
  //         this.toastr.error(error);
  //       }
  //     )
  // }


  // private getSurveyArea_() {
  //   this.newQuickTaskService.getSuveryAreas_().subscribe(res => {
  //     this.survey_areas_ = res;
  //     this.paintMap();
  //     this.addSurveyAreas_();
  //   });
  // }

  // private paintMap() {
  //   const project = this.currentProjectService.getCurrentProject();
  //   let latitude = 19.4326009;
  //   let longitude = -99.1333416;
  //   let zoom = 5;
  //   const request = {
  //     query: `${project.municipality}, ${project.state}, ${project.country}`,
  //     fields: ['name', 'geometry'],
  //   };

  //   let mapProperties = {
  //     center: new google.maps.LatLng(latitude, longitude),
  //     zoom: zoom,
  //     mapTypeId: google.maps.MapTypeId.ROADMAP
  //   };

  //   this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
  //   let service = new google.maps.places.PlacesService(this.map);
  //   service.findPlaceFromQuery(request, (results, status) => {
  //     if (status === google.maps.places.PlacesServiceStatus.OK) {
  //       this.map.setZoom(12);
  //       this.map.setCenter(results[0].geometry.location);
  //     }
  //   });
  // }

  // private addSurveyAreas_() {
  //   let polygonCoords = [];
  //   let deleteMenu = new DeleteMenu();
  //   for (let survey_area of this.survey_areas_) {
  //     if (!survey_area.points.length)
  //       continue;
  //     polygonCoords = survey_area.points.map(point => new google.maps.LatLng(point.latitude, point.longitude));
  //     let myPolygon = new google.maps.Polygon({
  //       paths: polygonCoords,
  //       strokeColor: '#4885E6',
  //       strokeOpacity: 0.8,
  //       fillColor: '#4885E1',
  //       fillOpacity: 0.25,
  //       map: this.map
  //     });
  //     myPolygon.addListener('click', (event) => { this.clickPoligon(myPolygon) });
  //     myPolygon.getPath().addListener('set_at', (event) => { this.changePolygon(myPolygon, survey_area) });
  //     myPolygon.getPath().addListener('insert_at', (event) => { this.changePolygon(myPolygon, survey_area) });
  //     myPolygon.getPath().addListener('remove_at', (event) => { this.changePolygon(myPolygon, survey_area) });
  //     polygonCoords = [];
  //     myPolygon.addListener('rightclick', function (e) {
  //       if (e.vertex == undefined)
  //         return;
  //       deleteMenu.open(this.map, myPolygon.getPath(), e.vertex);
  //     });
  //   }
  // }

  private changePolygon(polygon: google.maps.Polygon, survey_area: UpdateNewQuickSurveyArea) {
    const survey = new SurveyArea(polygon);
    survey.id = survey_area.id;
    this.newQuickTaskService.updateaPolygon(survey).subscribe(res => {
      this.toastr.success('Coordenadas actualizadas correctamente');
    }, error => {
      this.toastr.error(error);
    });

    // const array = [];
    // const len = polygon.getPath().getLength();
    // // console.log(this.survey_areas_);
    // for (let i = 0; i < len; i++) {
    //   array.push({
    //     latitude: polygon.getPath().getAt(i).lat(),
    //     longitude: polygon.getPath().getAt(i).lng()
    //   })
    //   // array.push(polygon.getPath().getAt(i).toUrlValue(5));
    // }
    // console.log(array);
    // survey_area.points = array;
    // console.log(survey_area, 'VAlores nuevos');
    // this.newQuickTaskService.updateaPolygon(survey_area).subscribe(res => {
    //   this.toastr.success('Coordenadas actualizadas correctamente');
    // }, error => {
    //   this.toastr.error(error);
    // });

    // const requestPayload = new SurveyAreaPayload(survey);
    // this.taskService
    //   .updateSurveyAreas(requestPayload)
    //   .subscribe(
    //     (response: any) => {
    //       this.toastr.success('Coordenadas actualizadas.');
    //     },
    //     (error) => {
    //       this.toastr.error(error);
    //     }
    //   )
  }




























  clickEditMap(): void {
    if (this.editMode === '') {
      this.driver.highlight('#mapLuminaires');
      this.setCenterMap();
      this.editMode = 'edit';
    } else {
      this.driver.reset();
      this.editMode = '';
      if (this.editingPolygon) {
        console.log('editing polygon');
        this.editingPolygon.setEditable(false);
      }
    }
  }



  createQuickTask() {
    if (this.newQuickTaskForm.valid) {
      if (this.newQuickTaskForm.get("deadline").value) {
        const formDate = this.newQuickTaskForm.get("deadline").value;
        const date = MomentTz(`${formDate.year}-${formDate.month}-${formDate.day}`).format();
        this.newQuickTaskForm.patchValue({ formattedDeadline: date });
      }
      const requestPayload = new NewQuickTaskPayload(this.newQuickTaskForm);
      // console.log(requestPayload);
      this.disabledBtn = true;
      this.newQuickTaskService
        .createQuickTask(requestPayload)
        .subscribe(
          (newQuickTaskResponse: NewQuickTaskResponse) => {
            this.toastr.success('Tarea creada.');
            this.router.navigate(['/u/tasks']);
            this.disabledBtn = false;
          },
          (error: any) => {
            this.toastr.error(error);
            this.disabledBtn = false;
          }
        );
    } else {
      this.newQuickTaskForm.controls.sector.markAsTouched();
      this.newQuickTaskForm.controls.deadline.markAsTouched();
      this.newQuickTaskForm.controls.user_id.markAsTouched();
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  //// modificacion de metodo para consumo de servicio migrado ----
  // createQuickTask_() {
  //   if (this.newQuickTaskForm.valid) {
  //     if (this.newQuickTaskForm.get("deadline").value) {
  //       const formDate = this.newQuickTaskForm.get("deadline").value;
  //       const date = MomentTz(`${formDate.year}-${formDate.month}-${formDate.day}`).format();
  //       this.newQuickTaskForm.patchValue({ formattedDeadline: date });
  //     }
  //     const requestPayload = new NewQuickTaskPayload(this.newQuickTaskForm);
  //     this.disabledBtn = true;
  //     this.newQuickTaskService.createQuickTask_(requestPayload).subscribe(res => {
  //       this.toastr.success('Tarea creada.');
  //       this.router.navigate(['/u/tasks']);
  //       this.disabledBtn = false;
  //     },
  //       (error: any) => {
  //         this.toastr.error(error);
  //         this.disabledBtn = false;
  //       }
  //     );
  //   } else {
  //     this.newQuickTaskForm.controls.sector.markAsTouched();
  //     this.newQuickTaskForm.controls.deadline.markAsTouched();
  //     this.newQuickTaskForm.controls.user_id.markAsTouched();
  //     this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
  //   }
  // }

  private addPointToForm(polygon: any) {
    if (this.isExistingPolygon(polygon)) {
      this.replacePolygon(polygon)
    } else {
      this.newQuickTaskForm.get('survey_areas').value.push(polygon);
    }
  }
  // Metodo antiguo
  private getTroopers(): void {
    this.newQuickTaskService
      .getTroopers()
      .subscribe(
        (getTroopSupervisorsResponse: Trooper[]) => {
          console.log(getTroopSupervisorsResponse);
          this.troopers = getTroopSupervisorsResponse.filter(x => x.is_active == true);
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  setBtnPoligonosClasses() {
    let classes = {
      'btn-primary': this.polygons.length ? this.polygons[0].getMap() : false,
      'oe--btn--text-white': this.polygons.length ? this.polygons[0].getMap() : false,
      'oe--btn--border': this.polygons.length ? !this.polygons[0].getMap() : true
    }
    return classes
  }

  changePolyg() {
    if (this.polygons.length && this.polygons[0].getMap()) {
      this.setMaptoPolygon(null);
    } else {
      this.setMaptoPolygon(this.map);
    }
  }

  private isExistingPolygon(polygon: any): boolean {
    let polygons = this.newQuickTaskForm.get('survey_areas').value;
    let existingPolygon = polygons.find(_polygon => _polygon.zIndex === polygon.zIndex);

    return existingPolygon ? true : false;
  }

  private replacePolygon(polygon: any): void {
    let polygons = this.newQuickTaskForm.get('survey_areas').value;
    let existingPolygonIndex = polygons.findIndex(_polygon => _polygon.zIndex === polygon.zIndex);

    polygons[existingPolygonIndex] = polygon;
  }




  private addPolygon() {
    this.taskService.getSuveryAreas().subscribe(surveyAreaResponse => {
      this.survey_areas = surveyAreaResponse.survey_areas;
      // console.log(surveyAreaResponse.survey_areas, 'valores no migrados');
      let polygonCoords = [];
      for (let survey_area of this.survey_areas) {
        polygonCoords = survey_area.points.map(point => new google.maps.LatLng(point.latitude, point.longitude));
        let myPolygon = new google.maps.Polygon({
          paths: polygonCoords,
          strokeColor: '#4885E6',
          strokeOpacity: 0.8,
          fillColor: '#4885E1',
          fillOpacity: 0.25
        });
        this.polygons.push(myPolygon);
        polygonCoords = [];
      }
      this.setMaptoPolygon(this.map);
    });
  }









  // private addPolygon_() {
  //   this.newQuickTaskService.getSuveryAreas_().subscribe(surveyAreaResponse => {
  //     this.survey_areas_ = surveyAreaResponse;
  //     const deleteMenu = new DeleteMenu();
  //     let polygonCoords = [];
  //     for (const survey_area of this.survey_areas_) {
  //       polygonCoords = survey_area.points.map(point => new google.maps.LatLng(point.latitude, point.longitude));
  //       const myPolygon = new google.maps.Polygon({
  //         paths: polygonCoords,
  //         strokeColor: '#4885E6',
  //         strokeOpacity: 0.8,
  //         fillColor: '#4885E1',
  //         fillOpacity: 0.25
  //       });
  //       myPolygon.addListener('click', (event) => { this.clickPoligon(myPolygon) });
  //       myPolygon.getPath().addListener('set_at', (event) => { this.changePolygon(myPolygon, survey_area) });
  //       // myPolygon.getPath().addListener('insert_at', (event) => { this.changePolygon(myPolygon, survey_area) });
  //       // myPolygon.getPath().addListener('remove_at', (event) => { this.changePolygon(myPolygon, survey_area) });
  //       // polygonCoords = [];
  //       myPolygon.addListener('rightclick', function (e) {
  //         if (e.vertex === undefined)
  //           return;
  //         deleteMenu.open(this.map, myPolygon.getPath(), e.vertex);
  //       });

  //       this.polygons.push(myPolygon);
  //       polygonCoords = [];
  //     }
  //     this.setMaptoPolygon(this.map);
  //   });
  // }



  private clickPoligon(polygon: google.maps.Polygon) {
    if (this.editMode == '')
      return;
    if (this.editingPolygon == polygon) {
      polygon.setEditable(false);
      this.editingPolygon = null;
    } else if (this.editingPolygon) {
      polygon.setEditable(true);
      this.editingPolygon.setEditable(false);
      this.editingPolygon = polygon;
    } else {
      polygon.setEditable(true);
      this.editingPolygon = polygon;
    }
  }




  setMaptoPolygon(map: any): void {
    for (let i = 0; i < this.polygons.length; i++) {
      this.polygons[i].setMap(map);
    }
  }

  private setCenterMap() {
    setTimeout(() => {
      const element = document.getElementById('driver-highlighted-element-stage');
      element.scrollIntoView(false);
      element.scrollIntoView({ block: 'center' });
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, 0);
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }
}
