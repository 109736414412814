import { Material } from "./material";
import { Meter } from "./meter";
import { Installation } from "./installation";
import { RepairTask } from "./repair-task";
import { LightTask } from "./light-task";
import { Technology } from "./technology";
import { LuminaireEnum } from './luminaire-enum';

export interface ProjectWattageInterface {
  id: string,
  marker_color: MarkerColor,
  power: number,
  optic: string,
  material_cat_id: string,
  isChecked: boolean,
  material: Material
}

export class ProjectWattage implements ProjectWattageInterface {
  id: string;
  marker_color: MarkerColor;
  power: number;
  optic: string;
  material_cat_id: string;
  isChecked: boolean;
  material: Material;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.marker_color = new MarkerColor(attrs.marker_color);
    this.material_cat_id = attrs.material_cat_id;
    this.power = attrs.power;
    this.optic = attrs.optic;
    this.isChecked = attrs.isChecked;
    this.material = new Material(attrs.material);
  }
}

export class MarkerColor {
  id: string;
  name: string;
  path: string;
  path2: string;
  fill_color: string;
  fill_color2: string;
  stroke: number;
  is_check: boolean;
  is_hover: boolean;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.path = attrs.path;
    this.path2 = attrs.path2;
    this.fill_color = attrs.fill_color;
    this.fill_color2 = attrs.fill_color2;
    this.stroke = attrs.stroke;
    this.is_check = false;
    this.is_hover = false;
  }

  readonly paths = [
    'assets/img/svg/ic_install_pin-',
    'assets/img/svg/ic_install_pin-'
  ]

  get svgIcon() {
    return `assets/img/svg/ic_install_pin-${this.name}.svg`;
  }
}

export class LuminaireProjectWattage {
  id: string;
  reference_id: string;
  polygonID: number;
  location: Location;
  project_wattage: ProjectWattage;
  linkedSituation: string;
  event: string;
  isSelected: Boolean;
  marker: google.maps.Marker;
  installation: Installation;
  is_in_task: boolean;
  most_recent_installation_task: LightTask;

  constructor(attrs: any, linkedSituation: string = 'single', event: string = 'normal') {
    this.id = attrs.id;
    this.reference_id = attrs.reference_id == null ? 'Sin Referencia' : attrs.reference_id.toString();
    this.location = new Location(attrs.location);
    this.project_wattage = attrs.project_wattage ? new ProjectWattage(attrs.project_wattage) : null;
    this.linkedSituation = attrs.project_wattage ? 'assign' : linkedSituation;
    this.event = event;
    this.isSelected = false;
    this.installation = attrs.installation ? new Installation(attrs.installation) : null;
    this.is_in_task = attrs.is_in_task;
    this.most_recent_installation_task = attrs.most_recent_installation_task ? new LightTask(attrs.most_recent_installation_task) : null;
  }

  readonly markerIcons = {
    linked: {
      hover: 'assets/img/svg/ic_link_hover.svg',
      normal: 'assets/img/svg/link.svg',
      selected: 'assets/img/svg/lam_selected.svg',
      circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
    },
    single: {
      normal: 'assets/img/svg/normal.svg',
      selected: 'assets/img/svg/selected-blue.svg'
    },
    assign: {
      selected: 'assets/img/svg/ic_install_selected-',
      circuitShowing: 'assets/img/svg/ic_install_linked-',
      normal: 'assets/img/svg/ic_install_pin-',
      hover: 'assets/img/svg/ic_install_hover-',
    },
    citizen: {
      normal: 'assets/img/svg/ic_single_normal.svg',
      selected: 'assets/img/svg/link_selected.svg'
    }
  }

  get_icon(): object {
    return this.linkedSituation == 'assign'
      ?
      {
        url: `${this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.project_wattage.marker_color.name}.svg`,
        scaledSize: new google.maps.Size(15, 15)
      }
      :
      {
        url: this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
      };
  }

  upadeIcon(event: string): void {
    this.event = event;
    this.marker.setIcon(this.get_icon());
  }

  paintMarker(map: any): void {
    let latitude = this.location.latitude;
    let longitude = this.location.longitude;
    this.event = this.isSelected ? 'selected' : 'normal';
    this.marker = new google.maps.Marker({
      title: this.reference_id,
      position: new google.maps.LatLng(latitude, longitude),
      icon: this.get_icon(),
      map: map
    });
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }

  setMap(map: any): void {
    this.marker.setMap(map);
  }
}

export class LamppostProjectWattage {
  id: string;
  has_circuit: boolean;
  luminaires: LuminaireProjectWattage[];
  event: string;

  constructor(attrs) {
    this.id = attrs.id;
    this.has_circuit = attrs.has_circuit;
    this.luminaires = attrs.luminaires != null ? attrs.luminaires.map((luminaire: LuminaireProjectWattage) => new LuminaireProjectWattage(luminaire, 'linked')) : [];
  }

  upadeIcon(event: string): void {
    this.event = event;
    for (let luminaire of this.luminaires) {
      let event = this.event == 'normal' ? luminaire.isSelected ? 'selected' : 'normal' : this.event;
      luminaire.upadeIcon(event);
    }
  }

  hideLamppost(): void {
    for (let luminaire of this.luminaires) {
      luminaire.hideMarker();
    }
  }

  setMap(map: any): void {
    for (let luminaire of this.luminaires) {
      luminaire.setMap(map);
    }
  }
}

export class CircuitProjectWattage {
  id: string;
  identifier: string;
  meter: Meter;
  lampposts: LamppostProjectWattage[];
  marker: google.maps.Marker;
  event: string;
  isSelected: boolean;
  installation: Installation;
  polygonID: number;
  isCollapsed: boolean;
  project_wattage: ProjectWattage;
  most_recent_installation_task: LightTask;

  readonly markerIcons = {
    normal: 'assets/img/svg/circuit_normal.svg',
    selected: 'assets/img/svg/circuit_selected.svg',
    circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
  }

  constructor(attrs: any) {
    this.id = attrs.id;
    this.identifier = attrs.identifier;
    this.meter = attrs.meter != null ? new Meter(attrs.meter) : null;
    this.lampposts = attrs.lampposts != null ? attrs.lampposts.map((lamppost: LamppostProjectWattage) => new LamppostProjectWattage(lamppost)) : [];
    this.installation = attrs.installation ? new Installation(attrs.installation) : null;
    this.isCollapsed = false;
    this.project_wattage = attrs.project_wattage ? new ProjectWattage(attrs.project_wattage) : null;
    this.most_recent_installation_task = attrs.most_recent_installation_task ? new LightTask(attrs.most_recent_installation_task) : null;
  }

  get_icon(): object {
    return {
      url: this.markerIcons[this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
    };
  }

  upadeIcon(event: string, continueWithAll: boolean = true): void {
    this.event = event;
    this.marker.setIcon(this.get_icon());
    if (continueWithAll) {
      for (let lamppost of this.lampposts) {
        lamppost.upadeIcon(event);
      }
    }
  }

  selected() {
    this.event = 'selected';
    this.isSelected = true;
    this.marker.setIcon(this.get_icon());
    for (let lamppost of this.lampposts) {
      lamppost.upadeIcon('normal');
    }
  }
  selectedALLLum() {
    for (let lamppost of this.lampposts) {
      for (let luminaire of lamppost.luminaires) {
        luminaire.isSelected = true;
      }
      lamppost.upadeIcon('selected');
    }
  }

  desSelected() {
    this.event = 'normal';
    this.isSelected = false;
    this.marker.setIcon(this.get_icon());
    for (let lamppost of this.lampposts) {
      lamppost.upadeIcon('normal');
    }
  }

  desSelectedAllLum() {
    for (let lamppost of this.lampposts) {
      for (let luminaire of lamppost.luminaires) {
        luminaire.isSelected = false;
      }
      lamppost.upadeIcon('normal');
    }
  }


  paintMarker(map: any): void {
    let latitude = this.meter.location.latitude;
    let longitude = this.meter.location.longitude;
    this.event = this.isSelected ? 'selected' : 'normal';
    this.marker = new google.maps.Marker({
      title: this.identifier,
      position: new google.maps.LatLng(latitude, longitude),
      icon: this.get_icon(),
      map: map
    });
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }
  setMap(map: any): void {
    this.marker.setMap(map);
    for (let lamppost of this.lampposts) {
      lamppost.setMap(map);
    }
  }
}

interface LocationInterface {
  latitude: number;
  longitude: number;
}
export class Location implements LocationInterface {
  latitude: number;
  longitude: number;

  constructor(attrs: any) {
    this.latitude = attrs.latitude;
    this.longitude = attrs.longitude;
  }
}

export class LuminaireViewGeneral extends LuminaireProjectWattage {
  type: number;
  name: string;
  comment: string;
  technology: Technology;
  has_photocell: boolean;
  has_photocellString: string;
  tasks: LightTask[];
  reference_id: string;
  problems: number[];
  installation: Installation;
  status: string;
  location: Location
  event: string;
  linkedSituation: string;
  marker: google.maps.Marker;
  isSelected: Boolean;
  project_wattage: ProjectWattage;
  is_in_task: boolean;
  progress: number
  progressEnum: string
  progressIconEnum: string

  constructor(attrs: any, linkedSituation: string = 'single', event: string = 'normal') {
    super(attrs, linkedSituation, event);
    this.progress = attrs.progress;
    this.progressEnum = this.progress != null ? ProgressEnum[this.progress] : null;
    this.progressIconEnum = this.progress != null ? ProgressIconrEnum[this.progress] : null;
    this.type = attrs.type;
    this.name = LuminaireEnum[attrs.type];
    this.comment = attrs.comment == null ? '' : attrs.comment.trim();
    this.technology = new Technology(attrs.technology);
    this.has_photocell = attrs.has_photocell;
    this.has_photocellString = attrs.has_photocell ? 'Si' : 'No';
    this.tasks = attrs.tasks ? attrs.tasks.map((task: LightTask) => new LightTask(task)) : null;
    this.reference_id = attrs.reference_id;
    this.problems = attrs.problems;
    this.status = attrs.status;
    this.location = new Location(attrs.location);
  }

  get_icon(): object {
    return this.progress != null
      ?
      {
        url: `${this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.progressIconEnum}.svg`,
        scaledSize: new google.maps.Size(15, 15)
      }
      :
      {
        url: this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
      };
  }
}

export class LamppostViewGeneral extends LamppostProjectWattage {
  luminaires: LuminaireViewGeneral[];

  constructor(attrs) {
    super(attrs)
    this.luminaires = attrs.luminaires != null ? attrs.luminaires.map((luminaire: LuminaireViewGeneral) => new LuminaireViewGeneral(luminaire, 'linked')) : [];
  }

}

export class CircuitViewGeneral extends CircuitProjectWattage {
  id: string;
  identifier: string;
  meter: Meter;
  lampposts: LamppostViewGeneral[];
  marker: google.maps.Marker;
  event: string;
  isSelected: boolean;
  installation: Installation;
  polygonID: number;
  isCollapsed: boolean;
  project_wattage: ProjectWattage;
  most_recent_installation_task: LightTask;

  constructor(attrs: any) {
    super(attrs);
    this.lampposts = attrs.lampposts != null ? attrs.lampposts.map((lamppost: LamppostViewGeneral) => new LamppostViewGeneral(lamppost)) : [];
  }


}

export enum ProgressEnum {
  'Censo rápido',
  'Censo infraestructura',
  'Instalación',
}

enum ProgressIconrEnum {
  '458_C',
  '190_C',
  '139_C',
}