import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { NewQuickTaskPayload } from '@models/new-quick-task-payload';
import { NewQuickTaskResponse } from '@models/new-quick-task-response';
import { map } from 'rxjs/operators';
import { Trooper } from '@models/trooper';
import { GetTroopersResponse } from '@models/get-troopers-response';
import { NewQuickSurveyArea, TroopsSurveyArea, UpdateNewQuickSurveyArea } from './QuickSurveyArea';

@Injectable()

export class NewQuickTaskService {
  constructor(private httpClient: HttpClient) { }
  // servicio migrado
  createQuickTask_(newQuickTaskPayload: NewQuickTaskPayload) {
    console.log(newQuickTaskPayload, 'valores para crear nuevo poligono')
    return this.httpClient.post(
      `${environment.SERVER_TASKS}/api/SurveyArea/register-survey-areas`,
      newQuickTaskPayload
    );
  }

  // Servicio no Mirado
  createQuickTask(newQuickTaskPayload: NewQuickTaskPayload): Observable<NewQuickTaskResponse> {
    return this.httpClient.post<NewQuickTaskResponse>(
      `${environment.SERVER_URL}/tasks`,
      newQuickTaskPayload.toJson()
    );
  }


  // Servicio Migrado
  // getTroopers_() {
  //   return this.httpClient.get<TroopsSurveyArea[]>(
  //     `${environment.SERVER_TASKS}/api/SurveyArea/get-troops-survey-areas`);
  // }


  // servicio No Migrado
  getTroopers(): Observable<Trooper[]> {
    return this.httpClient
      .get<GetTroopersResponse>(`${environment.SERVER_URL}/troopers`)
      .pipe(
        map(data =>
           data.troopers.map(trooper =>
             new Trooper(trooper))));
  }

  getSuveryAreas_() {
    return this.httpClient.get<NewQuickSurveyArea[]>(
      `${environment.SERVER_TASKS}/api/SurveyArea/get-list-survey-areas`)
      .pipe(map(res => res.map(mp => new NewQuickSurveyArea(mp))));
  }

  updateaPolygon(update) {
    console.log(update, 'send values of the service');
    return this.httpClient.post(
      `${environment.SERVER_TASKS}/api/SurveyArea/update-survey-areas`, update);
  }

}
