import { TechnologyEnum } from './technology-enum';

interface TechnologyInterface {
  id: number,
  name: string
}

export class Technology implements TechnologyInterface {
  id: number;
  name: string;

  constructor(id: number) {
    this.id = id;
    this.name = TechnologyEnum[id];
  }
}