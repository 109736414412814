import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericTable } from '@app/models/generic-table';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { GuarantyReport } from './guaranty-report-model';
import { GuarantyReportService } from './guaranty-report.service';

@Component({
  selector: 'app-guaranty-report',
  templateUrl: './guaranty-report.component.html',
  styleUrls: ['./guaranty-report.component.scss']
})
export class GuarantyReportComponent implements OnInit {
  startDate;
  endDate;
  referenceReportNumber = '';
  pagination: GenericTable<GuarantyReport>;

  constructor(
    private guarantyReportService: GuarantyReportService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: Toastr,
  ) { }

  ngOnInit() {
    this.getElementsPaginate(0);
  }

  printSelected() {
    if (this.pagination !== undefined) {
      // tslint:disable-next-line:max-line-length
      const reports_selected = this.pagination.elements.filter(luminaire => luminaire.is_select === true);
      if (reports_selected.length > 0) {
        const values = [];
        reports_selected.forEach(report => {
          values.push(
             report.id
          );
        });
        this.guarantyReportService.downloadPDFGuarantyReport(values);
      } else {
        this.toastr.singleError('Se debe seleccionar al menos un registro habilitado para reporte');
        return;
      }



      // if (reports_photocell.length > 0) {
      //   const values_ = [];+
      //   reports_photocell.forEach(photocells => {
      //     values_.push(photocells.id_guaranty_claim);
      //   });
      //   this.guarantyService.downloadPhotocellsReport(values_);
      // }


    } else {
      this.toastr.singleError('No existen datos para generar un reporte');
      return;
    }

  }

  getElementsPaginate(page) {
    const filter = {
      guaranty_reference: this.referenceReportNumber,
      // tslint:disable-next-line:max-line-length
      startDate:  this.startDate === null || this.startDate === undefined ? '' : (this.startDate.month + '/' + this.startDate.day + '/' + this.startDate.year),
      endDate: this.endDate === null || this.endDate === undefined ? '' : (this.endDate.month + '/' + this.endDate.day + '/' + this.endDate.year),
      page: page
    }
    this.guarantyReportService.getGuarantyReport(filter).subscribe((response: GenericTable<GuarantyReport>) => {
      console.log(response);
      this.pagination = response;
    });

  }

  detailGuarantyReport(guarantyRport_id) {
    this.router.navigate([`/u/guaranties/guaranty-report/detail-guaranty-report/${guarantyRport_id}`], { relativeTo: this.route });
  }

  onAssingMaterial() {
  }

  pageChange(page: number) {
    this.getElementsPaginate(page - 1);
  }

}
