import { Component, OnInit } from '@angular/core';
import { Toastr } from '@shared/toastr/toastr.service'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Rol } from '@models/rol'
import { ProfileService } from './profile.service';
import { Profile } from '@models/profile';
import { ProfilePayload } from './profile-payload';
import { PasswordPayload } from '@models/password-payload'

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ProfileService]
})

export class ProfileComponent implements OnInit {
  profile: Profile = new Profile();
  hasPermissions: boolean = false;
  rol: Rol = new Rol();
  roles: Array<Rol> = [];
  isActive: boolean = true;
  isCurrentActive: boolean = true;
  profileForm: FormGroup = this.formBuilder.group(
    {
      name: [{ value: null, disabled: true }, Validators.required],
      last_name: [{ value: null, disabled: true }, Validators.required],
      email: [{ value: null, disabled: true }, [Validators.required, Validators.email]],
      image: [null],
      image_url: [null]
    }
  );

  passwordForm: FormGroup = this.formBuilder.group(
    {
      current_password: [null, Validators.required],
      new_password: [null, Validators.required]
    }
  );

  constructor(
    private profileService: ProfileService,
    private formBuilder: FormBuilder,
    private toastr: Toastr
    ) {}

  ngOnInit() {
    this.getProfile();
  }

  private getProfile(): void {
    this.profileService.getProfile().subscribe(
      (profileResponse) => {
        const profile = profileResponse.profile;

        this.profile = profile;
        this.checkPermissions();

        this.profileForm = this.formBuilder.group(
          {
            name: [this.profile.name, Validators.required],
            last_name: [this.profile.last_name, Validators.required],
            email: [this.profile.email, [Validators.required, Validators.email]],
            image: [null],
            image_url: [this.profile.image_url]
          }
        );
        for(let i in this.profile.roles){
          let desc = this.rol.getName(this.profile.roles[i]);
          this.profile.roles[i]["description"] = desc;
        }
      },
      (error) => {
        this.toastr.error(error);
      }
    )
  }

  SaveProfile(): void {
    if (this.profileForm.valid) {
      const requestPayload = new ProfilePayload(this.profileForm);
      this.profileService
        .updateProfile(requestPayload)
        .subscribe(
          (profilePayload: ProfilePayload) => {
            this.toastr.success('Perfil actualizado.');
          },
          (error) => {
            this.toastr.error(error);
          }
        )
    } else {
      this.profileForm.controls.name.markAsTouched();
      this.profileForm.controls.last_name.markAsTouched();
      this.profileForm.controls.email.markAsTouched();
      this.toastr.error(this.toastr.singleError('Operación fallida. Revisar campos requeridos.'));
    }
  }

  UpdatePassword():void {
    if(this.passwordForm.valid){
      const requestPayload = new PasswordPayload(this.passwordForm);
      this.profileService
        .updatePassword(requestPayload)
        .subscribe(
          (passwordPayload: PasswordPayload) => {
            this.cleanValidatorsPassword();
            this.toastr.success('Contraseña actualizada.');
          },
          (error) => {
            this.passwordForm.controls.current_password.markAsTouched();
            this.passwordForm.controls.new_password.markAsTouched();
            this.toastr.error(error);
          }
        );
    } else {
      this.toastr.error(this.toastr.singleError('Operación fallida. Revisar campos requeridos.'));
    }
  }

  cleanValidatorsPassword(): void {
    this.passwordForm = this.formBuilder.group(
      {
        current_password: [null, Validators.required],
        new_password: [null, Validators.required]
      }
    );
  }

  checkPermissions(): void{
    if(this.profile['roles'].length){
      if(this.profile['roles'][0]["name"] <= 2){
        this.hasPermissions = true;
      }
    }
  }

  setImagePreview(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.profileForm.patchValue({ image_url: reader.result })
        this.profileForm.patchValue({ image: file })
      };

      reader.readAsDataURL(file);
    }
  }
}
