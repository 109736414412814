import { Time } from "@angular/common";
import { GeneralViewLumWattage } from "@app/pages/general-view/general-view-project-wattages";

export interface IAddDevice {
  name: string,
  register_key: string,
  group_id: string,
  geenapsis_device_id: string,
}

export class AddDevice implements IAddDevice {

  name: string;
  register_key: string;
  group_id: string;
  geenapsis_device_id: string;

  constructor(attrs: any) {
    this.name = attrs.name;
    this.register_key = attrs.register_key;
    this.group_id = attrs.group_id;
    this.geenapsis_device_id = attrs.geenapsis_device_id;
  }
}


export interface ISchedule {
  scheduleOn: string;
  scheduleOff: string;
  hourOn: string;
  hourOff: string;
  calendarChartLabel: any[];
  calendarChartValue: any[];
  meterStatusValue: any[];
  calendarCurrentList: any[];
}

export class Schedule implements ISchedule {

  scheduleOn: string;
  scheduleOff: string;
  hourOn: string;
  hourOff: string;
  calendarChartLabel: any[];
  calendarChartValue: any[];
  meterStatusValue: any[];
  calendarCurrentList: any[];


  constructor(attrs: any) {
    this.scheduleOn = attrs.scheduleOn;
    this.scheduleOff = attrs.scheduleOff;
    this.hourOn = attrs.hourOn;
    this.hourOff = attrs.hourOff;
    this.calendarChartLabel = attrs.calendarChartLabel;
    this.calendarChartValue = attrs.calendarChartValue;
    this.meterStatusValue = attrs.meterStatusValue;
    this.calendarCurrentList = attrs.calendarCurrentList;
  }
}

export interface ISim {
  total_volume: string;
  volume: string;
  simUsageList: [];
}

export class Sim implements ISim {

  total_volume: string;
  volume: string;
  simUsageList: [];

  constructor(attrs: any) {
    this.total_volume = attrs.total_volume;
    this.volume = attrs.volume;
    this.simUsageList = attrs.simUsageList;
  }
}

export interface IGetDevices {
  id: string,
  curcuitName: string,
  deviceName: string,
  zona: number,
  group: string,
  rssi: string,
  current: number,
  volts: number,
  consumoEstimado: number,
  consumoMensual: number,
  burnHours: number,
  on_off: string,
  lastRecordDate: Date,
  status: number,
  location: {
    latitude: number,
    longitude: number
  }
}

export class GetDevices implements IGetDevices {
  id: string;
  curcuitName: string;
  deviceName: string;
  zona: number;
  group: string;
  rssi: string;
  current: number;
  volts: number;
  consumoEstimado: number;
  consumoMensual: number;
  burnHours: number;
  on_off: string;
  status: number;
  event: string;
  lastRecordDate: Date;
  marker: google.maps.Marker;
  isSelected: Boolean;
  linkedSituation: string;
  location: {
    latitude: number,
    longitude: number
  }

  readonly markerIcons = {
    single: {
      normal: 'assets/img/svg/circuit_normal.svg',
      selected: 'assets/img/svg/circuit_selected.svg',
      circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
    }
  }

  constructor(attrs: any, event: string = 'normal', linkedSituation: string = 'single') {
    this.id = attrs.id;
    this.curcuitName = attrs.curcuitName;
    this.deviceName = attrs.deviceName;
    this.zona = attrs.zona;
    this.group = attrs.group;
    this.rssi = attrs.rssi;
    this.current = attrs.current;
    this.volts = attrs.volts;
    this.consumoEstimado = attrs.consumoEstimado;
    this.consumoMensual = attrs.consumoMensual;
    this.burnHours = attrs.burnHours;
    this.on_off = attrs.on_off;
    this.lastRecordDate = attrs.lastRecordDate;
    this.status = attrs.status;
    this.location = attrs.location;
    this.event = event;
    this.isSelected = false;
    this.linkedSituation = linkedSituation;
  }

  getMarkerIcon() {
    let  result = {}
   if (this.on_off === 'ON' && this.status == 0) {
      result = {
        single: {
          normal: 'assets/img/svg/circuit_normal-online.svg',
          selected: 'assets/img/svg/circuit_selected.svg',
          circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
        }
      }
    }
  else if (this.on_off === 'OFF' && this.status == 0) {
      result = {
        single: {
          normal: 'assets/img/svg/circuit_normal.svg',
          selected: 'assets/img/svg/circuit_selected.svg',
          circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
        }
      }
    }
    // else if (this.status == 0) {
    //   result = {
    //     single: {
    //       normal: 'assets/img/svg/circuit_normal.svg',
    //       selected: 'assets/img/svg/circuit_selected.svg',
    //       circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
    //     }
    //   }
    // }
else{
  result = {
    single: {
      normal: 'assets/img/svg/circuit_normal-offline.svg',
      selected: 'assets/img/svg/circuit_selected.svg',
      circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
    }
  }
}

return result;
  }


  get_icon(): object {
    return this.linkedSituation === 'assign'
      ?
      {
        url: `${this.getMarkerIcon()['assign'][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}`,
        scaledSize: new google.maps.Size(23, 26)
      }
      :
      {
        url: this.getMarkerIcon()[this.linkedSituation][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event],
        scaledSize: new google.maps.Size(23, 26)
      };
  }

  upadeIcon(event: string): void {
    this.event = event;
    this.marker.setIcon(this.get_icon());
  }

  paintMarker(map: any): void {
    const latitude = this.location.latitude;
    const longitude = this.location.longitude;
    this.event = this.isSelected ? 'selected' : 'normal';
    this.marker = new google.maps.Marker({
      title: this.deviceName == null ? this.id : this.deviceName.toString(),
      position: new google.maps.LatLng(latitude, longitude),
      icon: this.get_icon(),
      map: map
    });
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }

  setMap(map: any): void {
    this.marker.setMap(map);
  }

}

export interface IDevice {
  id: string,
  potenciaActiva: number,
  volts: number,
  currentFirstLine: number,
  currentSecondLine: number,
  tempreature: number,
  humedad: number,
  powerFactor: string,
  close_open: number,
  circuitName: string,
  deviceName: string,
  group: string,
  lastDate: string,
  rpu: string,
  expectedWatts: number,
  actualWatts: number,
  timeDelay: string,
  circuit: GetDevices,
  schedule: Schedule,
  sim: Sim,
  status: boolean,
  currentList: any[],
  voltsList: any[],
  wattsList: any[],
  luminaires: GeneralViewLumWattage[],
}

export class Device implements IDevice {

  id: string;
  potenciaActiva: number;
  volts: number;
  currentFirstLine: number;
  currentSecondLine: number;
  tempreature: number;
  humedad: number;
  powerFactor: string;
  close_open: number;
  circuitName: string;
  deviceName: string;
  lastDate: string;
  group: string;
  rpu: string;
  timeDelay: string;
  expectedWatts: number;
  actualWatts: number;
  circuit: GetDevices;
  schedule: Schedule;
  sim: Sim;
  status: boolean;
  currentList: any[];
  voltsList: any[];
  wattsList: any[];
  electricReadings: any[];
  luminaires: GeneralViewLumWattage[];

  constructor(attrs: any) {
    this.id = attrs.id;
    this.potenciaActiva = attrs.potenciaActiva;
    this.volts = attrs.volts;
    this.currentFirstLine = attrs.currentFirstLine;
    this.currentSecondLine = attrs.currentSecondLine;
    this.tempreature = attrs.tempreature;
    this.humedad = attrs.humedad;
    this.powerFactor = attrs.powerFactor;
    this.close_open = attrs.close_open;
    this.circuitName = attrs.circuitName;
    this.deviceName = attrs.deviceName;
    this.timeDelay = attrs.timeDelay;
    this.lastDate = attrs.lastDate;
    this.group = attrs.group;
    this.rpu = attrs.rpu;
    this.expectedWatts = attrs.expectedWatts;
    this.actualWatts = attrs.actualWatts;
    this.circuit = new GetDevices(attrs.circuit);
    this.schedule = new Schedule(attrs.schedule);
    this.sim = new Sim(attrs.sim);
    this.currentList = attrs.currentList;
    this.status = attrs.status;
    this.electricReadings = attrs.electricReadings;
    this.voltsList = attrs.voltsList;
    this.wattsList = attrs.wattsList;
    this.luminaires = attrs.luminaires != null ? attrs.luminaires.map((lum: GeneralViewLumWattage) => new GeneralViewLumWattage(lum)) : [];
  }
}
