export class NewQuickSurveyArea implements INewQuickSurveyArea {
    id: string;
    points: LocationNewQuickSurveyArea[]

    constructor(attrs) {
        this.id = attrs.id;
        this.points = attrs.points;
    }
}

export interface INewQuickSurveyArea {
    id: string,
    points: LocationNewQuickSurveyArea[]
}

export class LocationNewQuickSurveyArea implements ILocationNewQuickSurveyArea {
    latitude: number;
    longitude: number;
    constructor(attrs) {
        this.latitude = attrs.latitude;
        this.longitude = attrs.longitude;
    }
}

export interface ILocationNewQuickSurveyArea {
    latitude: number,
    longitude: number
}

export class TroopsSurveyArea implements ITroopsSurveyArea {
    id: string;
    fullName: string;
    constructor(attrs) {
        this.id = attrs.id;
        this.fullName = attrs.fullName;
    }
}

export interface ITroopsSurveyArea {
    id: string,
    fullName: string,
}

export class UpdateNewQuickSurveyArea implements IUpdateNewQuickSurveyArea {
    id: string;
    points: LocationNewQuickSurveyArea[]

    constructor(attrs) {
        this.id = attrs.id;
        this.points = attrs.points;
    }
}

export interface IUpdateNewQuickSurveyArea {
    id: string,
    points: LocationNewQuickSurveyArea[]
}
