import { Component, ViewChild, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { CurrentProjectService } from '@shared/cookies/current-project.service';

import { Toastr } from '@shared/toastr/toastr.service'

import { ProjectsModalService } from './projects-modal.service';
import { GetProjectsResponse } from '@models/get-projects-response';
import { NewProjectModalComponent } from '@shared/projects/new-project-modal/new-project-modal.component';
import { EditProjectModalComponent } from '@shared/projects/edit-project-modal/edit-project-modal.component';

import { Project } from '@models/project';
import { RoleEnum } from '@models/role-enum';
import { AuthService } from '@shared/auth/auth.service';

@Component({
  selector: 'projects-modal-component',
  templateUrl: './projects-modal.component.html',
  providers: [ProjectsModalService]
})

export class ProjectsModalComponent implements OnInit {
  projects: Project[] = [];
  shouldHideCloseButton: Boolean = false;

  @ViewChild('projectsModal') projectsModal: any;
  @ViewChild(NewProjectModalComponent) newProjectModalComponent: NewProjectModalComponent;
  @ViewChild(EditProjectModalComponent) editProjectModalComponent: EditProjectModalComponent;

  constructor(
    private modalService: NgbModal,
    private projectsModalService: ProjectsModalService,
    private currentProjectService: CurrentProjectService,
    private router: Router,
    private toastr: Toastr,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getProjects();
  }

  hideCloseButton() {
    this.shouldHideCloseButton = true;
  }

  openEditProjectModal(project: Project): void {
    this.editProjectModalComponent.initializeEditProjectForm(project);
    this.editProjectModalComponent.openEditProjectModal();
  }

  openNewProjectModal(): void {
    this.newProjectModalComponent.openNewProjectModal();
  }

  openProjectsModal(options?: Object): void {
    this.modalService.open(
      this.projectsModal,
      { ...options, ...{ ariaLabelledBy: 'Modal Proyectos', centered: true } }
    );
  }

  setCurrentProject(project: Project): void {
    this.currentProjectService.setCurrentProject(project);
    this.modalService.dismissAll();
    const user = this.authService.getUser();

    if (this.router.url === '/select-project') {
      if (user.role_name_enums.includes(RoleEnum.Cliente)) {
        this.router.navigate(['u/tableau-dashboard']);
      } else {
        this.router.navigate(['u']);
      }
    } else {
      location.reload();
    }
  }

  // TODO: Check flow for pagination
  getProjects(): void {
    this.projectsModalService
      .getProjects()
      .subscribe(
        (projectsResponse: GetProjectsResponse) => {
          this.projects = projectsResponse.projects;
        },
        (error) => {
          console.error(error);
          this.toastr.error(error);
        }
      )
  }
}
