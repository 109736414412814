import { GaugeEnum } from './gauge-enum';
import { MaterialEnum } from './material-enum';
import { DistributionEnum } from './distribution-enum';
import { SubtypeEnum } from './subtype-enum';

interface WiringInterface {
    id: string,
    is_present: boolean,
    gauge: number,
    gauge_name: string,
    material: number,
    material_name: string,
    distribution: number,
    distribution_name: string,
    subtype: number,
    subtype_name: string,
    has_lock: boolean,
    condition: number
}

export class Wiring implements WiringInterface {
    id: string;
    is_present: boolean;
    gauge: number;
    gauge_name: string;
    material: number;
    material_name: string;
    distribution: number;
    distribution_name: string;
    subtype: number;
    subtype_name: string;
    has_lock: boolean;
    condition: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.is_present = attrs.is_present ? true : false;
    this.gauge = attrs.gauge;
    this.gauge_name = GaugeEnum[attrs.gauge];
    this.material = attrs.material;
    this.material_name = MaterialEnum[attrs.material];
    this.distribution = attrs.distribution;
    this.distribution_name = DistributionEnum[attrs.distribution];
    this.subtype = attrs.subtype;
    this.subtype_name = SubtypeEnum[attrs.subtype];
    this.has_lock = attrs.has_lock ? true : false;
    this.condition = attrs.condition;
  }
}
