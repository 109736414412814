import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { DetailSectorArea, SingleSecctorArea } from './sector-area';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SectorService {
  constructor(private httpClient: HttpClient) { }

  getSectorAreas(sector_area_name) {
    const httpParams = new HttpParams()
      .set('sector_area_name', sector_area_name);
    return this.httpClient.get<SingleSecctorArea[]>(
      `${environment.SERVER_TASKS}/api/Sector/get-list-sectores`, { params: httpParams })
      .pipe(map(res => res.map(mp => new SingleSecctorArea(mp))));
  }

  createsectorarea(insert) {
    return this.httpClient.post<string>(
      `${environment.SERVER_TASKS}/api/Sector/register-sectores`, insert);
  }


  detailSectorArea(sectorarea_id) {
    const parametter = new HttpParams()
      .set('sector_area_id', sectorarea_id);
    return this.httpClient.get<DetailSectorArea>(
      `${environment.SERVER_TASKS}/api/Sector/get-luminaires-sectores`, { params: parametter })
      .pipe(map(element => new DetailSectorArea(element)));
  }

  deleteSector(sectorarea_id) {
    const parametter = new HttpParams()
      .set('sector_area_id', sectorarea_id);
    return this.httpClient.delete(`${environment.SERVER_TASKS}/api/Sector/delete-sectores`, { params: parametter });
  }
  updatenameSector(value) {
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/Sector/update-name-sectores`, value);
  }
  // updateneamesectorarea(value) {
  //   return this.httpClient.post(`${environment.SERVER_TASKS}​/api​/Sector​/update-name-sectores`, value);
  // }



  updateSectorArea(update) {
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/Sector/update-sectores`, update);
  }


  exportallLuminairesDetail() {
    this.httpClient.get(
      `${environment.SERVER_TASKS}/api/Sector/get-xls-luminaires`, { responseType: 'blob' }
    ).subscribe(file => {
      this.downloadProcess(file, `Detalle del Luminaires.XLSX`)
    })
  }

  exportXLSsectorAreaDetails(sector_area_id) {
    const httpParams = new HttpParams()
      .set('sector_area_id', sector_area_id);

    this.httpClient.get(
      // tslint:disable-next-line:max-line-length
      `${environment.SERVER_TASKS}/api/Sector/get-xls-sectores`, { responseType: 'blob', params: httpParams }
    ).subscribe(file => {
      this.downloadProcess(file, `Detalle del Polígono.XLSX`);
    });
  }

  private downloadProcess(file, fileName) {
    const newBlob = new Blob([file], { type: 'application/vnd.ms-excel' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}
