import { Item } from "./item";

interface InventoryInterface {
    id: string
    items: Item[];
  }
  
  export class Inventory implements InventoryInterface {
    id: string
    items: Item[]
  
    constructor(attrs) {
      this.id = attrs.id;
      this.items = attrs.items.map(item => new Item(item));
    }
} 