import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { GenericTable } from '@app/models/generic-table';
import { Rpus } from '@app/models/rpus';
import { RpusService } from './rpus.service';
import { FormGroup, FormBuilder } from '@angular/forms';

// @Pipe({ name: "getCircuitsWithOutreference" })
// export class getCircuitsWithOutreferencePipe implements PipeTransform {
//   transform(circuits: any[], reduce: boolean = false): string {
//     if (reduce && circuits.length >= 3)
//       circuits = [circuits[0], circuits[1], circuits[2]]
//     return circuits.map(Circuit => Circuit).join(', ');
//   }
// }

@Component({
  selector: 'app-rpus',
  templateUrl: './rpus.component.html',
  styleUrls: ['./rpus.component.scss']
})
export class RpusComponent implements OnInit {

  pagination: GenericTable<Rpus>;
  rpusSearchForm: FormGroup;

  constructor(
    private Service: RpusService,
    private fb: FormBuilder
  ) {
    this.rpusSearchForm = fb.group({
      rpu: "",
      meter: "",
      period_type: "",
      tariff_type: "",
      month: "",
      year: new Date().getFullYear()
    });
  }

  ngOnInit() {
    this.getElementsPaginated(0);
  }

  getElementsPaginated(page: number) {
    this.Service.getRpus(this.rpusSearchForm.getRawValue(), page).subscribe(
      response => {
        this.pagination = response;
      }
    );
  }

  pageChange(page: number) {
    this.getElementsPaginated(page - 1);
  }

  calculateClasses(value: number) {
    return {
      'withoutImport': value === 0,
      'withImport': value === 1,
      'underConsumption': value === 3,
      'overConsumption': value === 2
    };
  }

}
