import { DetailMarkerColor } from "@app/models/detail-marker-color";
import { DeviceMarkers } from "@app/models/smart-devices";


interface ILuminaireAll {
  id: string;
  comment: string;
  has_photocell: boolean;
  myProperty: number;
  progress: number;
  reference_id: number;
  status: number;
  tasks: [{
    id: string;
    reference_id: string;
  }];
  technology: number;
  type: number;
  municipality_status: string;
  arm: IArm;
  lamppost: ILamppost;
  bases: IBases;
}

interface IBases {
  id: string;
  bolt_spacing: number;
  condition: number;
  height: number;
  image_url: string;
  is_present: boolean;
  length: number;
  material: number;
  width: number;
}

interface ILamppost {
  id: string;
  circuit_id: string;
  condition: number;
  diameter: number;
  geometry: number;
  has_circuit: boolean;
  height: number;
  image_url: string;
  is_first_in_circuit: boolean;
  is_last_in_circuit: boolean;
  type: number;
}

interface IArm {
  id: string;
  condition: number;
  diameter_is_acceptable: boolean;
  geometry: number;
  image_url: string;
  is_present: boolean;
  length: number;
  material: number;
  mounting_type: number;
  wiring_is_inside: boolean;
}

export class LuminaireAll implements ILuminaireAll {
  id: string;
  comment: string;
  has_photocell: boolean;
  myProperty: number;
  progress: number;
  reference_id: number;
  status: number;
  tasks: [{
    id: string;
    reference_id: string;
  }];
  technology: number;
  type: number;
  municipality_status: string;
  name: string;
  power: number;
  arm: IArm;
  lamppost: ILamppost;
  bases: IBases;
  markers: LuminaireMarkers[];

  constructor(attrs: any) {
    this.id = attrs.id;
    this.comment = attrs.comment;
    this.has_photocell = attrs.has_photocell;
    this.myProperty = attrs.myProperty;
    this.progress = attrs.progress;
    this.reference_id = attrs.reference_id;
    this.status = attrs.status;
    this.tasks = attrs.tasks;
    this.technology = attrs.technology;
    this.type = attrs.type;
    this.municipality_status = attrs.municipality_status;
    this.arm = attrs.arm;
    this.lamppost = attrs.lamppost;
    this.bases = attrs.bases;
    this.power = attrs.power;
    this.name = attrs.name;
  }

}

export class NewBases implements IBases {
  id: string;
  bolt_spacing: number;
  condition: number;
  height: number;
  image_url: string;
  is_present: boolean;
  length: number;
  material: number;
  width: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.bolt_spacing = attrs.bolt_spacing;
    this.condition = attrs.condition;
    this.height = attrs.height;
    this.image_url = attrs.image_url;
    this.is_present = attrs.is_present;
    this.length = attrs.length;
    this.material = attrs.material;
    this.width = attrs.width;
  }
}

export class NewArm implements IArm {
  id: string;
  condition: number;
  diameter_is_acceptable: boolean;
  geometry: number;
  image_url: string;
  is_present: boolean;
  length: number;
  material: number;
  mounting_type: number;
  wiring_is_inside: boolean;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.condition = attrs.condition;
    this.diameter_is_acceptable = attrs.diameter_is_acceptable;
    this.geometry = attrs.geometry;
    this.image_url = attrs.image_url;
    this.is_present = attrs.is_present;
    this.length = attrs.length;
    this.material = attrs.material;
    this.mounting_type = attrs.mounting_type;
    this.wiring_is_inside = attrs.wiring_is_inside;
  }
}

export class NewLamppost implements ILamppost {
  id: string;
  circuit_id: string;
  condition: number;
  diameter: number;
  geometry: number;
  has_circuit: boolean;
  height: number;
  image_url: string;
  is_first_in_circuit: boolean;
  is_last_in_circuit: boolean;
  type: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.circuit_id = attrs.circuit_id;
    this.condition = attrs.condition;
    this.diameter = attrs.diameter;
    this.geometry = attrs.geometry;
    this.has_circuit = attrs.has_circuit;
    this.height = attrs.height;
    this.image_url = attrs.image_url;
    this.is_first_in_circuit = attrs.is_first_in_circuit;
    this.is_last_in_circuit = attrs.is_last_in_circuit;
    this.type = attrs.type;
  }
}



export class LuminaireMarkers {

  marker_color: DetailMarkerColor;
  location: {
    latitude: number;
    longitude: number;
  };
  marker: google.maps.Marker;

  readonly markerIcons = {
    circuit: {
      normal: 'assets/img/svg/circuit_',
      selected: 'assets/img/svg/circuit_selected.svg',
    },
    luminaire: {
      normal: {
        normal: 'assets/img/svg/ic_single_normal.svg',
        selected: 'assets/img/svg/selected-blue.svg'
      },
      assing: {
        normal: 'assets/img/svg/ic_install_pin-',
        selected: 'assets/img/svg/ic_install_selected-',
      }

    }
  }

  constructor(attrs) {

    this.location = attrs.location;
    this.marker_color = attrs.marker_color

  }



  buildSVG(): string {
    let svg = '';

    if (this.marker_color) {
      svg = `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <g>
                    <path stroke="black" stroke-opacity="12" stroke-width="5" fill="${this.marker_color.fill_color}"
                    d="${this.marker_color.path}"> </path>
                    <path fill="${this.marker_color.fill_color2}" d="${this.marker_color.path2}"> </path>
                </g>
            </svg>`;
    } else {
      svg = `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd" transform="translate(13 13)">
          <path fill="#F14336" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.48662295,5.46992268 C3.38709836,2.19976804 6.92837705,-1.39891394e-14 10.9827787,-1.39891394e-14 C17.0452213,-1.39891394e-14 21.9596967,4.91677835 21.9596967,10.9820619 C21.9596967,12.5032088 21.6499836,13.9512887 21.091959,15.2688402 C20.5326721,16.5863918 19.7238115,17.7720619 18.7256066,18.7655928 C17.8937541,19.6503351 17.1476475,20.5850515 16.4770082,21.5402448 C12.0834098,27.8027191 10.9827787,31.9525515 10.9827787,31.9525515 C10.9827787,31.9525515 9.78747541,27.1894072 4.91519672,20.7553608 C4.39819672,20.0742139 3.84017213,19.407049 3.23868852,18.7655928 C2.56931148,18.0997809 1.98577049,17.3469201 1.50582787,16.5248711 C0.552344262,14.8974613 0.00586065574,13.0037629 0.00586065574,10.9819716 C0.00586065574,8.9730799 0.544680328,7.08957474 1.48662295,5.46992268 Z"/>
          <g transform="translate(2.87 2.866)">
            <polygon points="0 0 16.261 0 16.261 16.239 0 16.239"/>
            <path fill="#FFF" d="M2.6423913,8.11940299 C2.6423913,6.96238806 3.58416667,6.02189055 4.74275362,6.02189055 L7.45289855,6.02189055 L7.45289855,4.73631841 L4.74275362,4.73631841 C2.87275362,4.73631841 1.35507246,6.2519403 1.35507246,8.11940299 C1.35507246,9.98686567 2.87275362,11.5024876 4.74275362,11.5024876 L7.45289855,11.5024876 L7.45289855,10.2169154 L4.74275362,10.2169154 C3.58416667,10.2169154 2.6423913,9.27641791 2.6423913,8.11940299 L2.6423913,8.11940299 Z M5.42028986,8.7960199 L10.8405797,8.7960199 L10.8405797,7.44278607 L5.42028986,7.44278607 L5.42028986,8.7960199 L5.42028986,8.7960199 Z M11.5181159,4.73631841 L8.80797101,4.73631841 L8.80797101,6.02189055 L11.5181159,6.02189055 C12.6767029,6.02189055 13.6184783,6.96238806 13.6184783,8.11940299 C13.6184783,9.27641791 12.6767029,10.2169154 11.5181159,10.2169154 L8.80797101,10.2169154 L8.80797101,11.5024876 L11.5181159,11.5024876 C13.3881159,11.5024876 14.9057971,9.98686567 14.9057971,8.11940299 C14.9057971,6.2519403 13.3881159,4.73631841 11.5181159,4.73631841 L11.5181159,4.73631841 Z"/>
          </g>
        </g>
      </svg>
      `
    }
    return svg;
  }



  paintMarker(map: any): void {
    this.marker = new google.maps.Marker({
      title: '',
      position: new google.maps.LatLng(this.location.latitude, this.location.longitude),
      map: map,
      icon: {
        url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.buildSVG())
      },
    })
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }

}


export interface Editlocationlum {
  marker_color: DetailMarkerColor,
  location: {
    latitude: number;
    longitude: number;
  }
}

