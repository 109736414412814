import { Component, OnInit } from '@angular/core';
import { VersionsService } from '@pages/admin/versions/versions.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toastr } from '@shared/toastr/toastr.service';
import { AppVersion } from '../app-version';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html'
})
export class VersionComponent implements OnInit {
  isSubmitted: boolean;
  selectPlatforms: boolean;
  versionId: string;
  versionForm: FormGroup;

  constructor(
    private _version: VersionsService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: Toastr
  ) {
    route.paramMap.subscribe(params => {
      this.versionId = params.get('id');
    });

    this.versionForm = this.fb.group({
      id: null,
      name: [null, Validators.required],
      is_active: [true]
    });

    if (this.versionId) {
      this.getVersion(this.versionId);
    }
  }

  ngOnInit() {
  }

  getVersion(id: string) {
    this._version.getVersion(id).subscribe(response => {
      this.versionForm.patchValue(response['version']);
    }, error => this.toastr.error(error));
  }

  onSubmit({ value, invalid }: { value: AppVersion, invalid: boolean }) {
    if (invalid) { return this.isSubmitted = true };
    if (this.versionId) {
      this.updateVersion(value);
    } else {
      this.createVersion(value);
    }
  }

  updateVersion(version: AppVersion) {
    this._version.updateVersion(version).subscribe(() => {
      this.toastr.success('Version actualizada');
    }, error => this.toastr.error(error));
  }

  createVersion(version: AppVersion) {
    this._version.createVersion(version).subscribe(() => {
      this.toastr.success('Version creada.');
      this.router.navigate(['/u', 'admin']);
    }, error => this.toastr.error(error));
  }

}
