import { Component, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Toastr } from '@shared/toastr/toastr.service'

import { NewProjectModalService } from './new-project-modal.service';
import { NewProjectPayload } from './new-project-payload';
import { NewProjectResponse } from '@models/new-project-response';
import { ProjectPhasesEnum } from '@app/models/project_phases-enum';

@Component({
  selector: 'new-project-modal-component',
  templateUrl: './new-project-modal.component.html',
  providers: [NewProjectModalService]
})

export class NewProjectModalComponent {
  newProjectForm: FormGroup = this.formBuilder.group(
    {
      name: [null, Validators.required],
      country: [null, Validators.required],
      state: [null, Validators.required],
      municipality: [null, Validators.required],
      slug: [null, Validators.required],
      phase: [null, Validators.required],
      energy: [null, Validators.required]
    }
  );
  openModal: NgbModalRef;
  phasesEnum = Object.keys(ProjectPhasesEnum).filter(key => !isNaN(Number(ProjectPhasesEnum[key])));


  @Input() parent: any;

  @ViewChild('newProjectModal') newProjectModal: any;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private newProjectModalService: NewProjectModalService,
    private toastr: Toastr,
  ) {}

  // HACK: (Jose Farias) Projects should be stored in-memory to avoid having to call getProjects
  //       on parent (avoiding circular dependency).
  closeNewProjectModal() {
    this.openModal.dismiss();
    this.parent.getProjects();
  }

  createProject(): void {
    if (this.newProjectForm.valid) {
      const requestPayload = new NewProjectPayload(this.newProjectForm);

      this.newProjectModalService
        .createProject(requestPayload)
        .subscribe(
          (newProjectResponse: NewProjectResponse) => {
            this.toastr.success('Proyecto creado.');
            this.closeNewProjectModal();
          },
          (error) => {
            this.toastr.error(error);
          }
        );
    } else {
      this.newProjectForm.controls.name.markAsTouched();
      this.newProjectForm.controls.country.markAsTouched();
      this.newProjectForm.controls.state.markAsTouched();
      this.newProjectForm.controls.municipality.markAsTouched();
      this.newProjectForm.controls.slug.markAsTouched();
      this.toastr.error(this.toastr.singleError('Operación fallida. Revisar campos requeridos.'));
    }
  }

  openNewProjectModal(options?: Object): void {
    this.openModal = this.modalService.open(
      this.newProjectModal,
      {...options, ...{ ariaLabelledBy: 'Modal Proyectos', centered: true }}
    );
  }
}
