import { Component, OnInit } from '@angular/core';
import { OperationDasboardService } from './operation-dasboard.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { OperationDashboard, OperationOficialsDashboard } from '@app/models/operation-dashboard';
import { Router } from '@angular/router';
import { FusionChartModel } from '@app/models/FusionChartModel';


@Component({
  selector: 'app-operation-dashboard',
  templateUrl: './operation-dashboard.component.html',
  styleUrls: ['./operation-dashboard.component.scss']
})
export class OperationDashboardComponent implements OnInit {
  chartHistorict: FusionChartModel;
  dashboardInfo: OperationDashboard;
  oficials: OperationOficialsDashboard[];
  barChartPlugins = [pluginDataLabels];
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
      yAxes: [{}]
    },
    plugins: {
      datalabels: {
        color: 'black',
        anchor: 'center',
        align: 'center',
        font: {
          size: 10
        },
        formatter: (value) => {
          return value + '%';
        }
      }
    }
  };

  barChartOptionsOficials: ChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
      yAxes: [{}]
    },
    plugins: {
      datalabels: {
        color: 'black',
        anchor: 'center',
        align: 'center',
        font: {
          size: 10
        },
        formatter: (value) => {
          return value + '%';
        }
      }
    }
  };
  lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 10
        },
      }
    }
  }


  constructor(
    private dashboardService: OperationDasboardService,
    private route: Router,
  ) { }

  ngOnInit() {
    // this.signalRService.addTransferDashboardListener();
    this.dashboardService.getGeneralOperation().subscribe(dashboard => {
      this.dashboardInfo = dashboard;
    });
    this.dashboardService.getGeneralOficialOperation().subscribe(oficials => {
      this.oficials = oficials;
    });
    this.dashboardService.getDetailsOfiicer_Charts().subscribe(response => {
      this.chartHistorict = response;
    });
  }

  reviewUser(user_id) {
    this.route.navigate(['/u/reports/operation-dashboard/indiviual-dashboard/', user_id])
  }
}
