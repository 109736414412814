export class SingleSecctorArea implements ISingleSectorArea {
  id: string;
  points: LocationSectorArea[];
  selected: boolean;
  polygon: google.maps.Polygon;

  constructor(attrs) {
    this.id = attrs.id;
    this.points = attrs.points;
    this.selected = false;
  }
}

export interface ISingleSectorArea {
  id: string,
  points: LocationSectorArea[]
}

export class LocationSectorArea implements ILocationSectorArea {
  latitude: number;
  longitude: number;
  constructor(attrs) {
    this.longitude = attrs.longitude;
    this.latitude = attrs.latitude;

  }
}

export interface ILocationSectorArea {
  latitude: number,
  longitude: number
}


// DETAIL LUMINARIES SECTOR
export class DetailSectorArea implements IDetailSectorArea {
  surveyAreaName: string;
  luminairesCount: number;
  luminairesNMedCount: number;
  luminairesMedCount: number;
  detailsWattages: DetailWattagesSectorArea[];
  listTechnology: ValuesTechnologySectorArea[];

  constructor(attrs) {
      this.surveyAreaName = attrs.surveyAreaName;
      this.luminairesCount = attrs.luminairesCount;
      this.luminairesNMedCount = attrs.luminairesNMedCount;
      this.luminairesMedCount = attrs.luminairesMedCount;
      this.detailsWattages = attrs.detailsWattages;
      this.listTechnology = attrs.listTechnology;
  }

}

export interface IDetailSectorArea {
  surveyAreaName: string,
  luminairesCount: number,
  luminairesNMedCount: number,
  luminairesMedCount: number,
  detailsWattages: DetailWattagesSectorArea[],
  listTechnology: ValuesTechnologySectorArea[],
}

export class ValuesTechnologySectorArea implements IValuesTechnologySectorArea {
technology: string;
count: number;
// power: number;

constructor(attrs) {
  this.technology = attrs.technology;
  this.count = attrs.count;
  // this.power = attrs.power;
}
}

export interface IValuesTechnologySectorArea {
technology: string,
count: number,
// power: number,
}

export class DetailWattagesSectorArea implements IDetailWattagesSectorArea {
power: number;
luminairesCount: number;
constructor(attrs) {
  this.power = attrs.power
  this.luminairesCount = attrs.luminairesCount
}
}

export interface IDetailWattagesSectorArea {
power: number,
luminairesCount: number,
}
