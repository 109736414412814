export interface ProjectInterface {
  id: string;
  name: string;
  state: string;
  country: string;
  municipality: string;
  slug: string;
  tickets_enabled: boolean;
  last_luminaire_reference_id: string;
  phase: number;
  energy_year: number;
}

export class Project implements ProjectInterface {
  phase: number;
  id: string;
  name: string;
  state: string;
  country: string;
  municipality: string;
  slug: string;
  tickets_enabled: boolean;
  last_luminaire_reference_id: string;
  energy_year: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.state = attrs.state;
    this.country = attrs.country;
    this.municipality = attrs.municipality;
    this.slug = attrs.slug;
    this.tickets_enabled = attrs.tickets_enabled;
    this.last_luminaire_reference_id = attrs.last_luminaire_reference_id;
    this.phase = attrs.phase;
    this.energy_year = attrs.energy_year;
  }

}
