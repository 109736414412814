import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { RepairTasksResponse, RepairTasks } from '@models/reapir-tasks-response';
import { GenericTable } from '@app/models/generic-table';
import { map } from 'rxjs/operators';
import { AuthService } from '@app/shared/auth/auth.service';

@Injectable()

export class RepairTasksTableService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  // getTasks(page: number, q: string, user: string, sector: string, finished: string): Observable<RepairTasksResponse> {
  //   const params: HttpParams = new HttpParams()
  //     .set('page', page.toString())
  //     .set('q', q)
  //     .set('user', user)
  //     .set('sector', sector)
  //     .set('finished', finished);
  //   return this.httpClient.get<RepairTasksResponse>(
  //     `${environment.SERVER_URL}/repair_tasks`, { params: params }
  //   );
  // }

  getTasks(page: number, task_lum_ticket: string, sector: string, user_id: string, is_Finished: string,end_date) {
    console.log(end_date)
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);

    const params: HttpParams = new HttpParams()
      .set('page', page.toString())
      .set('task_lum_ticket', task_lum_ticket)
      .set('user_id', user_id)
      .set('sector', sector)
      .set('is_Finished', is_Finished)
      .set('end_date', end_date);

    return this.httpClient.get<GenericTable<RepairTasks>>(
      `${environment.SERVER_TASKS}/api/Repairs/GetRepairTasks`, { params: params, headers: httpHeaders }
    ).pipe(
      map(response => new GenericTable<RepairTasks>(response))
    )
  }

}
