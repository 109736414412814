import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { StyleMap } from '@app/models/styleMap';

import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { Editlocationlum, LuminaireMarkers } from '../luminaire-edit-all';

@Component({
  selector: 'app-edit-ubicacion',
  templateUrl: './edit-ubicacion.component.html',
  styleUrls: ['./edit-ubicacion.component.scss']
})
export class EditUbicacionComponent implements OnInit {
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  @ViewChild('gmap') gmapElement: any;
  @Input() markers: LuminaireMarkers[];

  locationForm: FormGroup; 
  constructor(
    public fb: FormBuilder,
    private currentProjectService: CurrentProjectService,

  ) {
    this.locationForm = this.fb.group({
      latitud: [null],
      longitud: [null]
    })
  }

  ngOnInit() {
    this.paintMap();
    console.log(this.markers);
    this.locationForm.patchValue({
      latitud: this.markers[0].location.latitude,
      longitud: this.markers[0].location.longitude
    });
    this.paintLuminaires(this.markers);
  }
  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  } 


  private paintMap() {
    const project = this.currentProjectService.getCurrentProject();
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })

  }


  paintLuminaires(luminaires) {
    const markers = this.markers.map(marker => new LuminaireMarkers(marker));
    markers.forEach(marker => {
      marker.paintMarker(this.map);
    });
}

}
