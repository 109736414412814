import { FormGroup } from '@angular/forms';

import { User } from './user';
import { Troop } from './troop';

export class NewUserPayload {
  name: string;
  last_name: string;
  email: string;
  role_id: string;
  cell_phone_number: string;
  address: string;
  assigned_tools: string;
  troop_id: string;
  password: string;
  password_confirmation: string;
  image: any;
  troops: Troop[];

  constructor(newUserForm: FormGroup, troops?: Troop[]) {
    this.name = newUserForm.get('name').value;
    this.last_name = newUserForm.get('last_name').value;
    this.email = newUserForm.get('email').value;
    this.role_id = newUserForm.get('role_id').value;
    this.cell_phone_number = newUserForm.get('cell_phone_number').value;
    this.address = newUserForm.get('address').value;
    this.assigned_tools = newUserForm.get('assigned_tools').value;
    this.troop_id = newUserForm.get('troop_id').value;
    this.password = newUserForm.get('password').value;
    this.password_confirmation = newUserForm.get('password_confirmation').value;
    this.image = newUserForm.get('image').value;
    this.troops = troops;
  }

  // TODO: roles should be appended using a loop when multiple roles per user is available.
  toFormData() {
    let formData = new FormData();

    formData.append('user[name]', this.name);
    formData.append('user[last_name]', this.last_name);
    formData.append('user[email]', this.email);
    formData.append('user[cell_phone_number]', this.cell_phone_number);
    formData.append('user[assigned_tools]', this.assigned_tools);
    formData.append('user[address]', this.address);
    formData.append('user[roles][][id]',this.role_id );
    formData.append('user[password]', this.password);
    formData.append('user[password_confirmation]', this.password_confirmation);
    formData.append('user[image]', this.image);

    if (this.troops.length){
      for(let troop of this.troops){
        formData.append('user[troops][][id]', troop.id);
      }
    } else {
      if(this.troop_id != null)
        formData.append('user[troops][][id]', this.troop_id);
    }

    return formData;
  }

  toJson() {
    return {
      user: {
        name: this.name,
        last_name: this.last_name,
        email: this.email,
        cell_phone_number: this.cell_phone_number,
        assigned_tools: this.assigned_tools,
        address: this.address,
        roles: [{ id: this.role_id }],
        troop: { id: this.troop_id },
        password: this.password,
        password_confirmation: this.password_confirmation
      }
    };
  }
}