import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '@app/shared/auth/auth.service';
import { IgetSectores } from '../../../models/smart/sectors.model'

@Injectable({
  providedIn: 'root'
})
export class AlertsByTypeService {

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

//   getAlertsList(form): Observable<any> {
//     return this.httpClient.post<any>(
//       `${environment.SERVER_SMART}/api/SmartAlerts/SmartAlerts`, form);
//   }
//   getAlertDetailsList(id): Observable<SmartAlertInfo> {
//     const httpParams = new HttpParams()
//       .set('id', id)
//     return this.httpClient.get<SmartAlertInfo>(
//       `${environment.SERVER_SMART}/api/SmartAlerts/SmartAlertDetails`, { params: httpParams });
//   }

  getSectores(): Observable<IgetSectores[]> {
    return this.httpClient.get<IgetSectores[]>(`${environment.SERVER_NETCORE}/api/GeneralView/GetListSectores`);
  }

  getAlertsCountByTypeOverDays(type: number, days: number = 14): Observable<any> {
    const url = `${environment.SERVER_SMART}/api/SmartAlerts/AlertsCountByTypeOverDays?days=${days}&type=${type}`;
    return this.httpClient.get<any>(url);
  }

  getAlertsList(form): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_SMART}/api/SmartAlerts/SmartAlerts`, form);
  }
}
