// alerts-by-type.component.ts
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IgetSectores } from '../../../models/smart/sectors.model';
import { Observable, of } from 'rxjs';
import { AlertsByTypeService } from './alerts-by-type.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ChartOptions, ChartDataSets } from 'chart.js';
import { switchMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-alerts-by-type',
  templateUrl: './alerts-by-type.component.html',
  styleUrls: ['./alerts-by-type.component.scss']
})
export class AlertsByTypeComponent implements OnInit {
    type: string;
    sectors$: Observable<IgetSectores[]>;
    selectedSector: IgetSectores;
    public model: any;
    selectedDays = 14;
    capitalizedType: string;
    @ViewChild('canvas') canvas: ElementRef;

    alertTypes = [
    { id: 0, name: 'high-volts' },
    { id: 1, name: 'low-volts' },
    { id: 2, name: 'high-watts' },
    { id: 3, name: 'low-watts' },
    { id: 4, name: 'high-temperature' },
    { id: 5, name: 'high-humidity' },
    { id: 6, name: 'high-light' },
    { id: 7, name: 'low-light' },
    { id: 8, name: 'out-of-service' },
    { id: 9, name: 'secondary-switch-off' },
    { id: 10, name: 'no-schedule' }
    ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertsByTypeService: AlertsByTypeService
  ) {}

  ngOnInit() {
    this.type = this.route.snapshot.paramMap.get('type');
    this.capitalizedType = this.type.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    this.sectors$ = this.alertsByTypeService.getSectores();
    this.updateChartData();
  }

  selectItemEvent(item: any): void {    
    // Assuming 'item' has a property 'id' which is the alert's identifier
    // Navigate to the appropriate details page based on the item's reference prefix    
    if (item.display.startsWith('OE')) {
      this.router.navigate(['u/smart/devices/device-details', item.deviceId]);
    } else if (item.display.startsWith('SA')) {
      this.router.navigate(['u/smart/alerts/alert-details', item.id]);
    }
  }


  updateChartData() {
    const alertTypeObject = this.alertTypes.find(alertType => alertType.name === this.type);
    const alertTypeId = alertTypeObject ? alertTypeObject.id : undefined;
    if (alertTypeId !== undefined) {
      this.alertsByTypeService.getAlertsCountByTypeOverDays(alertTypeId, this.selectedDays).subscribe(data => {
        const chartDataSets: ChartDataSets[] = [{
          data: [],
          label: this.type,
          backgroundColor: '#0047BB',
          borderColor: 'rgba(0, 123, 255, 1)',
          borderWidth: 1,
          hoverBackgroundColor: '#5C9AFF',
          hoverBorderColor: '#5C9AFF',
        }];
        const rawLabels = Object.keys(data);
        const chartLabels = rawLabels.map(date => {
          const dateObj = new Date(date);
          return `${dateObj.getDate()}/${dateObj.getMonth() + 1}`;
        });        
        chartDataSets[0].data = rawLabels.map(label => data[label][this.capitalizedType] || 0);
        this.chartData.labels = chartLabels;
        this.chartData.datasets = chartDataSets;
        // Update the chart...
      });
    }
  }

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    plugins: {
      datalabels: {
        color: '#FFFFFF',     
      }
    }
  };

  chartData = {
    datasets: [],
    labels: [],
    options: this.chartOptions,
    type: 'bar'
  };

  // Add this inside the AlertsByTypeComponent class


resultFormatter = (result: { display: string, id: number }) => result.display;
  
// This function will be used by the typeahead to search for alerts
search = (text$: Observable<string>) => {
  console.log('search function called');
  return text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    switchMap(term => {
      if (term.length < 2) {
        return of([]);
      } else if (term.startsWith('OE') || term.startsWith('SA-')) {
        return this.alertsByTypeService.getAlertsList({
          deviceName: term.startsWith('OE') ? term : '',
          reference: term.startsWith('SA-') ? term : '',
          alertType: '',
          orderBy: 'date',
          alertStatus: '',
          sector: ''
        }).pipe(
          map(alerts => alerts.map(alert => (
            {
            display: `${term.startsWith('OE') ? alert.deviceName : alert.reference}: ${alert.alert_type}`,
            id: alert.id, // Assuming each alert has an 'id' property,
            deviceId: alert.deviceID
          })))
        );
      } else {
        return of([]);
      }
    }),
    catchError(() => of([])) // Handle errors and return an empty array in case of an error
  );
};
}