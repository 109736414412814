import { SubstationEnum } from './substation_type-enum';
import { SubstationCurrentCapacityEnum } from './substation_current_capacity-enum';
import { MtVoltageEnum } from './mt_voltage-enum';
import { BtVoltageEnum } from './bt_voltage-enum';
import { PhasesEnum } from './phases-enum';
import { ServiceTypeEnum } from './service_type-enum';

interface SubstationInterface {
    id: string,
    is_present: boolean,
    identifier: string,
    substation_type: number,
    substattion_type_name: string,
    current_capacity: number,
    current_capacity_name: string,
    mt_voltage: number,
    mt_voltage_name: string,
    bt_voltage: number,
    bt_voltage_name: string,
    phases: number,
    phases_name: string,
    service_type: number,
    service_type_name: string,
    condition: number,
    image_url: string
}

export class Substation implements SubstationInterface {
    id: string;
    is_present: boolean;
    identifier: string;
    substation_type: number;
    substattion_type_name: string;
    current_capacity: number;
    current_capacity_name: string;
    mt_voltage: number;
    mt_voltage_name: string;
    bt_voltage: number;
    bt_voltage_name: string;
    phases: number;
    phases_name: string;
    service_type: number;
    service_type_name: string;
    condition: number;
    image_url: string;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.is_present = attrs.is_present ? true : false;
    this.identifier = attrs.identifier;
    this.substation_type = attrs.substation_type;
    this.substattion_type_name = SubstationEnum[attrs.substation_type];
    this.current_capacity = attrs.current_capacity;
    this.current_capacity_name = SubstationCurrentCapacityEnum[attrs.current_capacity];
    this.mt_voltage = attrs.mt_voltage;
    this.mt_voltage_name = MtVoltageEnum[attrs.current_capacity];
    this.bt_voltage = attrs.bt_voltage;
    this.bt_voltage_name = BtVoltageEnum[attrs.bt_voltage];
    this.phases = attrs.phases;
    this.phases_name = PhasesEnum[attrs.phases];
    this.service_type = attrs.service_type;
    this.service_type_name = ServiceTypeEnum[attrs.service_type];
    this.condition = attrs.condition;
    this.image_url = attrs.image_url;
  }
}
