import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CensoService {

  constructor(public http: HttpClient) { }

  getLumi(): Observable<object> {
    return this.http.get<object>(`${environment.SERVER_NETCORE}/api/GeneralView/GetLuminairesStatus?status=4`)
  }

  getLumZone(location:string): Observable<object> {
    const params: HttpParams = new HttpParams().set('location', location);
return this.http.get<object>(`${environment.SERVER_TASKS}/api/Censos/GetLuminairesForArea/location=${location}`);
  }

}


