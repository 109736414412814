// TODO: comment: Add a blank space to avoid the error using numbers.
export enum SubstationCurrentCapacityEnum {
    '  10',
	'  15',
	'  25',
	'  30',
	'  37.5',
	'  45',
	'  50',
	'  75',
	'  100',
	'  112.5',
	'  150',
	'  167',
	'  225',
	'  300',
	'  500'
}