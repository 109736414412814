import { Measurements } from './measurements';
import { PhotocellGroup } from './photocell_group';
import { PrimarySwitch } from './primary_switch';
import { SecondarySwitch } from './secondary_switch';
import { Contactor } from "./contactor";

interface CabinetInterface {
    id: string,
    is_present: boolean,
    is_functional: boolean,
    image_url: string,
    board_image_url: string,
    has_secondary_switches: boolean, // controls is_present attr for every SecondarySwitch association
    number_of_secondary_switches: number, // virtual attribute, doesn't actually exist in db
    has_contactors: boolean, // controls is_present attr for every Contactor association
    number_of_contactors: number, // virtual attribute, doesn't actually exist in db
    measurements: Measurements,
    photocell_group: PhotocellGroup,
    primary_switch: PrimarySwitch,
    secondary_switches: SecondarySwitch[],
    contactors: Contactor[]
}

export class Cabinet implements CabinetInterface {
    id: string;
    is_present: boolean;
    is_functional: boolean;
    image_url: string;
    board_image_url: string;
    has_secondary_switches: boolean; 
    number_of_secondary_switches: number;
    has_contactors: boolean;
    number_of_contactors: number;
    measurements: Measurements;
    photocell_group: PhotocellGroup;
    primary_switch: PrimarySwitch;
    secondary_switches: SecondarySwitch[];
    contactors: Contactor[];

  constructor(attrs: any) {
    this.id = attrs.id;
    this.is_present = attrs.is_present ? true : false;
    this.is_functional = attrs.is_functional ? true: false;
    this.image_url = attrs.image_url;
    this.board_image_url = attrs.board_image_url;
    this.has_secondary_switches = attrs.has_secondary_switches ? true : false; 
    this.number_of_secondary_switches = attrs.number_of_secondary_switches;
    this.has_contactors = attrs.has_contactors ? true : false;
    this.number_of_contactors = attrs.number_of_contactors;
    this.measurements = new Measurements(attrs.measurements);
    this.photocell_group = new PhotocellGroup(attrs.photocell_group);
    this.primary_switch = new PrimarySwitch(attrs.primary_switch);
    this.secondary_switches = attrs.secondary_switches.map((secondary: SecondarySwitch) => new SecondarySwitch(secondary));
    this.contactors = attrs.contactors.map((contactor: Contactor) => new Contactor(contactor));
  }
}