import { GenericChart } from './generic-chart';
import { VehiclesLocation } from './vehicles-location';

export class OperationDashboard {
    horizontalChart: GenericChart;
    lineChart: GenericChart;
    reapirs_today: number;
    repairs_yesterday: number;

    constructor(attrs) {
        this.horizontalChart = attrs.horizontalChart;
        this.lineChart = attrs.lineChart;
        this.reapirs_today = attrs.reapirs_today;
        this.repairs_yesterday = attrs.repairs_yesterday;

    }
}

export class OperationOficialsDashboard {
    user_id: string;
    user_name: string;
    horizontalChart: GenericChart;
    percentage_finished: number;
    programmed_work: number;
    pending: number;
    in_work: number;
    totalHoursRepairs: number;
    repaired: number;
    caps: string;

    constructor(attrs) {
        this.user_id = attrs.user_id;
        this.user_name = attrs.user_name;
        this.horizontalChart = attrs.horizontalChart;
        this.percentage_finished = attrs.percentage_finished;
        this.programmed_work = attrs.programmed_work;
        this.pending = attrs.pending;
        this.in_work = attrs.in_work;
        this.repaired = attrs.repaired;
        this.caps = attrs.caps;
    }
}

// export class OfficerEvaluation {
//     deadTime: number;
//     deadTimeFormated: string;
//     porcRepair: number;
//     timeDriving: number;
//     timeDrivingFormated: string;
//     totalHourAlmacen: number;
//     totalHourAlmacenFormated: string;
//     totalHoursPend: number;
//     totalHoursPendFormated: string;
//     totalHoursRepairs: number;
//     totalHourRepairFormated: string;
//     totalTimeCamp: number;
//     totalTimeCampFormated: string;

// }


export class OficialDashboard {
    deadTime: number;
    deadTimeFormated: string;
    porcRepair: number;
    timeDriving: number;
    timeDrivingFormated: string;
    totalHourAlmacen: number;
    totalHourAlmacenFormated: string;
    totalHoursPend: number;
    totalHoursPendFormated: string;
    totalHoursRepairs: number;
    totalHoursRepairFormated: string;
    totalTimeCamp: number;
    totalTimeCampFormated: string;
    workEvaluation: number;
    totalPromedio: number;
    totalPromedioPon: number;
    totalPromPonPorc :number;
    penalizacion: number;
    turn_type: number;


    user_id: string;
    user_name: string;
    horizontalChart: GenericChart;
    percentage_finished: number;
    programmed_work: number;

    pending: number;
    in_work: number;
    repaired: number;
    caps: string;
    tasks: [
        {
            task_id: string;
            task_reference: string;
        }
    ];
    markers: TaskMarkers[];
    materials_avalibable: [
        {
            name: string;
            allQuantities: number;
        }
    ];
    materials_used: [
        {
            usedName: string;
            usedQuantities: number;
        }
    ];
    detailOffialCap: [{
        status: number;
        statusName: string;
        workDone: number;
        workDoneName: string;
        timeFormated: string;
        // totalRepair: number;
        promedio: number;
        promPonderado: number;
        porcPonderado: number;

    }];
    recoverMaterials: [{
        name: string;
        quantity: number;
    }];
    gEOTAB_Vehicles: VehiclesLocation[];

    constructor(attrs) {
        this.user_id = attrs.user_id;
        this.user_name = attrs.user_name;
        this.horizontalChart = attrs.horizontalChart;
        this.percentage_finished = attrs.percentage_finished;
        this.programmed_work = attrs.programmed_work;
        this.pending = attrs.pending;
        this.in_work = attrs.in_work;
        this.repaired = attrs.repaired;
        this.tasks = attrs.tasks;
        this.markers = attrs.markers.map(marker => new TaskMarkers(marker));
        this.turn_type = attrs.turn_type;
        // this.officerEvaluation = attrs.officerEvaluation;
        this.materials_avalibable = attrs.materials_avalibable;
        this.materials_used = attrs.materials_used;
        this.caps = attrs.caps;
        this.recoverMaterials = attrs.recoverMaterials;
        this.detailOffialCap = attrs.detailOffialCap;
        this.gEOTAB_Vehicles = attrs.gEOTAB_Vehicles;
        // this.officerEvaluation = attrs.officerEvaluation;
        //////////// esto se tendra que mover a otra dto /////////
        this.totalHourAlmacen = attrs.totalHourAlmacen;
        this.totalHourAlmacenFormated = attrs.totalHourAlmacenFormated;
        this.totalHoursPend = attrs.totalHoursPend;
        this.totalHoursPendFormated = attrs.totalHoursPendFormated;
        this.totalHoursRepairs = attrs.totalHoursRepairs;
        this.totalHoursRepairFormated = attrs.totalHoursRepairFormated;
        this.deadTime = attrs.deadTime;
        this.deadTimeFormated = attrs.deadTimeFormated;
        this.timeDriving = attrs.timeDriving;
        this.timeDrivingFormated = attrs.timeDrivingFormated;
        this.totalTimeCamp = attrs.totalTimeCamp;
        this.totalTimeCampFormated = attrs.totalTimeCampFormated;
        this.porcRepair = attrs.porcRepair;
        this.workEvaluation = attrs.workEvaluation;
        this.totalPromedio = attrs.totalPromedio;
        this.totalPromedioPon = attrs.totalPromedioPon;
        this.totalPromPonPorc = attrs.totalPromPonPorc;
        this.penalizacion = attrs.penalizacion;
        ///////////////////////////////////////////////////////////
    }
}

export class TaskMarkers {

    luminaire_id: string;
    reference_id: string;
    latitude: number;
    longitude: number;
    status: number;
    repair_id: string;
    event: string;
    isSelected: boolean;
    time: string;
    marker: google.maps.Marker;

    readonly markerIcons = {
        normal: [
            'assets/img/svg/luminaire_normal-4.svg',
            'assets/img/svg/luminaire_normal-2.svg',
            'assets/img/svg/luminaire_normal-4.svg',
            'assets/img/svg/luminaire_normal-4.svg',
            'assets/img/svg/luminaire_normal-3.svg',
            'assets/img/svg/luminaire_normal-1.svg',
        ],
        selected: [
            'assets/img/svg/luminaire_selected-0.svg',
            'assets/img/svg/luminaire_selected-1.svg',
            'assets/img/svg/luminaire_selected-2.svg',
            'assets/img/svg/luminaire_selected-3.svg',
            'assets/img/svg/luminaire_selected-4.svg'
        ]
    }

    constructor(attrs, event: string = 'normal') {
        this.luminaire_id = attrs.luminaire_id;
        this.reference_id = attrs.reference_id;
        this.latitude = attrs.latitude;
        this.longitude = attrs.longitude;
        this.status = attrs.status;
        this.event = event;
        this.time = attrs.time;
        this.isSelected = false;
    }


    get_icon(): object {
        return {
            url: this.markerIcons[this.event][this.status],
            scaledSize: this.isSelected ? new google.maps.Size(34, 50) : new google.maps.Size(28, 40)
        }
    }

    upadeIcon(): void {
        this.marker.setIcon(this.get_icon());
    }

    paintMarker(map: any): void {
        const latitude = this.latitude;
        const longitude = this.longitude;
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.reference_id,
            position: new google.maps.LatLng(latitude, longitude),
            icon: this.get_icon(),
            map: map
        });
    }

    hideMarker(): void {
        this.marker.setMap(null);
    }
}


export class TaskMaterial {

    name: string;
    allQuantities: number;




    constructor(attrs) {
        this.name = attrs.name;
        this.allQuantities = attrs.allQuantities;

    }
}


export interface ICurrentAndUsedMaterials {
    currentMaterial: [{
        name: string,
        allQuantities: number,
    }],

    usedMaterial: [{
        usedName: string,
        usedQuantities: number,
    }]

}
export class CurrentAndUsedMaterials implements ICurrentAndUsedMaterials {

    currentMaterial: [{
        name: string;
        allQuantities: number;
    }];

    usedMaterial: [{
        usedName: string;
        usedQuantities: number;
    }];

    constructor(attrs: any) {
        this.currentMaterial = attrs.currentMaterial;
        this.usedMaterial = attrs.usedMaterial;
    }

}

