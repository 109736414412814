import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ProjectWattage, MarkerColor } from '@models/project_wattage';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ProjectMaterial } from '@models/project_material';
import { ProjectWattagePayload } from '@models/project-wattage-payload';
import { GenericTable } from '@app/models/generic-table';
import { Material } from '@app/models/material';

@Injectable({
  providedIn: 'root'
})
export class AdministerPowerService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getAvailableMrkerColor() {
    return this.httpClient.get<MarkerColor[]>(`${environment.SERVER_CONFIG}/api/Powers/get-available-marker-colors`)
  }

  // getMarkerColorsAvailable() {

  //   return this.httpClient.get<MarkerColor[]>(`${environment.SERVER_CONFIG}​/api​/Powers​/get-available-marker-colors`)
  //     .pipe(
  //       map(data => data.map(markerColor => new MarkerColor(markerColor))));
  // }

  changeTypeLuminaire(material_cat_id, material_id) {
    const httpParams = new HttpParams()
      .set('material_cat_id', material_cat_id)
      .set('material_id', material_id);
    return this.httpClient.get<Material[]>(`${environment.SERVER_CONFIG}/api/Powers/get-image-luminaires-power`, { params: httpParams })
      .pipe(map(data => data.map(project_material => new Material(project_material))));
  }

  getProjectWattages(page, technologyType) {
    const httpParams = new HttpParams()
      .set('page', page)
      .set('technologyType', technologyType);
    return this.httpClient.get<GenericTable<ProjectWattage>>(
      `${environment.SERVER_CONFIG}/api/Powers/GetPowers`, { params: httpParams }
    ).pipe(
      map(data => new GenericTable<ProjectWattage>(data))
    );
  }

  getProjectMaterials(): Observable<ProjectMaterial[]> {
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/project_materials/luminaires`)
      .pipe(map(data => data.project_materials.map(project_material => new ProjectMaterial(project_material))));
  }

  getAvailableMarkerColor() {
    
  }


  getMakerColors(selected_marker?: string) {
    const params: HttpParams = new HttpParams()
      .set('selected_marker', selected_marker);
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/marker_colors`, { params })
      .pipe(map(data => data.marker_colors.map(marker_color => new MarkerColor(marker_color))));
  }

  // getListMarkerColors() {
  //   return this.httpClient.get<MarkerColor[]>(
  //     `${environment.SERVER_CONFIG}/api/MarkerColor/get-marker-colors`)
  //     .pipe(map(data => data.map(item => new MarkerColor(item))));
  // }


  createProjectWattage(form) {
    return this.httpClient.post<any>(
      `${environment.SERVER_CONFIG}/api/Powers/CreatePowers`, form
    );
  }

  updateProjectWattage(form) {
    return this.httpClient.post<any>(
      `${environment.SERVER_CONFIG}/api/Powers/UpdatePowers`, form
    );
  }

  deleteProjectWattage(project_wattage_id) {
    const httpParams = new HttpParams().set('project_wattage_id', project_wattage_id);
    return this.httpClient.delete<any>(
      `${environment.SERVER_CONFIG}/api/Powers/DeletePowers`, { params: httpParams }
    );
  }

}
