interface MeasurementsInterface {
    id: string,
    entry_voltage: number,
    current: number,
    power: number,
    power_factor: number
}

export class Measurements implements MeasurementsInterface {
    id: string;
    entry_voltage: number;
    current: number;
    power: number;
    power_factor: number;

  constructor(attrs) {
    this.id = attrs.id;
    this.entry_voltage = attrs.entry_voltage;
    this.current = attrs.current;
    this.power = attrs.power;
    this.power_factor = attrs.power_factor;
  }
}