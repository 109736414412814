import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Toastr } from '@shared/toastr/toastr.service'
import { TasksService } from '@pages/tasks/tasks.service';
import { Task } from 'app/models/task';
import { TaskTypeTable } from './task-type';
import { TaskService } from './task.service';
import { timeStamp } from 'console';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  providers: [TasksService, TaskService]
})
export class TaskComponent implements OnInit {
  taskId: string;
  type: string;
  taskTableInformation: TaskTypeTable;
  task: Task;

  constructor(
    private route: ActivatedRoute,
    private _tasks: TasksService,
    private taskTableService: TaskService,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.taskId = params.get('id');
      this.type = params.get('type');
      
      this.getTask_Id(this.taskId);
      // this.getInformationTable(this.taskId);
    });
  }

  private getInformationTable(id: string) {
    this.taskTableService.getSingleTask(id).subscribe(response => {
      // console.log(response);
      this.taskTableInformation = new TaskTypeTable(response);
    });
  }

  private getTask_Id(id: string) {
    console.log(this.type);

    this._tasks.getTaskById(id).subscribe(response => {
      // console.log(response.task);
      this.task = new Task(response.task);
    }, error => {
      this.toastr.error(error);
    });
  }

}
