import { FormGroup } from '@angular/forms';

export class CreateCircuitPayload {
    latitude: string;
    longitude: number;
    location: object

    constructor(luminaireForm: FormGroup) {

        this.latitude = luminaireForm.controls.latitude.value,
        this.longitude = luminaireForm.controls.longitude.value
        this.location = {
            latitude: this.latitude,
            longitude: this.longitude
        }
    }


  toJson(): Object {
    const json = {
        circuit: {
            meter: {
                location: this.location
            }
        }
    };

    return json;
  }
}
