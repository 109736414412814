import { SmartDeviceSingleDetails, SmartDevicesMarkers } from './../../../../models/smart-devices';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GetDevices } from '@app/models/devices';
import { GenericTable } from '@app/models/generic-table';
import { StyleMap } from '@app/models/styleMap';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { Observable, Subscription } from 'rxjs';
import { DevicesService } from '../devices.service';
import { isNullOrUndefined, isUndefined } from 'util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-devices-map',
  templateUrl: './devices-map.component.html',
  styleUrls: ['./devices-map.component.scss'],
  providers: [MapFuncAuxService, CurrentProjectService],
})
export class DevicesMapComponent implements OnInit, OnDestroy {

  @ViewChild('gmap') gmapElement: any;
  private eventsSubscription: Subscription;
  @Input() events: Observable<void>;
  @Input() searchFrom: FormGroup;
  map: google.maps.Map;
  markers: SmartDevicesMarkers[];
  lstInfowindows = [];
  styleMap: StyleMap = new StyleMap();

  constructor(
    private mapFuncAux: MapFuncAuxService,
    private currentProjectService: CurrentProjectService,
    private devicesService: DevicesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.paintMap();
    this.getMarkers();
    this.eventsSubscription = this.events.subscribe(() => {
      this.getMarkers();
    })
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  paintMap() {
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const project = this.currentProjectService.getCurrentProject()
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };


    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })

  }

  getMarkers() {
    if (!isNullOrUndefined(this.markers)) this.markers.forEach(marker => marker.hideMarker());

    this.devicesService.getDevicesMarkers(
      this.searchFrom.get('device').value,
      this.searchFrom.get('circuit').value,
      this.searchFrom.get('device_status').value,
      this.searchFrom.get('circuit_status').value,
      this.searchFrom.get('group').value).subscribe(response => {
        const markers = response.map(marker => new SmartDevicesMarkers(marker));

        markers.forEach(marker => {
          marker.paintMarker(this.map);
          marker.marker.addListener('click', () => {
            this.devicesService.getSingleDeviceDetail(marker.device_id).subscribe(response => {
              this.showInfoWindow(marker, response);
            });
          });
        });
        this.markers = markers;
      });
  }

  private showInfoWindow(smartDevice: SmartDevicesMarkers, details: SmartDeviceSingleDetails) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const strInfo = `
    <div class='container my-0 mx-0 py-0 px-0' style="width: 300px;">
      <div class='row mx-1 my-0 mt-1'>
          <div class='col-7'>
              <div class='my-0'>
                  <strong class="mb-0"> No. Dispositivo: </strong>
              </div>
              <div>
                  ${smartDevice.device_name}
              </div>
              <br>
              <div class='my-0'>
                  <strong class="mb-0"> No. Circuito: </strong>
              </div>
              <div>
                  ${details.circuit_name}
              </div>
              <br>
              <div class='my-0'>
                  <strong class="mb-0"> Estatus: </strong>
              </div>
              <div>
                  ${details.device_status}
              </div>
              <br>
              <div class='mr-1'>
                <a href='u/smart/devices/device-details/${smartDevice.device_id}' onclick='return false;'>
                  <input id='seeDetailDevice' type='button' style=' cursor:pointer;' value='Ver Dispositivo' class='oe--button--info'>
                </a>
              </div>
          </div>
          <div class='col-4'>
              <div class='my-0'>
                  <strong class="mb-0"> Voltaje: </strong>
              </div>
              <div>
                  ${Math.round(details.voltage)} V.
              </div>
              <br>
              <div class='my-0'>
                  <strong class="mb-0"> Coriiente: </strong>
              </div>
              <div>
                  ${Math.round(details.current)} A.
              </div>
              <br>
              <div class='my-0'>
                  <strong class="mb-0"> Consumo: </strong>
              </div>
              <div>
                  ${Math.round(details.kwh)} kWh
              </div>
              <br>
              <div class='mr-1'>
                <a href='u/circuits/${details.circuit_id}' onclick='return false;'>
                  <input id='seeDetailCircuit' type='button' style=' cursor:pointer;' value='Ver Circuito' class='oe--button--info'>
                </a>
              </div>
          </div>
      </div>
  </div>
    `;
    const infowindow = new google.maps.InfoWindow({
      content: strInfo,
    });
    infowindow.open(this.map, smartDevice.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      const seeDetailDevice = document.getElementById('seeDetailDevice');
      const seeDetailCircuit = document.getElementById('seeDetailCircuit');
      seeDetailDevice.addEventListener('click', () =>
        this.seeMoreDetailDevice(smartDevice.device_id));
      seeDetailCircuit.addEventListener('click', () =>
        this.seeMoreDetailCircuit(details.circuit_id));
    }, 500);
  }

  public seeMoreDetailDevice(luminaireID: string): void{
    this.router.navigate([`/u/smart/devices/device-details/${luminaireID}`]);
  }

  public seeMoreDetailCircuit(circuit_id: string) {
    this.router.navigate([`/u/circuits/${circuit_id}`]);
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

}

