import { Component, OnInit } from '@angular/core';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { VersionsService } from './versions.service';
import { AppVersion } from './app-version';
import { Pagination } from 'app/models/pagination';

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html'
})
export class VersionsComponent implements OnInit {
  pagination: Pagination;
  versions: Array<AppVersion>

  constructor(
    private _versions: VersionsService,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.getVersions();
  }

  getVersions(page = 1) {
    this._versions.getVersions(page).subscribe(response => {
      this.versions = response['versions'];
      this.pagination = response['meta']['pagination'];
    }, error => this.toastr.error(error));
  }

  updateVersionStatus(version: AppVersion) {
    version.is_active = !version.is_active;
    this._versions.updateVersion(version).subscribe(() => {
      this.toastr.success('Estado actualizado');
    }, error => {
      this.toastr.error(error);
      version.is_active = !version.is_active;
    });
  }

}
