import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InventorSummaryService } from './inventor-summary.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SummaryToday } from '@app/models/summaryToday';
import { SummaryTomorrow } from '@app/models/summaryTomorrow';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html'
})
export class SummaryComponent implements OnInit {
  @ViewChild('contentModal') contentModal: any;
  currentUser;
  devicesList;

  SummariesToday: SummaryToday[] = [];
  SummariesTomorrow: SummaryTomorrow[] = [];
  materialPerUser: [{
    image: string;
    material: string;
    totalQuantity: number;
  }];

  constructor(
    private inventorSummaryService: InventorSummaryService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: Toastr
  ) {}

  ngOnInit() {

    this.getSummaryToday();
    this.getSummaryTomorrow();
  }

  getSummaryToday() {
    this.inventorSummaryService.getSummaryToday().subscribe(res => {
        this.SummariesToday = res;
       } , error => {
          this.toastr.error(error);
        });
  }

  getSummaryTomorrow() {
    this.inventorSummaryService.getSummaryTomorrow().subscribe(res => {
        this.SummariesTomorrow = res; } , error => {
          this.toastr.error(error);
        });
  }

  showUserMaterial(user) {
    this.currentUser = this.SummariesToday.filter(x => x.oficial_id === user)[0];
    this.devicesList = this.SummariesToday.filter(x => x.oficial_id === user)[0].devices_list.join(',');
  }

  showModalSolicitud() {
    this.modalService.open(this.contentModal).result.then(result => {
      this.inventorSummaryService.updateShoppingCart(this.currentUser.oficial_id)
      .subscribe( request => {
        this.router.navigate(['/u/inventory/cart'],
        {
          relativeTo: this.route,
          queryParams: {
            user: this.currentUser.oficial_id
          }
        });
      }, error => {
        this.toastr.error(error);
      });
    }, reason => {
    });
  }

}
