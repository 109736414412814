import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { Luminaire } from '@models/luminaire';
import { Task } from '@models/task';
import { Lampposts } from '@models/lampposts';
import { LuminairePayload } from '@models/luminaire-payload';
import { LuminaireResponse } from '@models/luminaire-response';
import { SurveyAreResponse } from '@models/survey-area-response';
import { Circuit } from '@models/circuit';
import { GetLamppostsResponse } from '@models/get-lampposts-response';
import { Lamppost } from '@models/lamppost';
import { CreateCircuitPayload } from './type/infrastructure-survey/create-circuit-payload';
import { Trooper } from '@models/trooper';
import { GetTroopersResponse } from '@models/get-troopers-response';
import { map } from 'rxjs/operators';
import { Installation } from '@models/installation';
import { TaskTypeTable } from './task-type';


@Injectable()

export class TaskService {
  constructor(private httpClient: HttpClient) { }


  closeTaskReapir(task_id) {
    const httpParams = new HttpParams().set('task_id', task_id);
    return this.httpClient.get(
      `${environment.SERVER_TASKS}/api/Tasks/get-task-delete-id`,
      { params: httpParams }
    );
  }

  updateTask(task: Task, comment: string, is_finished?: boolean, user?: string, deadline?: string, sector?: string): Observable<object> {

    const json = {
      task: {
        comment: comment,
        is_finished: is_finished,
        user: null,
        deadline: deadline,
        sector: sector
      }
    };
    if (user) {
      json.task['user'] = {
        id: user
      }
    }
    return this.httpClient.put(
      `${environment.SERVER_URL}/tasks/${task.id}`,
      json
    );
  }

  updatePositonLuminarie(luminaire: Luminaire): Observable<object> {
    return this.httpClient.put(
      `${environment.SERVER_URL}/luminaires/${luminaire.id}`,
      {
        "luminaire": {
          "location": {
            "latitude": luminaire.location.latitude,
            "longitude": luminaire.location.longitude
          }
        }
      }
    );
  }

  updatePositonCircuit(circuit: Circuit): Observable<object> {
    return this.httpClient.put(
      `${environment.SERVER_URL}/circuits/${circuit.id}/meters/${circuit.meter.id}`,
      {
        "meter": {
          "location": {
            "latitude": circuit.meter.location.latitude,
            "longitude": circuit.meter.location.longitude
          }
        }
      }
    );
  }

  createLampposts(luminaires: Luminaire[], task: Task): Observable<object> {
    return this.httpClient.post(
      `${environment.SERVER_URL}/tasks/${task.id}/lampposts`,
      {
        "lamppost": {
          "luminaires": luminaires.map(luminaire => {
            return {
              id: luminaire.id
            }
          })
        }
      }
    );
  }

  createLuminaire(luminairePayload: LuminairePayload, task: Task): Observable<LuminaireResponse> {
    return this.httpClient.post<LuminaireResponse>(
      `${environment.SERVER_URL}/tasks/${task.id}/luminaires`,
      luminairePayload.toJson()
    );
  }

  editLuminaire(luminairePayload: LuminairePayload): Observable<LuminaireResponse> {
    return this.httpClient.put<LuminaireResponse>(
      `${environment.SERVER_URL}/luminaires/${luminairePayload.id}`,
      luminairePayload.toJson()
    );
  }

  editCircuit(circuit: any): Observable<object> {
    return this.httpClient.put<object>(
      `${environment.SERVER_URL}/circuits/${circuit.id}`,
      {
        circuit: {
          "comment": circuit.comment
        }
      }
    );
  }

  createCircuit(task: Task, circuitPayload: CreateCircuitPayload): Observable<object> {
    return this.httpClient.post(
      `${environment.SERVER_URL}/tasks/${task.id}/circuits`,
      circuitPayload.toJson()
    );
  }

  addLamppostsToCircuit(task: Task, circuit: Circuit, lampposts: Lamppost[]): Observable<object> {
    return this.httpClient.post(
      `${environment.SERVER_URL}/tasks/${task.id}/circuits/${circuit.id}/lampposts`,
      {
        circuit: {
          lampposts: lampposts.map(lamppost => {
            return { id: lamppost.id }
          })
        }
      }
    );
  }

  deleteLampposts(lamppost: Lamppost): Observable<object> {
    return this.httpClient.delete(
      `${environment.SERVER_URL}/lampposts/${lamppost.id}`);
  }

  deleteLuminaire(luminaire: Luminaire): Observable<object> {
    return this.httpClient.delete(
      `${environment.SERVER_URL}/luminaires/${luminaire.id}`);
  }

  deleteLamppostInCircuit(circuit: Circuit, lamppost: Lampposts): Observable<object> {
    return this.httpClient.delete(
      `${environment.SERVER_URL}/circuits/${circuit.id}/lampposts/${lamppost.id}`);
  }

  deleteCircuit(task: Task, circuit: Circuit): Observable<object> {
    return this.httpClient.delete(
      `${environment.SERVER_URL}/tasks/${task.id}/circuits/${circuit.id}`);
  }
  // survey area no migrado --> se paso a new-quick-survey-area.service
  getSuveryAreas(): Observable<SurveyAreResponse> {
    return this.httpClient.get<SurveyAreResponse>(
      `${environment.SERVER_URL}/survey_areas`);
  }



  getSingleTask(task_id) {
    const params = new HttpParams()
      .set('task_id', task_id);
    return this.httpClient.get<TaskTypeTable>(`${environment.SERVER_TASKS}/api/Tasks/get-task`, { params: params }).pipe(
      map(elements => new TaskTypeTable(elements))
    );
  }

  getProjectLampposts(page: number, taskID?: string): Observable<GetLamppostsResponse> {
    const params: HttpParams = new HttpParams()
      .set('page', page.toString())
      .set('filter', taskID);
    return this.httpClient
      .get<GetLamppostsResponse>(`${environment.SERVER_URL}/lampposts`, { params: params });
  }

  getNewProjectLampposts(latitude: number, longitude: number, task_id: string) {
    const params = new HttpParams().set('latitude', latitude.toString()).set('longitude', longitude.toString()).set('task_id', task_id);

    return this.httpClient.get<GetLamppostsResponse>(
      `${environment.SERVER_TASKS}/api/Task_Infrastructure/GetLampposts`, { params: params }
    );
  }

  closeTask(task_id: string, is_Finished: boolean) {
    const task = { task_id: task_id, is_Finished: is_Finished };

    return this.httpClient.post(
      `${environment.SERVER_TASKS}/api/Task_Installation/CloseTask`, task
    );
  }

  getTroopers(): Observable<Trooper[]> {
    return this.httpClient
      .get<GetTroopersResponse>(`${environment.SERVER_URL}/troopers`)
      .pipe(map(data => data.troopers.map(trooper => new Trooper(trooper))));
  }

  getInstallation(id: string): Observable<Installation> {
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/installations/${id}`)
      .pipe(map(data => new Installation(data.installation)));
  }
  getInstallationNew(id: string): Observable<Installation> {
    const httpParams = new HttpParams()
      .set('id', id)
    return this.httpClient.get<Installation>(
      `${environment.SERVER_TASKS}/api/Task_Installation/GetInstallation`, { params: httpParams }
    );
  }
  getExportInstallation(id: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    return this.httpClient
      .get<any>(
        `${environment.SERVER_URL}/tasks/${id}/export`,
        { headers: headers, responseType: "arraybuffer" } as Object
      );
  }

  getRepairMaterials(task_id) {
    const httpParams = new HttpParams().set('task_id', task_id);
    return this.httpClient.get<any>(
      `${environment.SERVER_TASKS}/api/Repairs/GetRepairMaterials`, { params: httpParams }
    )
  }

  initRepair(element_id, task_id, user_id, cap_id) {
    const httpParams = new HttpParams()
      .set('element_id', element_id)
      .set('task_id', task_id)
      .set('element_type', 'Luminaire')
      .set('user_id', user_id)
      .set('cap_id', cap_id);

      return this.httpClient.get(`${environment.SERVER_IOS}/api/Repairs/Create_Repair`, {params: httpParams});
  }

  downloadMap(task_id) {
    const httpParams = new HttpParams().set('task_id', task_id);
    this.httpClient.get(
      `${environment.SERVER_TASKS}/api/Repairs/RecoverPrintable`, { params: httpParams, responseType: 'blob' }).subscribe(file => {
        this.downloadProcess(file, 'Hoja-Reparación.pdf');
      });
  }

  private downloadProcess(file, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([file], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}
