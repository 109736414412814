import { Component, OnInit, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { RpuDetailService } from './rpu-detail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RpuDetail, RpuCircuitDetail, RpuLuminaireWattage, RpuLamppostDetail, Period } from './rpu-detail';
import { RpuHistoric, PeriodHistoric } from './rpu-historic';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { GenericTable } from '@app/models/generic-table';
import { Circuit } from '@models/circuit';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { GeneralViewService } from '@app/pages/general-view/general-view.service';
import { Luminaire } from '@app/models/luminaire';
import { Lamppost } from '@app/models/lamppost';
import { LuminaireEnum } from '@app/models/luminaire-enum';
import { TechnologyEnum } from '@app/models/technology-enum';
import { SubstationEnum } from '@app/models/substation_type-enum';
import { SubstationCurrentCapacityEnum } from '@app/models/substation_current_capacity-enum';
import { MountingTypeEnum } from '@app/models/mounting_type-enum';
import { concat } from 'rxjs';
import { ChartDataSets, ChartOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Pipe({ name: 'getCircuitsWithOutreference' })

// tslint:disable-next-line:class-name
export class getCircuitsWithOutreferencePipe implements PipeTransform {
  transform(circuits: any[], reduce: boolean = false): string {
    if (reduce && circuits.length >= 3) {
      circuits = [circuits[0], circuits[1], circuits[2]]
    }

    return circuits.map(circuit => circuit).join(', ');
  }
}


@Component({
  selector: 'app-rpu-detail',
  templateUrl: './rpu-detail.component.html',
  styleUrls: ['./rpu-detail.component.scss'],
  providers: [MapFuncAuxService]
})
export class RpuDetailComponent implements OnInit {

  lastmonth: Period;
  rpuDetail: RpuDetail;
  rpuHistoric: RpuHistoric;
  elements: Period[];
  elementsHistoric: Period[];
  circuits_names: string[];
  luminaireEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));
  substationEnum = Object.keys(SubstationEnum).filter(key => !isNaN(Number(SubstationEnum[key])));
  substationCurrentCapacityEnum = Object.keys(SubstationCurrentCapacityEnum).filter(key => key.length > 2);
  mountingTypeEnum = Object.keys(MountingTypeEnum).filter(key => !isNaN(Number(MountingTypeEnum[key])));
  lstInfowindows = [];
  rpuDetailEnergiaSearchForm: FormGroup;
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  circuits: RpuCircuitDetail[] = [];
  lamppostMarkers = [];
  rpu_id: string;
  page = 0;
  year = 0;
  month = 0;
  vistaHistorial = false;
  data: number[] = [];
  public barChartData: ChartDataSets[] = [];
  barChartPlugins = [pluginDataLabels];
  chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
      yAxes: [{}]
    },
    plugins: {
      datalabels: {
        color: 'black',
        anchor: 'center',
        align: 'center',
        font: {
          size: 10
        },
        formatter: (value) => {
          if (this.parameterTypeData == true) return '$' + value
          return value;
        }
      }
    },
    legend: {
      display: true,
      position: 'bottom'
    }
  };
  chartColors: any[] = [
    {
      backgroundColor: ['#FF7360', '#6FC8CE', '#FAFFF2', '#FFFCC4', '#B9E8E0']
    }
  ];
  chartLabels = [];
  historicYears = [''];
  parameterTypeData = false;
  parameterTypePeriod = false;

  constructor(
    private toastr: Toastr,
    private currentProjectService: CurrentProjectService,
    private Service: RpuDetailService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private mapFuncAux: MapFuncAuxService,
    private generalViewService: GeneralViewService,
    private router: Router,
  ) {
    this.rpu_id = this.route.snapshot.paramMap.get('rpu')
  }

  ngOnInit() {
    this.getHistoricYear();
    this.paintMap();
  }

  private getHistoricYear() {
    this.historicYears = ['Historial'];
    var yearCurrent = new Date().getFullYear();
    var yearBase = this.currentProjectService.getCurrentProject().energy_year;
    if (this.vistaHistorial) this.historicYears.push(yearBase.toString());
    while (yearBase < yearCurrent) {
      yearBase++;
      this.historicYears.push(yearBase.toString());
    }
  }

  getDetailRpu() {
    this.chartLabels = [];
    this.barChartData = [];
    this.circuits_names = [];
    this.getHistoricYear();
    this.circuits.forEach(circuit => {
      circuit.lampposts.forEach(lamp => lamp.luminaires.forEach(lum => lum.hideMarker()));
      circuit.hideMarker();
    });
    this.Service.GetRpuDetail(this.rpu_id, this.currentProjectService.getCurrentProject().energy_year).subscribe(
      response => {
        if (response.circuits.length > 0) {
          this.circuits = response.circuits.map(circuit => new RpuCircuitDetail(circuit));
          this.circuits_names = response.circuits_names;
          this.addCircuits(this.circuits);
        }
        const mapDashboard = new RpuDetail(response);
        this.rpuDetail = mapDashboard;
        this.setUpGraph(mapDashboard.GetByYearAndType(this.year, this.parameterTypePeriod), this.parameterTypeData);
        this.lastmonth = mapDashboard.GetLastValue();
      }

    );
  }

  getHistoricRpu() {
    this.chartLabels = [];
    this.barChartData = [];
    this.getHistoricYear();
    this.Service.GetRpuHistoric(this.rpu_id).subscribe(
      response => {
        const mapDashboardHistoric = new RpuHistoric(response);
        this.rpuHistoric = mapDashboardHistoric;
        this.setUpGraphHistoric(mapDashboardHistoric.GetByYearAndType(this.year, this.parameterTypePeriod), this.parameterTypeData);
      }
    );
  }

  setUpGraph(periods: Period[], parameterTypeData) {

    periods.sort(function (a, b) {
      return a.numberMonth - b.numberMonth;
    });
    periods.sort(function (a, b) {
      return a.year - b.year;
    });

    this.elements = JSON.parse(JSON.stringify(periods));
    this.elements.reverse();
    periods.map(x => { return this.parameterTypePeriod ? x.year : x.monthString + "-" + x.year }).forEach(label => {

      this.chartLabels.push(label);
    });
    this.barChartData = [
      { data: periods.map(x => { return (parameterTypeData == false ? Math.round(x.estimatedConsumptionKwh) : Math.round(x.estimatedConsumptionMxn)) }), label: 'Estimado', type: 'line', backgroundColor: 'rgba(0,0,0,0)' },
      { data: periods.map(x => { return (parameterTypeData == false ? Math.round((x.kwh - x.aditionalKwh) < 0 ? 0 : (x.kwh - x.aditionalKwh)) : Math.round((x.mxn - x.aditionalMxn) < 0 ? 0 : (x.mxn - x.aditionalMxn))) }), label: 'Consumido', stack: 'a', },
      { data: periods.map(x => { return (parameterTypeData == false ? (x.kwh === 0 ? 0 : Math.round(x.aditionalKwh)) : (x.kwh === 0 ? 0 : Math.round(x.aditionalMxn))) }), label: 'Aicional', stack: 'a', },
      { data: periods.map(x => { return (parameterTypeData == false ? Math.round(x.savingKwh) : Math.round(x.savingMxn)) }), label: 'Ahorro', stack: 'a', },

    ];
  }

  setUpGraphHistoric(periods: PeriodHistoric[], parameterTypeData) {
    this.elementsHistoric = JSON.parse(JSON.stringify(periods));
    this.elementsHistoric.reverse();
    console.log(this.elementsHistoric);
    periods.map(x => { return this.parameterTypePeriod ? x.year : x.month + "-" + x.year }).forEach(label => {
      this.chartLabels.push(label);
    });
    this.barChartData = [
      { data: periods.map(x => { return (parameterTypeData == false ? x.kwh : Math.round(x.mxn)) }), label: 'Consumido', stack: 'a', },
    ];
  }

  private addCircuits(circuits: RpuCircuitDetail[]): void {
    const center = new google.maps.LatLng(circuits[0].meter.location.latitude, circuits[0].meter.location.longitude);
    this.map.setCenter(center);
    for (const circuit of circuits) {
      circuit.paintMarker(this.map);
      circuit.marker.addListener('click', () => {
        this.showCircuit(circuit);
        this.showInfoCircuit(circuit);

      });
      for (const lamppost of circuit.lampposts) {
        for (const luminaire of lamppost.luminaires) {
          luminaire.paintMarker(this.map);
          luminaire.marker.addListener('click', () => {
            this.showCircuit(circuit);
            this.showInfoMarker(luminaire, lamppost);
            this.addLamppostAnimation(lamppost, null);
          });
          luminaire.marker.addListener('mouseover', () => {
            lamppost.upadeIcon('hover');
          });
          luminaire.marker.addListener('mouseout', () => {
            lamppost.upadeIcon(circuit.event);
          });
          this.lamppostMarkers.push(luminaire.marker);
        }
      }
    }
  }

  addLamppostAnimation(lamppost: RpuLamppostDetail, animation: google.maps.Animation) {
    lamppost.luminaires.forEach((luminaire: RpuLuminaireWattage) => {
      const marker: google.maps.Marker = this.lamppostMarkers.find(markers => markers.getTitle() == luminaire.id);
      marker.setAnimation(animation);
    })
  }

  private showInfoMarker(luminaire: RpuLuminaireWattage, lamppost?: RpuLamppostDetail) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.generalViewService.getLuminaire(luminaire.id)
      .subscribe((_luminaire: Luminaire) => {
        const strInfo = this.getStringInfo(_luminaire, lamppost);
        const infowindow = new google.maps.InfoWindow({ content: strInfo });
        infowindow.open(this.map, luminaire.marker);
        window.setTimeout(() => {
          const seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
          // let btnEditLum = document.getElementById('luminaireEdit');
          // btnEditLum.addEventListener('click', () => this.saveEditLum(luminaire));
          seeMoreLuminaire.addEventListener('click', () => this.mapFuncAux.seeMoreLuminaire(luminaire.id));
        }, 500);
        this.lstInfowindows.push(infowindow);
      }, error => {
        this.toastr.error(error);
      })
  }

  private getStringInfo(luminaire: Luminaire, lamppost?: RpuLamppostDetail): string {

    return `
    <div class='container my-0 mx-0 py-0 px-0'>
      <div class='row mx-1 my-0 mt-1'>
        <div class='col-6'>
          <div class='row'>
            <div class='col-12 px-0'>
              <strong>${luminaire.reference_id ? 'Referencia' : 'ID'}</strong>
            </div>
          </div>
          <div class='row mb-1'>
            <div class='col-12 px-0'>${luminaire.reference_id || luminaire.id}</div>
          </div>
          <div class='row'>
            <div class='col-12 px-0'>
              <strong>Tipo</strong>
            </div>
          </div>
          <div class='row mb-1'>
            ${this.luminaireEnum[luminaire.type]}
          </div>
          <div class='row'>
            <div class='col-12 px-0'><strong>Tecnología</strong></div>
          </div>
          <div class='row mb-1'>
            ${luminaire.technology.name}
          </div>
          <div class='row'>
            <div class='col-12 px-0'><strong>Fotocelda</strong></div>
          </div>
          <div class='row mb-1'>
            ${luminaire.has_photocell ? 'Si' : 'No'}
          </div>
          <div class='row'>
            <div class='col-12 px-0'><strong>Ubicación</strong> (lat, lon)</div>
          </div>
          <div class='row mb-1'>
            <div class='col-12 px-0'>(${luminaire.location.latitude}, ${luminaire.location.longitude})</div>
          </div>
        </div>
        <div class='col-6'>
          ${lamppost ? `
          <div class='row'>
            <div class='col-12'><strong>Poste ID</strong></div>
          </div>
          <div class='row'>
            <div class='col-12 mb-1'>
              ${lamppost.id}
            </div>
          </div>
          ` : ``}
          <div class='row'>
            <div class='col-12'><strong>Comentarios</strong></div>
          </div>
          <div class='row'>
            <div class='col-12 mb-1'>
              <textarea id='textAreaComment' style='width: 100%;' rows='6' cols='40'>${luminaire.comment == null ? '' : luminaire.comment}</textarea>
            </div>
          </div>
          <div class='row'>
            <div class='col-6'>
              <a href='u/luminaires/${luminaire.id}' onclick='return false;'>
                <input id='seeMoreLuminaire' type='button' style='position: absolute; cursor:pointer;' value='Ver mas' class='oe--button--info'>
              </a>
            </div>
            <div class='col-6'>
              <input id='luminaireEdit' type='button' style='position: absolute; right: 14px;' value='Guardar' class='oe--button--info'>
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
  }

  private showInfoCircuit(circuit: RpuCircuitDetail): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.generalViewService.getCircuit(circuit.id)
      .subscribe((_circuit: Circuit) => {
        const strInfo = this.mapFuncAux.getStringInfoCircuit(_circuit, true);
        const infowindow = new google.maps.InfoWindow({ content: strInfo });
        infowindow.open(this.map, circuit.marker);
        window.setTimeout(() => {
          const seeMoreCircuit = document.getElementById('seeMoreCircuit');
          // const btnEditCircuit = document.getElementById('btnEditCircuit');
          // const btnFirstLamppostCircuit = document.getElementById('btnFirstLamppost');
          // const btnLastLamppostCircuit = document.getElementById('btnLastLamppost');
          seeMoreCircuit.addEventListener('click', () => this.seeMoreCircuit(circuit.id));
          // btnFirstLamppostCircuit.addEventListener('click', () => this.clickPositionLamppost(circuit, true));
          // btnLastLamppostCircuit.addEventListener('click', () => this.clickPositionLamppost(circuit, false));
        }, 500);
        this.lstInfowindows.push(infowindow);
      }, error => {
        this.toastr.error(error);
      });

  }

  private seeMoreCircuit(circuitID: string): void {
    this.router.navigate([`/u/circuits/${circuitID}`]);
  }


  private showCircuit(circuit: RpuCircuitDetail): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();
    circuit.upadeIcon('circuitShowing');
  }

  private hideCircuit(): void {
    const _circuit = this.circuits.find(__circuit => __circuit.event == 'circuitShowing');
    if (_circuit) {
      const event = _circuit.isSelected ? 'selected' : 'normal';
      _circuit.upadeIcon(event);
    }
  }

  paintMap() {
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const project = this.currentProjectService.getCurrentProject()
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(15);
        this.map.setCenter(results[0].geometry.location);
        this.getDetailRpu();
      }
    })

    this.map.addListener('click', () => {
      this.hideCircuit();
    });
  }

  GetDataRpu(event: NgbTabChangeEvent) {
    this.chartLabels = [];
    this.barChartData = [];
    if (event.nextId == 'kwh') {
      this.parameterTypeData = false;
      if (!this.vistaHistorial) this.setUpGraph(this.rpuDetail.GetByYearAndType(this.year, this.parameterTypePeriod), false);
      if (this.vistaHistorial) this.setUpGraphHistoric(this.rpuHistoric.GetByYearAndType(this.year, this.parameterTypePeriod), false);
    }
    if (event.nextId == 'mxn') {
      this.parameterTypeData = true;
      if (!this.vistaHistorial) this.setUpGraph(this.rpuDetail.GetByYearAndType(this.year, this.parameterTypePeriod), true);
      if (this.vistaHistorial) this.setUpGraphHistoric(this.rpuHistoric.GetByYearAndType(this.year, this.parameterTypePeriod), true);
    }
  }

  GetPeriodRpu(event: NgbTabChangeEvent) {
    this.chartLabels = [];
    this.barChartData = [];
    if (event.nextId == 'month') {
      this.parameterTypePeriod = false;
      if (!this.vistaHistorial) this.setUpGraph(this.rpuDetail.GetByYearAndType(this.year, false), this.parameterTypeData);
      if (this.vistaHistorial) this.setUpGraphHistoric(this.rpuHistoric.GetByYearAndType(this.year, false), this.parameterTypeData);
    }
    if (event.nextId == 'year') {
      this.parameterTypePeriod = true;
      if (!this.vistaHistorial) this.setUpGraph(this.rpuDetail.GetByYearAndType(this.year, true), this.parameterTypeData);
      if (this.vistaHistorial) this.setUpGraphHistoric(this.rpuHistoric.GetByYearAndType(this.year, true), this.parameterTypeData);
    }
  }

  GetVista(event: NgbTabChangeEvent) {
    if (event.nextId == 'tariff') {
      this.vistaHistorial = false;
      this.getDetailRpu();
    }
    if (event.nextId == 'historic') {
      this.vistaHistorial = true;
      this.getHistoricRpu();
    }
  }

  UpdateYear(event) {
    this.chartLabels = [];
    this.barChartData = [];
    if (event == 'Historial') {
      this.year = 0;
    } else {
      this.year = event;
    }
    if (!this.vistaHistorial) this.setUpGraph(this.rpuDetail.GetByYearAndType(this.year, this.parameterTypePeriod), this.parameterTypeData);
    if (this.vistaHistorial) this.setUpGraphHistoric(this.rpuHistoric.GetByYearAndType(this.year, this.parameterTypePeriod), this.parameterTypeData);

  }

  getActionArrow(next: boolean) {
    this.Service.nextRpuDetail(this.rpu_id, next).subscribe(
      response => {
        this.rpu_id = response;
        this.getDetailRpu();
      }
    );
  }

}
