import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { StyleMap } from '@app/models/styleMap';
import { VirtualcircuitsService } from '../virtualcircuits.service';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Luminaires } from '../virtualcircuitsmodel';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Console } from 'console';

@Component({
  selector: 'app-add-circuit',
  templateUrl: './add-circuit.component.html',
  styleUrls: ['./add-circuit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MapFuncAuxService, VirtualcircuitsService]
})
export class AddCircuitComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('modalInformation') ngModalInformation: any;
  addLuminairesCircuit = [];
  luminaire: Luminaires[] = [];
  luminairesNew: Luminaires[] = [];
  lstInfowindows = [];
  formGroupCircuit: FormGroup;
  formGroupMeter: FormGroup;
  styleMap: StyleMap = new StyleMap();
  map: google.maps.Map;
  marker: google.maps.Marker;
  location = {
    latitude: 0,
    longitude: 0
  };
  constructor(
    private currentProjectService: CurrentProjectService,
    private mapFuncAux: MapFuncAuxService,
    private modalService: NgbModal,
    private toastr: Toastr,
    private virtualCircuitService: VirtualcircuitsService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.formGroupCircuit = this.formBuilder.group({
      RPU: [null, Validators.required],
      comment: [null],
      tariffType: [null, Validators.required],
      identifier: [null],
      is_VirtualCircuit: 'false'
    });
    this.paintMap();
  }

  paintMap() {
    const latitude = 21.171642601705347;
    const longitude = -86.8438888306971;
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };


    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);

    this.map.addListener('dragend', () => this.changeZoom());
    this.map.addListener('click', (e) => {
      if (this.marker !== undefined) {
        this.marker.setMap(null);
      }
      this.placeMarker(e.latLng)
    });
    const project = this.currentProjectService.getCurrentProject();
    const service = new google.maps.places.PlacesService(this.map);
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(16);
        this.map.setCenter(results[0].geometry.location);
      }
    });

  }

  actionsCircuits() {
    const val = this.formGroupCircuit.get('is_VirtualCircuit').value;
    if (val === 'true') {
      this.location.latitude = 0;
      this.location.longitude = 0;
      if (this.marker !== undefined) {
        this.marker.setMap(null);
      }
      return;
    }
  }


  placeMarker(latLng: google.maps.LatLng) {
    if (this.formGroupCircuit.get('is_VirtualCircuit').value === 'false') {
      this.location = {
        latitude: latLng.lat(),
        longitude: latLng.lng()
      };
      this.marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        icon: 'assets/img/svg/circuit_normal.svg'
      });
      this.map.panTo(latLng);
      this.getLuminairesPhysicalCircuit(latLng.lat(), latLng.lng());
    }
  }

  getLuminairesPhysicalCircuit(latitude, longitude) {
    // const mySet = new Set();
  
    this.virtualCircuitService.getListLuminairesPhysicalCircuit(latitude, longitude).subscribe(
      response => {
        // const luminarias = [];
        for (let i = 0; i < response.length; i++) {
          // tslint:disable-next-line:max-line-length
          if (this.luminaire.filter(fil => fil.id === response[i].id).length === 0) {
            this.luminaire.push(response[i]);
            this.luminairesNew.push(response[i]);
          }
        }
        this.paintLuminaire(this.luminairesNew);
      }
    )
  }

  private paintLuminaire(luminaires: Luminaires[]) {
    for (const items of luminaires) {
      items.paintMarker(this.map);
      items.marker.addListener('click', () => {
        this.addLuminaires(items);
      });
      items.marker.addListener('rightclick', () => {
        this.deleteLuminaires(items);
      });
    }
    this.luminairesNew = [];
  }

  private addLuminaires(luminaria: Luminaires) {
    if (this.addLuminairesCircuit.filter(fil => fil.id === luminaria.id).length === 0) {
      const lumAdd = {
        id: luminaria.id,
        lamppost_id: luminaria.lamppost_id,
        is_selected: true,
        is_assignated: luminaria.is_assignated
      }
      this.addLuminairesCircuit.push(lumAdd);
      luminaria.upadateIcon('selected');
    }
  }

  private deleteLuminaires(luminaria: Luminaires) {
    if (this.addLuminairesCircuit.filter(fil => fil.id === luminaria.id).length === 1) {
      this.deleteLuminairesExt(luminaria);
    }
  }


  private deleteLuminairesExt(luminaria: Luminaires) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const strInfo = `
  <div>
    <div class='d-flex justify-content-between'>
      <input id='deleteLumExt' type='button' style='cursor:pointer;' value='eliminar del Circuito' class='oe--button--info mr-1'>
    </div>
  </div>
  `;
    const infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340
    });
    infowindow.open(this.map, luminaria.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      const btnAddLumCircuit = document.getElementById('deleteLumExt');
      btnAddLumCircuit.addEventListener('click', () => {
        this.addLuminairesCircuit = this.addLuminairesCircuit.filter(fil => fil.id !== luminaria.id);
        luminaria.upadateIcon('normal');
        infowindow.close();
      });
    }, 60);
  }



  changeZoom() {
    // this.formGroupCircuit.value.is_VirtualCircuit === '1'
    if (this.map.getZoom() >= 15) {
      this.virtualCircuitService.getListLuminairesPhysicalCircuit(this.map.getCenter().lat(), this.map.getCenter().lng())
        .subscribe(resultado => {
          for (let i = 0; i < resultado.length; i++) {
            // tslint:disable-next-line:max-line-length
            if (this.luminaire.filter(fil => fil.id === resultado[i].id).length === 0) {
              this.luminaire.push(resultado[i]);
              this.luminairesNew.push(resultado[i]);
            }
          }
          this.paintLuminaire(this.luminairesNew);
        });
    }
  }




  onSave() {
    if (this.formGroupCircuit.invalid) {
      this.toastr.singleError('Por favor llene los campos requeridos');
      return;
    }
    // tslint:disable-next-line:max-line-length
    if (this.location.latitude === 0 && this.location.longitude === 0 && this.formGroupCircuit.get('is_VirtualCircuit').value === 'false') {
      this.modalService.open(this.ngModalInformation, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
        result => {
        },
        reason => {
        });
      return;
    }

    if (this.addLuminairesCircuit.length === 0) {
      this.modalService.open(this.ngModalInformation, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
        result => {
        },
        reason => {
        });
      return;
    }

    const insert = {
      rpu: this.formGroupCircuit.get('RPU').value,
      tariffType: this.formGroupCircuit.get('tariffType').value,
      identifier: this.formGroupCircuit.get('identifier').value,
      comment: this.formGroupCircuit.get('comment').value,
      is_VirtualCircuit: this.formGroupCircuit.get('is_VirtualCircuit').value,
      location: this.location,
      valuesluminaires: this.addLuminairesCircuit
    }
    this.virtualCircuitService.registerVirtualCircuits(insert).subscribe();

    this.addLuminairesCircuit = [];
    this.lstInfowindows = [];
    this.toastr.success('Se creo correctamente el circuito.');
    this.onBack();
  }

  public changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  onBack() {
    this.router.navigate(['/u/virtual-circuits/']);
  }



}
