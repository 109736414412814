
interface BaseInterface {
    id: string,
    is_present: boolean,
    bolt_spacing: number,
    height: number,
    width: number,
    length: number,
    condition: number,
    image_url: string,
    material: number
}

export class Base implements BaseInterface {
    id: string;
    is_present: boolean;
    bolt_spacing: number;
    height: number;
    width: number;
    length: number;
    condition: number;
    image_url: string;
    material: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.is_present = attrs.is_present;
    this.bolt_spacing = attrs.bolt_spacing;
    this.height = attrs.height;
    this.width = attrs.width;
    this.length = attrs.length;
    this.condition = attrs.condition;
    this.image_url = attrs.image_url;
    this.material = attrs.material;
  }
}
