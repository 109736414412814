export interface ITicketsStatus {
    label: string,
    amount: number
}

export class TicketsStatus implements ITicketsStatus {
    label: string;
    amount: number;

    constructor(attrs) {
        this.label = attrs.label,
        this.amount = attrs.amount
    }

}
