
export interface IRpuHistoric {
    table_list: PeriodHistoric[];
}

export class RpuHistoric implements IRpuHistoric {
    table_list: PeriodHistoric[];

    constructor(attrs: any) {
        this.table_list = attrs.table_list;
    }

    GetByYearAndType(year_selected = 0, type = false) {
        var period: PeriodHistoric[] = [];
        if (year_selected == 0 && !type) {
            return this.table_list
        }

        if (type) {
            let years = year_selected == 0 ? this.table_list.map(x => x.year) : this.table_list.filter(x => x.year == year_selected).map(x => x.year);
            years = years.filter((n, i) => years.indexOf(n) === i);
            years.forEach(year => {
                const current_year = this.table_list.filter(x => x.year == year);
                const kwh = current_year.reduce((kwh, current) => kwh + current.kwh, 0);
                const mxn = current_year.reduce((mxn, current) => mxn + current.mxn, 0);
                const countMonths = current_year.length;

                period.push(new PeriodHistoric({ year: year, month: "", countMonths: countMonths, kwh: kwh, mxn: Math.round(mxn) }));
            });
            return period;
        } else {
            return this.table_list.filter(x => x.year == year_selected);
        }
    }
}

export class PeriodHistoric {
    month: string;
    year: number;
    numberMonth: number;
    countMonths: number;
    kwh: number;
    mxn: number;
    countDays: number;

    constructor(attrs: any) {
        this.month = attrs.month;
        this.year = attrs.year;
        this.numberMonth = attrs.numberMonth;
        this.countMonths = attrs.countMonths;
        this.kwh = attrs.kwh;
        this.mxn = attrs.mxn;
        this.countDays = attrs.countDays;
    }
}



