import { GeneralViewWattage } from '@app/pages/general-view/general-view-project-wattages';
import { DetailMarkerColor } from './detail-marker-color';

export class SmartDevicesTable {
  device_id: string;
  device_name: string;
  circuit_name: string;
  voltage: number;
  current: number;
  kwh: number;
  device_status: string;
  circuit_status: boolean;
  last_record_date: string;
  delayInMins: number;

  constructor(attrs) {
    this.device_id = attrs.device_id;
    this.device_name = attrs.device_name;
    this.circuit_name = attrs.circuit_name;
    this.voltage = attrs.voltage;
    this.current = attrs.current;
    this.kwh = attrs.kwh;
    this.device_status = attrs.device_status;
    this.circuit_status = attrs.circuit_status;
    this.last_record_date = attrs.last_record_date;
    this.delayInMins = attrs.delayInMins;
  }
}

export class SmartDeviceSingleDetails {
  circuit_id: string;
  circuit_name: string;
  voltage: number;
  current: number;
  device_status: string;
  kwh: number;
  circuit_status: boolean;



  constructor(attrs) {
    this.circuit_id = attrs.circuit_id;
    this.circuit_name = attrs.circuit_name;
    this.voltage = attrs.voltage;
    this.current = attrs.current;
    this.kwh = attrs.kwh;
    this.device_status = attrs.device_status;
    this.circuit_status = attrs.circuit_status;
  }



}

export class SimpleSmartGroup {
  id: string;
  name: string;
  constructor(attrs) {
    this.id = attrs.id;
    this.name = attrs.name;
  }
}

export class SmartDevicesMarkers {
  device_id: string;
  device_name: string;
  circuit_status: string;
  location: {
    latitude: number;
    longitude: number;
  };
  isSelected: boolean;
  marker: google.maps.Marker;

  readonly markerIcons = {
    nomral: 'assets/img/svg/circuit_',
    selected: 'assets/img/svg/circuit_selected.svg',
  }

  constructor(attrs) {
    this.device_id = attrs.device_id;
    this.device_name = attrs.device_name;
    this.circuit_status = attrs.circuit_status;
    this.location = attrs.location;
    this.isSelected = false;
  }

  get_icon() {
    return !this.isSelected ?
      {
        url: `${this.markerIcons.nomral}${this.circuit_status}.svg`
      } :
      {
        url: this.markerIcons.selected
      }
  }

  paintMarker(map) {
    this.marker = new google.maps.Marker({
      title: this.device_name,
      position: new google.maps.LatLng(this.location.latitude, this.location.longitude),
      icon: this.get_icon(),
      map: map
    })
  }

  updateIcon(event) {
    this.isSelected = event;
    this.marker.setIcon(this.get_icon());
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }

}

export class TicketPerDevice {
  id: string;
  reference: string;
}

export class SmartDeviceGeneralDetails {
  device_name: string;
  circuit_name: string;
  group_id: string;
  rpu: string;
  expected_watts: string;
  schedule_id: string;
  volts: string;
  amps: string;
  watts: string;
  on_off: boolean;
  circuit_status: string;
  sim_status: string;
  version: string;
  last_record_date: string;
  iccid: string;
  total_luminaires: number;
  on_time: string;
  off_time: string;
  luminaires_reported: number;
  tickets_unresolved: number;
  markers: DeviceMarkers[];
  wattages: GeneralViewWattage[];
  tickets: TicketPerDevice[];

  constructor(attrs) {
    this.device_name = attrs.device_name;
    this.circuit_name = attrs.circuit_name;
    this.group_id = attrs.group_id ? attrs.group_id : '';
    this.on_time = attrs.fixed_time == null ? null : (attrs.fixed_time.on.hour > 12 ? `${attrs.fixed_time.on.hour}:${attrs.fixed_time.on.minutes} pm` : `${attrs.fixed_time.on.hour}:${attrs.fixed_time.on.minutes} am`);
    this.off_time = attrs.fixed_time == null ? null : (attrs.fixed_time.off.hour > 12 ? `${attrs.fixed_time.off.hour}:${attrs.fixed_time.off.minutes} pm` : `${attrs.fixed_time.off.hour}:${attrs.fixed_time.off.minutes} am`);
    this.rpu = attrs.rpu;
    this.expected_watts = attrs.expected_watts + ' W.';
    this.volts = attrs.volts + ' V.';
    this.amps = attrs.amps + ' A.';
    this.watts = attrs.watts + ' kW.';
    this.on_off = attrs.on_off;
    this.schedule_id = this.setScheduleControl(attrs.schedule_id, attrs.fixed_time);
    this.tickets = attrs.tickets;
    this.markers = attrs.markers.map(marker => new DeviceMarkers(marker));
    this.sim_status = attrs.sim_status;
    this.circuit_status = attrs.circuit_status;
    this.version = attrs.version;
    this.last_record_date = attrs.last_record_date;
    this.iccid = attrs.iccid;
    this.total_luminaires = attrs.total_luminaires;
    this.luminaires_reported = attrs.luminaires_reported;
    this.tickets_unresolved = attrs.tickets_unresolved;
    this.wattages = attrs.wattages.map(x => new GeneralViewWattage(x));
  }

  setScheduleControl(schedule_id, fixed_time) {
    if (schedule_id == null && fixed_time == null) {
      return null;
    } else if (schedule_id == null && fixed_time != null) {
      return 'fijo';
    } else {
      return schedule_id;
    }
  }
}

export class DeviceMarkers {
  element_id: string;
  element_type: string;
  element_status: string;
  element_reference: string;
  municipality_status: number;
  technology: number;
  has_photocell: boolean;
  marker_color: DetailMarkerColor;
  type: number;
  is_ok: boolean;
  location: {
    latitude: number;
    longitude: number;
  };
  isSelected: boolean;
  marker: google.maps.Marker;

  readonly markerIcons = {
    circuit: {
      normal: 'assets/img/svg/circuit_',
      selected: 'assets/img/svg/circuit_selected.svg',
    },
    luminaire: {
      normal: {
        normal: 'assets/img/svg/ic_single_normal.svg',
        selected: 'assets/img/svg/selected-blue.svg'
      },
      assing: {
        normal: 'assets/img/svg/ic_install_pin-',
        selected: 'assets/img/svg/ic_install_selected-',
      }

    }
  }

  constructor(attrs) {
    this.element_id = attrs.element_id;
    this.element_type = attrs.element_type;
    this.element_status = attrs.element_status;
    this.element_reference = attrs.element_reference;
    this.location = attrs.location;
    this.municipality_status = attrs.municipality_status;
    this.technology = attrs.technology;
    this.has_photocell = attrs.has_photocell;
    this.marker_color = attrs.marker_color
    this.type = attrs.type;
    this.is_ok = attrs.is_ok;
    this.isSelected = false;
  }

  get_icon() {
    if (this.element_type === 'circuit') {
      return !this.isSelected ?
        {
          url: `${this.markerIcons.circuit.normal}${this.element_status}.svg`
        } :
        {
          url: this.markerIcons.circuit.selected
        }
    } else {
      if (this.element_status === 'normal') {
        return !this.isSelected ?
          {
            url: this.markerIcons.luminaire.normal.normal,
            scaledSize: new google.maps.Size(15, 15)
          } :
          {
            url: this.markerIcons.luminaire.normal.selected,
            scaledSize: new google.maps.Size(15, 15)
          }
      } else {
        return !this.isSelected ?
          {
            url: `${this.markerIcons.luminaire.assing.normal}${this.element_status}.svg`,
            scaledSize: new google.maps.Size(15, 15)
          } :
          {
            url: `${this.markerIcons.luminaire.assing.selected}${this.element_status}.svg`,
            scaledSize: new google.maps.Size(15, 15)
          }
      }
    }
  }

  buildSVG(): string {
    let svg = '';
    if (this.element_type === 'circuit') {
      svg = `<svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 36">
      <g fill="none" fill-rule="evenodd" transform="translate(1 2)">
        <path fill="#F89728" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
        d="M1.4807623,5.46992268 C3.3812377,2.19976804 6.92251639,2.21715365e-16 10.976918,2.21715365e-16 C17.0393607,2.21715365e-16 21.9538361,4.91677835 21.9538361,10.9820619 C21.9538361,12.5032088 21.644123,13.9512887 21.0860984,15.2688402 C20.5268115,16.5863918 19.7179508,17.7720619 18.7197459,18.7655928 C17.8878934,19.6503351 17.1417869,20.5850515 16.4711475,21.5402448 C12.0775492,27.8027191 10.976918,31.9525515 10.976918,31.9525515 C10.976918,31.9525515 9.78161475,27.1894072 4.90933607,20.7553608 C4.39233607,20.0742139 3.83431148,19.407049 3.23282787,18.7655928 C2.56345082,18.0997809 1.97990984,17.3469201 1.49996721,16.5248711 C0.546483607,14.8974613 0,13.0037629 0,10.9819716 C5.12522629e-15,8.9730799 0.538819672,7.08957474 1.4807623,5.46992268 Z"/>
        <g transform="translate(2.87 2.866)">
          <polygon points="0 0 16.261 0 16.261 16.239 0 16.239"/>
          <polygon fill="#FFF" points="7.014 15.284 7.014 9.91 4.783 9.91 9.246 .955 9.246 6.925 11.478 6.925"/>
        </g>
      </g>
    </svg>`;
    } else {
      if (this.marker_color) {
        svg = `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <g>
                    <path stroke="black" stroke-opacity="12" stroke-width="5" fill="${this.marker_color.fill_color}"
                    d="${this.marker_color.path}"> </path>
                    <path fill="${this.marker_color.fill_color2}" d="${this.marker_color.path2}"> </path>
                </g>
            </svg>`;
      } else {
        svg = `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd" transform="translate(13 13)">
          <path fill="#F14336" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.48662295,5.46992268 C3.38709836,2.19976804 6.92837705,-1.39891394e-14 10.9827787,-1.39891394e-14 C17.0452213,-1.39891394e-14 21.9596967,4.91677835 21.9596967,10.9820619 C21.9596967,12.5032088 21.6499836,13.9512887 21.091959,15.2688402 C20.5326721,16.5863918 19.7238115,17.7720619 18.7256066,18.7655928 C17.8937541,19.6503351 17.1476475,20.5850515 16.4770082,21.5402448 C12.0834098,27.8027191 10.9827787,31.9525515 10.9827787,31.9525515 C10.9827787,31.9525515 9.78747541,27.1894072 4.91519672,20.7553608 C4.39819672,20.0742139 3.84017213,19.407049 3.23868852,18.7655928 C2.56931148,18.0997809 1.98577049,17.3469201 1.50582787,16.5248711 C0.552344262,14.8974613 0.00586065574,13.0037629 0.00586065574,10.9819716 C0.00586065574,8.9730799 0.544680328,7.08957474 1.48662295,5.46992268 Z"/>
          <g transform="translate(2.87 2.866)">
            <polygon points="0 0 16.261 0 16.261 16.239 0 16.239"/>
            <path fill="#FFF" d="M2.6423913,8.11940299 C2.6423913,6.96238806 3.58416667,6.02189055 4.74275362,6.02189055 L7.45289855,6.02189055 L7.45289855,4.73631841 L4.74275362,4.73631841 C2.87275362,4.73631841 1.35507246,6.2519403 1.35507246,8.11940299 C1.35507246,9.98686567 2.87275362,11.5024876 4.74275362,11.5024876 L7.45289855,11.5024876 L7.45289855,10.2169154 L4.74275362,10.2169154 C3.58416667,10.2169154 2.6423913,9.27641791 2.6423913,8.11940299 L2.6423913,8.11940299 Z M5.42028986,8.7960199 L10.8405797,8.7960199 L10.8405797,7.44278607 L5.42028986,7.44278607 L5.42028986,8.7960199 L5.42028986,8.7960199 Z M11.5181159,4.73631841 L8.80797101,4.73631841 L8.80797101,6.02189055 L11.5181159,6.02189055 C12.6767029,6.02189055 13.6184783,6.96238806 13.6184783,8.11940299 C13.6184783,9.27641791 12.6767029,10.2169154 11.5181159,10.2169154 L8.80797101,10.2169154 L8.80797101,11.5024876 L11.5181159,11.5024876 C13.3881159,11.5024876 14.9057971,9.98686567 14.9057971,8.11940299 C14.9057971,6.2519403 13.3881159,4.73631841 11.5181159,4.73631841 L11.5181159,4.73631841 Z"/>
          </g>
        </g>
      </svg>
      `
      }

    }
    return svg;
  }



  paintMarker(map: any): void {
    this.marker = new google.maps.Marker({
      title: this.element_reference,
      position: new google.maps.LatLng(this.location.latitude, this.location.longitude),
      map: map,
      icon: {
        url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.buildSVG())
      },
    })
  }


  paintMarker_(map: any): void {
    this.marker = new google.maps.Marker({
      title: this.element_reference,
      position: new google.maps.LatLng(this.location.latitude, this.location.longitude),
      icon: this.get_icon(),
      map: map
    });
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }
}

export class Readdings30Days implements IReaddings30Days {
  start_date: string;
  end_date: string;
  values_rssi: RSSIDetails[];
  constructor(attrs) {
    this.start_date = attrs.start_date;
    this.end_date = attrs.end_datel;
    this.values_rssi = attrs.values_rssi;
  }
}

export interface IReaddings30Days {
  start_date: string,
  end_date: string,
  values_rssi: RSSIDetails[]
}

export class RSSIDetails implements IRSSIDetails {
  rssi: number;
  date: Date;
  constructor(attrs) {
    this.rssi = attrs.rssi;
    this.date = attrs.date;
  }
}

export interface IRSSIDetails {
  rssi: number,
  date: Date
}
