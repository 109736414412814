import { Component, ViewChild, OnInit } from '@angular/core';
import {
  FormBuilder, FormGroup, Validators
} from '@angular/forms';
import {Router} from "@angular/router"

import { NewUserService } from './new-user.service';
import { NewUserPayload } from '@models/new-user-payload';
import { NewUserResponse } from '@models/new-user-response';
import { Toastr } from '@shared/toastr/toastr.service'
import { UserFormComponent } from '../user-form/user-form.component';

@Component({
  selector: 'new-user-component',
  templateUrl: './new-user.component.html',
  providers: [NewUserService]
})

export class NewUserComponent implements OnInit {
  @ViewChild(UserFormComponent) userFormComponent: UserFormComponent
  // TODO: Custom validator to troop id, only validate if role is troop
  newUserForm: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    last_name: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    role_id: [null, Validators.required],
    cell_phone_number: [""],
    address: [""],
    assigned_tools: [""],
    troops: [this.formBuilder.array([])],
    troop_id: [null],
    password: [null, Validators.required],
    password_confirmation: [null, Validators.required]
  });

  constructor(
    private formBuilder: FormBuilder,
    private newUserService: NewUserService,
    private router: Router,
    private toastr: Toastr
  ) {}

  ngOnInit(){
    this.userFormComponent.getTroops();
  }

  // TODO: Handle invalid form error
  createUser(): void {
    if (this.newUserForm.valid) {

      const requestPayload = new NewUserPayload(this.newUserForm, this.userFormComponent.user_troops);
      this.newUserService
        .createUser(requestPayload)
        .subscribe(
          (newUserResponse: NewUserResponse) => {
            this.toastr.success('Usuario creado.');
            this.router.navigate(['/u/users']);
          },
          (error) => {
            this.toastr.error(error);
          }
        )
    } else {
      this.newUserForm.controls.name.markAsTouched();
      this.newUserForm.controls.last_name.markAsTouched();
      this.newUserForm.controls.email.markAsTouched();
      this.newUserForm.controls.role_id.markAsTouched();
      this.newUserForm.controls.password.markAsTouched();
      this.newUserForm.controls.password_confirmation.markAsTouched();
      if(this.newUserForm.get('troop_id').errors)
        this.newUserForm.controls.troop_id.markAsTouched();
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  performAction(form: FormGroup): void {
    this.newUserForm = form;

    this.createUser();
  }
}
