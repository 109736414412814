import { Component, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toastr } from '@shared/toastr/toastr.service';

import { EditProjectModalService } from './edit-project-modal.service';
import { EditProjectPayload } from './edit-project-payload';
import { EditProjectResponse } from '@models/edit-project-response';

import { Project } from '@models/project';
import { CurrentProjectService } from 'app/shared/cookies/current-project.service';
import { ProjectPhasesEnum } from '@app/models/project_phases-enum';

@Component({
  selector: 'edit-project-modal-component',
  templateUrl: './edit-project-modal.component.html',
  providers: [EditProjectModalService]
})

export class EditProjectModalComponent {
  editProjectForm: FormGroup = this.formBuilder.group(
    {
      name: [null, Validators.required],
      country: [null, Validators.required],
      state: [null, Validators.required],
      municipality: [null, Validators.required],
      slug: [null, Validators.required],
      phase: [null, Validators.required],
      energy: [null, Validators.required]
    }
  );
  editableProject: Project;
  openModal: NgbModalRef;
  phasesEnum = Object.keys(ProjectPhasesEnum).filter(key => !isNaN(Number(ProjectPhasesEnum[key])));

  @Input() parent: any;

  @ViewChild('editProjectModal') editProjectModal: any;

  constructor(
    private editProjectModalService: EditProjectModalService,
    private currentProjectService: CurrentProjectService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: Toastr
  ){}

  // HACK: (Jose Farias) Projects should be stored in-memory to avoid having to call getProjects
  //       on parent (avoiding circular dependency).
  closeEditProjectModal() {
    this.openModal.dismiss();
    this.parent.getProjects();
  }

  initializeEditProjectForm(project: Project): void {
    this.editableProject = project;

    this.editProjectForm.patchValue({
      name: project.name,
      state: project.state,
      country: project.country,
      municipality: project.municipality,
      slug: project.slug,
      phase: project.phase,
      energy: project.energy_year
    });
  }

  editProject(): void {
    if (this.editProjectForm.valid) {
      const requestPayload = new EditProjectPayload(this.editProjectForm);
      this.editProjectModalService
        .editProject(this.editableProject, requestPayload)
        .subscribe(
          (editProjectResponse: EditProjectResponse) => {
            const newValue: Project = {
              id: this.editableProject.id,
              name: this.editProjectForm.get('name').value,
              country: this.editProjectForm.get('country').value,
              state: this.editProjectForm.get('state').value,
              municipality: this.editProjectForm.get('municipality').value,
              slug: this.editProjectForm.get('slug').value,
              phase: this.editProjectForm.get('phase').value,
              tickets_enabled: this.editableProject.tickets_enabled,
              last_luminaire_reference_id: this.editableProject.last_luminaire_reference_id,
              energy_year: this.editProjectForm.get('energy').value
            };
            this.currentProjectService.updateCurrentProject(newValue);
            this.toastr.success('Operación exitosa.')
            this.closeEditProjectModal();
          },
          (error) => {
            this.toastr.error(error);
          }
        );
    } else {
      this.editProjectForm.controls.name.markAsTouched();
      this.editProjectForm.controls.country.markAsTouched();
      this.editProjectForm.controls.state.markAsTouched();
      this.toastr.error(this.toastr.singleError('Operación fallida. Revisar campos requeridos.'));
    }
  }

  openEditProjectModal(options?: Object): void {
    this.openModal = this.modalService.open(
      this.editProjectModal,
      {...options, ...{ ariaLabelledBy: 'Modal Proyectos', centered: true }}
    );
  }
}