import { FormGroup } from '@angular/forms';

export class ProjectWattagePayload {
    id: string
    marker_colorID: string
    power: number
    optic: string
    materialID: string

  constructor(projectWattageForm: FormGroup) {
    this.id = projectWattageForm.get('id').value;
    this.marker_colorID = projectWattageForm.get('marker_color_id').value;
    this.power = projectWattageForm.get('power').value;
    this.optic = projectWattageForm.get('optic').value;
    this.materialID = projectWattageForm.get('material_id').value;
  }

  toJson(): Object {
    const json = {
        project_wattage: {
            marker_color: null,
            power: this.power,
            optic: this.optic,
            material: {
                id: this.materialID
            }
        }
    };

    if(this.marker_colorID){
        json.project_wattage.marker_color = { id: this.marker_colorID }
      }

    return json;
  }
}