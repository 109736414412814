import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericTable } from '@app/models/generic-table';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsType } from './alerts-type';
import { AlertsTypeService } from './alerts-type.service';

@Component({
  selector: 'app-alerts-type',
  templateUrl: './alerts-type.component.html',
  styleUrls: ['./alerts-type.component.scss']
})
export class AlertsTypeComponent implements OnInit {

  @ViewChild('UpdateAlertTypeModal') UpdateAlertTypeModal: any;

  alertsTypeSearchForm: FormGroup;
  updateAlertType: FormGroup;
  alertsType: GenericTable<AlertsType>;

  constructor(
    private alertTypeService: AlertsTypeService,
    private modalService: NgbModal,
    private toaster: Toastr,
    private fb: FormBuilder
  ) {
    this.alertsTypeSearchForm = fb.group({
      name: '',
      priority: -1,
    });
  }

  ngOnInit() {
    this.paginatedTable(0);
    this.updateAlertType = this.fb.group({
      id: [null],
      priority: [null],
      standard_comparation: [null],
      activate: [null]
    })
  }

  onClose() {
    this.modalService.dismissAll();
  }

  paginatedTable(page: number) {
    this.alertTypeService.getAlerts(this.alertsTypeSearchForm.getRawValue(), page).subscribe(response => {
      this.alertsType = response;
    })
  }

  onEdit(alert: AlertsType) {
    if (!alert.if_comparation) {
      this.updateAlertType.controls['standard_comparation'].disable();
    } else {
      this.updateAlertType.controls['standard_comparation'].enable();
    }
    this.updateAlertType.patchValue({
      id: alert.id,
      priority: alert.priority,
      standard_comparation: alert.standard_comparation,
      activate: alert.activate
    });
    this.modalService.open(this.UpdateAlertTypeModal, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  updateAlert() {
    this.alertTypeService.updateAlert(this.updateAlertType.getRawValue()).subscribe(response => {
      this.toaster.success('Se modifico correctamente');
      this.paginatedTable(0);
    }, error => {
      this.paginatedTable(0);
      this.toaster.error('Error al modificar');
    });
    this.modalService.dismissAll();
  }

  pageChange(page: number) {
    this.paginatedTable(page - 1);
}

}
