import { Component, OnInit } from '@angular/core';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { JudmentService } from '@app/pages/guaranties/judment_claims/judment/judment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GuarantyLuminaryOptions } from '@app/models/judment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-guarantyluminaire',
  templateUrl: './guarantyluminaire.component.html',
  styleUrls: ['./guarantyluminaire.component.scss']
})
export class GuarantyluminaireComponent implements OnInit {
  is_accepted;
  send_to_provider = '';
  is_guaranty = '';
  is_update = true;
  formGroup: FormGroup;
  luminaireOpinion_Id: string;
  first_image: any;
  second_image: any;
  formData: FormData = new FormData();
  constructor(
    private toastr: Toastr,
    private judmentService: JudmentService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.luminaireOpinion_Id = this.activatedRoute.snapshot.paramMap.get('id');
    this.formGroup = this.fb.group({
      id: [null],
      guaranty_luminaire_claim_id: [null],
      policy_number: [null, Validators.required],
      created_at: [null],
      temp_amb: [null, Validators.required],
      humidity: [null, Validators.required],
      electrical_behavior: [null],
      is_oxidation_electric_components: [null],
      damaged_door_insurance: [null],
      atmospheric_discharge: [null],
      is_short_circuit: [null],
      hits_scrapes: [null],
      sealed_receptacle_nema: [null],
      damaged_hinges: [null],
      screw_complete: [null],
      signs_vandalism: [null],
      damaged_wiring: [null],
      optical_damage: [null],
      led_modules_damage: [null],
      surge_arrester_damage: [null],
      complete_components: [null],
      power_test: [null],
      on_during_test: [null],
      luminarie_power: [null],
      input_frequency: [null],
      supply_voltage: [null],
      supply_current: [null],
      thd_av: [null, [Validators.min(1), Validators.max(1000)]],
      thd_ai: [null, [Validators.min(1), Validators.max(100)]],
      measured_input_power: [null],
      f_p: [null],
      output_frequency: [null],
      output_voltage: [null],
      output_current: [null],
      alternative_photocell_placed: [null],
      lit_with_new_photocell: [null],
      alternate_driver_was_placed: [null],
      lit_with_new_alternate_driver: [null],
      alternate_suppressor_was_placed: [null],
      ignited_with_new_alternate_suppressor: [null],
      luminaire_conditions: [null],
      corrective_actions: [null],
      is_guaranty: [null],
      send_to_provider: [null],
      total_amount: [null],
      first_image: [null],
      second_image: [null],
      is_accepted: ['false'],
      firstimage_URL: [null],
      secondimage_URL: [null]
    });
    this.getLuminaireOpinion(this.luminaireOpinion_Id);
  }

  getLuminaireOpinion(guaranty_luminaire_claim_id: string) {
    this.judmentService.get_Luminaires_Opinions(guaranty_luminaire_claim_id).subscribe(
      response => {
        this.populateForm(response);
      if (this.formGroup.value.id === '00000000-0000-0000-0000-000000000000' || this.formGroup.value.id === null) {
          this.is_update = false;
        }
      }
   );
  }


  populateForm(detailLuminaireOpinion: GuarantyLuminaryOptions) {

    this.formGroup.patchValue({
      id: detailLuminaireOpinion.id,
      guaranty_luminaire_claim_id: this.luminaireOpinion_Id,
      policy_number: detailLuminaireOpinion.policy_number,
      temp_amb: detailLuminaireOpinion.temp_amb,
      humidity: detailLuminaireOpinion.humidity,
      electrical_behavior: detailLuminaireOpinion.electrical_behavior,
      is_oxidation_electric_components: detailLuminaireOpinion.is_oxidation_electric_components,
      damaged_door_insurance: detailLuminaireOpinion.damaged_door_insurance,
      atmospheric_discharge: detailLuminaireOpinion.atmospheric_discharge,
      is_short_circuit: detailLuminaireOpinion.is_short_circuit,
      hits_scrapes: detailLuminaireOpinion.hits_scrapes,
      sealed_receptacle_nema: detailLuminaireOpinion.sealed_receptacle_nema,
      damaged_hinges: detailLuminaireOpinion.damaged_hinges,
      screw_complete: detailLuminaireOpinion.screw_complete,
      signs_vandalism: detailLuminaireOpinion.signs_vandalism,
      damaged_wiring: detailLuminaireOpinion.damaged_wiring,
      optical_damage: detailLuminaireOpinion.optical_damage,
      led_modules_damage: detailLuminaireOpinion.led_modules_damage,
      surge_arrester_damage: detailLuminaireOpinion.surge_arrester_damage,
      complete_components: detailLuminaireOpinion.complete_components,
      power_test: detailLuminaireOpinion.power_test,
      on_during_test: detailLuminaireOpinion.on_during_test,
      luminarie_power: detailLuminaireOpinion.luminarie_power,
      input_frequency: detailLuminaireOpinion.input_frequency,
      supply_voltage: detailLuminaireOpinion.supply_voltage,
      supply_current: detailLuminaireOpinion.supply_current,
      thd_av: detailLuminaireOpinion.thd_av,
      thd_ai: detailLuminaireOpinion.thd_ai,
      measured_input_power: detailLuminaireOpinion.measured_input_power,
      f_p: detailLuminaireOpinion.f_p,
      output_frequency: detailLuminaireOpinion.output_frequency,
      output_voltage: detailLuminaireOpinion.output_voltage,
      output_current: detailLuminaireOpinion.output_current,
      alternative_photocell_placed: detailLuminaireOpinion.alternative_photocell_placed,
      lit_with_new_photocell: detailLuminaireOpinion.lit_with_new_photocell,
      alternate_driver_was_placed: detailLuminaireOpinion.alternate_driver_was_placed,
      lit_with_new_alternate_driver: detailLuminaireOpinion.lit_with_new_alternate_driver,
      alternate_suppressor_was_placed: detailLuminaireOpinion.alternate_suppressor_was_placed,
      ignited_with_new_alternate_suppressor: detailLuminaireOpinion.ignited_with_new_alternate_suppressor,
      luminaire_conditions: detailLuminaireOpinion.luminaire_conditions,
      corrective_actions: detailLuminaireOpinion.corrective_actions,
      is_guaranty: detailLuminaireOpinion.is_guaranty,
      send_to_provider: detailLuminaireOpinion.send_to_provider,
      total_amount: detailLuminaireOpinion.total_amount,
      first_image: detailLuminaireOpinion.first_image,
      second_image: detailLuminaireOpinion.second_image,
      is_accepted: detailLuminaireOpinion.is_accepted,
      firstimage_URL: detailLuminaireOpinion.firstimage_URL,
      secondimage_URL: detailLuminaireOpinion.secondimage_URL
    });

    this.is_accepted = detailLuminaireOpinion.is_accepted;
    this.is_guaranty = this.formGroup.value.is_guaranty;
    this.send_to_provider = this.formGroup.value.send_to_provider;


  }

  registerLuminaireOpinion(val: FormGroup) {

    this.formData.append('guaranty_luminaire_claim_id', this.luminaireOpinion_Id);
    this.formData.append('policy_number', val.value.policy_number);
    this.formData.append('first_image', this.first_image);
    this.formData.append('second_image', this.second_image);
    this.formData.append('temp_amb', val.value.temp_amb);
    this.formData.append('humidity', val.value.humidity);
    this.formData.append('electrical_behavior', val.value.electrical_behavior);
    this.formData.append('is_oxidation_electric_components', val.value.is_oxidation_electric_components);
    this.formData.append('damaged_door_insurance', val.value.damaged_door_insurance);
    this.formData.append('atmospheric_discharge', val.value.atmospheric_discharge);
    this.formData.append('is_short_circuit', val.value.is_short_circuit);
    this.formData.append('hits_scrapes', val.value.hits_scrapes);
    this.formData.append('sealed_receptacle_nema', val.value.sealed_receptacle_nema);
    this.formData.append('damaged_hinges', val.value.damaged_hinges);
    this.formData.append('screw_complete', val.value.screw_complete);
    this.formData.append('signs_vandalism', val.value.signs_vandalism);
    this.formData.append('damaged_wiring', val.value.damaged_wiring);
    this.formData.append('optical_damage', val.value.optical_damage);
    this.formData.append('led_modules_damage', val.value.led_modules_damage);
    this.formData.append('surge_arrester_damage', val.value.surge_arrester_damage);
    this.formData.append('complete_components', val.value.complete_components);
    this.formData.append('power_test', val.value.power_test);
    this.formData.append('on_during_test', val.value.on_during_test);
    this.formData.append('luminarie_power', val.value.luminarie_power);
    this.formData.append('input_frequency', val.value.input_frequency);
    this.formData.append('supply_voltage', val.value.supply_voltage);
    this.formData.append('supply_current', val.value.supply_current);
    this.formData.append('thd_av', val.value.thd_av);
    this.formData.append('thd_ai', val.value.thd_ai);
    this.formData.append('measured_input_power', val.value.measured_input_power);
    this.formData.append('f_p', val.value.f_p);
    this.formData.append('output_frequency', val.value.output_frequency);
    this.formData.append('output_voltage', val.value.output_voltage);
    this.formData.append('output_current', val.value.output_current);
    this.formData.append('alternative_photocell_placed', val.value.alternative_photocell_placed);
    this.formData.append('lit_with_new_photocell', val.value.lit_with_new_photocell);
    this.formData.append('alternate_driver_was_placed', val.value.alternate_driver_was_placed);
    this.formData.append('lit_with_new_alternate_driver', val.value.lit_with_new_alternate_driver);
    this.formData.append('alternate_suppressor_was_placed', val.value.alternate_suppressor_was_placed);
    this.formData.append('ignited_with_new_alternate_suppressor', val.value.ignited_with_new_alternate_suppressor);
    this.formData.append('luminaire_conditions', val.value.luminaire_conditions);
    this.formData.append('corrective_actions', val.value.corrective_actions);
    this.formData.append('is_guaranty', this.is_guaranty);
    this.formData.append('send_to_provider', this.send_to_provider);
    this.formData.append('total_amount', val.value.total_amount);
    this.formData.append('is_accepted', this.is_accepted);

    this.judmentService.registerLuminairesOpinion(this.formData).subscribe(
      response => {
        this.toastr.success('Se Guardo Correctamente.');
        this.router.navigate(['/u/guaranties/judment_claims']);
      }
    );
  }

  updateLuminaireOpinion(val: FormGroup) {

    this.formData.append('id', val.get('id').value);
    this.formData.append('policy_number', val.get('policy_number').value);
    this.formData.append('guaranty_luminaire_claim_id', this.luminaireOpinion_Id);

    if (this.formGroup.get('firstimage_URL').value !== null) {
      this.formData.append('first_image_mod', 'true');
    } else {
      this.formData.append('first_image_mod', 'false');
    }

    if (this.formGroup.get('secondimage_URL').value !== null) {
      this.formData.append('second_image_mod', 'true');
    } else {
      this.formData.append('second_image_mod', 'false');
    }

    this.formData.append('first_image', this.first_image);
    this.formData.append('second_image', this.second_image);
    this.formData.append('temp_amb', val.value.temp_amb);
    this.formData.append('humidity', val.value.humidity);
    this.formData.append('electrical_behavior', val.value.electrical_behavior);
    this.formData.append('is_oxidation_electric_components', val.value.is_oxidation_electric_components);
    this.formData.append('damaged_door_insurance', val.value.damaged_door_insurance);
    this.formData.append('atmospheric_discharge', val.value.atmospheric_discharge);
    this.formData.append('is_short_circuit', val.value.is_short_circuit);
    this.formData.append('hits_scrapes', val.value.hits_scrapes);
    this.formData.append('sealed_receptacle_nema', val.value.sealed_receptacle_nema);
    this.formData.append('damaged_hinges', val.value.damaged_hinges);
    this.formData.append('screw_complete', val.value.screw_complete);
    this.formData.append('signs_vandalism', val.value.signs_vandalism);
    this.formData.append('damaged_wiring', val.value.damaged_wiring);
    this.formData.append('optical_damage', val.value.optical_damage);
    this.formData.append('led_modules_damage', val.value.led_modules_damage);
    this.formData.append('surge_arrester_damage', val.value.surge_arrester_damage);
    this.formData.append('complete_components', val.value.complete_components);
    this.formData.append('power_test', val.value.power_test);
    this.formData.append('on_during_test', val.value.on_during_test);
    this.formData.append('luminarie_power', val.value.luminarie_power);
    this.formData.append('input_frequency', val.value.input_frequency);
    this.formData.append('supply_voltage', val.value.supply_voltage);
    this.formData.append('supply_current', val.value.supply_current);
    this.formData.append('thd_av', val.value.thd_av);
    this.formData.append('thd_ai', val.value.thd_ai);
    this.formData.append('measured_input_power', val.value.measured_input_power);
    this.formData.append('f_p', val.value.f_p);
    this.formData.append('output_frequency', val.value.output_frequency);
    this.formData.append('output_voltage', val.value.output_voltage);
    this.formData.append('output_current', val.value.output_current);
    this.formData.append('alternative_photocell_placed', val.value.alternative_photocell_placed);
    this.formData.append('lit_with_new_photocell', val.value.lit_with_new_photocell);
    this.formData.append('alternate_driver_was_placed', val.value.alternate_driver_was_placed);
    this.formData.append('lit_with_new_alternate_driver', val.value.lit_with_new_alternate_driver);
    this.formData.append('alternate_suppressor_was_placed', val.value.alternate_suppressor_was_placed);
    this.formData.append('ignited_with_new_alternate_suppressor', val.value.ignited_with_new_alternate_suppressor);
    this.formData.append('luminaire_conditions', val.value.luminaire_conditions);
    this.formData.append('corrective_actions', val.value.corrective_actions);
    this.formData.append('is_guaranty', this.is_guaranty);
    this.formData.append('send_to_provider', this.send_to_provider);
    this.formData.append('total_amount', val.value.total_amount);
    this.formData.append('is_accepted', this.is_accepted);
    this.judmentService.updateLuminaireOpinion(this.formData).subscribe(
      response => {
        this.toastr.success('Se Actualizo Correctamente.');
        this.router.navigate(['/u/guaranties/judment_claims']);

      });

  }
  setImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.first_image = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.formGroup.patchValue({ firstimage_URL: reader.result })
        this.formGroup.patchValue({ image: file })
      };
      // this.formData.append('first_image', event.target.files[0]);
      reader.readAsDataURL(file);
    }
  }
  setImage2Preview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.second_image = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.formGroup.patchValue({ secondimage_URL: reader.result })
        this.formGroup.patchValue({ image: file })
      };
      // this.formData.append('second_image', event.target.files[0])
      reader.readAsDataURL(file);
    }
  }

  setImage(event) {
    const file: File = event.target.files[0];

    if (file.type !== 'image/jpg') {
      this.toastr.singleError('El archivo cargado no es una imagen');
      return;
    }

    this.formData.append('first_image', file, file.name);
  }

  setImage2(event) {
    const file: File = event.target.files[0]; 

    if (file.type !== 'image/jpg') {
      this.toastr.singleError('El archivo cargado no es una imagen');
      return;
    }

    this.formData.append('second_image', file, file.name);
  }

}
