import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GenericTable } from '@app/models/generic-table';
import { CFEImports, DetailCFEImports } from '@app/models/imports-cfe';
import { AuthService } from '@app/shared/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { isNull, isUndefined } from 'util';
import { v4 as uuid } from 'uuid';
import { CfeFilesService } from './cfe-files.service';
import * as convert_tojson from 'xml-js';

@Component({
  selector: 'app-cfe-files-import',
  templateUrl: './cfe-files-import.component.html',
  styleUrls: ['./cfe-files-import.component.scss']
})

export class CfeFilesImportComponent implements OnInit {

  @ViewChild('rpusResultModal') rpusResultModal;
  count_files = 0;
  is_HistoricActive = true;
  is_Loading = false;
  searchFrom: FormGroup;
  filesRepeat = 0;
  files_result: RPUSValidationResult[] = []
  importProgess = 0;
  files_to_import: FilesValidations[] = [];
  imports: GenericTable<CFEImports>;

  constructor(
    private toaster: ToastrService,
    private authService: AuthService,
    private fb: FormBuilder,
    private cfeService: CfeFilesService,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
    this.searchFrom = this.fb.group({
      code: '',
      month: 0,
      year: 0
    })

    this.getUploads(0);
  }
  getUploads(page) {
    this.cfeService.getImports(this.searchFrom.value.code, this.searchFrom.value.month, this.searchFrom.value.year, page)
      .subscribe(response => {
        this.imports = response;
      });
  }

  exportXLSX() {
    this.cfeService.downloadXLS(this.searchFrom.value.month, this.searchFrom.value.year, this.searchFrom.value.code);
  }

  async setFilesOnForm(filesRecived) {
    let files: File[] = [];
    let is_SaveProcess = true;
    this.files_result = [];
    this.modalService.dismissAll();

    if (filesRecived.target.classList[0] === 'inputfile') {

      if (filesRecived.target.files.length === 0) {
        this.toaster.error('Seleccione por lo menos un archivo');
        return;
      }

      files = filesRecived.target.files;
      is_SaveProcess = false;
    } else {
      files = this.files_to_import.map(x => x.file);
    }

    this.importProgess = 0;
    this.is_Loading = true;
    const percentage = 100 / files.length;
    this.count_files = files.length;

    if (!is_SaveProcess) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type !== 'application/pdf' || files[i].type !== 'application/xml') {
          this.validateDuplicateFiles(files[i]);
          this.sendFile(files[i], is_SaveProcess, files.length, i, percentage, null);
        }
      }
    } else {
      this.cfeService.createImportId(this.authService.getUser().id, this.is_HistoricActive.toString()).subscribe(response => {
        for (let i = 0; i < files.length; i++) {
          if (files[i].type !== 'application/pdf' || files[i].type !== 'application/xml') {
            setTimeout(() => {
              this.sendFile(files[i], is_SaveProcess, files.length, i, percentage, response.import_id);
            }, i * 900);
          }
        }
      }, error => {
        this.toaster.error('Error al generar la importación. Intente de nuevo.');
      });
    }

    this.files_result = [];
    this.files_to_import = [];
    this.filesRepeat = 0;
  }

  validateDuplicateFiles(file: File) {
    let current_folio;
    const reader = new FileReader()
    reader.readAsText(file);
    reader.onloadend = () => {
      const file_info = reader.result.toString();
      const xml = JSON.parse(convert_tojson.xml2json(file_info));
      current_folio = `${xml.elements[0].attributes.Serie}-${xml.elements[0].attributes.Folio}`;
      if (this.files_to_import.filter(x => x.serial === current_folio).length === 0) {
        this.files_to_import.push({ file: file, serial: current_folio });
      } else {
        this.filesRepeat++;
      }
    }

  }

  sendFile(file, is_SaveProcess, files_lenght, file_number, percentage, import_id?) {
    const formData = new FormData();
    formData.append('is_HistoricActive', this.is_HistoricActive.toString());
    formData.append('is_SaveProcess', is_SaveProcess.toString());
    formData.append('file', file, file.name);
    if (is_SaveProcess) { formData.append('import_id', import_id) };
    this.cfeService.validateFiles(formData).subscribe(response => {
      this.afterImportProcess(is_SaveProcess, file_number, files_lenght, percentage);
      this.files_result.push(new RPUSValidationResult(response, true));
    }, error => {
      // console.log(error);
      this.afterImportProcess(is_SaveProcess, file_number, files_lenght, percentage);
      this.files_result.push(new RPUSValidationResult(error.error, false));
    });
  }

  afterImportProcess(is_SaveProcess, file_number, files_lenght, percentage) {
    this.importProgess += percentage;
    if (file_number === files_lenght - 1) {
      setTimeout(() => {
        this.is_Loading = !this.is_Loading;
        if (is_SaveProcess) {
          this.getUploads(0);
          this.toaster.success('Archivos procesados');
        } else {
          this.modalService.open(this.rpusResultModal, { size: 'lg', backdrop: 'static', keyboard: false });
        }
      }, 1000)
    }
  }


  pageChange(page: number) {
    this.getUploads(page - 1);
  }

  getValidatedRPUS() {
    return this.files_result.filter(x => x.is_imported === true && x.is_validTariff === true).length;
  }

  getNewsRPUS() {
    return this.files_result.filter(x => x.is_new === true).length;
  }
}

export class FilesValidations { file: File; serial: string }

export interface IRPUSValidationResult {
  rpu: string;
  rmu: string;
  is_new: boolean;
  is_validTariff: boolean;
  is_imported: boolean;
  date: string;
  tariff: string;
}

export class RPUSValidationResult implements IRPUSValidationResult {
  rpu: string;
  rmu: string;
  is_new: boolean;
  is_validTariff: boolean;
  is_imported: boolean;
  date: string;
  tariff: string;
  message: string;

  constructor(attrs, serverResponse: boolean) {
    this.rpu = attrs.rpu;
    this.rmu = attrs.rmu;
    this.is_new = attrs.is_new;
    this.is_validTariff = attrs.is_validTariff;
    this.is_imported = attrs.is_imported;
    this.date = attrs.date;
    this.tariff = attrs.tariff
    this.message = this.getMessage(serverResponse);
  }

  getMessage(serverResponse) {
    let message = '';

    if (serverResponse) {
      if (!this.is_validTariff) {
        message += `Sin tarifa ${this.tariff} ${this.date}. \n`;
      }
      if (!this.is_imported) {
        message += `El RPU ${this.rpu} ya se importo en la fecha ${this.date}.`
      }
    } else {
      message += `Enviar archivo ${this.rpu} a R&D para revisión`;
    }


    return message;
  }


}
