import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AdministerPowerService } from '../administer-power/administer-power.service';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { ProjectWattage, MarkerColor } from 'app/models/project_wattage';
import { CurrentProjectService } from 'app/shared/cookies/current-project.service';
import { AssignPowerService } from './assign-power.service';
import { DeleteMenu } from 'app/shared/components/map-delete-node.component';
import { MapFuncAuxService } from 'app/shared/components/map-func-aux';
import { Router } from '@angular/router';
import { StyleMap } from 'app/models/styleMap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LuminaireWattages, LamppostWattage, CircuitWattage } from '@app/models/project-wattages-resources';

@Component({
  selector: 'app-assign-power',
  templateUrl: './assign-power.component.html',
  providers: [MapFuncAuxService]
})
export class AssignPowerComponent implements OnInit, OnDestroy {
  @ViewChild('gmap') gmapElement: any;
  powerWattage_Marker: ProjectWattage[];
  // marker_colors: MarkerColor[];
  map: google.maps.Map;
  luminaires: LuminaireWattages[] = [];
  lamppostsLums: LuminaireWattages[] = [];
  lampposts: LamppostWattage[] = [];
  circuits: CircuitWattage[] = [];
  lstInfowindows = [];
  polygons: any[] = [];
  // currentUrl = '/u/power/assignment';
  styleMap: StyleMap = new StyleMap();
  project_max_page = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private currentProjectService: CurrentProjectService,
    private administerPowerService: AdministerPowerService,
    private assignPowerService: AssignPowerService,
    private mapFuncAux: MapFuncAuxService,
    private router: Router,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.getProjectWattage();
    // this.getMarkerColors();
    this.paintMap();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private getProjectWattage() {
    this.assignPowerService.getListPowerWattagewMarkerColors()
      .subscribe((project_wattage: ProjectWattage[]) => {
        console.log(project_wattage, 'power wattages');
        this.powerWattage_Marker = project_wattage;
      }, error => {
        console.log(error);
        this.toastr.error(error);
      });
  }

  // private getMarkerColors(): void {
  //   this.administerPowerService.getListMarkerColors()
  //     .subscribe((marker_colors: PowerWattage_Marker[]) => {
  //       this.marker_colors = marker_colors;
  //       console.log(marker_colors, 'Getr list marker color vgshcjbaknml');
  //       // this.marker_colors = this.marker_colors.sort((a, b) => a.name.localeCompare(b.name));
  //     }, error => {
  //       this.toastr.error(error);
  //     });
  // }

  private paintMap() {
    const project = this.currentProjectService.getCurrentProject();
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    });
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: {
        editable: true,
        draggable: false,
        fillOpacity: 0.25
      }
    });
    google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
      const deleteMenu = new DeleteMenu();
      const polygonPath = polygon.getPath();


      this.addPointToForm(polygon);

      google.maps.event.addListener(polygon, 'rightclick', function (e) {
        // Check if click was on a vertex control point
        if (e.vertex == undefined) {
          return;
        }

        deleteMenu.open(this.map, polygon.getPath(), e.vertex);
      });

      google.maps.event.addListener(polygonPath, 'set_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'insert_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'remove_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'removeVertex', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygon, 'dragend', (_) => {
        this.addPointToForm(polygon);
      })
    });

    this.map.addListener('zoom_changed', () => this.changeZoom());
    this.map.addListener('dragend', () => {
      if (this.map.getZoom() > 15) {
        this.getProjectResources(this.map.getCenter().lat(), this.map.getCenter().lng());
      }
    });

    drawingManager.setMap(this.map);
    this.map.addListener('click', () => {
      this.hideCircuit();
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    });
  }

  changeZoom() {
    if (this.map.getZoom() > 15) {
      this.getProjectResources(this.map.getCenter().lat(), this.map.getCenter().lng());
    } else {
      this.hideLums(this.luminaires);
      this.luminaires = this.luminaires.filter(lum => lum.isSelected);
    }
  }

  private addPointToForm(polygon: google.maps.Polygon) {
    if (this.isExistingPolygon(polygon)) {
      this.replacePolygon(polygon)
    } else {
      this.polygons.push(polygon);
      this.updatePolygon(polygon);
      google.maps.event.addListener(polygon, 'click', (_) => {
        this.hideCircuit();
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
      });
    }
  }

  private replacePolygon(polygon: any): void {
    const polygons = this.polygons;
    const existingPolygonIndex = polygons.findIndex(_polygon => _polygon.zIndex === polygon.zIndex);

    polygons[existingPolygonIndex] = polygon;
    this.updatePolygon(polygon);
  }

  private isExistingPolygon(polygon: any): boolean {
    const polygons = this.polygons;
    const existingPolygon = polygons.find(_polygon => _polygon.zIndex === polygon.zIndex);

    return existingPolygon ? true : false;
  }

  updatePolygon(polygon: any) {
    this.isWithinLuminaires(this.luminaires, polygon);
    for (const lamppost of this.lampposts) {
      this.isWithinLuminaires(lamppost.luminaires, polygon);
    }
    for (const circuit of this.circuits) {
      for (const lamppost of circuit.lampposts) {
        this.isWithinLuminaires(lamppost.luminaires, polygon);
      }
    }
    this.clearLuminairesOfProject(polygon);
  }

  isWithinLuminaires(luminaires: LuminaireWattages[], polygon: any) {
    for (const luminaire of luminaires) {
      if (this.isWithin(polygon, luminaire)) {
        luminaire.isSelected = true;
        luminaire.updateIcon('selected');
        luminaire.polygonID = polygon['zIndex'];
      }
    }
  }

  clearLuminairesOfProject(polygon: any) {
    this.clearLuminaires(this.luminaires, polygon);
    for (const lamppost of this.lampposts) {
      this.clearLuminaires(lamppost.luminaires, polygon);
    }
    for (const circuit of this.circuits) {
      for (const lamppost of circuit.lampposts) {
        this.clearLuminaires(lamppost.luminaires, polygon);
      }
    }
  }

  clearLuminaires(luminaires: LuminaireWattages[], polygon: any) {
    for (const luminaire of luminaires) {
      if (luminaire.polygonID >= 0 && luminaire.polygonID == polygon['zIndex']) {
        if (!this.isWithin(polygon, luminaire)) {
          luminaire.isSelected = false;
          luminaire.updateIcon('normal');
        }
      }
    }
  }

  isWithin(polygon: any, luminaire: LuminaireWattages) {
    return google.maps.geometry.poly.containsLocation(luminaire.marker.getPosition(), polygon);
  }

  private getProjectResources(latitude, longitude) {
    this.assignPowerService.getProjectResourcesMap(latitude, longitude)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        let luminaires = response.luminaires.map(luminaire => new LuminaireWattages(luminaire));
        let lumsSelected = this.luminaires.filter(luminaire => luminaire.isSelected);
        let lumsUnselected = this.luminaires.filter(luminaire => !luminaire.isSelected);

        luminaires = luminaires.filter(lum => !lumsSelected.some(_lum => _lum.id === lum.id));

        if (this.luminaires.length === 0) {
          this.addLuminaires(luminaires);
          this.luminaires = luminaires;
          return;
        }

        let iqualLums = lumsUnselected.filter(lum => luminaires.some(_lum => lum.id === lum.id));
        let diffLums = lumsUnselected.filter(lum => !iqualLums.some(_lum => _lum.id === lum.id));
        this.hideLums(diffLums);

        let newLums = luminaires.filter(lum => !lumsUnselected.some(_lum => _lum.id === lum.id));
        this.addLuminaires(newLums);

        this.luminaires = lumsSelected;
        this.luminaires = this.luminaires.concat(newLums, iqualLums);
        this.toastr.success('Carga terminada.');
      }, error => {
        this.toastr.singleError('Error al cargar luminarias');
      });
  }


  hideLums(luminaires: LuminaireWattages[]) {
    luminaires.forEach(lum => {
      if (!lum.isSelected) {
        lum.setMap(null);
      }
    })
  }


  // private addCircuits(circuits: CircuitWattage[]): void {
  //   for (const circuit of circuits) {
  //     circuit.paintMarker(this.map);
  //     circuit.marker.addListener('click', () => {
  //       this.showCircuit(circuit);
  //       this.showInfoCircuit(circuit);
  //     });
  //     for (const lamppost of circuit.lampposts) {
  //       for (const luminaire of lamppost.luminaires) {
  //         luminaire.paintMarker_(this.map);
  //         luminaire.marker.addListener('click', () => {
  //           this.hideCircuit();
  //           this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
  //           if (this.luminaireIsSelected(luminaire)) {
  //             luminaire.isSelected = false;
  //             luminaire.updateIcon('normal');
  //           } else {
  //             luminaire.isSelected = true;
  //             luminaire.updateIcon('selected');
  //           }
  //         });
  //         luminaire.marker.addListener('mouseover', () => {
  //           lamppost.upadeIcon('hover');
  //         });
  //         luminaire.marker.addListener('mouseout', () => {
  //           lamppost.upadeIcon(this.getEvent(luminaire, circuit));
  //         });
  //       }
  //     }
  //   }
  // }

  // private getEvent(luminaire: LuminaireWattages, circuit: CircuitWattage) {
  //   return luminaire.event == 'circuitShowing' && luminaire.isSelected ? 'selected'
  //     : circuit.event == 'circuitShowing' ? 'circuitShowing' : 'normal';
  // }

  // private addlampposts(lampposts: LamppostWattage[]): void {
  //   for (const lamppost of lampposts) {
  //     this.paintLamppost(lamppost);
  //   }
  // }

  private paintLamppost(lamppost: LamppostWattage) {
    for (const luminaire of lamppost.luminaires) {
      luminaire.paintMarker_(this.map);
      luminaire.marker.addListener('click', () => {
        this.hideCircuit();
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        if (this.luminaireIsSelected(luminaire)) {
          luminaire.isSelected = false;
          luminaire.updateIcon('normal');
        } else {
          luminaire.isSelected = true;
          luminaire.updateIcon('selected');
        }
      });
      luminaire.marker.addListener('mouseover', () => {
        lamppost.upadeIcon('hover');
      });
      luminaire.marker.addListener('mouseout', () => {
        lamppost.upadeIcon('normal');
      });
    }
  }

  private addLuminaires(luminaires: LuminaireWattages[]): void {
    for (const luminaire of luminaires) {
      luminaire.paintMarker_(this.map);
      luminaire.marker.addListener('click', () => {
        this.hideCircuit();
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        if (this.luminaireIsSelected(luminaire)) {
          luminaire.isSelected = false;
          luminaire.updateIcon('normal');
        } else {
          luminaire.isSelected = true;
          luminaire.updateIcon('selected');
        }
      });
    }
  }

  private showCircuit(circuit: CircuitWattage): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();
    circuit.upadeIcon('circuitShowing');
  }

  private luminaireIsSelected(luminaire: LuminaireWattages) {
    return luminaire.event === 'selected' || luminaire.isSelected;
  }

  private clearProjectWattageSelect(): void {
    for (const project_wattage of this.powerWattage_Marker) {
      project_wattage.marker_color.is_check = false;
    }
  }

  projectWattageSelect(projectWattage: ProjectWattage) {
    if (!this.getSelectionLumTotal()) {
      return this.toastr.singleError('Seleciona una luminaria.');
    }

    projectWattage.marker_color.is_check = !projectWattage.marker_color.is_check;
    const luminairesSelected = this.getSelectionLum();
    const lumSend = luminairesSelected.map(lum => {

      return lum.id
    });
    this.assignPowerService.assignLuminaires(lumSend, projectWattage.id)
      .subscribe((response: any) => {
        this.clearProjectWattageSelect();
        for (const luminaire of luminairesSelected) {
          luminaire.project_wattage = projectWattage;
          // luminaire.marker_color = projectWattage.marker_color.name;
          luminaire.linkedSituation = 'assign';
          luminaire.marker_color.path = projectWattage.marker_color.path;
          luminaire.marker_color.path2 = projectWattage.marker_color.path2;
          luminaire.marker_color.fillColor = projectWattage.marker_color.fill_color;
          luminaire.marker_color.fillColor2 = projectWattage.marker_color.fill_color2;
          luminaire.marker_color.stroke = projectWattage.marker_color.stroke;
          luminaire.isSelected = false;
          luminaire.updateIcon('normal-update');
        }
        const circuits = this.circuits.filter(_circuit => _circuit.isSelected);
        for (const circuit of circuits) {
          circuit.isSelected = false;
          circuit.upadeIcon('normal');
        }
        for (const polygon of this.polygons) {
          polygon.setMap(null);
        }
        this.toastr.success('Asignación completada correctamente.');
      }, error => {
        this.toastr.error(error);
      });
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  private showInfoCircuit(circuit: CircuitWattage) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const strInfo = this.getStringCircuitOptions(circuit);
    const infowindow = new google.maps.InfoWindow({ content: strInfo });
    infowindow.open(this.map, circuit.marker);
    window.setTimeout(() => {
      const btnCircuit = document.getElementById('circuittUnir');
      btnCircuit.addEventListener('click', () => {
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        if (circuit.isSelected) {
          circuit.isSelected = false;
          circuit.upadeIcon('normal', true);
          for (const lamppost of circuit.lampposts) {
            for (const luminaire of lamppost.luminaires) {
              luminaire.isSelected = false;
              luminaire.updateIcon('normal');
            }
          }
        } else {
          circuit.isSelected = true;
          circuit.upadeIcon('selected');
          for (const lamppost of circuit.lampposts) {
            for (const luminaire of lamppost.luminaires) {
              luminaire.isSelected = true;
              luminaire.updateIcon('selected');
            }
          }
        }
      });
    }, 500);
    this.lstInfowindows.push(infowindow);
  }

  private getStringCircuitOptions(circuit: CircuitWattage) {
    return `
    <div>
      <div class="col-12">
        ${circuit.isSelected ?
        '<input id="circuittUnir" type="button" class="oe--button--gray--popover mr-1" value="Deseleccionar circuito">'
        :
        '<input id="circuittUnir" type="button" class="oe--button--gray--popover mr-1" value="Seleccionar circuito">'
      }
      </div>
    </div>
    `;
  }

  getSelectionLumTotal() {
    const luminairesSelected = this.getSelectionLum();
    return luminairesSelected.length;
  }

  getSelectedLams(lampposts: LamppostWattage[]) {
    let luminairesSelected: LuminaireWattages[] = [];
    for (const lamppost of lampposts) {
      const _lums = lamppost.luminaires.filter(_lum => _lum.event === 'selected' || _lum.isSelected);
      luminairesSelected = luminairesSelected.concat(_lums);
    }
    return luminairesSelected;
  }

  getSelectionLum() {
    let luminairesSelected: LuminaireWattages[] = [];
    luminairesSelected = this.luminaires.filter(_lum => _lum.event === 'selected' || _lum.isSelected);
    let _lums = this.getSelectedLams(this.lampposts);
    luminairesSelected = luminairesSelected.concat(_lums);
    for (const circuit of this.circuits) {
      _lums = this.getSelectedLams(circuit.lampposts);
      luminairesSelected = luminairesSelected.concat(_lums);
    }
    return luminairesSelected;
  }

  // updateIconLuminaire() {
  //   let luminairesSelected: LuminaireWattages[] = [];
  //   luminairesSelected = this.luminaires.filter(_lum => _lum.event == 'selected' || _lum.isSelected);

  //   luminairesSelected.forEach(lum => {
  //     lum.
  //   })



  // }


  private hideCircuit(): void {
    const _circuit = this.circuits.find(__circuit => __circuit.event === 'circuitShowing');
    if (_circuit) {
      const event = _circuit.isSelected ? 'selected' : 'normal';
      _circuit.upadeIcon(event);
    }
  }

}
