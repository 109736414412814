import { User } from "@models/user";

export interface InfraestructureSurveyKpisInterface {
    luminaire_kpis: Luminaire_kpis;
    arms_kpis: ArmsKpis;
    base_kpis: BaseKpis;
    circuit_kpis: CircuitKpis;
    lampposts_kpis: LamppostsKpis;
    user_kpis: User[];
}

export class  InfraestructureSurveyKpis implements InfraestructureSurveyKpisInterface {
    luminaire_kpis: Luminaire_kpis;
    arms_kpis: ArmsKpis;
    base_kpis: BaseKpis;
    circuit_kpis: CircuitKpis;
    lampposts_kpis: LamppostsKpis;
    user_kpis: User[];

    constructor(attrs: any) {
        this.luminaire_kpis = new Luminaire_kpis(attrs.luminaire_kpis);
        this.arms_kpis = new ArmsKpis(attrs.arms_kpis);
        this.base_kpis = new BaseKpis(attrs.base_kpis);
        this.circuit_kpis = new CircuitKpis(attrs.circuit_kpis);
        this.lampposts_kpis = new LamppostsKpis(attrs.lampposts_kpis);
        this.user_kpis = attrs.user_kpis ? attrs.user_kpis.map( user => new User(user)) : [];
    }
}

class LamppostsKpis {
    double_lampposts_count: number;
    concrete_count: number;
    metal_count: number;
    wood_count: number;
    wall_count: number;
    other_count: number;

    constructor(attrs: any) {
        this.double_lampposts_count = attrs.double_lampposts_count;
        this.concrete_count = attrs.concrete_count;
        this.metal_count = attrs.metal_count;
        this.wood_count = attrs.wood_count;
        this.wall_count = attrs.wall_count;
        this.other_count = attrs.other_count;
    }
}

class CircuitKpis {
    total_count: number;

    constructor(attrs: any) {
        this.total_count = attrs.total_count;
    }
}

class BaseKpis {
    is_present_count: number;
    not_present_count: number;

    constructor(attrs: any) {
        this.is_present_count = attrs.is_present_count;
        this.not_present_count = attrs.not_present_count;
    }
}

class ArmsKpis {
    unacceptable_diameter_count: number;
    wiring_outside_count: number;
    galvanized_count: number;
    steel_count: number;
    other_material_count: number;

    constructor(attrs: any) {
        this.unacceptable_diameter_count = attrs.unacceptable_diameter_count;
        this.wiring_outside_count = attrs.wiring_outside_count;
        this.galvanized_count = attrs.galvanized_count;
        this.steel_count = attrs.steel_count;
        this.other_material_count = attrs.other_material_count;
    }
}

class Luminaire_kpis {
    total_count: number;
    total_posts_and_arms_count: number;

    constructor(attrs: any) {
        this.total_count = attrs.total_count;
        this.total_posts_and_arms_count = attrs.total_posts_and_arms_count;
    }
}