
export interface IDashboardDetailHistoric {
    table_list: PeriodHistoric[],
}

export class DashboardDetailHistoric implements IDashboardDetailHistoric {
    table_list: PeriodHistoric[];

    constructor(attrs: any) {
        this.table_list = attrs.table_list;
    }

    GetByYearAndType(year_selected = 0, type = false) {
        const period: PeriodHistoric[] = [];
        if(year_selected == 0 && !type){
            this.table_list.forEach(periodo => {
                periodo.monthString = this.GetMonthString(periodo.month);
                period.push(periodo);
            });
            return period
        }
        if (type) {
            let years = year_selected == 0 ? this.table_list.map(x => x.year) : this.table_list.filter(x => x.year == year_selected).map(x => x.year);
            years = years.filter((n, i) => years.indexOf(n) === i);
            years.forEach(year => {
                const current_year = this.table_list.filter(x => x.year == year);
                const kwh = current_year.reduce((kwh, current) => kwh + current.kwh, 0);
                const mxn = current_year.reduce((mxn, current) => mxn + current.mxn, 0);
                const countMonths = current_year.length;

                period.push(new PeriodHistoric({year: year, month: "", countMonths: countMonths, kwh: kwh, mxn: mxn}));
            });
            return period;
        } else {
            return this.table_list.filter(x => x.year == year_selected);
        }
    }
    GetMonthString(numberMonth: number){
        var stringMonth;
        switch(numberMonth){
          case 1:{
            stringMonth = "ENE";
            break;
          }
          case 2:{
            stringMonth = "FEB";
            break;
          }
          case 3:{
            stringMonth = "MAR";
            break;
          }
          case 4:{
            stringMonth = "ABR";
            break;
          }
          case 5:{
            stringMonth = "MAY";
            break;
          }
          case 6:{
            stringMonth = "JUN";
            break;
          }
          case 7:{
            stringMonth = "JUL";
            break;
          }
          case 8:{
            stringMonth = "AGO";
            break;
          }
          case 9:{
            stringMonth = "SEP";
            break;
          }
          case 10:{
            stringMonth = "OCT";
            break;
          }
          case 11:{
            stringMonth = "NOV";
            break;
          }
          case 12:{
            stringMonth = "DIC";
            break;
          }
        }
        return stringMonth;
      }
}

export class PeriodHistoric {
    year: number;
    month: number;
    monthString:string;
    kwh: number;
    mxn: number;
    countMonths: number;

    constructor(attrs: any) {
        this.year = attrs.year;
        this.month = attrs.month;
        this.kwh = attrs.kwh;
        this.mxn = attrs.mxn;
        this.countMonths = attrs.countMonths
    }
}
