import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { SmartAlertInfo } from '@app/models/smart-alerts';
import { AuthService } from '@app/shared/auth/auth.service';
import { IgetSectores } from '../../../models/smart/sectors.model'

@Injectable({
  providedIn: 'root'
})
export class SmartAlertsService {

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getAlertsList(form): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_SMART}/api/SmartAlerts/SmartAlerts`, form);
  }
  getAlertDetailsList(id): Observable<SmartAlertInfo> {
    const httpParams = new HttpParams()
      .set('id', id)
    return this.httpClient.get<SmartAlertInfo>(
      `${environment.SERVER_SMART}/api/SmartAlerts/SmartAlertDetails`, { params: httpParams });
  }

  changeDeviceStatus(form): Observable<any> {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id)
    return this.httpClient.post<any>(
      `${environment.SERVER_SMART}/api/SmartAlerts/ChangeDeviceStatus`, form, { headers: httpHeaders });
  }

  getSectores() {
    return this.httpClient.get<IgetSectores[]>(`${environment.SERVER_NETCORE}/api/GeneralView/GetListSectores`);
  }
  // SmartAlertsTypysForDevice(id): Observable<any> {
  //   const httpParams = new HttpParams()
  //   .set('id', id)
  //   return this.httpClient.get<any>(
  //     `${environment.SERVER_SMART}/api/SmartAlerts/SmartAlertsTypysForDevice` , {params: httpParams});
  // }
}
