import { LuminaireEnum } from "./luminaire-enum";
import { TechnologyEnum } from "./technology-enum";
import { ProblemEnum } from "./problem-enum";
import { Meter } from "./meter";
import { Repair } from "./repair";
import * as moment from 'moment';
import { Observable } from 'rxjs/Rx';

enum StatusmEnum {
  "Sin asignar",
  "Programada para reparación",
  "Pendiente",
  "En proceso de reparación",
  "Funcionando",
}

interface ReportedElementsInterface {
  totalLuminaires: number;
  circuits: Circuit[];
  lampposts: Lamppost[];
  luminaires: LuminaireReported[];
}

export class ReportedElements implements ReportedElementsInterface {
  totalLuminaires: number;
  circuits: Circuit[];
  lampposts: Lamppost[];
  luminaires: LuminaireReported[];


  constructor(attrs: any) {
    this.totalLuminaires = attrs.totalLuminaires;
    this.circuits = attrs.circuits.map(
      (circuit: Circuit) => new Circuit(circuit)
    );
    this.lampposts = attrs.lampposts.map(
      (lamppost: Lamppost) => new Lamppost(lamppost)
    );
    this.luminaires = attrs.luminaires.map(
      (luminaire: LuminaireReported) => new LuminaireReported(luminaire)
    );
  }
}

interface LuminaireInterface {
  id: string;
  reference_id: string;
  type: number;
  technology: number;
  problems: number[];
  first_report_date: string;
  last_report_date: string;
  date_of_last_repair: string;
  status: number;
  ticket_references: string[];
  is_in_task: boolean;
  location: Location;
  repair: Repair;
  porcentaje: number;
}
export class LuminaireReported implements LuminaireInterface {
  id: string;
  reference_id: string;
  type: number;
  typeName: string;
  technology: number;
  technologyName: string;
  problems: number[];
  problemsNames: string[];
  first_report_date: string;
  first_report_format: string;
  last_report_date: string;
  last_report_format: string;
  date_of_last_repair: string;
  status: number;
  statusName: string;
  ticket_references: string[];
  isSelected: boolean;
  is_in_task: boolean;
  location: Location;
  get_path_icon_selected: string;
  linkedSituation: string;
  event: string;
  marker: google.maps.Marker;
  repair: Repair;
  porcentaje: number;
  deadlineDate: Date;


  constructor(
    attrs: any,
    linkedSituation: string = "single",
    event: string = "normal",
  ) {
    this.id = attrs.id;
    this.reference_id = attrs.reference_id == null ? 'Sin referencia' : attrs.reference_id.toString();
    this.type = attrs.type;
    this.typeName = LuminaireEnum[attrs.type];
    this.technology = attrs.technology;
    this.technologyName = TechnologyEnum[attrs.technology];
    this.problems = attrs.problems != null ? attrs.problems : [];
    this.problemsNames = attrs.problems
      ? attrs.problems.map((problem: number) => ProblemEnum[problem])
      : [];
    this.first_report_date = attrs.first_report_date;
    this.first_report_format = getDateFormat(attrs.first_report_date);
    this.last_report_date = attrs.last_report_date;
    this.last_report_format = getDateFormat(attrs.last_report_date);
    this.date_of_last_repair = attrs.date_of_last_repair;
    this.statusName = StatusmEnum[attrs.status || 0];
    this.ticket_references = attrs.ticket_references;
    this.isSelected = false;
    this.location =
      attrs.location != null ? new Location(attrs.location) : null;
    this.status = attrs.status || 0;
    this.linkedSituation = linkedSituation;
    this.event = event;
    this.porcentaje = attrs.porcentaje;
    this.repair = attrs.repair != null ? new Repair(attrs.repair) : null;
    this.deadlineDate = this.getTotalDays();
    this.is_in_task = attrs.is_in_task
  }

  readonly markerIcons = {
    linked: {
      hover: [
        // verde azulado, muestra el poste completo
        "assets/img/svg/link_selected-0.svg",
        "assets/img/svg/link_selected-1.svg",
        "assets/img/svg/link_selected-2.svg",
        "assets/img/svg/link_selected-3.svg",
        "assets/img/svg/link_selected-4.svg",
        "assets/img/svg/link_selected-5.svg",
        "assets/img/svg/link_selected-6.svg",
      ],
      normal: [
        // normal, rojo
        "assets/img/svg/link_normal-0.svg",
        "assets/img/svg/link_normal-1.svg",
        "assets/img/svg/link_normal-2.svg",
        "assets/img/svg/link_normal-3.svg",
        "assets/img/svg/link_normal-4.svg",
        "assets/img/svg/link_normal-5.svg",
        "assets/img/svg/link_normal-6.svg",
      ],
      selected: [
        // grande y azul
        "assets/img/svg/lamppost_selected-0.svg",
        "assets/img/svg/lamppost_selected-1.svg",
        "assets/img/svg/lamppost_selected-2.svg",
        "assets/img/svg/lamppost_selected-3.svg",
        "assets/img/svg/lamppost_selected-4.svg",
        "assets/img/svg/lamppost_selected-5.svg",
        "assets/img/svg/lamppost_selected-6.svg",
      ],
      circuitShowing: [
        // morado
        "assets/img/svg/ic_link_circuit-0.svg",
        "assets/img/svg/ic_link_circuit-1.svg",
        "assets/img/svg/ic_link_circuit-2.svg",
        "assets/img/svg/ic_link_circuit-3.svg",
        "assets/img/svg/ic_link_circuit-4.svg",
        "assets/img/svg/ic_link_circuit-5.svg",
        "assets/img/svg/ic_link_circuit-6.svg",
      ],
    },
    single: {
      normal: [
        "assets/img/svg/luminaire_normal-0.svg",
        "assets/img/svg/luminaire_normal-1.svg",
        "assets/img/svg/luminaire_normal-2.svg",
        "assets/img/svg/luminaire_normal-3.svg",
        "assets/img/svg/luminaire_normal-4.svg",
        "assets/img/svg/luminaire_normal-5.svg",
        "assets/img/svg/luminaire_normal-6.svg",
      ],
      selected: [
        "assets/img/svg/luminaire_selected-0.svg",
        "assets/img/svg/luminaire_selected-1.svg",
        "assets/img/svg/luminaire_selected-2.svg",
        "assets/img/svg/luminaire_selected-3.svg",
        "assets/img/svg/luminaire_selected-4.svg",
        "assets/img/svg/luminaire_selected-5.svg",
        "assets/img/svg/luminaire_selected-6.svg",
      ],
    },
  };

  get_icon(): object {
    return {
      url: this.markerIcons[this.linkedSituation][
        this.event == "normal"
          ? this.isSelected
            ? "selected"
            : "normal"
          : this.event
      ][this.status],
      scaledSize: this.isSelected
        ? new google.maps.Size(34, 50)
        : new google.maps.Size(28, 40),
    };
  }

  setIconLuminaire(selected: boolean): string {
    let svg = '';
    if (selected) {
      svg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="36" viewBox="0 0 24 36">
      <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
        <path fill="blue" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.48662295,6.46992268 C3.38709836,3.19976804 6.92837705,1 10.9827787,1 C17.0452213,1 21.9596967,5.91677835 21.9596967,11.9820619 C21.9596967,13.5032088 21.6499836,14.9512887 21.091959,16.2688402 C20.5326721,17.5863918 19.7238115,18.7720619 18.7256066,19.7655928 C17.8937541,20.6503351 17.1476475,21.5850515 16.4770082,22.5402448 C12.0834098,28.8027191 10.9827787,32.9525515 10.9827787,32.9525515 C10.9827787,32.9525515 9.78747541,28.1894072 4.91519672,21.7553608 C4.39819672,21.0742139 3.84017213,20.407049 3.23868852,19.7655928 C2.56931148,19.0997809 1.98577049,18.3469201 1.50582787,17.5248711 C0.552344262,15.8974613 0.00586065574,14.0037629 0.00586065574,11.9819716 C0.00586065574,9.9730799 0.544680328,8.08957474 1.48662295,6.46992268 Z"/>
        <path fill="#7E2D25" d="M10.8424426,6 C14.0685984,6 16.6833525,8.61751289 16.6833525,11.8451804 C16.6833525,15.0728479 14.0685984,17.6888273 10.8424426,17.6888273 C7.61628689,17.6888273 5,15.0728479 5,11.8451804 C5,8.61751289 7.61637705,6 10.8424426,6 Z"/>
        <circle cx="18.5" cy="3.5" r="3.5" fill="#FF00B2" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>`;
    } else {
      svg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="36" viewBox="0 0 24 36">
      <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
        <path fill="#F14336" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1.48662295,6.46992268 C3.38709836,3.19976804 6.92837705,1 10.9827787,1 C17.0452213,1 21.9596967,5.91677835 21.9596967,11.9820619 C21.9596967,13.5032088 21.6499836,14.9512887 21.091959,16.2688402 C20.5326721,17.5863918 19.7238115,18.7720619 18.7256066,19.7655928 C17.8937541,20.6503351 17.1476475,21.5850515 16.4770082,22.5402448 C12.0834098,28.8027191 10.9827787,32.9525515 10.9827787,32.9525515 C10.9827787,32.9525515 9.78747541,28.1894072 4.91519672,21.7553608 C4.39819672,21.0742139 3.84017213,20.407049 3.23868852,19.7655928 C2.56931148,19.0997809 1.98577049,18.3469201 1.50582787,17.5248711 C0.552344262,15.8974613 0.00586065574,14.0037629 0.00586065574,11.9819716 C0.00586065574,9.9730799 0.544680328,8.08957474 1.48662295,6.46992268 Z"/>
        <path fill="#7E2D25" d="M10.8424426,6 C14.0685984,6 16.6833525,8.61751289 16.6833525,11.8451804 C16.6833525,15.0728479 14.0685984,17.6888273 10.8424426,17.6888273 C7.61628689,17.6888273 5,15.0728479 5,11.8451804 C5,8.61751289 7.61637705,6 10.8424426,6 Z"/>
        <circle cx="18.5" cy="3.5" r="3.5" fill="#F136EF" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>`;
    }
    return svg;
  }

  upadeIcon(): void {
    if (this.status === 5) {
      this.marker.setIcon(('data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.setIconLuminaire(true))));
    } else {
      this.marker.setIcon(this.get_icon());
    }

  }

  paintMarker(map: any): void {
    let latitude = this.location.latitude;
    let longitude = this.location.longitude;
    this.event = this.isSelected ? "selected" : "normal";
    if (this.status === 5) {
      this.marker = new google.maps.Marker({
        title: this.reference_id,
        position: new google.maps.LatLng(latitude, longitude),
        icon: {
          url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.setIconLuminaire(false))
        },
        map: map,
      });
    } else {
      this.marker = new google.maps.Marker({
        title: this.reference_id,
        position: new google.maps.LatLng(latitude, longitude),
        icon: this.get_icon(),
        map: map,
      });
    }

  }

  getTotalDays() {
    let count = {};
    this.problems.forEach((problem) => {
      count[problem] = (count[problem] || 0) + 1;
    });

    var arr = Object.keys(count);

    let maxValue = Math.max.apply(
      null,
      arr.map(function (key) {
        return count[key];
      })
    );
    let problemReturn: number[] = [];
    arr.forEach((problem) => {
      const currentValue = count[problem];
      if (maxValue === currentValue) {
        maxValue = currentValue;
        problemReturn.push(parseInt(problem));
      }
    });
    let sDate = new Date(this.first_report_date);

    if (problemReturn.filter((x) => x === 0 || x === 1).length > 0) {
      sDate.setDate(sDate.getDate() + 2);
    }

    if (
      problemReturn.filter(
        (x) => x === 2 || x === 3 || x === 4 || x === 5 || x === 6 || x === 7
      ).length > 0
    ) {
      sDate.setDate(sDate.getDate() + 3);
    }
    return sDate
  }


}

interface LamppostInterface {
  id: string;
  luminaires: LuminaireReported[];
}
class Lamppost implements LamppostInterface {
  id: string;
  luminaires: LuminaireReported[];
  event: string;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.luminaires = attrs.luminaires.map(
      (luminaire: LuminaireReported) =>
        new LuminaireReported(luminaire, "linked")
    );
  }

  upadeIcon(): void {
    for (let luminaire of this.luminaires) {
      luminaire.event =
        this.event == "normal"
          ? luminaire.isSelected
            ? "selected"
            : "normal"
          : this.event;
      luminaire.upadeIcon();
    }
  }
}

interface CircuitInterface {
  id: string;
  lampposts: Lamppost[];
  meter: Meter;
  repair: Repair;
}
class Circuit implements CircuitInterface {
  id: string;
  identifier: string;
  lampposts: Lamppost[];
  isCollapsed: boolean;
  isSelected: boolean;
  meter: Meter;
  marker: google.maps.Marker;
  event: string;
  total_luminaires: number;
  status: number;
  statusName: string;
  repair: Repair;

  readonly markerIcons = {
    normal: [
      "assets/img/svg/circuit_normal-0.svg",
      "assets/img/svg/circuit_normal-1.svg",
      "assets/img/svg/circuit_normal-2.svg",
      "assets/img/svg/circuit_normal-3.svg",
      "assets/img/svg/circuit_normal-4.svg",
    ],
    selected: [
      "assets/img/svg/circuit_selected-0.svg",
      "assets/img/svg/circuit_selected-1.svg",
      "assets/img/svg/circuit_selected-2.svg",
      "assets/img/svg/circuit_selected-3.svg",
      "assets/img/svg/circuit_selected-4.svg",


    ],
    circuitShowing: [
      "assets/img/svg/ic_circuit_normal-0.svg",
      "assets/img/svg/ic_circuit_normal-1.svg",
      "assets/img/svg/ic_circuit_normal-2.svg",
      "assets/img/svg/ic_circuit_normal-3.svg",
      "assets/img/svg/ic_circuit_normal-4.svg",
    ],
  };

  constructor(attrs: any) {
    this.id = attrs.id;
    this.identifier = attrs.identifier;
    this.lampposts = attrs.lampposts.map(
      (lamppost: Lamppost) => new Lamppost(lamppost)
    );
    this.isCollapsed = false;
    this.isSelected = false;
    this.meter = new Meter(attrs.meter);
    this.total_luminaires = attrs.total_luminaires;
    this.status = attrs.status || 0;
    this.statusName = StatusmEnum[this.status];
    this.repair = attrs.repair != null ? new Repair(attrs.repair) : null;
  }

  get_icon(): object {
    return {
      url: this.markerIcons[
        this.event == "normal"
          ? this.isSelected
            ? "selected"
            : "normal"
          : this.event
      ][this.status],
      scaledSize: this.isSelected
        ? new google.maps.Size(34, 50)
        : new google.maps.Size(28, 40),
    };
  }

  upadeIcon(flag?: boolean): void {
    this.marker.setIcon(this.get_icon());
    if (flag) return;
    for (let lamppost of this.lampposts) {
      lamppost.event = this.event;
      lamppost.upadeIcon();
    }
  }

  paintMarker(map: any): void {
    let latitude = this.meter.location.latitude;
    let longitude = this.meter.location.longitude;
    this.event = this.isSelected ? "selected" : "normal";
    this.marker = new google.maps.Marker({
      title: this.identifier,
      position: new google.maps.LatLng(latitude, longitude),
      icon: this.get_icon(),
      map: map,
    });
  }
}

interface LocationInterface {
  latitude: number;
  longitude: number;
}
class Location implements LocationInterface {
  latitude: number;
  longitude: number;

  constructor(attrs: any) {
    this.latitude = attrs.latitude;
    this.longitude = attrs.longitude;
  }
}

function getDateFormat(strDate: string): string {
  let currentDate = new Date(strDate);
  let date = currentDate.getDate();
  let month = currentDate.getMonth();
  let year = currentDate.getFullYear();
  return pad(date) + "/" + pad(month + 1) + "/" + year;
}

function pad(n: number) {
  return n < 10 ? "0" + n : n;
}
