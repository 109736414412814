export class Location {
    latitude: number;
    longitude: number;

    constructor(attrs) {
        this.latitude = attrs.latitude,
        this.longitude = attrs.longitude
    }
}
export class LuminairesStatus {
    lampposts: LamppostStatus[];
    luminaires: LumStatus[];

    constructor(attrs) {
        this.lampposts = attrs.lampposts;
        this.luminaires = attrs.luminaires;
    }
}

export class LumStatus {
    id: string;
    reference_id: string;
    location: Location;
    municipality_status: number;
    marker_color: string;
    linkedSituation: string;
    event: string;
    isSelected: Boolean;
    marker: google.maps.Marker;
    polygonID: number;



    readonly markerIcons = {
        linked: {
            hover: 'assets/img/svg/ic_link_hover.svg',
            normal: 'assets/img/svg/link.svg',
            selected: 'assets/img/svg/lam_selected.svg',
        },
        single: {
            normal: 'assets/img/svg/normal.svg',
            selected: 'assets/img/svg/selected-blue.svg'
        },
        assign: {
            selected: 'assets/img/svg/ic_municipality_selected-status-',
            normal: 'assets/img/svg/ic_municipality_status-',
            hover: 'assets/img/svg/ic_municipality_hover_status-',
        },
        citizen: {
            normal: 'assets/img/svg/ic_single_normal.svg',
            selected: 'assets/img/svg/link_selected.svg'
        }
    }

    constructor(attrs: any, linkedSituation: string = 'single', event: string = 'normal') {
        this.id = attrs.id;
        this.reference_id = attrs.reference_id;
        this.location = new Location(attrs.location);
        this.municipality_status = attrs.municipality_status;
        this.marker_color = attrs.marker_color;
        this.linkedSituation = attrs.municipality_status ? 'assign' : attrs.situation;
        this.event = event;
        this.isSelected = false;
    }

    get_icon(): object {
        return this.linkedSituation == 'assign'
            ?
            // tslint:disable-next-line:max-line-length
            {
                url: `${this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.municipality_status}.svg`,
                scaledSize: new google.maps.Size(15, 15)
            }
            :
            {
                url: this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
            };
    }

    upadeIcon(event: string): void {
        this.event = event;
        this.marker.setIcon(this.get_icon());
    }

    paintMarker(map: any): void {
        const latitude = this.location.latitude;
        const longitude = this.location.longitude;
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.reference_id ,
            position: new google.maps.LatLng(latitude, longitude),
            icon: this.get_icon(),
            map: map
        });
    }

    hideMarker(): void {
        this.marker.setMap(null);
    }

    setMap(map: any): void {
        this.marker.setMap(map);
    }
}
export class LamppostStatus {
    id: string;
    luminaires: LumStatus[];
    event: string;

    constructor(attrs) {
        this.id = attrs.id;
        // tslint:disable-next-line:max-line-length
        this.luminaires = attrs.luminaires != null ? attrs.luminaires.map((luminaire: LumStatus) => new LumStatus(luminaire, 'linked')) : [];
    }

    upadeIcon(event: string): void {
        this.event = event;
        for (const luminaire of this.luminaires) {
            const event = this.event == 'normal' ? luminaire.isSelected ? 'selected' : 'normal' : this.event;
            luminaire.upadeIcon(event);
        }
    }

    hideLamppost(): void {
        for (const luminaire of this.luminaires) {
            luminaire.hideMarker();
        }
    }

    setMap(map: any): void {
        for (const luminaire of this.luminaires) {
            luminaire.setMap(map);
        }
    }
}