import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class Toastr {
  constructor(private toastr: ToastrService) { }

  success(message?: string, title?: string) {
    this.toastr.success(message, title);
  }

  singleError(error: string) {
    this.toastr.error(error)
  }

  error(errorMessages) {
    if (errorMessages.status === 500) {
      this.toastr.error(errorMessages.statusText);
    } else if (errorMessages.status === 404) {
      this.toastr.error(errorMessages.statusText);
    } else if (errorMessages.status === 0) {
      this.toastr.error(errorMessages.statusText);
    } else {
      let messageArray: Array<string>;
      messageArray = errorMessages['error']['errors'];
      if(messageArray.length){
        messageArray.forEach(error => {
          this.toastr.error(error);
        });
      }
 
    }
  }

}