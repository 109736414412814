import { Injectable } from '@angular/core';
import { LuminaireEnum } from '@models/luminaire-enum';
import { TechnologyEnum } from '@models/technology-enum';
import { SubstationEnum } from '@models/substation_type-enum';
import { SubstationCurrentCapacityEnum } from '@models/substation_current_capacity-enum';
import { MountingTypeEnum  } from '@models/mounting_type-enum';
import { Lampposts } from '@models/lampposts';
import { Luminaire } from '@models/luminaire';
import { Task } from '@models/task';
import { Circuit } from '@models/circuit';
import { Router } from '@angular/router';
import { Lamppost } from '@models/lamppost';
import { CircuitRpu } from '@app/models/circuit-rpu';

@Injectable()
export class MapFuncAuxService {
    luminaireEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
    technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));
    substationEnum = Object.keys(SubstationEnum).filter(key => !isNaN(Number(SubstationEnum[key])));
    substationCurrentCapacityEnum = Object.keys(SubstationCurrentCapacityEnum).filter(key => key.length > 2);
    mountingTypeEnum = Object.keys(MountingTypeEnum).filter(key => !isNaN(Number(MountingTypeEnum[key])));
    lstCircuist = [];

  

  constructor(
    private router: Router
  ) {}

  public searchLamppostByLumId(id: string, task: Task):Lamppost{
    let luminaires: Luminaire[];
    let luminaire: any;
    let lamppost: Lamppost;
    for(let post of task.lampposts){
      luminaires = post.luminaires.map( lum => id == lum.id ? lum : null);
      luminaire = luminaires.filter( lum => lum != null);
      if(luminaire.length != 0){
        lamppost = post;
        break;
      }
    }
    return lamppost;
  }

  public searchCircuitByLamppostID(lamppostID: string, task: Task):Circuit{

    let circuit = task.circuits.find( _circuit => 
      _circuit.lampposts.find( lamppost => 
        lamppost.id == lamppostID) != undefined );

    return circuit;
  }

  public searchLamppostByLumIdInCircuit(luminaireID: string, task: Task): Lamppost{
    let lam: Lamppost
    for(let i = 0; i < task.circuits.length; i++){
      for(let _i = 0; _i < task.circuits[i].lampposts.length; _i++){
        let lamppost = task.circuits[i].lampposts[_i];
        for(let io = 0; io < lamppost.luminaires.length; io++){
          let lum = lamppost.luminaires[io];
          if(lum && lum.id == luminaireID)
            lam = lamppost;
        }
      }
    }
    return lam;
  }

  public isPost(luminaire:Luminaire, task: Task):boolean{
    let lst: Luminaire[] = task.luminaires.map( lum => luminaire.id == lum.id ? lum : null);
    let lum: any = lst.filter( lum2 => lum2 != null);
    return !lum.length
  }

  public addLuminaires(luminaires: Luminaire[]): any[]{
      let markers = [];
      for(let luminaire of luminaires){
          let latitude = luminaire.location.latitude;
          let longitude = luminaire.location.longitude;
          let marker = new google.maps.Marker({
              title: luminaire.id,
              position: new google.maps.LatLng(latitude,longitude),
              icon: 'assets/img/svg/normal.svg' });
          //marker.addListener('click', () => this.clickMarker(luminaire.id));
          markers.push(marker);
      }
      return markers
  }

  public closeAllInfowindows(lstInfowindows): void{
    let btnAddComent = document.getElementById('luminaireComment');
    if(btnAddComent)
      btnAddComent.removeEventListener('click', () => {})
    for(let infowindow of lstInfowindows){
      infowindow.close();
    }
  }

  public searchMarker(id: string, markers: any[]) {
    for(let i = 0; i < markers.length; i++){
      if(markers[i].getTitle() == id )
        return markers[i];
    }
    return null;
  }

  public searchLuminaire(id:string, task: Task): Luminaire{
    let luminaires: Luminaire[] = task.luminaires.map( lum => id == lum.id ? lum : null);
    let luminaire: any = luminaires.filter( lum => lum != null);

    if(luminaire.length == 0){
      for(let post of task.lampposts){
        luminaires = post.luminaires.map( lum => id == lum.id ? lum : null);
        luminaire = luminaires.filter( lum => lum != null);
        if(luminaire.length != 0)
          break;
      }
    }

    if(luminaire.length == 0)
      return null

    luminaire = luminaire[0];
    return luminaire;
  }

  public searchLuminaireInCircuit(luminaireID: string, task: Task): Luminaire{
    let luminaire: Luminaire
    for(let i = 0; i < task.circuits.length; i++){
      for(let _i = 0; _i < task.circuits[i].lampposts.length; _i++){
        let lamppost = task.circuits[i].lampposts[_i];
        for(let io = 0; io < lamppost.luminaires.length; io++){
          let lum = lamppost.luminaires[io];
          if(lum && lum.id == luminaireID)
            luminaire = lum;
        }
      }
    }
    return luminaire;
  }

  public searchCircuit(circuitID:string, task: Task): Circuit{
    let circuits = task.circuits.filter( circuit => circuit.id == circuitID);
    return circuits.length == 1 ? circuits[0] : null
  }

  public searchLamppostInCircuit(lamppostID:string, circuit:Circuit): Lamppost{
    let lamppost = circuit.lampposts.find( lamppost => lamppost.id == lamppostID);
    return lamppost
  }

  public remplaceLum(luminaire: Luminaire, task: Task): Task{
    if(this.isPost(luminaire, task)){
      let posLamp = 0;
      for(let i = 0; i > task.lampposts.length; i++){
        for(let io = 0; io > task.lampposts[i].luminaires.length; io++){
          if(task.lampposts[i].luminaires[io].id == luminaire.id){
            posLamp = i;
            break;
          }
        }
      }
      task.lampposts[posLamp].luminaires = task.lampposts[posLamp].luminaires.filter(lum => lum.id != luminaire.id);
      task.lampposts[posLamp].luminaires.push(luminaire);
    } else {
      task.luminaires = task.luminaires.filter(lum => lum.id != luminaire.id);
      task.luminaires.push(luminaire);
    }
    return task
  }

  public setMapOnAllMarkers(map, markers): void {
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  }

  public setLumToLamp(selectedMarkers: google.maps.Marker [], event: Function){
    for(let marker of selectedMarkers){
      marker.setIcon('assets/img/svg/link.svg');
      marker.addListener('click', () => event(marker.getTitle()));
    }
  }

  public resetLumMarks(selectedMarkers: google.maps.Marker [], event: Function){
    for(let marker of selectedMarkers){
      marker.setIcon('assets/img/svg/normal.svg');
      marker.addListener('click', () => event(marker.getTitle()));
    }
  }

  public deleteMarkersLuminaires(selectedMarkers: google.maps.Marker [], task: Task){
    let lstMark = selectedMarkers.slice();
    for(let marker of selectedMarkers){
      task.luminaires = task.luminaires.filter(luminaire => luminaire.id != marker.getTitle());
      marker.addListener('mouseover', () => this.mouseoverMarker(lstMark));
      marker.addListener('mouseout', () => this.mouseoutMarker(lstMark));
    }
  }

  public resetLamppost(lamppost:Lampposts, markers, event){  
    for(let luminaire of lamppost.luminaires){
      let marker:any = markers.filter(_marker => _marker.getTitle() == luminaire.id);
      marker = marker[0];
      marker.setIcon('assets/img/svg/normal.svg');
      google.maps.event.clearInstanceListeners(marker);
      marker.addListener('click', () => event(luminaire.id));
    }
  }

  public mouseoverMarker(lst): void{
    for(let marker of lst){
      marker.setIcon('assets/img/svg/ic_link_hover.svg');
    }
  }

  public mouseoutMarker(lst): void{
    for(let marker of lst){
      marker.setIcon('assets/img/svg/link.svg');
    }
  }

  public selectedCircuit(lst, task: Task): void{
    for(let marker of lst){
      let luminaire = this.searchLuminaireInCircuit(marker.getTitle(), task);
      if(luminaire){
        marker.setIcon('assets/img/svg/ic_link_circuit.svg');
      } else {
        marker.setIcon('assets/img/svg/ic_circuit_normal.svg');
      }
    }
  }

  public DesselectedCircuit(lst, task: Task): void{
    for(let marker of lst){
      let luminaire = this.searchLuminaireInCircuit(marker.getTitle(), task);
      if(luminaire){
        marker.setIcon('assets/img/svg/link.svg');
      } else {
        marker.setIcon('assets/img/svg/circuit_normal.svg');
      }
    }
  }

  public offBounce(marker: google.maps.Marker): void {
    if (marker.getAnimation() !== null) {
      marker.setAnimation(null);
    }
  }

  public seeMoreLuminaire(luminaireID: string): void{
    this.router.navigate([`/u/luminaires/${luminaireID}`]);
  }
  public seeMoreVehicles(troopID: string): void{
    this.router.navigate([`/u/troops/${troopID}`]);
  }

  public seeMoreRpu(rpuID: string): void{
    this.router.navigate([`/u/rpu-detail/${rpuID}`]);
  }
  public seeMoreCircuit(circuitID: string): void{
    this.router.navigate([`/u/circuits/${circuitID}`]);
  }
  public seeMoreDevice(DeviceID: string): void{
    this.router.navigate([`/u/smart/devices/device-details/${DeviceID}`]);
  }
  public getStringInfoCircuit(circuit: Circuit, btnEdit?: Boolean):string {
    return`
    <div class="container my-0 mx-0 py-0 px-0">
      <div class="row mx-1 my-0 mt-1">
        <div class="col-6">
          <div class="row">
            <div class="col-12 px-0">
              <strong>${ 'No. de Circuito' }</strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12 px-0">${ circuit.identifier == null  ? 'Vacio' :  circuit.identifier}</div>
          </div>
          <div class="row">
            <div class="col-12 px-0">
              <strong>No. de Medidor</strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12 px-0">${ circuit.meter.identifier == null || circuit.meter.identifier == "" ? 'Vacio' : circuit.meter.identifier }</div>
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Tipo</strong></div>
          </div>
          <div class="row mb-1">
            ${this.substationEnum[circuit.substation.substation_type] == undefined ? 'Vacio' : this.substationEnum[circuit.substation.substation_type]}
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Capacidad</strong></div>
          </div>
          <div class="row mb-1">
            ${this.substationCurrentCapacityEnum[circuit.substation.current_capacity] == undefined ? 'Vacio' : this.substationCurrentCapacityEnum[circuit.substation.current_capacity]}
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Tipo de montaje</strong></div>
          </div>
          <div class="row mb-1">
            ${this.mountingTypeEnum[circuit.meter.mounting_type] == undefined ? 'Vacio' : this.mountingTypeEnum[circuit.meter.mounting_type]}
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Ubicación</strong> (lat, lon)</div>
          </div>
          <div class="row mb-1">
            <div class="col-12 px-0">(${ circuit.meter.location.latitude }, ${ circuit.meter.location.longitude })</div>
          </div>

          <div class="row">
            <div class="col-6 pl-0">
              <input id="btnFirstLamppost" type="button" style="cursor:pointer;" value="Primer Poste" class="${ circuit.first_lamppost.id == null ? 'oe--button--void' :
              'oe--button--info' } ">
            </div>
            <div class="col-6">
              <input id="btnLastLamppost" type="button" style="cursor:pointer;" value="Último Poste" class="${ circuit.last_lamppost.id == null ? 'oe--button--void' :
              'oe--button--info'} float-right">
            </div>
          </div>

        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <strong>Circuito ID</strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12">${ circuit.id }</div>
          </div>
          <div class="row">
            <div class="col-12">
              <strong>Puntuación Ponderada</strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12">${ circuit.weighted_score == null  ? '0' :  circuit.weighted_score}</div>
          </div>
          <div class="row">
            <div class="col-12"><strong>Comentarios</strong></div>
          </div>
          <div class="row">
            <div class="col-12 mb-1">
              <textarea id="textAreaComment" style="width: 100%;" rows="6" cols="40">${ circuit.comment == null ? '' : circuit.comment}</textarea>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-6">
              <a href="u/circuits/${ circuit.id }" onclick="return false;">
                <input id="seeMoreCircuit" type="button" style="cursor:pointer;" value="Ver mas" class="oe--button--info">
              </a>
            </div>
            ${btnEdit ? `
            <div class="col-6">
              <input id="btnEditCircuit" type="button" style="cursor:pointer;" value="Guardar" class="oe--button--info float-right">
            </div>
            ` : ''}
          </div>
        </div>
      </div>
    </div>
    `;
  }

  public getStringInfoRpu(circuit: CircuitRpu, btnEdit?: Boolean):string {
    return`
    <div class="container my-0 mx-0 py-0 px-0">
      <div class="row mx-1 my-0 mt-1">
        <div class="col-6">
          <div class="row">
            <div class="col-12 px-0">
              <strong>${ 'No. de Rpu' }</strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12 px-0">${ circuit.rpus_info.rpu == null  ? 'Vacio' :  circuit.rpus_info.rpu}</div>
          </div>
          <div class="row">
            <div class="col-12 px-0">
              <strong>No. de Medidor</strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12 px-0">${ circuit.rpus_info.meter == null || circuit.rpus_info.meter == "" ? 'Vacio' : circuit.rpus_info.meter }</div>
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Tipo de Tarifa</strong></div>
          </div>
          <div class="row mb-1">
            ${ circuit.rpus_info.tariff_type == null || circuit.rpus_info.tariff_type == "" ? 'Vacio' : circuit.rpus_info.tariff_type }
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Tipo de Periodo</strong></div>
          </div>
          <div class="row mb-1">
            ${ circuit.rpus_info.period_type == null || circuit.rpus_info.period_type == "" ? 'Vacio' : circuit.rpus_info.period_type }
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <div class="col-12 px-0"><strong>Municipio</strong></div>
          </div>
          <div class="row mb-1">
            ${ circuit.rpus_info.municipality == null || circuit.rpus_info.municipality == "" ? 'Vacio' : circuit.rpus_info.municipality }
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Estado</strong></div>
          </div>
          <div class="row mb-1">
            ${ circuit.rpus_info.state == null || circuit.rpus_info.state == "" ? 'Vacio' : circuit.rpus_info.state }
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Direccion</strong></div>
          </div>
          <div class="row mb-1">
            ${ circuit.rpus_info.address == null || circuit.rpus_info.address == "" ? 'Vacio' : circuit.rpus_info.address }
          </div>
          <div class="row mb-1">
            <a href='u/circuits/${ circuit.id}' onclick="return false;">
              <input id="seeMoreCircuit" type="button" style="cursor:pointer;" value="Ver Circuito" class="oe--button--info">
            </a>
            &nbsp;
            <a href='u/energy/rpu-detail/${ circuit.rpus_info.id}' onclick="return false;">
              <input id="seeMoreRpu" type="button" style="cursor:pointer;" value="Ver RPU" class="oe--button--info">
            </a>
          </div>
        </div>
      </div>
    </div>
    `;
  }

  public getStringInfo(luminaire: Luminaire, lamppost?: Lamppost):string {
    let strType = '',
      strTechonology = '',
      strPhotoCell = '';
    for(let i = 0; i < this.luminaireEnum.length; i++){
      let type = this.luminaireEnum[i];
      strType += `<option value="${i}" ${luminaire.type == i ? 'selected' : ''}>${type}</option>`;
    }
    for(let i = 0; i < this.technologyEnum.length; i++){
      let technology = this.technologyEnum[i];
      strTechonology += `<option value="${i}" ${luminaire.technology.id == i ? 'selected' : ''}>${technology}</option>`;
    }
    strPhotoCell += `<option value="0" ${luminaire.has_photocell ? 'selected' : ''}>No</option>`;
    strPhotoCell += `<option value="1" ${luminaire.has_photocell ? 'selected' : ''}>Si</option>`;
    return`
    <div class="container my-0 mx-0 py-0 px-0">
      <div class="row mx-1 my-0 mt-1">
        <div class="col-6">
          <div class="row">
            <div class="col-12 px-0">
              <strong>${ luminaire.reference_id ? 'Referencia' : 'ID' }</strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12 px-0">${ luminaire.reference_id || luminaire.id }</div>
          </div>
          <div class="row">
            <div class="col-12 px-0">
              <strong>Tipo</strong>
            </div>
          </div>
          <div class="row mb-1">
            ${this.luminaireEnum[luminaire.type]}
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Tecnología</strong></div>
          </div>
          <div class="row mb-1">
            ${luminaire.technology.name}
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Fotocelda</strong></div>
          </div>
          <div class="row mb-1">
            ${luminaire.has_photocell ? 'Si' : 'No'}
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Ubicación</strong> (lat, lon)</div>
          </div>
          <div class="row mb-1">
            <div class="col-12 px-0">(${ luminaire.location.latitude }, ${ luminaire.location.longitude })</div>
          </div>
        </div>
        <div class="col-6">
          ${ lamppost ? `
          <div class="row">
            <div class="col-12"><strong>Poste ID</strong></div>
          </div>
          <div class="row">
            <div class="col-12 mb-1">
              ${lamppost.id}
            </div>
          </div>
          ` : `` }
          <div class="row">
            <div class="col-12"><strong>Comentarios</strong></div>
          </div>
          <div class="row">
            <div class="col-12 mb-1">
              <textarea id="textAreaComment" style="width: 100%;" rows="6" cols="40">${ luminaire.comment == null ? '' : luminaire.comment }</textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <a href="u/luminaires/${ luminaire.id }" onclick="return false;">
                <input id="seeMoreLuminaire" type="button" style=" cursor:pointer;" value="Ver mas" class="oe--button--info">
              </a>
            </div>
            <div class="col-6">
              <input id="luminaireEdit" type="button" style="cursor:pointer;" value="Guardar" class="oe--button--info float-right">
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
  }

  public getStringLamppostOptions(luminaire: Luminaire):string {
    return`
    <div>
      <div class="col-12">
        <input id="lamppostUnir" type="button" class="oe--button--gray--popover mr-1" value="Unir a circuito">
      </div>
    </div>
    `;
  }

  public getStringCircuitOptions() {
    return`
    <div>
      <div class="col-12">
        <input id="circuittUnir" type="button" class="oe--button--gray--popover mr-1" value="Ligar poste">
        <input id="circuitDelete" type="button" class="oe--button--gray--popover mr-1" value="Eliminar circuito">
      </div>
    </div>
    `;
  }

  public getStringLamppostTheCircuitOptions() {
    return`
    <div>
      <div class="col-12">
        <input id="lamppostSeparar" type="button" class="oe--button--gray--popover mr-1" value="Separar Poste del Circuito">
      </div>
    </div>
    `;
  }

  public getStringLuminaireOptions(luminaire: Luminaire):string {
    return`
    <div>
      <div class="col-12">
        <input id="luminaireUnir" type="button" class="oe--button--secondary--popover mr-1" value="Unir">
        <input id="luminaireDelete" type="button" class="oe--button--danger--popover" value="Eliminar">
      </div>
    </div>
    `;
  }

  public addDragg(lstMarkers: google.maps.Marker []){
    for(let marker of lstMarkers){
      marker.setDraggable(true);
    }
  }
  public removeDragg(lstMarkers: google.maps.Marker []){
    for(let marker of lstMarkers){
      marker.setDraggable(false);
    }
  }
}
