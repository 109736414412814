import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { CurrentProjectService } from '../cookies/current-project.service';
import { Project } from '@app/models/project';


declare var $: any;
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: RouteInfo[];
    private sequence = [];
    constructor(private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private currentProject: CurrentProjectService

    ) {
    }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        let user = this.getUser();
        const projectPhase = [this.currentProject.getCurrentProject().phase];
        const menuRoles = this.filterItems(this.menuItems, user['role_name_enums']);
        this.menuItems = this.filterMenuPhases(menuRoles, projectPhase);
    }

    private filterItems(asyncItemsMap: RouteInfo[], userRoles: number[]) {
        const accessedItemsMenu = asyncItemsMap.filter(menuItem => {
            if (this.sequence[menuItem.roles.toString()]) return this.sequence[menuItem.roles.toString()];
            if (this.hasPermission(userRoles, menuItem)) {
                this.sequence[menuItem.roles.toString()] = true;
                if (menuItem.submenu.length) {
                    menuItem.submenu = this.filterItems(menuItem.submenu, userRoles);
                }
                return true
            }
            this.sequence[menuItem.roles.toString()] = false;
            return false
        });
        return accessedItemsMenu
    }

    private filterMenuPhases(asyncItemsMap: RouteInfo[], phaseProjects: number[]) {
        const secuencia = [];
        const accessedItemsMenu = asyncItemsMap.filter(menuItem => {
            if (secuencia[menuItem.phases.toString()]) return secuencia[menuItem.phases.toString()];
            if (this.hasPhasePermision(phaseProjects, menuItem)) {
                secuencia[this.menuItems.toString()] = true;
                if (menuItem.submenu.length) {
                    menuItem.submenu = this.filterMenuPhases(menuItem.submenu, phaseProjects);
                }
                return true;
            }
            secuencia[menuItem.phases.toString()] = false;
            return false;
        })

        return accessedItemsMenu;
    }

    private getUser(): object {
        const helper = new JwtHelperService();
        let token = this.authService.getToken();
        let user = helper.decodeToken(token);
        return user;
    }

    private hasPermission(rolesOfUser: number[], menuItem: RouteInfo): boolean {
        if (menuItem.roles.length) {
            return rolesOfUser.some((v) => {
                return menuItem.roles.indexOf(v) >= 0;
            });
        } else {
            return true;
        }
    }

    private hasPhasePermision(phaseNumber: number[], menuItem: RouteInfo): boolean {
        if (menuItem.phases.length) {
            return phaseNumber.some((v) => {
                return menuItem.phases.indexOf(v) >= 0;
            })
        } else {
            return true;
        }
    }

}
