import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { CurrentProjectService } from '../cookies/current-project.service';
import { AuthService } from '../auth/auth.service';

@Injectable()

export class ProjectHeaderInterceptor implements HttpInterceptor {
  constructor(private currentProjectService: CurrentProjectService, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isHeaderNeeded(request.url)) {
      request = request.clone({
        headers: request.headers.append('X-Project', this.currentProjectService.getCurrentProject().id)
        .append('X-Phase', this.currentProjectService.getCurrentProject().phase.toString())
      });
    }
    return next.handle(request);
  }

  isHeaderNeeded(url: string) {
    if (url.startsWith('https://apis.forcsec.com/api/codigos-postales')) {
      return false;
    } else {
      return true;
    }
  }
}
