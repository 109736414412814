import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TasksService } from './tasks.service'
import { Task } from '@models/task';
import { GetTasksResponse } from '@models/get-tasks-response';
import { Toastr } from '@shared/toastr/toastr.service'
import { User } from '@models/user';
import { Sector } from '@models/sector';
import { GetSectorsResponse } from '@models/get-sectors-response';
import { Trooper } from 'app/models/trooper';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GenericTable } from '@app/models/generic-table';
import { ActivatedRoute, Router } from '@angular/router';

class SearchValues {
  type?: string;
  user?: string;
  sector?: string;
  reference?: string;
  finished?: string;
}

@Component({
  selector: 'tasks-component',
  templateUrl: './tasks.component.html',
  providers: [TasksService]
})

export class TasksComponent implements OnInit {
  tasksSearchForm: FormGroup;
  tasks: GenericTable<GetTasksResponse>;
  troopSupervisors: User[] = [];
  troopers: Trooper[] = [];
  sectors: Sector[] = [];
  showAddButtons = true;
  pages = 0;
  valueOfTaskSearch: SearchValues;

  constructor(
    private fb: FormBuilder,
    private _tasks: TasksService,
    private toastr: Toastr,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.tasksSearchForm = fb.group({
      type: '',
      user: '',
      sector: '',
      finished: '',
      reference: ''
    });
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(queries => {
      this.pages = queries.get('page') ? parseInt(queries.get('page')) : 0;
      const type = queries.get('type') ? queries.get('type') : '';
      const user = queries.get('user') ? queries.get('user') : '';
      const sector = queries.get('sector') ? queries.get('sector') : '';
      const reference = queries.get('reference') ? queries.get('reference') : '';
      const finished = queries.get('finished') ? queries.get('finished') : '';
      this.getTasks(this.pages, type, user, sector, reference, finished);
      this.populateForm(type, user, sector, reference, finished);
      this.getTroopSupervisors();
      this.getSectors();
    });
    //   this.getTasks(0);
    // this.getTroopSupervisors();
    // this.getSectors();
    this.setButtonVisibility();
  }


  get_task_detail(task: GetTasksResponse) {
    this.router.navigate([`/u/tasks/${task.id}/${task.type}`], { relativeTo: this.route });
  }


  setButtonVisibility() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);
    if (decodeToken["role_name_enums"][0] === 8 || decodeToken["role_name_enums"][0] === 11) {
      this.showAddButtons = false;
    }
  }

  getTasks(page: number, type = '', user = '', sector = '', reference = '', finished = ''): void {
    this._tasks.getTasks(page, type, user, sector, reference, finished).subscribe(response => {
      this.tasks = response;

      this.valueOfTaskSearch = {
        type: type,
        user: user,
        sector: sector,
        reference: reference,
        finished: finished
      }
    }, error => {
      this.toastr.error(error);
    });
  }
  onSearchPress({ value }: { value: SearchValues }) {
    this.router.navigate(['/u/tasks'],
      {
        relativeTo: this.route,
        queryParams: {
          page: this.pages,
          type: value.type,
          user: value.user,
          sector: value.sector,
          reference: value.reference,
          finished: value.finished
        }
      });
  }

  pageChange(page: number) {
    //  this.getTasks(page - 1);
    this.pages = page - 1;
    this.router.navigate(['/u/tasks'],
      {
        relativeTo: this.route,
        queryParams: {
          page: this.pages,
          type: this.valueOfTaskSearch.type,
          user: this.valueOfTaskSearch.user,
          sector: this.valueOfTaskSearch.sector,
          reference: this.valueOfTaskSearch.reference,
          finished: this.valueOfTaskSearch.finished
        }
      });
  }

  private getSectors(): void {
    this._tasks
      .getSectors()
      .subscribe(
        resp => {
          this.sectors = resp;
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  private getTroopSupervisors(): void {
    this._tasks
      .getTroopers()
      .subscribe(
        (getTroopSupervisorsResponse: Trooper[]) => {
          this.troopers = getTroopSupervisorsResponse.filter(x => x.is_active == true);
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  private populateForm(type: string, user: string, sector: string, reference: string, finished: string) {
    this.tasksSearchForm.patchValue({
      type: type,
      user: user,
      sector: sector,
      reference: reference,
      finished: finished
    });
  }
}
