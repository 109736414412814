import { FormGroup } from '@angular/forms';

export class NewTroopPayload {
  number: string;
  ipad_serial_number: string;
  plates: string;

  constructor(newTroopForm: FormGroup) {
    this.number = newTroopForm.get('number').value;
    this.ipad_serial_number = newTroopForm.get('ipad_serial_number').value;
    this.plates = newTroopForm.get('plates').value;
  }


  toJson() {
    return {
      troop: {
        number: this.number,
        ipad_serial_number: this.ipad_serial_number,
        plates: this.plates
      }
    };
  }
}