import { Component } from '@angular/core';
import { AuthService } from '@shared/auth/auth.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'download-app-component',
  templateUrl: './download-app.component.html'
})

export class DownloadAppComponent {

  ipaURL = environment.iOSIPA;
  constructor(
    private authService: AuthService
  ) {}


  logout() {
    this.authService.logout();
  }
}