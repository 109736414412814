import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Judment, GuarantyPhotoCellsOpinion, GuarantyLuminaryOptions } from '@app/models/judment';
import { GenericTable } from '@app/models/generic-table';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '@app/shared/auth/auth.service';
import { stat } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class JudmentService {

  constructor(
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private authService: AuthService
  ) { }

  private downloadProcess(file, fileName, extension) {
    let newBlob;

    if (extension === 'pdf') {
      newBlob = new Blob([file], { type: 'application/pdf' });
    } else {
      newBlob = new Blob([file], { type: 'application/vnd.google-earth.kml+xml' });
    }

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  filter_Judment() {
  }

  downloadLuminairesReport(form: any) {
    this.httpClient.post(
      `${environment.SERVER_TASKS}/api/GuarantyClaimsOpinionReport/Get_Guaranty_Claim_Luminaire_Report`, form, { responseType: 'blob' }
    ).subscribe(file => {

      this.downloadProcess(file, `Dictamen-Luminaria.pdf`, 'pdf');
    }, error => {
      this.spinner.hide();
    });
  }

  downloadPhotocellsReport(form: any) {
    this.httpClient.post(
      `${environment.SERVER_TASKS}/api/GuarantyClaimsOpinionReport/Get_Guaranty_Claim_PhotoCell_Report`, form, { responseType: 'blob' }
    ).subscribe(file => {

      this.downloadProcess(file, `Reporte-Fotoceldas.pdf`, 'pdf');
    }, error => {
      this.spinner.hide();
    });
  }

  getList_Judment(form, page: number) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const httpParams = new HttpParams()
    .set('page', page.toString());
    return this.httpClient.post<GenericTable<Judment>>(
      `${environment.SERVER_TASKS}/api/GuaratyClaimsOpinion/GetList_Guaranty_Claim_Option`,form, { params: httpParams, headers: httpHeaders }
    ).pipe(
      map(elements => new GenericTable<Judment>(elements))
    );
  }

  get_Photocells_Opinions(guranty_photocell_claim_id: string) {
    const httpParams = new HttpParams().set('guranty_photocell_claim_id', guranty_photocell_claim_id);
    return this.httpClient.get<GuarantyPhotoCellsOpinion>(
      `${environment.SERVER_TASKS}/api/GuaratyClaimsOpinion/Get_Photocell_Opinion`, { params: httpParams }
    ).pipe(
      map(elements => new GuarantyPhotoCellsOpinion(elements))
    );
  }

  register_PhotocellOpinion(insert) {
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/GuaratyClaimsOpinion/Register_PhotoCell_Opinion`, insert);
  }

  update_PhotocellOpinion(update) {
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/GuaratyClaimsOpinion/Update_PhotoCell_Opinion`, update);
  }




  get_Luminaires_Opinions(guranty_luminaire_claim_id: string) {
    const httpParams = new HttpParams().set('guranty_luminaire_claim_id', guranty_luminaire_claim_id)
    return this.httpClient.get<GuarantyLuminaryOptions>(
      `${environment.SERVER_TASKS}/api/GuaratyClaimsOpinion/Get_Luminaire_Opinion`, { params: httpParams }
    ).pipe(
      map(elements => new GuarantyLuminaryOptions(elements))
    );
  }

  registerLuminairesOpinion (insert) {
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/GuaratyClaimsOpinion/Register_Luminaire_Opinion`, insert);

  }

  updateLuminaireOpinion(file: FormData) {
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/GuaratyClaimsOpinion/Update_Luminaire_Opinion`, file);


  }


  updateLuminaireOpinion_V2(file) {
    const httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data;boundary=---WebKitFormBoundaryuL67FWkv1CA'
      })
    };
    const body = file;
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/GuaratyClaimsOpinion/Update_Luminaire_Opinion`, body, httpOptions);
  }
}
