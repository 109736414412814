import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '@app/shared/auth/auth.service';
import { VirtualCircuits, ValuesVirtualCircuit, Luminaires } from './virtualcircuitsmodel';
import { GenericTable } from '@app/models/generic-table';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VirtualcircuitsService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  downloadCircuitDetailsCLSX(form) {
    const httpParams = new HttpParams()
      .set('is_virtual', form.get('is_VirtualCircuit').value)
      .set('tariff_type', form.get('tariffType').value)
      // .set('status', form.get('status').value)
      .set('rpu', form.get('RPU').value);

    // return this.httpClient.get<any>(`${environment.SERVER_TASKS}/api/VirtualCircuits/exportFile`
    // );

    this.httpClient.get(
      `${environment.SERVER_TASKS}/api/VirtualCircuits/exportFile`,
      { responseType: 'blob', params: httpParams }
    ).subscribe(file => {
      this.downloadProcess(file, `DetalleCircuitos.xlsx`);
    }, error => {
      console.log(error);
    });


  }

  private downloadProcess(file, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([file], { type: 'application/vnd.ms-excel' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }



  getListVirtualCircuits(form: FormGroup, page) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const httpParams = new HttpParams()
      .set('page', page)
      .set('is_VirtualCircuit', form.get('is_VirtualCircuit').value)
      .set('tariffType', form.get('tariffType').value)
      .set('status', form.get('status').value)
      .set('rpu', form.get('RPU').value);
    return this.httpClient.get<GenericTable<VirtualCircuits>>(
      `${environment.SERVER_TASKS}/api/VirtualCircuits/get-list-virtual-circuits`, { params: httpParams, headers: httpHeaders }
    ).pipe(
      map(elements => new GenericTable<VirtualCircuits>(elements))
    );
  }

  registerLuminairesToCircuit(insert) {
    return this.httpClient.post(
      `${environment.SERVER_TASKS}/api/VirtualCircuits/register-luminaires-virtual-circuits`, insert
    );
  }

  getluminairesVirtualCircuit(circuit_id) {
    // const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const httpParams = new HttpParams()
      .set('circuit_id', circuit_id);
    return this.httpClient.get<ValuesVirtualCircuit>(
      `${environment.SERVER_TASKS}/api/VirtualCircuits/get-list-luminaires-virtual-circuits`, { params: httpParams }
    ).pipe(
      map(elements => new ValuesVirtualCircuit(elements))
    );

  }

  getMoreLuminaires(latitude, longitude, circuit_id): Observable<Luminaires[]> {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const params = new HttpParams()
      .set('latitude', latitude.toString())
      .set('longitude', longitude.toString())
      .set('circuit_id', circuit_id);

    return this.httpClient.get<Luminaires[]>(
      `${environment.SERVER_TASKS}/api/VirtualCircuits/get-more-luminaires-virtual-circuits`, { params: params, headers: httpHeaders }
    ).pipe(
      map(data => data.map(item => new Luminaires(item)))
    );
  }

  registerVirtualCircuits(insert) {
    return this.httpClient.post(
      `${environment.SERVER_TASKS}/api/VirtualCircuits/register-virtual-circuits`, insert
    );
  }

  getListLuminairesPhysicalCircuit(latitude, longitude): Observable<Luminaires[]> {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const params = new HttpParams()
      .set('latitude', latitude.toString())
      .set('longitude', longitude.toString());

    return this.httpClient.get<Luminaires[]>(
      `${environment.SERVER_TASKS}/api/VirtualCircuits/get-list-luminaires-physical-circuits`, { params: params, headers: httpHeaders }
    ).pipe(
      map(data => data.map(item => new Luminaires(item)))
    );
  }

}
