import { FormGroup } from '@angular/forms';
import { SurveyArea } from './survey-area';
import { ReportedElements } from './reported_elements';

export class NewRepairTaskPayload {
  type: number;
  sector: string;
  deadline: string;
  user_id: string;
  circuits: any[];
  luminaires: any[];
  selected: Object;
  formattedDeadline: string;

  constructor(newQuickTaskPayload: FormGroup) {
    this.type = newQuickTaskPayload.get('type').value;
    this.sector = newQuickTaskPayload.get('sector').value;
    this.formattedDeadline = newQuickTaskPayload.get('formattedDeadline').value;
    this.user_id = newQuickTaskPayload.get('user_id').value;
    this.luminaires =
      this.deserializeLuminaires(newQuickTaskPayload.get('selected').value);
  }

  toJson(): Object {
    let json = {
        type: this.type,
        sector: this.sector,
        deadline: this.formattedDeadline,
        user: null,
        circuits: this.circuits,
        luminaires: this.luminaires
    };
    if(this.user_id){
      json['user'] = this.user_id
    }
    return json;
  }

  private deserializeDeadline(deadline: any): string {
    return `${deadline.year}-${deadline.month}-${deadline.day}`;
  }

  private deserializeCircuits(selected: ReportedElements): any[] {
    return selected.circuits.map( circuit => { return circuit.id }); 
  }

  private deserializeLuminaires(selected: ReportedElements): any[] {
      let luminaires = [];
      for(let circuit of selected.circuits){
        for(let lamppost of circuit.lampposts){
          let _filter = lamppost.luminaires.filter(luminaire => luminaire );
          luminaires = luminaires.concat(_filter.map(luminaire => { return luminaire.id }));
        }
      }
      for(let lamppost of selected.lampposts){
          let _filter = lamppost.luminaires.filter(luminaire => luminaire );
          luminaires = luminaires.concat(_filter.map(luminaire => { return luminaire.id  }));
      }
      luminaires = luminaires.concat(selected.luminaires.map(luminaire => { return luminaire.id }));
    return luminaires; 
  }
}
