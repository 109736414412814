import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { RepairTasksTableService } from './repair-tasks-table.service';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { RepairTasksResponse, RepairTasks } from 'app/models/reapir-tasks-response';
import { RepairTask } from 'app/models/repair-task';
import { PaginationAttributes } from 'app/models/pagination';
import { Router, ActivatedRoute } from '@angular/router';
import { Luminaire } from 'app/models/luminaire';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TasksService } from 'app/pages/tasks/tasks.service';
import { GetSectorsResponse } from 'app/models/get-sectors-response';
import { Trooper } from 'app/models/trooper';
import { Sector } from 'app/models/sector';
import { GenericTable } from '@app/models/generic-table';

@Pipe({ name: "getReference_idLuminaires" })
export class getReference_idLuminairesPipe implements PipeTransform {
  transform(luminaires: number[]): string {
    let lums = luminaires.map(luminaire => luminaire || 'Sin referencia')
    return lums.join(', ');
  }
}

@Pipe({ name: "getTickets" })
export class getTicketsPipe implements PipeTransform {
  transform(tickets: any[], reduce: boolean = false): string {
    if (reduce && tickets.length >= 3)
      tickets = [tickets[0], tickets[1], tickets[2]]
    return tickets.map(ticket => ticket).join(', ');
  }
}

@Pipe({ name: "getTicketsWithOutreference" })
export class getTicketsWithOutreferencePipe implements PipeTransform {
  transform(tickets: any[], reduce: boolean = false): string {
    if (reduce && tickets.length >= 3)
      tickets = [tickets[0], tickets[1], tickets[2]]
    return tickets.map(ticket => ticket).join(', ');
  }
}


@Component({
  selector: 'app-repair-tasks-table',
  templateUrl: './repair-tasks-table.component.html',
  styleUrls: ['./repair-tasks-table.component.scss'],
  providers: [RepairTasksTableService, TasksService]
})
export class RepairTasksTableComponent implements OnInit {
  tasks: RepairTasks[];
  pagination: PaginationAttributes = null;
  repairTasksSearchForm: FormGroup;
  currentPage = 0;
  sectors: Sector[] = [];
  troopers: Trooper[] = [];


  constructor(
    private toastr: Toastr,
    private router: Router,
    private _tasks: TasksService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private repairTasksTableService: RepairTasksTableService
  ) {
    this.repairTasksSearchForm = formBuilder.group({
      q: [''],
      user: [''],
      sector: [''],
      finished: [''],
      end_date: ['']
    });
  }

  ngOnInit() {
    this.getSectors();
    this.getTroopSupervisors();
    this.route.queryParamMap.subscribe(queries => {
      this.currentPage = queries.get('page') ? +queries.get('page') : 0;
      const task_lum_ticket = queries.get('task_lum_ticket') ? queries.get('task_lum_ticket') : '';
      const user_id = queries.get('user_id') ? queries.get('user_id') : '';
      const sector = queries.get('sector') ? queries.get('sector') : '';
      const is_Finished = queries.get('is_Finished') ? queries.get('is_Finished') : '';
      const end_date = queries.get('end_date') ? queries.get('end_date') : '';

      this.repairTasksTableService.getTasks(this.currentPage, task_lum_ticket, sector, user_id, is_Finished, end_date)
        .subscribe((response: GenericTable<RepairTasks>) => {
          // console.log(response.elements, 'valores de la tabla');
          this.tasks = response.elements.map(task => new RepairTasks(task));
          this.pagination = new PaginationAttributes(
            response.index,
            response.next,
            response.previous,
            response.count,
            response.pages,
            response.from,
            response.size)
        }, (error) => {
          this.toastr.error(error);
        })
    });
  }



  pageChange(page: number) {
    let date_form = this.repairTasksSearchForm.get('end_date').value;
    this.currentPage = page;
    this.router.navigate(['/u/call-center/repair-tasks'],
      {
        relativeTo: this.route,
        queryParams: {
          page: this.currentPage - 1,
          task_lum_ticket: this.repairTasksSearchForm.get('q').value,
          user_id: this.repairTasksSearchForm.get('user').value,
          sector: this.repairTasksSearchForm.get('sector').value,
          is_Finished: this.repairTasksSearchForm.get('finished').value,
          end_date: this.parseDate(date_form),
        }
      });
  }

  onSearchPress(value) {
    let date_form;
    if (this.repairTasksSearchForm.get('end_date').value) {
      date_form = this.repairTasksSearchForm.get('end_date').value;
    } else {
      date_form = null;
    }



    this.router.navigate(['/u/call-center/repair-tasks'],
      {
        relativeTo: this.route,
        queryParams: {
          page: this.currentPage,
          task_lum_ticket: this.repairTasksSearchForm.get('q').value,
          user_id: this.repairTasksSearchForm.get('user').value,
          sector: this.repairTasksSearchForm.get('sector').value,
          is_Finished: this.repairTasksSearchForm.get('finished').value,
          end_date: this.parseDate(date_form),
        }
      });
  }

  private getSectors(): void {
    this._tasks
      .getSectors()
      .subscribe(
        response => {
          this.sectors = response;
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  private getTroopSupervisors(): void {
    this._tasks
      .getTroopers()
      .subscribe(
        (getTroopSupervisorsResponse: Trooper[]) => {
          this.troopers = getTroopSupervisorsResponse.filter(x => x.is_active == true);
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  getClass(value: number) {
    const classes = {
      'status-0': value === 0,
      'status-1': value === 1,
      'status-2': value === 2,
      'status-3': value === 3,
      'status-4': value === 4
    }
    return classes
  }

  parseDate(date) {
    if (date) {
      return `${date.year}/${date.month}/${date.day}`;
    }
    return '';
  }
}
