import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Base } from '@models/base';
import { Toastr } from '@shared/toastr/toastr.service';
import { EditBasePayload } from './edit-base-payload';
import { EditLuminaireService } from '../edit-luminaire.service';
import { BaseMaterialEnum } from './base-material-enum';
import { NewBases } from '../luminaire-edit-all';
import { AuthService } from '@shared/auth/auth.service';

@Component({
  selector: 'app-edit-base',
  templateUrl: './edit-base.component.html',
  providers: [EditLuminaireService]
})
export class EditBaseComponent implements OnInit {
  baseMaterialEnum = Object.keys(BaseMaterialEnum).filter(key => !isNaN(Number(BaseMaterialEnum[key])));
  
  isClient : Boolean;
  image: any;
  baseForm: any;

  @Input() base: NewBases;
  constructor(
    private authService: AuthService,
    private toastr: Toastr,
    private formBuilder: FormBuilder,
    private editLuminaireService: EditLuminaireService
  ) { }

  ngOnInit() {
    this.isClient = this.authService.isClient();
    this.baseForm = this.formBuilder.group({
      id: [null],
      is_present: [null],
      material: [null],
      height: [null],
      width: [null],
      length: [null],
      bolt_spacing: [null],
      condition: [null, Validators.max(5)],
      image_url: [null],
      image: [null]
    });
    this.populateForm(this.base);
  }

  private populateForm(base){
    this.baseForm.patchValue({
      id: base.id,
      is_present: base.is_present,
      bolt_spacing: base.bolt_spacing,
      height: base.height,
      width: base.width,
      length: base.length,
      condition: base.condition,
      material: base.material,
      image_url: base.image_url
    });

    if(this.isClient){
      this.baseForm.get('is_present').disable();
      this.baseForm.get('material').disable();
      this.baseForm.get('bolt_spacing').disable();
      this.baseForm.get('height').disable();
      this.baseForm.get('width').disable();
      this.baseForm.get('length').disable();
      this.baseForm.get('condition').disable();
    }
  }

  performFormAction(): void{
    const formData: FormData = new FormData();
    
    formData.append('id', this.baseForm.value.id);
    formData.append('is_present', this.baseForm.value.is_present);
    if (this.baseForm.value.material != null) {
    formData.append('material', this.baseForm.value.material);
    }
    if (this.baseForm.value.bolt_spacing != null) {
    formData.append('bolt_spacing', this.baseForm.value.bolt_spacing);
    }
    if (this.baseForm.value.height != null) {
    formData.append('height', this.baseForm.value.height);
    }
    if (this.baseForm.value.width != null) {
    formData.append('width', this.baseForm.value.width);
    }
    if (this.baseForm.value.length != null) {
    formData.append('length', this.baseForm.value.length);
    }
    if (this.baseForm.value.condition != null) {
      formData.append('condition', this.baseForm.value.condition);
      }
    formData.append('image', this.image);

console.log(formData.get('material'))
      this.editLuminaireService
        .editBase(formData)
        .subscribe(
          () => {
            this.toastr.success('Base actualizada.');
          },
          (error) => {
            this.toastr.error(error);
          }
        )

  }
  setImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {

      this.image = event.target.files[0];
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.baseForm.patchValue({ image_url: reader.result })
        this.baseForm.patchValue({ image: file })
      };

      reader.readAsDataURL(file);
    }
  }
}
