import { SecondarySwitchesCurrentCapacityEnum } from "./secondary_switches_current_capacity-enum";

interface SecondarySwitchInterface {
    id: string,
    current_capacity: number,
    current_capacity_name: string,
    is_functional: boolean,
    is_present: boolean
}

export class SecondarySwitch implements SecondarySwitchInterface {
    id: string;
    is_present: boolean;
    current_capacity: number;
    current_capacity_name: string;
    is_functional: boolean;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.is_present = attrs.is_present ? true : false;
    this.current_capacity = attrs.current_capacity;
    this.current_capacity_name = SecondarySwitchesCurrentCapacityEnum[attrs.current_capacity];
    this.is_functional = attrs.is_functional ? true: false;
  }
}
