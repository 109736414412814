import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router"

import { EditUserService } from './edit-user.service';
import { EditUserPayload } from './edit-user-payload';
import { GetUserResponse } from '@models/get-user-response';
import { Toastr } from '@shared/toastr/toastr.service'

import { User } from '@models/user';
import { Project } from '@models/project';
import { UserFormComponent } from '../user-form/user-form.component';
import { GetProjectsResponse } from '@models/get-projects-response';
import { EditPasswordPayload } from './edit-password-payload';

@Component({
  selector: 'edit-user-component',
  templateUrl: './edit-user.component.html',
  providers: [EditUserService]
})

export class EditUserComponent {
  userForm: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    last_name: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    role_id: [null, Validators.required],
    cell_phone_number: [""],
    address: [""],
    assigned_tools: [""],
    troops: [this.formBuilder.array([])],
    troop_id: [null],
    password: [null, Validators.required],
    password_confirmation: [null, Validators.required],
    image: [null],
    image_url: [null]
  });
  passwordForm: FormGroup = this.formBuilder.group({
    password: [null, Validators.required],
    password_confirmation: [null, Validators.required],
  });
  projectsForm: FormGroup = this.formBuilder.group({
    projectId: [null, Validators.required]
  })
  editableUserId: string;
  editableUser: User;
  userProjects: Project[] = [];
  projects: Project[] = [];

  @ViewChild(UserFormComponent) userFormComponent: UserFormComponent

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: Toastr,
    private router: Router,
    private editUserService: EditUserService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.editableUserId = this.activatedRoute.snapshot.paramMap.get('id');

    this.editUserService
      .getUser(this.editableUserId)
      .subscribe(
        (getUserResponse: GetUserResponse) => {
          const user = new User(getUserResponse.user);

          this.editableUser = user;
          this.userProjects = user.projects;
          this.userFormComponent.setEditableUser(user);
          this.userFormComponent.initializeUserForm(user);
          this.userFormComponent.getTroops();
        },
        (error) => {
          this.toastr.error(error);
        }
      )

    this.getProjects();
  }

  editUser(): void {
    console.log(this.userForm)
    console.log(this.userForm.valid)
    if (this.userForm.valid) {
      const requestPayload = new EditUserPayload(this.userForm, this.userFormComponent.user_troops);

      this.editUserService
        .editUserData(this.editableUser, requestPayload)
        .subscribe(
          () => {
            this.toastr.success('Usuario actualizado.');
            this.router.navigate(['/u/users']);
          },
          (error) => {
            this.toastr.error(error);
          }
        )
    } else {
      this.userForm.controls.name.markAsTouched();
      this.userForm.controls.last_name.markAsTouched();
      this.userForm.controls.email.markAsTouched();
      this.userForm.controls.role_id.markAsTouched();
      if(this.userForm.get('troop_id').errors)
        this.userForm.controls.troop_id.markAsTouched();
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  onRemove(project: Project): void {
    this.removeUserFromProject(project);
  }

  performAction(form: FormGroup): void {
    this.userForm = form;

    this.editUser();
  }

  updatePassword(form: FormGroup): void {
    this.passwordForm = form;
   
    if (this.passwordForm.valid) {
      const requestPayload = new EditPasswordPayload(this.passwordForm);
      this.editUserService
        .editUser(this.editableUser, requestPayload)
        .subscribe(
          () => {
            this.toastr.success('Contraseña actualizada.');
            this.passwordForm.patchValue({
              password: null,
              password_confirmation: null
            });
            this.passwordForm.markAsUntouched()
          },
          (error) => {
            this.toastr.error(error);
          }
        )
    } else {
      this.passwordForm.controls.password.markAsTouched();
      this.passwordForm.controls.password_confirmation.markAsTouched();
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  public addUserToProject(): void {
    if(this.projectsForm.get('projectId').value == null)
      return
    let selectedProject = this.projects.find( project => project.id === this.projectsForm.get('projectId').value );

    this.editUserService
      .addUserToProject(selectedProject, this.editableUser)
      .subscribe(
        () => {
          this.userProjects.push(selectedProject);
          this.toastr.success('Proyecto agregado.');
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  private getProjects(): void {
    this.editUserService
      .getProjects()
      .subscribe(
        (projectsResponse: GetProjectsResponse) => {
          this.projects = projectsResponse.projects;
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  private removeUserFromProject(project: Project): void {
    this.editUserService
      .removeUserFromProject(project, this.editableUser)
      .subscribe(
        () => {
          this.toastr.success('Proyecto removido.');
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }
}