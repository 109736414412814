import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { NewProjectPayload } from './new-project-payload';
import { NewProjectResponse } from '@models/new-project-response';

@Injectable()

export class NewProjectModalService {
  constructor(private httpClient: HttpClient) {}

  createProject(projectPayload: NewProjectPayload): Observable<NewProjectResponse> {
    return this.httpClient.post<NewProjectResponse>(
      `${environment.SERVER_URL}/projects`,
      projectPayload.toJson()
    );
  }
}