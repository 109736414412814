import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { TicketResponse } from '@models/ticket-response';

@Injectable()

export class CheckReportService {
  constructor(private httpClient: HttpClient) {}

  // getFolio(slug: string, folio: string, last_name: string): Observable<TicketResponse> {
  //   const headers = new HttpHeaders({
  //     'X-Last-Name': last_name
  //   });
  //   return this.httpClient.get<TicketResponse>(
  //     `${environment.SERVER_URL}/projects/${slug}/tickets/${folio}`,
  //     { headers }
  //   );
  // }


  getFolio(reference: string, name: string): Observable<TicketResponse> {
    return this.httpClient.get<any>(
      `${environment.SERVER_CALLCENTER}/api/CheckTickets/CheckTicket/${reference}/${name}`
    )
  }

}
