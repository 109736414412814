import { RepairTask } from "./repair-task";
import { Pagination } from "./pagination";


export interface RepairTasksResponse {
  tasks: RepairTask[],
  meta: {
    pagination: Pagination
  }
}

export interface IRepairTasks {
  id: string;
  reference_id: string;
  created_at: Date;
  deadline?: any;
  is_finished: boolean;
  luminaires_status: number;
  tickets: number[];
  luminaires: number[];
  complete_name?: any;
  luminaires_statusName: string;


}

export class RepairTasks implements IRepairTasks {
  id: string;
  reference_id: string;
  created_at: Date;
  deadline?: any;
  is_finished: boolean;
  luminaires_status: number;
  tickets: number[];
  luminaires: number[];
  complete_name?: any;
  luminaires_statusName: string;

  constructor(attrs) {
    this.id = attrs.id;
    this.reference_id = attrs.reference_id;
    this.created_at = attrs.created_at;
    this.deadline = attrs.deadline != undefined ? new Date(attrs.deadline) : undefined;
    this.is_finished = attrs.is_finished;
    this.luminaires_status = attrs.luminaires_status;
    this.tickets = attrs.tickets;
    this.luminaires = attrs.luminaires;
    this.complete_name = attrs.complete_name;
    this.luminaires_statusName = StatusmEnum[attrs.luminaires_status];
  }
}

enum StatusmEnum {
  'Sin asignar',
  'Programada para reparación',
  'Pendiente',
  'En proceso de reparación',
  'Funcionando'
}