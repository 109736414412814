

interface IMaterial_Categorys {
    id: string,
    name: string,
}

export class Material_Categorys implements IMaterial_Categorys {
  id: string;
  name: string;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.name = attrs.name;
  }
}