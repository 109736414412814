export class ShoppingCart implements IShoppingCart {
    id: string;
    items: ShoppingCartItems[]
    constructor(attrs) {
        this.id = attrs.id;
        this.items = attrs.items;
    }
}

export interface IShoppingCart {
    id: string,
    items: ShoppingCartItems[]
}

export class ShoppingCartItems implements IShoppingCartItems {
    id: string;
    quantity: number;
    material: Material;
    constructor(attrs) {
        this.id = attrs.id;
        this.quantity = attrs.quantity;
        this.material = attrs.material;
    }
}

export interface IShoppingCartItems {
    id: string,
    quantity: number,
    material: Material
}

export class Material {
    id: string;
    image_url: string;
    name: string;

    constructor(attrs) {
        this.id = attrs.id;
        this.name = attrs.name;
        this.image_url = attrs.image_url;
    }

}

export interface IMaterial {
    id: string,
    image_url: string,
    name: string
}


export class TroopShoppingCart implements ITroopShoppingCart {
    id: string;
    fullName: string;

    constructor(attrs) {
        this.id = attrs.id;
        this.fullName = attrs.fullName;
    }
}

export interface ITroopShoppingCart {
    id: string,
    fullName: string
}
