import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevicesService } from '../../devices.service';
import * as FusionCharts from 'fusioncharts';

@Component({
  selector: 'app-device-graphics-rssi',
  templateUrl: './device-graphics-rssi.component.html',
  styleUrls: ['./device-graphics-rssi.component.scss']
})
export class DeviceGraphicsRssiComponent implements OnInit {
  dataSource: any;
  type: string;
  width: string;
  height: string;
  constructor(
    private devicesService: DevicesService,
    private activatedRoute: ActivatedRoute
  ) {
    this.type = 'timeseries';
    this.width = '100%';
    this.height = '600';
    this.dataSource = {
      data: null,
      chart: {
        showLegend: 0
      },
      caption: {
        text: 'Grafica RSSI'
      },
      yAxis: [
        {
          plot: {
            value: 'RSSI',
            type: ''
          },
          title: 'Rango de Lectura',
          referenceline: [
            {
              label: 'Señal Alta', // aqui por si la quieren nombrar
              value: '-75',
              style: {
                marker: {
                  fill: '#41D371',
                  stroke: '#41D371',
                  'stroke-width': 3,
                  'stroke-dasharray': [4, 3]
                }
              }
            },
            {
              label: 'Señal media', // aqui por si la quieren nombrar
              value: '-85',
              style: {
                marker: {
                  fill: '#FF8111',
                  stroke: '#FF8111',
                  'stroke-width': 3,
                  'stroke-dasharray': [4, 3]
                }
              }
            },
            {
              label: 'Señal Baja', // aqui por si la quieren nombrar
              value: '-95',
              style: {
                marker: {
                  fill: '#FF0000',
                  stroke: '#FF0000',
                  'stroke-width': 3,
                  'stroke-dasharray': [4, 3]
                }
              }
            }
          ]
        }
      ]
    };
    // this.fetchData();
  }

  fetchData(data_: any[]) {
    const schemaFetch = [{
      'name': 'Time',
      'type': 'date',
      'format': '%Y-%m-%d %H:%M'
    }, {
      'name': 'RSSI',
      'type': 'number'
    }];

    Promise.all([data_, schemaFetch]).then(res => {
      const [data, schema] = res;
      // First we are creating a DataStore
      const fusionDataStore = new FusionCharts.DataStore();
      // After that we are creating a DataTable by passing our data and schema as arguments
      const fusionTable = fusionDataStore.createDataTable(data, schema);
      // Afet that we simply mutated our timeseries datasource by attaching the above
      // DataTable into its data property.
      // console.log(fusionTable);
      this.dataSource.data = fusionTable;
    });
  }

  ngOnInit() {
    this.devicesService.Get_Reddings30Days(this.activatedRoute.snapshot.paramMap.get('id')).subscribe(response => {
      this.fetchData(response);
    });

  }
}
