import { Component, OnInit } from '@angular/core';
import { KpiTaskService } from '../kpi-task.service';
import { QuickSurveyKpis } from '@app/models/quick-survey-kpis';
import { Toastr } from '@app/shared/toastr/toastr.service';

@Component({
  selector: 'app-kpi-quick-task',
  templateUrl: './kpi-quick-task.component.html'
})
export class KpiQuickTaskComponent implements OnInit {
  quickSurveyKpis: QuickSurveyKpis;
  txtTitle: String = 'Censo Rapido';
  dataUsercounts: number[] = [];
  chartOptions = {
    responsive: true
  };
  chartData = [
    {
      data: this.dataUsercounts,
      borderWidth: 1
    }
  ];
  chartColors: any[] = [
    {
      backgroundColor:["#FF7360", "#6FC8CE", "#FAFFF2", "#FFFCC4", "#B9E8E0"]
    }
  ];

  chartLabels = [];

  constructor(
    private kpiTaskService: KpiTaskService,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.kpiTaskService.getKpisQuickSurvey()
    .subscribe( (response: QuickSurveyKpis) => {
      this.quickSurveyKpis = response;
      for(let user of this.quickSurveyKpis.user_kpis){
        this.chartLabels.push(user.fullName);
        this.dataUsercounts.push(user.luminaire_count);
      }
      this.chartLabels.push('Otros');
      this.dataUsercounts.push(this.quickSurveyKpis.not_troopers_luminaires_count);
    }, error => this.toastr.error(error));
  }

}
