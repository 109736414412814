import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArmMaterialEnum } from './arm-material-enum';
import { ArmGeometryEnum } from './arm-geometry-enum';
import { ArmMountingTypeEnum } from './arm-mounting-type-enum';
import { ArmLengthEnum } from './arm-length-enum';
import { Arm } from '@models/arm';
import { Toastr } from '@shared/toastr/toastr.service';
import { EditArmPayload } from './edit-arm-payload';
import { EditLuminaireService } from '@pages/luminaire/edit-luminaire/edit-luminaire.service';
import { NewArm } from '../luminaire-edit-all';
import { AuthService } from '@shared/auth/auth.service';

@Component({
  selector: 'app-edit-arm',
  templateUrl: './edit-arm.component.html',
  providers: [EditLuminaireService]
})
export class EditArmComponent implements OnInit {
  armMaterialEnum = Object.keys(ArmMaterialEnum).filter(key => !isNaN(Number(ArmMaterialEnum[key])));
  armGeometryEnum = Object.keys(ArmGeometryEnum).filter(key => !isNaN(Number(ArmGeometryEnum[key])));
  armMountingTypeEnum = Object.keys(ArmMountingTypeEnum).filter(key => !isNaN(Number(ArmMountingTypeEnum[key])));
  armLengthEnum = Object.keys(ArmLengthEnum).filter(key => key.length > 2);
  image: any;
  armForm: any;
  isClient : Boolean;
  
  @Input() arm: NewArm;

  constructor(
    private authService: AuthService,
    private toastr: Toastr,
    private formBuilder: FormBuilder,
    private editLuminaireService: EditLuminaireService
  ) { }

  ngOnInit() {
    this.isClient = this.authService.isClient();
    this.armForm = this.formBuilder.group({
      id: [null],
      is_present: [null],
      material: [null],
      geometry: [null],
      mounting_type: [null],
      length: [null],
      condition: [null, Validators.max(5)],
      diameter_is_acceptable: [null],
      wiring_is_inside: [null],
      image_url: [null],
      image: [null]
    });
    this.populateForm(this.arm);
  }

  private populateForm(arm){
    this.armForm.patchValue({
      id: arm.id,
      is_present: arm.is_present,
      material: arm.material,
      geometry: arm.geometry,
      mounting_type: arm.mounting_type,
      length: arm.length,
      condition: arm.condition,
      diameter_is_acceptable: arm.diameter_is_acceptable,
      wiring_is_inside: arm.wiring_is_inside,
      image_url: arm.image_url
    });

    if(this.isClient){
      this.armForm.get('is_present').disable();
      this.armForm.get('material').disable();
      this.armForm.get('geometry').disable();
      this.armForm.get('mounting_type').disable();
      this.armForm.get('length').disable();
      this.armForm.get('condition').disable();
      this.armForm.get('diameter_is_acceptable').disable();
      this.armForm.get('wiring_is_inside').disable();
    }
  }


  performFormAction(): void {
    const formData: FormData = new FormData();
    
    formData.append('id', this.armForm.value.id);
    formData.append('is_present', this.armForm.value.is_present);
    if (this.armForm.value.material != null) {
    formData.append('material', this.armForm.value.material);
    }
    if (this.armForm.value.geometry != null) {
    formData.append('geometry', this.armForm.value.geometry);
    }
    if (this.armForm.value.mounting_type != null) {
    formData.append('mounting_type', this.armForm.value.mounting_type);
    }
    if (this.armForm.value.length != null) {
    formData.append('length', this.armForm.value.length);
    }
    if (this.armForm.value.condition != null) {
    formData.append('condition', this.armForm.value.condition);
    }
    formData.append('diameter_is_acceptable', this.armForm.value.diameter_is_acceptable);
    formData.append('wiring_is_inside', this.armForm.value.wiring_is_inside);
    formData.append('image', this.image);


    //if (this.armForm.valid) {
     // const requestPayload = new EditArmPayload(this.armForm);
  
      this.editLuminaireService
        .editArm(formData)
        .subscribe(
          () => {
            this.toastr.success('Brazo actualizado.');
          },
          (error) => {
            this.toastr.error(error);
          }
        )
    //} else {
     // this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    //}
  }

  setImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {

      this.image = event.target.files[0];
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.armForm.patchValue({ image_url: reader.result })
        this.armForm.patchValue({ image: file })
      };

      reader.readAsDataURL(file);
    }
  }

}
