import { Component, OnInit, Input } from '@angular/core';
import { Circuit } from '@models/circuit';
import { FormBuilder, FormGroup, Validators, Form, FormArray, FormControl } from '@angular/forms';
import { PrimarySwichtCurrentCapacityEnum } from '@models/primary_swicht_current_capacity-enum';
import { SecondarySwitchesCurrentCapacityEnum } from '@models/secondary_switches_current_capacity-enum';
import { ContactorsCurrentCapacityEnum } from '@models/contactors_current_capacity-enum';
import { NumberOfPhotocellsEnum } from '@models/number_of_photocells-enum';
import { NumberOfDamagedPhotocellsEnum } from '@models/number_of_damaged_photocells-enum';
import { NumberOfReceptacleInputsEnum } from '@models/number_of_receptacle_inputs-enum';
import { EditCircuitService } from '../edit-circuit.service';
import { Toastr } from '@shared/toastr/toastr.service';
import { SecondarySwitch } from '@models/secondary_switch';
import { Contactor } from '@models/contactor';

@Component({
  selector: 'app-edit-cabinet',
  templateUrl: './edit-cabinet.component.html'
})
export class EditCabinetComponent implements OnInit {

  primaryswitchCurrentCapacityEnum = Object.keys(PrimarySwichtCurrentCapacityEnum).filter(key => key.length > 2);
  secondarySwitchesCurrentCapacityEnum = Object.keys(SecondarySwitchesCurrentCapacityEnum).filter(key => key.length > 2);
  contactorsCurrentCapacityEnum = Object.keys(ContactorsCurrentCapacityEnum).filter(key => key.length > 2);
  numberOfPhotocellsEnum = Object.keys(NumberOfPhotocellsEnum).filter(key => key.length > 2);
  numberOfDamagedPhotocellsEnum = Object.keys(NumberOfDamagedPhotocellsEnum).filter(key => key.length > 2);
  numberOfReceptacleInputsEnum = Object.keys(NumberOfReceptacleInputsEnum).filter(key => key.length > 2);
  @Input() circuit: Circuit;
  image: any;
  board_image: any;
  photocell_group_photocells_image: any;
  cabinetForm: any;

  secondarySiwtchesArray: Array<any> = [];
  contractorArray: Array<any> = [];
  newSecondSwitch: SecondarySwitch = new SecondarySwitch({
    current_capacity: 1,
    is_functional: false
  });
  currentSecondSiwtch = 0;
  currentContractor = 0;
  contactors_is_prenset = false;
  second_switch_is_present = false;

  constructor(
    private formBuilder: FormBuilder,
    private editCircuitService: EditCircuitService,
    private toastr: Toastr,
  ) { }

  ngOnInit() {
    this.cabinetForm = this.formBuilder.group({
      id: [null],
      is_present: [null],
      is_functional: [null],
      image: [null],
      image_url: [null],
      board_image: [null],
      board_image_url: [null],
      has_secondary_switches: [null],
      number_of_secondary_switches: [null],
      has_contactors: [null],
      number_of_contactors: [null],

      primary_switch_id: [null],
      primary_switch_current_capacity: [null],
      primary_switch_is_present: [null],
      primary_switch_is_functional: [null],
      secondary_switch_is_present: [null],

      secondary_switch: new FormArray([]),
      new_second_siwcht_functional: [null],
      new_second_siwcht_current_capacity: [null],
      currentSecondSiwtch: [null],
      currentContractor: [null],

      measurements_id: [null],
      measurements_entry_voltage: [null],
      measurements_current: [null],
      measurements_power: [null],
      measurements_power_factor: [null],

      photocell_group_id: [null],
      photocell_group_is_present: [null],
      photocell_group_number_of_photocells: [null],
      photocell_group_number_of_damaged_photocells: [null],
      photocell_group_has_receptacle: [null],
      photocell_group_receptacle_is_inside_cabinet: [null],
      photocell_group_number_of_receptacle_inputs: [null],
      photocell_group_photocells_image_url: [null],
      photocell_group_photocells_image: [null],
      contactors: new FormArray([])
    });

    this.populateForm(this.circuit.cabinet);
    this.getSecondarySwitchData();
    this.getContactorsData();
  }

  private populateForm(cabinet) {

    this.cabinetForm.patchValue({
      id: cabinet.id,
      is_present: cabinet.is_present,
      is_functional: cabinet.is_functional,
      image_url: cabinet.image_url,
      board_image_url: cabinet.board_image_url,
      has_secondary_switches: cabinet.has_secondary_switches,
      number_of_secondary_switches: cabinet.number_of_secondary_switches,
      has_contactors: cabinet.has_contactors,
      number_of_contactors: cabinet.number_of_contactors,

      secondary_switch: cabinet.secondary_switches,

      primary_switch_id: cabinet.primary_switch.id,
      primary_switch_current_capacity: cabinet.primary_switch.current_capacity,
      primary_switch_is_present: cabinet.primary_switch.is_present,
      primary_switch_is_functional: cabinet.primary_switch.is_functional,

      measurements_id: cabinet.measurements.id,
      measurements_entry_voltage: cabinet.measurements.entry_voltage,
      measurements_current: cabinet.measurements.current,
      measurements_power: cabinet.measurements.power,
      measurements_power_factor: cabinet.measurements.power_factor,
      newSecondSiwchtFunctional: [null],
      currentContractor: [null],

      photocell_group_id: cabinet.photocell_group.id,
      photocell_group_is_present: cabinet.photocell_group.is_present,
      photocell_group_number_of_photocells: cabinet.photocell_group.number_of_photocells,
      photocell_group_number_of_damaged_photocells: cabinet.photocell_group.number_of_damaged_photocells,
      photocell_group_has_receptacle: cabinet.photocell_group.has_receptacle,
      photocell_group_receptacle_is_inside_cabinet: cabinet.photocell_group.receptacle_is_inside_cabinet,
      photocell_group_number_of_receptacle_inputs: cabinet.photocell_group.number_of_receptacle_inputs,
      photocell_group_photocells_image_url: cabinet.photocell_group.photocells_image_url,

      image: cabinet.image,
      board_image: cabinet.board_image,
      photocell_group_photocells_image: cabinet.photocell_group_photocells_image
    });

    this.currentContractor = this.cabinetForm.value.number_of_contactors;
    this.contractorArray = this.cabinetForm.value.contactors;


    this.secondarySiwtchesArray = this.cabinetForm.value.secondary_switch;
    this.currentSecondSiwtch = this.cabinetForm.value.number_of_secondary_switches;

    this.contactors_is_prenset = cabinet.has_contactors;
    this.second_switch_is_present = cabinet.has_secondary_switches;
  }



  performFormAction() {

    const formData: FormData = new FormData();

    formData.append('id', this.cabinetForm.value.id);
    formData.append('is_present', this.cabinetForm.value.is_present ? 'true' : 'false');
    formData.append('is_functional', this.cabinetForm.value.is_functional ? 'true' : 'false');
    formData.append('has_secondary_switches', this.cabinetForm.value.has_secondary_switches ? 'true' : 'false');
    formData.append('number_of_secondary_switches', this.cabinetForm.value.secondary_switch.length);
    formData.append('has_contactors', this.cabinetForm.value.has_contactors ? 'true' : 'false');
    formData.append('number_of_contactors', this.cabinetForm.value.contactors.length);

    formData.append('primary_switch_id', this.cabinetForm.value.primary_switch_id);
    if (this.cabinetForm.value.primary_switch_current_capacity != null) {
      formData.append('primary_switch_current_capacity', this.cabinetForm.value.primary_switch_current_capacity);
    }
    formData.append('primary_switch_is_present', this.cabinetForm.value.primary_switch_is_present ? 'true' : 'false');
    formData.append('primary_switch_is_functional', this.cabinetForm.value.primary_switch_is_functional ? 'true' : 'false');

    formData.append('measurements_id', this.cabinetForm.value.measurements_id);
    if (this.cabinetForm.value.measurements_entry_voltage != null) {
      formData.append('measurements_entry_voltage', this.cabinetForm.value.measurements_entry_voltage);
    }

    if (this.cabinetForm.value.measurements_current != null) {
      formData.append('measurements_current', this.cabinetForm.value.measurements_current);
    }

    if (this.cabinetForm.value.measurements_power != null) {
      formData.append('measurements_power', this.cabinetForm.value.measurements_power);
    }

    if (this.cabinetForm.value.measurements_power_factor != null) {
      formData.append('measurements_power_factor', this.cabinetForm.value.measurements_power_factor);
    }

    formData.append('photocell_group_id', this.cabinetForm.value.photocell_group_id);
    formData.append('photocell_group_is_present', this.cabinetForm.value.photocell_group_is_present ? 'true' : 'false');
    if (this.cabinetForm.value.photocell_group_number_of_photocells != null) {
      formData.append('photocell_group_number_of_photocells',
        this.cabinetForm.value.photocell_group_number_of_photocells);
    }

    if (this.cabinetForm.value.photocell_group_number_of_damaged_photocells != null) {
      formData.append('photocell_group_number_of_damaged_photocells',
        this.cabinetForm.value.photocell_group_number_of_damaged_photocells);
    }

    formData.append('photocell_group_has_receptacle', this.cabinetForm.value.photocell_group_has_receptacle ? 'true' : 'false');
    formData.append('photocell_group_receptacle_is_inside_cabinet',
      this.cabinetForm.value.photocell_group_receptacle_is_inside_cabinet ? 'true' : 'false');
    if (this.cabinetForm.value.photocell_group_number_of_receptacle_inputs != null) {
      formData.append('photocell_group_number_of_receptacle_inputs', this.cabinetForm.value.photocell_group_number_of_receptacle_inputs);
    }

    formData.append('image', this.image);
    formData.append('board_image', this.board_image);
    formData.append('photocell_group_photocells_image', this.photocell_group_photocells_image);
    formData.append('secondary_switch', JSON.stringify(this.cabinetForm.value.secondary_switch));
    formData.append('contactors', JSON.stringify(this.cabinetForm.value.contactors));

    this.editCircuitService.editCabinetNew(formData).subscribe(
      response => {
        this.toastr.success('Se Actualizo Correctamente.');
      });
  }


  addSecondarySwitch() {
    this.cabinetForm.get('secondary_switch').push(new FormGroup({
      secondary_switch_id: new FormControl(' '),
      secondary_switch_current_capacity: new FormControl(' '),
      secondary_switch_is_functional: new FormControl(' ')
    }))
  }

  getSecondarySwitchData() {
    if (this.cabinetForm.get('secondary_switch').length > 0) {
      // tslint:disable-next-line: forin
      for (const x in this.cabinetForm.get('secondary_switch')) {
        this.cabinetForm.get('secondary_switch').removeAt(x);
      }
    }
    const dat = this.circuit.cabinet.secondary_switches;
    if (dat.length > 0) {
      // tslint:disable-next-line: forin
      for (const x in dat) {
        this.cabinetForm.get('secondary_switch').push(new FormGroup({
          secondary_switch_id: new FormControl(dat[x].id),
          secondary_switch_current_capacity: new FormControl(dat[x].current_capacity),
          secondary_switch_is_functional: new FormControl(dat[x].is_functional)
        }))
      }
    }
  }

  addContactors() {
    this.cabinetForm.get('contactors').push(new FormGroup({
      contactors_id: new FormControl(' '),
      contactors_current_capacity: new FormControl(' '),
      contactorss_is_functional: new FormControl(' ')
    }))
  }

  getContactorsData() {
    if (this.cabinetForm.get('contactors').length > 0) {
      // tslint:disable-next-line: forin
      for (const x in this.cabinetForm.get('contactors')) {
        this.cabinetForm.get('contactors').removeAt(x);
      }
    }
    const dat = this.circuit.cabinet.contactors;
    if (dat.length > 0) {
      // tslint:disable-next-line: forin
      for (const x in dat) {
        this.cabinetForm.get('contactors').push(new FormGroup({
          contactors_id: new FormControl(dat[x].id),
          contactors_current_capacity: new FormControl(dat[x].current_capacity),
          contactorss_is_functional: new FormControl(dat[x].is_functional)
        }))
      }
    }
  }

  track(item: any, index: number) {
    return index;
  }

  // performFormAction():void {
  //   if (this.cabinetForm.valid) {
  //     this.setSecondSiwtch();
  //     this.setContractor();
  //     const requestPayload = new EditCabinetPayload(this.cabinetForm);
  //      this.editCircuitService
  //       .editCabinet(this.circuit, requestPayload)
  //       .subscribe(
  //         () => {
  //           this.toastr.success('Gabinete actualizado.');
  //         },
  //         (error) => {
  //           this.toastr.error(error);
  //         }
  //       )
  //   } else {
  //     this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
  //   }
  // }

  changeSecondSwitch(event: any) {
    if (this.cabinetForm.value.secondary_switch.length === this.currentSecondSiwtch)
      return
    let backup = this.cabinetForm.value.secondary_switch;
    this.cabinetForm.value.secondary_switch = [];
    let eventValue = event.target.value;
    let newValue = this.cabinetForm.get('secondary_switch').value.length;

    if (eventValue < newValue) {
      let dif = newValue - eventValue;
      for (let r = 0; r < dif; r++) {
        this.cabinetForm.get('secondary_switch').removeAt(
          this.cabinetForm.get('secondary_switch').value.findIndex(x => x.secondary_switch_is_functional === false));
      }
    }

    else {
      for (let i = 0; i < this.currentSecondSiwtch; i++) {
        if (backup.length > i) {
          this.cabinetForm.value.secondary_switch.push(backup[i]);
        }
        else {
          this.cabinetForm.get('secondary_switch').push(new FormGroup({
            secondary_switch_id: new FormControl('00000000-0000-0000-0000-000000000000'),
            secondary_switch_current_capacity: new FormControl(' '),
            secondary_switch_is_functional: new FormControl(false)
          }));
        }
      }
    }
  }

  changeContractor(event: any) {
    if (this.cabinetForm.value.contactors.length === this.currentContractor)
      return
    let backup = this.cabinetForm.value.contactors;
    this.cabinetForm.value.contactors = [];
    let eventValue = event.target.value;
    let newValue = this.cabinetForm.get('contactors').value.length;

    if (eventValue < newValue) {
      let dif = newValue - eventValue;
      for (let r = 0; r < dif; r++) {
        this.cabinetForm.get('contactors').removeAt(
          this.cabinetForm.get('contactors').value.findIndex(x => x.contactorss_is_functional === false));
      }
    }
    else {
      for (let i = 0; i < this.currentContractor; i++) {
        if (backup.length > i) {
          this.cabinetForm.value.contactors.push(backup[i]);
        }
        else {
          this.cabinetForm.get('contactors').push(new FormGroup({
            contactors_id: new FormControl('00000000-0000-0000-0000-000000000000'),
            contactors_current_capacity: new FormControl(' '),
            contactorss_is_functional: new FormControl(false)
          }));
        }
      }
    }
  }

  // changeContractor(event: any) {
  //   if (this.contractorArray.length == this.currentContractor)
  //     return
  //   let backup = this.contractorArray;
  //   this.contractorArray = [];

  //   for (let i = 0; i < this.currentContractor; i ++) {
  //     if (backup.length > i) {
  //       this.contractorArray.push(backup[i]);
  //     } else {
  //       let contractor = new Contactor({
  //         id: '',
  //         current_capacity: null,
  //         is_functional: null
  //       });
  //       this.contractorArray.push(contractor);
  //     }
  //   }
  // }

  changePresentContactors(event: any) {
    this.contactors_is_prenset = event;
  }

  changePresentSecondswitch(event: any) {
    this.second_switch_is_present = event;
  }

  setImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {

      this.image = event.target.files[0];
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.cabinetForm.patchValue({ image_url: reader.result })
        this.cabinetForm.patchValue({ image: file })
      };

      reader.readAsDataURL(file);
    }
  }

  setPhotocellImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {

      this.photocell_group_photocells_image = event.target.files[0];
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.cabinetForm.patchValue({ photocell_group_photocells_image_url: reader.result })
        this.cabinetForm.patchValue({ photocell_group_photocells_image: file })
      };

      reader.readAsDataURL(file);
    }
  }

  setBaseImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.board_image = event.target.files[0];


      const reader = new FileReader();
      reader.onload = e => {
        this.cabinetForm.patchValue({ board_image_url: reader.result })
        this.cabinetForm.patchValue({ board_image: file })
      };

      reader.readAsDataURL(file);
    }
  }

  setSecondSiwtch() {
    this.cabinetForm.patchValue({
      number_of_secondary_switches: this.currentSecondSiwtch,
      secondary_switch: []
    })
    let i = 0;
    while (i < this.currentSecondSiwtch) {
      this.cabinetForm.get('secondary_switch').value.push(this.cabinetForm.value.secondary_switch[i]);
      i++;
    }
  }

  setContractor() {
    this.cabinetForm.patchValue({
      number_of_contactors: this.currentContractor,
      contactors: []
    })
    let i = 0;
    while (i < this.currentContractor) {
      this.cabinetForm.get('contactors').value.push(this.cabinetForm.value.contactors[i]);
      i++;
    }
  }

}
