import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Sim } from '@app/models/devices';
import { DevicesService } from '../../devices.service';

@Component({
  selector: 'app-device-sim',
  templateUrl: './device-sim.component.html',
  styleUrls: ['./device-sim.component.scss']
})
export class DeviceSimComponent implements OnInit {

  width = 500;
  widthCylinder = 400;
  height = 400;

  type = 'line';
  dataFormat = 'json';
  dataSource;

  typeCylinder = 'cylinder';
  dataSourceCylinder;

  show = false;
  constructor(
    private deviceService: DevicesService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe(params => {
      if (!params.has('id')) {
        return;
      }

      this.deviceService.getDeviceSimusage(params.get('id')).subscribe(usage => {
        this.simUsage(usage)
      })
    });
  }


  simUsage(statistics) {

    const data = {
      chart: {
        caption: '',
        yaxisname: 'MB',
        rotatelabels: '1',
        setadaptiveymin: '1',
        theme: 'fusion'
      },
      data: statistics.simUsageList.reverse(),
    };
    this.dataSource = data;

    const dataClinder = {
      chart: {
        caption: 'MB Utiliziados',
        lowerlimit: '0',
        upperlimit: statistics.total_volume,
        lowerlimitdisplay: '0 MB',
        upperlimitdisplay: statistics.total_volume + ' MB',
        numbersuffix: ' MB',
        cylfillcolor: '#5D62B5',
        plottooltext: 'MB Utilizados: <b>$value</b>',
        cylfillhoveralpha: '85',
        theme: 'fusion'
      },
      value: statistics.total_volume - statistics.volume
    };
    this.dataSourceCylinder = dataClinder;

    this.show = true;
  }

}
