export class FusionChartModel implements IFusionChartModel {
    chartConfig: ChartConfig;
    dataSource: DataSource;
    constructor(attrs) {
        this.chartConfig = attrs.chartConfig;
        this.dataSource = attrs.dataSource;
    }
}

export interface IFusionChartModel {
    chartConfig: ChartConfig,
    dataSource: DataSource
}

export class DataSource {
    dataSet: Dataset[];
    chart: Chart;
    categories: Categories[];
}


export class ChartConfig implements IChartConfig {
    dataFormat: string;
    width: string;
    height: string;
    type: string;

}

export interface IChartConfig {
    dataFormat: string,
    width: string,
    height: string,
    type: string

}


export class Chart implements IChart {
    caption: string;
    subCaption: string;
    xAxisname: string;
    yAxisName: string;
    numberPrefix: string;
    numVisiblePlot: number;
    scrollheight: number;
    drawcrossline: number;
    plottooltext: string;
    scrollShowButtons: number;
    scrollColor: string;
    theme: string;
}

export interface IChart {
    caption: string,
    subCaption: string,
    xAxisname: string,
    yAxisName: string,
    numberPrefix: string,
    numVisiblePlot: number,
    scrollheight: number,
    drawcrossline: number,
    plottooltext: string,
    scrollShowButtons: number,
    scrollColor: string,
    theme: string
}




export class Categories implements ICategories {
    category: Category[];
    dataSet: Dataset;
}

export interface ICategories {
    category: Category[],
    dataSet: Dataset
}


export class Category {
    label: string;
}


export interface ICategory {
    label: string;
}

export class Dataset {
    seriesName: string;
    showValues: number;
    renderAs: string;
    data: DataDataSet[];
}

export interface IDataset {
    seriesName: string,
    showValues: number,
    renderAs: string,
    data: DataDataSet[]
}


export class DataDataSet implements IDataDataSet {
    value: string;
}


export interface IDataDataSet {
    value: string
}

