import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Trooper } from 'app/models/trooper';
import { User } from 'app/models/user';
import { NewQuickTaskService } from '../new-quick-task/new-quick-task.service';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { DeleteMenu } from 'app/shared/components/map-delete-node.component';
import { LuminaireProjectWattage, LamppostProjectWattage, CircuitProjectWattage } from 'app/models/project_wattage';
import { CurrentProjectService } from 'app/shared/cookies/current-project.service';
import { AssignPowerService } from 'app/pages/powers/assign-power/assign-power.service';
import { Router } from '@angular/router';
import { MapFuncAuxService } from 'app/shared/components/map-func-aux';
import { StyleMap } from 'app/models/styleMap';
import * as MomentTz from 'moment-timezone';
import { NewInstallationTaskPayload } from 'app/models/new-installation-task-payload';
import { NewInstallationTaskService } from './new-installation-task.service';
import { takeUntil, filter, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LuminairesInstallation, LamppostsInstallation, CircuitsInstallation } from '@app/models/installation-markers';
import { ElementsSuggestion, AvailableWattage, MaterialSuggested, MaterialsAvailable, DevicesAvailable } from '@app/models/instllations-suggestions';
import { element } from 'protractor';
import { timeStamp } from 'console';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-new-installation-task',
  templateUrl: './new-installation-task.component.html',
  styleUrls: ['./new-installation-task.component.scss'],
  providers: [MapFuncAuxService, NewQuickTaskService]
})
export class NewInstallationTaskComponent implements OnInit, OnDestroy {
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('contentAssingMaterial') assingMaterial: any;

  installationTaskForm: FormGroup = this.formBuilder.group({
    sector: [null, Validators.required],
    deadline: [null],
    formattedDeadline: [null],
    troop: [null],
  });
  troopSupervisors: User[] = [];
  troopers: Trooper[] = [];
  materialsArray: MaterialSuggestion[] = [];
  materialPerElement: ELementhMaterials[] = []
  selectedMaterial;
  materialOfElement: MaterialSuggestion[] = [];
  currentMaterialId;
  currentQuantity;

  elementsWithMaterial: ElementsSuggestion[] = [];
  powerWattages: AvailableWattage[] = [];
  materialsResume: MaterialsResume[] = [];
  seletedElement: ElementsSuggestion;
  currentMaterials: MaterialsAvailable[] = [];
  currentDevices: DevicesAvailable[] = [];
  materialForQuantity: MaterialSuggested;
  selectedDevices: DevicesSelected[] = [];

  map: google.maps.Map;
  luminaires: LuminairesInstallation[] = [];
  lampposts: LamppostsInstallation[] = [];
  circuits: CircuitsInstallation[] = [];
  lstInfowindows = [];
  polygons: any[] = [];
  currentUrl = '/u/tasks/new-installation-task';
  styleMap: StyleMap = new StyleMap();
  project_max_page = 0;
  disabledBtn = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentZoomMap = 0;
  showSmartDevices = false;

  constructor(
    private toastr: Toastr,
    private router: Router,
    private formBuilder: FormBuilder,
    private mapFuncAux: MapFuncAuxService,
    private assignPowerService: AssignPowerService,
    private newQuickTaskService: NewQuickTaskService,
    private currentProjectService: CurrentProjectService,
    private newInstallationTaskService: NewInstallationTaskService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.getTroopSupervisors();
    this.paintMap();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private getTroopSupervisors(): void {
    this.newQuickTaskService
      .getTroopers()
      .subscribe(
        (getTroopSupervisorsResponse: Trooper[]) => {
          this.troopers = getTroopSupervisorsResponse.filter(x => x.is_active == true);
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  private paintMap() {
    const project = this.currentProjectService.getCurrentProject();
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    });

    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: {
        editable: true,
        draggable: false,
        fillOpacity: 0.25
      }
    });

    google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
      const deleteMenu = new DeleteMenu();
      const polygonPath = polygon.getPath();


      this.addPointToForm(polygon);

      google.maps.event.addListener(polygon, 'rightclick', function (e) {
        // Check if click was on a vertex control point
        if (e.vertex === undefined) {
          return;
        }

        deleteMenu.open(this.map, polygon.getPath(), e.vertex);
      });

      google.maps.event.addListener(polygonPath, 'set_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'insert_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'remove_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'removeVertex', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygon, 'dragend', (_) => {
        this.addPointToForm(polygon);
      })
    });

    this.map.addListener('zoom_changed', () => this.changeZoom());
    this.map.addListener('dragend', () => this.changeZoom());

    drawingManager.setMap(this.map);
    this.map.addListener('click', () => {
      this.hideCircuit();
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    });
  }

  changeZoom() {
    this.currentZoomMap = this.map.getZoom();
    if (this.map.getZoom() > 15) {
      this.getProjectResources(this.map.getCenter().lat(), this.map.getCenter().lng());
    } else {
      this.circuits.forEach(circuit => {
        circuit.hideMarker()
        circuit.lampposts.forEach(lamppost => {
          lamppost.luminaires.forEach(luminaire => {
            luminaire.hideMarker();
          })
        })
      });

      this.lampposts.forEach(lamppost => {
        lamppost.luminaires.forEach(luminaire => {
          luminaire.hideMarker();
        })
      });

      this.luminaires.forEach(luminaire => {
        luminaire.hideMarker();
      })
    }
  }

  private getProjectResources(latitude: number, longitude: number) {
    this.newInstallationTaskService.getMarkers(latitude, longitude).subscribe(response => {

      const luminaires = response['luminaires'];
      const singleLumns = luminaires.filter(x => isNullOrUndefined(x.circuit_id)).map(luminaire => new LuminairesInstallation(luminaire));
      const assignLums = luminaires.filter(x => !isNullOrUndefined(x.circuit_id)).map(luminaire => new LuminairesInstallation(luminaire, 'linked'));

      singleLumns.forEach(luminaire => {
        if (this.luminaires.filter(a => a.id === luminaire.id).length === 0) {
          this.single_AddLuminaires(luminaire);
          this.luminaires.push(luminaire);
          console.log(this.luminaires.length);
        }
      });

      assignLums.forEach(lum_assing => {
        if (this.luminaires.filter(a => a.id === lum_assing.id).length === 0) {
          this.single_AddLuminaires(lum_assing);
          this.luminaires.push(lum_assing);
          console.log(this.luminaires.length);
        }
      });

      // this.addLuminaires(singleLumns);
      // this.addLuminaires(assignLums);

      // this.luminaires = this.luminaires.concat(singleLumns);
      // this.luminaires = this.luminaires.concat(assignLums);

      // const luminaires = response['luminaires'].map(luminaire => new LuminairesInstallation(luminaire));
      // this.addLuminaires(luminaires)
      // this.luminaires = this.luminaires.concat(luminaires);

      // const lampposts = response['lampposts'].map(lamppost => new LamppostsInstallation(lamppost));
      // this.addlampposts(lampposts)
      // this.lampposts = this.lampposts.concat(lampposts)

      const circuits = response['circuits'].map(circuit => new CircuitsInstallation(circuit));
      this.addCircuits(circuits);
      this.circuits = this.circuits.concat(circuits);

      this.toastr.success('Carga terminada.');
    }, error => {
      this.toastr.error('Error al obtener resultados');
    });
  }


  private addCircuits(circuits: CircuitsInstallation[]): void {
    for (const circuit of circuits) {
      circuit.paintMarker(this.map);
      circuit.marker.addListener('click', () => {
        this.showCircuit(circuit);
        this.showInfoCircuit(circuit);
      });


      // for (const lamppost of circuit.lampposts) {
      //   for (const luminaire of lamppost.luminaires) {
      //     luminaire.paintMarker(this.map);
      //     luminaire.marker.addListener('click', () => {
      //       this.hideCircuit();
      //       this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
      //       if (this.luminaireIsSelected(luminaire)) {
      //         luminaire.isSelected = false;
      //         luminaire.updateIcon('normal');
      //         this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'remove', 0)

      //       } else {
      //         luminaire.isSelected = true;
      //         luminaire.updateIcon('selected');
      //         this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'add', 0)
      //       }
      //     });
      //     luminaire.marker.addListener('mouseover', () => {
      //       lamppost.upadeIcon('hover');
      //     });
      //     luminaire.marker.addListener('mouseout', () => {
      //       lamppost.upadeIcon(this.getEvent(luminaire, circuit));
      //     });
      //   }
      // }
    }
  }

  private getEvent(luminaire: LuminairesInstallation, circuit: CircuitsInstallation) {
    return luminaire.event === 'circuitShowing' && luminaire.isSelected ? 'selected'
      : circuit.event === 'circuitShowing' ? 'circuitShowing' : 'normal';
  }

  private addlampposts(lampposts: LamppostsInstallation[]): void {
    for (const lamppost of lampposts) {
      this.paintLamppost(lamppost);
    }
  }

  private paintLamppost(lamppost: LamppostsInstallation) {
    for (const luminaire of lamppost.luminaires) {
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.hideCircuit();
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        if (this.luminaireIsSelected(luminaire)) {
          luminaire.isSelected = false;
          luminaire.updateIcon('normal');
          this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'remove', 0)

        } else {
          luminaire.isSelected = true;
          luminaire.updateIcon('selected');
          this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'add', 0)
        }
      });
      luminaire.marker.addListener('mouseover', () => {
        lamppost.upadeIcon('hover');
      });
      luminaire.marker.addListener('mouseout', () => {
        lamppost.upadeIcon('normal');
      });
    }
  }


  private single_AddLuminaires(luminaire: LuminairesInstallation) {
    luminaire.paintMarker_(this.map);
    luminaire.marker.addListener('click', () => {
      this.hideCircuit();
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
      if (this.luminaireIsSelected(luminaire)) {
        luminaire.isSelected = false;
        luminaire.updateIcon_('normal');
        this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'remove', 0)

      } else {
        luminaire.isSelected = true;
        luminaire.updateIcon_('selected');
        this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'add', 0)
      }
    });
  }

  private addLuminaires(luminaires: LuminairesInstallation[]): void {

    for (const luminaire of luminaires) {
      luminaire.paintMarker_(this.map);
      luminaire.marker.addListener('click', () => {
        this.hideCircuit();
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        if (this.luminaireIsSelected(luminaire)) {
          luminaire.isSelected = false;
          luminaire.updateIcon_('normal');
          this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'remove', 0)

        } else {
          luminaire.isSelected = true;
          luminaire.updateIcon_('selected');
          this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'add', 0)
        }
      });
    }
  }

  private luminaireIsSelected(luminaire: LuminairesInstallation) {
    return luminaire.event === 'selected' || luminaire.isSelected;
  }

  private showCircuit(circuit: CircuitsInstallation): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();
    circuit.upadeIcon('circuitShowing');
  }

  private showInfoCircuit(circuit: CircuitsInstallation) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);

    const strInfo = this.getStringCircuitOptions(circuit);
    const infowindow = new google.maps.InfoWindow({ content: strInfo });

    infowindow.open(this.map, circuit.marker);

    window.setTimeout(() => {

      const btnCircuit = document.getElementById('circuittUnir');
      const btnCircuitWithLum = document.getElementById('circuittUnirWithLun');

      btnCircuitWithLum.addEventListener('click', () => {
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        this.hideCircuit();
        const luminaires = this.luminaires.filter(x => x.circuit_id === circuit.id);

        if (this.haveAllLumSelectd(circuit)) {

          circuit.desSelectedAllLum(luminaires);
          luminaires.forEach(luminaire => {
            this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'remove', 0)
          });

        } else {

          circuit.selectedALLLum(luminaires);
          luminaires.forEach(luminaire => {
            this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'add', 0)
          })

        }

      });

      btnCircuit.addEventListener('click', () => {
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        this.hideCircuit();
        if (circuit.isSelected) {
          circuit.desSelected();
          this.suggestMaterial(circuit.id, circuit.identifier, 0, 'remove', 1)

        } else {
          circuit.selected(),
            this.suggestMaterial(circuit.id, circuit.identifier, 0, 'add', 1)
        }
      });
    }, 500);
    this.lstInfowindows.push(infowindow);
  }

  private haveAllLumSelectd(circuit: CircuitsInstallation) {
    let flag = true;

    const luminaires = this.luminaires.filter(x => x.circuit_id === circuit.id);

    for (const luminaire of luminaires) {
      if (!this.luminaireIsSelected(luminaire)) {
        flag = false;
        return flag;
      }
    }

    return flag;
  }

  private getStringCircuitOptions(circuit: CircuitsInstallation) {
    return `
    <div>
      <div class="col-12">
      ${circuit.isSelected ?
        `
        <input id="circuittUnir" type="button" class="oe--button--gray--popover mr-1" value="Deseleccionar medidor">
        `
        : '<input id="circuittUnir" type="button" class="oe--button--gray--popover mr-1" value="Seleccionar medidor">'}
        ${this.haveAllLumSelectd(circuit) ?
        `
          <input id="circuittUnirWithLun" type="button" class="oe--button--gray--popover mr-1" value="Deseleccionar luminarias">
          `
        :
        `
          <input id="circuittUnirWithLun" type="button" class="oe--button--gray--popover mr-1" value="Seleccionar luminarias">
          `
      }
      </div>
    </div>
    `;
  }

  private addPointToForm(polygon: any) {
    if (this.isExistingPolygon(polygon)) {
      this.replacePolygon(polygon)
    } else {
      this.polygons.push(polygon);
      this.updatePolygon(polygon);
      google.maps.event.addListener(polygon, 'click', (_) => {
        this.hideCircuit();
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
      });
    }
  }

  private replacePolygon(polygon: any): void {
    const polygons = this.polygons;
    const existingPolygonIndex = polygons.findIndex(_polygon => _polygon.zIndex === polygon.zIndex);

    polygons[existingPolygonIndex] = polygon;
    this.updatePolygon(polygon);
  }

  private isExistingPolygon(polygon: any): boolean {
    const polygons = this.polygons;
    const existingPolygon = polygons.find(_polygon => _polygon.zIndex === polygon.zIndex);

    return existingPolygon ? true : false;
  }

  updatePolygon(polygon: any) {
    this.isWithinLuminaires(this.luminaires, polygon);
    for (const lamppost of this.lampposts) {
      this.isWithinLuminaires(lamppost.luminaires, polygon);
    }
    for (const circuit of this.circuits) {
      if (this.isWithin(polygon, circuit.marker)) {
        if (!circuit.isSelected) {
          circuit.isSelected = true;
          circuit.upadeIcon('selected', false);
          circuit.polygonID = polygon['zIndex'];
          this.suggestMaterial(circuit.id, circuit.identifier, 0, 'add', 1)
        }
      }
      for (const lamppost of circuit.lampposts) {
        this.isWithinLuminaires(lamppost.luminaires, polygon);
      }
    }
    this.clearCircuitsOfProject(polygon);
    this.clearLuminairesOfProject(polygon);
  }

  isWithinLuminaires(luminaires: LuminairesInstallation[], polygon: any) {
    for (const luminaire of luminaires) {
      if (this.isWithin(polygon, luminaire.marker)) {
        if (!luminaire.isSelected) {
          luminaire.isSelected = true;
          luminaire.updateIcon('selected');
          luminaire.polygonID = polygon['zIndex'];
          this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'add', 0)
        }
      }
    }
  }

  isWithin(polygon: any, marker: google.maps.Marker) {
    return google.maps.geometry.poly.containsLocation(marker.getPosition(), polygon);
  }

  clearCircuitsOfProject(polygon: any) {
    for (const circuit of this.circuits) {
      if (circuit.polygonID >= 0 && circuit.polygonID === polygon['zIndex']) {
        if (!this.isWithin(polygon, circuit.marker)) {
          circuit.isSelected = false;
          circuit.upadeIcon('normal');
          this.suggestMaterial(circuit.id, circuit.identifier, 0, 'remove', 1)

        }
      }
    }
  }

  clearLuminairesOfProject(polygon: any) {
    this.clearLuminaires(this.luminaires, polygon);
    for (const lamppost of this.lampposts) {
      this.clearLuminaires(lamppost.luminaires, polygon);
    }
    for (const circuit of this.circuits) {
      for (const lamppost of circuit.lampposts) {
        this.clearLuminaires(lamppost.luminaires, polygon);
      }
    }
  }

  clearLuminaires(luminaires: LuminairesInstallation[], polygon: any) {
    for (const luminaire of luminaires) {
      if (luminaire.polygonID >= 0 && luminaire.polygonID === polygon['zIndex']) {
        if (!this.isWithin(polygon, luminaire.marker)) {
          luminaire.isSelected = false;
          luminaire.updateIcon('normal');
          this.suggestMaterial(luminaire.id, luminaire.reference_id, 1, 'remove', 0)

        }
      }
    }
  }

  private hideCircuit(): void {
    const _circuit = this.circuits.find(__circuit => __circuit.event === 'circuitShowing');
    if (_circuit) {
      const event = _circuit.isSelected ? 'selected' : 'normal';
      if (event === 'selected') {
        _circuit.upadeIcon(event, false);

      } else { _circuit.upadeIcon('normal', false) }
      for (const lamppost of _circuit.lampposts) {
        lamppost.upadeIcon('normal');
      }
    }
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  public SaveInfrastructureTask() {

    if (this.installationTaskForm.invalid) {
      this.toastr.singleError('Llene los campos correspondientes')
      return;
    }

    // tslint:disable-next-line:max-line-length

    let status = false;
    this.elementsWithMaterial.filter(x => x.element_type == "Circuit").forEach(circuit => {
      let smartCircuit = circuit.materialSuggested.filter(x => x.materials_category == 'Circuito Smart');

      if (smartCircuit.length > 0) {
        if (smartCircuit[0].quantity != 0 && circuit.device_id == null) {
          this.toastr.singleError('Asigne un dispositivo a todos los circuitos');
          status = true;
        }
      }
    });

    if (status) {
      return;
    }

    const formDate = this.installationTaskForm.get('deadline').value;
    const date = MomentTz(`${formDate.year}-${formDate.month}-${formDate.day}`).format();
    this.installationTaskForm.patchValue({ formattedDeadline: date });
    const form = {
      sector: this.installationTaskForm.get('sector').value,
      deadline: date,
      user_id: this.installationTaskForm.get('troop').value,
      installInfo: this.elementsWithMaterial
    }
    this.newInstallationTaskService.createInstallationTasks(form).subscribe(response => {
      this.router.navigateByUrl('/u/installations');
      this.toastr.success('Tarea creada')
    });
  }



  getSelectedLams(lampposts: LamppostsInstallation[]) {
    let luminairesSelected: LuminairesInstallation[] = [];
    for (const lamppost of lampposts) {
      const _lums = lamppost.luminaires.filter(_lum => _lum.event === 'selected' || _lum.isSelected);
      luminairesSelected = luminairesSelected.concat(_lums);
    }
    return luminairesSelected;
  }

  getSelectionLumTotal() {
    const luminairesSelected = this.getSelectionLum();
    return luminairesSelected.length;
  }

  getSelectionLum(withoutCircuitSelected?: boolean) {
    let luminairesSelected: LuminairesInstallation[] = [];
    luminairesSelected = this.luminaires.filter(_lum => _lum.event === 'selected' || _lum.isSelected);
    let _lums = this.getSelectedLams(this.lampposts);
    luminairesSelected = luminairesSelected.concat(_lums);
    for (const circuit of this.circuits) {
      _lums = this.getSelectedLams(circuit.lampposts);
      luminairesSelected = luminairesSelected.concat(_lums);
    }
    return luminairesSelected;
  }

  private suggestMaterial(id, reference, option, action, type) {

    this.newInstallationTaskService.getMaterialsToAssing(id, option).subscribe(response => {
      if (action === 'add') {
        if (this.elementsWithMaterial.filter(find => find.element_id === id).length === 0) {

          this.elementsWithMaterial.push(response.elements_suggestions)
          this.elementsWithMaterial.sort((a, b) => (+a.element_reference > +b.element_reference) ? 1 : -1);
          if (type == 1) {
            if (this.validateCircuitsDevices(this.elementsWithMaterial.filter(x => x.element_type == 'Circuit').length, response.elements_suggestions.materialSuggested.filter(x => x.materials_category == 'Circuito Smart')[0].devices_available.length)) {
              // this.toastr.singleError('Se necesitan crear más dispositivos Smart')
              // this.circuits.filter(x => x.id == id)[0].desSelected();
              // this.elementsWithMaterial = this.elementsWithMaterial.filter(
              //   element_id => element_id.element_id !== response.elements_suggestions.element_id
              // );
              // return;
            }
          }


          this.getMaterialsResume(response.elements_suggestions.materialSuggested, action);

        }
      } else {
        this.elementsWithMaterial = this.elementsWithMaterial.filter(
          element_id => element_id.element_id !== response.elements_suggestions.element_id
        );
        this.getMaterialsResume(response.elements_suggestions.materialSuggested, action);

      }
      this.powerWattages = response.available_wattages

    });
  }
  filterRef(value: ElementsSuggestion) {
    this.circuits.forEach(cir => {
      if (value.element_type == "Circuit" && value.element_id == cir.id) {
        cir.isSelected = false;
        cir.upadeIcon('normal');
      }

      cir.lampposts.forEach(lam => {

        lam.luminaires.forEach(lum => {
          if (value.element_type == "Luminaire" && value.element_id == lum.id) {
            lum.isSelected = false;
            lum.updateIcon('normal');
          }
        });
      });
    });

    let index = this.elementsWithMaterial.findIndex(x => x.element_id == value.element_id);

    this.elementsWithMaterial.splice(index, 1);

    value.materialSuggested.forEach(ele => {
      //   this.materialsResume.find(x => x.material_name === ele.materials_category).quantity -= ele.quantity;

      if ((this.materialsResume.filter(current_material => current_material.material_name === ele.materials_category)[0].quantity - ele.quantity) === 0) {
        this.materialsResume = this.materialsResume.filter(current_material => current_material.material_name !== ele.materials_category)
      } else {
        // tslint:disable-next-line:max-line-length
        this.materialsResume.filter(current_material => current_material.material_name === ele.materials_category)[0].quantity -= ele.quantity;
      }

      //   let MatIndex = this.materialsResume.findIndex(x=>x.quantity == 0);
      //   this.materialsResume.splice(MatIndex, 1);
    });

  }

  validateCircuitsDevices(amount_circuits, devices) {

    if (amount_circuits === 0) {
      return false;
    }
    return devices < amount_circuits;
  }

  getMaterialsResume(materials: MaterialSuggested[], option: string) {
    materials.forEach(material => {
      if (option === 'add') {
        if (this.materialsResume.filter(current_material => current_material.material_name === material.materials_category).length == 0) {
          this.materialsResume.push({ material_name: material.materials_category, quantity: material.quantity })
        } else {
          // tslint:disable-next-line:max-line-length
          this.materialsResume.filter(current_material => current_material.material_name === material.materials_category)[0].quantity += material.quantity;
        }
      } else {
        // tslint:disable-next-line:max-line-length
        if ((this.materialsResume.filter(current_material => current_material.material_name === material.materials_category)[0].quantity - material.quantity) === 0) {
          this.materialsResume = this.materialsResume.filter(current_material => current_material.material_name !== material.materials_category)
        } else {
          // tslint:disable-next-line:max-line-length
          this.materialsResume.filter(current_material => current_material.material_name === material.materials_category)[0].quantity -= material.quantity;
        }
      }
    })
  }

  onAssingMaterial() {
    if (this.elementsWithMaterial.length === 0) {
      this.toastr.singleError('Seleciona una luminaria o circuito.');
      return;
    }

    this.modalService.open(this.assingMaterial, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
      result => {
      },
      reason => {
        this.currentMaterials = [];
        this.currentDevices = [];
        this.materialForQuantity = null
      });

  }

  onSelectElement(event) {
    this.currentMaterials = [];
    this.currentDevices = [];
    this.materialForQuantity = null;
    this.seletedElement = this.elementsWithMaterial.filter(current => current.element_id == event.srcElement.value)[0];
  }

  onSelectMaterialCategory(event, element_id) {
    // tslint:disable-next-line:max-line-length
    this.currentDevices = [];
    this.currentMaterials = this.seletedElement.materialSuggested.filter(current => current.materials_category == event.srcElement.value)[0].materials_available;
    this.materialForQuantity = this.seletedElement.materialSuggested.filter(current => current.materials_category == event.srcElement.value)[0]

    if (event.srcElement.value === 'Circuito Smart') {
      // tslint:disable-next-line:max-line-length
      this.currentDevices = this.seletedElement.materialSuggested.filter(current => current.materials_category == event.srcElement.value)[0].devices_available.filter(device => !this.selectedDevices.some(_device => _device.device_id === device.devie_id));
      this.currentDevices = this.currentDevices.concat(this.seletedElement.materialSuggested.filter(current => current.materials_category == event.srcElement.value)[0].devices_available.filter(device => this.selectedDevices.some(_device => _device.device_id === device.devie_id && _device.element_id === element_id)));
    }
  }

  onSelectDevice(event, element_selected: ElementsSuggestion) {
    const device_id = event.srcElement.value;

    if (this.selectedDevices.length > 0) {
      if (this.selectedDevices.filter(x => x.device_id == device_id && x.element_id != element_selected.element_id).length > 0) {
        this.toastr.singleError('Este dispositivo ya esta asignado a otro circuito');
        return;
      } else if (this.selectedDevices.filter(x => x.element_id == element_selected.element_id).length > 0) {
        this.selectedDevices.filter(x => x.element_id == element_selected.element_id)[0].device_id = device_id;
        element_selected.device_id = device_id;
        return;
      }
    }

    this.selectedDevices.push({ device_id: device_id, element_id: element_selected.element_id });
    element_selected.device_id = device_id;

  }

  deleteDevice(element_selected: ElementsSuggestion) {
    this.selectedDevices = this.selectedDevices.filter(x => x.device_id != element_selected.device_id);
    element_selected.device_id = null;
  }

  assingMaterialToElement(material: MaterialsToAssing) {
    this.currentMaterials.forEach(materials => {
      materials.selected = false;
    });

    material.selected = true;
  }

  changeQuantity(event) {
    // tslint:disable-next-line:radix
    this.materialForQuantity.quantity = parseInt(event.srcElement.value);
  }
}


export class MaterialSuggestion {
  material: string;
  quantity: number;

  constructor(attrs) {
    this.material = attrs.material;
    this.quantity = attrs.quantity;
  }
}

export class MaterialsToAssing {
  id: string;
  name: string;
  image: string;
  selected: boolean;

  constructor(attrs) {
    this.id = attrs.id;
    this.image = attrs.image;
    this.name = attrs.name;
    this.selected = false
  }
}

export class MaterialsAssigned {
  material: string;
  quantity: number;
  material_name: string;

  constructor(attrs) {
    this.material = attrs.material;
    this.quantity = attrs.quantity;
    this.material_name = attrs.material.name;
  }
}

export class ELementhMaterials {
  element_id: string;
  element_reference: string;
  element_type: number;
  materials: MaterialSuggestion[];
  materialsAssing: MaterialsAssigned[];


  constructor(attrs) {
    this.element_id = attrs.element_id;
    this.element_reference = attrs.element_reference;
    this.materialsAssing = [];
    this.materials = attrs.materials;

  }
}

export class MaterialsResume { material_name: string; quantity: number }

export class DevicesSelected { device_id: string; element_id: string };
