import { Component, OnInit } from '@angular/core';
import { InstallationsService } from './installation.service';
import { InstallationTable } from '@app/models/installation-table';
import { GenericTable } from '@app/models/generic-table';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-installations',
  templateUrl: './installations.component.html',
  styleUrls: ['./installations.component.scss']
})
export class InstallationsComponent implements OnInit {

  instalationTable: GenericTable<InstallationTable>;
  task_reference = '';
  is_Finished = '';
  selectedTasks = [];
  instalation: InstallationTable[] = [];

  constructor(
    private installationService: InstallationsService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.hide();
    this.getElementsPaginated(0);
  }

  getElementsPaginated(page: number) {
    this.installationService.getInstallationTasks(page, this.task_reference, this.is_Finished).subscribe(response => {
      let listWithSelect = [];
      this.instalationTable = response;
      if (this.selectedTasks.length == 0) {
        this.instalation = response.elements;
      }
      else {
        response.elements.forEach(ele => {
          if (this.selectedTasks.find(x => x == ele.task_id)) {
            ele.selected = true;
          }
          listWithSelect.push(ele)
        });
        this.instalation = listWithSelect;
      }

    });
  }

  pageChange(page: number) {
    this.getElementsPaginated(page - 1);
  }

  selectTask(task: InstallationTable) {
    task.selected = !task.selected;
  }

  checkTask(task: InstallationTable) {
    task.selected = !task.selected;
    if (task.selected == true)
      this.selectedTasks.push(task.task_id);
    if (task.selected == false) {

      this.selectedTasks = this.selectedTasks.filter(x => x != task.task_id);
    }
  }
  onSearch() {
    this.installationService.getInstallationTasks(0, this.task_reference, this.is_Finished).subscribe(response => {
      this.instalation = response.elements
    });
  }

  //  Create a click
  //  Create an array ok task ids


  printSelected(printable_type: number) {
    this.spinner.show('gen-formats')

    const tasks = this.instalation.filter(task => task.selected === true);
    const taks_id = this.selectedTasks;

    // tasks.forEach(task => {
    //   taks_id.push(task.task_id);
    // })

    if (taks_id.length === 0) {
      this.toaster.error('Eliga al menos una tarea de instalación');
      return;
    }

    const form = { task_id: taks_id, printable_type: printable_type };
    console.log(form)
    this.installationService.downloadInstallaionFormats(form);
  }
}
