import { Component, OnInit } from '@angular/core';
import { KpiTaskService } from '../kpi-task.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { InfraestructureSurveyKpis } from './infrastructure-kpis';

@Component({
  selector: 'app-kpi-infrastructure-task',
  templateUrl: './kpi-infrastructure-task.component.html'
})
export class KpiInfrastructureTaskComponent implements OnInit {
  txtTitle: String = 'Infraestructura';
  infraestructureSurveyKpis: InfraestructureSurveyKpis;
  dataUsercounts: number[] = [];
  chartOptions = {
    responsive: true
  };
  chartData = [
    {
      data: this.dataUsercounts,
      borderWidth: 1
    }
  ];
  chartColors: any[] = [
    {
      backgroundColor:["#FF7360", "#6FC8CE", "#FAFFF2", "#FFFCC4", "#B9E8E0"]
    }
  ];
  chartLabels = [];

  constructor(
    private kpiTaskService: KpiTaskService,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.kpiTaskService.getKpisInfraestructureSurvey()
    .subscribe( (response: InfraestructureSurveyKpis) => {
      this.infraestructureSurveyKpis = response;
      for(let user of this.infraestructureSurveyKpis.user_kpis){
        this.chartLabels.push(user.fullName);
        this.dataUsercounts.push(user.finished_infrastructure_survey_count);
      }
    }, error => this.toastr.error(error));
  }

}
