import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { Pagination } from 'app/models/pagination';
import { TroopsService } from './troops.service';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GenericTable } from '@app/models/generic-table';
import { Troop } from './troop';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-troops',
  templateUrl: './troops.component.html',
  styleUrls: ['./troops.component.scss'],
  providers: [TroopsService]
})
export class TroopsComponent implements OnInit {

  troopsSearchForm: FormGroup;
  troops: GenericTable<Troop>
  showAddButtons = true;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private troopsServise: TroopsService,
    private toastr: Toastr,
    private authService: AuthService
  ) {this.troopsSearchForm = fb.group({
    number: "",
    ipad_serial_number: "",
    plates: "",
  }); }

  ngOnInit() {
    this.getTroops(0);
    this.setButtonVisibility();
  }

  setButtonVisibility() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    console.log(decodeToken["role_name_enums"])
    if (decodeToken["role_name_enums"][0] !== 0) {
      this.showAddButtons = false;
    }
  }

  getTroops(page:number): void{
    this.troopsServise.getTroops(page, this.troopsSearchForm.getRawValue()).subscribe( response => {
        this.troops = response;
    }, error => {
      this.toastr.error(error);
    });
  }

  pageChange(page: number) {
    this.getTroops(page - 1);
  }

}
