import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, Form } from '@angular/forms';
import { EditCircuitService } from '../edit-circuit.service';
import { Toastr } from '@shared/toastr/toastr.service';
import { Circuit } from '@models/circuit';
import { MountingTypeEnum  } from '@models/mounting_type-enum';
import { EditMeterPayload } from '../edit-meter-payload';

@Component({
  selector: 'app-edit-meter',
  templateUrl: './edit-meter.component.html'
})
export class EditMeterComponent implements OnInit {
  @Input() circuit: Circuit;
  mountingTypeEnum = Object.keys(MountingTypeEnum).filter(key => !isNaN(Number(MountingTypeEnum[key])));
  
  image: any;
  base_image: any;
  meterForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private editCircuitService: EditCircuitService,
    private toastr: Toastr,
  ) { }

  ngOnInit() {
   this.meterForm = this.formBuilder.group({
      id: [null],
      is_present: [null],
      identifier: [null],
      is_functional: [null],
      has_base: [null],
      base_is_functional: [null],
      mounting_type: [null],
      image_url: [null],
      image: [null],
      base_image_url: [null],
      base_image: [null],
      location_latitude: [null],
      location_longitude: [null]
    });
    this.populateForm(this.circuit.meter);
  }

  private populateForm(meter){
    this.meterForm.patchValue({
      id: meter.id,
      is_present: meter.is_present,
      identifier: meter.identifier,
      is_functional: meter.is_functional,
      has_base: meter.has_base,
      base_is_functional: meter.base_is_functional,
      mounting_type: meter.mounting_type,
      image_url: meter.image_url,
      base_image_url: meter.base_image_url,
      location_latitude: meter.location.latitude,
      location_longitude: meter.location.longitude,
      image: meter.image,
      base_image: meter.base_image,
    });
  }

  performFormAction() {
   const formData: FormData = new FormData();
if(this.meterForm.value.mounting_type != null){
  formData.append('mounting_type', this.meterForm.value.mounting_type);
}
    formData.append('id', this.meterForm.value.id);
    formData.append('identifier', this.meterForm.value.identifier);
    formData.append('has_base', this.meterForm.value.has_base ? 'true' : 'false');
    formData.append('base_is_functional', this.meterForm.value.base_is_functional ? 'true' : 'false');
    formData.append('is_functional', this.meterForm.value.is_functional ? 'true' : 'false');
    formData.append('is_present', this.meterForm.value.is_present ? 'true' : 'false');
    formData.append('image', this.image);
    formData.append('base_image', this.base_image);

    this.editCircuitService.editMeterNew(formData).subscribe(
      response => {
        this.toastr.success('Se Actualizo Correctamente.');
      });
    }

  // performFormAction():void {
  //   if (this.meterForm.valid) {
  //     const requestPayload = new EditMeterPayload(this.meterForm);
  //     this.editCircuitService
  //       .editMeter(this.circuit, requestPayload)
  //       .subscribe(
  //         () => {
  //           this.toastr.success('Medidor actualizado.');
  //         },
  //         (error) => {
  //           this.toastr.error(error);
  //         }
  //       )
  //   } else {
  //     this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
  //   }
  // }


  setImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.image = event.target.files[0];
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.meterForm.patchValue({ image_url: reader.result })
        this.meterForm.patchValue({ image: file })
      };

      reader.readAsDataURL(file);
    }
  }

  setBaseImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.base_image = event.target.files[0];
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.meterForm.patchValue({ base_image_url: reader.result })
        this.meterForm.patchValue({ base_image: file })
      };

      reader.readAsDataURL(file);
    }
  }

}
