export interface IMaterialLogs {
    username: string;
    log_id: string;
    material_name: string;
    quantity: number;
    removed_from: number;
    cap?: string;
    created_at: Date;
    reference_id: string;
    user_id: string;
}

export class MaterialLogs implements IMaterialLogs {
    username: string;
    log_id: string;
    material_name: string;
    user_id: string;
    quantity: number;
    removed_from: number;
    cap?: string;
    created_at: Date;
    reference_id: string;
    place: string;

    constructor(attrs) {
        this.username = attrs.username;
        this.log_id = attrs.log_id;
        this.material_name = attrs.material_name;
        this.quantity = attrs.quantity;
        this.removed_from = attrs.removed_from;
        this.cap = attrs.cap;
        this.created_at = new Date(attrs.created_at);
        this.reference_id = attrs.reference_id;
        this.user_id = attrs.user_id;
        this.place = this.removed_from === 0 ? 'Almacen' : (this.removed_from === 1 ? `${this.cap} - Inventario` : ( this.removed_from === 4 ? `${this.cap} - Recuperado` : 'Material Recuperado'))
    }
}
