import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router"
import { } from 'googlemaps';
import { Task } from '@models/task';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Luminaire } from '@models/luminaire';
import { Toastr } from '@shared/toastr/toastr.service'
import { TaskService } from '@pages/tasks/task/task.service';
import * as Driver from 'driver.js';
import { LuminaireEnum } from '@models/luminaire-enum';
import { TechnologyEnum } from '@models/technology-enum';
import { LuminairePayload } from '@models/luminaire-payload';
import { LuminaireResponse } from '@models/luminaire-response';
import { MapFuncAuxService } from '@shared/components/map-func-aux';
import { Lamppost } from '@models/lamppost';
import { StyleMap } from '@models/styleMap';
import { Trooper } from '@models/trooper';
import * as MomentTz from 'moment-timezone';
import { GeneralViewService } from '@pages/general-view/general-view.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-quick-survey',
  templateUrl: './quick-survey.component.html',
  styleUrls: ['./quick-survey.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TaskService, MapFuncAuxService]
})
export class QuickSurveyComponent implements OnInit {
  @Input() task: Task;
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('contentDelete') deleteElement: any;
  @ViewChild('contentCreteLuminaire') createElement: any;
  map: google.maps.Map;
  quickTaskForm: FormGroup = this.fromBuilder.group({
    id: [null, Validators.required],
    reference_id: [null, Validators.required],
    type: [null, Validators.required],
    municipality: [null, Validators.required],
    sector: [null, Validators.required],
    created_at: [null, Validators.required],
    deadline: [null],
    user: [null],
    last_interaction_at: [null, Validators.required],
    troop: [null],
    coment: [null],
    formattedDeadline: [null]
  });
  styleMap: StyleMap = new StyleMap();
  lstInfowindows: any[] = [];
  markers = [];
  selectedMarkes = [];
  editMode: string = '';
  driver: Driver = new Driver({
    animate: true,
    allowClose: false,
    keyboardControl: false
  });
  luminaireForm: FormGroup = this.fromBuilder.group(
    {
      type: [null, Validators.required],
      technology: [null, Validators.required],
      has_photocell: [null, Validators.required],
      comment: [null],
      latitude: [{ value: null, disabled: true }, Validators.required],
      longitude: [{ value: null, disabled: true }, Validators.required]
    }
  );
  luminaireEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));
  survey_areas = [];
  polygons = [];
  troopers: Trooper[] = [];
  showAddButtons = true;

  constructor(
    private generalViewService: GeneralViewService,
    private mapFuncAux: MapFuncAuxService,
    private fromBuilder: FormBuilder,
    private taskService: TaskService,
    private modalService: NgbModal,
    private toastr: Toastr,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.setButtonVisibility();
    this.getTroopSupervisors();
    this.poblarForm();
    this.paintMap();
    this.addLuminaires();
    this.addlampposts();
    this.addPolygon();
    this.addPolygonInGeneralOfTask();
  }

  setButtonVisibility() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    console.log(decodeToken["role_name_enums"])
    if (decodeToken["role_name_enums"][0] === 8 || decodeToken["role_name_enums"][0] === 5 ) {
      this.showAddButtons = false;
    }
  }

  private getTroopSupervisors(): void {
    this.taskService
      .getTroopers()
      .subscribe(
        (getTroopSupervisorsResponse: Trooper[]) => {
          this.troopers = getTroopSupervisorsResponse.filter(x => x.is_active == true);
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  poblarForm() {
    let dateDeadline = new Date(this.task.deadline);
    let dCreated = new Date(this.task.created_at);
    let ngbCreated: NgbDate = new NgbDate(dCreated.getFullYear(), (dCreated.getMonth() + 1), dCreated.getDate());
    let ngbDeadline = null;
    if (this.task.deadline) {
      let dateDeadline = new Date(this.task.deadline);
      ngbDeadline = new NgbDate(dateDeadline.getFullYear(), (dateDeadline.getMonth() + 1), dateDeadline.getDate());
    }

    this.quickTaskForm = this.fromBuilder.group({
      reference_id: [{ value: this.task.reference_id, disabled: true }, Validators.required],
      type: [{ value: this.task.type.name, disabled: true }, Validators.required],
      municipality: [{ value: this.task.municipality, disabled: true }, Validators.required],
      sector: [{ value: this.task.sector, disabled: true }, Validators.required],
      created_at: [{ value: ngbCreated, disabled: true }, Validators.required],
      deadline: [{ value: ngbDeadline, disabled: !this.showAddButtons }],
      user: [{ value: this.task.user ? this.task.user.id : null, disabled: !this.showAddButtons }],
      last_interaction_at: [{ value: null, disabled: true }, Validators.required],
      troop: [{ value: this.task.user ? this.task.user.troops.map(troop => troop.number).join(', ') : '', disabled: true }, Validators.required],
      comment: [{ value: this.task.comment, disabled: !this.showAddButtons }],
      formattedDeadline: [null]
    });

    if (this.task.last_interaction_at) {
      let dLast = new Date(this.task.last_interaction_at);
      let ngbLast: NgbDate = new NgbDate(dLast.getFullYear(), (dLast.getMonth() + 1), dLast.getDate());
      this.quickTaskForm.patchValue({ last_interaction_at: ngbLast });
    }
    if (this.task.user) {
      this.quickTaskForm.patchValue({
        user: this.task.user.id
      });
    }
    this.quickTaskForm
      .get('user')
      .valueChanges
      .subscribe((val) => {
        this.userChanged(val);
      });
  }

  private paintMap(): void {
    let latitude = this.task.centroid.latitude;
    let longitude = this.task.centroid.longitude;
    let zoom = 15;
    if (!longitude) {
      latitude = 19.4326009;
      longitude = -99.1333416;
      zoom = 5;
    }
    let mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    this.map.addListener('click', () => this.closeAllInfowindows());
    this.map.addListener('rightclick', (event) => this.createLuminaire(event));
  }

  private addLuminaires(): void {
    for (let luminaire of this.task.luminaires) {
      this.createLuminaireMarker(luminaire);
    }
  }

  private createLuminaireMarker(luminaire: Luminaire) {
    luminaire.paintMarker(this.map);
    luminaire.marker.addListener('click', () => {
      this.clickMarkerLuminaire(luminaire)
    });
    google.maps.event.addListener(luminaire.marker, 'dragend', () => { this.updateLuminairePosition(luminaire) });
    this.markers.push(luminaire.marker);
  }

  private addlampposts(): void {
    for (let lamppost of this.task.lampposts) {
      this.addLamppost(lamppost);
    }
  }

  private addLamppost(lamppost: Lamppost) {
    for (let luminaire of lamppost.luminaires) {
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.clickMarkerLamppost(luminaire, lamppost)
      });
      luminaire.marker.addListener('mouseover', () => {
        lamppost.upadeIcon('hover');
      });
      luminaire.marker.addListener('mouseout', () => {
        lamppost.upadeIcon('normal');
      });
      google.maps.event.addListener(luminaire.marker, 'dragend', () => { this.updateLuminairePosition(luminaire) });
      this.markers.push(luminaire.marker);
    }
  }

  private addPolygon() {
    let polygonCoords = [];
    for (let survey_area of this.task.survey_areas) {
      polygonCoords = survey_area.points.map(point => new google.maps.LatLng(point.latitude, point.longitude));
      let myPolygon = new google.maps.Polygon({ paths: polygonCoords, fillOpacity: 0.25 });
      myPolygon.addListener('rightclick', (event) => this.createLuminaire(event));
      myPolygon.setMap(this.map);
      polygonCoords = [];
    }
  }

  setBtnPoligonosClasses() {
    let classes = {
      'btn-primary': this.polygons.length ? this.polygons[0].getMap() : false,
      'oe--btn--text-white': this.polygons.length ? this.polygons[0].getMap() : false,
      'oe--btn--border': this.polygons.length ? !this.polygons[0].getMap() : true
    }
    return classes
  }

  createLuminaire(event) {
    if (this.editMode == '' || this.editMode == 'join luminaire')
      return

    let position = event.latLng;
    this.luminaireForm = this.fromBuilder.group(
      {
        type: [null, Validators.required],
        technology: [null, Validators.required],
        has_photocell: [false, Validators.required],
        comment: [''],
        latitude: [{ value: position.lat(), disabled: true }, Validators.required],
        longitude: [{ value: position.lng(), disabled: true }, Validators.required]
      }
    );
    this.modalService.open(this.createElement).result.then(result => { }, reason => {
      if (reason == 'Form valid') {
        const requestPayload = new LuminairePayload();
        requestPayload.populateAngularForm(this.luminaireForm);

        this.taskService
          .createLuminaire(requestPayload, this.task)
          .subscribe(
            (luminaireResponse: LuminaireResponse) => {
              this.toastr.success('Luminaria creada.');
              let luminaire = new Luminaire(luminaireResponse.luminaire);
              this.createLuminaireMarker(luminaire);
              luminaire.marker.setDraggable(true);
              this.task.luminaires.push(luminaire);
            },
            (error: any) => {
              this.toastr.error(error);
            }
          );
      }
    });
  }
  modalCreate() {
    if (this.luminaireForm.valid) {
      this.modalService.dismissAll('Form valid')
    } else {
      this.luminaireForm.controls.type.markAsTouched();
      this.luminaireForm.controls.technology.markAsTouched();
      this.luminaireForm.controls.has_photocell.markAsTouched();
    }
  }

  clickEdit(content) {
    switch (this.editMode) {
      case 'join luminaire':
        this.modalService.open(content).result.then(result => {
          this.cancelEdition();
          this.editMode = '';
          this.removeDragg();
          this.driver.reset();
        }, reason => { });
        break;
      case '':
        this.editMode = 'true';
        this.addDragg();
        this.driver.highlight('#mapLuminaires');
        this.closeAllInfowindows();
        setTimeout(() => {
          let element = document.getElementById('driver-highlighted-element-stage');
          element.scrollIntoView(false);
          element.scrollIntoView({ block: "center" });
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }, 0);
        break;
      default:
        this.cancelEdition();
        this.editMode = '';
        this.removeDragg();
        this.driver.reset();
    }
  }

  clickCreateCenso() {
    this.router.navigate([`/u/tasks/new-infrastructure-task/${this.task.id}`]);
    return false;
  }

  changePolyg() {
    if (this.polygons.length && this.polygons[0].getMap()) {
      this.setMaptoPolygon(null);
    } else {
      this.setMaptoPolygon(this.map);
    }
  }

  changeStatusTask(value: boolean) {
    let comment = this.quickTaskForm.get('comment').value;
    this.taskService
      .updateTask(this.task, comment, value)
      .subscribe(response => {
        this.toastr.success('Tarea actualizada.');
      },
        error => {
          this.toastr.error(error);
        });
  }
  private updateLuminairePosition(luminaire: Luminaire) {
    luminaire.location.latitude = luminaire.marker['position'].lat();
    luminaire.location.longitude = luminaire.marker['position'].lng();
    this.taskService
      .updatePositonLuminarie(luminaire)
      .subscribe(response => {
        this.toastr.success('Coordenadas actualizadas.');
      }, error => {
        this.toastr.error(error);
      });
  }
  private addDragg() {
    for (let marker of this.markers) {
      marker.setDraggable(true);
    }
  }
  private removeDragg() {
    for (let marker of this.markers) {
      marker.setDraggable(false);
    }
  }

  private searchLamppost(marker: any): Lamppost {
    let luminaires: Luminaire[];
    let luminaire: any;
    let lamppost: Lamppost;
    for (let post of this.task.lampposts) {
      luminaires = post.luminaires.map(lum => marker.getTitle() == lum.id ? lum : null);
      luminaire = luminaires.filter(lum => lum != null);
      if (luminaire.length != 0) {
        lamppost = post;
        break;
      }
    }
    return lamppost;
  }

  private setMapOnAll(map) {
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }

  clickMarkerLuminaire(luminaire: Luminaire) {
    this.closeAllInfowindows();

    if (this.editMode == '')
      return this.showInfoWindow(luminaire);

    if (this.editMode == 'true')
      return this.showUnionWindow(luminaire);

    if (this.editMode == 'join luminaire')
      return this.clickSelectedLuminaire(luminaire);

  }

  clickMarkerLamppost(luminaire: Luminaire, lamppost?: Lamppost) {
    this.closeAllInfowindows();

    if (this.editMode == '')
      return this.showInfoWindow(luminaire, lamppost);

    if (this.editMode == 'true')
      return this.showDeleteLammpost(luminaire, lamppost);

  }

  private isPost(luminaire: Luminaire): boolean {
    let luminaires: Luminaire[] = this.task.luminaires.map(lum => luminaire.id == lum.id ? lum : null);
    let lum: any = luminaires.filter(lum2 => lum2 != null);
    return !lum.length
  }

  private showInfoWindow(luminaire: Luminaire, lamppost?: Lamppost) {
    this.generalViewService.getLuminaire(luminaire.id)
      .subscribe((_luminaire: Luminaire) => {
        luminaire.reference_id = _luminaire.reference_id;
        luminaire.technology = _luminaire.technology;
        luminaire.type = _luminaire.type;
        luminaire.has_photocell = _luminaire.has_photocell;
        luminaire.comment = _luminaire.comment;
        let strInfo = this.mapFuncAux.getStringInfo(luminaire, lamppost);
        let infowindow = new google.maps.InfoWindow({
          content: strInfo,
          maxWidth: 600
        });
        infowindow.open(this.map, luminaire.marker);
        this.lstInfowindows.push(infowindow);
        window.setTimeout(() => {
          let btnEditLum = document.getElementById('luminaireEdit');
          let seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
          btnEditLum.addEventListener('click', () => this.clickEditLum(luminaire));
          seeMoreLuminaire.addEventListener('click', () => this.mapFuncAux.seeMoreLuminaire(luminaire.id));
        }, 500);
      }, error => {
        this.toastr.error(error);
      })
  }

  private showUnionWindow(luminaire: Luminaire) {
    let contentString = this.getStringSelected(luminaire);
    let infowindow = new google.maps.InfoWindow({
      content: contentString,
      maxWidth: 600
    });
    infowindow.open(this.map, luminaire.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      let btnAddComent = document.getElementById('luminaireUnir');
      let btnDeleteLuminaire = document.getElementById('luminaireDelete');
      btnAddComent.addEventListener('click', () => this.clickSelectedLuminaire(luminaire));
      btnDeleteLuminaire.addEventListener('click', () => this.showModaldeleteLuminaire(luminaire));
    }, 500);
  }

  private showDeleteLammpost(luminaire: Luminaire, lamppost?: Lamppost) {
    let contentString = this.getStringDesUnir(luminaire);
    let infowindow = new google.maps.InfoWindow({
      content: contentString,
      maxWidth: 400
    });
    infowindow.open(this.map, luminaire.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      let btnAddComent = document.getElementById('luminaireDes');
      let btnDeleteLuminaire = document.getElementById('luminaireDelete');
      btnAddComent.addEventListener('click', () => this.deletLamppost(lamppost));
      btnDeleteLuminaire.addEventListener('click', () => this.showModaldeleteLuminaire(luminaire));
    }, 500);
  }

  private addPolygonInGeneralOfTask() {
    this.taskService.getSuveryAreas().subscribe(surveyAreaResponse => {
      this.survey_areas = surveyAreaResponse.survey_areas;
      let polygonCoords = [];
      for (let survey_area of this.survey_areas) {
        if (this.task.survey_areas.find(element => element['id'] == survey_area.id))
          continue;
        polygonCoords = survey_area.points.map(point => new google.maps.LatLng(point.latitude, point.longitude));
        let myPolygon = new google.maps.Polygon({
          paths: polygonCoords,
          strokeColor: '#4885E6',
          strokeOpacity: 0.8,
          fillColor: '#4885E1',
          fillOpacity: 0.25
        });
        myPolygon.addListener('rightclick', (event) => this.createLuminaire(event));
        this.polygons.push(myPolygon);
        polygonCoords = [];
      }
      this.setMaptoPolygon(this.map);
    });
  }

  setMaptoPolygon(map: any): void {
    for (let i = 0; i < this.polygons.length; i++) {
      this.polygons[i].setMap(map);
    }
  }

  updateTask() {
    const comment = this.quickTaskForm.get('comment').value;
    let user = this.quickTaskForm.get('user').value;

    if (this.quickTaskForm.get("deadline").value) {
      const formDate = this.quickTaskForm.get("deadline").value;
      const date = MomentTz(`${formDate.year}-${formDate.month}-${formDate.day}`).format();
      this.quickTaskForm.patchValue({ formattedDeadline: date });
    }
    const deadline = this.quickTaskForm.get('formattedDeadline').value;
    if (typeof (user) == 'object') {
      user = user.value;
    }

    this.taskService
      .updateTask(this.task, comment, null, user, deadline)
      .subscribe(response => {
        this.toastr.success('Tarea actualizada.');
      },
        error => {
          this.toastr.error(error);
        });
  }

  closeAllInfowindows() {
    let btnAddComent = document.getElementById('luminaireComment');
    if (btnAddComent)
      btnAddComent.removeEventListener('click', () => { })
    for (let infowindow of this.lstInfowindows) {
      infowindow.close();
    }
  }

  clickEditLum(luminaire: Luminaire) {
    const requestPayload = new LuminairePayload();
    requestPayload.populateJavascriptForm(luminaire);

    this.taskService.editLuminaire(requestPayload).subscribe(response => {
      luminaire.comment = requestPayload.comment;
      this.toastr.success('Luminaria actualizada.');
    },
      error => {
        this.toastr.error(error);
      });
  }
  clickSelectedLuminaire(luminaire: Luminaire) {
    this.closeAllInfowindows();
    luminaire.upadeIcon('selected')
    this.editMode = 'join luminaire'
    google.maps.event.clearInstanceListeners(luminaire.marker);
  }

  showModaldeleteLuminaire(luminaire: Luminaire) {
    this.modalService.open(this.deleteElement).result.then(result => {

      this.taskService.deleteLuminaire(luminaire).subscribe(response => {
        luminaire.hideMarker();
        this.toastr.success('Luminaria eliminada.');
      }, error => {
        this.toastr.error(error);
      });
    }, reason => { });
  }

  saveEdition() {
    this.closeAllInfowindows();

    if (this.editMode == 'join luminaire')
      this.saveLumMarks();
  }

  cancelEdition() {
    this.closeAllInfowindows();

    if (this.editMode == 'join luminaire') {
      for (let luminaire of this.task.luminaires) {
        if (luminaire.event == 'selected') {
          luminaire.upadeIcon('normal');
          luminaire.marker.addListener('click', () => {
            this.clickMarkerLuminaire(luminaire)
          });
          google.maps.event.addListener(luminaire.marker, 'dragend', () => { this.updateLuminairePosition(luminaire) });
        }
      }
    }
    this.editMode = 'true';
  }

  private saveLumMarks() {
    let luminaires = this.task.luminaires.filter(luminaire => luminaire.event == 'selected');
    this.taskService.createLampposts(luminaires, this.task)
      .subscribe(response => {
        this.toastr.success('Poste creado.');
        let lamppost = new Lamppost(response['lamppost']);
        this.task.lampposts.push(lamppost);
        for (let luminaire of luminaires) {
          luminaire.hideMarker();
        }
        this.addLamppost(lamppost);
        this.task.luminaires = this.task.luminaires.filter(luminaire => luminaire.event != 'selected');
        this.editMode = 'true';
      }, error => {
        this.toastr.error(error);
      });
  }

  private deletLamppost(lamppost: Lamppost) {
    this.taskService.deleteLampposts(lamppost)
      .subscribe(response => {
        this.toastr.success('Poste eliminado.');
        for (let luminaire of lamppost.luminaires) {
          google.maps.event.clearListeners(luminaire.marker, 'click');
          google.maps.event.clearListeners(luminaire.marker, 'mouseover');
          google.maps.event.clearListeners(luminaire.marker, 'mouseout');
          this.task.luminaires.push(luminaire);
          luminaire.linkedSituation = 'single';
          luminaire.upadeIcon('normal');
          luminaire.marker.addListener('click', () => {
            this.clickMarkerLuminaire(luminaire)
          });
        }
        this.task.lampposts = this.task.lampposts.filter(lamp => lamp.id != lamppost.id);
        this.closeAllInfowindows();
        this.editMode = 'true';
      }, error => {
        this.toastr.error(error);
      });
  }

  private getStringSelected(luminaire: Luminaire): string {
    return `
    <div>
      <div class="col-12">
        <input id="luminaireUnir" type="button" class="oe--button--secondary--popover mr-1" value="Unir Poste">
        <input id="luminaireDelete" type="button" class="oe--button--danger--popover" value="Eliminar Luminaria">
      </div>
    </div>
    `;
  }

  private getStringDesUnir(luminaire: Luminaire): string {
    return `
    <div>
      <div class="col-12">
        <input id="luminaireDes" type="button" class="oe--button--gray--popover mr-1" value="Separar Poste">
        <input id="luminaireDelete" type="button" class="oe--button--danger--popover" value="Eliminar Luminaria">
      </div>
    </div>
    `;
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  private userChanged(val: any) {
    let user = this.troopers.find(troop => troop.id == val);
    this.quickTaskForm.patchValue({
      troop: user.troops.map(troop => troop.number).join(', ')
    });

  }

}
