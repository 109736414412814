import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Requisition } from '@models/requisition';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toastr } from '@shared/toastr/toastr.service';
import { InventoryService } from '@pages/inventory/inventory.service';
import { RequisitionService } from '../requisition.service';

@Component({
  selector: 'app-sent',
  templateUrl: './sent.component.html'
})
export class SentComponent implements OnInit {
  @ViewChild('contentDelete') deleteElement: any;
  requisitions: Requisition[];

  constructor(
    private requisitionService: RequisitionService,
    private inventoryService: InventoryService,
    private modalService: NgbModal,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.requisitionService.getRequisitionsSend()
      .subscribe((rq:Requisition[]) => {
        this.requisitions = rq;
      }, error => {
        this.toastr.error(error);
      })
  }

  showModaldeleteSolicitud(requisition: Requisition){
    this.modalService.open(this.deleteElement).result.then(result => {
      this.inventoryService.updateRequisition(requisition, 3).subscribe(response => {
        this.toastr.success('Solicitud eliminada.');
        this.requisitions = this.requisitions.filter( req => req.id != requisition.id);
        window.location.reload();
      }, error => {
        this.toastr.error(error);
      });
    }, reason => {});
  }

}
