import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";

interface UserInterface {
  id: string
  last_name: string
  name: string
  role_name_enums: number[]
  token: string
  user_image_url: string
  email: string
}

@Injectable()
export class AuthService {
  constructor(
    private cookieService: CookieService,
    private router: Router,
    private httpClient: HttpClient
  ) {}

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

  signinUser(jwt: string) {
    this.setToken(jwt);

    if(this.isAuthenticated()) {
      this.router.navigate(['u']);
    }
  }

  logout() {
    this.httpClient.delete(`${environment.SERVER_URL}/sessions`);

    this.cookieService.remove(`${environment.SESSION_COOKIE_KEY}`);
    this.cookieService.remove(`${environment.CURRENT_PROJECT_COOKIE}`);

    this.router.navigate(['/login']);
  }

  getToken() {
    return this.cookieService.get(`${environment.SESSION_COOKIE_KEY}`) || '';
  }

  isAuthenticated() {
    return this.getToken() ? true : false;
  }

  private setToken(jwt: string) {
    this.cookieService.put(`${environment.SESSION_COOKIE_KEY}`, jwt);
  }

  getUser(): UserInterface{
    const helper = new JwtHelperService();
    let token = this.getToken();
    let user:UserInterface = helper.decodeToken(token);
    
    return user;
  }

  isClient(): boolean{
    const helper = new JwtHelperService();
    let token = this.getToken();
    let user:UserInterface = helper.decodeToken(token);
    if(user.role_name_enums.includes(7)){
      return true; 
    } else {
      return false;
    }
  }
}
