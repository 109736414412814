import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { DashboardEfficiency } from '@app/models/dashboard-efficiency';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserEfficiencyService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getMainDashboard() {
    return this.httpClient.get<DashboardEfficiency>(
      `${environment.SERVER_CALLCENTER}/api/TicketsStatistics/GetMainDashboard`
    ).pipe(
      map(response => new DashboardEfficiency(response))
    );
  }
}
