import { Component, OnInit } from '@angular/core';
import { SmartAlertInfo, SmartAlertListDetails } from '@app/models/smart-alerts';
import { DevicesService } from '../../devices/devices.service';
import { ActivatedRoute } from '@angular/router';
import { SmartAlertsService } from '../smart-alerts.service';

@Component({
  selector: 'app-smart-alerts-details',
  templateUrl: './smart-alerts-details.component.html',
  styleUrls: ['./smart-alerts-details.component.scss']
})
export class SmartAlertsDetailsComponent implements OnInit {

  alertDetails: SmartAlertInfo;
  alertList: SmartAlertListDetails[] = [];
  deviceID: string;

  constructor(private alertsService: SmartAlertsService,
    private activatedRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.deviceID = this.activatedRoute.snapshot.paramMap.get('id');

    this.alertsService.getAlertDetailsList(this.deviceID).subscribe(res  => {

      this.alertDetails = res;
      this.alertList =  res.alarmDetailsList.map(x=> new SmartAlertListDetails(x));

     })
  }

}
