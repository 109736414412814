export interface IExtrackedMaterial {
    material: string,
    quantity: string,
    etradaOsalida: string,
    date: Date
}

export class ExtrackedMaterial implements IExtrackedMaterial {

    material: string;
    quantity: string;
    etradaOsalida: string;
    date: Date;

    constructor(attrs: any) {
        this.material = attrs.material;
        this.quantity = attrs.quantity;
        this.etradaOsalida = attrs.etradaOsalida;
        this.date = new Date(attrs.date);
    }


}
