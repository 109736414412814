import { User } from "./user";

export interface QuickSurveyKpisInterface {
    not_troopers_luminaires_count: number;
    luminaire_kpis: Luminaire_kpis;
    user_kpis: User[];
}

export class QuickSurveyKpis implements QuickSurveyKpisInterface {
    not_troopers_luminaires_count: number;
    luminaire_kpis: Luminaire_kpis;
    user_kpis: User[];

    constructor(attrs: any) {
        this.not_troopers_luminaires_count = attrs.not_troopers_luminaires_count;
        this.luminaire_kpis = new Luminaire_kpis(attrs.luminaire_kpis);
        this.user_kpis = attrs.user_kpis.map( user => new User(user));
    }
}

class Photocell_info {
    has_photocell_count: number;
    no_photocell_count: number;

    constructor(attrs: any) {
        this.has_photocell_count = attrs.has_photocell_count;
        this.no_photocell_count = attrs.no_photocell_count;
    }
}

class Technologies {
    fluorescent_count: number;
    hid_count: number;
    led_count: number;
    magnetic_induction_count: number;
    other_technology_count: number;

    constructor(attrs: any) {
        this.fluorescent_count = attrs.fluorescent_count;
        this.hid_count = attrs.hid_count;
        this.led_count = attrs.led_count;
        this.magnetic_induction_count = attrs.magnetic_induction_count;
        this.other_technology_count = attrs.other_technology_count;
    }
}

class Types {
    ball_count: number;
    cobra_head_count: number;
    lantern_count: number;
    other_types_count: number;
    suburban_count: number;
    xxi_century_count: number;
    reflector_count: number;

    constructor(attrs: any) {
        this.ball_count = attrs.ball_count;
        this.cobra_head_count = attrs.cobra_head_count;
        this.lantern_count = attrs.lantern_count;
        this.other_types_count = attrs.other_types_count;
        this.suburban_count = attrs.suburban_count;
        this.xxi_century_count = attrs.xxi_century_count;
        this.reflector_count = attrs.reflector_count || 0;
    }
}

class Luminaire_kpis {
    photocell_info: Photocell_info;
    technologies: Technologies;
    total_luminaire_count: number;
    types: Types;

    constructor(attrs: any) {
        this.photocell_info = new Photocell_info(attrs.photocell_info);
        this.technologies = new Technologies(attrs.technologies);
        this.total_luminaire_count = attrs.total_luminaire_count;
        this.types = new Types(attrs.types);
    }
}


export class LuminairesInstallation_kpis{

    allInstallations: number;
    finishedInstallations: number;
    installationPercentage: number;
    luminairesByWattages: LuminairesByWattage[];

    constructor(attrs: any) {
        this.allInstallations = attrs.allInstallations;
        this.finishedInstallations = attrs.finishedInstallations;
        this.installationPercentage = attrs.installationPercentage;
        this.luminairesByWattages = attrs.luminairesByWattages;
    }
  }
  
  class LuminairesByWattage {
    power: number;
    optic: string;
    name: string;
    count: number;
  }