import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EditLuminaireService } from './edit-luminaire.service';
import { GetLuminaireResponse } from '@models/get-luminaire-response';
import { Luminaire } from '@models/luminaire';
import { Toastr } from '@shared/toastr/toastr.service';
import { EditLuminairePayload } from './edit-luminaire-payload';
import { TechnologyEnum } from '@models/technology-enum';
import { LuminaireEnum } from '@models/luminaire-enum';
import { Arm } from '@models/arm';
import { Lamppost } from '@models/lamppost';
import { LightTask } from '@models/light-task';
import { LuminaireAll, NewArm, NewLamppost, NewBases } from './luminaire-edit-all';
import { StyleMap } from '@app/models/styleMap';
import { AuthService } from '@shared/auth/auth.service';

@Component({
  selector: 'app-edit-luminaire',
  templateUrl: './edit-luminaire.component.html',
  providers: [EditLuminaireService]
})
export class EditLuminaireComponent implements OnInit {
  technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));
  luminariaTypeEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  markers;
  isClient : Boolean;
  luminaireForm: FormGroup = this.formBuilder.group({
    id: [{ value: null, disabled: true }, Validators.required],
    reference_id: [null],
    type: [null],
    technology: [null],
    has_photocell: [null],
    municipality_status: [{ value: null, disabled: true }, Validators.required],
    comment: [null],
    name: [{value: null, disabled: true}],
    power: [{value: null, disabled: true}]
  });
  luminaireID: string;
  // luminaire: Luminaire;
  arm: NewArm;
  lamppost: NewLamppost;
  base: NewBases;
  lightTasks;
  newLum: LuminaireAll;
  power;
  name;

  constructor(
    private authService: AuthService,
    private toastr: Toastr,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private editLuminaireService: EditLuminaireService
  ) { }

  ngOnInit() {
    this.isClient = this.authService.isClient();
    this.luminaireID = this.activatedRoute.snapshot.paramMap.get('id');
    this.getNewLum(this.luminaireID);
    // this.editLuminaireService
    //   .getLuminaire(this.luminaireID)
    //   .subscribe(
    //     (getLuminaireResponse: GetLuminaireResponse) => {
    //       this.luminaire = getLuminaireResponse.luminaire;
    //       this.arm = this.luminaire['arm'];
    //       this.lamppost = this.luminaire['lamppost'];
    //       this.lightTasks = getLuminaireResponse.luminaire.tasks.map( (lightTask: LightTask) => new LightTask(lightTask) );
    //       this.populateForm();
    //     },
    //     (error) => {
    //       this.toastr.error(error);
    //     }
    //   );
  }


  getNewLum(id) {
    this.editLuminaireService.getNewLuminaire(id).subscribe(response => {
      this.newLum = response;
      this.arm = this.newLum['arm'];
      this.lamppost = this.newLum['lamppost'];
      this.base = this.newLum['bases'];
      this.lightTasks = response.tasks;
      this.markers = response.markers;
      this.populateForm();
      console.log(response, 'numero de tarea')
    })
  }

  private populateForm() {
    const luminaire = this.newLum;
    this.luminaireForm.patchValue({
      id: luminaire.id,
      reference_id: luminaire.reference_id || 'Sin referencia',
      type: luminaire.type || 0,
      technology: luminaire.technology || 0,
      has_photocell: luminaire.has_photocell || false,
      municipality_status: luminaire.municipality_status || '',
      comment: luminaire.comment || '',
      name: luminaire.name,
      power: luminaire.power || 'Sin potencia'
      // location_latitude: luminaire.location.latitude,
      // location_longitude: luminaire.location.longitude
    });

    if(this.isClient){
      this.luminaireForm.get('reference_id').disable();
      this.luminaireForm.get('type').disable();
      this.luminaireForm.get('technology').disable();
      this.luminaireForm.get('has_photocell').disable();
      this.luminaireForm.get('comment').disable();
    }
  }




  performFormAction(): void {
    if (this.luminaireForm.valid) {
      // const requestPayload = new EditLuminairePayload(this.luminaireForm);

      const form = {
        id: this.luminaireID,
        comment: this.luminaireForm.get('comment').value,
        has_photocell: this.luminaireForm.get('has_photocell').value,
        reference_id: this.luminaireForm.get('reference_id').value,
        technology: this.luminaireForm.get('technology').value,
        type: this.luminaireForm.get('type').value
      }
      // this.luminaireForm.removeControl('name');
      // this.luminaireForm.removeControl('power');
      this.editLuminaireService
        .editLuminaire(form)
        .subscribe(
          () => {
            this.toastr.success('Luminaria actualizada.');
          },
          (error) => {
            this.toastr.error(error);
          }
        )

    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  clickTaskBase(task) {
    const taskType: string = task.reference_id;
    let type = 0;
    switch (taskType.split("-")[0]) {
      case 'R':
        type = 4;
        break;
      case 'CR':
        type = 0;
        break;
      case 'CI':
        type = 1;
        break;
      case 'I':
        type = 2;
        break;
      default:
        break;
    }
    this.router.navigate([`/u/tasks/${task.id}/${type}`]);
  }

}
