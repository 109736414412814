import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string): string {

    const lowerCase = value.toLowerCase();
    const firstLetter = lowerCase.substring(0,1).toUpperCase();

    const capitalize = firstLetter + lowerCase.substring(1);
    return capitalize;
  }

}
