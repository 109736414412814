import { Component, OnInit, ViewChild } from '@angular/core';
import { Material } from 'app/models/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InventoryService } from '../inventory.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { MaterialPayload } from 'app/models/material-payload';
import { MaterialsResponse } from 'app/models/materials-response';
import { ProjectMaterialsResponse } from 'app/models/project_material-response';
import { ProjectMaterial } from 'app/models/project_material';
import { MaterialCategoryResponse } from 'app/models/material_category-response';
import { ShoppingCartItemPayload } from 'app/models/shopping_cart_item-payload';
import { ProjectMaterialPayload } from 'app/models/project_material-payload';
import { ChargedValuesService } from 'app/pages/charged-values/charged-values.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@app/shared/auth/auth.service';

@Component({
  selector: 'app-inventory-category',
  templateUrl: './inventory-category.component.html',
  providers: [ChargedValuesService]
})
export class InventoryCategoryComponent implements OnInit {
  @ViewChild('contentMaterialModal') materialModal: any;
  currentId: string;
  project_materials: ProjectMaterial[] = [];
  shoppingForm: FormGroup;
  projectMaterial: FormGroup;
  emptyMaterial: Material;
  totalRef: number = 0;
  showMessage: boolean = false;
  projectName = 'Material';
  showAddButtons = true

  constructor(
    private chargedValuesService: ChargedValuesService,
    private inventoryService: InventoryService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: Toastr,
    private router: Router,
    private authService: AuthService,
  ) {
    this.shoppingForm = this.fb.group(
      {
        id: [null, Validators.required],
        quantity: [null, Validators.required],
        action: ['add', Validators.required],
        stock: [null, Validators.required],
        image_url: [null],
        name: [null],
        minimum: [null]
      }
    );
    this.projectMaterial = this.fb.group(
      {
        id: [null],
        name: [null, Validators.required],
        minimum: [null, Validators.required],
        image_url: [null],
        is_active: [null],
        image: [null],
        stock: [null]
      }
    );
    this.emptyMaterial = new Material({});
  }

  ngOnInit() {

    this.setButtonVisibility();
    this.route.paramMap.subscribe(params => {
      this.currentId = params.get('id');
      this.getCategory();
    });
  }

  setButtonVisibility() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    console.log(decodeToken['role_name_enums'])
    if (decodeToken['role_name_enums'][0] === 2 || decodeToken['role_name_enums'][0] === 1) {
      this.showAddButtons = false;
    }
  }

  getCategory() {
    this.inventoryService.getCategory(this.currentId)
      .subscribe((materialCategoryResponse: MaterialCategoryResponse) => {
        this.project_materials = materialCategoryResponse.material_category.project_materials;
        this.project_materials = this.project_materials.sort((a, b) => a.material.name.localeCompare(b.material.name));
        this.projectName = materialCategoryResponse.material_category.name;
      }, error => {
        this.toastr.error(error);
      })
  }

  showMaterialModal(project_materials: ProjectMaterial) {
    this.setForm(project_materials);
    this.modalService.open(this.materialModal).result.then(result => {
    }, reason => {
      this.showMessage = false;
    });
  }

  updateMaterial() {
    if (this.shoppingForm.valid && this.shoppingForm.get('quantity').value > 0) {
      const requestPayload = new ShoppingCartItemPayload(this.shoppingForm);
      this.inventoryService.updateShoppingCart(requestPayload)
        .subscribe(result => {
          this.toastr.success('Se agregado al carrito.');
          this.modalService.dismissAll();
        }, error => {
          this.toastr.error(error);
        })
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  setForm(project_material: ProjectMaterial) {
    this.shoppingForm.patchValue({
      id: project_material.material.id,
      name: project_material.material.name,
      minimum: project_material.material.minimum,
      stock: project_material.stock,
      quantity: 0,
      image_url: project_material.material.image_url
    });
  }

  editMaterial(project_material: ProjectMaterial) {
    for (let _project_material of this.project_materials) {
      _project_material.material.editing = false;
    }
    this.totalRef = project_material.stock;

    project_material.material.editing = !project_material.material.editing;
  }





  saveTotal(project_material: ProjectMaterial) {
    if (this.totalRef < 0)
      return
    let bk = project_material.stock;

    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    // tslint:disable-next-line:max-line-length
    const form = {
      material_id: project_material.material.id,
      removed_from: 0,
      quantity: this.totalRef - project_material.stock,
      user_id: decodeToken['id'],
      cap_id: null
    };

    project_material.stock = this.totalRef;

    this.inventoryService.registerLog(form).subscribe(response => {
      this.setFormProject(project_material);
      const requestPayload = new ProjectMaterialPayload(this.projectMaterial);
      this.chargedValuesService.updateMaterial(requestPayload, this.currentId)
        .subscribe(result => {
          this.toastr.success('Material actualizado.');
        }, error => {
          this.toastr.error(error);
          project_material.stock = bk;
        });
    });

    project_material.material.editing = false;
  }

  operationToTotal(element: any, sum?: boolean) {
    if (sum) {
      if ((parseInt(element.value) + 10) > parseInt(this.shoppingForm.get('stock').value))
        return this.showMessage = true;
      return this.shoppingForm.patchValue({ quantity: parseInt(element.value) + 10 });
    }
    else if ((parseInt(element.value) - 10) >= 0)
      this.shoppingForm.patchValue({ quantity: parseInt(element.value) - 10 });
    else
      this.shoppingForm.patchValue({ quantity: 0 });
    this.showMessage = false;
  }

  setFormProject(projectMaterial: ProjectMaterial) {
    this.projectMaterial.patchValue({
      id: projectMaterial.id,
      name: projectMaterial.material.name,
      minimum: projectMaterial.troop_minimum,
      is_active: projectMaterial.is_active,
      stock: projectMaterial.stock
    });
  }

  operationToTotalMaterial(sum?: boolean) {
    if (sum) {
      return this.totalRef = this.totalRef + 10;
    }
    else if ((this.totalRef - 10) >= 0)
      this.totalRef = this.totalRef - 10
  }

}
