export interface IGraphStats {
    labels: string[],
    series: [
        {
            label: string,
            data: number[],
            stack: string
        }
    ]
}

export class GraphStats implements IGraphStats {
    labels: string[];
    series: [
        {
            label: string;
            data: number[];
            stack: string;
        }
    ];

    constructor(attrs) {
        this.labels = attrs.labels,
        this.series = attrs.series
    }

}
