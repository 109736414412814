import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Luminaire } from '@models/luminaire';
import { map } from 'rxjs/operators';
import { Circuit } from '@models/circuit';
import { endTimeRange } from '@angular/core/src/profile/wtf_impl';
import { LuminaireNew } from './luminairesNew';
import { ProjectWattage } from '@app/models/project_wattage';

@Injectable({
  providedIn: 'root'
})
export class GeneralViewService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getProjectResources(): Observable<object> {
    // return this.httpClient
    //   .get<object>(`${environment.SERVER_URL}/project_resources`, { params: params });

    return this.httpClient.get<object>(`${environment.SERVER_NETCORE}/api/GeneralView/GetGeneralView`);
  }

  getProjectCircuits(): Observable<object> {
    return this.httpClient.get<object>(`${environment.SERVER_NETCORE}/api/GeneralView/GetGeneralCircuits`);
  }

  getWattages(): Observable<object> {
    return this.httpClient.get<object>(`${environment.SERVER_NETCORE}/api/GeneralView/GetWattages`);
  }

  // getProjectLampposts(): Observable<object> {
  //   return this.httpClient.get<object>(`${environment.SERVER_NETCORE}/api/GeneralView/GetGeneralLampposts`);
  // }

  getProjectLuminaires(status: any[]): Observable<object> {
    const httpParams = new HttpParams()
    .append('status', status.join(','));
    return this.httpClient.get<object>(`${environment.SERVER_NETCORE}/api/GeneralView/GetLuminairesStatus`, {params: httpParams});
  }

  getLumWattages(status: any[] , wattArray: any[]): Observable<object> {
    const httpParams = new HttpParams()
    .append('status', status.join(','))
    .append('wattArray', wattArray.join(','))

    return this.httpClient.get<object>(`${environment.SERVER_NETCORE}/api/GeneralView/GetLumWattages`, {params: httpParams});
  }


  getAllLuminaires(): Observable<object> {
    return this.httpClient.get<object>(`${environment.SERVER_NETCORE}/api/GeneralView/GetAllLuminaires`);
  }

  // getLuminaireNew(luminaireID: string): Observable<object> {
  //   const httpParams = new HttpParams()
  //   .set('id', luminaireID)
  //   return this.httpClient.get<object>(`${environment.SERVER_NETCORE}/api/GeneralView/GetLuminaire`, {params: httpParams});
  // }

  editLuminaire(luminaire): Observable<any> {
    return this.httpClient.post(
      `${environment.SERVER_NETCORE}/api/GeneralView/UpdateLuminaire`, luminaire
    );
  }

  editCircuit(circuit): Observable<any> {
    return this.httpClient.post(
      `${environment.SERVER_NETCORE}/api/GeneralView/UpdateCircuit`, circuit
    );
  }
  getLuminaire(luminaireID: string): Observable<Luminaire> {
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/luminaires/${luminaireID}`)
      .pipe( map( data => new Luminaire(data.luminaire) ) );
  }

  getCircuit(circuitID: string): Observable<Circuit> {
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/circuits/${circuitID}`)
      .pipe( map( data => new Circuit(data.circuit) ) );
  }

  getStatistics():Observable<object> {
    return null;
     this.httpClient
      .get<object>(`${environment.SERVER_URL}/kpis/project`);
  }
}
