import { Injectable } from '@angular/core';
import { GenericTable } from '@app/models/generic-table';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DataGuarantyOpinion, GuarantyOpinion } from './guaranty-opinion-model';
import { environment } from '@environments/environment';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class GuarantyOpinionService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getListGuarantyOpinions(filters, page) {
    const httpParams = new HttpParams().set('page', page);
    return this.httpClient.post<GenericTable<GuarantyOpinion>>(
      `${environment.SERVER_TASKS}/api/GuarantyOpinion/get-list-guaranty-opinions`, filters, { params: httpParams }
    );
  }

  getGuareantyOpinion(guaranty_report_id) {
    const httpParams = new HttpParams().set('guaranty_report_id', guaranty_report_id);
    return this.httpClient.get<GuarantyOpinion>(
      `${environment.SERVER_TASKS}/api/GuarantyOpinion/get-guaranty-opinion`, { params: httpParams }
    );
  }


  getGuareantyOpinionDetail(guaranty_report_id) {
    const httpParams = new HttpParams().set('guaranty_report_id', guaranty_report_id);
    return this.httpClient.get<DataGuarantyOpinion>(
      `${environment.SERVER_TASKS}/api/GuarantyOpinion/get-detail-guaranty-opinion`, { params: httpParams }
    );
  }


  registerguarantyopinion(insert: FormData) {
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/GuarantyOpinion/register-guaranty-opinion`, insert);
  }
  updateguarantyopinion(update: FormData) {
    return this.httpClient.put(`${environment.SERVER_TASKS}/api/GuarantyOpinion/update-guaranty-opinion`, update);
  }


  downloadPDFGuarantyOpinion(report_ids) {
    this.httpClient.post(
      `${environment.SERVER_TASKS}/api/GuarantyClaimsOpinionReport/get-opinion-reports-luminaire-photcell`, report_ids,
      { responseType: 'blob', }
    ).subscribe(file => {
      this.downloadProcess(file, `Reporte-Opinions.pdf`);
    }, error => {
      console.log(error);
    });
  }

  private downloadProcess(file, fileName) {
    const newBlob = new Blob([file], { type: 'application/pdf' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }


}
