import { FormGroup } from '@angular/forms';

export class Luminaire implements ILuminaire {
    id: string

    constructor(attrs) {
        this.id = attrs.id;
    }

}

export interface ILuminaire {
    id: string
}

export class ReportLuminairePayload {
    userID: string;
    address: string;
    disclaimer_was_accepted: boolean;
    problem: number;
    luminaires: Luminaire[];
    specification: string;
    dial_code: string;
    source: string;
    responsible_party: string;
    whatsapp_disclaimer_was_accepted: boolean;
    comment: string;
    email: string;
    name: string;
    first_last_name: string;
    second_last_name: string;
    phone_number: string;


    constructor(reportForm: FormGroup, userID: string) {
        console.log(reportForm.value);
        this.userID = userID;
        this.address = reportForm.get('address').value;
        this.disclaimer_was_accepted = reportForm.get('disclaimer_was_accepted').value;
        this.whatsapp_disclaimer_was_accepted = reportForm.get('whatsapp_disclaimer_was_accepted').value;
        this.problem = reportForm.get('problem').value;
        this.luminaires = reportForm.get('luminaires').value;
        this.specification = reportForm.get('specification').value;
        this.dial_code = reportForm.get('dial_code').value;
        this.source = reportForm.get('source').value;
        this.responsible_party = reportForm.get('responsibleParty').value;
        this.comment = reportForm.get('comment').value;
        this.email = reportForm.get('email').value;
        this.name = reportForm.get('name').value;
        this.first_last_name = reportForm.get('first_last_name').value;
        this.second_last_name = reportForm.get('second_last_name').value;
        this.phone_number = reportForm.get('phone_number').value ? `${this.dial_code}${reportForm.get('phone_number').value}` : null;

    }

}


export class ReportLuminaireTicket implements IReportLuminaireTicket {
    reference: string;
    luminaires_status: number;

    constructor(attrs) {
        this.reference = attrs.reference;
        this.luminaires_status = attrs.luminaires_status;
    }

}

export interface IReportLuminaireTicket {
    reference: string,
    luminaires_status: number
}


