import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from 'app/models/pagination';
import { Ticket } from 'app/models/ticket';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tickets-table',
  templateUrl: './tickets-table.component.html'
})
export class TicketsTableComponent implements OnInit {
  @Input() tickets: Ticket[];
  @Input() pagination: Pagination;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() sort_byChange: EventEmitter<string> = new EventEmitter<string>();
  sort_by: string;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(queries => {
      this.sort_by = queries.get('sort_by') ? queries.get('sort_by') : 'created_at.desc';
    })
  }

  changeCurrentPage(page) {
    this.pageChange.emit(page);
  }

  changeSort_by() {
    this.sort_byChange.emit(null);
  }

}
