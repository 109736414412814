import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LuminaireProjectWattage, CircuitProjectWattage } from 'app/models/project_wattage';
import { Pagination } from '@app/models/pagination';

@Component({
  selector: 'app-luminaires-pending-table',
  templateUrl: './luminaires-pending-table.component.html'
})
export class LuminairesPendingTableComponent implements OnInit {
  @Output() changePage:  EventEmitter<any> = new EventEmitter<any>();
  @Input() luminaires: LuminaireProjectWattage[];
  @Input() circuits: CircuitProjectWattage[];
  @Input() pagination: Pagination;
  @Input() showSpinner: Boolean;

  constructor() { }

  ngOnInit() {
  }

  changeCurrentPage(page: number){
    this.changePage.emit(page);
  }

}
