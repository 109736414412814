import { PrimarySwichtCurrentCapacityEnum } from "./primary_swicht_current_capacity-enum";

interface PrimarySwitchInterface {
    id: string,
    is_present: boolean,
    current_capacity: number,
    current_capacity_name: string,
    is_functional: boolean,
}

export class PrimarySwitch implements PrimarySwitchInterface {
    id: string;
    is_present: boolean;
    current_capacity: number;
    current_capacity_name: string;
    is_functional: boolean;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.is_present = attrs.is_present ? true : false;
    this.current_capacity = attrs.current_capacity;
    this.current_capacity_name = PrimarySwichtCurrentCapacityEnum[attrs.current_capacity];
    this.is_functional = attrs.is_functional ? true: false;
  }
}
