import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { ImportDetailService } from './import-detail.service';
import { DetailRpusImport, RpusImportDetail } from '@app/models/import-detail';
import { GenericTable } from '@app/models/generic-table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-import-detail',
  templateUrl: './import-detail.component.html',
  styleUrls: ['./import-detail.component.scss']
})
export class ImportDetailComponent implements OnInit {
  pagination: GenericTable<DetailRpusImport>;
  // importsDetailSearchForm: FormGroup;
  import_id = '';
  rpu = '';
  month = 0;
  year = 0;
  is_imported = -1;
  constructor(
    private toaster: ToastrService,
    private Service: ImportDetailService,
    private route: ActivatedRoute
  ) {
    this.import_id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.getElementsPaginated(0);
  }

  exportXLSX() {
    this.Service.downloadXLS(this.import_id, this.rpu, this.month, this.year, this.is_imported)
  }

  getElementsPaginated(page: number) {
    this.Service.getList_RpusImportDetails(this.import_id, page).subscribe(
      (response: GenericTable<DetailRpusImport>) => {
        // console.log(response.elements);
        this.pagination = response;
      }
    );
  }

  getFilterElementsPaginated(page: number) {
    this.Service.getFilter_RpusImportDetails(this.import_id, this.rpu, this.month, this.year, this.is_imported, page).subscribe((response: GenericTable<DetailRpusImport>) => {
        // console.log(response.elements);
        this.pagination = response;
      }, error => {
        console.log(error);
        this.toaster.error(error.error.message);
      }
    );
  }

  pageChange(page: number) {
    this.getElementsPaginated(page - 1);
  }

}
