import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TrackMaterial } from '@app/models/material-track';
import { TrackMaterialService } from '../track-material.service';

@Component({
  selector: 'app-tracked-material',
  templateUrl: './tracked-material.component.html',
  styleUrls: ['./tracked-material.component.scss']
})
export class TrackedMaterialComponent implements OnInit {

  trackMaterial: TrackMaterial[] = [];
  formTrackMaterial: FormGroup;
  finalDate
  initialDate

  constructor(private fb: FormBuilder, private trackService: TrackMaterialService) { }

  ngOnInit() {
    this.formTrackMaterial = this.fb.group({
      StartDate: [null],
      EndDate: [null]
    });
  }
  searchForTrackMaterial() {
    const StartDate = this.formTrackMaterial.get('StartDate').value;
    const EndDate = this.formTrackMaterial.get('EndDate').value;
    this.trackService.getTrackMaterial(
      this.parseDate(StartDate),
      this.parseDate(EndDate)).subscribe(response => {
        this.trackMaterial = response;
       })
  }

  exportData(typeFile: string) {
    const initial = this.initialDate['year'] + '-' + this.initialDate['month'] + '-' + this.initialDate['day']
    const final = this.finalDate['year'] + '-' + this.finalDate['month'] + '-' + this.finalDate['day']

    this.trackService.downloadTrackMaterial(initial, final, typeFile);
  }

  parseDate(date) {
    if (date != null) {
      return  `${date.year}/${date.month}/${date.day}`
    }
    return ''
  }


}
