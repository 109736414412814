import { attr } from "highcharts";

export interface IJudment {
    guaranty_claims_opinion_id: string,
    guaranty_Claim_Id: string,
    policy_number: string,
    created_at: Date,
    guaranty_number: string,
    status: number,
    is_luminaire: boolean,
    selected: boolean,
    luminaire_reference: string
}


export class Judment implements IJudment {
    guaranty_claims_opinion_id: string;
    guaranty_Claim_Id: string;
    policy_number: string;
    created_at: Date;
    guaranty_number: string;
    status: number;
    is_luminaire: boolean;
    selected: boolean;
    luminaire_reference: string;

    constructor(attrs) {
        this.guaranty_claims_opinion_id = attrs.guaranty_claims_opinion_id;
        this.guaranty_Claim_Id = attrs.guaranty_Claim_Id;
        this.policy_number = attrs.policy_number;
        this.created_at = new Date(attrs.created_at);
        this.guaranty_number = attrs.guaranty_number;
        this.status = attrs.status;
        this.is_luminaire = attrs.is_luminaire;
        this.selected = false
        this.luminaire_reference = attrs.luminaire_reference;
    }
}

export interface IGuarantyPhotoCellsOpinion {
    id?: string,
    guaranty_photocell_claim_id?: string,
    policy_number?: string,
    model?: string,
    created_at?: Date,
    turn_on?: boolean,
    internal_work?: boolean,
    conditions?: string,
    is_guaranty?: boolean,
    send_to_provider?: boolean,
    is_accepted?: boolean
}

export class GuarantyPhotoCellsOpinion implements IGuarantyPhotoCellsOpinion {
    id?: string;
    guaranty_photocell_claim_id?: string;
    policy_number?: string;
    model?: string;
    created_at?: Date;
    turn_on?: boolean;
    internal_work?: boolean;
    conditions?: string;
    is_guaranty?: boolean;
    send_to_provider?: boolean;
    is_accepted?: boolean;


    constructor(attrs) {
        this.id = attrs.id;
        this.guaranty_photocell_claim_id = attrs.guaranty_photocell_claim_id;
        this.policy_number = attrs.policy_number;
        this.model = attrs.model;
        this.created_at = new Date(attrs.created_at);
        this.turn_on = attrs.turn_on;
        this.internal_work = attrs.internal_work;
        this.conditions = attrs.conditions;
        this.is_guaranty = attrs.is_guaranty;
        this.send_to_provider = attrs.send_to_provider;
        this.is_accepted = attrs.is_accepted;
    }

}

export class GuarantyLuminaryOptions implements IGuarantyLuminairesOption {
    id?: string;
    guaranty_luminaire_claim_id?: string;
    policy_number?: string;
    created_at?: Date;
    temp_amb?: number;
    humidity?: number;
    electrical_behavior?: string;
    is_oxidation_electric_components?: boolean;
    damaged_door_insurance?: boolean;
    atmospheric_discharge?: boolean;
    is_short_circuit?: boolean;
    hits_scrapes?: boolean;
    sealed_receptacle_nema?: boolean;
    damaged_hinges?: boolean;
    screw_complete?: boolean;
    signs_vandalism?: boolean;
    damaged_wiring?: boolean;
    optical_damage?: boolean;
    led_modules_damage?: boolean;
    surge_arrester_damage?: boolean;
    complete_components?: boolean;
    power_test?: boolean;
    on_during_test?: boolean;
    luminarie_power?: number;
    input_frequency?: number;
    supply_voltage?: number;
    supply_current?: number;
    thd_av?: number;
    thd_ai?: number;
    measured_input_power?: number;
    f_p?: number;
    output_frequency?: number;
    output_voltage?: number;
    output_current?: number;
    alternative_photocell_placed?: boolean;
    lit_with_new_photocell?: boolean;
    alternate_driver_was_placed?: boolean;
    lit_with_new_alternate_driver?: boolean;
    alternate_suppressor_was_placed?: boolean;
    ignited_with_new_alternate_suppressor?: boolean;
    luminaire_conditions?: string;
    corrective_actions?: string;
    is_guaranty: number;
    send_to_provider: number;
    total_amount?: number;
    first_image?: string;
    second_image?: string;
    is_accepted?: boolean;
    firstimage_URL?: string;
    secondimage_URL?: string;

    constructor(attrs) {
        this.id = attrs.id;
        this.guaranty_luminaire_claim_id = attrs.guaranty_luminaire_claim_id;
        this.policy_number = attrs.policy_number;
        this.created_at = new Date(attrs.created_at);
        this.temp_amb = attrs.temp_amb;
        this.humidity = attrs.humidity;
        this.electrical_behavior = attrs.electrical_behavior;
        this.is_oxidation_electric_components = attrs.is_oxidation_electric_components;
        this.damaged_door_insurance = attrs.damaged_door_insurance;
        this.atmospheric_discharge = attrs.atmospheric_discharge;
        this.is_short_circuit = attrs.is_short_circuit;
        this.hits_scrapes = attrs.hits_scrapes;
        this.sealed_receptacle_nema = attrs.sealed_receptacle_nema;
        this.damaged_hinges = attrs.damaged_hinges;
        this.screw_complete = attrs.screw_complete;
        this.signs_vandalism = attrs.signs_vandalism;
        this.damaged_wiring = attrs.damaged_wiring;
        this.optical_damage = attrs.optical_damage;
        this.led_modules_damage = attrs.led_modules_damage;
        this.surge_arrester_damage = attrs.surge_arrester_damage;
        this.complete_components = attrs.complete_components;
        this.power_test = attrs.power_test;
        this.on_during_test = attrs.on_during_test;
        this.luminarie_power = attrs.luminarie_power;
        this.input_frequency = attrs.input_frequency;
        this.supply_voltage = attrs.supply_voltage;
        this.supply_current = attrs.supply_current;
        this.thd_av = attrs.thd_av;
        this.thd_ai = attrs.thd_ai;
        this.measured_input_power = attrs.measured_input_power;
        this.f_p = attrs.f_p;
        this.output_frequency = attrs.output_frequency;
        this.output_voltage = attrs.output_voltage;
        this.output_current = attrs.output_current;
        this.alternative_photocell_placed = attrs.alternative_photocell_placed;
        this.lit_with_new_photocell = attrs.lit_with_new_photocell;
        this.alternate_driver_was_placed = attrs.alternate_driver_was_placed;
        this.lit_with_new_alternate_driver = attrs.lit_with_new_alternate_driver;
        this.alternate_suppressor_was_placed = attrs.alternate_suppressor_was_placed;
        this.ignited_with_new_alternate_suppressor = attrs.ignited_with_new_alternate_suppressor;
        this.luminaire_conditions = attrs.luminaire_conditions;
        this.corrective_actions = attrs.corrective_actions;
        this.is_guaranty = attrs.is_guaranty;
        this.send_to_provider = attrs.send_to_provider;
        this.total_amount = attrs.total_amount;
        this.first_image = attrs.first_image;
        this.second_image = attrs.second_image;
        this.is_accepted = attrs.is_accepted == null ? false : attrs.is_accepted;
        this.firstimage_URL = attrs.firstimage_URL;
        this.secondimage_URL = attrs.secondimage_URL;
    }






}

export interface IGuarantyLuminairesOption {
    id?: string,
    guaranty_luminaire_claim_id?: string,
    policy_number?: string,
    created_at?: Date,
    temp_amb?: number,
    humidity?: number,
    electrical_behavior?: string,
    is_oxidation_electric_components?: boolean,
    damaged_door_insurance?: boolean,
    atmospheric_discharge?: boolean,
    is_short_circuit?: boolean,
    hits_scrapes?: boolean,
    sealed_receptacle_nema?: boolean,
    damaged_hinges?: boolean,
    screw_complete?: boolean,
    signs_vandalism?: boolean,
    damaged_wiring?: boolean,
    optical_damage?: boolean,
    led_modules_damage?: boolean,
    surge_arrester_damage?: boolean,
    complete_components?: boolean,
    power_test?: boolean,
    on_during_test?: boolean,
    luminarie_power?: number,
    input_frequency?: number,
    supply_voltage?: number,
    supply_current?: number,
    thd_av?: number,
    thd_ai?: number,
    measured_input_power?: number,
    f_p?: number,
    output_frequency?: number,
    output_voltage?: number,
    output_current?: number,
    alternative_photocell_placed?: boolean,
    lit_with_new_photocell?: boolean,
    alternate_driver_was_placed?: boolean,
    lit_with_new_alternate_driver?: boolean,
    alternate_suppressor_was_placed?: boolean,
    ignited_with_new_alternate_suppressor?: boolean,
    luminaire_conditions?: string,
    corrective_actions?: string,
    is_guaranty: number,
    send_to_provider: number,
    total_amount?: number,
    first_image?: string,
    second_image?: string,
    is_accepted?: boolean,
    firstimage_URL?: string,
    secondimage_URL?: string
}


