// TODO: comment: Add a blank space to avoid the error using numbers.
export enum BtVoltageEnum {
    '120 - 240',
	'208 - 120',
	'220 - 127',
	'240 - 120',
	'240 - 480',
	' 277',
	'380 - 219',
	'440 - 254',
	'480 - 240',
	'Otro'
}