import { Luminaire } from "@app/models/luminaire";
import { ReportedElements } from "@app/models/reported_elements";

export class ReportedElementsRejectPayload {
  luminaires: Luminaire[]

  constructor(reported_elements: ReportedElements) {
    this.luminaires =
      this.deserializeLuminaires(reported_elements);
  }

  toJson(): Object {
    let json = {
      reported_elements: {
        luminaires: this.luminaires
      }
    };
    return json;
  }

  private deserializeLuminaires(selected: ReportedElements): any[] {
      let luminaires = [];
      for(let circuit of selected.circuits){
        for(let lamppost of circuit.lampposts){
          let _filter = lamppost.luminaires.filter(luminaire => luminaire.isSelected );
          luminaires = luminaires.concat(_filter.map(luminaire => { return {'id': luminaire.id } }));
        }
      }
      for(let lamppost of selected.lampposts){
          let _filter = lamppost.luminaires.filter(luminaire => luminaire.isSelected );
          luminaires = luminaires.concat(_filter.map(luminaire => { return {'id': luminaire.id } }));
      }
      let _filter = selected.luminaires.filter(luminaire => luminaire.isSelected );
      luminaires = luminaires.concat(_filter.map(luminaire => { return {'id': luminaire.id } }));
    return luminaires;
  }
}