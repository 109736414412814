import { Component, OnInit, ViewChild } from '@angular/core';
import { LuminaireEnum } from '@app/models/luminaire-enum';
import { MountingTypeEnum } from '@app/models/mounting_type-enum';
import { StyleMap } from '@app/models/styleMap';
import { SubstationCurrentCapacityEnum } from '@app/models/substation_current_capacity-enum';
import { SubstationEnum } from '@app/models/substation_type-enum';
import { TechnologyEnum } from '@app/models/technology-enum';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { Subject } from 'rxjs';
import { GeneralViewCircuit } from '../general-view-circuit';
import { GeneralViewService } from '../general-view.service';
import { LuminaireNew } from '../luminairesNew';

@Component({
  selector: 'app-general-view-status',
  templateUrl: './general-view-status.component.html',
  styleUrls: ['./general-view-status.component.scss'],
  providers: [MapFuncAuxService, CurrentProjectService]
})
export class GeneralViewStatusComponent implements OnInit {

  luminaireEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));
  substationEnum = Object.keys(SubstationEnum).filter(key => !isNaN(Number(SubstationEnum[key])));
  substationCurrentCapacityEnum = Object.keys(SubstationCurrentCapacityEnum).filter(key => key.length > 2);
  mountingTypeEnum = Object.keys(MountingTypeEnum).filter(key => !isNaN(Number(MountingTypeEnum[key])));
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  destroy$: Subject<boolean> = new Subject<boolean>(); 
  selectedItemsList = [];
  checkedIDs = [];
  lstInfowindows = [];
  circuits: GeneralViewCircuit[] = [];
  messageCircuitTxt = '';
  oldData: LuminaireNew[] = [];
  Luminaires: LuminaireNew[] = [];
  isSelected = true;

  checkboxesDataList  = [
    {
      icon: 'assets/img/svg/ic_municipality_status-1.svg',
      status: 'Municipalizada Consesionada',
      value: 1, isChecked: false , quantity: 0
      },
    {
      icon: 'assets/img/svg/ic_municipality_status-2.svg',
      status: 'Municipalizada No Consesionada',
       value: 2, isChecked: false , quantity: 0
      },
    {
      icon: 'assets/img/svg/ic_municipality_status-3.svg',
      status: 'No Municipalizada Consesionada',
      value: 3, isChecked: false , quantity: 0
    },
    {
      icon: 'assets/img/svg/ic_municipality_status-4.svg',
      status: 'No Municipalizada No Consesionada',
      value: 4, isChecked: false , quantity: 0
    }
  ];
  constructor(
    private toastr: Toastr,
    private mapFuncAux: MapFuncAuxService,
    private generalViewService: GeneralViewService,
    private currentProjectService: CurrentProjectService
    ) { }

  ngOnInit() {
    this.paintMap();
  }
  fetchSelectedItems() {
    this.selectedItemsList = this.checkboxesDataList.filter(opt => opt.isChecked)
    .map(opt => opt.value)
      return this.selectedItemsList;
  }

  private paintMap() {
    const project = this.currentProjectService.getCurrentProject(); 
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })
    this.map.addListener('click', () => {
      this.hideCircuit();
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
      let deselect = this.Luminaires.filter(x => x.isSelected === true);
      deselect.forEach(element => {
        element.isSelected = false;
        element.linkedSituation = 'single';
        element.upadeIcon('normal');
      });
    });
  }

  getLuminaires() {
    const statusArray: any[] =  this.fetchSelectedItems();
    this.oldData.forEach(element => {
      element.hideMarker();
    });
    this.generalViewService.getProjectLuminaires(statusArray).subscribe( (res: LuminaireNew[]) => {
      this.Luminaires = res.map(luminaire => new LuminaireNew(luminaire));
       this.oldData =  this.Luminaires;
       this.checkboxesDataList.forEach(qua => {
         if (qua.value === 1) {
          qua.quantity = this.Luminaires.filter(x => x.municipality_status === 1).length;
         }
         if (qua.value === 2) {
          qua.quantity = this.Luminaires.filter(x => x.municipality_status === 2).length;
         }
         if (qua.value === 3) {
          qua.quantity = this.Luminaires.filter(x => x.municipality_status === 3).length;
         }
         if (qua.value === 4) {
          qua.quantity = this.Luminaires.filter(x => x.municipality_status === 4).length;
         }

       });
      this.paintLuminaires(this.Luminaires);
    });
  }

  paintLuminaires(luminaires) { 
    for (let luminaire of luminaires){
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {

      luminaire.isSelected = ! luminaire.isSelected;
      this.selectedLum( luminaire);
      //  this.hideCircuit();
        this.showInfoMarker(luminaire);
      });
    }
  }

  selectedLum(luminaire: LuminaireNew) {
    let selected = this.Luminaires.filter(x => x.isSelected === true);
    let selctedByCircuit = this.oldData.filter(x => x.curcuit_id === luminaire.curcuit_id && x.curcuit_id !== null);
    selected.forEach(ele => {
      ele.isSelected = false;
  ele.linkedSituation = 'single';
  ele.upadeIcon('normal');
     });
     selctedByCircuit.forEach(element => {
      element.isSelected = true;
       element.linkedSituation = 'assign';
       element.upadeIcon('selected');
     });
  }

  private showInfoMarker(luminaire: LuminaireNew) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        let strInfo = this.getStringInfo(luminaire);
        let infowindow = new google.maps.InfoWindow({ content: strInfo });
        infowindow.open(this.map, luminaire.marker);
        window.setTimeout(() => {
          let seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
          let btnEditLum = document.getElementById('luminaireEdit');
          btnEditLum.addEventListener('click', () => this.saveEditLum(luminaire));
          seeMoreLuminaire.addEventListener('click', () => this.mapFuncAux.seeMoreLuminaire(luminaire.id));
       }, 500);
        this.lstInfowindows.push(infowindow);
  }

  private getStringInfo(luminaire: LuminaireNew): string {
    return `
    <div class="container my-0 mx-0 py-0 px-0">
      <div class="row mx-1 my-0 mt-1">
        <div class="col-6">
          <div class="row">
            <div class="col-12 px-0">
              <strong>${ luminaire.reference_id ? 'Referencia' : 'ID'}</strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12 px-0">${ luminaire.reference_id || luminaire.id}</div>
          </div>
          <div class="row">
            <div class="col-12 px-0">
              <strong>Tipo</strong>
            </div>
          </div>
          <div class="row mb-1">
            ${this.luminaireEnum[luminaire.type]}
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Tecnología</strong></div>
          </div>
          <div class="row mb-1">
            ${this.technologyEnum[luminaire.technology]}
          </div>
          <div class="row">
            <div class="col-12 px-0"><strong>Fotocelda</strong></div>
          </div>
          <div class="row mb-1">
            ${luminaire.has_photocell ? 'Si' : 'No'}
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-12"><strong>Comentarios</strong></div>
          </div>
          <div class="row">
            <div class="col-12 mb-1">
            <textarea id="textAreaComment" style="width: 100%;" rows="6" cols="40">${ luminaire.comment == null ? '' : luminaire.comment}</textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <a href="u/luminaires/${ luminaire.id}" onclick="return false;">
                <input id="seeMoreLuminaire" type="button" style="position: absolute; cursor:pointer;" value="Ver mas" class="oe--button--info">
              </a>
            </div>
            <div class="col-6">
              <input id="luminaireEdit" type="button" style="position: absolute; right: 14px;" value="Guardar" class="oe--button--info">
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
  }

  private saveEditLum(luminaire: LuminaireNew) {
    const txtArea = document.getElementById('textAreaComment');
   const data = {
     id : luminaire.id,
     comment: txtArea['value']
   };
    this.generalViewService.editLuminaire(data).subscribe(response => {
      this.toastr.success('Luminaria actualizada.');
    },
      error => {
        this.toastr.error(error);
      });
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }
  private showCircuit(circuit: GeneralViewCircuit): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();
    circuit.upadeIcon('circuitShowing');
  }

  private hideCircuit(): void {
    let _circuit = this.circuits.find(__circuit => __circuit.event === 'circuitShowing');
    if (_circuit) {
      let event = _circuit.isSelected ? 'selected' : 'normal';
      _circuit.upadeIcon(event);
    }
  }

}
