import { Material } from "./material";

interface ProjectMaterialInterface {
    id: string,
    troop_minimum: number,
    is_active: boolean,
    stock: number,
    material: Material
}

export class ProjectMaterial implements ProjectMaterialInterface {
    id: string;
    troop_minimum: number;
    is_active: boolean;
    stock: number;
    material: Material;
    is_check: boolean;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.troop_minimum = attrs.troop_minimum;
    this.is_active = attrs.is_active;
    this.stock = attrs.stock;
    this.material = new Material(attrs.material);
    this.is_check = false;
  }
}

