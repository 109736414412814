import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { GenericTable } from '@app/models/generic-table';
import { TaskRepairsPhase } from '@app/models/task-repairs-phase';

@Injectable({
  providedIn: 'root'
})
export class RepairTasksPhaseService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getCurrentTasks(page: number) {
    const httpParams = new HttpParams()
    .set('page', page.toString());
    return this.httpClient.get<GenericTable<TaskRepairsPhase>>(
      `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/GetRepiarTasks`, {params: httpParams}
      ).pipe(
      map(elements => new GenericTable<TaskRepairsPhase>(elements))
    );
  }

  getFilteredTasks(reference_id: string, lum_num: string, ticket_num: string, isFinished = 0,  page = 0) {
    const httpParams = new HttpParams()
    .set('reference_id', reference_id)
    .set('lum_num', lum_num)
    .set('ticket_num', ticket_num)
    .set('isFinished', isFinished.toString())
    .set('page', page.toString());
    return this.httpClient.get<GenericTable<TaskRepairsPhase>>(
      `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/GetRepiarTasksFilter`, {params: httpParams}
      ).pipe(
      map(elements => new GenericTable<TaskRepairsPhase>(elements))
    );
  }
}
