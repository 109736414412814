import { Component, OnInit } from '@angular/core';
import { RequstedMaterial } from '@app/models/material-requested';
import { TrackMaterialService } from '../track-material.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-request-material',
  templateUrl: './request-material.component.html',
  styleUrls: ['./request-material.component.scss']
})
export class RequestMaterialComponent implements OnInit {

  reqMaterial: RequstedMaterial[] = [];
  reqMaterials;
  formReqMaterial: FormGroup;
  finalDate
  initialDate
  public isCollapsed: boolean[] = [];


  constructor(private trackService: TrackMaterialService ,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.formReqMaterial = this.fb.group({
      StartDate: [null],
      EndDate: [null]
    });
  }
  searchForReqMaterial() {
    const StartDate = this.formReqMaterial.get('StartDate').value;
    const EndDate = this.formReqMaterial.get('EndDate').value;
    this.trackService.getRequestedMaterial(
      this.parseDate(StartDate),
      this.parseDate(EndDate)).subscribe(response => {
        this.reqMaterial = response;
       })
  }

  exportData(typeFile: string) {
    const initial = this.initialDate['year'] + '-' + this.initialDate['month'] + '-' + this.initialDate['day']
    const final = this.finalDate['year'] + '-' + this.finalDate['month'] + '-' + this.finalDate['day']

    this.trackService.downloadRequestedMaterial(initial, final, typeFile);
  }

  parseDate(date) {
    if (date != null) {
      return  `${date.year}/${date.month}/${date.day}`
    }
    return ''
  }



}
