import { StatusEnum } from "./status-enum";

export interface IRepiarPhaseTwoDetails {
    id: string,
    reference_id: string,
    luminaires: LumianirePhase[],
    failure_type: number,
    status_repair: number,
    supervisor: string,
    unity: number,
    repair_date: Date,
    oficial: string,
    work_done: number[],
    work_description: string,
    used_materials: [
        {
            material: number,
            quantity: number
        }
    ]
}

export class RepiarPhaseTwoDetails implements IRepiarPhaseTwoDetails {
    id: string;
    reference_id: string;
    luminaires: LumianirePhase[];
    failure_type: number;
    status_repair: number;
    supervisor: string;
    unity: number;
    repair_date: Date;
    oficial: string;
    work_done: number[];
    work_description: string;
    used_materials: [{ material: number; quantity: number; }];

    constructor(attrs) {
        this.id = attrs.id;
        this.reference_id = attrs.reference_id;
        this.luminaires = attrs.luminaires.map(luminaire => new LumianirePhase(luminaire));
        this.failure_type = attrs.failure_type;
        this.status_repair = attrs.status_repair;
        this.supervisor = attrs.supervisor;
        this.unity = attrs.unity;
        this.repair_date = new Date(attrs.repair_date);
        this.oficial = attrs.oficial;
        this.work_done = attrs.work_done;
        this.work_description = attrs.work_description == null ? '' : attrs.work_description;
        this.used_materials = attrs.used_materials;
    }
}

interface ILuminairePhase {
    luminaire_id: string,
    reference_id: number,
    status: number,
    location: Location
}

export class LumianirePhase implements ILuminairePhase {
    luminaire_id: string;
    reference_id: number;
    status: number;
    location: Location;
    marker: google.maps.Marker;
    isSelected: boolean;
    event: string;
    linkedSituation: string
    statusName: string;

    readonly markerIcons = {
        linked: {
            hover: [ // verde azulado, muestra el poste completo
                'assets/img/svg/link_selected-0.svg',
                'assets/img/svg/link_selected-1.svg',
                'assets/img/svg/link_selected-2.svg',
                'assets/img/svg/link_selected-3.svg',
                'assets/img/svg/link_selected-4.svg'
            ],
            normal: [ // normal, rojo
                'assets/img/svg/link_normal-0.svg',
                'assets/img/svg/link_normal-1.svg',
                'assets/img/svg/link_normal-2.svg',
                'assets/img/svg/link_normal-3.svg',
                'assets/img/svg/link_normal-4.svg'
            ],
            selected: [ // grande y azul
                'assets/img/svg/lamppost_selected-0.svg',
                'assets/img/svg/lamppost_selected-1.svg',
                'assets/img/svg/lamppost_selected-2.svg',
                'assets/img/svg/lamppost_selected-3.svg',
                'assets/img/svg/lamppost_selected-4.svg'
            ],
            circuitShowing: [ // morado
                'assets/img/svg/ic_link_circuit-0.svg',
                'assets/img/svg/ic_link_circuit-1.svg',
                'assets/img/svg/ic_link_circuit-2.svg',
                'assets/img/svg/ic_link_circuit-3.svg',
                'assets/img/svg/ic_link_circuit-4.svg'
            ]
        },
        single: {
            normal: [
                'assets/img/svg/luminaire_normal-0.svg',
                'assets/img/svg/luminaire_normal-1.svg',
                'assets/img/svg/luminaire_normal-2.svg',
                'assets/img/svg/luminaire_normal-3.svg',
                'assets/img/svg/luminaire_normal-4.svg'
            ],
            selected: [
                'assets/img/svg/luminaire_selected-0.svg',
                'assets/img/svg/luminaire_selected-1.svg',
                'assets/img/svg/luminaire_selected-2.svg',
                'assets/img/svg/luminaire_selected-3.svg',
                'assets/img/svg/luminaire_selected-4.svg'
            ]
        }
    }

    constructor(attrs) {
        this.luminaire_id = attrs.luminaire_id,
            this.reference_id = attrs.reference_id || 'Sin referencia',
            this.status = attrs.status,
            this.location = new Location({ longitude: attrs.longitude, latitude: attrs.latitude }),
            this.isSelected = false,
            this.linkedSituation = 'single',
            this.event = 'normal';
        this.statusName = StatusEnum[attrs.status || 0]
    }

    get_icon(): object {
        return {
            // tslint:disable-next-line:max-line-length
            url: this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event][this.status],
            scaledSize: this.isSelected ? new google.maps.Size(34, 50) : new google.maps.Size(28, 40)
        };
    }

    paintMarker(map: any) {
        const latitude = this.location.latitude;
        const longitude = this.location.longitude;

        this.marker = new google.maps.Marker({
            title: this.reference_id.toString(),
            position: new google.maps.LatLng(longitude, latitude),
            map: map,
            icon: this.get_icon()
        });
    }

    hideMarker(): void {
        this.marker.setMap(null);
    }

    setMap(map: any): void {
        this.marker.setMap(map);
    }


}

class Location {
    longitude: number;
    latitude: number
    constructor(attrs: { longitude: number, latitude: number }) {
        this.latitude = attrs.latitude;
        this.longitude = attrs.longitude
    }
}
