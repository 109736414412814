import { Component, OnInit, ViewChild } from '@angular/core';
import { StyleMap } from '@app/models/styleMap';
import { DeleteMenu } from '@app/shared/components/map-delete-node.component';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Driver from 'driver.js';
import { DetailSurveyArea, LocationSurveyArea, SingleSurveyArea } from '../survey-area/survey-area';
import { SingleSecctorArea } from './sector-area';
import { SectorService } from './sector.service';

@Component({
  selector: 'app-sectores',
  templateUrl: './sectores.component.html',
  styleUrls: ['./sectores.component.css']
})
export class SectoresComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('modalConfirm') ngModalSave: any;
  surveyAreaName = '';
  typearea = '';
  surveyAreaNameEdit = '';
  survey_area_name = '';
  btnShowAll = false;
  surveyAreaId;
  detailSurverAreaShow = false;
  detailSurveyArea: DetailSurveyArea = new DetailSurveyArea({});
  detailSurveyAreaAllLums: DetailSurveyArea = new DetailSurveyArea({});
  drawingManager: any;
  driver: Driver = new Driver({
    animate: true,
    allowClose: false,
    keyboardControl: false
  });
  polygons = [];
  editMode = '';
  listPolygonCreate_ = {};
  editingPolygon: google.maps.Polygon;
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  btnEditName = false;
  disableBtnEdit = true;
  btnExport = true;
  survey_areas: SingleSurveyArea[] = [];
  constructor(private modalService: NgbModal, private toastr: Toastr,
    private currentProjectService: CurrentProjectService, public sectorAreaService: SectorService) { }

  ngOnInit() {
    this.getSurveyAreas();
  }

  private getSurveyAreas() {
    this.sectorAreaService.getSectorAreas('').subscribe(result => {
      this.survey_areas = result;


      this.paintMap();
      this.addSurveyAreas_();
    }, error => {
      this.paintMap();
      this.addSurveyAreas_();
    });
    this.paintMap();
    this.addSurveyAreas_();
  }

  private addSurveyAreas_() {

    this.survey_areas.forEach(survey_area => {
      let polygonCoords = [];
      const deleteMenu = new DeleteMenu();
      if (!survey_area.points.length) {
        return;
      }
      polygonCoords = survey_area.points.map(point => new google.maps.LatLng(point.latitude, point.longitude));

      const myPolygon = new google.maps.Polygon({
        map: this.map,
        paths: polygonCoords,
        strokeColor: '#4885E6',
        strokeOpacity: 0.8,
        fillColor: '#4885E1',
        fillOpacity: 0.25,
      });

      survey_area.polygon = myPolygon;
      myPolygon.addListener('click', (event) => { this.clickPoligon_(survey_area) });
      myPolygon.getPath().addListener('set_at', (event) => { this.changePolygon_(survey_area, 'setat') });
      myPolygon.getPath().addListener('insert_at', (event) => { this.changePolygon_(survey_area, 'actualizar') });
      myPolygon.getPath().addListener('remove_at', (event) => { this.changePolygon_(survey_area, 'borrado') });
      polygonCoords = [];
      myPolygon.addListener('rightclick', function (e) {
        if (e.vertex === undefined) {
          return;
        }

        deleteMenu.open(this.map, myPolygon.getPath(), e.vertex);
      });
    });
  }



  searchSectorArea_() {
    console.log(this.survey_area_name);
    this.sectorAreaService.getSectorAreas(this.survey_area_name).subscribe(response => {
      console.log(response, 'busqueda');
      if (response.length > 0) {
        this.paintMap();
        /*Siempre se obtiene el primero ya que nunca se repiten los nombre en teoria*/
        this.survey_areas = [];
        this.survey_areas.push(new SingleSurveyArea(response[0]));
        this.addSurveyAreas_();
        this.sectorAreaService.detailSectorArea(response[0].id).subscribe(res => {
          this.detailSurveyArea = res;
          this.btnEditName = false;
          this.surveyAreaNameEdit = res.surveyAreaName;
          this.detailSurverAreaShow = true;
          this.btnExport = false;
          this.surveyAreaId = response[0].id;
        }, error => {
          this.toastr.error(error);
        });

      } else {
        this.toastr.singleError('no existe ninguna area con el nombre ' + this.survey_area_name + ' pruebe con otro');
      }



    });
    this.btnShowAll = true;
  }




  showAllPolygons() {

    this.getSurveyAreas();
    this.btnExport = true;
    this.btnShowAll = false;
    this.survey_area_name = '';
    this.detailSurverAreaShow = false;
  }

  detailALlLuminairesExport() {
    this.sectorAreaService.exportallLuminairesDetail();
  }


  editPolygonName(polygon_name) {
    if (polygon_name) {
      if (this.detailSurveyArea.surveyAreaName.toLowerCase().replace(' ', '') === this.surveyAreaNameEdit.toLowerCase().replace(' ', '')) {
        this.toastr.singleError('El nombre que se trata de cambiar es el mismo al anterior');
        return;
      }

      if (this.surveyAreaNameEdit.replace(' ', '') === '') {
        this.toastr.singleError('El nombre esta vacio asegurese de ingresar un nombre');
        return;
      }
      const updateNameSurveyArea = { name: this.surveyAreaNameEdit, sector_area_id: this.surveyAreaId };

      console.log(updateNameSurveyArea);
      this.sectorAreaService.updatenameSector(updateNameSurveyArea).subscribe(resp => {
        this.detailSurveyArea.surveyAreaName = this.surveyAreaNameEdit;
        console.log(resp);
      }, error => {
        this.toastr.error(error);
      })

    }
    this.btnEditName = !polygon_name;
  }
  clickEditMap(): void {
    if (this.editMode === '') {
      this.driver.highlight('#mapLuminaires');
      this.setCenterMap();
      this.drawingManager.setOptions({ drawingControl: false });
      this.editMode = 'edit';
    } else {
      this.driver.reset();
      this.drawingManager.setOptions({ drawingControl: true });
      this.editMode = '';
      if (this.editingPolygon) {
        this.drawingManager.setOptions({ drawingControl: true });
        this.editingPolygon.setEditable(false);
      }
    }
  }


  detailSectorAreaExport() {
    this.sectorAreaService.exportXLSsectorAreaDetails(this.surveyAreaId);
  }


  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    });
  }

  private addPointToForm(polygon: any) {
    this.listPolygonCreate_ = polygon;
  }

  openModal(polygon: any) {
    this.modalService.open(this.ngModalSave, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
      result => {
      },
      reason => {
        console.log(reason, 'borrado de vertices');
        // if (reason === 'Cross click') {
        for (const index = 0; index < polygon.getPath().length;) {
          polygon.getPath().removeAt(index);
        }
        this.surveyAreaName = '';
        this.listPolygonCreate_ = {
        };
        // }

      });
  }


  private paintMap() {
    const project = this.currentProjectService.getCurrentProject();
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    });
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: {
        editable: true,
        draggable: true,
        fillOpacity: .25
      }
    });

    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
      this.disableBtnEdit = true;
      const deleteMenu = new DeleteMenu();
      const polygonPath = polygon.getPath();
      this.addPointToForm(polygon);
      this.openModal(polygon);
      google.maps.event.addListener(polygon, 'rightclick', function (e) {

        if (e.vertex === undefined) {
          return;
        }
        deleteMenu.open(this.map, polygon.getPath(), e.vertex);
      });
      google.maps.event.addListener(polygonPath, 'set_at', (_) => {

        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'insert_at', (_) => {

        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'remove_at', (_) => {

        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygon, 'dragend', (_) => {

        this.addPointToForm(polygon);
      })
    });

    this.drawingManager.setMap(this.map);
  }
  private clickPoligon_(survey_area: SingleSurveyArea) {


    this.survey_areas.filter(item => item.selected === true).forEach(survey => {
      survey.polygon.setOptions({ strokeColor: '#4885E6', fillColor: '#4885E1', fillOpacity: .25 });
      survey.selected = false;
    });
    survey_area.selected = true;
    this.btnExport = false;
    this.surveyAreaId = survey_area.id;
    if (this.editMode === '') {

      survey_area.polygon.setOptions({ fillColor: '#50A2FF', fillOpacity: 1 });
      // polygon.setMap(this.map);
      this.sectorAreaService.detailSectorArea(survey_area.id).subscribe(res => {
        this.detailSurveyArea = res;
        this.btnEditName = false;
        this.surveyAreaNameEdit = res.surveyAreaName;
        this.detailSurverAreaShow = true;
      }, error => {
        this.toastr.error(error);
      });
      return;
    }

    if (this.editingPolygon === survey_area.polygon) {
      survey_area.polygon.setEditable(false);
      this.editingPolygon = null;
    } else if (this.editingPolygon) {
      survey_area.polygon.setEditable(true);
      this.editingPolygon.setEditable(false);
      this.editingPolygon = survey_area.polygon;
    } else {
      survey_area.polygon.setEditable(true);
      this.editingPolygon = survey_area.polygon;
    }
  }

  private setCenterMap() {
    setTimeout(() => {
      const element = document.getElementById('driver-highlighted-element-stage');
      element.scrollIntoView(false);
      element.scrollIntoView({ block: 'center' });
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, 0);
  }

  private changePolygon_(survey_area: SingleSecctorArea, valor) {
    console.log(valor, "Acciones")
    const update = {
      id: survey_area.id,
      points: []
    };
    if (!survey_area.polygon.getPath().getLength()) {
      this.toastr.singleError('No se puede crear el poligono');
      return;
    }

    for (let i = 0; i < survey_area.polygon.getPath().getLength(); i++) {
      update.points.push({
        latitude: survey_area.polygon.getPath().getAt(i).lat(),
        longitude: survey_area.polygon.getPath().getAt(i).lng()
      });
    }
 
    if (survey_area.polygon.getPath().getLength() <= 3) {

      this.sectorAreaService.deleteSector(update.id).subscribe(resp => {
        this.toastr.success('Se elimino el sector');
        this.getSurveyAreas();
      }, error => {

      })


    } else {
      this.sectorAreaService.updateSectorArea(update).subscribe(resp => {
        this.toastr.success('Coordenadas actualizadas');
      }, error => {
        console.log(error);
        this.toastr.error(error);
      })

    }


    // .subscribe(
    //   (response: any) => {
    //     this.toastr.success('Coordenadas actualizadas.');
    //   },
    //   (error) => {
    //     this.toastr.error(error);
    //   }
    // )
  }





  createNewSurveyArea() {
    if (this.listPolygonCreate_ && this.surveyAreaName.replace(' ', '') !== '') {
      let insert = {
        name: '',
        type: '',
        points: []
      };
      insert = {
        name: this.surveyAreaName,
        type: this.typearea,
        points: this.deserializePolygon(this.listPolygonCreate_)
      };


      this.sectorAreaService.createsectorarea(insert).subscribe(res => {
        this.survey_areas.push(new SingleSurveyArea({
          id: res,
          points: insert.points
        }));
        this.addSurveyAreas_();
        this.disableBtnEdit = true;
        this.toastr.success('Los poligonos se crearon corretamente')
        this.surveyAreaName = '';
        this.listPolygonCreate_ = {};
      }, error => {
        this.toastr.error(error);
      });
    } else {
      this.openModal(this.listPolygonCreate_);
      this.toastr.singleError('No se Asigno un nombre al area creada favor de asignar nombre');
    }


  }



  private deserializePolygon(polygon: any): LocationSurveyArea[] {
    const geolocationPoints: LocationSurveyArea[] = [];
    const polygonPath = polygon.getPath();

    for (let i = 0; i < polygonPath.getLength(); i++) {
      const point = polygonPath.getAt(i);

      geolocationPoints.push(new LocationSurveyArea(
        {
          latitude: point.lat(), longitude: point.lng()
        }));
    }
    return geolocationPoints;
  }


}
