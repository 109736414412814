import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { NewInstallationTaskPayload } from '@models/new-installation-task-payload';
import { MaterialSuggestion, MaterialsToAssing } from './new-installation-task.component';
import { ListSuggestions } from '@app/models/instllations-suggestions';

@Injectable({
  providedIn: 'root'
})
export class NewInstallationTaskService {

  constructor(
    private httpClient: HttpClient
  ) { }

  createInstallationTask(newInstallationTaskPayload: NewInstallationTaskPayload): Observable<Object> {
    console.log(newInstallationTaskPayload.toJson())
    return this.httpClient.post<object>(
      `${environment.SERVER_URL}/tasks`,
      newInstallationTaskPayload.toJson()
    );
  }

  getMarkers(latitude: number, longitude: number) {
    const params = new HttpParams()
      .set('latitude', latitude.toString())
      .set('longitude', longitude.toString());
    return this.httpClient.get<MaterialSuggestion[]>(
      `${environment.SERVER_TASKS}/api/Task_Installation/GetMarkers`, { params: params}
    );
  }
  verifyMaterial(element_id: string, element_type: number) {
    const params = new HttpParams()
      .set('element_id', element_id)
      .set('element_type', element_type.toString());

    return this.httpClient.get<MaterialSuggestion[]>(
      `${environment.SERVER_TASKS}/api/Task_Installation/GetMaterialPreview`, { params: params }
    );
  }

  getMaterialsToAssing(element_id: string, element_type: number) {
    const form = { element_id: element_id, element_type: element_type };

    return this.httpClient.post<ListSuggestions>(
      `${environment.SERVER_TASKS}/api/Task_Installation/MaterialsWithDefaultsSelected`, form
    );
  }

  materialsToAssing(material: string) {
    const params = new HttpParams().set('material', material);

    return this.httpClient.get<MaterialsToAssing[]>(
      `${environment.SERVER_TASKS}/api/Task_Installation/GetMaterialsToAssing`, { params: params }
    );
  }

  createInstallationTasks(material: any) {

    return this.httpClient.post<MaterialsToAssing[]>(
      `${environment.SERVER_TASKS}/api/Task_Installation/RegisterSuggestedMaterials`, material
    );
  }
}
