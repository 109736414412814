import { Component, OnInit, ViewChild } from '@angular/core';
import { RepairsPhaseService } from './repairs-phase.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { RepiarPhaseTwoDetails, LumianirePhase } from '@app/models/repiar-phase-two-details';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { element } from 'protractor';
import { StyleMap } from '@app/models/styleMap';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { PersonCbo } from '@app/models/person-cbo-class';

@Component({
  selector: 'app-repairs-phase',
  templateUrl: './repairs-phase.component.html',
  styleUrls: ['./repairs-phase.component.scss'],
  providers: [MapFuncAuxService]
})
export class RepairsPhaseComponent implements OnInit {

  @ViewChild('contentMaterialUsed') materialModal: any;
  @ViewChild('contentWorkDone') workModal: any;
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();

  repair_task: RepiarPhaseTwoDetails;
  supervisors: PersonCbo[];
  oficials: PersonCbo[];
  basicForm: FormGroup;
  materialSelected: FormGroup;
  workDoneForm: FormGroup;
  statusForm: FormGroup;
  workDescription;
  editMaterial = [];
  totalMaterial;
  lstInfowindows = [];

  materials = [
    'Poste Metalico', 'Brazo Metalico', 'Gabinete Completo', 'Base Medidor',
    'Tuberia Conduit', 'Tuberia Flexible', 'Cable', 'Fotocelda',
    'Fleje de Acero', 'Aislador', 'Abrazadera', 'Manga Termocontractil', 'Conector Bimetalico',
    'Conector IPC', 'Poliducto', 'Fusible Media Tension', 'Porta Fusible Media Tension',
    'CortaCircuitos', 'Bastidor Para Poste', 'Tornillos y Soporteria', 'Contactor', 'Conector Perico',
    'Conector LiquidThight', 'Cople Para Tubo Conduit', 'Cople Para Base de Medidor', 'Lampara Retrofit',
    'Varilla', 'Bobina para contactor', 'Interruptor Principal', 'Interruptor Derivado', 'Lampara HID',
    'Balastro Magnetico', 'Balastro Electronico', 'Cable de Interconexion', 'Cable Aereo', 'Cable Subterraneo'
  ]

  failure_diagnosis = [
    { display: 'No aplica', value: 0 },
    { display: 'Mantenimiento Preventivo', value: 1 },
    { display: 'Mantenimiento Correctivo', value: 2 },
    { display: 'Vandalismo', value: 3 },
    { display: 'Robo', value: 4 },
    { display: 'Accidente', value: 5 },
    { display: 'Naturaleza', value: 6 }
  ];

  work_done = [
    'Reconexion por Sulfatación', 'Reconexion en Luminaria', 'Cableado Aereo', 'Cableado Subterraneo',
    'Cambio de Kit de Fotocelda', 'Cambio Fotocelda', 'Ajuste de Fotocelda', 'Cambio de contactor', 'Cambio de bobina de contactor',
    'Cambio de Interruptor', 'Cambio de Base de Medidor', 'Cambio de Tubo Mufa de 1-1/2', 'Cambio de Tablero Completo',
    'Cambio de Gabinete Tablero', 'Cambio de Canilla', 'Cambio de Transformador', 'Cambio de Lampara VSAP',
    'Cambio de Lampara Inducción Magnetica', 'Cambio de Balstro', 'Cambio de Lampara Led', 'Cambio de Poste',
    'Cambio de Brazo', 'Reset Interruptor'
  ]





  constructor(
    private repairPhaseService: RepairsPhaseService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: Toastr,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private currentProjectService: CurrentProjectService,
    private mapFuncAux: MapFuncAuxService,

  ) { }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.paintMap();
    this.basicForm = this.fb.group({
      id: [null, Validators.required],
      reference_id: [{ value: null, disabled: true }, Validators.required],
      failure_type: [null, Validators.required],
      repair_date: [null, Validators.required],
      supervisor: [null, Validators.required],
      unity: [null, Validators.required],
      oficial: [null, Validators.required]
    });

    this.materialSelected = this.fb.group({
      material: [null, Validators.required]
    });

    this.workDoneForm = this.fb.group({
      work: [null, Validators.required]
    });

    this.statusForm = this.fb.group({
      status: [null, Validators.required],
      luminaire: [null, Validators.required]
    });

    this.repairPhaseService.getOficials().subscribe(persons => {
      this.oficials = persons
    });

    this.repairPhaseService.getSupervisors().subscribe(persons => {
      this.supervisors = persons
    })

    this.repairPhaseService.getRepair(id).subscribe(el => {
      this.repair_task = el
      el.used_materials.forEach(() => {
        this.editMaterial.push(false);
      });

      this.paintLuminaires();


      this.basicForm.patchValue({
        id: el.id,
        reference_id: el.reference_id,
        failure_type: el.failure_type,
        repair_date: new NgbDate(el.repair_date.getFullYear(), el.repair_date.getMonth() + 1, el.repair_date.getDate()),
        supervisor: el.supervisor,
        unity: el.unity,
        oficial: el.oficial
      });
    });
    
  }

  setBasicInfo() {

    if (this.basicForm.invalid) {
      this.toastr.singleError('Ingrese la información requerida');
      return null;
    }

    return this.basicForm.getRawValue();
  }

  showMaterialModal() {
    this.modalService.open(this.materialModal).result.then(result => {
    }, reason => {
    });
  }

  showWorkModal() {
    this.modalService.open(this.workModal).result.then(result => { }, reason => { });
  }

  addMaterial() {
    if (this.materialSelected.invalid) {
      this.toastr.singleError('Seleccione un material');
      this.materialSelected.reset();
      return;
    }

    const material = this.materialSelected.get('material').value;

    if (this.verifyMaterial(material)) {
      this.toastr.singleError('Este material se seleciono anteriormente');
      return;
    }

    this.repair_task.used_materials.push({ material: material, quantity: 1 });
    this.editMaterial.push(false);
    this.materialSelected.reset();
    this.modalService.dismissAll();
  }

  addWork() {
    if (this.workDoneForm.invalid) {
      this.toastr.singleError('Seleccione el trabajo realizado');
      return;
    }

    const work = this.workDoneForm.get('work').value;

    if (this.verifyWork(work)) {
      this.toastr.singleError('Este trabajo realizado se seleciono anteriormente');
      return;
    }

    this.repair_task.work_done.push(work);
    this.workDoneForm.reset();
    this.modalService.dismissAll();
  }

  verifyMaterial(material) {
    let existMaterial = false;
    this.repair_task.used_materials.forEach(mat => {
      if (mat.material == material) {
        existMaterial = true;
      }
    });
    return existMaterial;
  }

  verifyWork(work) {
    let existWork = false;
    this.repair_task.work_done.forEach(workDone => {
      if (workDone == work) {
        existWork = true;
      }
    });
    return existWork;
  }

  addOrRemoveMaterial(add: boolean = false, i) {
    if (add) {
      this.repair_task.used_materials[i].quantity += 10;
    } else {
      if (this.repair_task.used_materials[i].quantity > 10) {
        this.repair_task.used_materials[i].quantity -= 10;
      }
    }
  }

  addMaterialDetails() {
    if (this.repair_task.used_materials.length <= 0) {
      return [];
    }

    return this.repair_task.used_materials
  }

  addWorkDetails() {

    // if (this.repair_task.work_description == null || this.repair_task.work_done.length == 0) {
    //   this.toastr.singleError('Agregue los trabajos realizados y una descripción');
    //   return;
    // }
    const newValue = {
      work_description: null,
      work_done: []
    };


    if (this.repair_task.work_description != null) {
      newValue.work_description = this.repair_task.work_description
    }

    if (this.repair_task.work_done.length > 0) {
      newValue.work_done = this.repair_task.work_done
    }

    return newValue;
  }

  removeMaterial(i) {
    this.repair_task.used_materials.splice(i, 1);
  }

  removeWork(i) {
    this.repair_task.work_done.splice(i, 1);
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  private paintMap() {
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const project = this.currentProjectService.getCurrentProject();

    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })
  }

  paintLuminaires() {
    for (const luminaire of this.repair_task.luminaires) {
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        this.showInfoWindow(luminaire);
      })
    }
  }

  private showInfoWindow(luminaire: LumianirePhase) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const strInfo = `
    <div>
      <div class="d-flex my-0">
      <form>
        <label>Estatus Luminaira ${luminaire.reference_id}</label>
        <select class="form-control" id="repairStatus">
          <option value="0">Pendiente</option>
          <option value="1">Reparada</option>
          <option value="2">No procede</option>
          <option value="3">Sin falla presente</option>
        </select>
        <br>
        <div class="justify-content-end mb-2">
          <div class="mt-2">
            <input id="sendRepairValue" type="button" style="cursor:pointer;" value="Guardar" class="oe--button--info">
          </div>
        </div>
      </form>
      </div>
    </div>
    `;

    const infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 600
    });

    infowindow.open(this.map, luminaire.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      const btnSetLuminaireStatus = document.getElementById('sendRepairValue');
      btnSetLuminaireStatus.addEventListener('click', () => {
        const select = document.getElementById('repairStatus');

        for (let i = 0; i < select['options'].length; i++) {
          const opt = select['options'][i];

          if (opt.selected === true) {
            this.saveLuminaireStatus(luminaire, opt.value);
          }
        }
      })
    }, 600);
  }

  addMethods() {

    const formBasic = this.setBasicInfo();
    const formMaterial = this.addMaterialDetails();
    const formWork = this.addWorkDetails();

    if (formBasic == null) {
      return;
    }

    this.repairPhaseService.setRepairInfo(formBasic, formMaterial, formWork).subscribe(() => {
      this.toastr.success('El registro se actualizo correctamente');
    }, error => {
      this.toastr.singleError('El registro no se pudo actualizar');
    });

  }

  saveLuminaireStatus(luminaire: LumianirePhase, optionSelected: number) {
    if (optionSelected == 0) {
      luminaire.status = 2
    } else {
      luminaire.status = 4
    }
    luminaire.marker.setIcon(luminaire.get_icon())

    this.statusForm.patchValue({
      status: luminaire.status,
      luminaire: luminaire.luminaire_id
    });

    this.repairPhaseService.setLuminaireStatus(this.statusForm.value, this.repair_task.id).subscribe(() => {
      this.toastr.success('Se actualizo el registro');
    }, error => {
      this.toastr.singleError('Error Server')
    })

  }

  changeStatusTask(value: boolean) {
    this.repairPhaseService.changeRepairStatus(this.repair_task.id, value).subscribe(() => {
      this.toastr.success('Se actualizo el registro');
    }, error => {
      this.toastr.singleError('Error Server')
    })
  }
}
