import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { NewUserResponse } from '@models/new-user-response';
import { NewUserPayload } from '@models/new-user-payload';

@Injectable()

export class NewUserService {
  constructor(private httpClient: HttpClient) {}

  createUser(userPayload: NewUserPayload): Observable<NewUserResponse> {
    return this.httpClient.post<NewUserResponse>(
      `${environment.SERVER_URL}/users`,
      userPayload.toFormData()
    );
  }
}
