import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DevicesService } from '../../devices.service';
import * as FusionCharts from 'fusioncharts';

@Component({
  selector: 'app-device-kwhs',
  templateUrl: './device-kwhs.component.html',
  styleUrls: ['./device-kwhs.component.scss']
})
export class DeviceKwhsComponent implements OnInit {

  kwhsDataSource: any;

  type: string;
  width: string;
  height: string;

  constructor(private deviceService: DevicesService,
    private activatedRoute: ActivatedRoute,) {
    this.type = 'timeseries';
    this.width = '100%';
    this.height = '400';


    this.kwhsDataSource = {
      caption: {
        text: "Kwhs"
      },
      chart: {
        theme: "fusion"
      },
      yaxis: [
        {
          plot: "Kwhs",
          title: "Kwhs",
          plottype: "column"
        }
      ],
      xaxis: {
        binning: {
          year: [],
          month: [1],
          day: [1]
        }
      }
    }
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (!params.has('id')) {
        return;
      }

      this.deviceService.getDeviceKwhsReadings(params.get('id')).subscribe(data => {
        this.kwhsData(data)
      })
    })
  }
  kwhsData(data: any[]) {
    const dataFetch = data;
    const schemaFetch = [{
      'name': 'Time',
      'type': 'date',
      'format': '%Y-%m-%d'
    }, {
      'name': 'Kwhs',
      'type': 'number'
    }];
    // var schemaFetch = [{
    //   "name": "Time",
    //   "type": "date",
    //   "format": "%-m/%-d/%Y"
    // }, {
    //   "name": "Kwhs",
    //   "type": "number"
    // }
    // ]

    Promise.all([dataFetch, schemaFetch]).then(res => {
      const [data, schema] = res;
      const fusionDataStore = new FusionCharts.DataStore();
      const fusionTable = fusionDataStore.createDataTable(data, schema);
      this.kwhsDataSource.data = fusionTable;
    });
  }
}
