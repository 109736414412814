import { Material } from "./material";

interface InstallationInterface {
    id: string
    reference: string
    first_image_url: string
    second_image_url: string
    third_image_url: string
    fourth_image_url: string
    fifth_image_url: string
    sixth_image_url: string
    seventh_image_url: string
    eigth_image_url: string
    comment: string
    installed_items: Installed_item[]
    installation_recovered_items: Installation_recovered_item[]
    case_condition: number
    diffractor_condition: number
    bulb_condition: number
    ballast_condition: number
    photocell_condition: number
    is_finished: boolean
    resident_comment: string
    is_new_point: boolean
}

export class Installation implements InstallationInterface {
    id: string;
    reference: string;
    first_image_url: string;
    second_image_url: string;
    third_image_url: string;
    fourth_image_url: string;
    fifth_image_url: string;
    sixth_image_url: string;
    seventh_image_url: string;
    eigth_image_url: string;
    comment: string;
    installed_items: Installed_item[];
    installation_recovered_items: Installation_recovered_item[];
    case_condition: number;
    diffractor_condition: number;
    bulb_condition: number;
    ballast_condition: number;
    photocell_condition: number;
    is_finished: boolean;
    resident_comment: string;
    is_new_point: boolean;

    constructor(attrs: any) {
        this.id = attrs.id;
        this.reference = attrs.reference;
        this.first_image_url = attrs.first_image_url;
        this.second_image_url = attrs.second_image_url;
        this.third_image_url = attrs.third_image_url;
        this.fourth_image_url = attrs.fourth_image_url;
        this.fifth_image_url = attrs.fifth_image_url;
        this.sixth_image_url = attrs.sixth_image_url;
        this.seventh_image_url = attrs.seventh_image_url;
        this.eigth_image_url = attrs.eigth_image_url;
        this.comment = attrs.comment;
        this.installed_items = attrs.installed_items ? attrs.installed_items.map( installed => new Installed_item(installed)) : [];
        // tslint:disable-next-line:max-line-length
        this.installation_recovered_items = attrs.installation_recovered_items ? attrs.installation_recovered_items.map(installation => new Installation_recovered_item(installation)) : [];
        this.case_condition = attrs.case_condition;
        this.diffractor_condition = attrs.diffractor_condition;
        this.bulb_condition = attrs.bulb_condition;
        this.ballast_condition = attrs.ballast_condition;
        this.photocell_condition = attrs.photocell_condition;
        this.is_finished = attrs.is_finished;
        this.resident_comment = attrs.resident_comment;
        this.is_new_point = attrs.is_new_point;
    }
}


export class Installed_item {
    id: string
    installed_quantity: number
    material: Material
    suggested_quantity: number

    constructor(attrs: any) {
        this.id = attrs.id;
        this.installed_quantity = attrs.installed_quantity;
        this.material = new Material(attrs.material);
        this.suggested_quantity = attrs.suggested_quantity;
    }
}
export class Installation_recovered_item {
    id: string
    quantity: number
    material: Material

    constructor(attrs: any) {
        this.id = attrs.id;
        this.quantity = attrs.quantity;
        this.material = new Material(attrs.material);
    }
}

