import { FormGroup } from '@angular/forms';

export class EditPasswordPayload {
  password: string;
  password_confirmation: string;

  constructor(passwordForm: FormGroup) {
    this.password = passwordForm.get('password').value;
    this.password_confirmation = passwordForm.get('password_confirmation').value;
  }

   toFormData() {
    let formData = new FormData();
    formData.append('user[password]', this.password);
    formData.append('user[password_confirmation]', this.password_confirmation);
    return formData;
  }

  toJson(): Object {
    let json = {
      user: {
        password: this.password,
        password_confirmation: this.password_confirmation
      }
    };
    return json;
  }
}