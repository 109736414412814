import { FormGroup } from '@angular/forms';

import { Project } from '@models/project';

export class EditProjectPayload implements Project {
  id: string;
  name: string;
  country: string;
  state: string;
  municipality: string;
  slug: string;
  tickets_enabled: boolean;
  last_luminaire_reference_id: string;
  phase: number;
  energy_year: number;

  constructor(newProjectForm: FormGroup) {
    this.name = newProjectForm.get('name').value;
    this.country = newProjectForm.get('country').value;
    this.state = newProjectForm.get('state').value;
    this.municipality = newProjectForm.get('municipality').value;
    this.slug = newProjectForm.get('slug').value;
    this.phase = newProjectForm.get('phase').value;
    this.energy_year = newProjectForm.get('energy').value
  }

  toJson(): Object {
    const json = {
      project: {
        name: this.name,
        country: this.country,
        state: this.state,
        municipality: this.municipality,
        slug: this.slug,
        phase: this.phase,
        energy_year: this.energy_year
      }
    };

    return json;
  }
}
