import { User } from "@models/user";
import { Material } from "@app/models/material";

export interface InstallationKpisInterface {
    luminaire_kpis: Luminaire_kpis;
    project_materials_kpis: projectMaterialsKpis[];
    user_kpis: User[];
}

export class  InstallationKpi implements InstallationKpisInterface {
    luminaire_kpis: Luminaire_kpis;
    project_materials_kpis: projectMaterialsKpis[];
    user_kpis: User[];

    constructor(attrs: any) {
        this.luminaire_kpis = new Luminaire_kpis(attrs.luminaire_kpis);
        this.project_materials_kpis = attrs.project_materials_kpis.map( material => new projectMaterialsKpis(material));
        this.user_kpis = attrs.user_kpis ? attrs.user_kpis.map( user => new User(user)) : [];
    }
}

class projectMaterialsKpis {
    material: Material;
    total_installed_quantity: number;

    constructor(attrs: any) {
        this.material = new Material(attrs.material);
        this.total_installed_quantity = attrs.total_installed_quantity;
    }
}

class Luminaire_kpis {
    project_installation_goal: number;
    total_installed_luminaires: number;
    project_installation_goal_percentage: number;

    constructor(attrs: any) {
        this.project_installation_goal = attrs.project_installation_goal;
        this.total_installed_luminaires = attrs.total_installed_luminaires;
        this.project_installation_goal_percentage = attrs.project_installation_goal_percentage || 0;
    }
}