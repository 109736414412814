import { Component, OnInit, Input } from '@angular/core';
import { Requisition } from 'app/models/requisition';
import { RequisitionService } from '../requisition.service';
import { Toastr } from 'app/shared/toastr/toastr.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html'
})
export class HistoryComponent implements OnInit {
  @Input() requisitions: Requisition[];

  constructor(
    private requisitionService: RequisitionService,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.requisitionService.getRequisitionsHistory()
    .subscribe((rq:Requisition[]) => {
      this.requisitions = rq;
    }, error => {
      this.toastr.error(error);
    })
  }

  clickCollapsed(requisition: Requisition) {
    if (!requisition.items.length) {
      this.requisitionService.getRequisition(requisition.id)
      .subscribe((rq: Requisition) => {
        requisition.items = rq.items;
        requisition.isCollapsed = !requisition.isCollapsed;
      }, error => {
        this.toastr.error(error);
      })
    } else {
      requisition.isCollapsed = !requisition.isCollapsed;
    }
  }
}
