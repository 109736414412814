import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { LuminaireProjectWattage } from '@app/models/project_wattage';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LuminairesPendingService {

  constructor(private httpClient: HttpClient) { }

  getLuminairesPending(page: number, q?:string):Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('q', q)
      .set('page', page.toString());
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/luminaires/pending_installation`, { params: params });
  }
  getCircuitsPending(page: number, q?:string):Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('q', q)
      .set('page', page.toString());
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/circuits/pending_installation`, { params: params });
  }

}
