import { Component, OnInit } from '@angular/core';
import { Toastr } from '@shared/toastr/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';

import { UsersService } from './users.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GenericTable } from '@app/models/generic-table';
import { Users } from './users';

@Component({
  selector: 'user-component',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [UsersService]
})

export class UsersComponent implements OnInit {

  usersSearchForm: FormGroup;
  users: GenericTable<Users>;

  constructor(
    private usersService: UsersService,
    private toastr: Toastr,
    private fb: FormBuilder
  ) {
    this.usersSearchForm = fb.group({
      rol_type: "",
      is_active: "",
    });
  }

  ngOnInit() {
    this.getUsers(0);
  }

  getUsers(page) {
    this.usersService.getUsers(this.usersSearchForm.getRawValue(), page).subscribe(response => {
          this.users = response;
          this.toastr.success('Usuarios');
        },
        (error) => {
          this.toastr.error(error);
        }
      );
  }
  onChange(event:boolean, id:string){
    if(event){
      this.usersService
        .reactiveUser(id)
        .subscribe(response => {
          this.toastr.success('Se actualizo correctamente.');
        }, error => {
          this.toastr.error(error);
        });
    } else {
      this.usersService
        .destroyUser(id)
        .subscribe(response => {
          this.toastr.success('Se actualizo correctamente.');
        }, error => {
          this.toastr.error(error);
        });
    }
  }

  pageChange(page) {
    this.getUsers(page-1);
  }
}
