export interface RolInterface {
    key: string,
    name: string
  }
  
  export class Rol implements RolInterface {
    key: string;
    name: string;
  
    constructor() {
      this.key = '';
      this.name = '';
    }

    getName(clave:any): string {
        let roles: Array<object> = [];
        roles.push({ key: '0', name: 'Administrador'});
        roles.push({ key: '1', name: 'Ing. de proyecto'});
        roles.push({ key: '2', name: 'Residente'});
        roles.push({ key: '3', name: 'Diseño'});
        roles.push({ key: '4', name: 'Almacén'});
        roles.push({ key: '5', name: 'Call Center'});
        roles.push({ key: '6', name: 'Supervisor de alumbrado público'});
        roles.push({ key: '7', name: 'Cliente'});
        roles.push({ key: '8', name: 'Supervisor de cuadrilla'});
        roles.push({ key: '9', name: 'Oficial de cuadrilla'});
        roles.push({ key: '10', name: 'Compras'});
        let result = roles.find( (element) => {
            return element['key'] == clave.name
        })
        return result['name']
    }
  }