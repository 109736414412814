import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Toastr } from '@shared/toastr/toastr.service';
import { TasksService } from '../tasks/tasks.service';
import { SurveyArea } from '@models/survey-area';
import { Lamppost } from '@models/lamppost';
import { SurveyAreaPayload } from './survey-area-payload';
import { DeleteMenu } from '@shared/components/map-delete-node.component';
import * as Driver from 'driver.js';
import { StyleMap } from '@models/styleMap';
import { CurrentProjectService } from '@shared/cookies/current-project.service';

@Component({
  selector: 'app-survey-areas',
  templateUrl: './survey-areas.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [TasksService, CurrentProjectService]
})
export class SurveyAreasComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;
  driver: Driver = new Driver({
    animate: true,
    allowClose: false,
    keyboardControl: false
  });

  editMode = '';
  editingPolygon: google.maps.Polygon;
  lampposts: Lamppost[];
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  survey_area: SurveyArea;
  survey_areas: SurveyArea[];

  constructor(
    private toastr: Toastr,
    private tasksService: TasksService,
    private currentProjectService: CurrentProjectService
  ) { }

  ngOnInit() {
    this.getSurveyAreas();
  }

  getSurveyAreas() {
    this.tasksService.getSurveyAreas()
      .subscribe((surveyAreas: SurveyArea[]) => {
        this.survey_areas = surveyAreas;
        this.paintMap();
        this.addSurveyAreas();
      }, error => {
        this.toastr.error(error);
      });
  }

  private paintMap() {
    let project = this.currentProjectService.getCurrentProject();
    let latitude = 19.4326009;
    let longitude = -99.1333416;
    let zoom = 5;
    let request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    let mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    let service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })
  }






  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  clickEditMap(): void {
    if (this.editMode == '') {
      this.driver.highlight('#mapLuminaires');
      this.setCenterMap();
      this.editMode = 'edit';
    } else {
      this.driver.reset();
      this.editMode = '';
      if (this.editingPolygon)
        this.editingPolygon.setEditable(false);
    }
  }

  

  private addSurveyAreas() {
    let polygonCoords = [];
    let deleteMenu = new DeleteMenu();

    for (let survey_area of this.survey_areas) {
      if (!survey_area.points.length)
        continue
      polygonCoords = survey_area.points.map(point => new google.maps.LatLng(point.latitude, point.longitude));
      let myPolygon = new google.maps.Polygon({
        paths: polygonCoords,
        strokeColor: '#4885E6',
        strokeOpacity: 0.8,
        fillColor: '#4885E1',
        fillOpacity: 0.25,
        map: this.map
      });
      myPolygon.addListener('click', (event) => { this.clickPoligon(myPolygon) });
      myPolygon.getPath().addListener('set_at', (event) => { this.changePolygon(myPolygon, survey_area) });
      myPolygon.getPath().addListener('insert_at', (event) => { this.changePolygon(myPolygon, survey_area) });
      myPolygon.getPath().addListener('remove_at', (event) => { this.changePolygon(myPolygon, survey_area) });
      polygonCoords = [];
      myPolygon.addListener('rightclick', function (e) {
        if (e.vertex == undefined)
          return;
        deleteMenu.open(this.map, myPolygon.getPath(), e.vertex);
      });
    }
  }

  private changePolygon(polygon: google.maps.Polygon, survey_area: SurveyArea) {
    // let array = [];
    // var len = polygon.getPath().getLength();

    // for (var i = 0; i < len; i++) {
    //   array.push(polygon.getPath().getAt(i).toUrlValue(5));
    // }
    const survey = new SurveyArea(polygon);
    survey.id = survey_area.id;
    const requestPayload = new SurveyAreaPayload(survey);
    this.tasksService
      .updateSurveyAreas(requestPayload)
      .subscribe(
        (response: any) => {
          this.toastr.success('Coordenadas actualizadas.');
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  private clickPoligon(polygon: google.maps.Polygon) {
    if (this.editMode == '')
      return;
    if (this.editingPolygon ==  polygon) {
      polygon.setEditable(false);
      this.editingPolygon = null;
    } else if (this.editingPolygon) {
      polygon.setEditable(true);
      this.editingPolygon.setEditable(false);
      this.editingPolygon = polygon;
    } else {
      polygon.setEditable(true);
      this.editingPolygon = polygon;
    }
  }



  private setCenterMap() {
    setTimeout(() => {
      let element = document.getElementById('driver-highlighted-element-stage');
      element.scrollIntoView(false);
      element.scrollIntoView({ block: "center" });
      element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }, 0);
  }

}
