import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TableauAuthService {
  constructor(private http: HttpClient) {}

  getJwt(): Observable<{ token: string }> {
    return this.http.get<{ token: string }>(`${environment.SERVER_URL}/tableau_auth`);
  }
}