import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Toastr } from '@shared/toastr/toastr.service';
import { LuminairesPendingService } from './luminaires-pending.service';
import { LuminaireProjectWattage, CircuitProjectWattage } from '@models/project_wattage';
import { Pagination } from '@app/models/pagination';
class SearchValues {
  q?: string
}

@Component({
  selector: 'app-luminaires-pending-installation',
  templateUrl: './luminaires-pending-installation.component.html',
  styles: [`.active{ background-color: rgb(186, 186, 186); }`]
})
export class LuminairesPendingInstallationComponent implements OnInit {
  luminairesSearchForm: FormGroup;
  circuitsSearchForm: FormGroup;
  pagination: Pagination;
  showMap = false;
  luminaires: LuminaireProjectWattage[] = [];
  circuits: CircuitProjectWattage[] = [];
  luminairesAll: LuminaireProjectWattage[] = [];
  circuitsAll: CircuitProjectWattage[] = [];
  txtTitle = 'Luminarias';
  currentPage = 1;
  showSpinner = false;

  constructor(
    private router: Router,
    private toastr: Toastr,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private luminairesPendingService: LuminairesPendingService
  ) {
    this.luminairesSearchForm = fb.group({
      q: [''],
    });
    this.circuitsSearchForm = fb.group({
      q: [''],
    });
   }

  ngOnInit() {

    this.route.queryParamMap.subscribe(queries => {
      this.currentPage = queries.get('page') ? +queries.get('page') : 1;
      const q = queries.get('q') ? queries.get('q') : '';
      this.txtTitle = queries.get('type') ? queries.get('type') : 'Luminarias';
      this.populateForm(this.currentPage,q);
      if(this.txtTitle == 'Circuitos'){
        this.getCircuitsPending(this.currentPage, q);
        this.getCircuitsPendingAll(q);
      } else {
        this.getLuminairesPending(this.currentPage, q);
        this.getLuminairesPendingAll(q);
      }
    });
  }

  private populateForm (currentPage: number, q: string){
    this.luminairesSearchForm.patchValue({
      q: q
    });
    this.circuitsSearchForm.patchValue({
      q: q
    });
  }

  getLuminairesPending(currentPage: number, q: string){
    this.showSpinner = true;
    this.luminairesPendingService
      .getLuminairesPending(currentPage, q)
      .subscribe( (response: any) => {
        this.luminaires = response.luminaires.map( luminaire => new LuminaireProjectWattage(luminaire));
        this.pagination = response.meta.pagination;
        if(this.showMap){
          this.showMap = false;
          window.setTimeout(() => {
            this.showMap = true;
          }, 0);
        }
        this.showSpinner = false;
      }, error => {
        this.toastr.error(error);
      });
  }

  getCircuitsPending(currentPage: number, q: string){
    this.showSpinner = true;
    this.luminairesPendingService
      .getCircuitsPending(currentPage, q)
      .subscribe( (response: any) => {
        this.circuits = response.circuits.map( circuit => new CircuitProjectWattage(circuit));
        this.pagination = response.meta.pagination;
        if(this.showMap){
          this.showMap = false;
          window.setTimeout(() => {
            this.showMap = true;
          }, 0);
        }
        this.showSpinner = false;
      }, error => {
        this.toastr.error(error);
      });
  }

  getLuminairesPendingAll(q:string){

    this.luminairesPendingService
      .getLuminairesPending(0, q)
      .subscribe( (response: any) => {
        this.luminairesAll = response.luminaires.map( luminaire => new LuminaireProjectWattage(luminaire));
        if(this.showMap){
          this.showMap = false;
          window.setTimeout(() => {
            this.showMap = true;
          }, 0);
        }
      }, error => {
        this.toastr.error(error);
      });
  }

  getCircuitsPendingAll(q:string){
    this.luminairesPendingService
      .getCircuitsPending(0, q)
      .subscribe( (response: any) => {
        this.circuitsAll = response.circuits.map( circuit => new CircuitProjectWattage(circuit));
        if(this.showMap){
          this.showMap = false;
          window.setTimeout(() => {
            this.showMap = true;
          }, 0);
        }
      }, error => {
        this.toastr.error(error);
      });
  }

  onSearchPress({ value }: { value: SearchValues }) {
    this.router.navigate(['/u/installation/pending'],
    {
      relativeTo: this.route,
      queryParams: {
        page: this.currentPage.toString(),
        q: value.q,
        type: this.txtTitle
      }
    });
  }

  changeTab(changeTab: any){
    this.txtTitle = changeTab.nextId;

    this.router.navigate(['/u/installation/pending'], {
      relativeTo: this.route,
      queryParams: {
        page: '1',
        q: '',
        type: this.txtTitle
      }
    });
  }

  pageChange(page: number) {
    this.currentPage = page;
    this.router.navigate(['/u/installation/pending'],
    {
      relativeTo: this.route,
      queryParams: {
        page: this.currentPage.toString(),
        q: this.luminairesSearchForm.get('q').value,
        type: this.txtTitle
      }
    });
  }

}
