import { Material } from "./material";

interface ItemInterface{
    id: string
    quantity: number
    material: Material
    troop_minimum: number
}
export class Item implements ItemInterface {
    id: string
    quantity: number
    material: Material
    troop_minimum: number

  constructor(attrs: any) {
    this.id = attrs.id;
    this.quantity = attrs.quantity;
    this.material = new Material(attrs.material);
    this.troop_minimum = attrs.troop_minimum;
  }
}
