import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CurrentAndUsedMaterials,
  OficialDashboard,
  TaskMarkers,
  TaskMaterial,
} from '@app/models/operation-dashboard';
import { ChartOptions } from 'chart.js';
import { OperationDasboardService } from '../operation-dasboard.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { VehiclesLocation } from '@app/models/vehicles-location';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

/*si hay mas de 4 caps agregar los colores que se desean utilizar de lo contrario abra problema de indexeo*/
enum getColor {
  '#0000FF',
  '#0B6121',
  '#FE2E2E',
  '#FF0080',
}

@Component({
  selector: 'app-individual-dashboard',
  templateUrl: './individual-dashboard.component.html',
  styleUrls: ['./individual-dashboard.component.scss'],
  providers: [MapFuncAuxService],
})



export class IndividualDashboardComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;
  oficial: OficialDashboard;
  // coordinatesPolyLine = [];
  polylines: [] = [];
  drawingManager: any;
  hidden: boolean = false;
  map: google.maps.Map;
  task_markers: TaskMarkers[] = [];
  materialChart: TaskMaterial[] = [];
  mchart = Highcharts;
  public materialChartOptions = true;
  public usedAndAllmaterialChartOptions: any;
  public cableChart: any;
  chart: object;
  currentAndUsedMaterials: CurrentAndUsedMaterials;
  recoverMaterials: [{ name: string, quantity: number }];
  namesToIf: string;
  lstInfowindows = [];
  vehicles: VehiclesLocation[];
  startDate;
  // endDate;
  barChartPlugins = [pluginDataLabels];

  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [{}],
    },
    plugins: {
      datalabels: {
        color: 'black',
        anchor: 'center',
        align: 'center',
        font: {
          size: 10,
        },
        formatter: (value) => {
          return value + '%';
        },
      },
    },
  };

  barChartOptionsOficials: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [{}],
    },
    plugins: {
      datalabels: {
        color: 'black',
        anchor: 'center',
        align: 'center',
        font: {
          size: 10,
        },
        formatter: (value) => {
          return value + '%';
        },
      },
    },
  };

  constructor(
    private operationalService: OperationDasboardService,
    private activedRoute: ActivatedRoute,
    private mapFuncAux: MapFuncAuxService,
    private currentProjectService: CurrentProjectService
  ) {
  }

  ngOnInit() {
    const user_id = this.activedRoute.snapshot.paramMap.get('id');
    this.paintMap();
    this.paintSingleMarker(user_id);
  }




  paintSingleMarker(user_id) {
    this.operationalService
      .getOficialDashboard(user_id,
        // tslint:disable-next-line:max-line-length
        (this.startDate === null || this.startDate === undefined ? '' : (this.startDate.month + '/' + this.startDate.day + '/' + this.startDate.year)))
      .subscribe((response) => {
        // console.log(response.recoverMaterials, 'datos iniciales');

        // response.recoverMaterials.forEach(item => { this.recoverMaterials.push() });
        // if(response.recoverMaterials.length > 0) {
        this.recoverMaterials = response.recoverMaterials;
        this.recoverMaterialsRepair();
        // } else {
        //   this.recoverMaterials.length = 1;
        // }
        this.oficial = response;
        this.hidden = true;
        const markers = response.markers.map(
          (marker) => new TaskMarkers(marker)
        );
        this.vehicles = response.gEOTAB_Vehicles.map(x => new VehiclesLocation(x));
        this.task_markers = markers;
        this.showMarkers(markers);
        this.paintvehicles();
        this.setMaterialGraph(
          response.materials_used,
          response.materials_avalibable

        );

      });

  }

  remove_polylines() {
    for (let index = 0; index < this.vehicles.length; index++) {
      this.vehicles[index].polyline.setMap(null);
    }
  }

  paintvehicles() {
    let index = 0;
    for (const vehicle of this.vehicles) {
      vehicle.paintMarker(this.map);
      vehicle.generate_polyline(this.map, getColor[index]);
      vehicle.marker.addListener('click', () => {
        this.showInfoWindowVechiel(vehicle);
      });
      index++;
    }
  }


  filterRouteVehicle() {
    this.paintMap();
    const user_id = this.activedRoute.snapshot.paramMap.get('id');
    this.task_markers = [];
    this.operationalService
      .getOficialDashboard(user_id,
        // tslint:disable-next-line:max-line-length
        this.startDate === null || this.startDate === undefined ? '' : (this.startDate.month + '/' + this.startDate.day + '/' + this.startDate.year))
      .subscribe((response) => {
        // if(response.recoverMaterials.length > 0) {
        this.recoverMaterials = response.recoverMaterials;
        this.recoverMaterialsRepair();
        // } else {
        //   this.recoverMaterials.length = 1;
        // }

        this.oficial = response;
        const markers = response.markers.map(
          (marker) => new TaskMarkers(marker)
        );
        this.vehicles = response.gEOTAB_Vehicles.map(x => new VehiclesLocation(x));
        this.task_markers = markers;
        this.showMarkers(markers);
        this.paintvehicles();
        this.setMaterialGraph(
          response.materials_used,
          response.materials_avalibable
        );
      });
  }



  private paintMap() {
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const project = this.currentProjectService.getCurrentProject();
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    this.map = new google.maps.Map(
      this.gmapElement.nativeElement,
      mapProperties
    );
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    });
    this.map.addListener('click', () => {
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    });
  }

  onShowTaskInfo(task_id) {
    if (this.task_markers) {
      this.hideMarkers(this.task_markers);
    }

    this.operationalService.getTaskMarkers(task_id).subscribe((response) => {
      const markers = response.taskMarker.map(
        (marker) => new TaskMarkers(marker)
      );
      this.showMarkers(markers);
      this.task_markers = markers;
      this.materialChart = response.currentMaterial;


      const names = [];
      const quantity = [];
      for (let i = 0; i < this.materialChart.length; i++) {
        names.push(this.materialChart[i].name);
        quantity.push(this.materialChart[i].allQuantities);
      }

      // this.mChart(names, quantity);
      this.allAndUsedMChart(names, quantity, null);
      this.materialChartOptions = !this.materialChartOptions;
      //  }
    });
  }

  showMarkers(luminaires: TaskMarkers[]) {
    luminaires.forEach((luminaire) => {
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.showInfoWindowLum(luminaire);
      });
    });
  }



  hideMarkers(luminaires: TaskMarkers[]) {
    luminaires.forEach((luminaire) => {
      luminaire.hideMarker();
    });
  }




  setMaterialGraph(
    used_material: [{ usedName: string; usedQuantities: number }],
    avalibable_materials: [{ name: string; allQuantities: number }]
  ) {
    this.currentAndUsedMaterials = new CurrentAndUsedMaterials({
      currentMaterial: avalibable_materials,
      usedMaterial: used_material,
    });

    // console.log(avalibable_materials, used_material)
    const allNames = [];
    const allQuantities = [];
    const usedQuantities = [];

    const allCables = [];
    const cablesQuantities = [];
    const usedCablesQuantities = [];
    avalibable_materials.forEach((material) => {
      if (!material.name.includes('CABLE')) {
        allNames.push(material.name);
        const currentUsed = used_material.filter(
          (x) => x.usedName === material.name
        )[0];

        if (currentUsed) {
          allQuantities.push(
            material.allQuantities - currentUsed.usedQuantities
          );
          usedQuantities.push(currentUsed.usedQuantities);
        } else {
          allQuantities.push(material.allQuantities);
          usedQuantities.push();
        }
      } else {
        allCables.push(material.name);
        const currentUsed = used_material.filter(
          (x) => x.usedName === material.name
        )[0];
        if (currentUsed) {
          cablesQuantities.push(
            material.allQuantities - currentUsed.usedQuantities
          );
          usedCablesQuantities.push(currentUsed.usedQuantities);
        } else {
          cablesQuantities.push(material.allQuantities);
          usedCablesQuantities.push();
        }
      }
    });

    // console.log(allNames, allQuantities, usedQuantities);

    this.allAndUsedMChart(allNames, allQuantities, usedQuantities);

    this.onlyCableChart(allCables, cablesQuantities, usedCablesQuantities);
  }

  assingTurnToOficial(event) {
    this.operationalService.saveTurnOfficial(this.activedRoute.snapshot.paramMap.get('id'), event.target.value).subscribe(respose => {
      this.operationalService
        .getOficialDashboard(this.activedRoute.snapshot.paramMap.get('id'),
          // tslint:disable-next-line:max-line-length
          (this.startDate === null || this.startDate === undefined ? '' : (this.startDate.month + '/' + this.startDate.day + '/' + this.startDate.year)))
        .subscribe((response) => {

          this.recoverMaterials = response.recoverMaterials;
          this.recoverMaterialsRepair();
          this.oficial = response;
          this.hidden = true;
          const markers = response.markers.map(
            (marker) => new TaskMarkers(marker)
          );
          this.vehicles = response.gEOTAB_Vehicles.map(x => new VehiclesLocation(x));
          this.task_markers = markers;
          this.showMarkers(markers);
          this.paintvehicles();
          this.setMaterialGraph(
            response.materials_used,
            response.materials_avalibable

          );

        });
    });
  }

  recoverMaterialsRepair() {
    const categories = [], allQuantities = [];
    this.recoverMaterials.forEach(item => {
      categories.push(item.name);
      allQuantities.push(item.quantity);
    });


    const recoverMaterialChart = Highcharts.chart('recoverMaterials', {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        gridLineWidth: 0,
        min: 0,
        labels: {
          overflow: 'justify',
        },
        title: {
          text: '',
        },
      },
      legend: {
        reversed: true
      },
      plotOptions: {
        bar: {
          stacking: 'normal',
          dataLabels: {
            color: 'black',
            enabled: true,
            style: {
              fontWeight: 'normal'
            },
          },
        },
      },
      credits: {
        enabled: false
      },
      series: []
    });
    recoverMaterialChart.addSeries({
      name: 'Quantity',
      type: undefined,
      showInLegend: false,
      data: allQuantities,
      color: 'rgba(134, 199, 243, 0.5)',
    });
  }


  allAndUsedMChart(allNames, allQuantities, usedQuantities?) {
    const chart = Highcharts.chart('usedAndAllmaterialChartOptions', {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: allNames,
      },
      yAxis: {
        gridLineWidth: 0,
        min: 0,
        labels: {
          overflow: 'justify',
        },
        title: {
          text: '',
        },
      },
      legend: {
        reversed: true,
      },

      plotOptions: {
        bar: {
          stacking: 'normal',
          dataLabels: {
            color: 'black',
            enabled: true,
            style: {
              fontWeight: 'normal',
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [],
    });

    if (allQuantities && usedQuantities) {
      chart.addSeries({
        name: 'Quantity',
        type: undefined,
        showInLegend: false,
        data: allQuantities,
        color: 'rgba(134, 199, 243, 0.5)',
      });

      chart.addSeries({
        name: 'Used Items Quantity',
        type: undefined,
        showInLegend: false,
        data: usedQuantities,
        color: 'rgba(255, 99, 132, 0.6)',
      });
    } else {
      chart.addSeries({
        name: 'Quantity',
        type: undefined,
        showInLegend: false,
        data: allQuantities,
        color: 'rgba(255, 99, 132, 0.6)',
      });
    }
    chart.reflow();
    console.log(chart)
    this.usedAndAllmaterialChartOptions = chart;


  }

  private onlyCableChart(allCables, cablesQuantities, usedCablesQuantities) {
    this.cableChart = Highcharts.chart('cableChart', {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: allCables,
      },
      yAxis: {
        gridLineWidth: 0,
        min: 0,
        labels: {
          overflow: 'justify',
        },
        title: {
          text: '',
        },
      },
      legend: {
        reversed: true,
      },

      plotOptions: {
        bar: {
          stacking: 'normal',
          dataLabels: {
            color: 'black',
            enabled: true,
            style: {
              fontWeight: 'normal',
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Quantity',
          type: undefined,
          showInLegend: false,
          data: cablesQuantities,
          color: 'rgba(134, 199, 243, 0.5)',
        },
        {
          name: 'Used Items Quantity',
          type: undefined,
          showInLegend: false,
          data: usedCablesQuantities,
          color: 'rgba(255, 99, 132, 0.6)',
        },
      ],
    });
  }

  private showInfoWindowLum(luminaire: TaskMarkers) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const strInfo = `
    <div>

      <div>
        <p class='mb-0'> <strong> No. Luminaria: </strong> </p>
      </div>
      <div>
        <p>${luminaire.reference_id}</p>
      </div>
      <div>
        <p class='mb-0'> <strong> Tiempo de Reparación: </strong> </p>
      </div>
      <div>
        <p>${luminaire.time}</p>
      </div>

      <div class='d-flex justify-content-between'>
        <div class='mr-1'>
          <a href='u/luminaires/${luminaire.luminaire_id}' onclick='return false;'>
            <input id='seeMoreLuminaire' type='button' style=' cursor:pointer;' value='Ver mas' class='oe--button--info'>
          </a>
          ${luminaire.repair_id ? `
          <a href="u/repairs/${luminaire.repair_id}" onclick="return false;">
            <input id="seeRepair" type="button" style="cursor:pointer;" value="Ver Reparación" class="oe--button--info">
          </a>
        ` : ''}
        </div>
        <div>
        </div>
      </div>
    </div>
    `;
    const infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340,
    });
    infowindow.open(this.map, luminaire.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      const btnSelected = document.getElementById('btnSelected');
      const btnDesSelected = document.getElementById('btnDesselected');
      const seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
      let btnSeeRepair = document.getElementById('seeRepair');
      if (btnSelected) {
        btnSelected.addEventListener('click', () =>
          this.checkStatus(luminaire)
        );
      }
      if (btnDesSelected) {
        btnDesSelected.addEventListener('click', () =>
          this.luminaireSelect(luminaire)
        );
      }
      seeMoreLuminaire.addEventListener('click', () =>
        this.mapFuncAux.seeMoreLuminaire(luminaire.luminaire_id)
      );
      btnSeeRepair.addEventListener('click', () =>
        this.mapFuncAux.seeMoreRpu(luminaire.repair_id)
      );
    }, 500);
  }

  private luminaireSelect(luminaire: any): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    luminaire.isSelected = !luminaire.isSelected;
    luminaire.event = 'normal';
    luminaire.upadeIcon();
  }
  private checkStatus(luminaire: any) {
    if (luminaire.status !== 5) {
      this.luminaireSelect(luminaire);
    }
  }

  private showInfoWindowVechiel(Vehicles: VehiclesLocation) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const strInfo = `
    <div>

      <div>
        <p class='mb-0'> <strong> No. Vehicle: </strong> </p>
      </div>
      <div>
        <p>${Vehicles.name}</p>
      </div>
      <div class='d-flex justify-content-between'>
        <div class='mr-1'>
          <a href='u/troops/${Vehicles.troop_id}' onclick='return false;'>
            <input id='seeMoreVehicles' type='button' style=' cursor:pointer;' value='Ver mas' class='oe--button--info'>
          </a>
        </div>
        <div>
        </div>
      </div>
    </div>
    `;
    const infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340,
    });
    infowindow.open(this.map, Vehicles.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      const btnSelected = document.getElementById('btnSelected');
      const seeMoreVehicles = document.getElementById('seeMoreVehicles');
      if (btnSelected) {
        btnSelected.addEventListener('click', () =>
          this.checkStatus(Vehicles)
        );
      }
      seeMoreVehicles.addEventListener('click', () =>
        this.mapFuncAux.seeMoreVehicles(Vehicles.troop_id)
      );
    }, 500);
  }
}

