import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class RepairService {
  reoir_id: string;
  constructor(
    private httpClient: HttpClient
  ) { }

  editReapir(form: any) {
    return this.httpClient.post(
      `${environment.SERVER_TASKS}/api/Repairs/Update_Repair`, form
    );
  }

  nextRepair(repair_id: string, is_next: string) {
    const httpParams = new HttpParams()
    .set('repair_id', repair_id)
    .set('is_next', is_next);
    return this.httpClient.get<string>(
      `${environment.SERVER_TASKS}/api/Repairs/Get_Repairs`, {params: httpParams}
      ).pipe(
      map(elements => repair_id = elements)
    );
  }
}
