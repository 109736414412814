export enum LamppostHeightEnum {
    '  5',
	'  6',
    '  7',
    '  8',
    '  9',
    '  10',
    '  11',
    '  12',
    '  13',
    '  14',
    '  Otro'
}