import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GenericTable } from '@app/models/generic-table';
import { SingleReportRpu } from './report-rpu';
import { AuthService } from '@app/shared/auth/auth.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportRpuService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  Get_InfotmationRpus(rpu, fechaInicial, fechaFinal, page) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const httpParams = new HttpParams()
      .set('rpu', rpu)
      .set('fechaInicial', fechaInicial)
      .set('fechaFinal', fechaFinal)
      .set('page', page)
    return this.httpClient.get<GenericTable<SingleReportRpu>>(
      `${environment.SERVER_ENERGY}/api/ExportRPUSXLS/Get_InfotmationRpus`, { params: httpParams, headers: httpHeaders }
    );
  }

  downloadReportPeriodicRpusXLS(rpu, fechaInicial, fechaFinal) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const httpParams = new HttpParams()
    .set('rpu', rpu)
    .set('fechaInicial', fechaInicial)
    .set('fechaFinal', fechaFinal)
    .set('year', fechaFinal);

    this.httpClient.get(
      // tslint:disable-next-line:max-line-length
      `${environment.SERVER_ENERGY}/api/ExportRPUSXLS/Download_XLSX_Rpu_PeriodicInfo`, { responseType: 'blob', params: httpParams, headers: httpHeaders }
    ).subscribe(file => {
      this.downloadProcess(file, `Detalle-RPU-Periodico.xlsx`);
    });
  }

  downloadReportHistoricRpusXLS(rpu, fechaInicial, fechaFinal) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const httpParams = new HttpParams()
    .set('rpu', rpu)
    .set('fechaInicial', fechaInicial)
    .set('fechaFinal', fechaFinal)
    .set('year', fechaFinal);

    this.httpClient.get(
      // tslint:disable-next-line:max-line-length
      `${environment.SERVER_ENERGY}/api/ExportRPUSXLS/Download_Excel_RPUS`, { responseType: 'blob', params: httpParams, headers: httpHeaders }
    ).subscribe(file => {
      this.downloadProcess(file, `Detalle-RPU-Historico.xlsx`);
    });
  }

  private downloadProcess(file, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([file], { type: 'application/vnd.ms-excel' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }


}
