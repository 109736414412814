import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GenericTable } from '@app/models/generic-table';
import { environment } from '@environments/environment';
import { GuarantyClaim, DetailGuarantyClaim } from '@app/models/GuarantyClaim';
import { map } from 'rxjs/operators';
import { element } from 'protractor';
import { Observable } from 'rxjs';
import { AuthService } from '@app/shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuarantyClaimService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  // tslint:disable-next-line:max-line-length
  filter_GuarantyClaim(referenceReportNumber: string, model_luminaire: string, referenceLuminaire: string, startDate?: string, endDate?: string) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    // if (statusReport1 === '') {
    //   statusReport1 = 'false';
    // }
    // if (statusReport2 === '') {
    //   statusReport2 = 'false';
    // }
    // if (statusReport3 === '') {
    //   statusReport3 = 'false';
    // }
    const httpParams = new HttpParams()
      .set('referenceReportNumber', referenceReportNumber)
      .set('model_luminaire', model_luminaire)
      .set('referenceLuminaire', referenceLuminaire)
      // .set('statusReport1', statusReport1)
      // .set('statusReport2', statusReport2)
      // .set('statusReport3', statusReport3)
      .set('startDate', startDate)
      .set('endDate', endDate);


    return this.httpClient.get<GenericTable<GuarantyClaim>>(
      `${environment.SERVER_TASKS}/api/GuarantyClaim/GetFilter_Guaranty_Claim`, { params: httpParams, headers: httpHeaders }
    ).pipe(
      map(elements => new GenericTable<GuarantyClaim>(elements))
    );
  }

  getList_GuarantyClaim(page: number) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const httpParams = new HttpParams().set('page', page.toString());
    return this.httpClient.get<GenericTable<GuarantyClaim>>(
      `${environment.SERVER_TASKS}/api/GuarantyClaim/GetList_Guaranty_Claim`, { params: httpParams, headers: httpHeaders }
    ).pipe(
      map(elements => new GenericTable<GuarantyClaim>(elements))
    );
  }

  get_GuarantyClaim(id_Guranty_Claim: string, is_luminaire: string) {
    const httpParams = new HttpParams()
      .set('id_guaranty_claims', id_Guranty_Claim)
      .set('is_luminaire', is_luminaire);
    return this.httpClient.get<DetailGuarantyClaim>(
      `${environment.SERVER_TASKS}/api/GuarantyClaim/Get_Guaranty_Claim`, { params: httpParams }
    ).pipe(
      map(elements => new DetailGuarantyClaim(elements))
    );
  }

  update_Claim(form) {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/GuarantyClaim/Accept_Guaranty_Claim`, form);
  }


  downloadLuminairesReport(form: any) {
    this.httpClient.post(
      `${environment.SERVER_TASKS}/api/GuarantyClaimsOpinionReport/Get_Report_Luminaire_Claim`, form, { responseType: 'blob' }
    ).subscribe(file => {

      this.downloadProcess(file, `Dictamen-Luminaria.pdf`, 'pdf');
    }, error => {
    });
  }

  downloadPhotocellsReport(form: any) {
    this.httpClient.post(
      `${environment.SERVER_TASKS}/api/GuarantyClaimsOpinionReport/Get_Report_Fotocelda_Claim`, form, { responseType: 'blob' }
    ).subscribe(file => {

      this.downloadProcess(file, `Reporte-Fotoceldas.pdf`, 'pdf');
    }, error => {
    });
  }

  private downloadProcess(file, fileName, extension) {
    let newBlob;

    if (extension === 'pdf') {
      newBlob = new Blob([file], { type: 'application/pdf' });
    } else {
      newBlob = new Blob([file], { type: 'application/vnd.google-earth.kml+xml' });
    }

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

}
