import { Component, OnInit } from '@angular/core';
import { SmartDashboard } from '@app/models/smart_dashboard';
import { SmartDashboardService } from './smart-dashboard.service';

@Component({
  selector: 'app-smart-dashboard',
  templateUrl: './smart-dashboard.component.html',
  styleUrls: ['./smart-dashboard.component.scss']
})
export class SmartDashboardComponent implements OnInit {

  dashboard: SmartDashboard;

  width = '100%';
  height = 400;
  typeKwhs = 'scrollstackedcolumn2d';
  typeGroup = 'scrollbar2d';
  typeAlarm = 'pie2d';
  typeCost = 'line';
  dataFormat = 'json';
  KwhsDataSource;
  GroupDataSource;
  deviceScheduleSource;
  CostDataSource;


  constructor(private service: SmartDashboardService) { }

  ngOnInit() {
    this.service.getDashboard().subscribe(res => {
      this.dashboard = res;

      // const alarmsPerGroup = {
      //   chart: {
      //     caption: "Alarms Per Group",
      //     plottooltext: "$dataValue Alarms",
      //     palettecolors: "#86C7F3",
      //     theme: "fusion"
      //   },
      //   categories: [
      //     {
      //       category: this.dashboard.alarmsPerGroupLabel
      //     }
      //   ],
      //   dataset: [
      //     {
      //       data: this.dashboard.alarmsPerGroupValue
      //     }
      //   ]
      // };



      // const totalKwhs = {
      //   chart: {
      //     caption: "Kwh",
      //     flatscrollbars: "0",
      //     scrollheight: "12",
      //     numvisibleplot: "8",
      //     plottooltext:
      //       "<b>$dataValue</b> Kwhs $seriesName in $label",
      //     palettecolors: "#86C7F3,#FF9EB3",
      //     theme: "fusion"
      //   },
      //   categories: [
      //     {
      //       category: this.dashboard.categoryKwhs
      //     }
      //   ],
      //   dataset: [
      //     {
      //       seriesname: "Actual",
      //       data: this.dashboard.actualKwhs
      //     },
      //     {
      //       seriesname: "Expected",
      //       data: this.dashboard.expectedKwhs

      //     }
      //   ]

      // };



      const deviceSchedule = {
        chart: {
          caption: 'Horarios por equipos',
          plottooltext: '<b>$percentValue</b> $label',
          showlegend: '1',
          showpercentvalues: '1',
          legendposition: 'bottom',
          usedataplotcolorforlabels: '1',
          palettecolors: "#FFB47F,#7F9BD6,#BBBCBC,#CEF58B,#93F9F8,#F9F693,#F58B8B,#97D4C5",
          theme: 'fusion'
        },
        data: this.dashboard.deviceSchedule
      };
      // const costs = {
      //   chart: {
      //     caption: 'Monthly: ' + this.dashboard.lastCost + ' MXN',
      //     captionFontSize: 30,
      //     subcaption: 'Expected: ' + this.dashboard.expectedCost + ' MXN',
      //     subcaptionFontSize: 20,
      //     rotatelabels: '1',
      //     setadaptiveymin: '1',
      //     theme: 'fusion'
      //   },
      //   data: this.dashboard.costs
      // };

      if (this.dashboard) {
        // this.KwhsDataSource = totalKwhs;
        // this.GroupDataSource = alarmsPerGroup;
        this.deviceScheduleSource = deviceSchedule;
        // this.CostDataSource = costs;
      }
    });
  }

}
