import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecoveredMaterialsService } from '../recovered-materials.service';
import { MaterialCategoryResponse } from 'app/models/material_category-response';
import { FormGroup } from '@angular/forms';
import { ProjectMaterial } from 'app/models/project_material';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { ProjectRecoveryStock } from 'app/models/project_recovery_stock';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryService } from '../../inventory.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-recovered-material-category',
  templateUrl: './recovered-material-category.component.html'
})
export class RecoveredMaterialCategoryComponent implements OnInit {
  @ViewChild('contentMaterialModal') materialModal: any;
  currentId: string;
  project_recovery_stocks: ProjectRecoveryStock[] = [];
  currentProjectRecoveryStock: ProjectRecoveryStock;
  materialForm: FormGroup;
  emptyMaterial: ProjectMaterial;
  projectName = 'Material';
  totalRef: number = 0;
  showMessage: boolean = false;

  constructor(
    private toastr: Toastr,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private recoveredMaterialsService: RecoveredMaterialsService,
    private inventoryService: InventoryService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.currentId = params.get('id');
      this.getCategory();
    });
  }

  getCategory() {
    this.recoveredMaterialsService.getCategory(this.currentId)
      .subscribe((materialCategoryResponse: MaterialCategoryResponse) => {
        this.project_recovery_stocks = materialCategoryResponse.material_category.project_recovery_stocks;
        this.project_recovery_stocks = this.project_recovery_stocks.sort((a, b) => a.material.name.localeCompare(b.material.name));
        this.projectName = materialCategoryResponse.material_category.name;
      }, error => {
        this.toastr.error(error);
      })
  }

  editMaterial(projectRecoveryStock: ProjectRecoveryStock) {
    for (let _projectRecoveryStock of this.project_recovery_stocks) {
      _projectRecoveryStock.material.editing = false;
    }
    this.totalRef = projectRecoveryStock.quantity;

    projectRecoveryStock.material.editing = !projectRecoveryStock.material.editing;
  }

  showMaterialModal(projectRecoveryStock: ProjectRecoveryStock) {
    this.currentProjectRecoveryStock = projectRecoveryStock;
    this.modalService.open(this.materialModal).result.then(result => {
    }, reason => {
      this.showMessage = false;
    });
  }

  saveTotal(projectRecoveryStock: ProjectRecoveryStock) {
    let bk = projectRecoveryStock.quantity;

    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    // tslint:disable-next-line:max-line-length
    const form = {
      material_id: projectRecoveryStock.material.id,
      removed_from: 2,
      quantity: this.totalRef - projectRecoveryStock.quantity,
      user_id: decodeToken['id'],
      cap_id: null
    };

    projectRecoveryStock.quantity = this.totalRef;

    this.inventoryService.registerLog(form).subscribe(response => {
      this.recoveredMaterialsService.updateProjectRecoveryStock(projectRecoveryStock)
        .subscribe(result => {
          this.toastr.success('Material actualizado.');
        }, error => {
          this.toastr.error(error);
          projectRecoveryStock.quantity = bk;
        });
    });

    projectRecoveryStock.material.editing = false;
  }

  operationToTotalMaterial(sum?: boolean) {
    if (sum) {
      return this.totalRef = this.totalRef + 10;
    }
    else if ((this.totalRef - 10) >= 0)
      this.totalRef = this.totalRef - 10
  }

  operationToTotal(element: any, sum?: boolean) {
    if (sum) {
      if ((parseInt(element.value) + 10) > this.currentProjectRecoveryStock.quantity)
        return this.showMessage = true;
      return element.value = parseInt(element.value) + 10;
    }
    else if ((parseInt(element.value) - 10) >= 0)
      element.value = parseInt(element.value) - 10;
    else
      element.value = 0;
    this.showMessage = false;
  }

  transferToInventory(value: number) {
    if (value > 0) {
      this.recoveredMaterialsService.transferToInventory(this.currentProjectRecoveryStock, value)
        .subscribe(result => {
          this.currentProjectRecoveryStock.quantity = this.currentProjectRecoveryStock.quantity - value;
          this.toastr.success('Se completo el envío al inventario.');
        }, error => {
          this.toastr.error(error);
        })
      this.modalService.dismissAll();
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }
}
