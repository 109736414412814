import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { TicketsStatus } from '@app/models/tickets-status';
import { TicketsStatsService } from './tickets-stats.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { GraphStats } from '@app/models/graph-stats';


@Component({
  selector: 'app-tickets-stats',
  templateUrl: './tickets-stats.component.html',
  styleUrls: ['./tickets-stats.component.scss']
})
export class TicketsStatsComponent implements OnInit {

  ReportsStatus: TicketsStatus[] = [];
  LuminairesStatus: TicketsStatus[] = []
  periodicity = 0;
  month = new Date().getMonth() + 1;
  year = new Date().getFullYear();
  yearFilter = new Date().getFullYear();
  day = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  years = [];
  months = [
    { value: 1, display: 'Enero' },
    { value: 2, display: 'Febrero' },
    { value: 3, display: 'Marzo' },
    { value: 4, display: 'Abril' },
    { value: 5, display: 'Mayo' },
    { value: 6, display: 'Junio' },
    { value: 7, display: 'Julio' },
    { value: 8, display: 'Agosto' },
    { value: 9, display: 'Septiembre' },
    { value: 10, display: 'Octubre' },
    { value: 11, display: 'Noviembre' },
    { value: 12, display: 'Diciembre' }
  ];
  showMontYear = false;
  showDatePicker = false;
  graphBarMonth: Graph;
  barStackedChartLabels: Label[];
  barStackedChartType: ChartType = 'bar';
  chartLegend = true;
  barStackedData: ChartDataSets[] = [];

  graphLineWeekly: Graph;
  lineChartLabels: Label[];
  lineChartType: ChartType = 'line';
  lineChartData: ChartDataSets[] = [];


  graphLineWeek: Graph;

  constructor(
    private ticketsStatusService: TicketsStatsService
  ) { }

  ngOnInit() {
    this.getYearsCombo();
    this.getTickets(this.periodicity, this.month, this.year, `${this.day.year}-${this.day.month}-${this.day.day}`);
    this.getLuminairesStatus(this.periodicity, this.month, this.year, `${this.day.year}-${this.day.month}-${this.day.day}`);
    this.getMonthStackedBarData(false, this.yearFilter, this.yearFilter);
    this.weekLine(this.graphLineWeekly);
  }

  getYearsCombo() {
    for (let i = 2018; i <= new Date().getFullYear(); i++) {
      this.years.push({ value: i, display: i })
    }
  }

  getTickets(filter: number, month: number, year: number, day: string) {
    this.ticketsStatusService.getTicketsStatus(filter, month, year, day).subscribe(response => {
      this.ReportsStatus = response;
    })
  }

  getLuminairesStatus(filter: number, month: number, year: number, day: string) {
    this.ticketsStatusService.getLuminaresStatus(filter, month, year, day).subscribe(response => {
      this.LuminairesStatus = response;
    })
  }

  getMonthStackedBarData(comparative: boolean, yearOne: number, yearTwo: number) {
    this.ticketsStatusService.getbarStackedGraph(comparative, yearOne, yearTwo).subscribe(respopnse => {
      this.monthStackedBar(respopnse);
    })
  }

  onClickFilter() {
    const day = `${this.day.year}-${this.day.month}-${this.day.day}`
    this.getTickets(this.periodicity, this.month, this.year, day);
    this.getLuminairesStatus(this.periodicity, this.month, this.year, day);
  }

  onChangeFilter() {
    this.getMonthStackedBarData(false, this.yearFilter, this.yearFilter);
  }

  onChageSearchFor() {
    console.log(this.periodicity)
    // tslint:disable-next-line:triple-equals
    if (this.periodicity == 0) {
      this.showDatePicker = false;
      this.showMontYear = false;
      // tslint:disable-next-line:triple-equals
    } else if (this.periodicity == 1) {
      this.showDatePicker = false;
      this.showMontYear = true;
      // tslint:disable-next-line:triple-equals
    } else if (this.periodicity == 2) {
      this.showDatePicker = true;
      this.showMontYear = false;
    }
  }

  monthStackedBar(graphStats: GraphStats) {
    // tslint:disable-next-line:max-line-length
    this.barStackedChartLabels = graphStats.labels;
    this.barStackedData = graphStats.series;
  }

  weekLine(graphData: Graph) {
    this.lineChartLabels = [
      'Sem 1', 'Sem 2', 'Sem 3', 'Sem 4', 'Sem 5', 'Sem 6',
      'Sem 1', 'Sem 2', 'Sem 3', 'Sem 4', 'Sem 5', 'Sem 6'];
    this.lineChartData = [
      {
        data: [
          0, 2, 3, 4, 5, 6,
          0, 2, 3, 4, 5, 6,
        ],
        label: 'Reportadas',
        backgroundColor: 'rgba(0,0,0,0)'
      }, {
        data: [
          4, 2, 5, 6, 1, 3,
          4, 2, 5, 6, 1, 3,
        ],
        label: 'Reparadas',
        backgroundColor: 'rgba(0,0,0,0)'
      }
    ]
  }
}

export class Graph {
  labels: string[];
  series: [{
    name: string,
    data: number[]
  }]

  constructor(attrs) {
    this.labels = attrs.labels,
      this.series = attrs.series
  }

}
