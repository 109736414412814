import { Component, OnInit, ViewChild } from '@angular/core';
import { TariffsService } from './tariffs.service';
import { GenericTable } from '@app/models/generic-table';
import { CfeTariffs } from '@app/models/cfe-tariffs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { ProjectService } from '@app/shared/projects/project.service';
import { EditUserService } from '@app/pages/users/edit-user/edit-user.service';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { type } from 'jquery';

@Component({
  selector: 'app-tarrifs',
  templateUrl: './tarrifs.component.html',
  styleUrls: ['./tarrifs.component.scss'],
  providers: [EditUserService]

})
export class TarrifsComponent implements OnInit {
  @ViewChild('contentMaterialModal') materialModal: any;
  @ViewChild('copyModal') copyModal: any

  tariffs: GenericTable<CfeTariffs>;
  // singleTariff: CfeTariffs;
  tarrifsCopy = []
  addTarrif: FormGroup;
  edit = false;
  projects = [];
  projectSelected;
  tariffsSearchForm: FormGroup;
  distributionDeseable = false;


  constructor(
    private tariffsService: TariffsService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toaster: Toastr,
    private projectService: EditUserService,
    private currentProjectService: CurrentProjectService
  ) {
    this.tariffsSearchForm = fb.group({
      name: '',
      tariffsType: '',
      month: '',
      year: -1
    });
  }

  ngOnInit() {
    this.addTarrif = this.fb.group({
      id: [null],
      type: [null, Validators.required],
      month: [null, Validators.required],
      year: [null, Validators.required],
      state: [{ value: null, disabled: true }, Validators.required],
      municipality: [{ value: null, disabled: true }, Validators.required],
      distribution: [null, Validators.required],
      fixed_cost: [null, Validators.required],
      // variable_energy: [null, Validators.required],
      name: [null, Validators.required],
      charge_factor: [null, Validators.required],
      variable_energy_base: [null, Validators.required],
      variable_energy_media: [null, Validators.required],
      variable_energy_punta: [null, Validators.required],
      capacity: [null, Validators.required]
    });
    this.paginatedTable(0);
    this.projectService.getProjects().subscribe(projectsResponse => {
      this.projects = projectsResponse.projects;
    });
  }

  paginatedTable(page: number) {
    this.tariffsService.getTariffs(this.tariffsSearchForm.getRawValue(), page).subscribe(response => {
      this.tariffs = response
    });
  }

  selectTarrif(tarrif_id: string) {
    const index = this.tariffs.elements.findIndex(x => x.id === tarrif_id);
    this.tariffs.elements[index].selected = !this.tariffs.elements[index].selected;
  }

  copyTariffs() {
    let show = false;
    this.tariffs.elements.forEach(element => {
      if (element.selected) {
        show = true;
      }
    });

    if (show) {
      this.modalService.open(this.copyModal, { size: 'lg', backdrop: 'static', keyboard: false })
    }
  }

  get getTarrifsCopy() {
    let total = 0;
    this.tariffs.elements.forEach(element => {
      if (element.selected) {
        total += 1
      }
    });

    if (total === 1) {
      return '1 Tarifa seleccionada'
    } else {
      return total + ' Tarifas seleccionadas'
    }
  }

  pageChange(page: number) {
    this.paginatedTable(page - 1);
  }

  showModal() {
    this.edit = false;
    this.addTarrif.reset();
    this.modalService.open(this.materialModal, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  onClose() {
    this.modalService.dismissAll();
  }


  getAddress(event) {
    this.tariffsService.getAddressData(event.target.value).subscribe(response => {
      this.addTarrif.patchValue({
        state: response.estado.toUpperCase(),
        municipality: response.municipio.toUpperCase()
      });
    }, error => {
      this.toaster.error(error);
      console.log(error);
    });
  }

  onEdit(tarrif: CfeTariffs) {
    this.edit = true;
    this.addTarrif.patchValue({
      id: tarrif.id,
      type: tarrif.type,
      month: tarrif.month,
      year: tarrif.year,
      state: tarrif.state,
      municipality: tarrif.municipality,
      distribution: tarrif.distribution,
      fixed_cost: tarrif.fixed_cost,
      // variable_energy: tarrif.variable_energy,
      name: tarrif.name,
      charge_factor: tarrif.charge_factor,
      variable_energy_base: tarrif.variable_energy_base,
      variable_energy_media: tarrif.variable_energy_media,
      variable_energy_punta: tarrif.variable_energy_punta,
      capacity: tarrif.capacity
    });
    this.changeTypeTariffEdit(tarrif.type);

    this.modalService.open(this.materialModal, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(result => {

    }, reason => {

    });
  }

  validateCero(event, control) {
    // this.toaster.singleError('La maxima es 1');
    if (parseInt(event.target.value, 0) > 1 && control === 'charge_factor') {
      this.addTarrif.patchValue({
        charge_factor: 1
      });
      return;
    }

    if (parseInt(event.target.value, 0) < 0) {
      if (control === 'distribution') {
        this.addTarrif.patchValue({
          distribution: 0
        });
      } else if (control === 'fixed_cost') {
        this.addTarrif.patchValue({
          fixed_cost: 0
        });
      } else if (control === 'variable_energy_base') {
        this.addTarrif.patchValue({
          variable_energy_base: 0
        });
      } else if (control === 'charge_factor') {
        this.addTarrif.patchValue({
          charge_factor: 0
        });
      }
    }
  }

  saveTarrifs() {
    this.tariffsService.addTariff(this.addTarrif.getRawValue()).subscribe(response => {
      this.paginatedTable(0);
      this.toaster.success('Se agrego correctamente');
      this.paginatedTable(0);
    }, error => {
      this.paginatedTable(0);
      this.toaster.error('Error al agregar la tarifa');
    })
    this.modalService.dismissAll();
  }

  editTariffs() {
    this.tariffsService.updateTariff(this.addTarrif.getRawValue()).subscribe(response => {
      this.toaster.success('Se modifico correctamente');
      this.paginatedTable(0);
    }, error => {
      this.paginatedTable(0);
      this.toaster.error('Error al modificar');
    });
    this.modalService.dismissAll();
  }

  onCopyTariffs() {
    const form = {
      tariffs_id: [],
      to_project_id: this.projectSelected
    };

    this.tariffs.elements.forEach(element => {
      if (element.selected) {
        form.tariffs_id.push(element.id);
      }
    });

    this.tariffsService.copyTarrifs(form).subscribe(response => {
      this.toaster.success('Se copiaron correctamente');
      this.paginatedTable(0);
    }, error => {
      this.toaster.error('Error al copiar las tarifas');
    });

    this.modalService.dismissAll();
  }

  private changeTypeTariffEdit(typeTariff) {
    if (typeTariff === 'APBT' || typeTariff === 'PDBT') {
      this.addTarrif.controls['distribution'].disable();
      this.addTarrif.controls['capacity'].disable();
      this.addTarrif.patchValue({
        distribution: 0,
        capacity: 0,
        variable_energy_punta: 0,
        variable_energy_media: 0
      });
    } else if (typeTariff === 'APMT') {
      this.addTarrif.controls['capacity'].disable();
      this.addTarrif.controls['distribution'].enable();
      this.addTarrif.patchValue({
        capacity: 0,
        variable_energy_punta: 0,
        variable_energy_media: 0
      });
    } else if (typeTariff === 'GDBT' || typeTariff === 'GDMTO') {
      this.addTarrif.controls['capacity'].enable();
      this.addTarrif.controls['distribution'].enable();
      this.addTarrif.patchValue({
        variable_energy_punta: 0,
        variable_energy_media: 0
      });
    } else {
      this.addTarrif.controls['capacity'].enable();
      this.addTarrif.controls['distribution'].enable();
    }


    this.generateNameTariff();
  }



  changeTypeTariff(typeTariff) {
    if (typeTariff.target.value === 'APBT' || typeTariff.target.value === 'PDBT') {
      this.addTarrif.controls['distribution'].disable();
      this.addTarrif.controls['capacity'].disable();
      this.addTarrif.patchValue({
        distribution: 0,
        capacity: 0,
        variable_energy_base: null,
        variable_energy_punta: 0,
        variable_energy_media: 0
      });
    } else if (typeTariff.target.value === 'APMT') {
      this.addTarrif.controls['capacity'].disable();
      this.addTarrif.controls['distribution'].enable();
      this.addTarrif.patchValue({
        distribution: null,
        capacity: 0,
        variable_energy_base: null,
        variable_energy_punta: 0,
        variable_energy_media: 0
      });
    } else if (typeTariff.target.value === 'GDBT' || typeTariff.target.value === 'GDMTO') {
      this.addTarrif.controls['capacity'].enable();
      this.addTarrif.controls['distribution'].enable();
      this.addTarrif.patchValue({
        distribution: null,
        capacity: null,
        variable_energy_base: null,
        variable_energy_punta: 0,
        variable_energy_media: 0
      });
    } else {
      this.addTarrif.controls['capacity'].enable();
      this.addTarrif.controls['distribution'].enable();
      this.addTarrif.patchValue({
        distribution: null,
        capacity: null,
        variable_energy_punta: null,
        variable_energy_media: null
      });
    }

    this.generateNameTariff();
  }

  changeMonth() {
    this.generateNameTariff();
  }

  changeYear() {
    this.generateNameTariff();
  }

  private generateNameTariff() {
    const project = this.currentProjectService.getCurrentProject()
    this.addTarrif.patchValue({
      name: project.name + '-' + this.addTarrif.controls['type'].value + '-' +
        this.addTarrif.controls['month'].value + '-' + this.addTarrif.controls['year'].value
    });
  }
}
