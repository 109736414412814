import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LuminairesInstallation_kpis } from '@app/models/quick-survey-kpis';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { KpiTaskService } from '../kpi-task.service';
import { InstallationKpi } from './installation-kpi';

@Component({
  selector: 'app-kpi-installation-task',
  templateUrl: './kpi-installation-task.component.html'
})
export class KpiInstallationTaskComponent implements OnInit {
  installationKpi: InstallationKpi
  LuminairesInstallation: LuminairesInstallation_kpis
  txtTitle: String = 'Instalación';
  dataUsercounts: number[] = [];
  chartOptions = {
    responsive: true
  };
  chartData = [
    {
      data: this.dataUsercounts,
      borderWidth: 1
    }
  ];
  chartColors: any[] = [
    {
      backgroundColor: ["#FF7360", "#6FC8CE", "#FAFFF2", "#FFFCC4", "#B9E8E0"]
    }
  ];
  chartLabels = [];
  repairTasksSearchForm: FormGroup;
  end_date = "";
  start_date = "";
  constructor(
    private kpiTaskService: KpiTaskService,
    private toastr: Toastr,
    public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.kpiTaskService.getKpisInstallation()
      .subscribe((response: InstallationKpi) => {
        this.installationKpi = response;
        for (let user of this.installationKpi.user_kpis) {
          this.chartLabels.push(user.fullName);
          this.dataUsercounts.push(user.installed_luminaire_count);
        }
      }, error => this.toastr.error(error));

    this.kpiTaskService.getLuminairesInstallation(this.end_date, this.start_date).subscribe(res => {
      this.LuminairesInstallation = res;
    });
    // this.route.queryParamMap.subscribe(queries => {
    //   const end_date = queries.get('end_date') ? queries.get('end_date') : '';
    //   const start_date = queries.get('end_date') ? queries.get('end_date') : '';


    // });


    this.repairTasksSearchForm = this.fb.group({
      end_date: [],
      start_date: []
    })
  }
  filtrar(form: FormGroup) {
    console.log(form.value);

    this.end_date = this.parseDate(this.repairTasksSearchForm.get('end_date').value);
    this.start_date = this.parseDate(this.repairTasksSearchForm.get('start_date').value);

    console.log(this.end_date, this.start_date)
    this.kpiTaskService.getLuminairesInstallation(this.end_date, this.start_date).subscribe(res => {
      this.LuminairesInstallation = res;
    });
    // this.router.navigate(['/u/call-center/repair-tasks'],
    //   {
    //     relativeTo: this.route,
    //     queryParams: {
    //       end_date: this.parseDate(date_form),
    //       start_date: this.parseDate(star_form),
    //     }
    //   });


  }

  parseDate(date) {
    if (date != null) {
      return `${date.year}/${date.month}/${date.day}`;
    }
    return '';
  }
}
