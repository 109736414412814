import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataGuarantyOpinion, DataLuminaire, DataPhotocell } from '../guaranty-opinion-model';

import { GuarantyOpinionService } from '../guaranty-opinion.service';

@Component({
  selector: 'app-assignated-opinion',
  templateUrl: './assignated-opinion.component.html',
  styleUrls: ['./assignated-opinion.component.css']
})
export class AssignatedOpinionComponent implements OnInit {
  @ViewChild('modalInformation') ngModalInformation: any;
  id;
  guaranty_report_id;
  opinion_type;
  formPhotocell: FormGroup;
  formLuminaire: FormGroup;
  first_image: any;
  second_image: any;
  diabledButtonLuminaire = false;
  diabledButtonPhotocell = false;
  formData: FormData = new FormData();

  constructor(
    private activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private toastr: Toastr,
    private router: Router,
    private guarantyService: GuarantyOpinionService,
    // private guarantyserv: GuarantyOpinionService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.guaranty_report_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.formPhotocell = this.fb.group({
      policy_number: '',
      model: '',
      turn_on: false,
      internal_work: false,
      conditions: '',
      is_guaranty: false,
      send_to_provider: false,
      accepted: 0
    });
    this.formLuminaire = this.fb.group({
      policy_number: [''],
      temp_amb: [0],
      humidity: [0],
      electrical_behavior: [''],
      is_oxidation_electric_components: [false],
      damaged_door_insurance: [false],
      atmospheric_discharge: [false],
      is_short_circuit: [false],
      hits_scrapes: [false],
      no_sealed_receptacle_nema: [false],
      damaged_hinges: [false],
      screw_incomplete: [false],
      signs_vandalism: [false],
      damaged_wiring: [false],
      optical_damage: [false],
      led_modules_damage: [false],
      surge_arrester_damage: [false],
      incomplete_components: [false],
      power_test: [false],
      on_during_test: [false],
      luminarie_power: [0],
      input_frequency: [0],
      supply_voltage: [0],
      supply_current: [0],
      thd_av: [0, [Validators.min(1), Validators.max(1000)]],
      thd_ai: [0, [Validators.min(1), Validators.max(100)]],
      measured_input_power: [0],
      f_p: [0],
      output_frequency: [0],
      output_voltage: [0],
      output_current: [0],
      alternative_photocell_placed: [false],
      lit_with_new_photocell: [false],
      alternate_driver_was_placed: [false],
      lit_with_new_alternate_driver: [false],
      alternate_suppressor_was_placed: [false],
      ignited_with_new_alternate_suppressor: [false],
      luminaire_conditions: [''],
      corrective_actions: [''],
      is_guaranty: [false],
      send_to_provider: [false],
      total_amount: [0],
      first_image: [null],
      second_image: [null],
      accepted: 0,
      firstimage_URL: [null],
      secondimage_URL: [null]
    });
    this.getDetailOpinion();
  }


  getDetailOpinion() {
    this.guarantyService.getGuareantyOpinionDetail(this.guaranty_report_id).subscribe((detailGuarantyOpinion: DataGuarantyOpinion) => {

      if (detailGuarantyOpinion.id !== null) {
        this.id = detailGuarantyOpinion.id;
        this.opinion_type = detailGuarantyOpinion.opinion_type;
        switch (detailGuarantyOpinion.opinion_type) {
          case 0: {
            this.populateDataLuminaire(detailGuarantyOpinion.data_luminaire);
            break;
          }
          case 1: {
            this.populateDataPhoptocell(detailGuarantyOpinion.data_photocell);
            break;
          }
          case 2: {
            this.populateDataLuminaire(detailGuarantyOpinion.data_luminaire);
            this.populateDataPhoptocell(detailGuarantyOpinion.data_photocell);
            break;
          }
          default: {
            this.toastr.singleError('Se presenta un error en el tipo de garantia');
            return;
          }
        }
      } else {
        this.opinion_type = detailGuarantyOpinion.opinion_type;
      }

    }, error => {
      this.toastr.error(error);
    })
  }

  private populateDataLuminaire(data_luminaire: DataLuminaire) {
    this.formLuminaire.patchValue({
      policy_number: data_luminaire.policy_number,
      temp_amb: data_luminaire.temp_amb,
      humidity: data_luminaire.humidity,
      electrical_behavior: data_luminaire.electrical_behavior,
      is_oxidation_electric_components: data_luminaire.is_oxidation_electric_components,
      damaged_door_insurance: data_luminaire.damaged_door_insurance,
      atmospheric_discharge: data_luminaire.atmospheric_discharge,
      is_short_circuit: data_luminaire.is_short_circuit,
      hits_scrapes: data_luminaire.hits_scrapes,
      no_sealed_receptacle_nema: data_luminaire.no_sealed_receptacle_nema,
      damaged_hinges: data_luminaire.damaged_hinges,
      screw_incomplete: data_luminaire.screw_incomplete,
      signs_vandalism: data_luminaire.signs_vandalism,
      damaged_wiring: data_luminaire.damaged_wiring,
      optical_damage: data_luminaire.optical_damage,
      led_modules_damage: data_luminaire.led_modules_damage,
      surge_arrester_damage: data_luminaire.surge_arrester_damage,
      incomplete_components: data_luminaire.incomplete_components,
      power_test: data_luminaire.power_test,
      on_during_test: data_luminaire.on_during_test,
      luminarie_power: data_luminaire.luminarie_power,
      input_frequency: data_luminaire.input_frequency,
      supply_voltage: data_luminaire.supply_voltage,
      supply_current: data_luminaire.supply_current,
      thd_av: data_luminaire.thd_av,
      thd_ai: data_luminaire.thd_ai,
      measured_input_power: data_luminaire.measured_input_power,
      f_p: data_luminaire.f_p,
      output_frequency: data_luminaire.output_frequency,
      output_voltage: data_luminaire.output_voltage,
      output_current: data_luminaire.output_current,
      alternative_photocell_placed: data_luminaire.alternative_photocell_placed,
      lit_with_new_photocell: data_luminaire.lit_with_new_photocell,
      alternate_driver_was_placed: data_luminaire.alternate_driver_was_placed,
      lit_with_new_alternate_driver: data_luminaire.lit_with_new_alternate_driver,
      alternate_suppressor_was_placed: data_luminaire.alternate_suppressor_was_placed,
      ignited_with_new_alternate_suppressor: data_luminaire.ignited_with_new_alternate_suppressor,
      luminaire_conditions: data_luminaire.luminaire_conditions,
      corrective_actions: data_luminaire.corrective_actions,
      is_guaranty: data_luminaire.is_guaranty,
      send_to_provider: data_luminaire.send_to_provider,
      total_amount: data_luminaire.total_amount,
      first_image: data_luminaire.first_image,
      second_image: data_luminaire.second_image,
      accepted: data_luminaire.accepted,
      firstimage_URL: data_luminaire.first_image,
      secondimage_URL: data_luminaire.second_image
    });
    if (data_luminaire.accepted === 2) {
      this.diabledButtonLuminaire = true;
      this.formLuminaire.disable();
    }
  }

  private populateDataPhoptocell(data_photocell: DataPhotocell) {
    this.formPhotocell.patchValue({
      policy_number: data_photocell.policy_number,
      model: data_photocell.model,
      turn_on: data_photocell.turn_on,
      internal_work: data_photocell.internal_work,
      conditions: data_photocell.conditions,
      is_guaranty: data_photocell.is_guaranty,
      send_to_provider: data_photocell.send_to_provider,
      accepted: data_photocell.accepted
    });
    if (data_photocell.accepted === 2) {
      this.diabledButtonPhotocell = true;
      this.formPhotocell.disable();
    }
  }

  updateOpinion(is_luminaire: boolean) {
    // if (!formGroup.valid || formLumi.valid) {
    this.formData.append('id', this.id);
    this.formData.append('guaranty_report_id', this.guaranty_report_id);
    if (is_luminaire) {
      this.appendDataLuminaire();
    } else {
      this.appendDataPhotocell();
    }

    // tslint:disable-next-line:max-line-length
    if ((Number(this.formLuminaire.get('accepted').value) === 2 && is_luminaire) || ((Number(this.formPhotocell.get('accepted').value) === 2 && !is_luminaire))) {
      this.modalService.open(this.ngModalInformation, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
        result => {
        },
        reason => {
          if (reason === 1) {
            this.guarantyService.updateguarantyopinion(this.formData).subscribe(resp => {
              console.log(resp)
              this.toastr.success('Se Guardo Correctamente.');
              this.router.navigate(['/u/guaranties/guaranty-opinion']);
            })
          }
        });
    } else {
      this.guarantyService.updateguarantyopinion(this.formData).subscribe(resp => {
        console.log(resp)
        this.toastr.success('Se Guardo Correctamente.');
        this.router.navigate(['/u/guaranties/guaranty-opinion']);
      })
    }
  }


  registerOpinion(is_lumianire: boolean) {
    this.formData.append('guaranty_report_id', this.guaranty_report_id);
    if (is_lumianire) {
      this.appendDataLuminaire();
    } else {
      this.appendDataPhotocell();
    }
    if (Number(this.formLuminaire.get('accepted').value) === 2 || Number(this.formPhotocell.get('accepted').value) === 2) {
      this.modalService.open(this.ngModalInformation, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
        result => {
        },
        reason => {
          if (reason === 1) {
            this.guarantyService.registerguarantyopinion(this.formData).subscribe(resp => {
              console.log(resp)
              this.toastr.success('Se Guardo Correctamente.');
              this.router.navigate(['/u/guaranties/guaranty-opinion']);
            });
          }
        });
    } else {
      this.guarantyService.registerguarantyopinion(this.formData).subscribe(resp => {
        this.toastr.success('Se Guardo Correctamente.');
        this.router.navigate(['/u/guaranties/guaranty-opinion']);
      });
    }
  }


  private appendDataLuminaire() {
    console.log(this.formLuminaire.value, 'valores a regisdtrar de luminarias');
    this.formData.append('data_luminaire.policy_number', this.formLuminaire.value.policy_number);
    this.formData.append('data_luminaire.first_image_', this.first_image);
    this.formData.append('data_luminaire.second_image_', this.second_image);
    this.formData.append('data_luminaire.temp_amb', this.formLuminaire.value.temp_amb);
    this.formData.append('data_luminaire.humidity', this.formLuminaire.value.humidity);
    this.formData.append('data_luminaire.electrical_behavior', this.formLuminaire.value.electrical_behavior);
    this.formData.append('data_luminaire.is_oxidation_electric_components', this.formLuminaire.value.is_oxidation_electric_components);
    this.formData.append('data_luminaire.damaged_door_insurance', this.formLuminaire.value.damaged_door_insurance);
    this.formData.append('data_luminaire.atmospheric_discharge', this.formLuminaire.value.atmospheric_discharge);
    this.formData.append('data_luminaire.is_short_circuit', this.formLuminaire.value.is_short_circuit);
    this.formData.append('data_luminaire.hits_scrapes', this.formLuminaire.value.hits_scrapes);
    this.formData.append('data_luminaire.no_sealed_receptacle_nema', this.formLuminaire.value.no_sealed_receptacle_nema);
    this.formData.append('data_luminaire.damaged_hinges', this.formLuminaire.value.damaged_hinges);
    this.formData.append('data_luminaire.screw_incomplete', this.formLuminaire.value.screw_incomplete);
    this.formData.append('data_luminaire.signs_vandalism', this.formLuminaire.value.signs_vandalism);
    this.formData.append('data_luminaire.damaged_wiring', this.formLuminaire.value.damaged_wiring);
    this.formData.append('data_luminaire.optical_damage', this.formLuminaire.value.optical_damage);
    this.formData.append('data_luminaire.led_modules_damage', this.formLuminaire.value.led_modules_damage);
    this.formData.append('data_luminaire.surge_arrester_damage', this.formLuminaire.value.surge_arrester_damage);
    this.formData.append('data_luminaire.incomplete_components', this.formLuminaire.value.incomplete_components);
    this.formData.append('data_luminaire.power_test', this.formLuminaire.value.power_test);
    this.formData.append('data_luminaire.on_during_test', this.formLuminaire.value.on_during_test);
    this.formData.append('data_luminaire.luminarie_power', this.formLuminaire.value.luminarie_power);
    this.formData.append('data_luminaire.input_frequency', this.formLuminaire.value.input_frequency);
    this.formData.append('data_luminaire.supply_voltage', this.formLuminaire.value.supply_voltage);
    this.formData.append('data_luminaire.supply_current', this.formLuminaire.value.supply_current);
    this.formData.append('data_luminaire.thd_av', this.formLuminaire.value.thd_av);
    this.formData.append('data_luminaire.thd_ai', this.formLuminaire.value.thd_ai);
    this.formData.append('data_luminaire.measured_input_power', this.formLuminaire.value.measured_input_power);
    this.formData.append('data_luminaire.f_p', this.formLuminaire.value.f_p);
    this.formData.append('data_luminaire.output_frequency', this.formLuminaire.value.output_frequency);
    this.formData.append('data_luminaire.output_voltage', this.formLuminaire.value.output_voltage);
    this.formData.append('data_luminaire.output_current', this.formLuminaire.value.output_current);
    this.formData.append('data_luminaire.alternative_photocell_placed', this.formLuminaire.value.alternative_photocell_placed);
    this.formData.append('data_luminaire.lit_with_new_photocell', this.formLuminaire.value.lit_with_new_photocell);
    this.formData.append('data_luminaire.alternate_driver_was_placed', this.formLuminaire.value.alternate_driver_was_placed);
    this.formData.append('data_luminaire.lit_with_new_alternate_driver', this.formLuminaire.value.lit_with_new_alternate_driver);
    this.formData.append('data_luminaire.alternate_suppressor_was_placed', this.formLuminaire.value.alternate_suppressor_was_placed);
    // tslint:disable-next-line:max-line-length
    this.formData.append('data_luminaire.ignited_with_new_alternate_suppressor', this.formLuminaire.value.ignited_with_new_alternate_suppressor);
    this.formData.append('data_luminaire.luminaire_conditions', this.formLuminaire.value.luminaire_conditions);
    this.formData.append('data_luminaire.corrective_actions', this.formLuminaire.value.corrective_actions);
    this.formData.append('data_luminaire.is_guaranty', this.formLuminaire.value.is_guaranty);
    this.formData.append('data_luminaire.send_to_provider', this.formLuminaire.value.send_to_provider);
    this.formData.append('data_luminaire.total_amount', this.formLuminaire.value.total_amount);
    this.formData.append('data_luminaire.accepted', this.formLuminaire.value.accepted);
  }

  private appendDataPhotocell() {
    // console.log(this.formPhotocell.value, 'valores a regisdtrar de fotocelda');
    this.formData.append('data_photocell.policy_number', this.formPhotocell.value.policy_number);
    this.formData.append('data_photocell.model', this.formPhotocell.value.model);
    this.formData.append('data_photocell.turn_on', this.formPhotocell.value.turn_on);
    this.formData.append('data_photocell.internal_work', this.formPhotocell.value.internal_work);
    this.formData.append('data_photocell.conditions', this.formPhotocell.value.conditions);
    this.formData.append('data_photocell.is_guaranty', this.formPhotocell.value.is_guaranty);
    this.formData.append('data_photocell.send_to_provider', this.formPhotocell.value.send_to_provider);
    this.formData.append('data_photocell.accepted', this.formPhotocell.value.accepted);
  }
  setImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.first_image = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.formLuminaire.patchValue({ first_image: reader.result })
        this.formLuminaire.patchValue({ image: file })
      };
      // this.formData.append('first_image', event.target.files[0]);
      reader.readAsDataURL(file);
    } else {
      this.first_image = null;
    }

  }
  setImage2Preview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.second_image = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.formLuminaire.patchValue({ second_image: reader.result })
        this.formLuminaire.patchValue({ image: file })
      };
      // this.formData.append('second_image', event.target.files[0])
      reader.readAsDataURL(file);
    }
  }
  setImage(event) {
    const file: File = event.target.files[0];

    if (file.type !== 'image/jpg') {
      this.toastr.singleError('El archivo cargado no es una imagen');
      return;
    }

    this.formData.append('first_image_', file, file.name);
  }

  setImage2(event) {
    const file: File = event.target.files[0];

    if (file.type !== 'image/jpg') {
      this.toastr.singleError('El archivo cargado no es una imagen');
      return;
    }

    this.formData.append('second_image_', file, file.name);
  }
}
