export interface IDashboardEfficiency {
    morning_efficienty: number,
    afternoon_efficienty: number,
    best_user: string,
    total_tasks: number,
    doughnutChart_DTO: {
        labels: string[],
        data: number[]
    },
    barChart_DTO: {
        oficials_names: string[],
        barData_DTO: [
            {
                data: number[],
                label: string
            }
        ]
    }
}

export class DashboardEfficiency implements IDashboardEfficiency {
    morning_efficienty: number;
    afternoon_efficienty: number;
    best_user: string;
    total_tasks: number;
    doughnutChart_DTO: { labels: string[]; data: number[]; };
    barChart_DTO: { oficials_names: string[]; barData_DTO: [{ data: number[]; label: string; }]; };

    constructor(attrs) {
        this.morning_efficienty = attrs.morning_efficienty;
        this.afternoon_efficienty = attrs.afternoon_efficienty;
        this.best_user = attrs.best_user;
        this.total_tasks = attrs.total_tasks;
        this.doughnutChart_DTO = attrs.doughnutChart_DTO;
        this.barChart_DTO = attrs.barChart_DTO;
    }
}
