import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { CheckReportService } from './check-report.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketResponse } from 'app/models/ticket-response';

@Component({
  selector: 'app-check-report',
  templateUrl: './check-report.component.html',
  providers: [CheckReportService]
})
export class CheckReportComponent implements OnInit {
  @ViewChild('surnameModal') surnameModal: any;
  surnameForm: FormGroup = this.formBuilder.group({
    first_last_name: [null, Validators.required]
  });
  reportForm: FormGroup = this.formBuilder.group({
    folio: [null, Validators.required]
  });
  showStatusFolio = false;
  slug = '';
  folio = '';
  status = 0;
  checkEveryLuminaria = [];
  showMessage = false;
  warningMessage = '';
  messages = [
    'Hemos recibido tu reporte y pronto revisaremos tu caso.',
    'Hemos recibido tu reporte y pronto enviaremos a una persona experta a resolver el problema.',
    'Hemos recibido tu reporte y pronto enviaremos a una persona experta a resolver el problema.',
    'Uno de nuestros expertos ya se encuentra resolviendo el problema.',
    'El problema reportado ya fue solucionado, si encuentra algun otro problema por favor comuniquese con nosotros.'
  ]

  constructor(
    private toastr: Toastr,
    private router: Router,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private checkReportService: CheckReportService
  ) { }

  ngOnInit() {
    this.folio = this.activatedRoute.snapshot.paramMap.get('folio');
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug');
    if(this.folio){
      this.reportForm.patchValue({
        folio: this.folio
      });
    }
  }

  openValidateConsultModal(content):void {
    this.modalService.open(content, { ariaLabelledBy: 'Modal Forgot Password' });
  }

  onSubmit({ value, invalid }: { value: { first_last_name: string }, invalid: boolean }) {
    if (invalid) { 
      this.surnameForm.controls.first_last_name.markAsTouched();
      return;
    }
    this.checkReportService
      .getFolio(this.folio, this.surnameForm.get('first_last_name').value)
      .subscribe(
        (response: TicketResponse) => {
          this.folio = response.reference;
          this.status = response.generalStatus;
          this.modalService.dismissAll('surnameModal');
          this.showStatusFolio = true;
          this.showMessage = false;
          this.checkEveryLuminaria = response.lumsTicketStatuses;
        },
        (error) => {
          this.toastr.singleError(error.error.message);
          // let messages = ''
          // if(error.error){
          //   let errors = error.error.message;
          //   if(errors.length){
          //     messages = errors.join(', ');
          //     this.showMessage = true;
          //     this.warningMessage = messages;
          //   }
          // }
        }
      );
  }

  onSubmitReport({ value, invalid }: { value: { email: string }, invalid: boolean }) {
    if (invalid) { 
      this.reportForm.controls.folio.markAsTouched();
      return;
    }
    this.folio = this.reportForm.get('folio').value;
    this.modalService.open(this.surnameModal).result.then(result => {
    }, reason => {});
  }

  restartConsult(){
    this.showStatusFolio = false;
    this.reportForm.patchValue({ folio: '' });
    this.reportForm.markAsUntouched();
    this.surnameForm.patchValue({ first_last_name: '' });
    this.surnameForm.markAsUntouched();
  }

  redirect(){
    this.router.navigate([`/reportar-luminaria/${this.slug}`]);
  }

}