export class InstallationMarkers {

}

export class CircuitsInstallation {
  id: string;
  identifier: string;
  meter_id: string;
  latitude: number;
  longitude: number;
  lampposts: LamppostsInstallation[];
  marker: google.maps.Marker;
  event: string;
  isSelected: boolean;
  polygonID: number;


  readonly markerIcons = {
    normal: 'assets/img/svg/circuit_normal.svg',
    selected: 'assets/img/svg/circuit_selected.svg',
    circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
  }

  constructor(attrs) {
    this.id = attrs.id;
    this.identifier = attrs.identifier;
    this.meter_id = attrs.meter_id;
    this.latitude = attrs.latitude;
    this.longitude = attrs.longitude;
    // tslint:disable-next-line:max-line-length
    this.lampposts = attrs.lampposts != null ? attrs.lampposts.map((lamppost: LamppostsInstallation) => new LamppostsInstallation(lamppost)) : []
  }

  get_icon(): object {
    return {
      url: this.markerIcons[this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
    };
  }

  upadeIcon(event: string, continueWithAll: boolean = true): void {
    this.event = event;
    this.marker.setIcon(this.get_icon());
    if (continueWithAll) {
      for (const lamppost of this.lampposts) {
        lamppost.upadeIcon(event);
      }
    }
  }

  selected() {
    this.event = 'selected';
    this.isSelected = true;
    this.marker.setIcon(this.get_icon());
    for (const lamppost of this.lampposts) {
      lamppost.upadeIcon('normal');
    }
  }

  selectedALLLum(luminaires: LuminairesInstallation[]) {
    for (const luminaire of luminaires) {
      luminaire.isSelected = true;
      luminaire.updateIcon_('selected');
    }
  }

  desSelectedAllLum(luminaires: LuminairesInstallation[]) {
    for (const luminaire of luminaires) {
      luminaire.isSelected = false;
      luminaire.updateIcon_('normal');
    }
  }


  desSelected() {
    this.event = 'normal';
    this.isSelected = false;
    this.marker.setIcon(this.get_icon());
    for (const lamppost of this.lampposts) {
      lamppost.upadeIcon('normal');
    }
  }



  paintMarker(map: any): void {
    this.event = this.isSelected ? 'selected' : 'normal';
    this.marker = new google.maps.Marker({
      title: this.identifier,
      position: new google.maps.LatLng(this.latitude, this.longitude),
      icon: this.get_icon(),
      map: map
    });
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }

  setMap(map: any): void {
    this.marker.setMap(map);
    for (const lamppost of this.lampposts) {
      lamppost.setMap(map);
    }
  }
}

export class LamppostsInstallation {
  id: string;
  has_circuit: boolean;
  luminaires: LuminairesInstallation[];
  event: string;

  constructor(attrs) {
    this.id = attrs.id;
    this.has_circuit = attrs.has_circuit;
    // tslint:disable-next-line:max-line-length
    this.luminaires = attrs.luminaires != null ? attrs.luminaires.map((luminaire: LuminairesInstallation) => new LuminairesInstallation(luminaire, 'linked')) : [];
  }

  upadeIcon(event: string): void {
    this.event = event;
    for (const luminaire of this.luminaires) {
      // tslint:disable-next-line:no-shadowed-variable
      const event = this.event === 'normal' ? luminaire.isSelected ? 'selected' : 'normal' : this.event;
      luminaire.updateIcon(event);
    }
  }

  hideLamppost(): void {
    for (const luminaire of this.luminaires) {
      luminaire.hideMarker();
    }
  }

  setMap(map: any): void {
    for (const luminaire of this.luminaires) {
      luminaire.setMap(map);
    }
  }
}

export class MarkerColorIntallation {
  fill_color: string;
  fill_color2: string;
  path: string;
  path2: string;

}


export class LuminairesInstallation {
  id: string;
  reference_id: string;
  latitude: number;
  longitude: number;
  power: number;
  optic: string;
  power_id: string;
  progress: number;
  linkedSituation: string;
  event: string;
  isSelected: Boolean;
  polygonID: number;
  circuit_id: string;
  marker: google.maps.Marker;
  marker_color: MarkerColorIntallation;

  readonly markerIcons = {
    linked: {
      hover: 'assets/img/svg/ic_link_hover.svg',
      normal: 'assets/img/svg/link.svg',
      selected: 'assets/img/svg/lam_selected.svg',
      circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
    },
    single: {
      normal: 'assets/img/svg/normal.svg',
      selected: 'assets/img/svg/selected-blue.svg'
    },
    assign: {
      selected: 'assets/img/svg/ic_install_selected-',
      circuitShowing: 'assets/img/svg/ic_install_linked-',
      normal: 'assets/img/svg/ic_install_pin-',
      hover: 'assets/img/svg/ic_install_hover-',
    },
    citizen: {
      normal: 'assets/img/svg/ic_single_normal.svg',
      selected: 'assets/img/svg/link_selected.svg'
    }
  }

  constructor(attrs: any, linkedSituation: string = 'single', event: string = 'normal') {
    this.id = attrs.id;
    this.reference_id = attrs.reference_id;
    this.latitude = attrs.latitude;
    this.longitude = attrs.longitude;
    this.linkedSituation = attrs.power_id !== '00000000-0000-0000-0000-000000000000' ? 'assign' : linkedSituation;
    this.isSelected = false;
    this.event = event;
    this.marker_color = attrs.marker_color;
    this.circuit_id = attrs.circuit_id;
  }

  get_icon(): object {
    return this.linkedSituation === 'assign' ? {
      // tslint:disable-next-line:max-line-length
      url: `${this.markerIcons[this.linkedSituation][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.marker_color}.svg`,
      scaledSize: new google.maps.Size(15, 15)
    } : {
      url: this.markerIcons[this.linkedSituation][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
    }
  }

  updateIcon(event: string): void {
    this.event = event;
    this.marker.setIcon(this.get_icon());
  }

  paintMarker(map: any) {
    this.event = this.isSelected ? 'selected' : 'normal';
    this.marker = new google.maps.Marker({
      title: this.reference_id,
      position: new google.maps.LatLng(this.latitude, this.longitude),
      icon: this.get_icon(),
      map: map
    });
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }

  setMap(map: any): void {
    this.marker.setMap(map);
  }


  /////////metodos nuevos

  updateIcon_(event: string) {
    this.marker.setIcon('data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.buildSVG()));
  }

  buildSVG(): string {
    let svg = ``;
    if (this.isSelected) {

      svg = `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <g>
          <path stroke="blue" stroke-opacity="5" stroke-width="6" fill="${this.marker_color.fill_color}" d="${this.marker_color.path}"> </path>     
          <path fill="${this.marker_color.fill_color2}" d="${this.marker_color.path2}"> </path>
      </g>
  </svg>`;
    } else {
      svg = `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <g>
          <path stroke="black" stroke-opacity="5" stroke-width="3" fill="${this.marker_color.fill_color}" d="${this.marker_color.path}"> </path>     
          <path fill="${this.marker_color.fill_color2}" d="${this.marker_color.path2}"> </path>
      </g>
  </svg>`;
    }
    return svg;
  }

  paintMarker_(map) {

    this.marker = new google.maps.Marker({
      title: this.reference_id,
      position: new google.maps.LatLng(this.latitude, this.longitude),
      icon: {
        url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.buildSVG())
      },
      map: map
    });
  }


}

