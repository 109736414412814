import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GenericTable } from '@app/models/generic-table';
import { AlertsType, ListAlertsType } from './alerts-type';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthService } from '@app/shared/auth/auth.service';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class AlertsTypeService {


  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  getAlerts(form, page) {
    const httpParams = new HttpParams().set('page', page)
    return this.httpClient.post<GenericTable<AlertsType>>(
      `${environment.SERVER_ENERGY}/api/RpusAlertsType/GetAlertsType`, form, { params: httpParams }
    ).pipe(
      map(elements => new GenericTable<AlertsType>(elements))
    );
  }

  updateAlert(form) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id)
    return this.httpClient.post(
      `${environment.SERVER_ENERGY}/api/RpusAlertsType/UpdateAlertsType`, form, { headers: httpHeaders }
    );
  }
}
