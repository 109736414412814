import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { UiSwitchModule } from 'ngx-ui-switch';

import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ProjectsModalComponent } from './projects/projects-modal/projects-modal.component';
import { NewProjectModalComponent } from './projects/new-project-modal/new-project-modal.component';
import { EditProjectModalComponent } from './projects/edit-project-modal/edit-project-modal.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';

import { LoginService } from '@pages/login/login.service';





@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        UiSwitchModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgbModule,
        ProjectsModalComponent,
        NewProjectModalComponent,
        EditProjectModalComponent,
        TagInputModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
        UiSwitchModule.forRoot({
            size: 'large',
          })
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        ProjectsModalComponent,
        NewProjectModalComponent,
        EditProjectModalComponent,
    ],
    providers: [
        LoginService
    ]
})
export class SharedModule { }
