import { TypeTaskEnum } from './type-task-enum';

interface TypeTaskInterface {
  id: number,
  name: string
}

export class TypeTask implements TypeTaskInterface {
  id: number;
  name: string;

  constructor(id) {
    this.id = id;
    this.name = TypeTaskEnum[id];
  }
}