import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '@app/shared/auth/auth.service';
import { IRpuDetail } from './rpu-detail';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Circuit } from '@app/models/circuit';
import { Luminaire } from '@app/models/luminaire';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RpuDetailService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    
  ) { }

  GetRpuDetail(rpu_id,yearSelectedSavin) {
    const httpParams = new HttpParams().set('rpu_id', rpu_id).set('yearSelectedSavin', yearSelectedSavin);
    return this.httpClient.get<IRpuDetail>(
      `${environment.SERVER_ENERGY}/api/RpusDetalle/GetRpuDetail`, { params: httpParams}
    );
  }

  GetRpuHistoric(rpu_id) {
    const httpParams = new HttpParams().set('rpu_id', rpu_id);
    return this.httpClient.get<IRpuDetail>(
      `${environment.SERVER_ENERGY}/api/RpusDetalle/GetRpuHistoric`, { params: httpParams}
    );
  }

  nextRpuDetail(rpu_id, next) {
    const httpParams = new HttpParams()
    .set('rpu_id', rpu_id)
    .set('next', next);
    return this.httpClient.get<string>(
      `${environment.SERVER_ENERGY}/api/RpusDetalle/GetNextRpuDetail`, {params: httpParams}
      ).pipe(
      map(elements => rpu_id = elements)
    );
  }

}
