import { Injectable } from '@angular/core';
import { AppVersion } from './app-version';
import { environment } from '@environments/environment';
import { HttpParams, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VersionsService {
  constructor(private httpClient: HttpClient) { }

  createVersion(version: AppVersion) {
    return this.httpClient
      .post(`${environment.SERVER_URL}/versions`, { version: version });
  }

  getVersion(id: string) {
    return this.httpClient
      .get(`${environment.SERVER_URL}/versions/${id}`);
  }

  getVersions(page: number) {
    const params = new HttpParams().set('page', page.toString());
    return this.httpClient
      .get(`${environment.SERVER_URL}/versions`, { params: params });
  }

  updateVersion(version: AppVersion) {
    return this.httpClient
      .put(`${environment.SERVER_URL}/versions/${version.id}`, { version: version });
  }

}
