import { Component, OnInit } from '@angular/core';
import { InventoryService } from '../inventory.service';
import { MaterialLogs } from '@app/models/material-logs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaginationAttributes } from '@app/models/pagination';
import { throwIfEmpty } from 'rxjs/operators';

@Component({
  selector: 'app-movements-log',
  templateUrl: './movements-log.component.html',
  styleUrls: ['./movements-log.component.scss']
})
export class MovementsLogComponent implements OnInit {

  materialLogs: MaterialLogs[] = [];
  users_guilty;
  pagination: PaginationAttributes;
  currentPage = 0;

  form: FormGroup;
  constructor(
    private inventoryLog: InventoryService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {

    this.form = this.fb.group({
      reference_id: [null],
      user_id: [null],
      place: [3],
      startDate: [null],
      endDate: [null]
    });

    this.inventoryLog.getLogs(this.currentPage, '', '', '', '', '').subscribe(response => {
      this.materialLogs = response.elements.map(log => new MaterialLogs(log));
      this.pagination = new PaginationAttributes(
        response.index + 1,
        response.next,
        response.previous + 1,
        response.count,
        response.pages,
        response.from,
        response.size)
    });

    this.inventoryLog.getGuiltyPeople().subscribe(guilty => {
      this.users_guilty = guilty;
    })
  }

  search() {
    const startDate = this.form.get('startDate').value;
    const endDate = this.form.get('endDate').value;
    const reference_id = this.form.get('reference_id').value;
    const user_id = this.form.get('user_id').value;
    const place = this.form.get('place').value;

    this.inventoryLog.getLogs(
      this.currentPage,
      reference_id == null ? '' : reference_id,
      user_id == null ? '' : user_id,
      place,
      this.parseDate(startDate),
      this.parseDate(endDate)).subscribe(response => {
        this.materialLogs = response.elements.map(log => new MaterialLogs(log));
        this.pagination = new PaginationAttributes(
          response.index + 1,
          response.next,
          response.previous + 1,
          response.count,
          response.pages,
          response.from,
          response.size)
      })
  }

  parseDate(date) {
    if (date != null) {
      return  `${date.year}/${date.month}/${date.day}`
    }
    return ''
  }

  pageChange(page: number) {
    this.currentPage = page - 1;
    this.search()
  }

}
