import { Component, OnInit, ViewChild } from '@angular/core';
import { EditCircuitService } from './edit-circuit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GetCircuitResponse } from '@models/get-circuit-response';
import { Circuit } from '@models/circuit';
import { Toastr } from '@shared/toastr/toastr.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CircuitPayload } from '@models/circuit-payload';
import { TariffEnum } from '@models/tariff-enum';
import { SupplyEnum } from '@models/supply-enum';
import { LightTask } from '@models/light-task';

@Component({
  selector: 'app-edit-circuit',
  templateUrl: './edit-circuit.component.html',
  styleUrls: ['./edit-circuit.component.scss'],
  providers: [EditCircuitService]
})
export class EditCircuitComponent implements OnInit {
  tariffEnum = Object.keys(TariffEnum).filter(key => key.length > 1);
  supplyEnum = Object.keys(SupplyEnum).filter(key => !isNaN(Number(SupplyEnum[key])));
  lightTasks: LightTask[] = [];
  circuitID: string;
  circuit: Circuit;
  is_edit = false;
  readonly = false;
  devices = [];
  keyword = 'geenapsis_device_id';
  device_name = '';


  circuitForm: FormGroup = this.formBuilder.group({
    id: [{ value: null, disabled: true }, Validators.required],
    rpu: [null],
    tariff: [null],
    supply: [null],
    identifier: [null],
    weighted_score: [{ value: null, disabled: true }],
    device_id: '',
    device_name: '',
    initial_calculated_kwh: [null],
    comment: [null]
  });

  constructor(
    private toastr: Toastr,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private editCircuitService: EditCircuitService
  ) { }

  ngOnInit() {
    this.circuitID = this.activatedRoute.snapshot.paramMap.get('id');
    this.editCircuitService
      .getNewCircuit(this.circuitID)
      .subscribe(
        (getCircuitResponse: Circuit) => {
          this.circuit = getCircuitResponse;
          this.populateForm();
          this.lightTasks = getCircuitResponse.lightTasks;
        },
        (error) => {
          this.toastr.error(error);
        }
      );
    this.editCircuitService.getDevices().subscribe(response => {
      this.devices = response;
    });
  }

  private populateForm() {
    // const circuit = this.circuit;
    this.circuitForm = this.formBuilder.group({
      id: this.circuit.id,
      rpu: this.circuit.rpu,
      tariff: this.circuit.tariff,
      supply: this.circuit.supply,
      identifier: this.circuit.identifier,
      weighted_score: this.circuit.weighted_score,
      device_name: this.circuit.device_name,
      device_id: this.circuit.device_id,
      // device_id: circuit.device_name,
      initial_calculated_kwh: this.circuit.initial_calculated_kwh,
      comment: this.circuit.comment
    });
    // console.log(this.circuitForm.value, 'populate Form');
    this.readonly = true;
  }



  performFormAction(): void {
    if (this.circuitForm.valid) {
      // console.log(this.circuitForm.value, '2')
      // if (this.devices.filter(items => items.geenapsis_device_id.toUpperCase() === this.device_name.toUpperCase()).length === 0) {
      //   this.toastr.singleError('No existe ningun dispositivo smart con ese nombre');
      //   return;
      // }

      const data = {
        id: this.circuitForm.value.id,
        rpu: this.circuitForm.value.rpu,
        tariff: this.circuitForm.value.tariff,
        supply: this.circuitForm.value.supply,
        identifier: this.circuitForm.value.identifier,
        initial_calculated_kwh: this.circuitForm.value.initial_calculated_kwh,
        device_name: this.circuitForm.get('device_name').value,
        device_id: this.is_edit === true ? this.circuitForm.value.device_id : this.circuit.device_id,
        // device_id: this.circuitForm.value.device_id,
        comment: this.circuitForm.value.comment
      }
      // console.log(data, '3');
      this.is_edit = false;
      this.editCircuitService.editCircuitNew(data).subscribe(
          () => {
            this.toastr.success('Circuito actualizado.');
          },
          (error) => {
            this.toastr.singleError('Operación fallida');
          }
        );
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  onFocused(e) {
    // here we can write our code for doing something when input is focused
    console.log('editado');
    this.is_edit = true;
  }

  selectEvent(device) {
    // console.log(device, '1')
    if (device.id !== undefined) {
      this.is_edit = true;
      this.circuitForm.patchValue({
        device_id: device.id,
        device_name: device.geenapsis_device_id
      });
    } else {
      this.is_edit = true;
      this.circuitForm.patchValue({
        device_id: null,
        device_name: null
      });
    }
  }
  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
   console.log('is edit');
    this.is_edit = true;

  }

  clickTaskBase(task: LightTask) {
    this.router.navigate([`/u/tasks/${task.id}`]);
  }

}
