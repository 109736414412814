export interface ISummaryToday {
  materials: [{
    image: string;
    material: string;
    totalQuantity: number;
  }];
  oficial_id: string;
  oficial_name: string;
  devices_list: string[];

}

export class SummaryToday implements ISummaryToday {
  materials: [{
    image: string;
    material: string;
    totalQuantity: number;
  }];
  oficial_id: string;
  oficial_name: string;
  devices_list: string[];

  constructor(attrs: any) {
    this.oficial_id = attrs.oficial_id;
    this.devices_list = attrs.device_list;
    this.oficial_name = attrs.oficial_name;
    this.materials = attrs.materials;
    this.devices_list = attrs.devices_list;
  }
}
