import { Component, OnInit, ViewChild } from '@angular/core';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { DashboardCfeService } from './dashboard-cfe.service';
import { LuminaireEnum } from '@app/models/luminaire-enum';
import { TechnologyEnum } from '@app/models/technology-enum';
import { SubstationEnum } from '@app/models/substation_type-enum';
import { SubstationCurrentCapacityEnum } from '@app/models/substation_current_capacity-enum';
import { MountingTypeEnum } from '@app/models/mounting_type-enum';
import { Circuit } from '@app/models/circuit';
import { Luminaire } from '@app/models/luminaire';
import { Lamppost } from '@app/models/lamppost';
import { GeneralViewService } from '@app/pages/general-view/general-view.service';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { GenericTable } from '@app/models/generic-table';
import { Period, DashboardDetail } from './dashboard-detail';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { ChartDataSets } from 'chart.js';
import { CircuitRpu } from '@app/models/circuit-rpu';
import { element } from 'protractor';
import { DashboardDetailHistoric , PeriodHistoric } from './dashboard-detail-historic';
import { Project } from '@app/models/project';

@Component({
  selector: 'app-dashboard-cfe',
  templateUrl: './dashboard-cfe.component.html',
  styleUrls: ['./dashboard-cfe.component.scss'],
  providers: [MapFuncAuxService]
})
export class DashboardCfeComponent implements OnInit {


  dataSource: any;
  chartConfig: any;

  dashboardDetail: DashboardDetail;
  dashboardDetailHistoric: DashboardDetailHistoric;
  luminaireEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));
  substationEnum = Object.keys(SubstationEnum).filter(key => !isNaN(Number(SubstationEnum[key])));
  substationCurrentCapacityEnum = Object.keys(SubstationCurrentCapacityEnum).filter(key => key.length > 2);
  mountingTypeEnum = Object.keys(MountingTypeEnum).filter(key => !isNaN(Number(MountingTypeEnum[key])));
  lstInfowindows = [];
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  circuits: CircuitRpu[] = [];
  circuitMarkers: CircuitRpu[] = [];
  lamppostMarkers = [];

  /*Nuevo codigo*/
  page = 0;
  year = 0;
  month = 0;
  typeValue = 'KWH';
  vistaHistorial = false;
  data: number[] = [];
  public barChartData: ChartDataSets[] = [];
  chartOptions = {
    responsive: true,
    'scales': {
      'yAxes': [{
        'ticks': {
          'beginAtZero': true
        }
      }],
    }
  };
  chartColors: any[] = [
    {
      backgroundColor: ['#FF7360', '#6FC8CE', '#FAFFF2', '#FFFCC4', '#B9E8E0']
    }
  ];
  lastmonth: Period;
  elements: Period[];
  elementsHistoric: PeriodHistoric[];
  chartLabels = [];
  historicYears = ['Historial'];
  parameterTypeData = false;
  parameterTypePeriod = false
  parameterYear = 0;
  parameterMonth = 0;
  filterApbt = true;
  filterApmt = true;
  filterOthersTariffs = true;
  filterWithout = true;
  filterMensual = true;
  filterBimestral = true;

  constructor(
    private service: DashboardCfeService,
    private generalViewService: GeneralViewService,
    private currentProjectService: CurrentProjectService,
    private mapFuncAux: MapFuncAuxService,
    private router: Router,
    private toastr: Toastr
  ) {
  }

  ngOnInit() {
    this.getHistoricYear();
    this.GetDashboard();
    this.paintMap();
  }

  private getHistoricYear() {
    var yearCurrent = new Date().getFullYear();
    var yearBase = this.currentProjectService.getCurrentProject().energy_year;
    while (yearBase < yearCurrent) {
      yearBase++;
      this.historicYears.push(yearBase.toString())
    }
  }

  private addCircuits(circuits: CircuitRpu[]): void {
    const center = new google.maps.LatLng(circuits[0].meter.location.latitude, circuits[0].meter.location.longitude);
    this.map.setCenter(center);
    for (const circuit of circuits) {
      circuit.paintMarker(this.map);
      this.circuitMarkers.push(circuit);
      circuit.marker.addListener('click', () => {
        this.showCircuit(circuit);
      });
    }
  }

  private showInfoCircuit(circuit: CircuitRpu): void {
    this.service.GetRpuInfo(circuit.id).subscribe(
      response => {
        circuit.rpus_info = response;
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const strInfo = this.mapFuncAux.getStringInfoRpu(circuit, true);
    const infowindow = new google.maps.InfoWindow({ content: strInfo });
    infowindow.open(this.map, circuit.marker);
    this.lstInfowindows.push(infowindow);
      }
    )
  }


  private showCircuit(circuit: CircuitRpu): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();
    circuit.upadeIcon('circuitShowing');
    window.setTimeout(() => {
      this.showInfoCircuit(circuit);
    }, 500);
  }

  private hideCircuit(): void {
    const _circuit = this.circuits.find(__circuit => __circuit.event == 'circuitShowing');
    if (_circuit) {
      const event = _circuit.isSelected ? 'selected' : 'normal';
      _circuit.upadeIcon(event);
    }
  }

  paintMap() {
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const project = this.currentProjectService.getCurrentProject()
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })

    this.map.addListener('click', () => {
      this.hideCircuit();
    });
  }

  GetDataRpu(event: NgbTabChangeEvent) {
    this.chartLabels = [];
    this.barChartData = [];

    if (event.nextId == 'kwh') {
      this.typeValue = 'KWH';
      this.parameterTypeData = false;
      if(!this.vistaHistorial)this.setUpGraph(this.dashboardDetail.GetByYearAndType(this.year, this.parameterTypePeriod), false);
      if(this.vistaHistorial)this.setUpGraphHistoric(this.dashboardDetailHistoric.GetByYearAndType(this.year, this.parameterTypePeriod), false);

    }
    if (event.nextId == 'mxn') {
      this.typeValue = 'MXN';
      this.parameterTypeData = true;
      if(!this.vistaHistorial)this.setUpGraph(this.dashboardDetail.GetByYearAndType(this.year, this.parameterTypePeriod), true);
      if(this.vistaHistorial)this.setUpGraphHistoric(this.dashboardDetailHistoric.GetByYearAndType(this.year, this.parameterTypePeriod), true);
    }
  }

  GetPeriodRpu(event: NgbTabChangeEvent) {
    this.chartLabels = [];
    this.barChartData = [];
    if (event.nextId == "month") {
      this.parameterTypePeriod = false;
      if(!this.vistaHistorial)this.setUpGraph(this.dashboardDetail.GetByYearAndType(this.year, false), this.parameterTypeData);
      if(this.vistaHistorial)this.setUpGraphHistoric(this.dashboardDetailHistoric.GetByYearAndType(this.year, false), this.parameterTypeData);
    }
    if (event.nextId == "year") {
      this.parameterTypePeriod = true;
      if(!this.vistaHistorial)this.setUpGraph(this.dashboardDetail.GetByYearAndType(this.year, true), this.parameterTypeData);
      if(this.vistaHistorial)this.setUpGraphHistoric(this.dashboardDetailHistoric.GetByYearAndType(this.year, true), this.parameterTypeData);
    }
  }

  GetVista(event: NgbTabChangeEvent) {

    if (event.nextId == 'tariff') {
      this.vistaHistorial = false;
      this.GetDashboard();
    }
    if (event.nextId == 'historic') {
      this.vistaHistorial = true;
      this.GetDashboardHistoric();
    }
  }

  UpdateYear(event) {
    this.chartLabels = [];
    this.barChartData = [];
    if (event == 'Historial') {
      this.year = 0;
    } else {
      this.year = event;
    }
    if(!this.vistaHistorial)this.setUpGraph(this.dashboardDetail.GetByYearAndType(event == "Historial" ? 0 : event, this.parameterTypePeriod), this.parameterTypeData);
    if(this.vistaHistorial)this.setUpGraphHistoric(this.dashboardDetailHistoric.GetByYearAndType(this.year, this.parameterTypePeriod), this.parameterTypeData) ;
  }

  GetDashboard() {
    this.chartLabels = [];
    this.barChartData = [];
    this.circuitMarkers.forEach(circuit => {
      circuit.hideMarker();
    });
    this.service.GetDashboardEnergy(this.currentProjectService.getCurrentProject().energy_year,this.filterApbt, this.filterApmt, this.filterOthersTariffs, this.filterWithout, this.filterMensual, this.filterBimestral).subscribe(
      response => {
        if (response.circuits.length > 0) {
          const circuits = response.circuits.map(circuit => new CircuitRpu(circuit));
          this.addCircuits(circuits);
        }
        // tslint:disable-next-line:max-line-length
        const mapDashboard = new DashboardDetail(response);
        this.dashboardDetail = mapDashboard;
        this.setUpGraph(mapDashboard.GetByYearAndType(this.year, this.parameterTypePeriod), this.parameterTypeData);
        this.lastmonth = mapDashboard.GetLastValue();

      }
    );
  }

  GetDashboardHistoric() {
    this.chartLabels = [];
    this.barChartData = [];
    this.service.GetDashboardEnergyHistoric().subscribe(
      response => {
        const mapDashboardHistoric = new DashboardDetailHistoric(response);
        this.dashboardDetailHistoric = mapDashboardHistoric;
        this.setUpGraphHistoric(mapDashboardHistoric.GetByYearAndType(this.year, this.parameterTypePeriod), this.parameterTypeData) ;
      }
    );
  }

  setUpGraph(periods: Period[], parameterTypeData) {
    this.elements = JSON.parse(JSON.stringify(periods));
    this.elements.reverse();
    periods.map(x => {return this.parameterTypePeriod? x.year : x.monthString + "-" + x.year}).forEach(label => {
      this.chartLabels.push(label);
    });
    this.barChartData = [
      { data: periods.map(x => {return (parameterTypeData == false ? Math.round(x.estimatedConsumptionKwh) : Math.round(x.estimatedConsumptioMxn))}), label: 'Estimado', type: 'line', backgroundColor: 'rgba(0,0,0,0)' },
      { data: periods.map(x => {return (parameterTypeData == false ? Math.round(x.kwh) : Math.round(x.mxn))}), label: 'Consumido', stack: 'a', },
      { data: periods.map(x => {return (parameterTypeData == false ? Math.round(x.aditionalKwh) : Math.round(x.aditionalMxn))}), label: 'Ad Consumido', stack: 'a', },
      { data: periods.map(x => {return (parameterTypeData == false ? Math.round(x.savingKwh) + Math.round(x.aditionalKwh) :  Math.round(x.savingMxn) + Math.round(x.aditionalMxn))}), label: 'Ahorro', stack: 'a', },
      // { data: periods.map(x => {return (parameterTypeData == false ? Math.round(x.aditionalKwh) : Math.round(x.aditionalMxn))}), label: 'Ad Ahorro', stack: 'a', },

    ];
  }



  setUpGraphHistoric(periods: PeriodHistoric[], parameterTypeData) {
    this.elementsHistoric = JSON.parse(JSON.stringify(periods));
    this.elementsHistoric.reverse()
    periods.map(x => {return this.parameterTypePeriod? x.year : x.monthString + "-" + x.year}).forEach(label => {
      this.chartLabels.push(label);
    });
    this.barChartData = [
      { data: periods.map(x => {return (parameterTypeData == false ? x.kwh : Math.round(x.mxn))}), label: 'Consumido', stack: 'a', },
    ];
  }

  setApbtClasses() {
    const classes = {
      'btn-primary': this.filterApbt ? true : false,
      'oe--btn--text-white': this.filterApbt ? true : false,
      'oe--btn--border': this.filterApbt ? true : true
    }
    return classes
  }

  setApmtClasses() {
    const classes = {
      'btn-primary': this.filterApmt ? true : false,
      'oe--btn--text-white': this.filterApmt ? true : false,
      'oe--btn--border': this.filterApmt ? true : true
    }
    return classes
  }

  setFiltersOthersClasses() {
    const classes = {
      'btn-primary': this.filterOthersTariffs ? true : false,
      'oe--btn--text-white': this.filterOthersTariffs ? true : false,
      'oe--btn--border': this.filterOthersTariffs ? true : true
    }
    return classes
  }

  setWithoutClasses() {
    const classes = {
      'btn-primary': this.filterWithout ? true : false,
      'oe--btn--text-white': this.filterWithout ? true : false,
      'oe--btn--border': this.filterWithout ? true : true
    }
    return classes
  }

  clickFilterApbt() {
    if (this.filterApbt) {
      this.filterApbt = false;
    } else {
      this.filterApbt = true;
    }
    this.GetDashboard();
  }

  clickFilterApmt() {
    if (this.filterApmt) {
      this.filterApmt = false;
    } else {
      this.filterApmt = true;
    }
    this.GetDashboard();
  }

  clickFilterOthers() {
    if (this.filterOthersTariffs) {
      this.filterOthersTariffs = false;
    } else {
      this.filterOthersTariffs = true;
    }
    this.GetDashboard();
  }

  clickFilterWithout() {
    if (this.filterWithout) {
      this.filterWithout = false;
    } else {
      this.filterWithout = true;
    }
    this.GetDashboard();
  }

  setMensualClasses() {
    const classes = {
      'btn-primary': this.filterMensual ? true : false,
      'oe--btn--text-white': this.filterMensual ? true : false,
      'oe--btn--border': this.filterMensual ? true : false
    }
    return classes
  }

  setBimestralClasses() {
    const classes = {
      'btn-primary': this.filterBimestral ? true : false,
      'oe--btn--text-white': this.filterBimestral ? true : false,
      'oe--btn--border': this.filterBimestral ? true : false
    }
    return classes
  }

  clickFilterMensual() {
    if (this.filterMensual == true) {
      this.filterMensual = false;
    } else {
      this.filterMensual = true;
    }
    this.GetDashboard();
  }

  clickFilterBimestral() {
    if (this.filterBimestral == true) {
      this.filterBimestral = false;
    } else {
      this.filterBimestral = true;
    }
    this.GetDashboard();
  }
}
