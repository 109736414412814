import { LuminaireEnum } from './luminaire-enum';
import { Technology } from './technology';
import { LightTask } from './light-task';
import { ProblemEnum } from './problem-enum';
import { Repair } from './repair';
import { Installation } from './installation';
import { ProjectWattage } from './project_wattage';


interface LuminaireInterface {
  id: string,
  type: number,
  name: string,
  comment: string,
  technology: Technology,
  has_photocell: boolean,
  has_photocellString: string,
  tasks: LightTask[],
  reference_id: string;
  problems: number[];
  installation: Installation;
  status: string;
  location: {
    latitude: number,
    longitude: number
  }
  repair: Repair;
  project_wattage: ProjectWattage;
  is_in_task: boolean;
  progress: number
  progressEnum: string
  progressIconEnum: string
}

export class Luminaire implements LuminaireInterface {
  id: string;
  type: number;
  name: string;
  comment: string;
  technology: Technology;
  has_photocell: boolean;
  has_photocellString: string;
  tasks: LightTask[];
  reference_id: string;
  problems: number[];
  installation: Installation;
  status: string;
  location: Location
  event: string;
  linkedSituation: string;
  marker: google.maps.Marker;
  isSelected: Boolean;
  repair: Repair;
  project_wattage: ProjectWattage;
  is_in_task: boolean;
  progress: number;
  progressEnum: string;
  progressIconEnum: string;

  readonly markerIcons = {
    linkedOutsideTheTask: {
      hover: 'assets/img/svg/ic_link_hover.svg',
      normal: 'assets/img/svg/linkGray.svg',
      selected: 'assets/img/svg/lamppost_selected-0.svg',
      circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
    },
    linked: {
      hover: 'assets/img/svg/ic_link_hover.svg',
      normal: 'assets/img/svg/link.svg',
      selected: 'assets/img/svg/lamppost_selected-0.svg',
      circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
    },
    single: {
      normal: 'assets/img/svg/normal.svg',
      selected: 'assets/img/svg/selected.svg'
    },
    singleOutsideTheTask: {
      normal: 'assets/img/svg/normalGray.svg',
      selected: 'assets/img/svg/selected.svg'
    },
    assign: {
      selected: 'assets/img/svg/ic_install_selected-',
      circuitShowing: 'assets/img/svg/ic_install_linked-',
      normal: 'assets/img/svg/ic_install_pin-',
      hover: 'assets/img/svg/ic_install_hover-',
    }
  }

  constructor(attrs, linkedSituation: string = 'single', event: string = 'normal') {
    this.id = attrs.id;
    this.type = attrs.type;
    this.name = LuminaireEnum[attrs.type];
    this.comment = attrs.comment == null ? '' : attrs.comment.trim();
    this.technology = new Technology(attrs.technology);
    this.has_photocell = attrs.has_photocell;
    this.has_photocellString = attrs.has_photocell ? 'Si' : 'No';
    this.tasks = attrs.tasks ? attrs.tasks.map((task: LightTask) => new LightTask(task)) : null;
    this.reference_id = attrs.reference_id;
    this.problems = attrs.problems;
    this.status = attrs.status;
    this.location = new Location(attrs.location);
    this.linkedSituation = linkedSituation;
    this.event = event;
    this.isSelected = false;
    this.repair = attrs.repair != null ? new Repair(attrs.repair) : null;
    this.installation = attrs.installation ? new Installation(attrs.installation) : null;
    this.project_wattage = attrs.project_wattage ? new ProjectWattage(attrs.project_wattage) : null;
    this.is_in_task = attrs.is_in_task;
    this.progress = attrs.progress;
    this.progressEnum = this.progress != null ? ProgressEnum[this.progress] : null;
    this.progressIconEnum = this.progress != null ? ProgressIconrEnum[this.progress] : null;
  }

  get_icon(): object {
    return this.progress != null && this.linkedSituation === 'assign'
      ?
      {
        url: `${this.markerIcons['assign'][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.progressIconEnum}.svg`,
        scaledSize: new google.maps.Size(15, 15)
      }
      :
      {
        url: this.markerIcons[this.linkedSituation][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
      };
  }

  upadeIcon(event: string): void {
    this.event = event;
    this.marker.setIcon(this.get_icon());
  }

  paintMarker(map: any): void {
    const latitude = this.location.latitude;
    const longitude = this.location.longitude;
    this.event = this.isSelected ? 'selected' : 'normal';
    this.marker = new google.maps.Marker({
      title: this.reference_id == null ? this.id : this.reference_id.toString(),
      position: new google.maps.LatLng(latitude, longitude),
      icon: this.get_icon(),
      map: map
    });
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }

  setMap(map: any): void {
    this.marker.setMap(map);
  }
}

interface LocationInterface {
  latitude: number;
  longitude: number;
}
export class Location implements LocationInterface {
  latitude: number;
  longitude: number;

  constructor(attrs: any) {
    this.latitude = attrs.latitude;
    this.longitude = attrs.longitude;
  }
}

export enum ProgressEnum {
  'Censo rápido',
  'Censo infraestructura',
  'Instalación',
}

enum ProgressIconrEnum {
  '485_C',
  '190_C',
  '139_C',
}




