import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-general-view',
  templateUrl: './general-view.component.html',
  styleUrls: ['./general-view.component.scss']
})
export class GeneralViewComponent implements OnInit {

  constructor() { }

  ngOnInit() { }



 // ngOnDestroy() {
  //  this.destroy$.next(true);
 //   this.destroy$.unsubscribe();
 // }



}
