import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { LuminaireProjectWattage, CircuitProjectWattage } from '@app/models/project_wattage';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { LuminairesPendingService } from '../luminaires-pending.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { Router } from '@angular/router';
import { StyleMap } from '@app/models/styleMap';

@Component({
  selector: 'app-luminaires-pending-map',
  templateUrl: './luminaires-pending-map.component.html',
  providers: [CurrentProjectService, MapFuncAuxService]
})
export class LuminairesPendingMapComponent implements OnInit {
  @Input() luminaires: LuminaireProjectWattage[];
  @Input() circuits: CircuitProjectWattage[];
  @ViewChild('gmap') gmapElement: any;
  styleMap: StyleMap = new StyleMap();
  map: google.maps.Map;
  lstInfowindows = [];

  constructor(
    private currentProjectService: CurrentProjectService,
    private mapFuncAux: MapFuncAuxService,
    private router: Router,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.paintMap();
    this.paintCircuists();
    this.paintLuminaires();
  }

  paintMap(){
    let latitude = 19.4326009;;
    let longitude = -99.1333416;
    let zoom = 5;
    let project = this.currentProjectService.getCurrentProject()
    let mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    let request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };


    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    let service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if(status === google.maps.places.PlacesServiceStatus.OK){
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })

    this.map.addListener('click', () => {
      this.hideCircuit();
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    });
  }

  paintCircuists(){
    this.circuits = this.circuits.map( circuit => new CircuitProjectWattage(circuit));
    for(let circuit of this.circuits){
      circuit.paintMarker(this.map);
      circuit.marker.addListener('click', () => {
        this.hideCircuit();
        this.showInfoWindowCircuit(circuit);
      });
    }
  }


  private paintLuminaires(): void{
    for(let luminaire of this.luminaires){
      if(luminaire.linkedSituation != 'assign'){
        luminaire.linkedSituation = 'citizen';
      }
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.hideCircuit();
        this.showInfoWindowLuminaire(luminaire);
      });
    }
  }

  private hideCircuit(): void{
    let _circuit = this.circuits.find(__circuit => __circuit.event == 'circuitShowing');
    if(_circuit){
      _circuit.event = _circuit.isSelected ? 'selected' : 'normal';
      _circuit.upadeIcon('normal');
    }
  }

  private showInfoWindowCircuit(circuit: any){
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    let strInfo = `
    <div class="container my-0 mx-0 py-0 px-0">
      <div class="row mx-0 my-0">
        <div class="col-12">
          <div class="row">
            <div class="col-12 px-0">
              <strong>${ circuit.identifier ? 'Referencia' : 'ID' }</strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12 px-0">${ circuit.identifier || circuit.id }</div>
          </div>
          <div class="row">
            <div class="col-6 px-0">
              <a href="u/circuits/${ circuit.id }" onclick="return false;">
                <input id="seeCircuit" type="button" style="cursor:pointer;" value="Ver circuito" class="oe--button--info mr-1">
              </a>
            </div>
            <div class="col-6 px-0 d-flex justify-content-end">
              <a href="u/tasks/${ circuit.most_recent_installation_task.id }" onclick="return false;">
                <input id="seeTask" type="button" style="cursor:pointer;" value="Ver tarea" class="oe--button--info mr-1">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
    let infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 350
    });
    infowindow.open(this.map,  circuit.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      let seeTask = document.getElementById('seeTask');
      let seeCircuit = document.getElementById('seeCircuit');
      seeTask.addEventListener('click', () => this.router.navigate([`/u/tasks/${circuit.most_recent_installation_task.id}`]));
      seeCircuit.addEventListener('click', () => this.router.navigate([`/u/circuits/${circuit.id}`]));
    }, 600);
  }

  private showInfoWindowLuminaire(luminaire: any){
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    let strInfo = `
    <div class="container my-0 mx-0 py-0 px-0">
      <div class="row mx-0 my-0">
        <div class="col-12">
          <div class="row">
            <div class="col-12 px-0">
              <strong>${ luminaire.reference_id ? 'Referencia' : 'ID' }</strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12 px-0">${ luminaire.reference_id || luminaire.id }</div>
          </div>
          <div class="row">
            <div class="col-6 px-0">
              <a href="u/luminaires/${ luminaire.id }" onclick="return false;">
                <input id="seeLuminaire" type="button" style="cursor:pointer;" value="Ver luminairia" class="oe--button--info mr-1">
              </a>
            </div>
            <div class="col-6 px-0 d-flex justify-content-end">
              <a href="u/tasks/${ luminaire.most_recent_installation_task.id }" onclick="return false;">
                <input id="seeTask" type="button" style="cursor:pointer;" value="Ver tarea" class="oe--button--info mr-1">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
    let infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 350
    });
    infowindow.open(this.map,  luminaire.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      let seeTask = document.getElementById('seeTask');
      let seeLuminaire = document.getElementById('seeLuminaire');
      seeTask.addEventListener('click', () => this.router.navigate([`/u/tasks/${luminaire.most_recent_installation_task.id}`]));
      seeLuminaire.addEventListener('click', () => this.router.navigate([`/u/luminaires/${luminaire.id}`]));
    }, 600);
  }

  public changeTypeMap(value){
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

}
