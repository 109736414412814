import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { AuthService } from '@app/shared/auth/auth.service';
import { GenericTable } from '@app/models/generic-table';
import { map } from 'rxjs/operators';
import { CFEImports, DetailCFEImports } from '@app/models/imports-cfe';

@Injectable({
  providedIn: 'root'
})
export class CfeFilesService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) { }


  validateFiles(formData: FormData) {
    return this.httpClient.post(
      `${environment.SERVER_ENERGY}/api/ImportRPU/Validate_Import_Process`, formData
    );
  }

  createImportId(user_id, is_HistoricActive) {
    const httpParams = new HttpParams().set('is_HistoricActive', is_HistoricActive).set('user_id', user_id);
    return this.httpClient.get<{ import_id: string }>(
      `${environment.SERVER_ENERGY}/api/ImportRPU/Create_Import`, { params: httpParams }
    );
  }

  getImports(code, month, year, page) {
    const httpParams = new HttpParams()
      .set('code', code)
      .set('month', month)
      .set('year', year)
      .set('page', page);
    return this.httpClient.get<GenericTable<CFEImports>>(
      `${environment.SERVER_ENERGY}/api/ImportRPU/GetUploads`, { params: httpParams }
    ).pipe(
      map(elements => new GenericTable<CFEImports>(elements))
    );
  }

  generateImports(form) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id)
    return this.httpClient.post(
      `${environment.SERVER_CONFIG}/api/Import/GenerateImports`, form, { headers: httpHeaders }
    );
  }

  downloadXLS(month, year, reference_id) {
    // console.log(month,year,reference_id);
    const httpParams = new HttpParams()
      .set('reference_id', reference_id)
      .set('month', month)
      .set('year', year);

    this.httpClient.get(
      `${environment.SERVER_ENERGY}/api/ExportRPUSXLS/Download_Excel_RPUS`, { responseType: 'blob', params: httpParams }
    ).subscribe(file => {
      this.downloadProcess(file, `CFE.XLSX`);
    });
  }

  private downloadProcess(file, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([file], { type: 'application/vnd.ms-excel' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}
