import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashCase'
})
export class DashCasePipe implements PipeTransform {
  transform(value: string): string {
    return value
      .toLowerCase()
      .split(' ')
      .join('-');
  }
}