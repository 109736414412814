export enum PrimarySwichtCurrentCapacityEnum {
    '  10',
    '  15',
    '  20',
    '  30',
    '  40',
    '  50',
    '  60',
    '  70',
    '  75',
    '  80',
    '  90',
    '  100',
    '  125',
    '  150',
    '  160',
    '  175',
    '  200',
    '  225',
    '  250'
}