import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { EditUserService } from '@app/pages/users/edit-user/edit-user.service';
import { DevicesService } from './devices.service';
import { GetDevices } from '@app/models/devices';
import { GenericTable } from '@app/models/generic-table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SimpleSmartGroup } from '@app/models/smart-devices';


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {

  @ViewChild('contentMaterialModal') materialModal: any;
  devicesForm: FormGroup;
  simpleSmartGroup: SimpleSmartGroup[];
  showMap = false;
  searchForm: FormGroup
  pages: number;
  eventsSubject: Subject<void> = new Subject<void>();
  devicesStatus;


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private devicesService: DevicesService,
    private toaster: Toastr,
    private modalService: NgbModal,
  ) {
    this.searchForm = this.fb.group({
      device: [''],
      circuit: [''],
      device_status: [''],
      circuit_status: [''],     
      orderBy: ['byName'], 
      group: [''],
      delay : ['']
      
    });

    this.devicesForm = this.fb.group({
      name: [null, Validators.required],
      register_key: [null, Validators.required],
      geenapsis_device_id: [null, Validators.required],
    });

  }

  ngOnInit() {
    this.devicesService.GetOnAndOffDevices().subscribe(res=>{
      this.devicesStatus = res;
    })
    this.getQueryParams();
    this.getSmartGroups();
  }

  private getSmartGroups() {
    this.devicesService.getSimpleSmartGroups().subscribe(response => {
      this.simpleSmartGroup = response;
    });
  }

  getQueryParams() {
    this.route.queryParamMap.subscribe(params => {
      this.searchForm.patchValue({
        device: params.has('device') ? params.get('device') : '',
        circuit: params.has('circuit') ? params.get('circuit') : '',
        device_status: params.has('device_status') ? params.get('device_status') : '',
        circuit_status: params.has('circuit_status') ? params.get('circuit_status') : '', 
        orderBy: params.has('orderBy') ? params.get('orderBy') : 'byName',     
        group: params.has('group') ? params.get('group') : '',
        delay: params.has('delay') ? params.get('delay') : '',
      });

      this.pages = params.has('page') ? parseInt(params.get('page')) : 0;

      this.eventsSubject.next();
    });
  }

  onSearch() {
    this.router.navigate(['/u/smart/devices'],
      {
        relativeTo: this.route,
        queryParams: {
          page: this.pages,
          device: this.searchForm.get('device').value,
          circuit: this.searchForm.get('circuit').value,
          device_status: this.searchForm.get('device_status').value,
          circuit_status: this.searchForm.get('circuit_status').value,   
          orderBy: this.searchForm.get('orderBy').value,      
          group: this.searchForm.get('group').value,
          delay: this.searchForm.get('delay').value
        }
      });
  }

  saveDevice() {
    if (this.devicesForm.valid) {
      this.devicesService.addDevice(this.devicesForm.getRawValue()).subscribe(
        (response) => {
          if (response['value'] == true) {
            this.toaster.success('Se agrego correctamente');
            this.eventsSubject.next();
          }
          else {
            this.toaster.singleError('Error al agregar la device');
          }
        }
      );
      this.modalService.dismissAll();

    }

  }

  showModal() {
    this.devicesForm.reset();
    this.modalService.open(this.materialModal, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  onClose() {
    this.modalService.dismissAll();
  }



}
