import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { EditTroopPayload } from './edit-troop-payload';
import { GetTroopResponse } from '@models/get-troop-response';
import { Troop } from 'app/models/troop';

@Injectable()

export class EditTroopService {
  constructor(private httpClient: HttpClient) {}

  // editTroop(troop: Troop, editPayload: EditTroopPayload): Observable<any> {
  //   return this.httpClient.put<any>(
  //     `${environment.SERVER_URL}/troops/${troop.id}`,
  //     editPayload.toJson()
  //   );
  // }
  editTroop(troop: IEditTroop): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_WAREHOUSE}/api/Troops/UpdateTroop`, troop
    );
  }
  // getTroop(troop_id: string): Observable<GetTroopResponse> {
  //   return this.httpClient.get<GetTroopResponse>(
  //     `${environment.SERVER_URL}/troops/${troop_id}`
  //   );
  // }
  getTroop(troop_id: string): Observable<IEditTroop> {
    const params: HttpParams = new HttpParams()
    .set('id', troop_id)
    return this.httpClient.get<IEditTroop>(
      `${environment.SERVER_WAREHOUSE}/api/Troops/GetTroop`, { params: params }
    );
  }
}
