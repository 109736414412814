import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TicketsService } from './tickets.service';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Ticket } from './ticket';
import { SourceTicketsMapping } from '@app/models/source-tickets';
import { GenericTable } from '@app/models/generic-table';
import { AuthService } from '@shared/auth/auth.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html'
})
export class TicketsComponent implements OnInit {
  ticketsSearchForm: FormGroup;
  tickets: GenericTable<Ticket>;
  sort_by = true;
  isClient : Boolean;
  constructor(
    private authService: AuthService,
    private ticketsService: TicketsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: Toastr,
    private router: Router,
  ) {

    this.isClient = this.authService.isClient();
    this.ticketsSearchForm = fb.group({
      reference: "",
      citizen_name: "",
      status: "",
      source: [""],
      finished_at: [""],
      start_date: [""],
      end_date: [""],
      sort_by: true,
      is_report_advance: false
    });
  }

  ngOnInit() {
    this.getTickets(0);
  }

  getTickets(page) {
    this.ticketsSearchForm.patchValue({ sort_by: this.sort_by });
    this.ticketsService.getTickets(this.ticketsSearchForm.getRawValue(), page).subscribe(response => {
      this.tickets = response;
    }, error => {
      this.toastr.error(error);
    });
  }

  private sourceMapping: SourceTicketsMapping = {
    0: 'Call Center',
    1: 'Supervisión Municipal',
    2: 'Supervisión de Concesión',
    3: 'Reporte Directo',
    4: 'Dependencia Municipal',
    5: 'Redes Sociales',
    6: 'Sitio Web',
    7: 'Control de Calidad'
  };

  getSourceDescription(statusCode: number): string | undefined {
    return this.sourceMapping[statusCode];
  }

  filterTickets(tickets: Ticket[]): Ticket[] {
    // Si no es cliente, retorna todos los tickets
    if (!this.isClient) {
      return tickets;
    }
    
    // Si es cliente, filtra los tickets removiendo los que tengan source "Supervisión de Concesión"
    return tickets.filter(ticket => ticket.source !== 2);
  }

  pageChange(page) {
    this.getTickets(page - 1);
  }

  ExportDetailTicketsXls(is_report_advance: boolean) {

    const startDate = this.ticketsSearchForm.get('start_date').value;
    const endDate = this.ticketsSearchForm.get('end_date').value;

    if (startDate == '' || endDate == '') { this.toastr.singleError('Seleccione un rango de fechas'); return; }

    this.ticketsSearchForm.patchValue({ is_report_advance: is_report_advance });
    this.ticketsService.ExportXLSXDetailTickect(this.ticketsSearchForm.getRawValue());
  }

  changeSort_by() {
    if (this.sort_by) {
      this.sort_by = false;
    } else {
      this.sort_by = true;
    }
    this.getTickets(0);
  }

}
