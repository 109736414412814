export interface IRequestedMaterial {
    req: string,
    envia: string,
    recibe: string,
    proyecto: string,
    estatus: string,
    entradaOSalida: string,
    materialList: [{
        material: string,
        quantity: string
    }],
    date: Date
}

export class RequstedMaterial implements IRequestedMaterial {
    req: string;
    envia: string;
    recibe: string;
    proyecto: string;
    estatus: string;
    entradaOSalida: string;
    materialList: [{
        material: string,
        quantity: string
    }];
    date: Date;

    constructor(attrs: any) {
        this.req = attrs.req;
        this.envia = attrs.envia;
        this.recibe = attrs.recibe;
        this.proyecto = attrs.proyecto;
        this.estatus = attrs.estatus;
        this.entradaOSalida = attrs.entradaOSalida;
        this.materialList = attrs.materialList;
        this.date = new Date(attrs.date);
    }

}
