import { FormGroup } from '@angular/forms';

import { Luminaire } from './luminaire';
import { Technology } from './technology';
import { LuminaireViewGeneral } from './project_wattage';

export class LuminairePayload {
    id: string;
    type: number;
    name: string;
    comment: string;
    technology: number;
    has_photocell: boolean;
    has_photocellString: string;
    location: {
      latitude: number
      longitude: number
    }

  populateAngularForm(luminaireForm: FormGroup) {
    this.id =
    this.type = luminaireForm.get('type').value;
    this.technology = luminaireForm.get('technology').value;
    this.has_photocell = luminaireForm.get('has_photocell').value;
    this.comment = luminaireForm.get('comment').value;

    let location = {
        latitude: luminaireForm.controls.latitude.value,
        longitude: luminaireForm.controls.longitude.value
    }
    this.location = location
  }

  populateJavascriptForm(luminaire: any){
    let txtArea = document.getElementById('textAreaComment');
    
    if(!txtArea)
      return
    this.id = luminaire.id;
    this.comment = txtArea["value"];
    this.type = luminaire.type;
    this.technology = luminaire.technology.id;
    this.has_photocell = luminaire.has_photocell;
    this.location = luminaire.location;
  }

  toJson(): Object {
    const json = {
        luminaire: {
            type: this.type,
            technology: this.technology,
            has_photocell: this.has_photocell,
            comment: this.comment,
            location: this.location
        }
    };

    return json;
  }
}
