import { SurveyArea } from './survey-area';
import { User } from './user';
import { TypeTask } from './type-task';
import { Luminaire } from './luminaire';
import { Circuit } from './circuit';
import { Lamppost } from './lamppost';
import { MaterialDueToday } from './warehouse-stats';

interface TaskInterface {
  id: string
  reference_id: string
  type: TypeTask
  municipality: string
  parent_task_id: string
  sector: string
  comment: string
  centroid: {
    "latitude": number,
    "longitude": number
  }
  number_of_points: number
  created_at: string
  deadline: string
  last_interaction_at: string
  circuits: Circuit[]
  survey_areas: SurveyArea[]
  lampposts: Lamppost[]
  luminaires: Luminaire[]
  user: User
  is_finished: boolean
  suggested_installations: MaterialDueToday[];
}

export class Task implements TaskInterface {
    id: string
    reference_id: string
    type: TypeTask
    municipality: string
    parent_task_id: string
    sector: string
    comment: string
    centroid: {
      "latitude": number,
      "longitude": number
    }
    number_of_points: number
    created_at: string
    deadline: string
    circuits: Circuit[]
    last_interaction_at: string
    survey_areas: SurveyArea[]
    lampposts: Lamppost[]
    luminaires: Luminaire[]
    user: User
    is_finished: boolean
    suggested_installations: MaterialDueToday[]

  constructor(attrs) {
    this.id = attrs.id;
    this.type = new TypeTask(attrs.type);
    this.municipality = attrs.municipality;
    this.parent_task_id = attrs.parent_task_id ? attrs.parent_task_id : '';
    this.reference_id = attrs.reference_id ? attrs.reference_id : '';
    this.sector = attrs.sector;
    this.comment = attrs.comment;
    this.centroid = attrs.centroid
    this.number_of_points = attrs.number_of_points;
    this.created_at = attrs.created_at;
    this.deadline = attrs.deadline;
    this.circuits = attrs.circuits ? attrs.circuits.map((circuit: Circuit) => new Circuit(circuit)) : null;
    this.last_interaction_at = attrs.last_interaction_at;
    this.survey_areas = attrs.survey_areas;
    this.lampposts = attrs.lampposts ? attrs.lampposts.map((lamppost: Lamppost) => new Lamppost(lamppost)) : null;
    this.luminaires = attrs.luminaires ? attrs.luminaires.map((luminaire: Luminaire) => new Luminaire(luminaire)) : null;
    this.user = attrs.user ? new User(attrs.user) : null;
    this.is_finished = attrs.is_finished;
    this.suggested_installations = attrs.suggested_installations ? attrs.suggested_installations.map( mat => new MaterialDueToday(mat)) : [];
  }
}
