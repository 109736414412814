import { Component, OnInit } from '@angular/core';
import { LogMaintanenceService } from './log-maintanence.service';
import { isUndefined } from 'util';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-log-maintanence',
  templateUrl: './log-maintanence.component.html',
  styleUrls: ['./log-maintanence.component.scss']
})
export class LogMaintanenceComponent implements OnInit {

  finalDate
  initialDate
  log_type = 6

  elementsRecovered: any[] = [];

  constructor(
    private printReport: LogMaintanenceService,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
  }

  printLog() {

    if (isUndefined(this.initialDate) || isUndefined(this.finalDate)) {
      this.toaster.error('Asigne las fechas de busqueda');
      return;
    }

    const initial = this.initialDate['year'] + '-' + this.initialDate['month'] + '-' + this.initialDate['day']
    const final = this.finalDate['year'] + '-' + this.finalDate['month'] + '-' + this.finalDate['day']

    if (new Date(initial) >= new Date(final)) {
      this.toaster.error('La fecha final debe ser menor a la inicial');
      return;
    }

    this.printReport.getLogList(initial, final, this.log_type).subscribe(response => {
      if (response.length === 0) {
          this.toaster.error('No se encontro ningun registro');
      } else {
        this.elementsRecovered = response;
      }
    });
  }

  exportData(typeFile: string) {
    const initial = this.initialDate['year'] + '-' + this.initialDate['month'] + '-' + this.initialDate['day']
    const final = this.finalDate['year'] + '-' + this.finalDate['month'] + '-' + this.finalDate['day']

    this.printReport.downloadLogMaintenance(initial, final, typeFile, this.log_type);
  }

}
