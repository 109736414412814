interface CitizenLuminaireInterface {
  id: string;
  reference_id: string;
  location: {
    latitude: number,
    longitude: number
  }
  isSelected: boolean;
  marker: google.maps.Marker;
}

export class CitizenLuminaire implements CitizenLuminaireInterface {
    id: string;
    reference_id: string;
    location: Location;
    isSelected: boolean;
    marker: google.maps.Marker;

    readonly markerIcons = {
      normal: 'assets/img/svg/ic_single_normal.svg',
      selected: 'assets/img/svg/link_selected.svg',
    }

  constructor(attrs) {
    this.id = attrs.id;
    this.isSelected = false;
    this.reference_id = attrs.reference_id;
    this.location = new Location(attrs.location);
  }

  get_icon(): object {
    return this.isSelected ? { url: `${this.markerIcons['selected']}` }
      : { url: `${this.markerIcons['normal']}`, scaledSize: new google.maps.Size(24, 34) }
  }

  upadeIcon(): void {
    this.marker.setIcon(this.get_icon());
  }

  paintMarker(map: any): void {
    let latitude = this.location.latitude;
    let longitude = this.location.longitude;
    this.marker = new google.maps.Marker({
        title: this.id,
        position: new google.maps.LatLng(latitude,longitude),
        icon: this.get_icon(),
        map: map
    });
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }

  setMap(map: any): void {
    this.marker.setMap(map);
  }

  toggleSelect(): void{
    this.isSelected = !this.isSelected;
    this.upadeIcon();
  }
}

interface LocationInterface {
  latitude: number;
  longitude: number;
}
class Location implements LocationInterface {
  latitude: number;
  longitude: number;

  constructor(attrs: any) {
      this.latitude = attrs.latitude;
      this.longitude = attrs.longitude;
  }
}