import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MaterialCategoriesResponse } from '@models/material_categories-response';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { MaterialCategoryResponse } from '@models/material_category-response';
import { ProjectRecoveryStock } from '@models/project_recovery_stock';

@Injectable({
  providedIn: 'root'
})
export class RecoveredMaterialsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getChargedValue():Observable<MaterialCategoriesResponse> {
    return this.httpClient
      .get<MaterialCategoriesResponse>(`${environment.SERVER_URL}/material_categories`);
  }

  getCategory(id: string):Observable<MaterialCategoryResponse> {
    return this.httpClient
      .get<MaterialCategoryResponse>(`${environment.SERVER_URL}/material_categories/${ id }/project_recovery_stocks`);
  }
  updateProjectRecoveryStock(projectRecoveryStock: ProjectRecoveryStock): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/project_recovery_stocks/${projectRecoveryStock.id}`,
      {
        project_recovery_stock: {
          quantity: projectRecoveryStock.quantity
        }
      }
    );
  }

  transferToInventory(projectRecoveryStock: ProjectRecoveryStock, quantity: number): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_URL}/project_recovery_stocks/${projectRecoveryStock.id}/transfer_to_inventory`, 
      {
        project_recovery_stock: {
          quantity: quantity
        }
      }
    );
  }
}
