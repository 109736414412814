import { Material } from "./material";
import { DiagnosisEnum } from './diagnosis-enum';
import { StatusEnum } from './status-enum';
import { WorkDoneEnum } from "./work-done-enum";
import { RepairProblemEnum } from "./repair-problem-enum";

interface RepairInterface {
    id: string
    diagnosis: number
    status: number
    work_done: number
    problem: number
    comment: string
    first_problem_image_url: string
    second_problem_image_url: string
    first_material_image_url: string
    second_material_image_url: string
    third_material_image_url: string
    fourth_material_image_url: string
    first_repair_image_url: string
    second_repair_image_url: string
    used_items: Used_item[]
    recovered_items: Recovered_item[]
}

export class Repair implements RepairInterface {
    id: string;
    diagnosis: number;
    diagnosisName: string;
    status: number;
    statusName: string;
    work_done: number;
    work_doneName: string;
    problem: number;
    problemName: string;
    comment: string;
    first_problem_image_url: string;
    second_problem_image_url: string;
    first_material_image_url: string;
    second_material_image_url: string;
    third_material_image_url: string;
    fourth_material_image_url: string;
    first_repair_image_url: string;
    second_repair_image_url: string;
    used_items: Used_item[];
    recovered_items: Recovered_item[];

    constructor(attrs: any) {
        this.id = attrs.id;
        this.diagnosis = attrs.diagnosis;
        this.diagnosisName = DiagnosisEnum[this.diagnosis];
        this.status = attrs.status;
        this.statusName = StatusEnum[this.status];
        this.work_done = attrs.work_done;
        this.work_doneName = WorkDoneEnum[this.work_done];
        this.problem = attrs.problem;
        this.problemName = RepairProblemEnum[this.problem];
        this.comment = attrs.comment;
        this.first_problem_image_url = attrs.first_problem_image_url;
        this.second_problem_image_url = attrs.second_problem_image_url;
        this.first_material_image_url = attrs.first_material_image_url;
        this.second_material_image_url = attrs.second_material_image_url;
        this.third_material_image_url = attrs.third_material_image_url;
        this.fourth_material_image_url = attrs.fourth_material_image_url;
        this.first_repair_image_url = attrs.first_repair_image_url;
        this.second_repair_image_url = attrs.second_repair_image_url;
        this.used_items = attrs.used_items != null ? attrs.used_items.map( used_item => new Used_item(used_item)) : [];
        this.recovered_items = attrs.recovered_items != null ? attrs.recovered_items.map( recovered_item => new Recovered_item(recovered_item)) : [];
    }
}

export interface GetRepairResponse {
    repair: Repair
  }


export class Used_item {
    id: string
    quantity: number
    material: Material

    constructor(attrs: any) {
        this.id = attrs.id;
        this.quantity = attrs.quantity;
        this.material = new Material(attrs.material);
    }
}
export class Recovered_item {
    id: string
    quantity: number
    material: Material

    constructor(attrs: any) {
        this.id = attrs.id;
        this.quantity = attrs.quantity;
        this.material = new Material(attrs.material);
    }
}