import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenCommentary'
})
export class ShortenCommentaryPipe implements PipeTransform {

  transform(value?: string): string {
    var result = value;
    if(value != null){
      if (value.length > 40){
        result = value.substring(0,40);
      }
    }
    return result;
  }

}
