import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()

export class DashboardHeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isHeaderNeeded(request.url)) {
      request = request.clone({
        headers: request.headers.append('Accept', 'application/vnd.oe.v1+json')
      });
    }
    return next.handle(request);
  }

  isHeaderNeeded(url: string) {
    if (url.startsWith('https://apis.forcsec.com/api/codigos-postales')) {
      return false;
    } else {
      return true;
    }
  }
}
