import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';


import { GetLuminaireResponse } from '@models/get-luminaire-response';
import { Luminaire } from '@models/luminaire';
import { EditLuminairePayload } from './edit-luminaire-payload';
import { Arm } from '@models/arm';
import { EditArmPayload } from './edit-arm/edit-arm-payload';
import { Base } from '@models/base';
import { EditBasePayload } from './edit-base/edit-base-payload';
import { Lamppost } from '@models/lamppost';
import { EditLamppostPayload } from './edit-lamppost/edit-lamppost-payload';
import { LuminaireAll, NewArm, NewLamppost } from './luminaire-edit-all';
import { map } from 'rxjs/operators';

@Injectable()

export class EditLuminaireService {
  constructor(private httpClient: HttpClient) { }

  // getLuminaire(luminaire_id: string): Observable<GetLuminaireResponse> {
  //   return this.httpClient.get<GetLuminaireResponse>(
  //     `${environment.SERVER_URL}/luminaires/${luminaire_id}`
  //   );
  // }



  getNewLuminaire(id: string): Observable<LuminaireAll> {
    const httpParams = new HttpParams()
      .set('id', id)
    console.log(`${environment.SERVER_CONFIG}/api/Luminaire/GetLuminaire`);
    return this.httpClient.get<LuminaireAll>(
      `${environment.SERVER_CONFIG}/api/Luminaire/GetLuminaire`, { params: httpParams }
    );
  }
  editLuminaire(luminaire: any) {
    return this.httpClient.post(
      `${environment.SERVER_CONFIG}/api/Luminaire/UpdateLuminaire`, luminaire
    );
  }
  // editLuminaire(luminaire: Luminaire, luminairePayload: EditLuminairePayload): Observable<any> {
  //   return this.httpClient.put<any>(
  //     `${environment.SERVER_URL}/luminaires/${luminaire.id}`,
  //     luminairePayload.toJson()
  //   );
  // }

  editArm(arm: FormData): Observable<any> {
    return this.httpClient.post(
      `${environment.SERVER_CONFIG}/api/Luminaire/UpdateArm`, arm
    );
  }

  // editArm(arm: Arm, armPayload: EditArmPayload): Observable<any> {
  //   return this.httpClient.put<any>(
  //     `${environment.SERVER_URL}/arms/${arm.id}`,
  //     armPayload.toFormData()
  //   );
  // }

  editBase(bases: FormData): Observable<any> {
    return this.httpClient.post(
      `${environment.SERVER_CONFIG}/api/Luminaire/UpdateBase`, bases
    );
  }
  // editBase(base: Base, basePayload: EditBasePayload): Observable<any> {
  //   return this.httpClient.put<any>(
  //     `${environment.SERVER_URL}/bases/${base.id}`,
  //     basePayload.toFormData()
  //   );
  // }

  editLamppost(lamppost: FormData): Observable<any> {
    return this.httpClient.post(
      `${environment.SERVER_CONFIG}/api/Luminaire/UpdateLampost`, lamppost
    );
  }

  // editLamppost(lamppost: Lamppost, lamppostPayload: EditLamppostPayload): Observable<any> {
  //   return this.httpClient.put<any>(
  //     `${environment.SERVER_URL}/lampposts/${lamppost.id}`,
  //     lamppostPayload.toFormData()
  //   );
  // }

}
