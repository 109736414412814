import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { EditTroopService } from './edit-troop.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Troop } from 'app/models/troop';
import { TroopFormComponent } from '../troop-form/troop-form.component';
import { GetTroopResponse } from 'app/models/get-troop-response';
import { EditTroopPayload } from './edit-troop-payload';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-edit-troop',
  templateUrl: './edit-troop.component.html',
  styleUrls: ['./edit-troop.component.scss'],
  providers: [EditTroopService]
})
export class EditTroopComponent implements OnInit {
  troopForm: FormGroup = this.formBuilder.group({
    id: [null, Validators.required],
    number: [null, Validators.required],
    ipad_serial_number: [null, [Validators.required]],
    plates: [null, Validators.required],
    geotab_id: [null, Validators.required]
  });
  editableTroopId: string;
  editableTroop: IEditTroop;
  showAddButtons = true;

  @ViewChild(TroopFormComponent) troopFormComponent: TroopFormComponent

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: Toastr,
    private router: Router,
    private editTroopService: EditTroopService,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.editableTroopId = this.activatedRoute.snapshot.paramMap.get('id');
    this.setButtonVisibility();

    this.editTroopService
      .getTroop(this.editableTroopId)
      .subscribe(
        (troopUserResponse: IEditTroop) => {
        //  const troop = new Troop(troopUserResponse);
          console.log(troopUserResponse);

          this.editableTroop = troopUserResponse;
          this.troopFormComponent.initializeUserForm(troopUserResponse);
          this.troopFormComponent.setEditableTroop(troopUserResponse);
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }


  setButtonVisibility() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    console.log(decodeToken["role_name_enums"])
    if (decodeToken["role_name_enums"][0] !== 0 && decodeToken["role_name_enums"][0] !== 1 && decodeToken["role_name_enums"][0] !== 2 && decodeToken["role_name_enums"][0] !== 4 ) {
      this.showAddButtons = false;
    }
  }

  editTroop(): void {
    if (this.troopForm.valid) {
      //const requestPayload = new EditTroopPayload(this.troopForm);
        console.log(this.troopForm.getRawValue())
      this.editTroopService
        .editTroop(this.troopForm.getRawValue())
        .subscribe(
          () => {
            this.toastr.success('Cuadrilla actualizada.');
            this.router.navigate(['/u/troops']);
          },
          (error) => {
            this.toastr.error(error);
          }
        )
    } else {
      this.troopForm.controls.number.markAsTouched();
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  performAction(form: FormGroup): void {
    this.troopForm = form;
    this.editTroop();
  }

}
