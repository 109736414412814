import { Role } from "./role";
import { Troop } from "./troop";

interface TrooperInterface {
  id: string,
  name: string,
  last_name: string,
  email: string,
  cell_phone_number: string,
  assigned_tools: string,
  address: string,
  image_url: string,
  roles: Role[],
  troops: Troop[]
}

export class Trooper implements TrooperInterface {
  id: string;
  name: string;
  last_name: string;
  email: string;
  cell_phone_number: string;
  assigned_tools: string;
  address: string;
  image_url: string;
  roles: Role[];
  troops: Troop[];
  fullName: string;
  is_active: boolean

  constructor(attrs) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.last_name = attrs.last_name;
    this.email = attrs.email;
    this.cell_phone_number = attrs.cell_phone_number;
    this.is_active = attrs.is_active
    this.assigned_tools = attrs.assigned_tools;
    this.address = attrs.address;
    this.image_url = attrs.image_url;
    this.roles = attrs.roles.map((role: Role) => new Role(role));
    this.troops = attrs.troops.map((troop: Troop) => new Troop(troop));
    this.fullName = `${attrs.name} ${attrs.last_name} (${this.troops.map(troop => troop.number).join(', ')})`.trim()
  }
}
