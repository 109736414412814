import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-track-material',
  templateUrl: './track-material.component.html',
  styleUrls: ['./track-material.component.scss']
})
export class TrackMaterialComponent implements OnInit {


  constructor() { }

  ngOnInit() {

  }

}
