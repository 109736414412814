import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { RepiarPhaseTwoDetails } from '@app/models/repiar-phase-two-details';
import { PersonCbo } from '@app/models/person-cbo-class';

@Injectable({
  providedIn: 'root'
})
export class RepairsPhaseService {

  constructor(
    private httpclient: HttpClient
  ) { }

  getRepair(task_id: string) {
    const httpParams = new HttpParams().set('task_id', task_id);

    return this.httpclient.get<RepiarPhaseTwoDetails>(
      `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/GetRepair`,
      { params: httpParams }
    ).pipe(
      map(response => new RepiarPhaseTwoDetails(response)));
  }

  getOficials() {
    return this.httpclient.get<PersonCbo[]>(
      `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/GetOficials`
    ).pipe(
      map(persons => persons.map(person => new PersonCbo(person)))
    )
  }

  getSupervisors() {
    return this.httpclient.get<PersonCbo[]>(
      `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/GetSupervisors`
    ).pipe(
      map(persons => persons.map(person => new PersonCbo(person)))
    )
  }

  setRepairInfo(formBasic: any, formMaterial: any, formWork: any) {
    const form = {
      repair_Basic_Info: formBasic,
      repair_Work_Done: formMaterial,
      work_Done_Dto: formWork
    }
    return this.httpclient.post(
      `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/SetRepairInfo`, form
    );
  }

  // setRepairBasic(form: any) {
  //   return this.httpclient.post(
  //     `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/SetRepairBasic`, form
  //   );
  // }

  // setUsedMaterial(form: any, task_id: string) {
  //   const httpParams = new HttpParams().set('task_id', task_id);
  //   return this.httpclient.post(
  //     `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/SetUsedMaterial`, form, { params: httpParams}
  //   );
  // }

  // setWorkDone(form: any, task_id: string) {
  //   const httpParams = new HttpParams().set('task_id', task_id);
  //   return this.httpclient.post(
  //     `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/SetWorkDone`, form, { params: httpParams}
  //   );
  // }

  setLuminaireStatus(form: any, task_id: string) {
    const httpParams = new HttpParams().set('task_id', task_id);
    return this.httpclient.post(
      `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/SetLuminaireStatus`, form, { params: httpParams}
    );
  }

  changeRepairStatus(task_id: string, status: boolean) {
    const httpParams = new HttpParams()
    .set('status', status.toString())
    .set('fuuid_taskid', task_id);
    return this.httpclient.get(
      `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/ChangeRepairStatus`, { params: httpParams}
    );
  }
}
