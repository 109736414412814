import { isNull } from '@angular/compiler/src/output/output_ast';
import { GenericTable } from '@app/models/generic-table';
import { Meter } from '@app/models/meter';
import { Location } from '@app/models/project_wattage';
import { Rpus } from '@app/models/rpus';


export interface IRpuDetail {
    rpu: Rpus,
    weighted_score: number,
    circuits_names: string[],
    circuits: RpuCircuitDetail[],
    table_list: Period[]
}

export class RpuDetail implements IRpuDetail {
    rpu: Rpus;
    weighted_score: number;
    circuits_names: string[];
    circuits: RpuCircuitDetail[];
    table_list: Period[];

    constructor(attrs: any) {
        this.rpu = attrs.rpu;
        this.weighted_score = attrs.weighted_score;
        this.circuits_names = attrs.circuits_names;
        this.circuits = attrs.circuits;
        this.table_list = attrs.table_list;
    }

    GetByYearAndType(year_selected = 0, type = false) {
        const period: Period[] = [];
        if (year_selected == 0 && !type) {
            this.table_list.forEach(periodo => {
                periodo.monthString = this.GetMonthString(periodo.month);
                period.push(periodo);
            });
            return period
        }

        if (type) {
            let years = year_selected == 0 ? this.table_list.map(x => x.year) : this.table_list.filter(x => x.year == year_selected).map(x => x.year);
            years = years.filter((n, i) => years.indexOf(n) === i);
            years.forEach(year => {
                const current_year = this.table_list.filter(x => x.year == year);
                const kwh = current_year.map(x => x.kwh)[0];
                const mxn = current_year.map(x => x.mxn)[0];
                const savingKWH = current_year.map(x => x.savingKwh)[0];
                const savingMXN = current_year.map(x => x.savingMxn)[0];
                const estimatedConsumptionKwh = current_year.map(x => x.estimatedConsumptionKwh)[0];
                const estimatedConsumptioMxn = current_year.map(x => x.estimatedConsumptionMxn)[0];
                const aditionalKwh = current_year.map(x => x.aditionalKwh)[0];
                const aditionalMxn = current_year.map(x => x.aditionalMxn)[0];
                const countMonths = current_year.length;

                period.push(new Period({ year: year, month: "", countMonths: countMonths, kwh: kwh, mxn: Math.round(mxn), savingKwh: Math.round(savingKWH), savingMxn: Math.round(savingMXN), estimatedConsumptionKwh: Math.round(estimatedConsumptionKwh), estimatedConsumptioMxn: Math.round(estimatedConsumptioMxn), aditionalKwh: aditionalKwh, aditionalMxn: aditionalMxn }));
            });
            return period;
        } else {
            return this.table_list.filter(x => x.year == year_selected);
        }
    }

    GetLastValue() {
        return this.table_list[this.table_list.length - 1];
    }

    GetMonthString(numberMonth: number) {
        var stringMonth;
        switch (numberMonth) {
            case 1: {
                stringMonth = 'ENE';
                break;
            }
            case 2: {
                stringMonth = 'FEB';
                break;
            }
            case 3: {
                stringMonth = 'MAR';
                break;
            }
            case 4: {
                stringMonth = 'ABR';
                break;
            }
            case 5: {
                stringMonth = 'MAY';
                break;
            }
            case 6: {
                stringMonth = 'JUN';
                break;
            }
            case 7: {
                stringMonth = 'JUL';
                break;
            }
            case 8: {
                stringMonth = 'AGO';
                break;
            }
            case 9: {
                stringMonth = 'SEP';
                break;
            }
            case 10: {
                stringMonth = 'OCT';
                break;
            }
            case 11: {
                stringMonth = 'NOV';
                break;
            }
            case 12: {
                stringMonth = 'DIC';
                break;
            }
        }
        return stringMonth;
    }

}

export class Period {
    month: number;
    year: number;
    countDays: number;
    countMonths: number;
    monthString: string;
    kwh: number;
    mxn: number;
    savingKwh: number;
    savingMxn: number;
    estimatedConsumptionKwh: number;
    estimatedConsumptionMxn: number;
    aditionalKwh: number;
    aditionalMxn: number;
    numberMonth: number;

    constructor(attrs: any) {
        this.month = attrs.month;
        this.year = attrs.year;
        this.countDays = attrs.countDays;
        this.countMonths = attrs.countMonths;
        this.kwh = attrs.kwh;
        this.mxn = attrs.mxn;
        this.savingKwh = attrs.valueInvoiced;
        this.savingMxn = attrs.savingMxn;
        this.estimatedConsumptionKwh = attrs.estimatedConsumptionKwh;
        this.estimatedConsumptionMxn = attrs.estimatedConsumptionMxn;
        this.aditionalKwh = attrs.aditionalKwh;
        this.aditionalMxn = attrs.aditionalMxn;
        this.numberMonth = attrs.numberMonth;
    }
}




export class RpuCircuitDetail {
    id: string;
    identifier: string;
    wattage: number;
    lampposts: RpuLamppostDetail[];
    color?: any;
    meter: Meter;
    marker: google.maps.Marker;
    event: string;
    isSelected: boolean;

    readonly markerIcons = {
        normal: 'assets/img/svg/circuit_normal.svg',
        selected: 'assets/img/svg/circuit_selected.svg',
        circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
    }

    constructor(attrs) {
        this.id = attrs.id;
        this.identifier = attrs.identifier;
        this.wattage = attrs.wattage;
        // tslint:disable-next-line:max-line-length
        this.lampposts = attrs.lampposts != null ? attrs.lampposts.map((lamppost: RpuLamppostDetail) => new RpuLamppostDetail(lamppost)) : [];
        this.color = attrs.color;
        this.meter = attrs.meter;
    }

    get_icon(): object {
        return {
            url: this.markerIcons[this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
        };
    }

    upadeIcon(event: string): void {
        this.event = event;
        this.marker.setIcon(this.get_icon());
        for (const lamppost of this.lampposts) {
            lamppost.upadeIcon(event);
        }
    }

    paintMarker(map: any): void {
        const latitude = this.meter.location.latitude;
        const longitude = this.meter.location.longitude;
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.id,
            position: new google.maps.LatLng(latitude, longitude),
            icon: this.get_icon(),
            map: map
        });
    }

    hideMarker(): void {
        this.marker.setMap(null);
    }
    setMap(map: any): void {
        this.marker.setMap(map);
        for (const lamppost of this.lampposts) {
            lamppost.setMap(map);
        }
    }
}

export class RpuLamppostDetail {
    id: string;
    luminaires: RpuLuminaireWattage[];
    event: string;

    constructor(attrs) {
        this.id = attrs.id;
        this.luminaires = attrs.luminaires != null ? attrs.luminaires.map(luminaire => new RpuLuminaireWattage(luminaire, 'assign')) : []
    }

    upadeIcon(event: string): void {
        this.event = event;
        for (const luminaire of this.luminaires) {
            // tslint:disable-next-line:no-shadowed-variable
            const event = this.event == 'normal' ? luminaire.isSelected ? 'selected' : 'normal' : this.event;
            luminaire.upadeIcon(event);
        }
    }

    hideLamppost(): void {
        for (const luminaire of this.luminaires) {
            luminaire.hideMarker();
        }
    }

    setMap(map: any): void {
        for (const luminaire of this.luminaires) {
            luminaire.setMap(map);
        }
    }
}

export class RpuLuminaireWattage {
    id: string;
    reference_id: number;
    location: Location;
    progress: number;
    color: string;
    marker: google.maps.Marker;
    event: string;
    isSelected: boolean;
    linkedSituation: string;


    readonly markerIcons = {
        linked: {
            hover: 'assets/img/svg/ic_link_hover.svg',
            normal: 'assets/img/svg/link.svg',
            selected: 'assets/img/svg/lam_selected.svg',
            circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
        },
        single: {
            normal: 'assets/img/svg/normal.svg',
            selected: 'assets/img/svg/selected-blue.svg'
        },
        assign: {
            selected: 'assets/img/svg/ic_install_selected-',
            circuitShowing: 'assets/img/svg/ic_install_linked-',
            normal: 'assets/img/svg/ic_install_pin-',
            hover: 'assets/img/svg/ic_install_hover-',
        },
        citizen: {
            normal: 'assets/img/svg/ic_single_normal.svg',
            selected: 'assets/img/svg/link_selected.svg'
        }
    }

    constructor(attrs, linkedSituation: string = 'single', event: string = 'normal') {
        this.id = attrs.id;
        this.reference_id = attrs.reference_id == null ? 0 : attrs.reference_id;
        this.location = new Location(attrs.location);
        this.progress = attrs.progress;
        this.color = attrs.color;
        this.linkedSituation = attrs.color == '' ? 'linked' : linkedSituation;
        this.event = event;
        this.isSelected = false;
    }

    get_icon(): object {
        return this.linkedSituation == 'assign'
            ?
            {
                url: `${this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.color}.svg`,
                scaledSize: new google.maps.Size(15, 15)
            }
            :
            {
                url: this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
            };
    }

    upadeIcon(event: string): void {
        this.event = event;
        this.marker.setIcon(this.get_icon());
    }

    paintMarker(map: any): void {
        const latitude = this.location.latitude;
        const longitude = this.location.longitude;
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.reference_id.toString(),
            position: new google.maps.LatLng(latitude, longitude),
            icon: this.get_icon(),
            map: map
        });
    }

    hideMarker(): void {
        this.marker.setMap(null);
    }

    setMap(map: any): void {
        this.marker.setMap(map);
    }
}
