import { Routes, RouterModule } from '@angular/router';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...
import { LoginComponent } from '@pages/login/login.component';
import { ReportLuminaireComponent } from '@pages/report-luminaire/report-luminaire.component';
import { CheckReportComponent } from '@pages/check-report/check-report.component';

export const CONTENT_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/u',
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'reportar-luminaria/:slug',
    component: ReportLuminaireComponent
  },
  {
    path: 'revisar-reporte/:slug',
    component: CheckReportComponent
  },
  {
    path: 'revisar-reporte/:slug/:folio',
    component: CheckReportComponent
  }
];
