import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable, Subject } from 'rxjs';

import { Project } from '@models/project';

import { environment } from '@environments/environment';

// TODO: Add observables error handling.
@Injectable()
export class CurrentProjectService {
  readonly emptyProject = {
    id: '',
    name: 'Sin Selección',
    state: '',
    country: '',
    municipality: '',
    slug: '',
    tickets_enabled: false,
    last_luminaire_reference_id: '',
    phase: '',
    energy_year: ''
  };

  _currentProject: Subject<Project> = new Subject<Project>();
  currentProject$: Observable<Project> = this._currentProject.asObservable();

  constructor(private cookieService: CookieService) {}

  deleteCurrentProject(): void {
    this.cookieService.remove(`${environment.CURRENT_PROJECT_COOKIE}`);
  }

  getCurrentProject(): Project {
    const projectString = this.cookieService.get(`${environment.CURRENT_PROJECT_COOKIE}`);
    return projectString ? JSON.parse(projectString) : this.emptyProject;
  }

  setCurrentProject(project: Project): void {
    const projectString = JSON.stringify(project);

    this.cookieService.put(`${environment.CURRENT_PROJECT_COOKIE}`, projectString);
    this._currentProject.next(project);
  }

  updateCurrentProject(project: Project) {
    const currentProject = this.getCurrentProject();

    if (currentProject.id === project.id) {
      this.setCurrentProject(project);
    }
  }
}
