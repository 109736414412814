import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { ProfileResponse } from '@models/profile-response';
import { RolesResponse } from '@models/roles-response';
import { ProfilePayload } from './profile-payload';

import { PasswordPayload } from '@models/password-payload'

@Injectable()

export class ProfileService {
  constructor(private httpClient: HttpClient) {}

  getProfile(): Observable<ProfileResponse> {
    return this.httpClient.get<ProfileResponse>(
      `${environment.SERVER_URL}/profile`
    );
  }

  updateProfile(profilePayload: ProfilePayload): Observable<ProfilePayload> {
    return this.httpClient.put<ProfilePayload>(
      `${environment.SERVER_URL}/profile`,
      profilePayload.toFormData()
    );
  }

  updatePassword(passwordPayload: PasswordPayload): Observable<PasswordPayload> {
    return this.httpClient.put<PasswordPayload>(
      `${environment.SERVER_URL}/password`,
      passwordPayload.toJson()
    );
  }

  getRoles(): Observable<RolesResponse> {
    return this.httpClient.get<RolesResponse>(
      `${environment.SERVER_URL}/roles`
    );
  }
}
