import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericTable } from '@app/models/generic-table';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Routes } from './incident-luminaire-routes-route4me-model';

@Injectable({
  providedIn: 'root'
})
export class IncidentLuminaireRoutesRoute4meService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  get_all_routes(page) {

    const httpParams = new HttpParams()
    .set('page', page);

    return this.httpClient.get<GenericTable<Routes>>(
      `${environment.SERVER_CALLCENTER}/api/Route4/get-list-routes-route4me`, { params: httpParams }
    ).pipe(
      map(elements => new GenericTable<Routes>(elements))
    );
  }

}
