import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProjectService } from './project.service';
import { Router } from '@angular/router';

@Injectable()
export class ProjectGuard implements CanActivate {

  constructor(
    private projectsService: ProjectService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.projectsService.projectIsSelected()) {
      return true;
    } else {
      this.router.navigate(['/select-project']);
    }
  }
}
