import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { GenericTable } from '@app/models/generic-table';
import { CfeTariffs } from '@app/models/cfe-tariffs';
import { map } from 'rxjs/operators';
import { CpApi } from '@app/models/cp-api';
import { AuthService } from '@app/shared/auth/auth.service';
import { Observable } from 'rxjs';
import { Sepomex } from '@app/models/sepomex';

@Injectable({
  providedIn: 'root'
})
export class TariffsService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }


  getTariffs(form, page) {
    const httpParams = new HttpParams().set('page', page)
    return this.httpClient.post<GenericTable<CfeTariffs>>(
      `${environment.SERVER_ENERGY}/api/Tariffs/GetTariffs`, form, { params: httpParams }
    ).pipe(
      map(elements => new GenericTable<CfeTariffs>(elements))
    );
  }

  getAddressData(cp: string): Observable<Sepomex> {
    const httpParams = new HttpParams()
    .set('CP', cp);
    return this.httpClient.get<Sepomex>(
      `${environment.SERVER_CONFIG}/api/get-detail-sepomex`, { params: httpParams}
    );

    // const url = `https://apis.forcsec.com/api/codigos-postales/20210602-503b7df03fa52f03/${cp}`;
    // return this.httpClient.get(url);
  }

  addTariff(form) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    // console.log(form);
    return this.httpClient.post(
      `${environment.SERVER_ENERGY}/api/Tariffs/SaveTariffs`, form, { headers: httpHeaders }
    );
  }

  updateTariff(form) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id)
    return this.httpClient.post(
      `${environment.SERVER_ENERGY}/api/Tariffs/UpdateTariff`, form, { headers: httpHeaders }
    );
  }

  copyTarrifs(form) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id)
    return this.httpClient.post(
      `${environment.SERVER_ENERGY}/api/Tariffs/CopyTariffs`, form, { headers: httpHeaders }
    );
  }
}
