import { Component, OnInit, Input } from '@angular/core';
import { Circuit } from '@models/circuit';
import { Toastr } from '@shared/toastr/toastr.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditCircuitService } from '../edit-circuit.service';
import { GaugeEnum  } from '@models/gauge-enum';
import { MaterialEnum  } from '@models/material-enum';
import { DistributionEnum  } from '@models/distribution-enum';
import { SubtypeEnum  } from '@models/subtype-enum';
import { EditWiringPayload } from './edit-wiring-payload';

@Component({
  selector: 'app-edit-wiring',
  templateUrl: './edit-wiring.component.html',
  providers: [EditCircuitService]
})
export class EditWiringComponent implements OnInit {
  gaugeEnum = Object.keys(GaugeEnum).filter(key => key.length > 2);
  materialEnum = Object.keys(MaterialEnum).filter(key => !isNaN(Number(MaterialEnum[key])));
  distributionEnum = Object.keys(DistributionEnum).filter(key => !isNaN(Number(DistributionEnum[key])));
  subtypeEnum = Object.keys(SubtypeEnum).filter(key => !isNaN(Number(SubtypeEnum[key])));
  @Input() circuit: Circuit;
  wiringForm: FormGroup = this.formBuilder.group({
    id: [null],
    is_present: [null],
    gauge: [null],
    material: [null],
    distribution:[null],
    subtype: [null],
    has_lock: [null],
    condition: [null]
  });

  constructor(
    private formBuilder: FormBuilder,
    private editCircuitService: EditCircuitService,
    private toastr: Toastr,
  ) { }

  ngOnInit() {
    this.populateForm();
  }

  private populateForm(){
    let wiring = this.circuit.wiring;
    this.wiringForm.patchValue({
      id: wiring.id,
      is_present: wiring.is_present,
      gauge: wiring.gauge,
      material: wiring.material,
      distribution: wiring.distribution,
      subtype: wiring.subtype,
      has_lock: wiring.has_lock,
      condition: wiring.condition
    });
  }

  // public performFormAction(){
  //   if (this.wiringForm.valid) {
  //     const requestPayload = new EditWiringPayload(this.wiringForm);

  //     this.editCircuitService
  //       .editWiring(this.circuit, requestPayload)
  //       .subscribe(
  //         () => {
  //           this.toastr.success('Cableado actualizado.');
  //         },
  //         (error) => {
  //           this.toastr.error(error);
  //         }
  //       )
  //   } else {
  //     this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
  //   }
  // }

  public performFormAction() {
    if (this.wiringForm.valid) {
      this.editCircuitService
        .editWiringNew(this.wiringForm.value)
        .subscribe(
          () => {
            this.toastr.success('Cableado actualizado.');
          },
          (error) => {
            this.toastr.error(error);
          }
        )
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

}
