import { DevicesService } from './../../devices.service';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as FusionCharts from 'fusioncharts';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-device-graphics',
  templateUrl: './device-graphics.component.html',
  styleUrls: ['./device-graphics.component.scss']
})
export class DeviceGraphicsComponent implements OnInit {
  @Input() is_detail_consulting;
  cant_days = 10;
  days_before = 0;
  ampsDataSource: any;
  voltsDataSource: any;
  powerDataSource: any;
  deviceID;
  electricParametersSource: any;
  electricReadings: any[] = [];
  showOrHide = false;
  showOrHideAlerts = false;
  type: string;
  width: string;
  height: string;

  constructor(
    private deviceService: DevicesService,
    private activatedRoute: ActivatedRoute,
  ) {

  }
  view_more_history_electric() {
    this.days_before -= this.cant_days;
    this.cant_days = 30;
    this.get_detail_device();
  }

  get_detail_device() {
    this.deviceService.getDeviceElectricReadings30Days(this.deviceID, this.cant_days, this.days_before).subscribe(readings => {
      if (readings) {
        this.electricReadings = this.electricReadings.concat(readings);
        this.electricParametersData(this.electricReadings)
      }
    });
  }

  ngOnInit(): void {
    console.log(this.is_detail_consulting);
    if (this.is_detail_consulting === false) {
      this.deviceID = this.activatedRoute.snapshot.paramMap.get('id');
      // // this.activatedRoute.paramMap.subscribe(params => {
      // //   if (!params.has('id')) {
      // //     return;
      // //   }
      this.get_detail_device();


      this.type = 'timeseries';
      this.width = '100%';
      this.height = '800';
      this.electricParametersSource = {
        chart: {},
        caption: {
          text: 'Lecturas de parametros electricos (30 días)'
        },
        subcaption: {
          text: 'Voljate (v), Corriente (a), Potencia (w)'
        },
        yaxis: [
          {
            plot: [
              {
                value: 'Voltaje',
                connectnulldata: true
              }
            ],
            title: 'Voltaje (v)',
            format: {
              sufix: ' v.'
            }
          },
          {
            plot: [
              {
                value: 'Corriente (L1)',
                connectnulldata: true,
              },
              {
                value: 'Corriente (L2)',
                connectnulldata: true,
              }
            ],
            title: 'Corriente (a)',
            format: {
              sufix: ' a.'
            }
          },
          {
            plot: [
              {
                value: 'Potencia',
                connectnulldata: true
              }
            ],
            title: 'Potencia (w)',
            format: {
              sufix: ' w.'
            }
          },
        ],
        datamarker: null
      }
    } else {
      this.electricParametersData(this.electricReadings);
    }

  }

  onAndOffAnnotations() {
    this.showOrHide = !this.showOrHide;

    if (this.showOrHide === true) {
      let onArray = [];
      this.deviceService.getSmartAnnotations(this.deviceID).subscribe(res => {
        onArray.push(...res)
        if (res.length > 0) {
          for (let i = 1; i < this.electricReadings.length - 1; i++) {
            if (this.electricReadings[i][4] >= 5 && this.electricReadings[i - 1][4] < 5) {
              if (res.find(x => x.identifier === 'O' && x.time !== this.electricReadings[i][0])) {
                onArray.push({
                  value: 'Potencia',
                  time: this.electricReadings[i][0],
                  timeformat: '%Y-%m-%d %H:%M',
                  // timeformat: '%b %-d, %Y, %-I:%-M',
                  tooltext: 'OFF',
                  identifier: 'O',
                  style: {
                    "marker": {
                      "fill": "#e03131"
                    }
                  }
                });
              }
            }
            if (this.electricReadings[i][4] >= 5 && this.electricReadings[i + 1][4] < 5) {
              if (res.find(x => x.identifier === 'I' && x.time !== this.electricReadings[i][0])) {
                onArray.push({
                  value: 'Potencia',
                  time: this.electricReadings[i][0],
                  timeformat: '%Y-%m-%d %H:%M',
                  // timeformat: '%b %-d, %Y, %-I:%-M',
                  tooltext: 'ON',
                  identifier: 'I',
                  style: {
                    "marker": {
                      "fill": "#0daf0d"
                    }
                  }
                });
              }
            }
          }
        } else {
          for (let i = 1; i < this.electricReadings.length - 1; i++) {
            if (this.electricReadings[i][4] >= 5 && this.electricReadings[i - 1][4] < 5) {

              onArray.push({
                value: 'Potencia',
                time: this.electricReadings[i][0],
                timeformat: '%Y-%m-%d %H:%M',
                // timeformat: '%b %-d, %Y, %-I:%-M',
                tooltext: 'OFF',
                identifier: 'O',
                style: {
                  "marker": {
                    "fill": "#e03131"
                  }
                }
              });

            }
            if (this.electricReadings[i][4] >= 5 && this.electricReadings[i + 1][4] < 5) {
              onArray.push({
                value: 'Potencia',
                time: this.electricReadings[i][0],
                timeformat: '%Y-%m-%d %H:%M',
                // timeformat: '%b %-d, %Y, %-I:%-M',
                tooltext: 'ON',
                identifier: 'I',
                style: {
                  "marker": {
                    "fill": "#0daf0d"
                  }
                }
              });
            }
          }
        }

        for (let i = 1; i < onArray.length - 1; i++) {
          let readingsDate = new Date(onArray[i].time);
          if (((onArray[i].identifier !== onArray[i + 1].identifier || onArray[i].identifier !== onArray[i - 1].identifier)
            && (onArray[i].time === onArray[i + 1].time || onArray[i].time === onArray[i - 1].time))) {
          } else if ((onArray[i].identifier === onArray[i + 1].identifier || onArray[i].identifier === onArray[i - 1].identifier)
            && (new Date(onArray[i].time) !== new Date(readingsDate.setMinutes(readingsDate.getMinutes() + 1)) ||
              new Date(onArray[i].time) !== new Date(readingsDate.setMinutes(readingsDate.getMinutes() - 1)))) {
            console.log(onArray[i])
            onArray.splice(i, 1)
          }
        }
        this.electricParametersSource.datamarker = onArray;

      })
    } else { this.electricParametersSource.datamarker = null }
  }

  alertsAnnotations() {
    this.showOrHideAlerts = !this.showOrHideAlerts;
    if (this.showOrHideAlerts === true) {
      this.deviceService.GetSmartAlertsAnnotations(this.deviceID).subscribe(res => {
        this.electricParametersSource.datamarker = res;

      })
    } else {
      this.electricParametersSource.datamarker = null
    }
  }

  electricParametersData(readings) {
    let dataFetch = readings;
    
    this.electricParametersSource.caption.text = `Lecturas de parametros electricos (${Math.abs(this.cant_days)} días)`;
    let schemaFetch = [{
      'name': 'Time',
      'type': 'date',
      'format': '%Y-%m-%d %H:%M',
      // 'format': '%b %-d, %Y, %-I:%-M'
    }, {
      'name': 'Voltaje',
      'type': 'number'
    }, {
      'name': 'Corriente (L1)',
      'type': 'number'
    }, {
      'name': 'Corriente (L2)',
      'type': 'number'
    },
    {
      'name': 'Potencia',
      'type': 'number'
    }];

    Promise.all([dataFetch, schemaFetch]).then(res => {
      const [data, schema] = res;
      const fusionDataStore = new FusionCharts.DataStore();
      const fusionTable = fusionDataStore.createDataTable(data, schema);
      this.electricParametersSource.data = fusionTable;
    });
  }
}
