import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { GetProjectsResponse } from '@models/get-projects-response';
import { GetUserResponse } from '@models/get-user-response';
import { User } from '@models/user';
import { Project } from '@models/project';
import { CfeTariffs } from '@app/models/cfe-tariffs';

@Injectable()

export class EditUserService {
  constructor(private httpClient: HttpClient) {}

  addUserToProject(project: Project, user: User): Observable<any> {
    const httpParams = new HttpParams().set('project_id', project.id).set('user_id', user.id);
    return this.httpClient.get<any>(
      `${environment.SERVER_CONFIG}/api/users/AddUserToProject`, { params: httpParams}
    );
  }

  editUserData(user: User, userPayload): Observable<any> {
    const httpParams = new HttpParams().set('user_id', user.id);
    return this.httpClient.post<any>(
      `${environment.SERVER_CONFIG}/api/users/UpdateUser`, userPayload.toFormData(), { params: httpParams}
      
    );
  }

  editUser(user: User, userPayload: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/users/${user.id}`,
      userPayload.toFormData()
    );
  }

  getProjects(): Observable<GetProjectsResponse> {
    return this.httpClient.get<GetProjectsResponse>(
      `${environment.SERVER_URL}/projects`
    );
  }

  getUser(user_id: string): Observable<GetUserResponse> {
    return this.httpClient.get<GetUserResponse>(
      `${environment.SERVER_URL}/users/${user_id}`
    );
  }

  removeUserFromProject(project: Project, user: User): Observable<any> {
    const httpParams = new HttpParams().set('project_id', project.id).set('user_id', user.id);
    return this.httpClient.delete<any>(
      `${environment.SERVER_CONFIG}/api/users/RemoveUserFromProject`, { params: httpParams}
    );
  }


}
