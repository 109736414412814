import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit {
    shouldHideLogo: Boolean = false;

    constructor(private router: Router) {
        router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        ).subscribe((e) => {
            if((e as NavigationEnd).urlAfterRedirects === '/u') {
                this.shouldHideLogo = false;
            } else {
                this.shouldHideLogo = true;
            }
        });
    }

    options = {
        direction: 'ltr'
    };

    ngOnInit(){
       this.AddEventShortcupToMap();
    }

    private AddEventShortcupToMap(): void{
        window.addEventListener('keydown', (e) => {
          let el;
          if(e.key == 'a' && e.ctrlKey){
            el = document.getElementById('switchPoly');
          }
          if(e.key == 'l' && e.ctrlKey){
            el = document.getElementById('switchLum');
          }
          if(e.key == 'p' && e.ctrlKey){
            el = document.getElementById('switchLam');
          }
          if(e.key == 'c' && e.ctrlKey){
            el = document.getElementById('switchCir');
          }
          if(el)
            el.click();
        })
      }
}
