
import { GeolocationPoint } from '@models/geolocation-point';

export class SurveyAreaPayload {
  id: string;
  points: GeolocationPoint[];

  constructor(attrs: any) {
    this.id = attrs.id;
    this.points = attrs.points;
  }


  toJson(): Object {
    const json = {
      survey_area: {
        points: this.points
      }
    };

    return json;
  }
}
