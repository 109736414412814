import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { GetRepairResponse, Repair } from '@models/repair';

@Injectable({
  providedIn: 'root'
})
export class RepairsService {

  constructor(private httpClient: HttpClient) {}

  getRepair(repairId: string): Observable<Repair> {
    return this.httpClient.get<GetRepairResponse>
    ( `${environment.SERVER_URL}/repairs/${ repairId }` )
    .pipe( map( data => new Repair(data.repair) ) );
  }

  updateRepair(repairID: string, formData: FormData): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/repairs/${ repairID }`, formData);
  }
}
