import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { element } from 'protractor';

import { CensoService } from './censo.service';
import { LuminaireNew2, LuminaireSW } from './censoLuminarie';

@Component({
  selector: 'app-censos',
  templateUrl: './censos.component.html',
  styleUrls: ['./censos.component.scss']
})
export class CensosComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  panorama: google.maps.StreetViewPanorama;
  @ViewChild('pano') pano: any;
  Luminaries: LuminaireNew2[];
  LuminariasSW: LuminaireSW[];
  LuminariasM2: LuminaireNew2[];
  lstInfowindows = [];
  acccion;
  accionSW;
  cordSW = { lat: 21.151469659197442, lng: -86.86514019181477 }
  centerMap2d;
  Mylocation;
  constructor(public fb: FormBuilder, public api: CensoService,
    private currentProjectService: CurrentProjectService) {
  }


  ngOnInit() {
    this.paintMap();
  }

  paintMap() {


    const project = this.currentProjectService.getCurrentProject();
    const zoom = 18;
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    const mapProperties = {
      center: this.cordSW,
      zoom: zoom,
      streetViewControl: false,
      disableDoubleClickZoom: true
    };


    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(18);
        let getlocproject = results[0].geometry.location.toJSON();
        this.cordSW = { lat: getlocproject.lat, lng: getlocproject.lng };
        const location = `${getlocproject.lat},${getlocproject.lng}`

        this.init_information(location)
        this.paintPanorama(this.cordSW);
        this.map.setCenter(results[0].geometry.location);


        this.Mylocation = new google.maps.Marker({
          position: results[0].geometry.location,
          map: this.map,
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 10,
            fillOpacity: 1,
            strokeWeight: 2,
            fillColor: '#5384ED',
            strokeColor: '#ffffff',
          },
          label: {
            text: "X",
            fontWeight: "bold"
          }
        });

        let circle = new google.maps.Circle({
          map: this.map,
          radius: 100,    // 10 miles in metres
          fillColor: '#AA0000'
        });
        circle.bindTo('center', this.Mylocation, 'position');

      }
    })



    this.map.addListener('zoom_changed', () => {

      if (this.map.getZoom() > 18) {

   
      }
    });

    this.map.addListener("dblclick", (event) => {

      let newmarker = event.latLng.toJSON();
      let location2 = { latitude: newmarker.lat, longitude: newmarker.lng }
      let marker = new google.maps.Marker({
        map: this.map,
        position: new google.maps.LatLng(newmarker.lat, newmarker.lng),
        title: '¡Hola!'
      });

    });





  }

  paintPanorama(valor) {
    const project = this.currentProjectService.getCurrentProject();
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    this.panorama = new google.maps.StreetViewPanorama(this.pano.nativeElement, {
      position: new google.maps.LatLng(valor.lat, valor.lng),
      pov: { heading: 165, pitch: 0 },
      zoom: 1,
    });




    this.panorama.addListener("position_changed", ($event) => {





      this.updateMarker(this.panorama)

    });

    this.panorama.addListener("dblclick", (event) => {

      let newmarker = event.latLng.toJSON();
    
      let location2 = { latitude: newmarker.lat, longitude: newmarker.lng }
      let marker = new google.maps.Marker({
        map: this.map,
        position: new google.maps.LatLng(newmarker.lat, newmarker.lng),
        title: '¡Hola!'
      });
    });

  }

  paintMarkersMap2d() {

    this.Luminaries = [...this.Luminaries];
    this.Luminaries.forEach(x => {

      x.paintMarker(this.map)

      x.marker.addListener('dragend', (mapsMouseEvent) => {
        const newLoctaion = mapsMouseEvent.latLng.toJSON();
        x.location = { latitude: newLoctaion.lat, longitude: newLoctaion.lng }
        this.Luminaries.filter(y => y.id === x.id)[0].marker.setOptions({ position: { lat: x.location.latitude, lng: x.location.longitude } });

        this.cordSW = { lat: newLoctaion.lat, lng: newLoctaion.lng };
        this.panorama = null;

        this.paintPanorama(this.cordSW);
        this.paintMarkersSW();
      });
      x.marker.addListener('dblclick', (mapsMouseEvent) => {

        this.map.setZoom(18);
        this.map.setCenter(x.marker.getPosition());
        this.cordSW = { lat: x.location.latitude, lng: x.location.longitude };
        this.panorama = null;
        this.paintPanorama(this.cordSW);
        this.paintMarkersSW();
      });

      //DEsplegar Opciones pop-up
      x.marker.addListener('rightclick', (event) => {
        console.group('-----Opciones-----');
        console.log(x, x.marker, event);
        this.editLuminarie(x)
        console.groupEnd()

      })



      x.marker.addListener('click', (event) => {
        console.group('-----Informacion-----');
        console.log('Click MAp2D');

        this.infoMarker(x, this.map);


        console.groupEnd();
      })



    });
  }

  editLuminarie(valor) {
    this.closeAllInfowindows(this.lstInfowindows);
    let editlum = new google.maps.InfoWindow({
      content: this.Options(valor),
      maxWidth: 340
    });
    editlum.open(this.map, valor.marker);
    this.lstInfowindows.push(editlum);
  }

  infoMarker(valor, map) {
    this.closeAllInfowindows(this.lstInfowindows);
    let info = new google.maps.InfoWindow();

    info.setContent("Latitude: " + valor.location +
      "<br>" + "Longitude: " + '');
    info.open(map, valor.marker);
    console.log(info);
    this.lstInfowindows.push(info);
  }


  public closeAllInfowindows(lstInfowindows): void {
    let btnAddComent = document.getElementById('luminaireComment');
    if (btnAddComent) {
      btnAddComent.removeEventListener('click', () => { });
    }

    for (let infowindow of lstInfowindows) {
      infowindow.close();
    }
  }

  init_information(location) {

    this.api.getLumZone(location).subscribe((res: LuminaireNew2[]) => {
      let luminaires = res.map(luminaire => new LuminaireNew2(luminaire));
      let backLuminaires = this.Luminaries;



      if (!this.Luminaries) {
        this.Luminaries = luminaires;

        this.paintMarkersSW();
        this.paintMarkersMap2d();
        return;
      }
      const iqualLums = backLuminaires.filter(lum => luminaires.some(_lum => _lum.id === lum.id));
      const diffHideLums = backLuminaires.filter(lum => !iqualLums.some(_lum => _lum.id === lum.id));
      const diffNewLums = luminaires.filter(lum => !backLuminaires.some(_lum => _lum.id === lum.id));




      this.Luminaries = null;
      this.Luminaries = iqualLums;
      this.Luminaries = this.Luminaries.concat(diffNewLums);

      this.setMarkersToMap(diffHideLums, null);

      this.paintMarkersMap2d();
      this.paintMarkersSW();

    })
  }

  paintMarkersSW() {
   
    this.Luminaries = [...this.Luminaries];
    this.Luminaries.forEach(x => {
      x.paintMarker(this.panorama)
      x.marker.addListener('dragend', (mapsMouseEvent) => {
        const newLoctaion = mapsMouseEvent.latLng.toJSON();
        x.location = { latitude: newLoctaion.lat, longitude: newLoctaion.lng }
        this.Luminaries.filter(y => y.id === x.id)[0].location = x.location;
        this.Luminaries.filter(y => y.id === x.id)[0].marker.setOptions({ position: { lat: x.location.latitude, lng: x.location.longitude } });


        const mapProperties = {
          center: new google.maps.LatLng(newLoctaion.lat, newLoctaion.lng),
          zoom: 18,
          streetViewControl: false,
          disableDoubleClickZoom: true
        };
        this.map = null;
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
        this.paintMarkersMap2d();

        this.Mylocation = new google.maps.Marker({
          position: new google.maps.LatLng(newLoctaion.lat, newLoctaion.lng),
          map: this.map,
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 10,
            fillOpacity: 1,
            strokeWeight: 2,
            fillColor: '#5384ED',
            strokeColor: '#ffffff',
          },
          label: {
            text: "X",
            fontWeight: "bold"
          }
        });
        this.updateMarker(this.panorama);

      });
      x.marker.addListener('dblclick', (mapsMouseEvent) => {
        console.log("DBLClick Panorama");
        this.panorama.setOptions({ position: { lat: x.location.latitude, lng: x.location.longitude } });
        this.updateMarker(this.panorama)
      })
      x.marker.addListener('click', (event) => {
        console.group('-----Informacion-----');
        console.log('Click Street');
        this.infoMarker(x, this.panorama);
        console.groupEnd();
      });

    });
  }

  updateMarker(marker) {
    let prevPosn = marker.getPosition().toJSON();
    let prevPosn2 = this.Mylocation.getPosition();

    this.Mylocation.setPosition(
      new google.maps.LatLng(
        prevPosn.lat,
        prevPosn.lng
      )
    );
    this.Mylocation.setIcon({
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      strokeColor: 'red',
      strokeWeight: 3,
      scale: 6,
      rotation: google.maps.geometry.spherical.computeHeading(prevPosn2, marker.getPosition())
    });
    this.map.setCenter(prevPosn2);
    const location = `${prevPosn.lat},${prevPosn.lng}`

    this.init_information(location);

  }
  private setMarkersToMap(luminaires: LuminaireNew2[], map: google.maps.Map): void {
    console.log(luminaires, "entro");
    for (let luminaire of luminaires) {
      luminaire.marker.setVisible(false);
      luminaire.marker.setMap(null)
      luminaire.setMap(null);
      console.log(luminaire.setMap(null));



    }
  }

  private Options(circuit: any) {
  
    let strInfo = `
    <div>
      <div class=" my-0">
        <p class="mb-0"> <strong> Estado: </strong> </p>
      </div>
      <div>
  
      </div>
      <div class="d-flex justify-content-between">
          <input id="seeMoreLuminaire" type="button" style=" cursor:pointer;" value="Ver mas" class="oe--button--info mr-1">
      
      </div>
    </div>
    `;
    return strInfo;


  }



}
