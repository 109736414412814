import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { GetCircuitResponse } from '@models/get-circuit-response';
import { NewRepairTaskPayload } from '@models/new-repair-taks-payload';

@Injectable()

export class NewRepairTaskService {
  constructor(private httpClient: HttpClient) {}

  getCircuit(circuit_id: string): Observable<GetCircuitResponse> {
    return this.httpClient.get<GetCircuitResponse>(
      `${environment.SERVER_URL}/circuits/${circuit_id}`
    );
  }

  createReparationTask(newInfrastructureTaskPayload: NewRepairTaskPayload):Observable<Object>{
    return this.httpClient.post<object>(
      `${environment.SERVER_CALLCENTER}/api/Tasks/create-repair-task`,
      newInfrastructureTaskPayload.toJson()
    );
  }
}