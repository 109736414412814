import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { LuminairesInstallation_kpis, QuickSurveyKpis } from '@app/models/quick-survey-kpis';
import { InfraestructureSurveyKpis } from './kpi-infrastructure-task/infrastructure-kpis';
import { InstallationKpi } from './kpi-installation-task/installation-kpi';

@Injectable({
  providedIn: 'root'
})
export class KpiTaskService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getKpisQuickSurvey(): Observable<QuickSurveyKpis> {
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/kpis/quick_survey`)
      .pipe(map(data => new QuickSurveyKpis(data.quick_survey_kpis)));
  }

  getKpisInfraestructureSurvey(): Observable<InfraestructureSurveyKpis> {
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/kpis/infrastructure_survey`)
      .pipe(map(data => new InfraestructureSurveyKpis(data.infrastructure_survey_kpis)));
  }

  getKpisInstallation(): Observable<InstallationKpi> {
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/kpis/installation_task`)
      .pipe(map(data => new InstallationKpi(data.installation_task_kpis)));
  }

  getLuminairesInstallation(end_date,start_date): Observable<LuminairesInstallation_kpis> {

    const params: HttpParams = new HttpParams()
      .set('end_date', end_date)
      .set('start_date', start_date);

    return this.httpClient.get<LuminairesInstallation_kpis>(
      `${environment.SERVER_NETCORE}/api/KPIs/installationDashboard`,{ params: params });
  }
}
