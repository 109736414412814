import { Component, OnInit } from '@angular/core';
import { isUndefined } from 'util';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualLogService } from './individual-log.service';


@Component({
  selector: 'app-individual-log',
  templateUrl: './individual-log.component.html',
  styleUrls: ['./individual-log.component.scss']
})
export class IndividualLogComponent implements OnInit {

  finalDate
  initialDate
  log_type = 6
  dirs = [];
  constructor(
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private individualLogService: IndividualLogService
  ) { }

  ngOnInit() {
    this.spinner.hide();
    this.getDirs();
  }

  getDirs() {
    this.individualLogService.getDirs().subscribe(response => {
      this.dirs = response
    });
  }

  printLog() {
    if (isUndefined(this.initialDate) || isUndefined(this.finalDate)) {
      this.toaster.error('Asigne las fechas de busqueda');
      return;
    }

    const initial = this.initialDate['year'] + '-' + this.initialDate['month'] + '-' + this.initialDate['day']
    const final = this.finalDate['year'] + '-' + this.finalDate['month'] + '-' + this.finalDate['day']

    if (new Date(initial) >= new Date(final)) {
      this.toaster.error('La fecha final debe ser menor a la inicial');
      return;
    }

    this.spinner.show('gen-formats')

    this.individualLogService.generateDir(initial, final, this.log_type).subscribe(response => {
      this.spinner.hide('gen-formats');
      this.toaster.success('Archivos generados');
      this.getDirs();
    }, error => {
      this.spinner.hide('gen-formats');
      this.toaster.success('Error al generar los archivos');
    })
  }

  deleteDir(folderName: string) {
    this.spinner.show('delete-formats')

    this.individualLogService.deleteDir(folderName).subscribe(response => {
      this.spinner.hide('delete-formats');
      this.toaster.success('Archivos eliminados');
      this.getDirs();
    }, error => {
      this.spinner.hide('delete-formats');
      this.toaster.success('Error al eliminar los archivos');
    })
  }

  getZip(folderName: string) {
    this.spinner.show('download-formats')
    this.individualLogService.downloadDir(folderName)
  }

}
