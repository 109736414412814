export interface ProfileInterface {
  name: string,
  last_name: string,
  email: string,
  cell_phone_number: string,
  assigned_tools: string,
  address: string,
  roles: Array<Object>
  image_url: string;
}

export class Profile implements ProfileInterface {
  name: string;
  last_name: string;
  email: string;
  cell_phone_number: string;
  assigned_tools: string;
  address: string;
  roles: Array<object>;
  image_url: string;

  constructor() {
    this.name = '';
    this.last_name = '';
    this.email = '';
    this.cell_phone_number = "";
    this.assigned_tools = "";
    this.address = '';
    this.roles = [];
  }
}