import { Meter } from './meter';
import { Rpus } from './rpus';

export interface ICircuitRpu {
    id: string;
    rpus_info: Rpus;
    meter: Meter;
    wattage: number;
}

export class CircuitRpu implements ICircuitRpu {
    id: string;
    rpus_info: Rpus;
    color: string;
    meter: Meter;
    wattage: number;
    isSelected: boolean;
    marker: google.maps.Marker;
    event: string;

    readonly markerIcons = {
        normal: 'assets/img/svg/circuit_normal.svg',
        selected: 'assets/img/svg/circuit_selected.svg',
        circuitShowing: 'assets/img/svg/ic_circuit_normal.svg',
        over: 'assets/img/svg/ic_circuit_over_power.svg',
        below: 'assets/img/svg/ic_circuit_below_power.svg'
    }

    constructor(attrs: any) {
        this.id = attrs.id;
        this.isSelected = false;
        this.rpus_info = attrs.rpus_info;
        this.meter = attrs.meter;
        this.color = attrs.color;
    }

    get_icon(): object {
        if(this.isSelected) {
            return {
                url: this.markerIcons['selected']
            }
        } else {
            return {
                url: this.markerIcons[this.color]
            }
        }
    }

    upadeIcon(event: string): void {
        this.event = event;
        this.marker.setIcon(this.get_icon());
    }

    paintMarker(map: any): void {
        const latitude = this.meter.location.latitude;
        const longitude = this.meter.location.longitude;
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.id,
            position: new google.maps.LatLng(latitude, longitude),
            icon: this.get_icon(),
            map: map
        });
    }

    hideMarker(): void {
        this.marker.setMap(null);
    }

    setMap(map: any): void {
        this.marker.setMap(map);
    }
}
