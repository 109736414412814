import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Item } from '@app/models/item';
import { InventoryService } from '@app/pages/inventory/inventory.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TroopMaterialItems } from '../troop';
import { TroopsService } from '../troops.service';

@Component({
  selector: 'app-troop-material-items',
  templateUrl: './troop-material-items.component.html',
  styleUrls: ['./troop-material-items.component.scss'],
  providers: [TroopsService]
})
export class TroopMaterialItemsComponent implements OnInit {

  materials: TroopMaterialItems[] = [];
  totalRef = 0;
  troopID;
  @Input() disableButtonsForm: boolean;

  constructor(private troopsService: TroopsService,
    private activatedRoute: ActivatedRoute,
    private toastr: Toastr,
    private authService: AuthService,
    private inventoryService: InventoryService) { }

  ngOnInit() {

    this.troopID = this.activatedRoute.snapshot.paramMap.get('id');
    this.troopsService.GetTroopMaterilasItems(this.troopID).subscribe((res: TroopMaterialItems[]) => {
      this.materials = res;
      // console.log(this.materials);
    });
  }

  saveTotal(item: IMaterialTroop) {
    let bk = item.quantity;
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    // tslint:disable-next-line:max-line-length
    const form = {
      material_id: item.material_id,
      removed_from: 1,
      quantity: this.totalRef - item.quantity,
      user_id: decodeToken['id'],
      cap_id: this.troopID
    };
    const updateTroopInventory = {
      troopMaterials_id: item.troopMaterials_id,
      quantity: this.totalRef,
    };
    // console.log(form);
    // console.log(updateTroopInventory);
    item.quantity = this.totalRef;

    this.inventoryService.registerLog(form).subscribe(response => {
      this.troopsService.updateTroop(updateTroopInventory)
        .subscribe(result => {
          this.toastr.success('Material actualizado.');
        }, error => {
          this.toastr.error(error);
          item.quantity = bk;
        })
    })
    item.editing = false;
  }

  editMaterial(item: IMaterialTroop) {
    for (let item of this.materials) {
      item.editing = false;
    }
    this.totalRef = item.quantity;

    item.editing = !item.editing;
  }

  operationToTotal(item: Item, sum?: boolean) {
    if (sum) {
      this.totalRef += 10;
    }
    else if ((item.quantity - 10) >= 0)
      this.totalRef -= 10;
    else
      this.totalRef = 0;
  }

}
