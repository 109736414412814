import { FormGroup } from '@angular/forms';
import { Luminaire } from './luminaire';


export class ReportLuminairePayload {
    userID: string;
    address: string;
    comment: string;
    disclaimer_was_accepted: boolean;
    problem: number;
    luminaires: Luminaire[];
    specification: string;
    name: string;
    first_last_name: string;
    second_last_name: string;
    email: string;
    phone_number: string;
    dial_code: string;
    source: string;
    responsible_party: string;
    whatsapp_disclaimer_was_accepted: boolean;


  constructor(reportForm: FormGroup, userID: string) {
    this.userID = userID;
    this.address = reportForm.get('address').value;
    this.comment = reportForm.get('comment').value;
    this.disclaimer_was_accepted = reportForm.get('disclaimer_was_accepted').value;
    this.whatsapp_disclaimer_was_accepted = reportForm.get('whatsapp_disclaimer_was_accepted').value;
    this.problem = reportForm.get('problem').value;
    this.luminaires = reportForm.get('luminaires').value;
    this.specification = reportForm.get('specification').value;
    this.name = reportForm.get('name').value;
    this.first_last_name = reportForm.get('first_last_name').value;
    this.second_last_name = reportForm.get('second_last_name').value;
    this.email = reportForm.get('email').value;
    this.dial_code = reportForm.get('dial_code').value;
    this.phone_number = reportForm.get('phone_number').value ? `${this.dial_code}${reportForm.get('phone_number').value}` : null ;
    this.source = reportForm.get('source').value;
    this.responsible_party = reportForm.get('responsibleParty').value;
  }

  toJson(): Object {
    const json = {
        ticket: {
            address: this.address,
            comment: this.comment,
            disclaimer_was_accepted: this.disclaimer_was_accepted,
            whatsapp_disclaimer_was_accepted: this.whatsapp_disclaimer_was_accepted,
            problem: this.problem,
            luminaires: this.luminaires,
            specification: this.specification,
            source: this.source,
            responsible_party: this.responsible_party,
            citizen: {
                name: this.name,
                first_last_name: this.first_last_name,
                second_last_name: this.second_last_name,
                email: this.email,
                phone_number: this.phone_number
            }
        }
    };

    return json;
  }
}
