import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CallCenterDashboard } from '@app/models/call-center-dashboard';
import { CallcenterDashboardService } from './callcenter-dashboard.service';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { StatusEnum } from '@app/models/status-enum';
import { ProblemEnum } from '@app/models/problem-enum';
import { TechnologyEnum } from '@app/models/technology-enum';
import { SourceEnum } from '@app/models/source-enum';
import * as moment from 'moment';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
  selector: 'app-call-center-dashboard',
  templateUrl: './call-center-dashboard.component.html',
  styleUrls: ['./call-center-dashboard.component.scss'],
})
export class CallCenterDashboardComponent implements OnInit {
  callCenter: CallCenterDashboard;
  formCallCenter: FormGroup;
  public lumChart: any;
  public openChart: any;
  public closeChart: any;
  public problemChart: any;
  public usersChart: any;
  public lumTech: any;
  public lumtech: any;
  public TicketByEntryMethod: any;
  infLoop;
  selectIntervalValue: number;


  constructor(
    private callService: CallcenterDashboardService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
   const start = new Date();
    this.formCallCenter = this.fb.group({
      StartDate: [{year: start.getFullYear(), month: start.getMonth() + 1, day: 1}],
      EndDate: [{year: start.getFullYear(), month: start.getMonth() + 1, day: start.getDate()}]
    });
    this.getCallCenterDashboard();
  }

  private buildGaugeforOpen(): any {
    return {
      chart: {
        type: 'solidgauge',
      },
      title: null,
      pane: {
        center: ['50%', '85%'],
        size: '120%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        stops: [
          [0.9, 'rgba(255,106,0,0.5)'],
          [0.5, 'rgba(255,106,0,0.5)'],
          [0.1, 'rgba(255,106,0,0.5)'],
        ],
        lineWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -80,
        },
        labels: {
          y: 16,
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
    };
  }

  private buildGaugeForClose(): any {
    return {
      chart: {
        type: 'solidgauge',
      },
      title: null,
      pane: {
        center: ['50%', '85%'],
        size: '120%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        stops: [
          [0.9, 'rgba(6,143,12,0.5)'],
          [0.5, 'rgba(6,143,12,0.5)'],
          [0.1, 'rgba(6,143,12,0.5)'],
        ],
        lineWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -80,
        },
        labels: {
          y: 16,
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
    };
  }

  private openTicketChart(
    gaugeOptions: any,
    maxValue: number,
    currentValue: number
  ) {
    this.openChart = Highcharts.chart(
      'openTicket',
      Highcharts.merge(gaugeOptions, {
        yAxis: {
          min: 0,
          max: maxValue,
          title: {
            text: 'Estatus de Tickets',
            style: {
              fontSize: '19px',
              color: 'black',
            },
          },
          tickPositioner: function () {
            return [this.min, this.max];
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            data: [currentValue],
            dataLabels: {
              format:
                '<div style="text-align:center">' +
                '<span style="font-size:25px">{y}</span><br/>' +
                '<span style="font-size:10px;opacity:0.4">Open Tickets</span>' +
                '</div>',
            },
            tooltip: {
              valueSuffix: 'Abiertos',
            },
          },
        ],
      })
    );
  }

  private closeTicketChart(
    gaugeOptions: any,
    maxValue: number,
    currentValue: number
  ) {
    this.closeChart = Highcharts.chart(
      'closeTicket',
      Highcharts.merge(gaugeOptions, {
        yAxis: {
          min: 0,
          max: maxValue,
          tickPositioner: function () {
            return [this.min, this.max];
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            data: [currentValue],
            dataLabels: {
              format:
                '<div style="text-align:center">' +
                '<span style="font-size:25px">{y}</span><br/>' +
                '<span style="font-size:10px;opacity:0.4">Closed Tickets</span>' +
                '</div>',
            },
            tooltip: {
              valueSuffix: 'Cerrados',
            },
          },
        ],
      })
    );
  }

  private lumStatusChart(cate, values) {
    this.lumChart = Highcharts.chart('lumChart', {
      chart: {
        inverted: false,
        polar: false,
      },
      title: {
        text: 'Estatus de Luminarias',
      },

      xAxis: {
        categories: cate,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      colors: [
        'rgba(6,143,12,0.5)',
        'rgba(0,56,174,0.5)',
        'rgba(119,121,122,0.5)',
        'rgba(158,235,24,0.5)',
        'rgba(40,244,242,0.5)',
      ],
      series: [
        {
          type: 'column',
          colorByPoint: true,
          data: values,
          showInLegend: false,
        },
      ],
    });
  }

  private users(names, amounts) {
    this.usersChart = Highcharts.chart('usersChart', {
      chart: {
        inverted: false,
        polar: false,
      },
      title: {
        text: 'Agente',
      },

      xAxis: {
        categories: names,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      colors: [
        'rgba(255,106,0,.5)',
        'rgba(0,56,174,.5)',
        'rgba(119,121,122,.5)',
        'rgba(158,235,24,.5)',
        'rgba(40,244,242,.5)',
        'rgba(235,24,158,.5)',
        'rgba(244,237,40,.5)',
        'rgba(235,24,24,.5)',
        'rgba(180,9,223,.5)',
        'rgba(48,170,140,.5)',
        'rgba(46,49,50,.5)',
        'rgba(189,154,33,.5)',
        'rgba(8,100,114,.5)',
        'rgba(242,177,206)',
        'rgba(183,138,98)',
        'rgba(142,38,18,.5)',
        'rgba(6,143,12,.5)',
      ],
      series: [
        {
          type: 'column',
          colorByPoint: true,
          data: amounts,
          showInLegend: false,
        },
      ],
    });
  }

  private ticketByEntry(names, amounts) {
    this.TicketByEntryMethod = Highcharts.chart('ticketMethod', {
      chart: {
        inverted: false,
        polar: false,
      },
      title: {
        text: 'Tickets Por Fuente',
      },

      xAxis: {
        categories: names,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      colors: [
        'rgba(255,106,0,.5)',
        'rgba(0,56,174,.5)',
        'rgba(119,121,122,.5)',
        'rgba(158,235,24,.5)',
        'rgba(40,244,242,.5)',
        'rgba(235,24,158,.5)',
      ],

      series: [
        {
          type: 'bar',
          colorByPoint: true,
          data: amounts,
          showInLegend: false,
        },
      ],
    });
  }

  private problem(data) {
    this.problemChart = Highcharts.chart('problemChart', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Tipo de Falla',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y} </b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y} ',
            connectorColor: 'silver',
          },
          showInLegend: true,
        },
      },
      legend: {
        enabled: true,
        labelFormatter: function () {
          return this.name + ': ' + this['y'];
        },
      },
      colors: [
        'rgba(255,106,0,.5)',
        'rgba(0,56,174,.5)',
        'rgba(119,121,122,.5)',
        'rgba(40,244,242,.5)',
        'rgba(235,24,158,.5)',
        'rgba(244,237,40,.5)',
        'rgba(135,24,24,.5)',
      ],
      series: [
        {
          name: 'Share',
          type: undefined,
          data: data,
        },
      ],
    });
  }

  private lTech(values) {
    this.lumtech = Highcharts.chart('lumtech', {
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>',
      },
      title: {
        text: 'Techologia',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      legend: {
        enabled: true,
        labelFormatter: function () {
          return this.name + ': ' + this['y'];
        },
      },
      credits: {
        enabled: false,
      },
      colors: [
        'rgba(255,106,0,.5)',
        'rgba(119,121,122,.5)',
        'rgba(158,235,24,.5)',
        'rgba(40,244,242,.5)',
        'rgba(0,56,174,.5)',
      ],
      series: [
        {
          type: 'pie',
          name: this['name'],
          innerSize: '50%',
          data: values,
        },
      ],
    });
  }


  onChangeTimer(event) {
    this.selectIntervalValue = event;
      if (this.infLoop != null || this.infLoop !== undefined) {
        clearInterval(this.infLoop);
     }
     this.infLoop = setInterval(() => {
        this.getCallCenterDashboard();
        console.log(this.selectIntervalValue);
     }, this.selectIntervalValue);

 }

  getCallCenterDashboard() {
    const StartDate = this.formCallCenter.get('StartDate').value;
    const EndDate = this.formCallCenter.get('EndDate').value;
    this.callService
      .getCallCenterDashboard(
        this.parseDate(StartDate),
        this.parseDate(EndDate)
      )
      .subscribe((response) => {
        this.callCenter = response;
        const maxValue =
          this.callCenter.ticketStatus.close +
          this.callCenter.ticketStatus.open;
        this.openTicketChart(
          this.buildGaugeforOpen(),
          maxValue,
          this.callCenter.ticketStatus.open
        );
        this.closeTicketChart(
          this.buildGaugeForClose(),
          maxValue,
          this.callCenter.ticketStatus.close
        );

        const lumStatus = [];
        const lumStatusAmount = [];
        const ticketProblem = [];
        const ticketUserNames = [];
        const ticketUserAmount = [];
        const LumByTech = [];
        const ticketEntryName = [];
        const ticketEntryAmount = [];

        this.callCenter.tickeClosed.forEach((element) => {
          const ticketEnums = StatusEnum[element.status];
          lumStatus.push(ticketEnums);
          lumStatusAmount.push(element.amount);
        });
        this.lumStatusChart(lumStatus, lumStatusAmount);

        this.callCenter.ticketByProblem.forEach((element) => {
          const ticketEnums = ProblemEnum[element.problem];
          ticketProblem.push({ name: ticketEnums, y: element.amount });
        });
        this.problem(ticketProblem);

        this.callCenter.ticketByuser.forEach((element) => {
          ticketUserNames.push(element.userName + element.lastName);
          ticketUserAmount.push(element.amount);
        });
        this.users(ticketUserNames, ticketUserAmount);

        this.callCenter.ticketByLumTech.forEach((element) => {
          const lumTech = TechnologyEnum[element.lumTech];
          LumByTech.push([lumTech, element.amount]);
        });
        this.lTech(LumByTech);

        this.callCenter.ticketByEntryMethod.forEach((element) => {
          const ticketEntryMethod = SourceEnum[element.method];
          ticketEntryName.push(ticketEntryMethod);
          ticketEntryAmount.push(element.amount);
        });
        this.ticketByEntry(ticketEntryName, ticketEntryAmount);
      });
  }

  parseDate(date) {
    if (date != null) {
      return `${date.year}/${date.month}/${date.day}`;
    }
    return '';
  }
}
