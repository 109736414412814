import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JudmentService } from '@app/pages/guaranties/judment_claims/judment/judment.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GuarantyPhotoCellsOpinion } from '@app/models/judment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Toastr } from '@app/shared/toastr/toastr.service';

@Component({
  selector: 'app-guarantyphotocells',
  templateUrl: './guarantyphotocells.component.html',
  styleUrls: ['./guarantyphotocells.component.scss']
})
export class GuarantyphotocellsComponent implements OnInit {
  is_update = true;
  is_accepted;
  photocellOpinion_Id: string;
  formGroup: FormGroup;
  constructor(
    private toastr: Toastr,
    private judmentService: JudmentService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.photocellOpinion_Id = this.activatedRoute.snapshot.paramMap.get('id');
    this.formGroup = this.fb.group({
      id: [null],
      guaranty_photocell_claim_id: [null],
      policy_number: [null],
      model: [null],
      created_at: [null],
      turn_on: [null],
      internal_work: [null],
      conditions: [null],
      is_guaranty: [null],
      send_to_provider: [null],
      is_accepted: [null]
    });

    // this.getGuarantyOpinion(this.photocellOpinion_Id);
  }

  getGuarantyOpinion(guranty_photocell_claim_id: string) {

    this.judmentService.get_Photocells_Opinions(guranty_photocell_claim_id).subscribe(
      response => {
        this.populateForm(response);
        if (this.formGroup.value.id === '00000000-0000-0000-0000-000000000000') {
          this.is_update = false;
        }
      }
    );
  }

  private populateForm(detailPhotoCellOpinion: GuarantyPhotoCellsOpinion) {
    this.is_accepted = detailPhotoCellOpinion.is_accepted;
    this.formGroup.patchValue({
      id: detailPhotoCellOpinion.id,
      guaranty_photocell_claim_id: this.photocellOpinion_Id,
      policy_number: detailPhotoCellOpinion.policy_number,
      model: detailPhotoCellOpinion.model,
      created_at: detailPhotoCellOpinion.created_at,
      turn_on: detailPhotoCellOpinion.turn_on,
      internal_work: detailPhotoCellOpinion.internal_work,
      conditions: detailPhotoCellOpinion.conditions,
      is_guaranty: detailPhotoCellOpinion.is_guaranty,
      send_to_provider: detailPhotoCellOpinion.send_to_provider,
      is_accepted: detailPhotoCellOpinion.is_accepted
    });
  }

  registerPhotocellOpinion(val: FormGroup) {
    const insert = {
      guaranty_photocell_claim_id: val.value.guaranty_photocell_claim_id,
      policy_number: val.value.policy_number,
      model: val.value.model,
      turn_on: val.value.turn_on,
      internal_work: val.value.internal_work,
      conditions: val.value.conditions,
      is_guaranty: val.value.is_guaranty,
      send_to_provider: val.value.send_to_provider,
      is_accepted: this.is_accepted
    }
    this.judmentService.register_PhotocellOpinion(insert).subscribe(
      response => {
        this.toastr.success('Se Guardo Correctamente.');
        this.router.navigate(['/u/guaranties/judment_claims']);
      }
    );
  }

  updatePhotocell(val: FormGroup) {
    const insert = {
      id: this.formGroup.value.id,
      guaranty_photocell_claim_id: val.value.guaranty_photocell_claim_id,
      policy_number: val.value.policy_number,
      model: val.value.model,
      turn_on: val.value.turn_on,
      internal_work: val.value.internal_work,
      conditions: val.value.conditions,
      is_guaranty: val.value.is_guaranty,
      send_to_provider: val.value.send_to_provider,
      is_accepted: this.is_accepted
    }

    this.judmentService.update_PhotocellOpinion(insert).subscribe(
      response => {
        this.toastr.success('La Informacion se actualizo correctamente.');
        this.router.navigate(['/u/guaranties/judment_claims']);
      }
    );

  }





}
