import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ProjectResponse } from 'app/models/project-response';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private httpClient: HttpClient) { }

  generateNumerate():Observable<any> {
    return this.httpClient
      .post<any>(`${environment.SERVER_URL}/luminaires/labels`, {});
  }

  getProject(projectID: string):Observable<any> {
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/projects/${projectID}`);
  }

  putProject(projectID: string, tickets_enabled: boolean, installation_goal?: number):Observable<any> {
    return this.httpClient
      .put<any>(`${environment.SERVER_URL}/projects/${projectID}`, { 'tickets_enabled': tickets_enabled, 'installation_goal': installation_goal });
  }
}
