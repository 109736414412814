import { Component, OnInit } from '@angular/core';
import { GenericTable } from '@app/models/generic-table';
import { RepairsTable } from '@app/models/repairs-table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RepairTasksService } from './repair-tasks.service';

@Component({
  selector: 'app-repair-tasks',
  templateUrl: './repair-tasks.component.html',
  styleUrls: ['./repair-tasks.component.scss']
})
export class RepairTasksComponent implements OnInit {

  repairsTable: GenericTable<RepairsTable>;
  task_reference = '';
  is_Finished = '';
  selectedTasks = [];
  repairs: RepairsTable[] = [];

  constructor(
    private repairService: RepairTasksService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.hide();
    this.getElementsPaginated(0);
  }

  getElementsPaginated(page: number) {
    this.repairService.getInstallationTasks(page, this.task_reference, this.is_Finished).subscribe(response => {
      let listWithSelect = [];
      this.repairsTable = response;
      if (this.selectedTasks.length == 0) {
        this.repairs = response.elements;
      }
      else {
        response.elements.forEach(ele => {
          if (this.selectedTasks.find(x => x == ele.task_id)) {
            ele.selected = true;
          }
          listWithSelect.push(ele)
        });
        this.repairs = listWithSelect;
      }

    });
  }

  selectTask(task: RepairsTable) {
    task.selected = !task.selected;
  }

  checkTask(task: RepairsTable) {
    task.selected = !task.selected;
    if (task.selected == true)
      this.selectedTasks.push(task.task_id);
    if (task.selected == false) {

      this.selectedTasks = this.selectedTasks.filter(x => x != task.task_id);
    }
  }

  printSelected(printable_type) {
    const tasks = this.repairs.filter(task => task.selected === true);
    const taks_id = this.selectedTasks;

    // tasks.forEach(task => {
    //   taks_id.push(task.task_id);
    // })

    if (taks_id.length === 0) {
      this.toaster.error('Eliga al menos una tarea de instalación');
      return;
    }

    const form = { task_id: taks_id, printable_type: printable_type };
    console.log(form)
    this.repairService.downloadRepairsFormats(form);
  }

  onSearch() {
    this.repairService.getInstallationTasks(0, this.task_reference, this.is_Finished).subscribe(response => {
      this.repairs = response.elements
    });
  }

  pageChange(page: number) {
    this.getElementsPaginated(page - 1);
  }

}
