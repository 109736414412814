export enum MtVoltageEnum {
    '2400 - 4160',
	'2400 - 19920',
	'4160 - 2400',
	' 11400',
	' 13200',
	'13200(Y) - 7620',
	' 13800',
	'19920 - 34500',
	'34500 - 19920',
	'Otro'
}