import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { LoginPayload } from './login-payload';
import { LoginResponse } from '@models/login-response';

@Injectable()

export class SessionService {
  constructor(private httpClient: HttpClient) {}

  createSession(loginPayload: LoginPayload): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(
      `${environment.SERVER_URL}/sessions`,
      loginPayload.toJson()
    );
  }
}
