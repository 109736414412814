import { Component, OnInit } from '@angular/core';
import { LogMaintanenceService } from '../log-maintanence/log-maintanence.service';

@Component({
  selector: 'app-users-active-tasks',
  templateUrl: './users-active-tasks.component.html',
  styleUrls: ['./users-active-tasks.component.scss']
})
export class UsersActiveTasksComponent implements OnInit {

  userTasks: any = [];

  constructor(
    private service: LogMaintanenceService
  ) { }

  ngOnInit() {
    this.service.userTasks().subscribe(response => {
      this.userTasks = response
    })
  }

}
