import { Injectable } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DetailGuarantyReport, GuarantyReport } from './guaranty-report-model';
import { GenericTable } from '@app/models/generic-table';
import { environment } from '@environments/environment';
import { map } from 'highcharts';

@Injectable({
  providedIn: 'root'
})
export class GuarantyReportService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  getGuarantyReport(filter) {
    return this.httpClient.post<GenericTable<GuarantyReport>>(
      `${environment.SERVER_TASKS}/api/GuarantyReport/get-list-guaranty-reports`, filter
    );
  }

  detailGuarantyReport(guarantyReport_id) {
    const httpParams = new HttpParams()
      .set('guaranty_report_id', guarantyReport_id);
    return this.httpClient.get<DetailGuarantyReport>(
      `${environment.SERVER_TASKS}/api/GuarantyReport/get-detail-guaranty-report`, { params: httpParams }
    )
    // return this.httpClient.get<>

  }

  changeStatusGuarantyReport(update) {
    return this.httpClient.post(`${environment.SERVER_TASKS}/api/GuarantyReport/change-status-report`, update);
  }





  downloadPDFGuarantyReport(report_ids) {
    this.httpClient.post(
      `${environment.SERVER_TASKS}/api/GuarantyReport/download-pdf-guaranty-reports`, report_ids,
      { responseType: 'blob', }
    ).subscribe(file => {
      this.downloadProcess(file, `Reporte-Garantia.pdf`);
    }, error => {
      console.log(error);
    });
  }

  private downloadProcess(file, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([file], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }




}
