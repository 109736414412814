import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { environment } from '@environments/environment';

@Injectable()
export class LoginService {
    constructor(private httpClient: HttpClient) { }

    changePassword(token: string, { password, password_confirmation }: { password: string, password_confirmation: string }) {
        return this.httpClient
          .put(`${environment.SERVER_URL}/password_recovery`,  { token, password, password_confirmation } )
          .map((response: Response) => response);
      }

    forgotPassword(email: string) {
        return this.httpClient
          .post(`${environment.SERVER_URL}/password_recovery `, { email })
          .map((response: Response) => response );
      }
}