import { RoleEnum } from './role-enum';

interface RoleInterface {
  id: string,
  name: string
}

export class Role implements RoleInterface {
  id: string;
  name: string;
  enumValue: number;

  constructor(attrs: Role) {
    this.id = attrs.id;
    this.name = RoleEnum[attrs.name];
    this.enumValue = parseInt(attrs.name);
  }
}