import { TariffEnum } from './tariff-enum';
import { SupplyEnum } from './supply-enum';
import { Substation } from './substation';
import { Meter } from './meter';
import { Wiring } from './wiring';
import { Cabinet } from './cabinet';
import { Lamppost } from './lamppost';
import { LightTask } from './light-task';
import { Repair } from './repair';
import { Installation } from './installation';
import { Rpus } from './rpus';

interface CircuitInterface {
  id: string,
  rpu: string,
  tariff: number,
  tariff_name: string,
  supply: number,
  supply_name: string,
  identifier: string,
  weighted_score: number,
  device_name: string,
  device_id: string,
  initial_calculated_kwh: number;
  comment: string,
  first_lamppost: Lamppost,
  last_lamppost: Lamppost,
  substation: Substation,
  meter: Meter,
  wiring: Wiring,
  cabinet: Cabinet,
  lampposts: Lamppost[],
  lightTasks: LightTask[]
  repair: Repair;
  installation: Installation;
  is_VirtualCircuit: boolean;
}

export class Circuit implements CircuitInterface {
  id: string;
  rpu: string;
  tariff: number;
  tariff_name: string;
  supply: number;
  supply_name: string;
  identifier: string;
  weighted_score: number;
  device_name: string;
  device_id: string;
  initial_calculated_kwh: number;
  comment: string;
  first_lamppost: Lamppost;
  last_lamppost: Lamppost;
  substation: Substation;
  meter: Meter;
  wiring: Wiring;
  cabinet: Cabinet;
  lampposts: Lamppost[];
  lightTasks: LightTask[];
  marker: google.maps.Marker;
  event: string;
  isSelected: boolean;
  repair: Repair;
  status: number;
  statusName: string;
  installation: Installation;
  is_VirtualCircuit: boolean;

  readonly markerIcons = {
    normal: 'assets/img/svg/circuit_normal.svg',
    selected: 'assets/img/svg/circuit_selected.svg',
    circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
  }

  constructor(attrs: any) {
    this.id = attrs.id;
    this.rpu = attrs.rpu;
    this.isSelected = false;
    this.tariff = attrs.tariff;
    this.supply = attrs.supply;
    this.identifier = attrs.identifier;
    this.weighted_score = attrs.weighted_score;
    this.device_name = attrs.device_name;
    this.device_id = attrs.device_id;
    this.initial_calculated_kwh = attrs.initial_calculated_kwh;
    this.supply_name = SupplyEnum[attrs.supply];
    this.tariff_name = TariffEnum[attrs.tariff];
    this.comment = attrs.comment == null ? '' : attrs.comment.trim();
    this.meter = attrs.meter != null ? new Meter(attrs.meter) : null;
    this.wiring = attrs.wiring != null ? new Wiring(attrs.wiring) : null;
    this.cabinet = attrs.cabinet != null ? new Cabinet(attrs.cabinet) : null;
    this.substation = attrs.substation != null ? new Substation(attrs.substation) : null;
    this.last_lamppost = attrs.last_lamppost == null ? null : new Lamppost(attrs.last_lamppost);
    this.first_lamppost = attrs.first_lamppost == null ? null : new Lamppost(attrs.first_lamppost);
    this.lampposts = attrs.lampposts != null ? attrs.lampposts.map((lamppost: Lamppost) => new Lamppost(lamppost)) : [];
    this.lightTasks = attrs.tasks != null ? attrs.tasks.map((lightTask: LightTask) => new LightTask(lightTask)) : null;
    this.repair = attrs.repair != null ? new Repair(attrs.repair) : null;
    this.status = attrs.status || 0;
    this.statusName = StatusmEnum[this.status];
    this.installation = attrs.installation ? new Installation(attrs.installation) : null;
    this.is_VirtualCircuit = attrs.is_VirtualCircuit;

  }

  get_icon(): object {
    return {
      url: this.markerIcons[this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
    };
  }

  upadeIcon(event: string): void {
    this.event = event;
    this.marker.setIcon(this.get_icon());
    for (let lamppost of this.lampposts) {
      lamppost.upadeIcon(event);
    }
  }

  paintMarker(map: any): void {
    let latitude = this.meter.location.latitude;
    let longitude = this.meter.location.longitude;
    this.event = this.isSelected ? 'selected' : 'normal';
    this.marker = new google.maps.Marker({
      title: this.id,
      position: new google.maps.LatLng(latitude, longitude),
      icon: this.get_icon(),
      map: map
    });
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }
  setMap(map: any): void {
    this.marker.setMap(map);
    for (let lamppost of this.lampposts) {
      lamppost.setMap(map);
    }
  }
}


enum StatusmEnum {
  'Sin asignar',
  'Programada para reparación',
  'Pendiente',
  'En proceso de reparación',
  'Funcionando'
}