import { FormGroup } from '@angular/forms';

import { Profile } from '@models/profile';

export class ProfilePayload implements Profile {
    name: string;
    last_name: string;
    email: string;
    cell_phone_number: string;
    assigned_tools: string;
    address: string;
    roles: Array<Object>
    image: any;
    image_url: string;

  constructor(ProfileForm: FormGroup) {
    this.name = ProfileForm.get('name').value;
    this.last_name = ProfileForm.get('last_name').value;
    this.email = ProfileForm.get('email').value;
    this.image = ProfileForm.get('image').value;
  }

  toFormData(): FormData {
    let formData = new FormData();

    formData.append('profile[name]', this.name);
    formData.append('profile[last_name]', this.last_name);
    formData.append('profile[email]', this.email);
    formData.append('profile[image]', this.image);

    return formData;
  }

  toJson(): Object {
    const json = {
      profile: {
        name: this.name,
        last_name: this.last_name,
        email: this.email
      }
    };

    return json;
  }
}
