import { GeolocationPoint } from './geolocation-point';

interface SurveyAreaInterface {
  id: string
  points: GeolocationPoint[];
}

export class SurveyArea implements SurveyAreaInterface {
  id: string;
  points: GeolocationPoint[];

  constructor(polygon: any) {
    if (!polygon)
      return
    this.points = this.deserializePolygon(polygon);
  }

  populate(attrs: any) {
    this.id = attrs.id;
    this.points = attrs.points.map(point => new GeolocationPoint(point.latitude, point.longitude));
  }

  private deserializePolygon(polygon: any): GeolocationPoint[] {
    let geolocationPoints: GeolocationPoint[] = [];
    const polygonPath = polygon.getPath();

    for (let i = 0; i < polygonPath.getLength(); i++) {
      let point = polygonPath.getAt(i);

      geolocationPoints.push(new GeolocationPoint(point.lat(), point.lng()));
    }

    return geolocationPoints;
  }
}
