export interface IPersonCbo {
    person_id: string,
    person_name: string
}

export class PersonCbo implements IPersonCbo {
    person_id: string;
    person_name: string;

    constructor(attrs) {
        this.person_id = attrs.person_id,
            this.person_name = attrs.person_name
    }

}
