import { FormGroup } from '@angular/forms';
import { SurveyArea } from './survey-area';

export class NewInfrastructureTaskPayload {
  type: number;
  sector: string;
  deadline: string;
  formattedDeadline: string;
  troop: string;
  surveyAreas: any[];
  parent_task_id: string;

  constructor(newInfrastuctureTaskForm: FormGroup) {
    this.type = 1;
    this.parent_task_id = newInfrastuctureTaskForm.get('parent_task_id').value;
    this.sector = newInfrastuctureTaskForm.get('sector').value;
    this.formattedDeadline = newInfrastuctureTaskForm.get('formattedDeadline').value;
    this.troop = newInfrastuctureTaskForm.get('troop').value;
    this.surveyAreas =
      this.deserializeSurveyAreas(newInfrastuctureTaskForm.get('survey_areas').value);
    this.addSurveyAreasExist(newInfrastuctureTaskForm.get('survey_areas_exist').value);

    let areas = newInfrastuctureTaskForm.get('survey_areas').value;
  }

  toJson(): Object {
    let json = {
      task: {
        type: this.type,
        parent_task_id: this.parent_task_id,
        sector: this.sector,
        deadline: this.formattedDeadline,
        user: null,
        survey_areas: this.surveyAreas
      }
    };

    if(this.troop){
      json.task['user'] = {
        id: this.troop
      }
    }
    return json;
  }

  private deserializeDeadline(deadline: any): string {
    return `${deadline.year}-${deadline.month}-${deadline.day}`;
  }

  private deserializeSurveyAreas(surveyAreas: any): SurveyArea[] {
    return surveyAreas.map(surveyArea => new SurveyArea(surveyArea));
  }

  private addSurveyAreasExist(surveyAreas: any) {
    for (let i = 0; i < surveyAreas.length; i++){
      let areaExist = { id: surveyAreas[i] };
      this.surveyAreas.push(areaExist);
    }
  }
}
