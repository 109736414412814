import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

import { GetTroopSupervisorsResponse } from '@models/get-troop-supervisors-response';
import { GetSectorsResponse } from '@models/get-sectors-response';
import { GetTasksResponse } from '@models/get-tasks-response';
import { GetTaskResponse } from '@models/get-task-response';
import { SurveyAreaPayload } from '../survey-areas/survey-area-payload';
import { Trooper } from '@models/trooper';
import { GetTroopersResponse } from '@models/get-troopers-response';
import { map } from 'rxjs/operators';
import { SurveyArea } from '@models/survey-area';
import { GenericTable } from '@app/models/generic-table';
import { Sector } from '@app/models/sector';
import { Task } from '@app/models/task';

@Injectable()
export class TasksService {
  constructor(private httpClient: HttpClient) { }

  getSectors() {
    return this.httpClient.get<Sector[]>(
      `${environment.SERVER_TASKS}/api/Tasks/GetSectors`
    );
  }

  // getTasks(page, form) {
  //   const params: HttpParams = new HttpParams().set('page', page)
  //   return this.httpClient
  //     .post<GenericTable<GetTasksResponse>>(`${environment.SERVER_TASKS}/api/Tasks/GetTasksFilters`, form, { params: params }
  //     ).pipe(
  //       map(elements => new GenericTable<GetTasksResponse>(elements))
  //     );
  // }

  getTasks(page, type, user, sector, reference, finished) {
    const params: HttpParams = new HttpParams()
      .set('page', page)
      .set('type', type)
      .set('user', user)
      .set('sector', sector)
      .set('finished', finished)
      .set('reference', reference);

    return this.httpClient
      .get<GenericTable<GetTasksResponse>>(`${environment.SERVER_TASKS}/api/Tasks/GetTasksFilters`, { params: params }
      ).pipe(
        map(elements => new GenericTable<GetTasksResponse>(elements))
      );
  }

  getSingleTask(id: string): Observable<GetTaskResponse> {
    const httpParams = new HttpParams()
      .set('task_id', id);
    return this.httpClient.get<GetTaskResponse>(`${environment.SERVER_TASKS}/api/Repairs/get-luminaires-repair`, { params: httpParams });
  }

  getTaskById(id: string): Observable<GetTaskResponse> {
    return this.httpClient
      .get<GetTaskResponse>(`${environment.SERVER_URL}/tasks/${id}`);
  }

  getTroopSupervisors(): Observable<GetTroopSupervisorsResponse> {
    return this.httpClient.get<GetTroopSupervisorsResponse>(
      `${environment.SERVER_URL}/troop_supervisors`
    );
  }

  getTroopers(): Observable<Trooper[]> {
    return this.httpClient
      .get<GetTroopersResponse>(`${environment.SERVER_URL}/troopers`)
      .pipe(map(data => data.troopers.map(trooper => new Trooper(trooper))));
  }

  getProjectResources(): Observable<object> {
    return this.httpClient
      .get<object>(`${environment.SERVER_URL}/project_resources/`);
  }

  getSurveyAreas(): Observable<SurveyArea[]> {
    return this.httpClient
      .get<any>(`${environment.SERVER_URL}/survey_areas`)
      .pipe(map(data => data.survey_areas.map(survey => {
        const survery_area = new SurveyArea(null)
        survery_area.populate(survey);
        return survery_area;
      })));
  }

  updateSurveyAreas(surveyAreaPayload: SurveyAreaPayload): Observable<object> {
    return this.httpClient
      .put<object>(`${environment.SERVER_URL}/survey_areas/${surveyAreaPayload.id}`,
        surveyAreaPayload.toJson());
  }
}
