import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { SelectProjectComponent } from './pages/projects/select-project/select-project.component';
import { DownloadAppComponent } from './pages/download-app/download-app.component';

import { Full_ROUTES } from './shared/routes/full-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';

import { AuthGuard } from './shared/auth/auth-guard.service';
import { ProjectGuard } from './shared/projects/project-guard.service';
import { RoleGuard } from './shared/roles/role-guard.service';

const appRoutes: Routes = [
  {
    path: '',
    component: ContentLayoutComponent,
    children: CONTENT_ROUTES
  },
  { 
    path: 'change-password/:token',
    component: ChangePasswordComponent
  },
  {
    path: 'select-project',
    component: SelectProjectComponent,
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: 'download-app',
    component: DownloadAppComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'u',
    component: FullLayoutComponent,
    children: Full_ROUTES,
    canActivate: [AuthGuard, ProjectGuard, RoleGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
