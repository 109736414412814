import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { InstallationTable } from '@app/models/installation-table';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { GenericTable } from '@app/models/generic-table';
import { InstallationsComponent } from './installations.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TaskInstallationDetail } from './intallation-detail';

@Injectable({
  providedIn: 'root'
})
export class InstallationsService {

  constructor(
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService
  ) { }

  getInstallationTasks(page: number, task_reference: string, is_Finished: string) {
    const paramsHeaders = new HttpParams()
      .set('page', page.toString())
      .set('task_reference', task_reference)
      .set('is_Finished', is_Finished);

    return this.httpClient.get<GenericTable<InstallationTable>>(
      `${environment.SERVER_TASKS}/api/Task_Installation/GetTasks`, { params: paramsHeaders }
    ).pipe(
      map(tasks => new GenericTable<InstallationTable>(tasks))
    );
  }


  getTaskReferences(task_id) {
    const paramsHeaders = new HttpParams().set('task_id', task_id);

    return this.httpClient.get<any[]>(
      `${environment.SERVER_TASKS}/api/Task_Installation/GetTaskReferences`,  { params : paramsHeaders}
    )
  }
  


  getInstallMaterials(task_id) {
    const httpParams = new HttpParams().set('task_id', task_id);
    return this.httpClient.get(
      `${environment.SERVER_TASKS}/api/Task_Installation/GetInstalledMaterials`, { params: httpParams}
    )
  }

  downloadInstallaionFormats(form: any) {
    this.httpClient.post(
      `${environment.SERVER_TASKS}/api/Task_Installation/InstallationPrint`, form, { responseType: 'blob' }
    ).subscribe(file => {

      let fileName = '';
      let extension = 'pdf';
      if (form['printable_type'] === 0) {
        fileName = 'Evidencia-Instalacion';
      } else if (form['printable_type'] === 1) {
        fileName = 'Material-Instalado';
      } else if (form['printable_type'] === 2) {
        fileName = 'Material-Desinstalado';
      } else if (form['printable_type'] === 4) {
        fileName = 'Punto Nuevo-Desinstalado';
      } else {
        fileName = 'Mapa de Instalación';
        extension = 'kml';
      }

      this.downloadProcess(file, `${fileName}.${extension}`, extension);

      this.spinner.hide('gen-formats')
    }, error => {
      this.spinner.hide('gen-formats')
    })
  }

  private downloadProcess(file, fileName, extension) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    let newBlob;

    if (extension === 'pdf') {
      newBlob = new Blob([file], { type: 'application/pdf' });
    } else {
      newBlob = new Blob([file], { type: 'application/vnd.google-earth.kml+xml' });
    }

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}
