import { Component, OnInit } from '@angular/core';
import { GenericTable } from '@app/models/generic-table';
import { Routes } from './incident-luminaire-routes-route4me-model';
import { IncidentLuminaireRoutesRoute4meService } from './incident-luminaire-routes-route4me.service';

@Component({
  selector: 'app-incidents-luminaires-routes-route4me',
  templateUrl: './incidents-luminaires-routes-route4me.component.html',
  styleUrls: ['./incidents-luminaires-routes-route4me.component.scss']
})
export class IncidentsLuminairesRoutesRoute4meComponent implements OnInit {
  view_routes_button = false;
  elements_paginated: GenericTable<Routes>;
  constructor(
    private routes_services: IncidentLuminaireRoutesRoute4meService
  ) { }

  ngOnInit() {
    this.getPAginateRoutes(0);

  }

  private getPAginateRoutes(page: number) {
    this.routes_services.get_all_routes(page).subscribe((response: GenericTable<Routes>) => {
      this.elements_paginated = response;
      console.log(response);
    })
  }

  validate_view_routes() {
    if (this.elements_paginated.elements.filter(element => element.selected === true).length > 0) {
      this.view_routes_button = true;
    } else {
      this.view_routes_button = false;
    }
    
  }

  view_routes() {
    console.log('router aqui');
  }


  pageChange(page: number) {
    this.getPAginateRoutes(page - 1);
  }

}
