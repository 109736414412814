import { Component, OnInit, ViewChild } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

/* se omitio ya que solo se ocupa el mismo modelo con los mismos datos */
// import { MaterialCategoriesResponse } from 'app/models/material_categories-response';
// import { MaterialCategory } from 'app/models/material_category';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { InventoryService } from './inventory.service';
import { MaterialCategory, Material, ProjectMaterial } from './MaterialCategory';
import { AuthService } from '@app/shared/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShoppingCartItemPayload } from '@app/models/shopping_cart_item-payload';
import { ProjectMaterialPayload } from '@app/models/project_material-payload';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  providers: [InventoryService]
})

export class InventoryComponent implements OnInit {
  @ViewChild('contentMaterialModal') materialModal: any;
  // material_categories: MaterialCategory[] = [];
  maxItems = 0;
  shoppingForm: FormGroup;
  projectMaterial: FormGroup;
  showAddButtons = true;
  category_id = '';
  // edit_Material: Material[] = [];
  material_category: MaterialCategory[] = [];
  project_materials: ProjectMaterial[] = [];
  showTable = false;
  showMessage = false;
  totalRef = 0;

  constructor(
    private inventoryService: InventoryService,
    private toastr: Toastr,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private authService: AuthService,
  ) {
    this.shoppingForm = this.fb.group(
      {
        id: [null, Validators.required],
        quantity: [null, Validators.required],
        action: ['add', Validators.required],
        stock: [null, Validators.required],
        image_url: [null],
        name: [null],
        minimum: [null]
      }
    );

    this.projectMaterial = this.fb.group(
      {
        id: [null],
        name: [null, Validators.required],
        minimum: [null, Validators.required],
        image_url: [null],
        is_active: [null],
        image: [null],
        stock: [null]
      }
    );

  }

  ngOnInit() {
    this.setButtonVisibility();
    this.getValues();
  }


  getValues() {
    this.inventoryService.getCategoriesValues().subscribe(res => {
      this.material_category = res;
    }, error => {
      this.toastr.error(error);
    });
  }

  selectedCategory(category_id, page) {
    if (category_id === '') {
      this.toastr.singleError('Se debe de seleccionar una opcion de la lista');
      this.showTable = false;
      this.projectMaterial = null;
      return;
    } else {
      this.category_id = category_id;
      this.showTable = true;
      // this.inventoryService.getCategory(category_id)
      //   .subscribe(res => {
      //     console.log(res, 'old service');
      //     // this.project_materials = materialCategoryResponse.material_category.project_materials;
      //     // this.project_materials = this.project_materials.sort((a, b) => a.material.name.localeCompare(b.material.name));
      //     // this.projectName = materialCategoryResponse.material_category.name;
      //   }, error => {
      //     this.toastr.error(error);
      //   })


      this.inventoryService.getMaterialsValues(category_id, page).subscribe(response => {
        this.project_materials = response;
        // console.log(response, 'new Service');
        // this.materials = response;
      });
    }

  }


  setButtonVisibility() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    // console.log(decodeToken['role_name_enums'])
    if (decodeToken['role_name_enums'][0] === 2 || decodeToken['role_name_enums'][0] === 1) {
      this.showAddButtons = false;
    }
  }



  operationToTotalMaterial(sum?: boolean) {
    if (sum) {
      return this.totalRef = this.totalRef + 10;
    } else if ((this.totalRef - 10) >= 0) {
      this.totalRef = this.totalRef - 10
    }
  }

  operationToTotal(element: any, sum?: boolean) {
    if (sum) {
      if ((parseInt(element.value) + 10) > parseInt(this.shoppingForm.get('stock').value))
        return this.showMessage = true;
      return this.shoppingForm.patchValue({ quantity: parseInt(element.value) + 10 });
    }
    else if ((parseInt(element.value) - 10) >= 0)
      this.shoppingForm.patchValue({ quantity: parseInt(element.value) - 10 });
    else
      this.shoppingForm.patchValue({ quantity: 0 });
    this.showMessage = false;
  }

  updateMaterial() {
    if (this.shoppingForm.valid && this.shoppingForm.get('quantity').value > 0) {
      if (this.shoppingForm.get('quantity').value > this.maxItems) {
        this.toastr.singleError('verifique la cantidad disponible...');
        return;
      }
      const token = this.authService.getToken();
      const helper = new JwtHelperService();
      const decodeToken = helper.decodeToken(token);
      const shoppingCardItems = {
        id: this.shoppingForm.get('id').value,
        quantity: this.shoppingForm.get('quantity').value,
        user_id: decodeToken['id']
      };

      this.inventoryService.addShoppingCart_(shoppingCardItems).subscribe(result => {
        this.toastr.success('Se agregado al carrito.');
        this.modalService.dismissAll();
      }, error => {
        this.toastr.error(error);
      });
    } else {

      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  saveTotal(project_material: ProjectMaterial) {
    if (this.totalRef < 0) {
      this.toastr.singleError('No se puede seleccionar un valor negativo');
      return;
    }
    const bk = project_material.stock;

    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    const form = {
      material_id: project_material.material.id,
      removed_from: 0,
      quantity: this.totalRef - project_material.stock,
      user_id: decodeToken['id'],
      cap_id: null
    };

    project_material.stock = this.totalRef;

    this.setFormProject(project_material);
    const updatePtojectMaterialValues = {
      id: this.projectMaterial.get('id').value,
      minimum: this.projectMaterial.get('minimum').value,
      stock: this.projectMaterial.get('stock').value
    };



    this.inventoryService.editMaterialValues(form, updatePtojectMaterialValues).subscribe(result => {
      this.toastr.success('Material actualizado.');
      project_material.material.editing = false;
    }, error => {
      this.toastr.error(error);
      project_material.material.editing = false;
      project_material.stock = bk;
    });




    // this.inventoryService.registerLog(form).subscribe(response => {

    // this.setFormProject(project_material);
    // // const requestPayload = new ProjectMaterialPayload(this.projectMaterial);
    // const updatePtojectMaterialValues = {
    //   id: this.projectMaterial.get('id').value,
    //   // category_id: this.category_id,
    //   minimum: this.projectMaterial.get('minimum').value,
    //   stock: this.projectMaterial.get('stock').value

    // };


    // console.log(updatePtojectMaterialValues, 'valores envidos', this.category_id, 'xategory id');
    //   this.inventoryService.updateMaterial_(updatePtojectMaterialValues)
    //     .subscribe(result => {
    //       this.toastr.success('Material actualizado.');
    //     }, error => {
    //       this.toastr.error(error);
    //       project_material.stock = bk;
    //     });
    // });

    // project_material.material.editing = false;
  }


  editMaterial(project_material: ProjectMaterial) {
    for (const _project_material of this.project_materials) {
      _project_material.material.editing = false;
    }
    this.totalRef = project_material.stock;

    project_material.material.editing = !project_material.material.editing;
  }

  showMaterialModal(project_materials: ProjectMaterial) {
    this.maxItems = project_materials.stock;
    this.setForm(project_materials);
    this.modalService.open(this.materialModal).result.then(result => {
    }, reason => {
      this.showMessage = false;
    });
  }

  setForm(project_material: ProjectMaterial) {
    this.shoppingForm.patchValue({
      id: project_material.material.id,
      name: project_material.material.name,
      minimum: project_material.material.minimum,
      stock: project_material.stock,
      quantity: 0,
      image_url: project_material.material.image_url
    });
  }


  setFormProject(projectMaterial: ProjectMaterial) {
    this.projectMaterial.patchValue({
      id: projectMaterial.id,
      name: projectMaterial.material.name,
      minimum: projectMaterial.troop_minimum,
      is_active: projectMaterial.is_active,
      stock: projectMaterial.stock
    });
  }















  // editMaterial(material: Material) {
  //   for (const items of this.materials) {
  //     items.edit = false;
  //   }
  //   this.totalRef = material.cantidad;
  //   material.edit = !material.edit;
  // }


  // saveTotal(material: Material) {
  //   if (this.totalRef < 0)
  //     return
  //   const bk = material.cantidad;

  //   const token = this.authService.getToken();
  //   const helper = new JwtHelperService();
  //   const decodeToken = helper.decodeToken(token);

  //   // tslint:disable-next-line:max-line-length
  //   const form = {
  //     material_id: material.id,
  //     removed_from: 0,
  //     quantity: this.totalRef - material.cantidad,
  //     user_id: decodeToken['id'],
  //     cap_id: null
  //   };

  //   material.cantidad = this.totalRef;

  //   this.inventoryService.registerLog(form).subscribe(response => {
  //     this.setFormProject(project_material);
  //     const requestPayload = new ProjectMaterialPayload(this.projectMaterial);
  //     this.chargedValuesService.updateMaterial(requestPayload, this.currentId)
  //       .subscribe(result => {
  //         this.toastr.success('Material actualizado.');
  //       }, error => {
  //         this.toastr.error(error);
  //         material.cantidad = bk;
  //       });
  //   });
  //   material.edit = false;
  // }


  // setFormProject(projectMaterial: ProjectMaterial) {
  //   this.projectMaterial.patchValue({
  //     id: projectMaterial.id,
  //     name: projectMaterial.material.name,
  //     minimum: projectMaterial.troop_minimum,
  //     is_active: projectMaterial.is_active,
  //     stock: projectMaterial.stock
  //   });
  // }









































  // operationToTotalMaterial(sum?: boolean) {
  //   if (sum) {
  //     return this.totalRef = this.totalRef + 10;
  //   }
  //   else if ((this.totalRef - 10) >= 0)
  //     this.totalRef = this.totalRef - 10
  // }


  // saveTotal(project_material: ProjectMaterial) {
  //   if (this.totalRef < 0)
  //     return
  //   let bk = project_material.stock;

  //   const token = this.authService.getToken();
  //   const helper = new JwtHelperService();
  //   const decodeToken = helper.decodeToken(token);

  //   // tslint:disable-next-line:max-line-length
  //   const form = {
  //     material_id: project_material.material.id,
  //     removed_from: 0,
  //     quantity: this.totalRef - project_material.stock,
  //     user_id: decodeToken['id'],
  //     cap_id: null
  //   };

  //   project_material.stock = this.totalRef;

  //   this.inventoryService.registerLog(form).subscribe(response => {
  //     this.setFormProject(project_material);
  //     const requestPayload = new ProjectMaterialPayload(this.projectMaterial);
  //     this.chargedValuesService.updateMaterial(requestPayload, this.currentId)
  //       .subscribe(result => {
  //         this.toastr.success('Material actualizado.');
  //       }, error => {
  //         this.toastr.error(error);
  //         project_material.stock = bk;
  //       });
  //   });

  //   project_material.material.editing = false;
  // }





  // ngOnInit() {
  //   this.inventoryService.getChargedValue()
  //   .subscribe((materialCategoriesResponse: MaterialCategoriesResponse) => {
  //     this.material_categories = materialCategoriesResponse.material_categories;
  //     this.material_categories = this.material_categories.sort( (a, b) => a.name.localeCompare(b.name) );
  //   }, error => {
  //     this.toastr.error(error);
  //   })
  // }

}
