import { SurveyArea } from './survey-area';
import { User } from './user';
import { TypeTask } from './type-task';
import { Luminaire } from './luminaire';
import { Circuit } from './circuit';
import { Lamppost } from './lamppost';

interface LightTaskInterface {
  id: string
  reference_id: string
  type: TypeTask
  parent_task_id: string
  sector: string
  comment: string
  number_of_points: number
  created_at: string
  deadline: string
  last_interaction_at: string
  is_finished: boolean
  luminaires_status: number
  luminaires_statusName: string
  user: User
}

export class LightTask implements LightTaskInterface {
  id: string
  reference_id: string
  type: TypeTask
  parent_task_id: string
  sector: string
  comment: string
  number_of_points: number
  created_at: string
  deadline: string
  last_interaction_at: string
  is_finished: boolean
  luminaires_status: number
  luminaires_statusName: string
  user: User

  constructor(attrs: any) {
    this.id = attrs.id;
    this.type = new TypeTask(attrs.type);
    this.parent_task_id = attrs.parent_task_id ? attrs.parent_task_id : '';
    this.reference_id = attrs.reference_id ? attrs.reference_id : '';
    this.sector = attrs.sector;
    this.comment = attrs.comment;
    this.number_of_points = attrs.number_of_points;
    this.created_at = attrs.created_at;
    this.deadline = attrs.deadline;
    this.last_interaction_at = attrs.last_interaction_at;
    this.is_finished = attrs.is_finished;
    this.luminaires_status = attrs.luminaires_status || 0;
    this.luminaires_statusName = StatusmEnum[this.luminaires_status];
    this.user = attrs.user != null ? new User(attrs.user) : null;
  }
}

enum StatusmEnum {
  'Sin asignar',
  'Programada para reparación',
  'Pendiente',
  'En proceso de reparación',
  'Funcionando'
}