import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@app/shared/auth/auth.service';
import { environment } from '@environments/environment';
import { GenericTable } from '@app/models/generic-table';
import { map } from 'rxjs/operators';
import { Schedules } from '@app/models/schedules';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
    ) { }

    getSchedules(form, page) {
      const httpParams = new HttpParams().set('page', page)
      return this.httpClient.post<GenericTable<Schedules>>(
        `${environment.SERVER_SMART}/api/Schedules/GetSchedules`, form, { params: httpParams }
      ).pipe(
        map(elements => new GenericTable<Schedules>(elements))
      );
    }

    addSchedule(form) {
      const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id )
      return this.httpClient.post(
        `${environment.SERVER_SMART}/api/Schedules/AddSchedule`, form, { headers: httpHeaders}
      );
    }

    updateSchedule(form) {
      const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id )
      return this.httpClient.post(
        `${environment.SERVER_SMART}/api/Schedules/UpdateSchedule`, form, { headers: httpHeaders}
      );
    }
    updateOnOff(values): Observable<any> {
      console.log(values)
      return this.httpClient.post<any>(
        `${environment.SERVER_SMART}/api/Schedules/UpdateOnOff`, values
      );
    }
}
