import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewTroopService } from './new-troop.service';
import { Router } from '@angular/router';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { NewTroopPayload } from 'app/models/new-troop-payload';
import { NewTroopResponse } from 'app/models/new-troop-response';

@Component({
  selector: 'app-new-troop',
  templateUrl: './new-troop.component.html',
  styleUrls: ['./new-troop.component.scss'],
  providers: [NewTroopService]
})
export class NewTroopComponent {

  newTroopForm: FormGroup = this.formBuilder.group({
    number: [null, Validators.required],
    ipad_serial_number: [null],
    plates: [null]
  });

  constructor(
    private formBuilder: FormBuilder,
    private newTroopService: NewTroopService,
    private router: Router,
    private toastr: Toastr
  ) { }

  createUser(): void {
    if (this.newTroopForm.valid) {
      const requestPayload = new NewTroopPayload(this.newTroopForm);

      this.newTroopService
        .registerTroop(requestPayload)
        .subscribe(
          (newTroopResponse: NewTroopResponse) => {
            this.toastr.success('Cuadrilla creada.');
            this.router.navigate(['/u/troops']);
          },
          (error) => {
            this.toastr.error(error);
          }
        )
    } else {
      this.newTroopForm.controls.number.markAsTouched();
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  performAction(form: FormGroup): void {
    this.newTroopForm = form;
    this.createUser();
  }


}
