import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

import { ReportedElementsResponse } from 'app/models/reported_elements-response';
import { Luminaire } from 'app/models/luminaire';
import { ProjectResponse } from 'app/models/project-response';
import { map } from 'rxjs/operators';
import { ReportedElementsRejectPayload } from './reported-elements-reject-payload';
import { Router } from '@angular/router';
import { VehiclesLocation } from '@app/models/vehicles-location';
import { AuthService } from '@app/shared/auth/auth.service';
import { FormGroup } from '@angular/forms';
import { Sector } from '@app/models/sector';

@Injectable()
export class IncidentsService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private authService: AuthService
  ) { }



  // tslint:disable-next-line:max-line-length
  getIncidents(q?: string, type?: string, technology?: string, problem?: string, status?: string, start_day?: string, end_day?: string): Observable<ReportedElementsResponse> {
    const params: HttpParams = new HttpParams()
      .set('q', q)
      .set('type', type)
      .set('technology', technology)
      .set('problem', problem)
      .set('status', status)
      .set('date_after', end_day)
      .set('date_before', start_day);
    return this.httpClient
      .get<ReportedElementsResponse>(`${environment.SERVER_CALLCENTER}/api/Incidents/GetIncidents`, { params: params });
  }

  getSectors(): Observable<Sector[]> {
    return this.httpClient
      .get<Sector[]>(`${environment.SERVER_TASKS}/api/Tasks/GetSectors`)
  }

  getVehiclesLocation(): Observable<VehiclesLocation[]> {
    return this.httpClient
      .get<VehiclesLocation[]>(`${environment.SERVER_CALLCENTER}/api/VehiclesLocation/GetVehiclesLocation`);
  }

  getProjectLuminaires(slug: string): Observable<Luminaire[]> {
    
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const params: HttpParams = new HttpParams()
    
    return this.httpClient
      .get<ProjectResponse>(`${environment.SERVER_URL}/projects/${slug}/luminaires`, { params: params })
      .pipe(map(data => data.project.luminaires.map(luminaire => new Luminaire(luminaire))));
  }


  getProjectLuminairesOff(slug: string): Observable<Luminaire[]> {
    
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const params: HttpParams = new HttpParams()
    
    return this.httpClient
      .get<Luminaire[]>(`${environment.SERVER_URL}/api/Incidents/get-luminaires-incidents`, { params: params, headers: httpHeaders })
      .pipe(map(data => data.map(luminaire => new Luminaire(luminaire))));
  }




  updateReported_elements_Reject(reported_elements: ReportedElementsRejectPayload): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/reported_elements/reject`,
      reported_elements.toJson()
    );
  }

  createTaskRepair(luminaires_id: string[]) {
    this.httpClient.post(`${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/PrintTaskRepair`, luminaires_id, { responseType: 'blob' })
      .subscribe(file => {
        this.downloadProcess(file, 'Reporte-Folio.pdf');
        this.router.navigate(['/u/call-center/repair-task']);
      });
  }

  recoverPrintable(fuuid_taskID: string) {
    const params: HttpParams = new HttpParams()
      .set('task_id', fuuid_taskID);

    return this.httpClient.get(
      `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/RecoverPrintable`,
      {
        params: params,
        responseType: 'blob'
      })
      .subscribe(file => {
        this.downloadProcess(file, 'Reporte-Folio.pdf');
      })
  }

  downloadComplementaryTicket() {
    this.httpClient.get(
      `${environment.SERVER_TASKS}/api/Task_Repairs_Phase_Two/PrintRepairSheet`, { responseType: 'blob' }).subscribe(file => {
      this.downloadProcess(file, 'Hoja-Reparación.pdf');
    })
  }

  private downloadProcess(file, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([file], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

}
