import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { RequstedMaterial } from '@app/models/material-requested';
import { ExtrackedMaterial } from '@app/models/material-extrackted';
import { TrackMaterial } from '@app/models/material-track';



@Injectable({
  providedIn: 'root'
})
export class TrackMaterialService {

  constructor(private httpClient: HttpClient) { }

  getRequestedMaterial(StartDate, EndDate) {
    const httpParams = new HttpParams()
    .set('StartDate', StartDate)
    .set('EndDate', EndDate)
    return this.httpClient.get<RequstedMaterial[]>(
      `${environment.SERVER_WAREHOUSE}/api/RequestedMaterial/GetRequestedMaterial` , {params: httpParams}
    ).pipe(
      map(rm => rm.map(req => new RequstedMaterial(req) ))
    )
  }


  downloadRequestedMaterial(StartDate: string, EndDate: string, typeFile: string) {
    const params: HttpParams = new HttpParams()
      .set('StartDate', StartDate)
      .set('EndDate', EndDate)

    this.httpClient.get(
      `${environment.SERVER_WAREHOUSE}/api/RequestedMaterial/GetRequestedMaterial${typeFile.toUpperCase()}`, {
        params: params,
        responseType: 'blob'
      }).subscribe(file => {
      this.downloadProcess(file, `Bitacora-Mantenimiento.${typeFile}`);
    })
  }



  getExtractedMaterial(StartDate, EndDate) {
    const httpParams = new HttpParams()
    .set('StartDate', StartDate)
    .set('EndDate', EndDate)
    return this.httpClient.get<ExtrackedMaterial[]>(
      `${environment.SERVER_WAREHOUSE}/api/ExtractedMaterial/GetExtractedMaterial` , {params: httpParams}
    ).pipe(
      map(rm => rm.map(req => new ExtrackedMaterial(req) ))
    )
  }


  downloadExtractedMaterial(StartDate: string, EndDate: string, typeFile: string) {
    const params: HttpParams = new HttpParams()
      .set('StartDate', StartDate)
      .set('EndDate', EndDate)

    this.httpClient.get(
      `${environment.SERVER_WAREHOUSE}/api/ExtractedMaterial/GetExtractedMaterial${typeFile.toUpperCase()}`, {
        params: params,
        responseType: 'blob'
      }).subscribe(file => {
      this.downloadProcess(file, `Bitacora-Mantenimiento.${typeFile}`);
    })
  }

  getTrackMaterial(StartDate, EndDate) {
    const httpParams = new HttpParams()
    .set('StartDate', StartDate)
    .set('EndDate', EndDate)
    return this.httpClient.get<TrackMaterial[]>(
      `${environment.SERVER_WAREHOUSE}/api/TrackMaterial/GetTrackMaterial` , {params: httpParams}
    ).pipe(
      map(rm => rm.map(req => new TrackMaterial(req) ))
    )
  }

  downloadTrackMaterial(StartDate: string, EndDate: string, typeFile: string) {
    const params: HttpParams = new HttpParams()
      .set('StartDate', StartDate)
      .set('EndDate', EndDate)

    this.httpClient.get(
      `${environment.SERVER_WAREHOUSE}/api/TrackMaterial/GetTrackMaterial${typeFile.toUpperCase()}`, {
        params: params,
        responseType: 'blob'
      }).subscribe(file => {
      this.downloadProcess(file, `Bitacora-Mantenimiento.${typeFile}`);
    })
  }




  private downloadProcess(file, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([file], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}
