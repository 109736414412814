import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '@app/shared/auth/auth.service';
import { GenericTable } from '@app/models/generic-table';
import { map } from 'rxjs/operators';
import { Rpus, IRpus } from '@app/models/rpus';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RpusService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  getRpus(form, page) {
    console.log('this is service');
    const httpParams = new HttpParams().set('page', page);
    return this.httpClient.post<GenericTable<IRpus>>(
      `${environment.SERVER_ENERGY}/api/Rpus/GetRpus`, form, { params: httpParams}
    ).pipe(
      map(elements => new GenericTable<Rpus>(elements))
    );
  }

}
