import { MountingTypeEnum } from './mounting_type-enum';

interface MeterInterface {
    id: string,
    is_present: boolean,
    identifier: string,
    is_functional: boolean,
    has_base: boolean,
    base_is_functional: boolean,
    mounting_type: number,
    mounting_type_name: string,
    image_url: string,
    base_image_url: string,
    location: {
        latitude: number,
        longitude: number
    }
}

export class Meter implements MeterInterface {
    id: string;
    is_present: boolean;
    identifier: string;
    is_functional: boolean;
    has_base: boolean;
    base_is_functional: boolean;
    mounting_type: number;
    mounting_type_name: string;
    image_url: string;
    base_image_url: string;
    location: {
        latitude: number,
        longitude: number
    }

  constructor(attrs: any) {
    this.id = attrs.id;
    this.is_present = attrs.is_present ? true : false;
    this.identifier = attrs.identifier;
    this.is_functional = attrs.is_functional ? true : false;
    this.has_base = attrs.has_base ? true: false;
    this.base_is_functional = attrs.base_is_functional ? true : false;
    this.mounting_type = attrs.mounting_type;
    this.mounting_type_name = MountingTypeEnum[attrs.mounting_type];
    this.image_url = attrs.image_url;
    this.base_image_url = attrs.base_image_url;
    this.location = attrs.location;
  }
}
