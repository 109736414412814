export class SingleSurveyArea implements ISingleSurveyArea {
  id: string;
  points: LocationSurveyArea[];
  selected: boolean;
  polygon: google.maps.Polygon;

  constructor(attrs) {
    this.id = attrs.id;
    this.points = attrs.points;
    this.selected = false;
  }
}

export interface ISingleSurveyArea {
  id: string,
  points: LocationSurveyArea[]
}

export class LocationSurveyArea implements ILocationSurveyArea {
  latitude: number;
  longitude: number;
  constructor(attrs) {
    this.longitude = attrs.longitude;
    this.latitude = attrs.latitude;

  }
}

export interface ILocationSurveyArea {
  latitude: number,
  longitude: number
}

export class CreatePolygon implements ICreatePolygon {
  latitude: number;
  longitude: number;

  constructor(lat: number, lng: number) {
    this.latitude = lat;
    this.longitude = lng;
  }

}

export interface ICreatePolygon {
  latitude: number;
  longitude: number;
}

// export interface IDetailSurveyArea {
//   survey_name: string;
//   luminairesCount: number,
//   luminairesNMedCount: number,
//   luminairesMedCount: number,
//   detailsWattages: DetailWattagesSurveyArea[],
//   listTechnology: ValuesTechnologySurveyArea[],
// }

export class DetailSurveyArea implements IDetailSurveyArea {
    surveyAreaName: string;
    luminairesCount: number;
    luminairesNMedCount: number;
    luminairesMedCount: number;
    detailsWattages: DetailWattagesSurveyArea[];
    listTechnology: ValuesTechnologySurveyArea[];

    constructor(attrs) {
        this.surveyAreaName = attrs.surveyAreaName;
        this.luminairesCount = attrs.luminairesCount;
        this.luminairesNMedCount = attrs.luminairesNMedCount;
        this.luminairesMedCount = attrs.luminairesMedCount;
        this.detailsWattages = attrs.detailsWattages;
        this.listTechnology = attrs.listTechnology;
    }

}

export interface IDetailSurveyArea {
    surveyAreaName: string,
    luminairesCount: number,
    luminairesNMedCount: number,
    luminairesMedCount: number,
    detailsWattages: DetailWattagesSurveyArea[],
    listTechnology: ValuesTechnologySurveyArea[],
}

export class ValuesTechnologySurveyArea implements IValuesTechnologySurveyArea {
  technology: string;
  count: number;
  // power: number;

  constructor(attrs) {
    this.technology = attrs.technology;
    this.count = attrs.count;
    // this.power = attrs.power;
  }
}

export interface IValuesTechnologySurveyArea {
  technology: string,
  count: number,
  // power: number,
}

export class DetailWattagesSurveyArea implements IDetailWattagesSurveyArea {
  power: number;
  luminairesCount: number;
  constructor(attrs) {
    this.power = attrs.power
    this.luminairesCount = attrs.luminairesCount
  }
}

export interface IDetailWattagesSurveyArea {
  power: number,
  luminairesCount: number,
}

