import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericTable } from '@app/models/generic-table';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { GuarantyOpinion } from './guaranty-opinion-model';
import { GuarantyOpinionService } from './guaranty-opinion.service';

@Component({
  selector: 'app-guaranty-opinion',
  templateUrl: './guaranty-opinion.component.html',
  styleUrls: ['./guaranty-opinion.component.css']
})

export class GuarantyOpinionComponent implements OnInit {
  pagination: GenericTable<GuarantyOpinion>;
  filters: FormGroup;
  startDate;
  endDate;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: Toastr,
    private guarantyOpinionService: GuarantyOpinionService
  ) {
    this.filters = fb.group({
      reference_opinion: [''],
      report_type: -1,
      reference_luminaire: [''],
      reference_report: [''],
      start_date_: [null],
      start_date: [null],
      end_date: [null],
      end_date_: [null],
      status: -1
    });
   }

  ngOnInit() {
    this.getGuarantyOpinions(0);
  }

  getGuarantyOpinions(page: number) {
    // tslint:disable-next-line:max-line-length
    this.filters.value.start_date =  this.filters.get('start_date_').value === null || this.filters.get('start_date_').value === undefined ? '' : new Date((this.filters.get('start_date_').value.month + '/' + this.filters.get('start_date_').value.day + '/' + this.filters.get('start_date_').value.year));
    this.filters.value.end_date =  this.filters.get('end_date_').value === null || this.filters.get('end_date_').value === undefined ? '' : new Date((this.filters.get('end_date_').value.month + '/' + this.filters.get('end_date_').value.day + '/' + this.filters.get('end_date_').value.year));
    this.guarantyOpinionService.getListGuarantyOpinions(this.filters.value, page).subscribe(
      (response: GenericTable<GuarantyOpinion>) => {
        console.log(response.elements);
      this.pagination = response;
    }, error => {
      this.toastr.error(error);
    });
  }
  printSelected() {
    if (this.pagination !== undefined) {
      // tslint:disable-next-line:max-line-length
      const reports_selected = this.pagination.elements.filter(luminaire => luminaire.is_selected === true);
      if (reports_selected.length > 0) {
        const values = [];
        reports_selected.forEach(report => {
          values.push(
             report.guaranty_report_id
          );
        });
       this.guarantyOpinionService.downloadPDFGuarantyOpinion(values);
      } else {
        this.toastr.singleError('Se debe seleccionar al menos un registro habilitado para reporte');
        return;
      }
    } else {
      this.toastr.singleError('No existen datos para generar un reporte');
      return;
    }
  }


  pageChange(page: number) {
    this.getGuarantyOpinions(page - 1);
  }

  registerGuarantyOpinionRop(guarantyRport_id, route) {
    this.router.navigate([`/u/opinion/${route}/${guarantyRport_id}`], { relativeTo: this.route });
  }

}
