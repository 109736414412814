import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Lamppost } from '@models/lamppost';
import { Toastr } from '@shared/toastr/toastr.service';
import { EditLamppostPayload } from './edit-lamppost-payload';
import { EditLuminaireService } from '../edit-luminaire.service';
import { LamppostTypeEnum } from '@models/lamppost-type-enum';
import { LamppostHeightEnum } from '@models/lamppost-height-enum';
import { LamppostDiametertEnum } from '@models/lamppost-diameter-enum';
import { LamppostGeometrytEnum } from '@models/lamppost-geometry-enum';
import { AuthService } from '@shared/auth/auth.service';

@Component({
  selector: 'app-edit-lamppost',
  templateUrl: './edit-lamppost.component.html',
  providers: [EditLuminaireService]
})
export class EditLamppostComponent implements OnInit {
  LamppostTypeEnum = Object.keys(LamppostTypeEnum).filter(key => !isNaN(Number(LamppostTypeEnum[key])));
  LamppostHeightEnum = Object.keys(LamppostHeightEnum).filter(key => key.length > 2);
  LamppostDiametertEnum = Object.keys(LamppostDiametertEnum).filter(key => !isNaN(Number(LamppostDiametertEnum[key])));
  LamppostGeometrytEnum = Object.keys(LamppostGeometrytEnum).filter(key => !isNaN(Number(LamppostGeometrytEnum[key])));
  image: any;
  lamppostForm: any;
  isClient : Boolean;

  @Input() lamppost: Lamppost;

  constructor(
    private authService: AuthService,
    private toastr: Toastr,
    private formBuilder: FormBuilder,
    private editLuminaireService: EditLuminaireService
  ) { }

  ngOnInit() {
    this.isClient = this.authService.isClient();
    this.lamppostForm = this.formBuilder.group({
      id: [null],
      type: [null],
      height: [null],
      diameter: [null],
      geometry: [null],
      is_first_in_circuit: [null],
      is_last_in_circuit: [null],
      condition: [null, Validators.max(5)],
      image_url: [null],
      image: [null]
    });

    this.populateForm(this.lamppost);
  }

  performFormAction(): void{
    const formData: FormData = new FormData();

    formData.append('id', this.lamppostForm.value.id);
    if (this.lamppostForm.value.type != null) {
    formData.append('type', this.lamppostForm.value.type);
    }
    if (this.lamppostForm.value.height != null) {
      formData.append('height', this.lamppostForm.value.height);
      }
      if (this.lamppostForm.value.diameter != null) {
        formData.append('diameter', this.lamppostForm.value.diameter);
        }
        if (this.lamppostForm.value.geometry != null) {
          formData.append('geometry', this.lamppostForm.value.geometry);
          }
          if (this.lamppostForm.value.condition != null) {
            formData.append('condition', this.lamppostForm.value.condition);
            }
            formData.append('is_first_in_circuit', this.lamppostForm.value.is_first_in_circuit ? 'true' : 'false');
            formData.append('is_last_in_circuit', this.lamppostForm.value.is_last_in_circuit ? 'true' : 'false');
            formData.append('image', this.image);

    if (this.lamppostForm.valid) {
     // const requestPayload = new EditLamppostPayload(this.lamppostForm);
      this.editLuminaireService
        .editLamppost(formData)
        .subscribe(
          () => {
            this.toastr.success('Poste actualizado.');
          },
          (error) => {
            this.toastr.error(error);
          }
        )
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  private populateForm(lamppost){
   // let lamppost = new Lamppost(this.lamppost);
    this.lamppostForm.patchValue({
      id: lamppost.id,
      type: lamppost.type,
      height: lamppost.height,
      diameter: lamppost.diameter,
      geometry: lamppost.geometry,
      is_first_in_circuit: lamppost.is_first_in_circuit,
      is_last_in_circuit: lamppost.is_last_in_circuit,
      condition: lamppost.condition,
      image_url: lamppost.image_url
    });
    if(!this.lamppost.has_circuit){
      this.lamppostForm.controls['is_first_in_circuit'].disable();
      this.lamppostForm.controls['is_last_in_circuit'].disable();
    }
    if(this.isClient){
      this.lamppostForm.get('type').disable();
      this.lamppostForm.get('height').disable();
      this.lamppostForm.get('diameter').disable();
      this.lamppostForm.get('geometry').disable();
      this.lamppostForm.get('is_first_in_circuit').disable();
      this.lamppostForm.get('is_last_in_circuit').disable();
      this.lamppostForm.get('condition').disable()
    }
  }

  setImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {

      this.image = event.target.files[0];
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.lamppostForm.patchValue({ image_url: reader.result })
        this.lamppostForm.patchValue({ image: file })
      };

      reader.readAsDataURL(file);
    }
  }
}
