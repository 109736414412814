import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { PowerWattagesResources } from '@app/models/project-wattages-resources';
import { map } from 'rxjs/operators';
import { ProjectWattage } from '@app/models/project_wattage';

@Injectable({
  providedIn: 'root'
})
export class AssignPowerService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getProjectResources(page: number): Observable<object> {
    const params: HttpParams = new HttpParams()
      .set('page', page.toString());
    return this.httpClient
      .get<object>(`${environment.SERVER_URL}/project_resources_with_wattage`, { params: params });
  }

  getProjectResourcesMap(latitude, longitude) {
    const params = new HttpParams()
    .set('latitude', latitude.toString())
    .set('longitude', longitude.toString());
    return this.httpClient.get<PowerWattagesResources>(`${environment.SERVER_NETCORE}/api/PowerWattages/GetMapWattages`, { params: params })
    .pipe(map(response => new PowerWattagesResources(response)));
  }

  createAssignLuminaires(luminaires: any [], id: string): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_URL}/project_wattages/${id}/luminaires`,
      {
        project_wattage: {
          luminaires: luminaires
        }
      }
    );
  }

  getListPowerWattagewMarkerColors() {
    return this.httpClient.get<ProjectWattage[]>(
      `${environment.SERVER_CONFIG}/api/MarkerColor/get-marker-colors`);
  }


  assignLuminaires(luminaires: any [], id: string) {
    const form = { luminaire_id: luminaires, wattage_id: id}
    return this.httpClient.post(`${environment.SERVER_NETCORE}/api/PowerWattages/AssingWattage`, form);
  }
}
