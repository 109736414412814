import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confimation-status',
  templateUrl: './confimation-status.component.html',
  styleUrls: ['./confimation-status.component.scss']
})
export class ConfimationStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
