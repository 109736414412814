import { Component,  OnInit, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { EditUserService } from '@app/pages/users/edit-user/edit-user.service';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { Groups } from '@app/models/groups';
import { GenericTable } from '@app/models/generic-table';
import { GroupsService } from './groups.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  providers: [EditUserService]
})
export class GroupsComponent implements OnInit {
  @ViewChild('contentMaterialModal') materialModal: any;
  @ViewChild('copyModal') copyModal: any;

  groups: GenericTable<Groups>;
  SchedulesList: [];
  UsersList: [];
  groupSearchForm: FormGroup;
  groupsForm: FormGroup;
  edit = false;
  formData: FormData = new FormData();
  keyword = 'scheduleName';
  scheduleObjOn;
  scheduleObjOff;
 // scheduleOffName = '';
  constructor( 
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toaster: Toastr,
    private groupService: GroupsService
    ) {
      this.groupSearchForm = fb.group({
        name: '',
        scheduleName: '',
        userId: '00000000-0000-0000-0000-000000000000',
      });
    }

  ngOnInit() {
    this.groupsForm = this.fb.group({
      id: [null],
      name: [null, Validators.required],
      description: [null],
      scheduleOnName : [null, Validators.required],
      scheduleOffName : [null, Validators.required]
    })
   this.paginatedTable(0);

   this.groupService.getSchedulesList().subscribe(res  => {
    this.SchedulesList = res;
   })

   this.groupService.getUsersList().subscribe(res  => {
    this.UsersList = res;
   })
   
  }
  paginatedTable(page: number) {
    this.groupService.getGroups(this.groupSearchForm.getRawValue(), page).subscribe(response => {
      this.groups = response
    })
   }
   selectScheduleOn(event) {
     this.scheduleObjOn = event;
   }
   selectScheduleOff(event) {
    this.scheduleObjOff = event;
  }

   saveGroup() {

    const data = {
      name : this.groupsForm.value.name,
      description : this.groupsForm.value.description,
      schedule_on_id : this.scheduleObjOn.id,
      schedule_off_id : this.scheduleObjOff.id
    }

  this.groupService.addGroup(data).subscribe(response => {
    this.paginatedTable(0);
    this.toaster.success('Se agrego correctamente');
    this.paginatedTable(0);
  }, error => {
    this.paginatedTable(0);
    this.toaster.error('Error al agregar la group');
  })
  this.modalService.dismissAll();
}

onEdit(group: Groups) {
  this.edit = true;
  this.groupsForm.patchValue({
    id: group.id,
    name: group.name,
    description: group.description,
    scheduleOnName : group.scheduleOnName,
    scheduleOffName : group.scheduleOffName
  });
 // this.scheduleOffName = this.groupsForm.value.scheduleOffName;
 // console.log(this.scheduleOffName)
  
  this.modalService.open(this.materialModal, { size: 'lg', backdrop: 'static', keyboard: false });
}


editGroup() {
  const data = {
    id : this.groupsForm.value.id,
    name : this.groupsForm.value.name,
    description : this.groupsForm.value.description,
    schedule_on_id : this.scheduleObjOn.id,
    schedule_off_id : this.scheduleObjOff.id
  }
  this.groupService.updateGroup(data).subscribe(response => {
    this.toaster.success('Se modifico correctamente');
    this.paginatedTable(0);
  }, error => {
    this.paginatedTable(0);
    this.toaster.error('Error al modificar');
  });
  this.modalService.dismissAll();
}

pageChange(page: number) {
  this.paginatedTable(page - 1);
}

showModal() {
  this.edit = false;
  this.groupsForm.reset();
  this.modalService.open(this.materialModal, { size: 'lg', backdrop: 'static', keyboard: false });
}

onClose() {
  this.modalService.dismissAll();
}

}
