import { Component, OnInit } from '@angular/core';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label, MultiDataSet, PluginServiceGlobalRegistrationAndOptions } from 'ng2-charts';
import { UserEfficiencyService } from './user-efficiency.service';

@Component({
  selector: 'app-user-efficiency',
  templateUrl: './user-efficiency.component.html',
  styleUrls: ['./user-efficiency.component.scss']
})
export class UserEfficiencyComponent implements OnInit {

  /* Efficienty */

  morning_Efficienty = 0;
  afternoon_Efficienty = 0;
  best_Oficial = 'Sin oficial';
  total_Tasks = '0';

  /* Doughnut Chart Begin */

  doughnutChartLabels: Label[];
  doughnutOptions: ChartOptions = {
    cutoutPercentage: 75
  }
  doughnutChartData: number[] = [];
  doughnutChartType: ChartType = 'doughnut';
  legend = false;
  doughnutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [];
  donutColors = [
    {
      backgroundColor: [
        'rgba(255,131,162,0.79)',
        'rgba(98,188,245,0.79)',
      ],
    }
  ]

  /* Doughnut Chart Finish */

  /* Bar Chart Begin */

  barChartLabels: Label[];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartData: ChartDataSets[] = [];

  /* Bar Chart Finish */

  barStackChartLabels: Label[] = ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'];
  barStackChartType: ChartType = 'bar';
  barStackChartLegend = false;

  barStackChartData: ChartDataSets[] = [
    {
      data: [20, 30, 10, 45],
      label: 'Oficial 1 (Terminadas)',
      stack: 'a',
      backgroundColor: 'rgba(98,188,245,0.79)',
      hoverBackgroundColor: 'rgba(49,165,242,0.8)'
    },
    {
      data: [10, 50, 20, 40],
      label: 'Oficial 1 (Sin Terminar)',
      stack: 'a',
      backgroundColor: 'rgba(255,131,162,0.79)',
      hoverBackgroundColor: 'rgba(255,93,137,0.8)'
    },
    {
      data: [30, 19, 29, 32],
      label: 'Oficial 2 (Terminadas)',
      stack: 'b',
      backgroundColor: 'rgba(98,188,245,0.79)',
      hoverBackgroundColor: 'rgba(49,165,242,0.8)'
    },
    {
      data: [32, 43, 13, 10],
      label: 'Oficial 2 (Sin Terminar)',
      stack: 'b',
      backgroundColor: 'rgba(255,131,162,0.79)',
      hoverBackgroundColor: 'rgba(255,93,137,0.8)'
    },
    {
      data: [20, 38, 14, 39],
      label: 'Oficial 3 (Terminadas)',
      stack: 'c',
      backgroundColor: 'rgba(98,188,245,0.79)',
      hoverBackgroundColor: 'rgba(49,165,242,0.8)'
    },
    {
      data: [30, 19, 0, 10],
      label: 'Oficial 3 (Sin Terminar)',
      stack: 'c',
      backgroundColor: 'rgba(255,131,162,0.79)',
      hoverBackgroundColor: 'rgba(255,93,137,0.8)'
    },
    {
      data: [40, 20, 34, 23],
      label: 'Oficial 4 (Terminadas)',
      stack: 'd',
      backgroundColor: 'rgba(98,188,245,0.79)',
      hoverBackgroundColor: 'rgba(49,165,242,0.8)'
    },
    {
      data: [28, 29, 15, 30],
      label: 'Oficial 4 (Sin Terminar)',
      stack: 'd',
      backgroundColor: 'rgba(255,131,162,0.79)',
      hoverBackgroundColor: 'rgba(255,93,137,0.8)'
    },
    {
      data: [29, 40, 16, 52],
      label: 'Oficial 5 (Terminadas)',
      stack: 'e',
      backgroundColor: 'rgba(98,188,245,0.79)',
      hoverBackgroundColor: 'rgba(49,165,242,0.8)'
    },
    {
      data: [32, 19, 23, 45],
      label: 'Oficial 5 (Sin Terminar)',
      stack: 'e',
      backgroundColor: 'rgba(255,131,162,0.79)',
      hoverBackgroundColor: 'rgba(255,93,137,0.8)'
    },
    {
      data: [24, 54, 27, 30],
      label: 'Oficial 6 (Terminadas)',
      stack: 'f',
      backgroundColor: 'rgba(98,188,245,0.79)',
      hoverBackgroundColor: 'rgba(49,165,242,0.8)'
    },
    {
      data: [42, 37, 40, 19],
      label: 'Oficial 6 (Sin Terminar)',
      stack: 'f',
      backgroundColor: 'rgba(255,131,162,0.79)',
      hoverBackgroundColor: 'rgba(255,93,137,0.8)'
    },
    {
      data: [24, 40, 16, 30],
      label: 'Oficial 7 (Terminadas)',
      stack: 'g',
      backgroundColor: 'rgba(98,188,245,0.79)',
      hoverBackgroundColor: 'rgba(49,165,242,0.8)'
    },
    {
      data: [59, 26, 30, 20],
      label: 'Oficial 7 (Sin Terminar)',
      stack: 'g',
      backgroundColor: 'rgba(255,131,162,0.79)',
      hoverBackgroundColor: 'rgba(255,93,137,0.8)'
    },
  ];

  constructor(
    private dashboardEfficiency: UserEfficiencyService
  ) { }

  ngOnInit() {
    this.dashboardEfficiency.getMainDashboard().subscribe(response => {
      this.best_Oficial = response.best_user;
      this.morning_Efficienty = response.morning_efficienty,
        this.afternoon_Efficienty = response.afternoon_efficienty;
      this.doughnutChartLabels = response.doughnutChart_DTO.labels;
      this.barChartLabels = response.barChart_DTO.oficials_names;
      this.barChartData = response.barChart_DTO.barData_DTO;
      this.doughnutChartData = response.doughnutChart_DTO.data;

      this.doughnutChartPlugins.push({
        beforeDraw(chart) {

          const ctx = chart.ctx;
          const txt = 'Center Text';
          const sidePadding = 60;
          const sidePaddingCalculated = (sidePadding / 100) * (chart['innerRadius'] * 2)
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          const stringWidth = ctx.measureText(txt).width;
          const elementWidth = (chart['innerRadius'] * 2) - sidePaddingCalculated;
          const widthRatio = elementWidth / stringWidth;
          const newFontSize = Math.floor(30 * widthRatio);
          const elementHeight = (chart['innerRadius'] * 2);
          const fontSizeToUse = Math.min(newFontSize, elementHeight);
          ctx.font = fontSizeToUse + 'px Arial';
          ctx.fillStyle = 'black';

          ctx.fillText(response.total_tasks.toString(), centerX, centerY);
        }
      })
    })
  }

}
