import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from './admin.service';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { CurrentProjectService } from 'app/shared/cookies/current-project.service';
import { Project } from 'app/models/project';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  providers: [AdminService, CurrentProjectService]
})
export class AdminComponent implements OnInit {
  @ViewChild('modalAviso') modalAviso: any;
  @ViewChild('modalOfLum') modalOfLum: any;
  installation_goal: number = null;
  tickets_enabled: boolean = false;
  last_luminaire_reference_id: string;
  project: Project;
  url: string;
  showUrl: boolean = false;

  constructor(
    private toastr: Toastr,
    private modalService: NgbModal,
    private adminService: AdminService,
    private currentProjectService: CurrentProjectService
  ) { }

  ngOnInit() {
    this.project = this.currentProjectService.getCurrentProject();
    this.adminService.getProject(this.project.id)
      .subscribe( result => {
        this.url = `${location.origin}/reportar-luminaria/${result.project.slug}`;
        this.showUrl = result.project.tickets_enabled;
        this.tickets_enabled = result.project.tickets_enabled;
        this.installation_goal = result.project.installation_goal;
        this.last_luminaire_reference_id = result.project.last_luminaire_reference_id;
      }, error => {
        this.toastr.error(error);
      });
  }

  confirmEvent(){
    this.modalService.open(this.modalAviso, { centered: true }).result.then(result => {
      this.adminService.generateNumerate()
      .subscribe(
        (result: any) => {
          this.toastr.success('Se enumeraron las luminarias.')
        },
        (error) => {
          this.toastr.error(error);
        }
      )
    }, reason => {});
  }

  showModalOfLum(){
    this.modalService.open(this.modalOfLum, { centered: true }).result.then(result => {
      if(!this.installation_goal || isNaN(this.installation_goal) || this.installation_goal < 0)
        return;
      this.adminService.putProject(this.project.id, this.showUrl, this.installation_goal)
      .subscribe(
        (result: any) => {
          this.toastr.success('Se asignó correctamente.')
        },
        (error) => {
          this.toastr.error(error);
        }
      )
    }, reason => {});
  }

  onChange(event:boolean){
    this.adminService
    .putProject(this.project.id, event)
    .subscribe(response => {
      this.toastr.success('Se actualizo correctamente.');
      this.showUrl = !this.showUrl;
      this.project.tickets_enabled = this.showUrl;
      this.currentProjectService.updateCurrentProject(this.project);
    }, error => {
      this.toastr.error(error);
    });
  }

}
