import { Component, OnInit } from '@angular/core';
import { RequisitionService } from './requisition.service';
import { Requisition } from 'app/models/requisition';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { ProfileService } from 'app/pages/profile/profile.service';
import { User } from 'app/models/user';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-requisitions',
  templateUrl: './requisitions.component.html',
  providers: [ProfileService]
})
export class RequisitionsComponent implements OnInit {
  requisitions: Requisition[] = [];
  requisitionsPending: Requisition[] = [];
  requisitionsHistory: Requisition[] = [];
  showAddButtons = true;

  constructor(
    private requisitionService: RequisitionService,
    private profileService: ProfileService,
    private toastr: Toastr,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.setButtonVisibility();
  }

  setButtonVisibility() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    console.log(decodeToken['role_name_enums'])
    if (decodeToken['role_name_enums'][0] === 2 || decodeToken['role_name_enums'][0] === 1) {
      this.showAddButtons = false;
    }
  }

  deleteSend(id: string) {
    this.requisitionsPending = this.requisitionsPending.filter(req => req.id !== id);
  }

  deleteReceived(id: string) {
    this.requisitions = this.requisitions.filter(req => req.id !== id);
  }

}
