import { Base } from "./base";
import { Luminaire } from "./luminaire";


interface LamppostInterface {
  id: string,
  condition: number,
  diameter: number,
  geometry: number,
  is_first_in_circuit: boolean,
  is_last_in_circuit: boolean,
  height: number,
  image_url: string,
  type: number,
  base: Base,
  has_circuit: boolean,
  luminaires: Luminaire[]
}

export class Lamppost implements LamppostInterface {
    id: string;
    condition: number;
    diameter: number;
    geometry: number;
    height: number;
    image_url: string;
    type: number;
    is_first_in_circuit: boolean;
    is_last_in_circuit: boolean;
    base: Base;
    has_circuit: boolean;
    luminaires: Luminaire[];
    event: string;

  constructor(attrs) {
    this.id = attrs.id;
    this.condition = attrs.condition;
    this.diameter = attrs.diameter;
    this.geometry = attrs.geometry;
    this.is_first_in_circuit = attrs.is_first_in_circuit;
    this.is_last_in_circuit = attrs.is_last_in_circuit;
    this.height = attrs.height;
    this.image_url = attrs.image_url;
    this.type = attrs.type;
    this.base = attrs.base == null ? null : new Base(attrs.base);
    this.has_circuit = attrs.has_circuit;
    this.luminaires = attrs.luminaires != null ? attrs.luminaires.map((luminaire: Luminaire) => new Luminaire(luminaire, 'linked')) : [];
  }

  upadeIcon(event: string): void {
    this.event = event;
    for(let luminaire of this.luminaires){
        let event = this.event == 'normal' ? luminaire.isSelected ? 'selected' : 'normal' : this.event;
        luminaire.upadeIcon(event);
    }
  }

  hideLamppost(): void {
    for(let luminaire of this.luminaires){
      luminaire.hideMarker();
    }
  }

  setMap(map: any): void {
    for(let luminaire of this.luminaires){
      luminaire.setMap(map);
    }
  }
}