import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { TableauAuthService } from './tableau-auth.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, retryWhen, take, tap, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-tableau-dashboard',
  template: `
    <div *ngIf="isLoading$ | async" class="loading">Loading dashboard...</div>
    <div *ngIf="error$ | async as error" class="error">{{ error }}</div>
    <tableau-viz
      #tableauViz
      *ngIf="!(isLoading$ | async) && !(error$ | async)"
      [src]="dashboardUrl"
      [device]="deviceView"
      hide-tabs
      toolbar="bottom"
      width="100%"
      height="800px">
    </tableau-viz>
  `,
  styles: [`
    :host {
      display: block;
      width: 100%;
      height: 100%;
    }
    .loading, .error {
      padding: 20px;
      text-align: center;
    }
    .error {
      color: red;
    }
  `]
})
export class TableauDashboardComponent implements OnInit, AfterViewInit {
  @Input() dashboardUrl: string = 'https://us-east-1.online.tableau.com/t/optimaenergia/views/DashboardMunicipioiPad/Dashboard7/9587e9e5-ab66-4df5-976e-64c10c1c1d44/fccf3d22-87ef-45f4-9b81-1a91fd8c3952';
  @Input() deviceView: 'desktop' | 'tablet' | 'phone' = 'desktop';

  @ViewChild('tableauViz') set tableauViz(element: ElementRef<any>) {
    if (element && this._token) {
      console.log('Setting token on tableau-viz element');
      element.nativeElement.token = this._token;
    }
  }

  private _token: string | null = null;

  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  private errorSubject = new BehaviorSubject<string | null>(null);
  error$: Observable<string | null> = this.errorSubject.asObservable();

  constructor(private tableauAuthService: TableauAuthService) {}

  ngOnInit(): void {
    console.log('TableauDashboardComponent initialized');
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit called');
    this.initializeTableau();
  }

  private initializeTableau(): void {
    console.log('Initializing Tableau');
    this.isLoadingSubject.next(true);
    this.errorSubject.next(null);

    this.tableauAuthService.getJwt().pipe(
      retryWhen(errors => errors.pipe(
        delay(1000),
        take(3),
        tap(retryCount => console.log(`Retrying authentication... Attempt ${retryCount + 1}`))
      )),
      catchError(error => {
        console.error('Error fetching Tableau JWT:', error);
        this.errorSubject.next('Failed to authenticate. Please try refreshing the page.');
        return of(null);
      }),
      finalize(() => {
        console.log('Authentication process completed');
        this.isLoadingSubject.next(false);
      })
    ).subscribe(
      response => {
        console.log('Received response:', response);
        if (response && response.token) {
          this._token = response.token;
          this.attemptTokenSet();
        } else {
          console.warn('No token received in the response');
          this.errorSubject.next('Failed to receive authentication token. Please try again later.');
        }
      },
      error => {
        console.error('Unexpected error in subscription:', error);
        this.errorSubject.next('An unexpected error occurred. Please try again later.');
      }
    );
  }

  private attemptTokenSet(): void {
    if (this._token) {
      const tableauElement = document.querySelector('tableau-viz');
      if (tableauElement) {
        console.log('Setting token on found tableau-viz element');
        (tableauElement as any).token = this._token;
      } else {
        console.warn('tableau-viz element not found in DOM');
      }
    }
  }
}