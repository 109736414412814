import { Component, OnInit, ViewChild } from '@angular/core';
import { AdministerPowerService } from './administer-power.service';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { ProjectWattage, MarkerColor } from 'app/models/project_wattage';
import { ProjectMaterial } from 'app/models/project_material';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectWattagePayload } from 'app/models/project-wattage-payload';
import { GenericTable } from '@app/models/generic-table';
import { Material } from '@app/models/material';

@Component({
  selector: 'app-administer-power',
  templateUrl: './administer-power.component.html'
})
export class AdministerPowerComponent implements OnInit {
  @ViewChild('contentMaterialModal') materialModal: any;
  @ViewChild('contentRegisterMaterialModal') registerMaterialModal: any;
  @ViewChild('contentModal') contentModal: any;
  project_wattages: ProjectWattage[];
  // project_materials_: ProjectMaterial[];
  project_materials: Material[] = [];

  marker_colors: MarkerColor[] = [];
  emptyProjectWattage: ProjectWattage;
  projectForm: FormGroup;
  materialAdd: FormGroup;
  txtModalAdv = '';
  technologyType = '';
  pagination: GenericTable<ProjectWattage>;

  constructor(
    private administerPowerService: AdministerPowerService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private fb_: FormBuilder,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.getProjectWattages(0);
    //  this.getProjectMaterials();
    this.emptyProjectWattage = new ProjectWattage({ material: {}, marker_color: {} });
    this.materialAdd = this.fb_.group(
      {
        id: [null],
        power: [null, Validators.required],
        optic: [null, Validators.required],
        material_id: [null],
        material_cat_id: [null, Validators.required],
        marker_color_id: [null]
      });
    this.projectForm = this.fb.group(
      {
        id: [null],
        power: [null, Validators.required],
        optic: [null, Validators.required],
        material_id: [null],
        material_cat_id: [null],
        marker_color_id: [null]
      }
    );
  }

  pageChange(page) {
    this.getProjectWattages(page - 1);
  }

  getProjectWattages(page): void {
    this.administerPowerService.getProjectWattages(page, this.technologyType).subscribe(
      response => {
        this.pagination = response;
        this.project_wattages = response.elements;
      }, error => {
        this.toastr.error(error);
      });
  }

  // getProjectMaterials(): void {
  //   this.administerPowerService.getProjectMaterials()
  //   .subscribe((project_materials: ProjectMaterial[]) => {
  //     this.project_materials = project_materials;
  //     this.project_materials = this.project_materials.sort( (a, b) => a.material.name.localeCompare(b.material.name) );
  //   }, error => {
  //     this.toastr.error(error);
  //   });
  // }

  getMarkerColors(markerAdd: MarkerColor): void {
    console.log(markerAdd);
    markerAdd.is_check = true;
    this.administerPowerService.getAvailableMrkerColor().subscribe((markers: MarkerColor[]) => {
      this.marker_colors = markers;
      this.marker_colors.push(markerAdd);
    });
  }

  // getMarkerColors_(): void {
  //   let marker_color = this.projectForm.get('marker_color_id').value || null;
  //   this.administerPowerService.getMakerColors(marker_color)
  //     .subscribe((marker_colors: MarkerColor[]) => {
  //       this.marker_colors = marker_colors;
  //       this.marker_colors = this.marker_colors.sort((a, b) => a.name.localeCompare(b.name));
  //       if (marker_color) {
  //         let marker = this.marker_colors.find(_marker => _marker.id == marker_color);
  //         marker.is_check = true;
  //       }
  //     }, error => {
  //       this.toastr.error(error);
  //     });
  // }

  // onOptionsSelected(power_wattage_id: string , material_cat_id: string, material_id: string) {
  //   this.administerPowerService.changeTypeLuminaire(power_wattage_id, material_cat_id, material_id).subscribe(
  //     (response: Material[]) => {
  //       this.project_materials = response;
  //     }
  //   )
  // }
  onDropDownTypeTechnology() {
    this.administerPowerService.changeTypeLuminaire(this.projectForm.value.material_cat_id, this.projectForm.value.material_id).subscribe(
      (response: Material[]) => {
        this.project_materials = response.sort((a, b) => a.name.localeCompare(b.name));
      });
  }


  onDropDownTypeTechnologyRegister() {
    this.administerPowerService.changeTypeLuminaire(this.materialAdd.value.material_cat_id, this.materialAdd.value.material_id).subscribe(
      (response: Material[]) => {
        this.project_materials = response.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  private onOptionsSelected(projectWattage: ProjectWattage) {
    this.administerPowerService.changeTypeLuminaire(projectWattage.material_cat_id, projectWattage.material.id).subscribe(
      (response: Material[]) => {
        this.project_materials = response.sort((a, b) => a.name.localeCompare(b.name));
        this.projectForm.patchValue({
          id: projectWattage.id,
          power: projectWattage.power,
          material_cat_id: projectWattage.material_cat_id,
          optic: projectWattage.optic,
          material_id: projectWattage.material.id,
          marker_color_id: projectWattage.marker_color.id
        });
        if (projectWattage.id) {
          this.project_materials.forEach(item => {
            if (item.id === projectWattage.material.id) {
              item.is_check = true;
            }

          });
        }
        this.getMarkerColors(projectWattage.marker_color);
      }
    );
  }


  showRegisterMaterialModal() {
    this.clearSelectAll();
    this.project_materials = [];
    this.marker_colors = [];
    this.administerPowerService.getAvailableMrkerColor().subscribe((result: MarkerColor[]) => {
      // console.log(result);
      this.marker_colors = result;
    });
    // const material_cat_id = this.materialAdd.get('material_cat_id').value == null ? '' : this.materialAdd.get('material_cat_id').value;
    this.materialAdd.patchValue({
      id: '',
      power: '',
      material_cat_id: '',
      optic: '',
      material_id: '',
      marker_color_id: ''
    });

    this.modalService.open(this.registerMaterialModal, { size: 'lg' }).result.then(result => {

    }, reason => {
      this.project_materials = [];
      this.materialAdd.patchValue({
        id: '',
        power: '',
        material_cat_id: '',
        optic: '',
        material_id: '',
        marker_color_id: ''
      });
      this.clearSelectAll();
    });



  }

  showMaterialModal(projectWattage: ProjectWattage) {
    this.clearSelectAll();
    this.onOptionsSelected(projectWattage);
    // this.onOptionsSelected(projectWattage.id, projectWattage.material_cat_id, projectWattage.material.id);
    // const material_cat_id = this.projectForm.get('material_cat_id').value == null ? '' : this.projectForm.get('material_cat_id').value;
    // this.projectForm.patchValue({
    //   id: '',
    //   power: '',
    //   material_cat_id: material_cat_id,
    //   optic: '',
    //   material_id: '',
    //   marker_color_id: ''
    // });
    // this.project_materials = [];


    this.modalService.open(this.materialModal, { size: 'lg' }).result.then(result => {
    }, reason => {
      this.clearSelectAll();
    });
  }

  // private setForm(projectWattage: ProjectWattage){
  //   this.projectForm.patchValue({
  //     id: projectWattage.id,
  //     power: projectWattage.power,
  //     material_cat_id: projectWattage.material_cat_id,
  //     optic: projectWattage.optic,
  //     material_id: projectWattage.material.id,
  //     marker_color_id: projectWattage.marker_color.id
  //   });

  //   if(projectWattage.id) {
  //     let material = this.project_materials.find( material => material.id == projectWattage.material.id);
  //     console.log(material, 'material seleccionado');
  //     material.is_check = true;
  //   }
  //   this.getMarkerColors();
  // }

  clearSelectAll() {
    this.clearMarkerSelect();
    this.clearProjectSelect();
  }

  private clearMarkerSelect(): void {
    for (let marker of this.marker_colors) {
      marker.is_check = false;
    }
  }

  private clearProjectSelect(): void {
    for (let material_ of this.project_materials) {
      material_.is_check = false;
    }
  }

  markerSelect(marker_: MarkerColor) {
    this.clearMarkerSelect();
    marker_.is_check = true;
  }

  projectSelect(material: Material) {
    this.clearProjectSelect();
    material.is_check = true;
  }

  createProject() {
    let marker = this.marker_colors.find(_marker => _marker.is_check);
    let material = this.project_materials.find(_project => _project.is_check);

    if (this.materialAdd.valid && this.materialAdd.get('power').value >= 0) {
      if (!marker || !material) {
        return this.toastr.singleError('Operación fallida. Seleciona un marcador y una luminaria.');
      }
      this.materialAdd.patchValue({
        material_id: material.id,
        marker_color_id: marker.id
      });
      const requestPayload = new ProjectWattagePayload(this.materialAdd);
      this.administerPowerService.createProjectWattage(requestPayload)
        .subscribe(result => {
          this.toastr.success('Potencia asignada.');
          this.getProjectWattages(0);
          this.modalService.dismissAll();
        }, error => {
          this.toastr.error(error);
        })
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
      this.materialAdd.controls.power.markAsTouched();
      this.materialAdd.controls.optic.markAsTouched();
    }
  }

  updateProject() {
    if (this.projectForm.valid && this.projectForm.get('power').value >= 0) {
      let material = this.project_materials.find(_project => _project.is_check);
      this.projectForm.patchValue({ marker_color_id: null });
      let marker = this.marker_colors.find(marker => marker.is_check);
      if (marker)
        this.projectForm.patchValue({ marker_color_id: marker.id });
      this.projectForm.patchValue({ material_id: material.id });
      const requestPayload = new ProjectWattagePayload(this.projectForm);
      this.administerPowerService.updateProjectWattage(requestPayload)
        .subscribe(result => {
          this.toastr.success('Potencia actualizada.');
          this.getProjectWattages(0);
          this.modalService.dismissAll();
        }, error => {
          this.toastr.error(error);
        })
    } else {
      this.toastr.error(this.toastr.singleError('Operación fallida. Revisar campos requeridos.'));
    }
  }

  showDeletePower(project: ProjectWattage) {
    this.txtModalAdv = `${project.material.name}, Potencia: ${project.power}, Optica: ${project.optic}`;
    this.modalService.open(this.contentModal).result.then(result => {
      this.administerPowerService.deleteProjectWattage(project.id)
        .subscribe(result => {
          this.toastr.success('Potencia eliminada.');
          this.getProjectWattages(0);
          this.modalService.dismissAll();
        }, error => {
          this.toastr.error(error);
        })
    }, reason => { });
  }

}
