
export class lumWattages {
  luminaires: GeneralViewWattage[];
  progress: WattageCount[];

  constructor(attrs) {
    this.luminaires = attrs.luminaires;
    this.progress = attrs.progress;
  }
}
export class WattageCount {
  count: number;
  wattKey: string;

  constructor(attrs) {
    this.count = attrs.count;
    this.wattKey = attrs.wattKey;
  }
}

export interface IGeneralViewProject {
  id: string,
  marker_color: MarkerColor,
  power: number,
  optic: string,
  isChecked: boolean,
}

export class GeneralViewWattage implements IGeneralViewProject {
  id: string;
  marker_color: MarkerColor;
  power: number;
  optic: string;
  isChecked: boolean;
  amount: number;
  constructor(attrs: any) {
    this.id = attrs.id;
    this.marker_color = new MarkerColor(attrs.marker_color);
    this.power = attrs.power;
    this.optic = attrs.optic;
    this.isChecked = attrs.isChecked;
    this.amount = 0;
  }
}

export class MarkerColor {
  id: string;
  name: string;
  fill_color: string;
  fill_color2: string;
  path: string;
  path2: string;
  is_check: boolean;
  is_hover: boolean;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.fill_color = attrs.fill_color;
    this.fill_color2 = attrs.fill_color2;
    this.path = attrs.path;
    this.path2 = attrs.path2;
    this.is_check = false;
    this.is_hover = false;
  }

  readonly paths = [
    'assets/img/svg/ic_install_pin-',
    'assets/img/svg/ic_install_pin-'
  ];

  get svgIcon() {
    return `assets/img/svg/ic_install_pin-${this.name}.svg`;
  }
}

export class DataSVG {
  fill_color: string;
  fill_color2: string;
  path: string;
  path2: string;
}

export class GeneralViewLumWattage {
  id: string;
  reference_id: string;
  polygonID: number;
  location: Location;
  municipality_status: number;
  curcuit_id: string;
  type: number;
  technology: number;
  has_photocell: boolean;
  linkedSituation: string;
  event: string;
  isSelected: Boolean;
  marker: google.maps.Marker;
  progress: number;
  comment: string;
  markerSVG: DataSVG;
  color: string;
  svg: string;


  constructor(attrs: any, linkedSituation: string = 'single', event: string = 'normal') {
    this.id = attrs.id;
    this.reference_id = attrs.reference_id;
    this.municipality_status = attrs.municipality_status;
    this.location = new Location(attrs.location);
    this.linkedSituation = linkedSituation;
    this.event = event;
    this.isSelected = false;
    this.curcuit_id = attrs.curcuit_id;
    this.type = attrs.type;
    this.technology = attrs.technology;
    this.has_photocell = attrs.has_photocell;
    this.progress = attrs.progress;
    this.markerSVG = attrs.markerSVG;
    this.comment = attrs.comment;
    this.color = attrs.color;


  }

  // tslint:disable-next-line: member-ordering
  readonly markerIcons = {
    linked: {
      hover: 'assets/img/svg/ic_link_hover.svg',
      normal: 'assets/img/svg/link.svg',
      selected: 'assets/img/svg/lam_selected.svg',
      circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
    },
    single: {
      normal: 'assets/img/svg/ic_install_pin-',
      selected: 'assets/img/svg/selected-blue.svg'
    },
    assign: {
      selected: 'assets/img/svg/ic_install_selected-',
      circuitShowing: 'assets/img/svg/ic_install_linked-',
      normal: 'assets/img/svg/ic_install_pin-',
      hover: 'assets/img/svg/ic_install_hover-',
    },
    citizen: {
      normal: 'assets/img/svg/ic_single_normal.svg',
      selected: 'assets/img/svg/link_selected.svg'
    }
  }
  // -----
  get_icon(): object {
    return this.linkedSituation === 'assign'
      ?
      {
        url: `${this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.color}.svg`,
        scaledSize: new google.maps.Size(12, 12)
      }
      :
      {
        url: `${this.markerIcons[this.linkedSituation][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.color}.svg`,
        scaledSize: this.color === 'normal' ? new google.maps.Size(22, 22) : new google.maps.Size(12, 12)
      };
  }
  // --metodo anterior---
  updateIcon_(event: string): void {
    // console.log(this.isSelected, 'update icon _');
    this.event = event;
    this.marker.setIcon(this.get_icon());
  }

  updateSVG(event: String) {
    // this.event = event;
    // const color = this.linkedSituation === ''
    if (this.isSelected) {
      this.svg = `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                      <g>
                          <path stroke="${event}" stroke-opacity="12" stroke-width="10" fill="${this.markerSVG.fill_color}"
                          d="${this.markerSVG.path}"> </path>
                          <path fill="${this.markerSVG.fill_color2}" d="${this.markerSVG.path2}"> </path>
                      </g>
                  </svg>`;
    } else {
      this.svg = `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                      <g>
                          <path stroke="black" stroke-opacity="12" stroke-width="2" fill="${this.markerSVG.fill_color}"
                          d="${this.markerSVG.path}"> </path>
                          <path fill="${this.markerSVG.fill_color2}" d="${this.markerSVG.path2}"> </path>
                      </g>
                  </svg>`;
    }
    // checar si se duplica el icono
    this.marker.setIcon('data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.svg));
  }



  paintMarker(map: any): void {
    this.event = this.isSelected ? 'selected' : 'normal';
    this.marker = new google.maps.Marker({
      title: this.reference_id == null ? this.id : this.reference_id.toString(),
      position: new google.maps.LatLng(this.location.latitude, this.location.longitude),
      icon: this.get_icon(),

      map: map
    });
  }

  paintMarker_(map: any) {
    // this.buildSVG();
    this.svg = `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <g>
          <path stroke="black" stroke-opacity="12" stroke-width="2" fill="${this.markerSVG.fill_color}"
          d="${this.markerSVG.path}"> </path>
          <path fill="${this.markerSVG.fill_color2}" d="${this.markerSVG.path2}"> </path>
      </g>
  </svg>`;
    this.event = this.isSelected ? 'selected' : 'normal';
    this.marker = new google.maps.Marker({
      title: this.reference_id == null ? this.id : this.reference_id.toString(),
      position: new google.maps.LatLng(this.location.latitude, this.location.longitude),
      icon: {
        url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.svg)
      },
      map: map
    });
  }

  buildSVG() {
    // console.log(this.isSelected, 'buildSVG')
    if (this.isSelected) {
      this.svg = `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <g>
          <path stroke="blue" stroke-opacity="12" stroke-width="5" fill="${this.markerSVG.fill_color}"
          d="${this.markerSVG.path}"> </path>
          <path fill="${this.markerSVG.fill_color2}" d="${this.markerSVG.path2}"> </path>
      </g>
  </svg>`;
    } else {
      this.svg = `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <g>
          <path stroke="black" stroke-opacity="12" stroke-width="2" fill="${this.markerSVG.fill_color}"
          d="${this.markerSVG.path}"> </path>
          <path fill="${this.markerSVG.fill_color2}" d="${this.markerSVG.path2}"> </path>
      </g>
  </svg>`;
    }
  }


  hideMarker(): void {
    this.marker.setMap(null);
  }

  setMap(map: any): void {
    this.marker.setMap(map);
  }
}


interface LocationInterface {
  latitude: number;
  longitude: number;
}
export class Location implements LocationInterface {
  latitude: number;
  longitude: number;

  constructor(attrs: any) {
    this.latitude = attrs.latitude;
    this.longitude = attrs.longitude;
  }
}



export enum ProgressEnum {
  'Censo rápido',
  'Censo infraestructura',
  'Instalación',
}

enum ProgressIconrEnum {
  '458_C',
  '190_C',
  '139_C',
}