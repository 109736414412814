import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment'
import { map } from 'rxjs/operators';
import { WarehouseStats } from '@app/models/warehouse-stats';
import { SummaryToday } from '@app/models/summaryToday';
import { SummaryTomorrow } from '@app/models/summaryTomorrow';

@Injectable({
  providedIn: 'root'
})
export class InventorSummaryService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // getWarehouseStats(): Observable<WarehouseStats> {
  //   return this.httpClient
  //     .get<any>(`${environment.SERVER_URL}/warehouse_stats`)
  //     .pipe( map( data => new WarehouseStats(data.warehouse_stats)) );
  // }

  updateShoppingCart(userId: string): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/shopping_cart/replace_with_user_needs/${userId}`, {});
  }
  getSummaryToday() {
    return this.httpClient.get<SummaryToday[]>(
      `${environment.SERVER_WAREHOUSE}/api/Summary/GetSummaryToday`).pipe(
      map(rm => rm.map(req => new SummaryToday(req) ))
    )
  }



  getSummaryTomorrow() {
    return this.httpClient.get<SummaryTomorrow[]>(
      `${environment.SERVER_WAREHOUSE}/api/Summary/GetSummaryTomorrow`).pipe(
      map(rm => rm.map(req => new SummaryTomorrow(req) ))
    )
  }

}
