import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priority'
})
export class PriorityPipe implements PipeTransform {

  transform(numberPriority: number): string {
    var result;
    switch(numberPriority){
      case 0:{
        result = "Alta";
        break;
      }
      case 1:{
        result = "Mediana";
        break;
      }
      case 2:{
        result = "Baja";
        break;
      }
    }
    return result;
  }

}
