import { GenericTable } from "@app/models/generic-table";
import { Circuit } from "@app/models/circuit";
import { FusionChartModel } from "@app/models/FusionChartModel";
import { DataTable } from "./dashboard-detail-values-rpu";

export interface IDashboardDetail {
  number_rpus: number,
  weighted_score: number,
  values_DataTable: DataTable[],
  fusionChart: FusionChartModel,
  circuits: Circuit[],
  table_list: Period[],

}

export class DashboardDetail implements IDashboardDetail {
  number_rpus: number;
  weighted_score: number;
  values_DataTable: DataTable[];
  fusionChart: FusionChartModel;
  circuits: Circuit[];
  table_list: Period[];

  constructor(attrs: any) {
    this.number_rpus = attrs.number_rpus;
    this.weighted_score = attrs.weighted_score;
    this.values_DataTable = attrs.values_DataTable;
    this.fusionChart = attrs.fusionchart;
    this.circuits = attrs.circuits;
    this.table_list = attrs.table_list;
  }

  GetByYearAndType(year_selected = 0, type = false) {
    const period: Period[] = [];
    if (year_selected == 0 && !type) {
      this.table_list.forEach(periodo => {
        periodo.monthString = this.GetMonthString(periodo.month);
        period.push(periodo);
      });
      return period
    }
    if (type) {
      let years = year_selected == 0 ? this.table_list.map(x => x.year) : this.table_list.filter(x => x.year == year_selected).map(x => x.year);
      years = years.filter((n, i) => years.indexOf(n) === i);
      years.forEach(year => {
        const current_year = this.table_list.filter(x => x.year === year);
        const kwh = current_year.map(x => x.kwh)[0];
        const mxn = current_year.map(x => x.mxn)[0];
        const savingKWH = current_year.map(x => x.savingKwh)[0];
        const savingMXN = current_year.map(x => x.savingMxn)[0];
        const estimatedConsumptionKwh = current_year.map(x => x.estimatedConsumptionKwh)[0];
        const estimatedConsumptioMxn = current_year.map(x => x.estimatedConsumptioMxn)[0];
        const aditionalKwh = current_year.map(x => x.aditionalKwh)[0];
        const aditionalMxn = current_year.map(x => x.aditionalMxn)[0];
        const receipts = current_year.map(x => x.receipts)[0];
        // const kwh = current_year.reduce((kwh, current) => kwh + current.kwh, 0);
        // const mxn = current_year.reduce((mxn, current) => mxn + current.mxn, 0);
        // const savingKWH = current_year.reduce((savingKWH, current) => savingKWH + current.savingKwh, 0);
        // const savingMXN = current_year.reduce((savingMXN, current) => savingMXN + current.savingMxn, 0);
        // const estimatedConsumptionKwh = current_year.reduce((estimatedConsumptionKwh, current) => estimatedConsumptionKwh + current.estimatedConsumptionKwh, 0);
        // const estimatedConsumptioMxn = current_year.reduce((estimatedConsumptioMxn, current) => estimatedConsumptioMxn + current.estimatedConsumptioMxn, 0);
        // const aditionalKwh = current_year.reduce((aditionalKwh, current) => aditionalKwh + current.aditionalKwh, 0);
        // const aditionalMxn = current_year.reduce((aditionalMxn, current) => aditionalMxn + current.aditionalMxn, 0);
        // const receipts = current_year.reduce((receipts, current) => receipts + current.receipts, 0);
        const countMonths = current_year.length;

        period.push(new Period({ year: year, month: "", countMonths: countMonths, kwh: kwh, mxn: Math.round(mxn), savingKwh: Math.round(savingKWH), savingMxn: Math.round(savingMXN), estimatedConsumptionKwh: Math.round(estimatedConsumptionKwh), estimatedConsumptioMxn: Math.round(estimatedConsumptioMxn), aditionalKwh: aditionalKwh, aditionalMxn: aditionalMxn, receipts: receipts }));
      });
      return period;
    } else {
      return this.table_list.filter(x => x.year == year_selected);
    }
  }

  GetLastValue() {
    return this.table_list[this.table_list.length - 1];
  }
  GetMonthString(numberMonth: number) {
    let stringMonth = '';
    switch (numberMonth) {
      case 1: {
        stringMonth = 'ENE';
        break;
      }
      case 2: {
       stringMonth = 'FEB';
        break;
      }
      case 3: {
        stringMonth = 'MAR';
        break;
      }
      case 4: {
        stringMonth = 'ABR';
        break;
      }
      case 5: {
        stringMonth = 'MAY';
        break;
      }
      case 6: {
        stringMonth = 'JUN';
        break;
      }
      case 7: {
        stringMonth = 'JUL';
        break;
      }
      case 8: {
        stringMonth = 'AGO';
        break;
      }
      case 9: {
        stringMonth = 'SEP';
        break;
      }
      case 10: {
        stringMonth = 'OCT';
        break;
      }
      case 11: {
        stringMonth = 'NOV';
        break;
      }
      case 12: {
        stringMonth = 'DIC';
        break;
      }
    }
    return stringMonth;
  }
}

export class Period {
  year: number;
  month: number;
  monthString: string;
  countMonths: number;
  kwh: number;
  mxn: number;
  savingKwh: number;
  savingMxn: number;
  estimatedConsumptionKwh: number;
  estimatedConsumptioMxn: number;
  aditionalKwh: number;
  aditionalMxn: number;
  receipts: number;

  constructor(attrs: any) {
    this.year = attrs.year;
    this.month = attrs.month;
    this.countMonths = attrs.countMonths;
    this.receipts = attrs.receipts;
    this.kwh = attrs.kwh;
    this.mxn = attrs.mxn;
    this.savingKwh = attrs.savingKwh;
    this.savingMxn = attrs.savingMxn;
    this.estimatedConsumptioMxn = attrs.estimatedConsumptioMxn;
    this.estimatedConsumptionKwh = attrs.estimatedConsumptionKwh;
    this.aditionalKwh = attrs.aditionalKwh;
    this.aditionalMxn = attrs.aditionalMxn;

  }
}
