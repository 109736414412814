import { Component, OnInit, ViewChild } from '@angular/core';
import { Luminaire } from '@app/models/luminaire';
import { LuminaireEnum } from '@app/models/luminaire-enum';
import { MountingTypeEnum } from '@app/models/mounting_type-enum';
import { StyleMap } from '@app/models/styleMap';
import { SubstationCurrentCapacityEnum } from '@app/models/substation_current_capacity-enum';
import { SubstationEnum } from '@app/models/substation_type-enum';
import { TechnologyEnum } from '@app/models/technology-enum';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { Subject } from 'rxjs';
import { GeneralViewCircuit } from '../general-view-circuit';
import { GeneralViewService } from '../general-view.service';
import { LuminaireNew, lumProgress, Progress } from '../luminairesNew';

@Component({
  selector: 'app-general-view-progress',
  templateUrl: './general-view-progress.component.html',
  styleUrls: ['./general-view-progress.component.scss'],
  providers: [MapFuncAuxService, CurrentProjectService],
})
export class GeneralViewProgressComponent implements OnInit {
  luminaireEnum = Object.keys(LuminaireEnum).filter(
    (key) => !isNaN(Number(LuminaireEnum[key]))
  );
  technologyEnum = Object.keys(TechnologyEnum).filter(
    (key) => !isNaN(Number(TechnologyEnum[key]))
  );
  substationEnum = Object.keys(SubstationEnum).filter(
    (key) => !isNaN(Number(SubstationEnum[key]))
  );
  CapacityEnum = Object.keys(SubstationCurrentCapacityEnum).filter(
    (key) => key.length > 2
  );
  mountingTypeEnum = Object.keys(MountingTypeEnum).filter(
    (key) => !isNaN(Number(MountingTypeEnum[key]))
  );

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedItemsList = [];
  checkedIDs = [];
  lstInfowindows = [];
  circuits: GeneralViewCircuit[] = [];
  messageCircuitTxt = '';
  Luminaires: LuminaireNew[] = [];
  progress: Progress[] = [];
  isSelected: boolean;
  crStatistics = 0;
  ciStatistics = 0;
  iStatistics = 0;
  zoomToShowMarkes = 16;
  slug: string;
  checkboxesDataList = [
    { status: 'Luminarias', value: 1, isChecked: false },
    { status: 'Circuitos', value: 2, isChecked: false },
  ];

  constructor(
    private toastr: Toastr,
    private mapFuncAux: MapFuncAuxService,
    private generalViewService: GeneralViewService,
    private currentProjectService: CurrentProjectService
  ) {}

  ngOnInit() {
    this.paintMap();
  }

  fetchSelectedItems() {
    this.selectedItemsList = this.checkboxesDataList
      .filter((opt) => opt.isChecked)
      .map((opt) => opt.value);
    return this.selectedItemsList;
  }

  private paintMap() {
    const project = this.currentProjectService.getCurrentProject();
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    this.map = new google.maps.Map(
      this.gmapElement.nativeElement,
      mapProperties
    );
    this.paintTheSearchBoxOfMap();
    this.paintTheSearchCircuit();
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    });
    this.map.addListener('click', () => {
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
     let deselect = this.Luminaires.filter(x => x.isSelected === true);
     deselect.forEach(element => {
       element.isSelected = false;
       element.linkedSituation = 'single';
       element.upadeIcon('normal');
     });
     let deselectCir = this.circuits.filter(x => x.isSelected === true);
     deselectCir.forEach(element => {
       element.isSelected = false;
       element.linkedSituation = 'single';
       element.upadeIcon('normal');
     });
    });

  }

  getLuminaires() {
    this.generalViewService.getAllLuminaires().subscribe((res: lumProgress) => {
      const allObject = new lumProgress(res);
      allObject.progress.forEach((element) => {
        if (element.key === 0) {
          this.crStatistics = element.count;
        }
        if (element.key === 1) {
          this.ciStatistics = element.count;
        }
        if (element.key === 2) {
          this.iStatistics = element.count;
        }
      });
      const lum = allObject.luminaires.map((x) => new LuminaireNew(x));
      this.Luminaires = lum;
      this.paintLuminaires(lum);
    });
  }

  getCircuits() {
    this.circuits.forEach((element) => {
      element.hideMarker();
    });
    this.generalViewService
      .getProjectCircuits()
      .subscribe((res: GeneralViewCircuit[]) => {
        const circuits = res.map((circuit) => new GeneralViewCircuit(circuit));
        this.circuits = circuits;
        this.paintCircuits(circuits);
      });
  }

  submitValue() {
    this.Luminaires.forEach((element) => {
      element.hideMarker();
    });
    this.circuits.forEach((element) => {
      element.hideMarker();
    });

    if (this.fetchSelectedItems().find((x) => x === 1)) {
      this.getLuminaires();
    }

    if (this.fetchSelectedItems().find((x) => x === 2)) {
      this.getCircuits();
    }
  }

  paintLuminaires(luminaires) {
    for (let luminaire of luminaires) {
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.selectedLum(luminaire);
        this.showInfoMarker(luminaire);
      });
    }
  }

  paintCircuits(circuits) {
    for (let circuit of circuits) {
      circuit.paintMarker(this.map);
      circuit.marker.addListener('click', () => {
        this.selectedCircuit(circuit);
        this.showInfocircuit(circuit);
      });
    }
  }

  selectedLum(luminaire: LuminaireNew) {
    let selected = this.Luminaires.filter(x => x.isSelected === true);
    let selctedByCircuit = this.Luminaires.filter(x => x.curcuit_id === luminaire.curcuit_id && x.curcuit_id !== null);
    selected.forEach(ele => {
      ele.isSelected = false;
  ele.linkedSituation = 'single';
  ele.upadeIcon('normal');
     });
     selctedByCircuit.forEach(element => {
      element.isSelected = true;
       element.linkedSituation = 'assign';
       element.upadeIcon('selected');
     });
  }

  selectedCircuit (circuit: GeneralViewCircuit) {
      if (this.Luminaires !== null) {
       let selected = this.Luminaires.filter(x => x.isSelected === true);
        let selctedByCircuit = this.Luminaires.filter(x => x.curcuit_id === circuit.id);
      selected.forEach(ele => {
        ele.isSelected = false;
    ele.linkedSituation = 'single';
     ele.upadeIcon('normal');
       });
         selctedByCircuit.forEach(element => {
          element.isSelected = true;
           element.linkedSituation = 'assign';
           element.upadeIcon('selected');
         });
      }
  }
  private showInfoMarker(luminaire: LuminaireNew) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    let strInfo = this.getStringInfo(luminaire);
    let infowindow = new google.maps.InfoWindow({ content: strInfo });
    infowindow.open(this.map, luminaire.marker);
    window.setTimeout(() => {
      let seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
      let btnEditLum = document.getElementById('luminaireEdit');
      btnEditLum.addEventListener('click', () => this.saveEditLum(luminaire));
      seeMoreLuminaire.addEventListener('click', () =>
        this.mapFuncAux.seeMoreLuminaire(luminaire.id)
      );
    }, 500);
    this.lstInfowindows.push(infowindow);
  }

  private getStringInfo(luminaire: LuminaireNew): string {
    return `
    <div class='container my-0 mx-0 py-0 px-0'>
      <div class='row mx-1 my-0 mt-1'>
        <div class='col-6'>
          <div class='row'>
            <div class='col-12 px-0'>
              <strong>${luminaire.reference_id ? 'Referencia' : 'ID'}</strong>
            </div>
          </div>
          <div class='row mb-1'>
            <div class='col-12 px-0'>${
              luminaire.reference_id || luminaire.id
            }</div>
          </div>
          <div class='row'>
            <div class='col-12 px-0'>
              <strong>Tipo</strong>
            </div>
          </div>
          <div class='row mb-1'>
            ${this.luminaireEnum[luminaire.type]}
          </div>
          <div class='row'>
            <div class='col-12 px-0'><strong>Tecnología</strong></div>
          </div>
          <div class='row mb-1'>
            ${this.technologyEnum[luminaire.technology]}
          </div>
          <div class='row'>
            <div class='col-12 px-0'><strong>Fotocelda</strong></div>
          </div>
          <div class='row mb-1'>
            ${luminaire.has_photocell ? 'Si' : 'No'}
          </div>
        </div>
        <div class='col-6'>
          <div class='row'>
            <div class='col-12'><strong>Comentarios</strong></div>
          </div>
          <div class='row'>
            <div class='col-12 mb-1'>
            <textarea id='textAreaComment' style='width: 100%;' rows='6' cols='40'>${
              luminaire.comment == null ? '' : luminaire.comment
            }</textarea>
            </div>
          </div>
          <div class='row'>
            <div class='col-6'>
              <a href='u/luminaires/${luminaire.id}' onclick='return false;'>
                <input id='seeMoreLuminaire' type='button' style='position: absolute; cursor:pointer;' value='Ver mas' class='oe--button--info'>
              </a>
            </div>
            <div class='col-6'>
              <input id='luminaireEdit' type='button' style='position: absolute; right: 14px;' value='Guardar' class='oe--button--info'>
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
  }

  private saveEditLum(luminaire: LuminaireNew) {
    const txtArea = document.getElementById('textAreaComment');
    const data = {
      id: luminaire.id,
      comment: txtArea['value'],
    };
    this.generalViewService.editLuminaire(data).subscribe(
      (response) => {
        this.toastr.success('Luminaria actualizada.');
      },
      (error) => {
        this.toastr.error(error);
      }
    );
  }

  private showInfocircuit(circuit: GeneralViewCircuit) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    let strInfo = this.getCircuitStringInfo(circuit);
    let infowindow = new google.maps.InfoWindow({ content: strInfo });
    infowindow.open(this.map, circuit.marker);
    window.setTimeout(() => {
      const seeMoreCircuit = document.getElementById('seeMoreCircuit');
      const btnEditCircuit = document.getElementById('CircuitEdit');
      btnEditCircuit.addEventListener('click', () =>
        this.saveEditCircuit(circuit)
      );
      seeMoreCircuit.addEventListener('click', () =>
        this.mapFuncAux.seeMoreCircuit(circuit.id)
      );
    }, 500);
    this.lstInfowindows.push(infowindow);
  }

  private getCircuitStringInfo(circuit: GeneralViewCircuit): string {
    return `
    <div class='container my-0 mx-0 py-0 px-0'>
      <div class='row mx-1 my-0 mt-1'>
        <div class='col-6'>
          <div class='row'>
            <div class='col-12 px-0'>
              <strong> ID </strong>
            </div>
          </div>
          <div class='row mb-1'>
            <div class='col-12 px-0'>${circuit.identifier}</div>
          </div>
          <div class='row'>
            <div class='col-12 px-0'>
              <strong>Capacidad</strong>
            </div>
          </div>
          <div class='row mb-1'>
            ${this.CapacityEnum[circuit.capacidad]}
          </div>
          <div class='row'>
            <div class='col-12 px-0'><strong>Total Watts</strong></div>
          </div>
          <div class='row mb-1'>
            ${circuit.totalWatts}
          </div>
          <div class='row'>
            <div class='col-12 px-0'><strong>Current</strong></div>
          </div>
          <div class='row mb-1'>
            ${circuit.current}
          </div>
          <div class='row'>
          <div class='col-12 px-0'><strong>Voltage</strong></div>
        </div>
        <div class='row mb-1'>
          ${circuit.voltage}
        </div>
        </div>
        <div class='col-6'>
        <div class='row'>
        <div class='col-12 px-0'><strong>Meter</strong></div>
      </div>
      <div class='row mb-1'>
        ${circuit.meter_identifier}
      </div>
      <div class='row'>
      <div class='col-12 px-0'><strong>Montaje</strong></div>
    </div>
    <div class='row mb-1'>
      ${this.mountingTypeEnum[circuit.mounting_type]}
    </div>
          <div class='row'>
            <div class='col-12 px-0'><strong>Comentarios</strong></div>
          </div>
          <div class='row'>
            <div class='col-12 mb-1 px-0'>
            <textarea id='textAreaComment' style='width: 100%;' rows='4' cols='40'>${
              circuit.comment == null ? '' : circuit.comment
            }</textarea>
            </div>
          </div>
          <div class='row'>
            <div class='col-6'>
              <a href='u/circuits/${circuit.id}' onclick='return false;'>
                <input id='seeMoreCircuit' type='button' style='position: absolute; cursor:pointer;' value='Ver mas' class='oe--button--info'>
              </a>
            </div>
            <div class='col-6'>
              <input id='CircuitEdit' type='button' style='position: absolute; right: 14px;' value='Guardar' class='oe--button--info'>
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
  }

  private saveEditCircuit(circuit: GeneralViewCircuit) {
    const txtArea = document.getElementById('textAreaComment');
    const data = {
      id: circuit.id,
      comment: txtArea['value'],
    };
    this.generalViewService.editCircuit(data).subscribe(
      (response) => {
        this.toastr.success('Luminaria actualizada.');
      },
      (error) => {
        this.toastr.error(error);
      }
    );
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value],
    });
  }

  private paintTheSearchBoxOfMap() {
    let input: any = document.getElementById('pac-input');
    var searchBox = new google.maps.places.SearchBox(input);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds());
    });

    searchBox.addListener('places_changed', () => {
      let data: any = input.value;
      data = data.trim();
      if (!isNaN(data)) {
      this.searchReferenceId(data);
       return;
        }

      let places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      let bounds = new google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log('Returned place contains no geometry');
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    });
  }

  private searchReferenceId(value) {
    const luminaire = this.Luminaires.find((x) => x.reference_id == value);

    if (luminaire == null || luminaire == undefined) {
      this.toastr.singleError("No se encontro considencias con el texto: '" + value + "'");
    }
    const position = new google.maps.LatLng(
      luminaire.location.latitude,
      luminaire.location.longitude
    );
    this.map.setCenter(position);
    luminaire.marker.setAnimation(google.maps.Animation.BOUNCE);
    this.map.setZoom(this.zoomToShowMarkes + 1);
    luminaire.marker.setIcon(luminaire.get_iconForSearch());
    luminaire.marker.addListener('click', () => {
      luminaire.marker.setAnimation(null);
      luminaire.marker.setIcon(luminaire.get_icon());
  });
  }

  private paintTheSearchCircuit() {
    let input: any = document.getElementById('cir-input');
    var searchBox = new google.maps.places.SearchBox(input);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds());
    });

    searchBox.addListener('places_changed', () => {
      let data: any = input.value;
      data = data.trim();
      this.searchCircuitId(data);
      let places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      let bounds = new google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log('Returned place contains no geometry');
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    });
  }

  private searchCircuitId(value) {
    const circuit = this.circuits.find((x) => x.identifier == value);
    if (circuit == null || circuit == undefined) {
      this.toastr.singleError("No se encontro considencias con el texto: '" + value + "'");
    }
    const position = new google.maps.LatLng(
      circuit.location.latitude,
      circuit.location.longitude
    );
    this.map.setCenter(position);
    circuit.marker.setAnimation(google.maps.Animation.BOUNCE);
    this.map.setZoom(this.zoomToShowMarkes + 1);
    circuit.marker.setIcon(circuit.get_iconForSearch());

    circuit.marker.addListener('click', () => {
        circuit.marker.setAnimation(null);
        circuit.marker.setIcon(circuit.get_icon());
    });

  }
}
