import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, Form } from '@angular/forms';
import { EditSubstationPayload } from './edit-substation-payload';
import { EditCircuitService } from '../edit-circuit.service';
import { Toastr } from '@shared/toastr/toastr.service';
import { SubstationEnum } from '@models/substation_type-enum';
import { SubstationCurrentCapacityEnum } from '@models/substation_current_capacity-enum';
import { MtVoltageEnum } from '@models/mt_voltage-enum';
import { BtVoltageEnum } from '@models/bt_voltage-enum';
import { PhasesEnum } from '@models/phases-enum';
import { ServiceTypeEnum } from '@models/service_type-enum';
import { Circuit } from '@models/circuit';

@Component({
  selector: 'app-edit-substation',
  templateUrl: './edit-substation.component.html',
  providers: [EditCircuitService]
})
export class EditSubstationComponent implements OnInit {
  substationEnum = Object.keys(SubstationEnum).filter(key => !isNaN(Number(SubstationEnum[key])));
  substationCurrentCapacityEnum = Object.keys(SubstationCurrentCapacityEnum).filter(key => key.length > 2);
  mtVoltageEnum = Object.keys(MtVoltageEnum).filter(key => key.length > 2);
  btVoltageEnum = Object.keys(BtVoltageEnum).filter(key => key.length > 2);
  phasesEnum = Object.keys(PhasesEnum).filter(key => key.length > 2);
  serviceTypeEnum = Object.keys(ServiceTypeEnum).filter(key => !isNaN(Number(ServiceTypeEnum[key])));
  substationForm: FormGroup;
  image: any;
  @Input() circuit: Circuit

  constructor(
    private formBuilder: FormBuilder,
    private editCircuitService: EditCircuitService,
    private toastr: Toastr,
  ) { }

  ngOnInit() {
   this.substationForm = this.formBuilder.group({
      id: [null],
      is_present: [null],
      identifier: [null],
      substation_type: [null],
      current_capacity: [null],
      mt_voltage: [null],
      bt_voltage: [null],
      phases: [null],
      service_type: [null],
      condition: [null, Validators.max(5)],
      image_url: [null],
      image: [null]
    });
    this.populateForm(this.circuit.substation);
  }

  populateForm(substation) {
    this.substationForm.patchValue({
      id: substation.id,
      is_present: substation.is_present,
      identifier: substation.identifier,
      substation_type: substation.substation_type,
      current_capacity: substation.current_capacity,
      mt_voltage: substation.mt_voltage,
      bt_voltage: substation.bt_voltage,
      phases: substation.phases,
      service_type: substation.service_type,
      image_url: substation.image_url,
      image: substation.image,
      condition: substation.condition
    });

  }

  //performSubstationFormAction(): void {
   // this.editSubstation();
  //}
  // private editSubstation(): void{
  //   if (this.substationForm.valid) {
  //     const requestPayload = new EditSubstationPayload(this.substationForm);
  //     this.editCircuitService
  //       .editSubstation(this.circuit, requestPayload)
  //       .subscribe(
  //         () => {
  //           this.toastr.success('Subestación actualizada.');
  //         },
  //         (error) => {
  //           this.toastr.error(error);
  //         }
  //       )
  //   } else {
  //     this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
  //   }
  // }
   editSubstation() {
    const formData: FormData = new FormData();
    formData.append('id', this.substationForm.value.id);
    formData.append('is_present', this.substationForm.value.is_present ? 'true' : 'false');
    formData.append('identifier', this.substationForm.value.identifier);
    if(this.substationForm.value.substation_type != null){
      formData.append('substation_type', this.substationForm.value.substation_type);
    }
    if(this.substationForm.value.current_capacity != null){
      formData.append('current_capacity', this.substationForm.value.current_capacity);
    }
    if(this.substationForm.value.mt_voltage != null){
      formData.append('mt_voltage', this.substationForm.value.mt_voltage);
    }
    if(this.substationForm.value.bt_voltage != null){
      formData.append('bt_voltage', this.substationForm.value.bt_voltage);
    }
    if(this.substationForm.value.phases != null){
      formData.append('phases', this.substationForm.value.phases);
    }
    if(this.substationForm.value.service_type != null){
      formData.append('service_type', this.substationForm.value.service_type);
    }
    if(this.substationForm.value.condition != null){
      formData.append('condition', this.substationForm.value.condition);
    }
    formData.append('image', this.image);

    console.log(this.substationForm.value.id)
    if (this.substationForm.valid) {
      this.editCircuitService
        .editSubstationNew(formData)
        .subscribe(
          () => {
            this.toastr.success('Subestación actualizada.');
          },
          (error) => {
            this.toastr.error(error);
          }
        )
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }
  setImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.image = event.target.files[0];
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.substationForm.patchValue({ image_url: reader.result })
        this.substationForm.patchValue({ image: file })
      };

      reader.readAsDataURL(file);
    }
  }

}
