import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogMaintanenceService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getLogList(startDate: string, endDate: string, log_type: number): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('task_type', log_type.toString());

    return this.httpClient.get(
      `${environment.SERVER_TASKS}/api/Service/GetCorrectiveLogList`, { params: params}
      );
  }

  downloadLogMaintenance(startDate: string, endDate: string, typeFile: string, log_type: number) {
    const params: HttpParams = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('task_type', log_type.toString());


    this.httpClient.get(
      `${environment.SERVER_TASKS}/api/Service/GetCorrictiveLog${typeFile.toUpperCase()}`, {
        params: params,
        responseType: 'blob'
      }).subscribe(file => {
      this.downloadProcess(file, `Bitacora-Mantenimiento.${typeFile}`);
    })
  }

  userTasks() {
    return this.httpClient.get(
      `${environment.SERVER_TASKS}/api/Service/GetUsersTasks`
    );
  }

  private downloadProcess(file, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([file], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}
