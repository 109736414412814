import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

import { environment } from '@environments/environment';

@Injectable()
export class ProjectService {
  constructor(
    private cookieService: CookieService
  ) {}

  getProject() {
    return this.cookieService.get(`${environment.CURRENT_PROJECT_COOKIE}`) || '';
  }

  projectIsSelected() {
    return this.getProject() ? true : false;
  }
}