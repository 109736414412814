import { FormGroup } from '@angular/forms';

import { Material, MaterialAttributes } from './material';

export class ProjectMaterialPayload implements Material {
    id: string;
    name: string;
    minimum: number;
    image_url: string;
    image: string;
    is_active: boolean;
    total: number;
    editing: boolean;
    stock: number;
    luminaire_type: number;
    technology: number;
    material_attributes: MaterialAttributes;
    is_check: boolean;
    material_code: string;
    cost: number;

  constructor(materialForm: FormGroup) {
    this.id = materialForm.get('id').value;
    this.name = materialForm.get('name').value;
    this.minimum = materialForm.get('minimum').value;
    this.image = materialForm.get('image').value;
    this.is_active = materialForm.get('is_active').value;
    this.stock = materialForm.get('stock') ? materialForm.get('stock').value : null;
    this.luminaire_type = materialForm.get('type') ? materialForm.get('type').value : null;
    this.technology = materialForm.get('technology') ? materialForm.get('technology').value : null;
    this.is_check = false;
    this.material_code = materialForm.get('material_code').value;
    this.cost = materialForm.get('cost').value
  }

  toFormData() {
    let formData = new FormData();
    formData.append('project_material[material_attributes][name]', this.name);
    formData.append('project_material[material_code]', this.material_code);
    formData.append('project_material[troop_minimum]', this.minimum != null ? this.minimum.toString() : '');
    if(this.image != undefined)
      formData.append('project_material[image]', this.image);
    formData.append('project_material[is_active]', this.is_active ? 'true' : 'false');
    if(this.stock != undefined)
      formData.append('project_material[stock]', this.stock.toString());
    if(this.luminaire_type){
      formData.append('project_material[material_attributes][luminaire_type]', this.luminaire_type.toString());
      formData.append('project_material[material_attributes][technology]', this.technology.toString());
    }

    return formData;
  }

}
