import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ExtrackedMaterial } from '@app/models/material-extrackted';
import { PaginationAttributes } from '@app/models/pagination';
import { TrackMaterialService } from '../track-material.service';

@Component({
  selector: 'app-extracted-material',
  templateUrl: './extracted-material.component.html',
  styleUrls: ['./extracted-material.component.scss']
})
export class ExtractedMaterialComponent implements OnInit {

  
  extrackedMaterial: ExtrackedMaterial[] = [];
  formExtractedMaterial: FormGroup;
  finalDate
  initialDate
  pagination: PaginationAttributes;
  currentPage = 0;

  constructor(private trackService: TrackMaterialService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.formExtractedMaterial = this.fb.group({
      MaterialFilter: ['all'],
      StartDate: [null],
      EndDate: [null]
    });
  }

  searchForExtractedMaterial() {
    const StartDate = this.formExtractedMaterial.get('StartDate').value;
    const EndDate = this.formExtractedMaterial.get('EndDate').value;
    const matFilter = this.formExtractedMaterial.get('MaterialFilter').value;
    this.trackService.getExtractedMaterial(
      this.parseDate(StartDate),
      this.parseDate(EndDate)).subscribe(response => {
        if (matFilter === 'all') {
          this.extrackedMaterial = response;
        }
      else{this.extrackedMaterial = response.filter(a => a.etradaOsalida === matFilter);
        }
       })
  }

  exportData(typeFile: string) {
    const initial = this.initialDate['year'] + '-' + this.initialDate['month'] + '-' + this.initialDate['day']
    const final = this.finalDate['year'] + '-' + this.finalDate['month'] + '-' + this.finalDate['day']

    this.trackService.downloadExtractedMaterial(initial, final, typeFile);
  }

  parseDate(date) {
    if (date != null) {
      return  `${date.year}/${date.month}/${date.day}`
    }
    return ''
  }

  pageChange(page: number) {
    this.currentPage = page - 1;
    this.searchForExtractedMaterial()
  }

}
