import { Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VirtualcircuitsService } from '../virtualcircuits.service';
import { ValuesVirtualCircuit, Luminaires } from '../virtualcircuitsmodel';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { StyleMap } from '@app/models/styleMap';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import * as Driver from 'driver.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Item } from '@app/models/item';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Toastr } from '@app/shared/toastr/toastr.service';
@Component({
  selector: 'app-luminaires-virtual-circuit',
  templateUrl: './luminaires-virtual-circuit.component.html',
  styleUrls: ['./luminaires-virtual-circuit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MapFuncAuxService, VirtualcircuitsService]
})
export class LuminairesVirtualCircuitComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;
  @Input() luminaires: Luminaires[];
  @ViewChild('contentHasCircuit') elementHasCircuit: any;
  @ViewChild('contentAvisoExit') elementAvisoExit: any;
  @ViewChild('modalInformation') ngModalInformation: any;
  editMode = '';
  addLuminairesCircuit = [];
  formGroup: FormGroup;
  lstInfowindows = [];
  styleMap: StyleMap = new StyleMap();
  isSelected: boolean;
  identifier: string;
  event: string;
  marker: google.maps.Marker[] = [];
  map: google.maps.Map;
  circuit_id;
  luminaire: Luminaires[] = [];
  valuesVirtualCircuit: ValuesVirtualCircuit;
  driver: Driver = new Driver({
    animate: true,
    allowClose: false,
    keyboardControl: false
  });

  constructor(
    private mapFuncAux: MapFuncAuxService,
    private toastr: Toastr,
    private currentProjectService: CurrentProjectService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private luminairesVirtualCircuitsService: VirtualcircuitsService
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      rpu: '',
      tariffType: '',
      identifier: '',
      circuitType: '',
      comment: ''
    });
    this.circuit_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getLuminairesVirtualCircuit();
  }

  getLuminairesVirtualCircuit() {
    this.luminairesVirtualCircuitsService.getluminairesVirtualCircuit(this.circuit_id).subscribe(
      response => {
        this.formGroup.patchValue({
          rpu: response.rpu,
          tariffType: response.tariff,
          identifier: response.identifier,
          comment: response.comment
        });
        this.valuesVirtualCircuit = response;

        const location = (response.locationcircuit == null ? response.luminaires[0].locationluminaire : response.locationcircuit);

        this.paintMap(location);

        if (response.locationcircuit != null) {
          this.paintCircuists(response);

        }

        this.paintLuminaire(response.luminaires.map(lums => new Luminaires(lums)));
        this.changeZoom();

      }
    )
  }

  changeZoom() {
    // console.log('latitude',this.map.getCenter().lat(), 'longitude', this.map.getCenter().lng());
    if (this.map.getZoom() >= 16) {
      this.luminairesVirtualCircuitsService.getMoreLuminaires(this.map.getCenter().lat(), this.map.getCenter().lng(), this.circuit_id)
        .subscribe(resultado => {
          const luminarias = [];
          for (let i = 0; i < resultado.length; i++) {
            // tslint:disable-next-line:max-line-length
            if (this.valuesVirtualCircuit.luminaires.filter(fil => fil.id === resultado[i].id).length === 0 && this.luminaire.filter(fil => fil.id === resultado[i].id).length === 0) {
              this.luminaire.push(resultado[i]);
              luminarias.push(resultado[i]);
            }
          }
          this.paintLuminaire(luminarias);
        });
    }
  }


  paintMap(location) {
    const mapProperties = {
      center: new google.maps.LatLng(location.latitude, location.longitude),
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    this.map.addListener('dragend', () => this.changeZoom());

    const project = this.currentProjectService.getCurrentProject();
    const service = new google.maps.places.PlacesService(this.map);
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(15);
      }
    });
  }


  paintCircuists(circuit) {
    circuit.paintMarker(this.map);
  }

  paintLuminaire(luminaires: Luminaires[]) {
    for (const items of luminaires) {
      items.paintMarkerLum(this.map);
      if (items.is_assignated === false) {
        items.marker.addListener('click', () => {
          this.addLuminaires(items);
        });
        items.marker.addListener('rightclick', () => {
          this.deleteLuminaires(items);
        });
      } else {
        items.marker.addListener('rightclick', () => {
          this.deleteLuminairesCircuits(items);
        });
      }
    }
  }

  private addLuminaires(luminaria: Luminaires) {
    if (this.addLuminairesCircuit.filter(fil => fil.id === luminaria.id).length === 0) {
      const lumAdd = {
        id: luminaria.id,
        lamppost_id: luminaria.lamppost_id,
        is_selected: true,
        is_assignated: luminaria.is_assignated
      }
      this.addLuminairesCircuit.push(lumAdd);
      luminaria.upadateIcon('selected');
    }
  }

  private deleteLuminairesCircuits(luminaria: Luminaires) {
    if (luminaria.is_assignated === true && this.addLuminairesCircuit.filter(fil => fil.id === luminaria.id).length === 0) {
      this.deleteLumCircuit(luminaria);
    }
  }

  private deleteLuminaires(luminaria: Luminaires) {
    if (this.addLuminairesCircuit.filter(fil => fil.id === luminaria.id).length === 1) {
      this.deleteLuminairesExt(luminaria);
    }
  }


  private deleteLuminairesExt(luminaria: Luminaires) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const strInfo = `
  <div>
    <div class='d-flex justify-content-between'>
      <input id='deleteLumExt' type='button' style='cursor:pointer;' value='Eliminar del Circuito' class='oe--button--info mr-1'>
    </div>
  </div>
  `;
    const infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340
    });
    infowindow.open(this.map, luminaria.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      const btnAddLumCircuit = document.getElementById('deleteLumExt');
      btnAddLumCircuit.addEventListener('click', () => {
        this.addLuminairesCircuit = this.addLuminairesCircuit.filter(fil => fil.id !== luminaria.id);
        luminaria.upadateIcon('normal');
        infowindow.close();
      });
    }, 60);
  }



  private deleteLumCircuit(luminaires: Luminaires) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const strInfo = `
    <div>
      <div class='d-flex justify-content-between'>
        <input id='deleteLumCircuit' type='button' style='cursor:pointer;' value='Eliminar del Circuito' class='oe--button--info mr-1'>
      </div>
    </div>
    `;
    const infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340
    });
    infowindow.open(this.map, luminaires.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      const btnAddLumCircuit = document.getElementById('deleteLumCircuit');
      btnAddLumCircuit.addEventListener('click', () => {
        const lumAdd = {
          id: luminaires.id,
          lamppost_id: luminaires.lamppost_id,
          is_selected: true,
          is_assignated: luminaires.is_assignated
        };
        this.addLuminairesCircuit.push(lumAdd);
        luminaires.marker.addListener('click', () => {
          this.addLumCircuit(luminaires);
        });
        luminaires.upadateIcon('normal');
        infowindow.close();
      });
    }, 60);
  }

  private addLumCircuit(luminarias: Luminaires) {
    if (luminarias.is_assignated === true && this.addLuminairesCircuit.filter(fil => fil.id === luminarias.id).length === 1) {
      this.addLuminairesCircuit = this.addLuminairesCircuit.filter(fil => fil.id !== luminarias.id);
      luminarias.upadateIcon('selected');
    }
  }

  registerLumToCircuit() {
    if (this.addLuminairesCircuit.length === 0) {
      this.modalService.open(this.ngModalInformation, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
        result => {
          // console.log(this.location.latitude, this.location.longitude, this.formGroupCircuit.get('is_VirtualCircuit').value);

        },
        reason => {
          // console.log(this.location.latitude, this.location.longitude, this.formGroupCircuit.get('is_VirtualCircuit').value);
        });
      return;
    }
    const insert = {
      circuit_id: this.circuit_id,
      valuesluminaires: this.addLuminairesCircuit
    };
    this.luminairesVirtualCircuitsService.registerLuminairesToCircuit(insert).subscribe();
    this.addLuminairesCircuit = [];
    this.lstInfowindows = [];
    this.toastr.success('Se Guardo Correctamente.');
    this.ngOnInit();
  }



  onBack() {
    this.router.navigate(['/u/virtual-circuits/']);
  }


  public changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

}
