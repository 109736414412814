import { Component, OnInit, OnDestroy, ViewChild  } from '@angular/core';
import { LamppostStatus, LumStatus } from '@app/models/luminiare-status';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LuminaireStatusService } from './luminaire-status.service';
import { DeleteMenu } from 'app/shared/components/map-delete-node.component';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { StyleMap } from '@app/models/styleMap';



@Component({
  selector: 'app-luminaire-status',
  templateUrl: './luminaire-status.component.html',
  styleUrls: ['./luminaire-status.component.scss'],
  providers: [MapFuncAuxService]
})
export class LuminaireStatusComponent implements OnInit, OnDestroy {

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  luminaires: LumStatus[] = [];
  lamppostsLums: LumStatus[] = [];
  lampposts: LamppostStatus[] = [];
  lstInfowindows = [];
  polygons: any[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  styleMap: StyleMap = new StyleMap();
  lumiaire_status = [
    {
      status: 1,
      icon: 'assets/img/svg/ic_municipality_status-1.svg',
      status_name: 'Municipalizada',
      status_name_2: 'Consesionada',
      is_Selected: false
    },
    {
      status: 2,
      icon: 'assets/img/svg/ic_municipality_status-2.svg',
      status_name: 'Municipalizada',
      status_name_2: 'No Consesionada',
      is_Selected: false
    },
    {
      status: 3,
      icon: 'assets/img/svg/ic_municipality_status-3.svg',
      status_name: 'No Municipalizada',
      status_name_2: 'Consesionada',
      is_Selected: false
    },
    {
      status: 4,
      icon: 'assets/img/svg/ic_municipality_status-4.svg',
      status_name: 'No Municipalizada',
      status_name_2: 'No Consesionada',
      is_Selected: false
    }
  ]

  constructor(private mapFuncAux: MapFuncAuxService, private toastr: Toastr, private service: LuminaireStatusService,
    private currentProjectService: CurrentProjectService) { }

  ngOnInit() {
    this.paintMap();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private paintMap() {
    const project = this.currentProjectService.getCurrentProject();
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    });
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: {
        editable: true,
        draggable: false,
        fillOpacity: 0.25
      }
    });
    google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
      const deleteMenu = new DeleteMenu();
      const polygonPath = polygon.getPath();


      this.addPointToForm(polygon);

      google.maps.event.addListener(polygon, 'rightclick', function (e) {
        // Check if click was on a vertex control point
        if (e.vertex == undefined) {
          return;
        }

        deleteMenu.open(this.map, polygon.getPath(), e.vertex);
      });

      google.maps.event.addListener(polygonPath, 'set_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'insert_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'remove_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'removeVertex', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygon, 'dragend', (_) => {
        this.addPointToForm(polygon);
      })
    });

    this.map.addListener('zoom_changed', () => this.changeZoom());
    this.map.addListener('dragend', () => {
      if (this.map.getZoom() > 15) {
        this.getluminairesStatus(this.map.getCenter().lat(), this.map.getCenter().lng());
      }
    });

    drawingManager.setMap(this.map);
    this.map.addListener('click', () => {
  
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    });
  }

  changeZoom() {
    if (this.map.getZoom() > 15) {
      this.getluminairesStatus(this.map.getCenter().lat(), this.map.getCenter().lng());
    } else {
      this.hideLums(this.luminaires);
      this.luminaires = this.luminaires.filter(lum => lum.isSelected);
    }
  }

  private addPointToForm(polygon: google.maps.Polygon) {
    if (this.isExistingPolygon(polygon)) {
      this.replacePolygon(polygon)
    } else {
      this.polygons.push(polygon);
      this.updatePolygon(polygon);
      google.maps.event.addListener(polygon, 'click', (_) => {

        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
      });
    }
  }

  private replacePolygon(polygon: any): void {
    const polygons = this.polygons;
    const existingPolygonIndex = polygons.findIndex(_polygon => _polygon.zIndex === polygon.zIndex);

    polygons[existingPolygonIndex] = polygon;
    this.updatePolygon(polygon);
  }

  private isExistingPolygon(polygon: any): boolean {
    const polygons = this.polygons;
    const existingPolygon = polygons.find(_polygon => _polygon.zIndex === polygon.zIndex);

    return existingPolygon ? true : false;
  }

  updatePolygon(polygon: any) {
    this.isWithinLuminaires(this.luminaires, polygon);
    for (const lamppost of this.lampposts) {
      this.isWithinLuminaires(lamppost.luminaires, polygon);
    }
    // for (const circuit of this.circuits) {
    //   for (const lamppost of circuit.lampposts) {
    //     this.isWithinLuminaires(lamppost.luminaires, polygon);
    //   }
    // }
    this.clearLuminairesOfProject(polygon);
  }

  isWithinLuminaires(luminaires: LumStatus[], polygon: any) {
    for (const luminaire of luminaires) {
      if (this.isWithin(polygon, luminaire)) {
        luminaire.isSelected = true;
        luminaire.upadeIcon('selected');
        luminaire.polygonID = polygon['zIndex'];
      }
    }
  }

  clearLuminairesOfProject(polygon: any) {
    this.clearLuminaires(this.luminaires, polygon);
    for (const lamppost of this.lampposts) {
      this.clearLuminaires(lamppost.luminaires, polygon);
    }
    // for (const circuit of this.circuits) {
    //   for (const lamppost of circuit.lampposts) {
    //     this.clearLuminaires(lamppost.luminaires, polygon);
    //   }
    // }
  }

  clearLuminaires(luminaires: LumStatus[], polygon: any) {
    for (const luminaire of luminaires) {
      if (luminaire.polygonID >= 0 && luminaire.polygonID == polygon['zIndex']) {
        if (!this.isWithin(polygon, luminaire)) {
          luminaire.isSelected = false;
          luminaire.upadeIcon('normal');
        }
      }
    }
  }

  isWithin(polygon: any, luminaire: LumStatus) {
    return google.maps.geometry.poly.containsLocation(luminaire.marker.getPosition(), polygon);
  }

  private getluminairesStatus(latitude, longitude) {
    this.service.getLuminaireStatus(latitude, longitude)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        let luminaires = response.luminaires.map(luminaire => new LumStatus(luminaire));
        const lumsSelected = this.luminaires.filter(luminaire => luminaire.isSelected);
        const lumsUnselected = this.luminaires.filter(luminaire => !luminaire.isSelected);

        luminaires = luminaires.filter(lum => !lumsSelected.some(_lum => _lum.id === lum.id));

        if (this.luminaires.length === 0) {
          this.addLuminaires(luminaires);
          this.luminaires = luminaires;
          return;
        }

        const iqualLums = lumsUnselected.filter(lum => luminaires.some(_lum => lum.id === lum.id));
        const diffLums = lumsUnselected.filter(lum => !iqualLums.some(_lum => _lum.id === lum.id));
        this.hideLums(diffLums);

        const newLums = luminaires.filter(lum => !lumsUnselected.some(_lum => _lum.id === lum.id));
        this.addLuminaires(newLums);

        this.luminaires = lumsSelected;
        this.luminaires = this.luminaires.concat(newLums, iqualLums);
        this.toastr.success('Carga terminada.');
      }, error => {
        this.toastr.singleError('Error al cargar luminarias');
      });
  }

  hideLums(luminaires: LumStatus[]) {
    luminaires.forEach(lum => {
      if (!lum.isSelected) {
        lum.setMap(null);
      }
    })
  }

  private addLuminaires(luminaires: LumStatus[]): void {
    for (const luminaire of luminaires) {
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        if (this.luminaireIsSelected(luminaire)) {
          luminaire.isSelected = false;
          luminaire.upadeIcon('normal');
        } else {
          luminaire.isSelected = true;
          luminaire.upadeIcon('selected');
        }
      });
    }
  }

  private luminaireIsSelected(luminaire: LumStatus) {
    return luminaire.event == 'selected' || luminaire.isSelected;
  }

  getSelectionLumTotal() {
    const luminairesSelected = this.getSelectionLum();
    return luminairesSelected.length;
  }

  getSelectionLum() {
    let luminairesSelected: LumStatus[] = [];
    luminairesSelected = this.luminaires.filter(_lum => _lum.event == 'selected' || _lum.isSelected);
    return luminairesSelected;
  }

  private clearProjectWattageSelect(): void {
    for (const status of this.lumiaire_status) {
      status.is_Selected = false;
    }
  }

  projectWattageSelect(lum_status: {
    status: number,
    icon: string,
    status_name: string,
    status_name_2: string,
    is_Selected: boolean
  }, ) {
    if (!this.getSelectionLumTotal()) {
      return this.toastr.singleError('Seleciona una luminaria.');
    }

    lum_status.is_Selected = !lum_status.is_Selected;
    const luminairesSelected = this.getSelectionLum();
    const lumSend = luminairesSelected.map(lum => { return lum.id });
    this.service.assignLuminaires(lumSend, lum_status.status)
      .subscribe((response: any) => {
        this.clearProjectWattageSelect();
        for (const luminaire of luminairesSelected) {

          luminaire.municipality_status = lum_status.status;
          luminaire.linkedSituation = 'assign';
          luminaire.isSelected = false;
          luminaire.upadeIcon('normal');
        }
        // const circuits = this.circuits.filter(_circuit => _circuit.isSelected);
        // for (const circuit of circuits) {
        //   circuit.isSelected = false;
        //   circuit.upadeIcon('normal');
        // }
        for (const polygon of this.polygons) {
          polygon.setMap(null);
        }
        this.toastr.success('Asignación completada correctamente.');
      }, error => {
        this.toastr.error(error);
      });
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }


}
