import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StyleMap } from '@app/models/styleMap';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressRoute4Me, IncidentsLuminairesRoute4Me, ResponseRoute4Me } from './incident-luminaires-route4-me';
import { IncidentsLuminairesRoute4MeService } from './incidents-luminaires-route4-me.service';

@Component({
  selector: 'app-incidents-luminaires-route4me',
  templateUrl: './incidents-luminaires-route4me.component.html',
  styleUrls: ['./incidents-luminaires-route4me.component.css'],
  providers: [MapFuncAuxService]
})

export class IncidentsLuminairesRoute4meComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('modalcreateroute') modalCreateRoute: any;
  incidentsLuminairesRoute4Me: IncidentsLuminairesRoute4Me[] = [];
  incidentsLuminairesRoute4Me_table: IncidentsLuminairesRoute4Me[] = [];
  // incidentsLuminairesRoute4Me_Selected: IncidentsLuminairesRoute4Me[] = [];
  // incidentsLuminairesRoute4Me_NoSelected: IncidentsLuminairesRoute4Me[] = [];
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  valuesForRouteForm: FormGroup;
  detail_routes: ResponseRoute4Me[];
  show_botton = false;
  show_map = false;
  lstInfowindows = [];
  active_save_modal_route = true;
  show_more_luminaires = false;
  is_show_all_luminaires = false;

  constructor(
    private fb: FormBuilder,
    private toastr: Toastr,
    private mapFuncAux: MapFuncAuxService,
    private modalService: NgbModal,
    private incidentsRoute4MeService: IncidentsLuminairesRoute4MeService,
    private currentProjectService: CurrentProjectService
  ) {
    this.valuesForRouteForm = fb.group({
      route_day: [''],
      start_date_: null,
      total_lums: 0,
      turn_mat_vesp: 0,
      count_cap: 0,
      work_type: 0,
      luminaires_detail: []
    });
  }



  ngOnInit() {
  }


  expandPoints() {
    this.show_map = true;
    this.is_show_all_luminaires = false;
    this.incidentsRoute4MeService.get_luminaires_incident_route4_me(this.valuesForRouteForm.value.work_type).subscribe(elements => {
      this.incidentsLuminairesRoute4Me = elements;
      this.paintMap({
        latitude: elements[0].latitude,
        longitude: elements[0].longitude
      });
      this.AssingMarkers(elements);
    }, error => {
      console.log(error);
      this.toastr.error(error.error.message);
    });
  }

  private paintMap(location) {
    const mapProperties = {
      center: new google.maps.LatLng(location.latitude, location.longitude),
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);

    const project = this.currentProjectService.getCurrentProject();
    const service = new google.maps.places.PlacesService(this.map);
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(15);
      }
    });
  }

  private Destroyer_OldPoints() {
    this.incidentsLuminairesRoute4Me.forEach(element => {
      element.marker.setMap(null);
    });
    this.incidentsLuminairesRoute4Me = [];
    // this.incidentsLuminairesRoute4Me_table = [];
  }

  private PaintMarker_Route(list_address_route: AddressRoute4Me[]) {
    let paint_depot = false;
    list_address_route.forEach(marker => {
      let svg = '';
      if (marker.is_depot === true) {
        if (paint_depot === false) {
          svg = `
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="warehouse" class="svg-inline--fa fa-warehouse fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 640 512">
            <path fill="currentColor" d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z">
            </path>
          </svg>
          `;
        }
        paint_depot = true;
      } else {
        svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
        <g fill="none" fill-rule="evenodd" transform="translate(11 9)">
          <path fill="red" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" d="M1.48662295,5.46992268 C3.38709836,2.19976804 6.92837705,-1.39891394e-14 10.9827787,-1.39891394e-14 C17.0452213,-1.39891394e-14 21.9596967,4.91677835 21.9596967,10.9820619 C21.9596967,12.5032088 21.6499836,13.9512887 21.091959,15.2688402 C20.5326721,16.5863918 19.7238115,17.7720619 18.7256066,18.7655928 C17.8937541,19.6503351 17.1476475,20.5850515 16.4770082,21.5402448 C12.0834098,27.8027191 10.9827787,31.9525515 10.9827787,31.9525515 C10.9827787,31.9525515 9.78747541,27.1894072 4.91519672,20.7553608 C4.39819672,20.0742139 3.84017213,19.407049 3.23868852,18.7655928 C2.56931148,18.0997809 1.98577049,17.3469201 1.50582787,16.5248711 C0.552344262,14.8974613 0.00586065574,13.0037629 0.00586065574,10.9819716 C0.00586065574,8.9730799 0.544680328,7.08957474 1.48662295,5.46992268 Z"/>
          <text x="5" y="10"
          font-family="Verdana"
          font-size="1">
            ${marker.reference_id}-${marker.index}
                </text>
        </g>
      </svg>
        `;
      }
      marker.marker = new google.maps.Marker({
        title: marker.reference_id,
        position: new google.maps.LatLng(marker.location.latitude, marker.location.longitude),
        icon: {
          url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
        },
        map: this.map
      });
    });
  }

  private Paint_Routes() {
    this.detail_routes.forEach(route => {
      const stroke_color = 'rgb(' + Math.round(Math.random() * 255) + ',' + Math.round(Math.random() * 255) + ',' + Math.round(Math.random() * 255) + ')';
      route.polyline = new google.maps.Polyline({
        icons: [
          {
            icon: {
              path: 'M 0,11 0,0 M 0,11 3,7 M -3, 7 0,11 z',
              strokeOpacity: 1,
              scale: 1,
            },
            offset: '0',
            repeat: '75px'
          }
        ],
        path: route.list_path.map(point => new google.maps.LatLng(point.latitude, point.longitude)),
        geodesic: true,
        strokeColor: stroke_color,
        strokeOpacity: 0.5,
        strokeWeight: 10,
        map: this.map,
        visible: true
      });
      route.color_polyline = stroke_color;
      

      this.PaintMarker_Route(route.list_address_route);
    });
  }

  Hidden_Show_Route(route_element: ResponseRoute4Me) {
    if (route_element.show_route === false) {
      route_element.list_address_route.forEach(address => { address.marker.setMap(null); });
      route_element.polyline.setMap(null);
    } else {
      route_element.list_address_route.forEach(address => { address.marker.setMap(this.map); });
      route_element.polyline.setMap(this.map);
    }

  }


  optimizar_ruta() {
    this.valuesForRouteForm.get('total_lums').disable();
    this.show_botton = false;
    this.modalService.open(this.modalCreateRoute, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
      result => {
        this.active_save_modal_route = false;
        if (result === 'Ok click') {
          this.Destroyer_OldPoints();
          this.valuesForRouteForm.value.route_day = this.valuesForRouteForm.get('start_date_').value === null || this.valuesForRouteForm.get('start_date_').value === undefined ? '' : new Date((this.valuesForRouteForm.get('start_date_').value.month + '/' + this.valuesForRouteForm.get('start_date_').value.day + '/' + this.valuesForRouteForm.get('start_date_').value.year));
          this.incidentsRoute4MeService.CreateRouteLuminaires_route4_me({
            route_day: this.valuesForRouteForm.value.route_day,
            turn_mat_vesp: this.valuesForRouteForm.value.turn_mat_vesp,
            // work_type: this.valuesForRouteForm.value.work_type,
            count_cap: this.valuesForRouteForm.value.count_cap,
            luminaires_detail: [...this.incidentsLuminairesRoute4Me_table.map(element => element.id)]
          }).subscribe((response: ResponseRoute4Me[]) => {
            
            this.detail_routes = response;
            this.Paint_Routes();
            this.toastr.success('La ruta se creó exitosamente');
          }, error => this.toastr.error(error));

        }
      },
      reason => {
        this.valuesForRouteForm.get('total_lums').enable();
        this.show_botton = true;
        this.valuesForRouteForm.patchValue({
          route_day: [''],
          start_date_: null,
          turn_mat_vesp: 0,
          newer_oldest: 0,
        });
      });
  }

  Validate_Values_Modal() {
    if (Number(this.valuesForRouteForm.get('count_cap').value) > 0 && (this.valuesForRouteForm.get('start_date_').value != null && this.valuesForRouteForm.get('start_date_').value !== undefined) && Number(this.valuesForRouteForm.get('total_lums').value) > 0 && Number(this.valuesForRouteForm.get('turn_mat_vesp').value) > 0) {
      this.active_save_modal_route = true;
    } else {
      this.active_save_modal_route = false;
    }
  }


  private AssingMarkers(elemnts_luminaires: IncidentsLuminairesRoute4Me[]) {
    this.incidentsLuminairesRoute4Me.slice(0, this.valuesForRouteForm.value.total_lums).forEach(luminaire => luminaire.is_selected = true);
    this.incidentsLuminairesRoute4Me_table = this.incidentsLuminairesRoute4Me.filter(element => element.is_selected === true);
    this.incidentsLuminairesRoute4Me.forEach(element => {
      element.svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
      <g fill="none" fill-rule="evenodd" transform="translate(11 9)">
        <path fill="${element.is_selected ? 'red' : 'gray'}" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" d="M1.48662295,5.46992268 C3.38709836,2.19976804 6.92837705,-1.39891394e-14 10.9827787,-1.39891394e-14 C17.0452213,-1.39891394e-14 21.9596967,4.91677835 21.9596967,10.9820619 C21.9596967,12.5032088 21.6499836,13.9512887 21.091959,15.2688402 C20.5326721,16.5863918 19.7238115,17.7720619 18.7256066,18.7655928 C17.8937541,19.6503351 17.1476475,20.5850515 16.4770082,21.5402448 C12.0834098,27.8027191 10.9827787,31.9525515 10.9827787,31.9525515 C10.9827787,31.9525515 9.78747541,27.1894072 4.91519672,20.7553608 C4.39819672,20.0742139 3.84017213,19.407049 3.23868852,18.7655928 C2.56931148,18.0997809 1.98577049,17.3469201 1.50582787,16.5248711 C0.552344262,14.8974613 0.00586065574,13.0037629 0.00586065574,10.9819716 C0.00586065574,8.9730799 0.544680328,7.08957474 1.48662295,5.46992268 Z"/>
        <path fill="#7E2D25" d="M10.9828689,5.13697165 C14.2090246,5.13697165 16.8237787,7.75448454 16.8237787,10.9821521 C16.8237787,14.2098196 14.2090246,16.825799 10.9828689,16.825799 C7.75671311,16.825799 5.14042623,14.2098196 5.14042623,10.9821521 C5.14042623,7.75448454 7.75680328,5.13697165 10.9828689,5.13697165 Z"/>
      </g>
    </svg>
      `;

      element.marker = new google.maps.Marker({
        title: element.reference_id,
        position: new google.maps.LatLng(element.latitude, element.longitude),
        icon: {
          url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(element.svg),
        },
        map: element.is_selected ? this.map : null
      });
      element.marker.addListener('click', () => {
        this.showInfoWindowLuminaire_Selected(element);
      });
    });
  }

  private AssingMarker(luminaire: IncidentsLuminairesRoute4Me, is_delete: boolean) {
    luminaire.marker.setMap(null);
    luminaire.svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
      <g fill="none" fill-rule="evenodd" transform="translate(11 9)">
        <path fill="${is_delete ? 'gray' : 'red'}" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" d="M1.48662295,5.46992268 C3.38709836,2.19976804 6.92837705,-1.39891394e-14 10.9827787,-1.39891394e-14 C17.0452213,-1.39891394e-14 21.9596967,4.91677835 21.9596967,10.9820619 C21.9596967,12.5032088 21.6499836,13.9512887 21.091959,15.2688402 C20.5326721,16.5863918 19.7238115,17.7720619 18.7256066,18.7655928 C17.8937541,19.6503351 17.1476475,20.5850515 16.4770082,21.5402448 C12.0834098,27.8027191 10.9827787,31.9525515 10.9827787,31.9525515 C10.9827787,31.9525515 9.78747541,27.1894072 4.91519672,20.7553608 C4.39819672,20.0742139 3.84017213,19.407049 3.23868852,18.7655928 C2.56931148,18.0997809 1.98577049,17.3469201 1.50582787,16.5248711 C0.552344262,14.8974613 0.00586065574,13.0037629 0.00586065574,10.9819716 C0.00586065574,8.9730799 0.544680328,7.08957474 1.48662295,5.46992268 Z"/>
        <path fill="#7E2D25" d="M10.9828689,5.13697165 C14.2090246,5.13697165 16.8237787,7.75448454 16.8237787,10.9821521 C16.8237787,14.2098196 14.2090246,16.825799 10.9828689,16.825799 C7.75671311,16.825799 5.14042623,14.2098196 5.14042623,10.9821521 C5.14042623,7.75448454 7.75680328,5.13697165 10.9828689,5.13697165 Z"/>
      </g>
    </svg>
      `;

    luminaire.marker = new google.maps.Marker({
      title: luminaire.reference_id,
      position: new google.maps.LatLng(luminaire.latitude, luminaire.longitude),
      icon: {
        url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(luminaire.svg),
      },
      map: this.map
    });
    if (is_delete) {
      luminaire.marker.addListener('click', () => {
        this.showInfoWindowLuminaire_NoSelected(luminaire);
      });
    } else {
      luminaire.marker.addListener('click', () => {
        this.showInfoWindowLuminaire_Selected(luminaire);
      });
    }
  }

  // elimina luminarias
  private showInfoWindowLuminaire_Selected(luminaire: IncidentsLuminairesRoute4Me) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.lstInfowindows = [];
    const strInfo = `
    <div>
      <div class="d-flex justify-content-between">
        <input id="btnSelected" type="button" style="cursor:pointer;" value="Eliminar" class="oe--button--info">
      </div>
    </div>
    `;
    const infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340
    });
    infowindow.open(this.map, luminaire.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      const btnSelected = document.getElementById('btnSelected');
      const btnDesSelected = document.getElementById('btnDesselected');
      if (btnSelected) {
        btnSelected.addEventListener('click', () => this.RemoveLuminaire(luminaire));
      }
    }, 500);
  }


  // agregar luminarias
  private showInfoWindowLuminaire_NoSelected(luminaire: IncidentsLuminairesRoute4Me) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.lstInfowindows = [];
    const strInfo = `
    <div>
      <div class="d-flex justify-content-between">
        <input id="btnDelete" type="button" style="cursor:pointer;" value="Agregar" class="oe--button--info">
      </div>
    </div>
    `;
    const infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340
    });
    infowindow.open(this.map, luminaire.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      const btnSelected = document.getElementById('btnDelete');
      const btnDesSelected = document.getElementById('btnDelete');
      if (btnSelected) {
        btnSelected.addEventListener('click', () => this.AddLuminaire(luminaire));
      }
    }, 500);
  }

  private RemoveLuminaire(luminaire: IncidentsLuminairesRoute4Me) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    luminaire.is_selected = false;
    this.incidentsLuminairesRoute4Me_table = this.incidentsLuminairesRoute4Me.filter(element => element.is_selected === true);
    if (this.is_show_all_luminaires) {
      this.AssingMarker(luminaire, true);
    } else {
      luminaire.marker.setMap(null);
    }
  }

  private AddLuminaire(luminaire: IncidentsLuminairesRoute4Me) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    luminaire.is_selected = true;
    this.incidentsLuminairesRoute4Me_table = this.incidentsLuminairesRoute4Me.filter(element => element.is_selected === true);
    this.AssingMarker(luminaire, false);
  }


  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  show_hidden_all_points() {
    if (this.is_show_all_luminaires) {
      this.is_show_all_luminaires = false;
      this.incidentsLuminairesRoute4Me.filter(element => element.is_selected === false).map(element_lum => element_lum.marker.setMap(null));
    } else {
      this.is_show_all_luminaires = true;
      this.incidentsLuminairesRoute4Me.filter(element => element.is_selected === false).map(element_lum => this.AssingMarker(element_lum, true));
    }
  }

  validate_ShowBotton() {
    if (this.valuesForRouteForm.get('total_lums').value > 1) {
      this.show_botton = true;
      return;
    }
    this.show_botton = false;
    this.toastr.singleError('El valor del total de luminarias debe de ser por lo menos 2');
  }


  private Reset_Form_Route() {
    this.valuesForRouteForm.patchValue({
      total_lums: 0,
      route_day: [''],
      start_date_: null,
      turn_mat_vesp: 0,
      newer_oldest: 0,
    });
  }



}
