export interface IGenericTable<T> {
    from: number,
    index: number,
    size: number,
    count: number,
    pages: number,
    elements: T[]
    previous: number,
    next: number
}
export class GenericTable <T> implements IGenericTable<T> {
    from: number;
    index: number;
    size: number;
    count: number;
    pages: number;
    elements: T[];
    previous: number;
    next: number;

    constructor(attrs) {
        this.count = attrs.count;
        this.elements = attrs.elements;
        this.from = attrs.from;
        this.index = attrs.index;
        this.next = attrs.next;
        this.previous = attrs.previous;
        this.size = attrs.size
    }
}
