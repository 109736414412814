import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toastr } from '@shared/toastr/toastr.service';
import { ChargedValuesService } from '../charged-values.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialPayload } from '@models/material-payload';
import { ProjectMaterial } from '@models/project_material';
import { MaterialCategoryResponse } from '@models/material_category-response';
import { ProjectMaterialPayload } from '@models/project_material-payload';
import { TechnologyEnum } from '@models/technology-enum';
import { LuminaireEnum } from '@models/luminaire-enum';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  providers: [ChargedValuesService]
})
export class CategoryComponent implements OnInit {
  technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));
  luminariaTypeEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  @ViewChild('contentMaterialModal') materialModal: any;
  currentId: string;
  project_materials: ProjectMaterial[] = [];
  materialForm: FormGroup;
  emptyMaterial: ProjectMaterial;
  projectName = 'Material';
  isLuminaire = false;

  constructor(
    private chargedValuesService: ChargedValuesService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: Toastr,
    private router: Router,
  ) {
    this.materialForm = this.fb.group(
      {
        id: [null],
        name: [null, Validators.required],
        minimum: [null, Validators.required],
        image_url: [null],
        is_active: [null],
        image: [null],
        stock: [null],
        type: [null],
        technology: [null],
      }
    );
    this.emptyMaterial = new ProjectMaterial({ material: {}, is_active: true });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.currentId = params.get('id');
      this.getCategory();
    });
  }

  getCategory() {
    this.chargedValuesService.getCategory(this.currentId)
      .subscribe((materialCategoryResponse: MaterialCategoryResponse) => {
        this.project_materials = materialCategoryResponse.material_category.project_materials.map(project => new ProjectMaterial(project));
        this.project_materials = this.project_materials.sort((a, b) => a.material.name.localeCompare(b.material.name));
        this.projectName = materialCategoryResponse.material_category.name;
      }, error => {
        this.toastr.error(error);
      })
  }

  showMaterialModal(projectMaterial: ProjectMaterial) {
    this.setForm(projectMaterial);
    if (projectMaterial.material.material_attributes) {
      this.isLuminaire = true;
    }
    this.modalService.open(this.materialModal, { size: 'lg' }).result.then(result => {
    }, reason => { });
  }

  createMaterial() {
    if (this.materialForm.valid && this.materialForm.get('minimum').value >= 0) {
      const requestPayload = new MaterialPayload(this.materialForm);
      if (!this.validateLuminaire())
        return this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
      this.chargedValuesService.createMaterial(requestPayload, this.currentId)
        .subscribe(result => {
          window.location.reload();
          this.toastr.success('Material creado.');
        }, error => {
          this.toastr.error(error);
        })
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  private validateLuminaire() {
    let flag = true;
    if (this.projectName.toLocaleLowerCase() != 'luminarias' && this.projectName.toLocaleLowerCase() != 'lámparas y balastros para tecnología hid')
      return flag;

    let type = this.materialForm.get('type').value;
    let technology = this.materialForm.get('technology').value;
    if (type < 0 || type == undefined || technology < 0 || technology == undefined) {
      flag = false;
      this.materialForm.controls.type.setErrors([Validators.required]);
      this.materialForm.controls.technology.setErrors([Validators.required]);
      this.materialForm.controls.type.markAsTouched();
      this.materialForm.controls.technology.markAsTouched();
    }

    return flag;
  }

  updateMaterial() {
    if (this.materialForm.valid && this.materialForm.get('minimum').value >= 0) {
      const requestPayload = new ProjectMaterialPayload(this.materialForm);
      if (!this.validateLuminaire())
        return this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
      this.chargedValuesService.updateMaterial(requestPayload, this.currentId)
        .subscribe(result => {
          this.toastr.success('Material actualizado.');
          let _material = this.project_materials.find(mat__ => mat__.id == requestPayload.id);
          _material.material.name = requestPayload.name;
          _material.troop_minimum = requestPayload.minimum;
          _material.is_active = requestPayload.is_active;
          _material.material.image_url = this.materialForm.get('image_url').value;
          let material_attributes = {
            luminaire_type: this.materialForm.get('type').value,
            technology: this.materialForm.get('technology').value
          }
          _material.material.material_attributes = material_attributes;
          _material.is_active = requestPayload.is_active;
          this.modalService.dismissAll();
        }, error => {
          this.toastr.error(error);
        })
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
  }

  setForm(projectMaterial: ProjectMaterial) {
    this.materialForm.patchValue({
      id: projectMaterial.id,
      name: projectMaterial.material.name,
      minimum: projectMaterial.troop_minimum,
      is_active: projectMaterial.is_active,
      image_url: projectMaterial.material.image_url,
      type: projectMaterial.material.material_attributes ? projectMaterial.material.material_attributes.luminaire_type : null,
      technology: projectMaterial.material.material_attributes ? projectMaterial.material.material_attributes.technology : null
    });
  }

  setImagePreview(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.materialForm.patchValue({ image_url: reader.result })
        this.materialForm.patchValue({ image: file })
      };

      reader.readAsDataURL(file);
    }
  }

  onChange(event: boolean, projectMaterial: ProjectMaterial) {
    projectMaterial.is_active = event;
    this.setForm(projectMaterial);
    this.updateMaterial();
  }

}
