
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportedElements } from 'app/models/reported_elements';
import { Toastr } from 'app/shared/toastr/toastr.service';
import * as moment from 'moment';



@Component({
  selector: 'app-incidents-table',
  templateUrl: './incidents-table.component.html',
  styleUrls: ['./incidents-table.component.scss']
})
export class IncidentsTableComponent implements OnInit {
  @Input() reported_elements: ReportedElements;
  @Input() manualSelection: boolean = false;
  @Output() selectedCircuit: EventEmitter<any> = new EventEmitter<any>();
  public isCollapsed = true;
  selectUnit = false;
  selectAll = false;
  timeInHours;
  reported: ReportedElements;
  countDown;
  element
  subscription: Object = null;
  observableData: number;


  constructor(
    private toastr: Toastr
  ) { }

  ngOnInit() {
    // for(let circuit of this.reported_elements.circuits){
    //   for(let lamppost of circuit.lampposts){
    //     for (let luminaire of lamppost.luminaires){
    //       this.timeCountdown(luminaire.deadlineDate);
    //     }
    //   }
    // }
    // for(let lamppost of this.reported_elements.lampposts){
    //   for(let luminaire of lamppost.luminaires){
    //       let newDate = new Date();
    //   }
    // }
    // for(let luminaire of this.reported_elements.luminaires){
    //   let newDate = new Date();

    // }

  }

  //  timeCountdown(dateFromDatabase) {
  //   let newDate = new Date();


  //    let Difference_In_Time = dateFromDatabase.getTime() - newDate.getTime();
  //   var duration: any = moment.duration(Difference_In_Time , 'milliseconds');
  //   this.countDown = duration.days() + ' days ' + duration.hours() + ':' + duration.minutes();
  //   var interval = 1000;
  //   this.timeInHours = Difference_In_Time / 1000 / 3600;
  //    setInterval(() =>{
  //     duration = moment.duration(duration -  interval, 'milliseconds');
  //     this.countDown = duration.days() + ' days ' + duration.hours() + ':' + duration.minutes();
  //     console.log(this.countDown);
  //  },  interval);
  //  }

  clickSelectAll(event) {
    if (this.checkAllStatus()) {
      for (let circuit of this.reported_elements.circuits) {
        circuit.isSelected = !this.selectAll;
        for (let lamppost of circuit.lampposts) {
          for (let luminaire of lamppost.luminaires) {
            luminaire.isSelected = !this.selectAll;
          }
        }
      }
      for (let lamppost of this.reported_elements.lampposts) {
        for (let luminaire of lamppost.luminaires) {
          luminaire.isSelected = !this.selectAll;
        }
      }
      for (let luminaire of this.reported_elements.luminaires) {
        luminaire.isSelected = !this.selectAll;
      }
    } else {
      this.toastr.singleError('Existen luminarias que ya están en proceso de reparación.');
      window.setTimeout(() => {
        this.selectAll = false;
      }, 0);
    }
  }

  clickSelectCircuit(circuit: any) {
    let status = false;
    for (let lamppost of circuit.lampposts) {
      if (status)
        break;
      for (let luminaire of lamppost.luminaires) {
        if (luminaire.status == 1 || luminaire.status == 3 || luminaire.status == 4) {
          status = true;
          break;
        }
      }
    }

    if (status) {
      window.setTimeout(() => {
        circuit.isSelected = false;
      }, 0);
      this.toastr.singleError('Existen luminarias que ya están en proceso de reparación.');
    } else {
      for (let lamppost of circuit.lampposts) {
        for (let luminaire of lamppost.luminaires) {
          luminaire.isSelected = !circuit.isSelected;
        }
      }
    }

  }

  private checkAllStatus() {
    let status = true;
    for (let circuit of this.reported_elements.circuits) {
      if (status)
        break;
      for (let lamppost of circuit.lampposts) {
        if (status)
          break;
        for (let luminaire of lamppost.luminaires) {
          if (luminaire.status == 1 || luminaire.status == 3 || luminaire.status == 4) {
            status = true;
            break;
          }
        }
      }
    }
    return !status;
  }

  private checkCircuitStatus(circuit: any) {
    let status = false;
    if (circuit.status == 0 || circuit.status == 2) {
      status = true;
    }
    return status
  }

  clickLumofCircuit(circuit: any, luminaire: any) {
    this.checkStatus(luminaire);
    circuit.isSelected = false;
  }

  private checkStatus(luminaire: any) {

    if (luminaire.status == 0 || luminaire.status == 2) {
    } else {
      window.setTimeout(() => {
        luminaire.isSelected = false;
      }, 0);
      this.toastr.singleError('La luminaria ya se encuentra en poceso de reparación.');
    }
  }

  seeOnMap(circuit: any) {
    this.selectedCircuit.emit(circuit);
  }


  formatFolio(str: string) {
    if (str.indexOf(',') >= 0) {
      str = str.split(',').join(', ')
    }
    return str;
  }
  getClass(value: number) {
    let classes = {
      'status-0': value == 0,
      'status-1': value == 1,
      'status-2': value == 2,
      'status-3': value == 3,
      'status-4': value == 4,
      'status-5': value == 5,
      'status-6': value == 6,

    }
    return classes
  }

}
