import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { GetCircuitResponse } from '@models/get-circuit-response';
import { Circuit } from '@models/circuit';
import { CircuitPayload } from '@models/circuit-payload';
import { EditSubstationPayload } from './edit-substation/edit-substation-payload';
import { EditMeterPayload } from './edit-meter-payload';
import { EditWiringPayload } from './edit-wiring/edit-wiring-payload';
import { EditCabinetPayload } from './edit-cabinet/edit-cabinet-payload';
import { Wiring } from '@app/models/wiring';
import { Substation } from '@app/models/substation';
import { Measurements } from '@app/models/measurements';

@Injectable()

export class EditCircuitService {
  constructor(private httpClient: HttpClient) {}

  getCircuit(circuit_id: string): Observable<GetCircuitResponse> {
    return this.httpClient.get<GetCircuitResponse>(
      `${environment.SERVER_URL}/circuits/${circuit_id}`
    );
  }

  getNewCircuit(id: string) {
    const httpParams = new HttpParams()
    .set('id', id)
    return this.httpClient.get<Circuit>(
      `${environment.SERVER_CONFIG}/api/Circuit/GetCircuit`, {params: httpParams}
    );
  }

  editCircuit(circuit: Circuit, circuitPayload: CircuitPayload) {
    console.log(circuit);
    console.log(circuitPayload);
    // return this.httpClient.put(`${environment.SERVER_URL}/circuits/${circuit.id}`, circuitPayload)
  }

  editCircuitOff(circuit: Circuit, circuitPayload: CircuitPayload): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/circuits/${circuit.id}`,
      circuitPayload.toJson()
    );
  }

  editSubstation(circuit: Circuit, substationPayload: EditSubstationPayload): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/circuits/${circuit.id}/substations/${circuit.substation.id}`,
      substationPayload.toFormData()
    );
  }

  editMeter(circuit: Circuit, meterPayload: EditMeterPayload): Observable<any> {

    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/circuits/${circuit.id}/meters/${circuit.meter.id}`,
      meterPayload.toFormData()
    );
  }

  // editWiring(circuit: Circuit, wiringPayload: EditWiringPayload): Observable<any> {
  //   return this.httpClient.put<any>(
  //     `${environment.SERVER_URL}/circuits/${circuit.id}/wirings/${circuit.wiring.id}`,
  //     wiringPayload.toJson()
  //   );
  // }

  editMeterNew(file: FormData) {
    return this.httpClient.post(`${environment.SERVER_CONFIG}/api/Circuit/UpdateMeter`, file);
  }

  editCabinetNew(file: FormData) {
    return this.httpClient.post(`${environment.SERVER_CONFIG}/api/Circuit/UpdateCabinet`, file);
  }

  editWiringNew(wiring: Wiring): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_CONFIG}/api/Circuit/UpdateWiring`, wiring
    );
  }

  editSubstationNew(file: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_CONFIG}/api/Circuit/UpdateSubstation`, file
    );
  }

  editCircuitNew(circuit): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_CONFIG}/api/Circuit/UpdateCircuit`, circuit
    );
  }


  editCabinet(circuit: Circuit, cabinetPayload: EditCabinetPayload): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/circuits/${circuit.id}/cabinets/${circuit.cabinet.id}`,
      cabinetPayload.toFormData()
    );
  }

  editMeasurements(measurements: Measurements): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_CONFIG}/api/Circuit/UpdateMeasurements`, measurements
    );
  }

  getDevices(): Observable<any> {
    return this.httpClient.get<Circuit>(
      `${environment.SERVER_SMART}/api/SmartDevices/SmartDevices`,
    );
  }
}
