import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Requisition } from '@models/requisition';
import { InventoryService } from '@pages/inventory/inventory.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toastr } from '@shared/toastr/toastr.service';
import { RequisitionService } from '../requisition.service';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html'
})
export class PendingComponent implements OnInit {
  action: string = '';
  @ViewChild('contentElement') contentElement: any;
  requisitions: Requisition[];
  currentRequisition: Requisition;

  constructor(
    private requisitionService: RequisitionService,
    private inventoryService: InventoryService,
    private modalService: NgbModal,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.requisitionService.getRequisitionsReceived()
      .subscribe((rq:Requisition[]) => {
        this.requisitions = rq;
      }, error => {
        this.toastr.error(error);
      })
  }

  showModalSolicitud(action: string, requisition: Requisition){
    this.action = action;
    let status = action == 'aceptar' ? 1 : 2;
    this.currentRequisition = requisition;
    this.modalService.open(this.contentElement).result.then(result => {
      this.inventoryService.updateRequisition(requisition, status).subscribe(response => {
        this.toastr.success(`La solicitid fue ${ action == 'aceptar' ? 'aceptada' : 'rechazada' }.`);
        this.requisitions = this.requisitions.filter( req => req.id != requisition.id);
        window.location.reload();
      }, error => {
        this.toastr.error(error);
      });
    }, reason => {});
  }

}
