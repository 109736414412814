export class TaskTypeTable implements ITaskTypeTable {
    id: string;
    reference_id: string;
    type: string;
    sector: string;
    created_at: Date;
    last_interaction_at: Date;
    comment: string;
    deadline: Date;
    is_finished: boolean;
    troop: string;
    troopName: string;


    constructor(attrs) {
        this.id = attrs.id;
        this.reference_id = attrs.reference_id;
        this.type = attrs.type;
        this.sector = attrs.sector;
        this.created_at = new Date(attrs.created_at);
        this.last_interaction_at = new Date(attrs.last_interaction_at);
        this.comment = attrs.comment;
        this.deadline = new Date(attrs.deadline); // fecha de entrega
        this.is_finished = attrs.is_finished;
        this.troop = attrs.troop;
        this.troopName = attrs.troopName;
    }

}

export interface ITaskTypeTable {
    id: string,
    reference_id: string;
    type: string;
    sector: string,
    created_at: Date,
    last_interaction_at: Date;
    comment: string,
    deadline: Date,
    is_finished: boolean,
    troop: string;
    troopName: string;
}
