import { ProjectWattage } from './project_wattage';

export class Location {
    latitude: number;
    longitude: number;

    constructor(attrs) {
        this.latitude = attrs.latitude,
            this.longitude = attrs.longitude
    }
}

export class PowerWattagesResources {
    circuits: CircuitWattage[];
    lampposts: LamppostWattage[];
    luminaires: LuminaireWattages[];

    constructor(attrs) {
        this.circuits = attrs.circuits;
        this.lampposts = attrs.lampposts;
        this.luminaires = attrs.luminaires;
    }
}

export class MarkerColorWattage {
    fillColor: string;
    fillColor2: string;
    path: string;
    path2: string;
    stroke: number;
}

export class LuminaireWattages {
    id: string;
    reference_id: string;
    location: Location;
    progress: number;
    marker_color: MarkerColorWattage;
    // marker_color: string;
    linkedSituation: string;
    event: string;
    isSelected: Boolean;
    marker: google.maps.Marker;
    polygonID: number;
    project_wattage: ProjectWattage;
    situation: string;
    svg: string;


    readonly markerIcons = {
        linked: {
            hover: 'assets/img/svg/ic_link_hover.svg',
            normal: 'assets/img/svg/link.svg',
            selected: 'assets/img/svg/lam_selected.svg',
            circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
        },
        single: {
            normal: 'assets/img/svg/normal.svg',
            selected: 'assets/img/svg/selected-blue.svg'
        },
        assign: {
            selected: 'assets/img/svg/ic_install_selected-',
            circuitShowing: 'assets/img/svg/ic_install_linked-',
            normal: 'assets/img/svg/ic_install_pin-',
            hover: 'assets/img/svg/ic_install_hover-',
        },
        citizen: {
            normal: 'assets/img/svg/ic_single_normal.svg',
            selected: 'assets/img/svg/link_selected.svg'
        }
    }

    constructor(attrs: any, linkedSituation: string = 'single', event: string = 'normal') {
        this.id = attrs.id;
        this.reference_id = attrs.reference_id;
        this.location = new Location(attrs.location);
        this.progress = attrs.progress;
        this.svg = attrs.svg;
        this.situation = attrs.situation;
        this.marker_color = attrs.marker_color;
        this.linkedSituation = attrs.project_wattage ? 'assign' : attrs.situation;
        this.event = event;
        this.isSelected = false;
    }


    // get_icon(): object {
    //     return this.linkedSituation == 'assign'
    //         ?
    //         // tslint:disable-next-line:max-line-length
    //         {
    //             url: `${this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.marker_color}.svg`,
    //             scaledSize: new google.maps.Size(15, 15)
    //         }
    //         :
    //         {
    //             url: this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
    //         };
    // }

    // updateIcon(event: string): void {
    //     this.event = event;
    //     this.get_icon();
    // }
    // private getIconPowerWattage(): string {
    //    return `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    //             <g>
    //               <path stroke="blue" stroke-opacity="12" stroke-width="20" fill="${this.marker_color.fillColor}" d="${this.marker_color.path}" > </path>
    //               <path fill="${this.marker_color.fillColor2}" d="${this.marker_color.path2}"> </path>
    //             </g>
    //           </svg>`;
    // }




    updateIcon(event: string): void {
        let svg = ``;
        switch (event) {
            case 'selected':
                if (this.situation === 'NORMAL') {
                    svg = `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> 
                                <g>
                                    <path stroke="blue" stroke-opacity="5" stroke-width="7" fill="${this.marker_color.fillColor}" d="${this.marker_color.path}"> </path>
                                    <path fill="${this.marker_color.fillColor2}" d="${this.marker_color.path2}"> </path>
                                </g>
                            </svg>`;
                } else if (this.situation === 'SM') {
                    svg = `<svg xmlns='http://www.w3.org/2000/svg' width='40px' height='40px' viewBox='0 0 48 48'>
                                <g fill='none' fill-rule='evenodd' transform='translate(0 0)'>
                                <path fill='#004DFF' stroke='#FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='${this.marker_color.path}'/>
                                <g transform='translate(2.87 2.866)'>
                                    <polygon points='0 0 16.261 0 16.261 16.239 0 16.239'/>
                                    <path fill='#FFF' d='${this.marker_color.path2}'/>
                                </g>
                                </g>
                            </svg>`
                } else if (this.situation === 'SMP') {
                    svg = `<svg height='40px' width='40px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43'>
                                <g fill='none' fill-rule='evenodd' transform='tranlate(0 0)'>
                                    <path fill='#004DFF' d='${this.marker_color.path}'> </path>
                                    <path fill='#7E2D25' d='${this.marker_color.path2}'> </path>
                                </g>
                            </svg>`;
                }
                this.marker.setIcon('data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg));
                break;
            case 'normal':
                this.marker.setIcon('data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.svg));
                break;
            case 'normal-update':
                this.svg = `<svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                <g>
                                    <path stroke="black" stroke-opacity="5" stroke-width="2" fill="${this.marker_color.fillColor}" d="${this.marker_color.path}"> </path>     
                                    <path fill="${this.marker_color.fillColor2}" d="${this.marker_color.path2}"> </path>
                                </g>
                            </svg>`;
                this.marker.setIcon('data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.svg));

                break;
        }

    }

     paintMarker_(map: any) {
        const latitude = this.location.latitude;
        const longitude = this.location.longitude;
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.reference_id,
            position: new google.maps.LatLng(latitude, longitude),
            icon: {
                url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.svg),
                // scaledSize: new google.maps.Size(30, 30)
            },
            map: map
        });
    }

    // Se cambio por paintMarker_()
    // paintMarker(map: any): void {
    //     const latitude = this.location.latitude;
    //     const longitude = this.location.longitude;
    //     this.event = this.isSelected ? 'selected' : 'normal';
    //     this.marker = new google.maps.Marker({
    //         title: this.reference_id,
    //         position: new google.maps.LatLng(latitude, longitude),
    //         icon: this.get_icon(),
    //         map: map
    //     });
    // }

    hideMarker(): void {
        this.marker.setMap(null);
    }

    setMap(map: any): void {
        this.marker.setMap(map);
    }
}

export class LuminaireWattage {
    id: string;
    reference_id: string;
    location: Location;
    progress: number;
    marker_color: string
    linkedSituation: string;
    event: string;
    isSelected: Boolean;
    marker: google.maps.Marker;
    polygonID: number;
    project_wattage: ProjectWattage;


    readonly markerIcons = {
        linked: {
            hover: 'assets/img/svg/ic_link_hover.svg',
            normal: 'assets/img/svg/link.svg',
            selected: 'assets/img/svg/lam_selected.svg',
            circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
        },
        single: {
            normal: 'assets/img/svg/normal.svg',
            selected: 'assets/img/svg/selected-blue.svg'
        },
        assign: {
            selected: 'assets/img/svg/ic_install_selected-',
            circuitShowing: 'assets/img/svg/ic_install_linked-',
            normal: 'assets/img/svg/ic_install_pin-',
            hover: 'assets/img/svg/ic_install_hover-',
        },
        citizen: {
            normal: 'assets/img/svg/ic_single_normal.svg',
            selected: 'assets/img/svg/link_selected.svg'
        }
    }

    constructor(attrs: any, linkedSituation: string = 'single', event: string = 'normal') {
        this.id = attrs.id;
        this.reference_id = attrs.reference_id;
        this.location = new Location(attrs.location);
        this.progress = attrs.progress;
        this.marker_color = attrs.marker_color;
        this.linkedSituation = attrs.project_wattage ? 'assign' : linkedSituation;
        this.event = event;
        this.isSelected = false;
    }

    get_icon(): object {
        return this.linkedSituation == 'assign'
            ?
            // tslint:disable-next-line:max-line-length
            {
                url: `${this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.marker_color}.svg`,
                scaledSize: new google.maps.Size(15, 15)
            }
            :
            {
                url: this.markerIcons[this.linkedSituation][this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
            };
    }

    upadeIcon(event: string): void {
        this.event = event;
        this.marker.setIcon(this.get_icon());
    }

    paintMarker(map: any): void {
        const latitude = this.location.latitude;
        const longitude = this.location.longitude;
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.reference_id,
            position: new google.maps.LatLng(latitude, longitude),
            icon: this.get_icon(),
            map: map
        });
    }

    hideMarker(): void {
        this.marker.setMap(null);
    }

    setMap(map: any): void {
        this.marker.setMap(map);
    }
}

export class LamppostWattage {
    id: string;
    luminaires: LuminaireWattages[];
    event: string;

    constructor(attrs) {
        this.id = attrs.id;
        // tslint:disable-next-line:max-line-length
        this.luminaires = attrs.luminaires != null ? attrs.luminaires.map((luminaire: LuminaireWattage) => new LuminaireWattage(luminaire, 'linked')) : [];
    }

    upadeIcon(event: string): void {
        this.event = event;
        for (const luminaire of this.luminaires) {
            const event = this.event == 'normal' ? luminaire.isSelected ? 'selected' : 'normal' : this.event;
            luminaire.updateIcon(event);
        }
    }

    hideLamppost(): void {
        for (const luminaire of this.luminaires) {
            luminaire.hideMarker();
        }
    }

    setMap(map: any): void {
        for (const luminaire of this.luminaires) {
            luminaire.setMap(map);
        }
    }
}

export class CircuitWattage {
    id: string;
    identifier: string;
    meter: { id: string, location: Location };
    lampposts: LamppostWattage[];
    marker: google.maps.Marker;
    event: string;
    isSelected: boolean;
    isCollapsed: boolean;

    readonly markerIcons = {
        normal: 'assets/img/svg/circuit_normal.svg',
        selected: 'assets/img/svg/circuit_selected.svg',
        circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
    }

    constructor(attrs: any) {
        this.id = attrs.id;
        this.identifier = attrs.identifier;
        this.meter = attrs.meter;
        this.lampposts = attrs.lampposts != null ? attrs.lampposts.map((lamppost: LamppostWattage) => new LamppostWattage(lamppost)) : [];
        this.isCollapsed = false;
    }

    get_icon(): object {
        return {
            url: this.markerIcons[this.event == 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
        };
    }

    upadeIcon(event: string, continueWithAll: boolean = true): void {
        this.event = event;
        this.marker.setIcon(this.get_icon());
        if (continueWithAll) {
            for (const lamppost of this.lampposts) {
                lamppost.upadeIcon(event);
            }
        }
    }

    selected() {
        this.event = 'selected';
        this.isSelected = true;
        this.marker.setIcon(this.get_icon());
        for (const lamppost of this.lampposts) {
            lamppost.upadeIcon('normal');
        }
    }
    selectedALLLum() {
        for (const lamppost of this.lampposts) {
            for (const luminaire of lamppost.luminaires) {
                luminaire.isSelected = true;
            }
            lamppost.upadeIcon('selected');
        }
    }

    desSelected() {
        this.event = 'normal';
        this.isSelected = false;
        this.marker.setIcon(this.get_icon());
        for (const lamppost of this.lampposts) {
            lamppost.upadeIcon('normal');
        }
    }

    desSelectedAllLum() {
        for (const lamppost of this.lampposts) {
            for (const luminaire of lamppost.luminaires) {
                luminaire.isSelected = false;
            }
            lamppost.upadeIcon('normal');
        }
    }


    paintMarker(map: any): void {
        const latitude = this.meter.location.latitude;
        const longitude = this.meter.location.longitude;
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.identifier,
            position: new google.maps.LatLng(latitude, longitude),
            icon: this.get_icon(),
            map: map
        });
    }

    hideMarker(): void {
        this.marker.setMap(null);
    }
    setMap(map: any): void {
        this.marker.setMap(map);
        for (const lamppost of this.lampposts) {
            lamppost.setMap(map);
        }
    }
}
