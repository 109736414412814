import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Requisition, GetRequisitionsResponse, GetRequisitionResponse } from '@models/requisition';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequisitionService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getRequisitionsReceived(): Observable<Requisition[]> {
    return this.httpClient
      .get<GetRequisitionsResponse>(`${environment.SERVER_URL}/requisitions`)
      .pipe( map( data => data.requisitions.map( requisition => new Requisition(requisition)) ) );
  }

  getRequisitionsSend(): Observable<Requisition[]> {
    return this.httpClient
      .get<GetRequisitionsResponse>(`${environment.SERVER_URL}/requisitions/sent_and_pending`)
      .pipe( map( data => data.requisitions.map( requisition => new Requisition(requisition)) ) );
  }

  getRequisitionsHistory(): Observable<Requisition[]> {
    return this.httpClient
      .get<GetRequisitionsResponse>(`${environment.SERVER_URL}/requisitions/history`)
      .pipe( map( data => data.requisitions.map( requisition => new Requisition(requisition)) ) );
  }

  getRequisition(requisitionID: string): Observable<Requisition> {
    return this.httpClient
      .get<GetRequisitionResponse>(`${environment.SERVER_URL}/requisitions/${ requisitionID }`)
      .pipe( map( data =>  new Requisition(data.requisition) ) );
  }
}
