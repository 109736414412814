import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Troop } from 'app/models/troop';
import { Pagination } from 'app/models/pagination';

@Component({
  selector: 'app-troops-table',
  templateUrl: './troops-table.component.html',
  styleUrls: ['./troops-table.component.scss']
})
export class TroopsTableComponent implements OnInit {
  @Input() troops: Troop[];
  @Input() pagination: Pagination;
  @Input() page: number;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  changeCurrentPage(page) {
    this.pageChange.emit(page);
  }

}
