import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { IncidentsLuminairesRoute4Me, ResponseRoute4Me } from './incident-luminaires-route4-me';

@Injectable({
  providedIn: 'root'
})
export class IncidentsLuminairesRoute4MeService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }


  get_luminaires_incident_route4_me(work_type) {
    const httpParams = new HttpParams().set('work_type', work_type);
    
    return this.httpClient.get<IncidentsLuminairesRoute4Me[]>(`
    ${environment.SERVER_CALLCENTER}/api/Route4/get-incidents-luminaires-route4-me`, {params: httpParams});
  }

  get_luminaires_incident_route4_me_with_sectors(work_type, sectors) {
    let httpParams = new HttpParams().set('work_type', work_type);
    if (sectors) {
      sectors.forEach((sector, index) => {        
        httpParams = httpParams.append(`sectors[${index}]`, sector.id);
      });
    }
    
    return this.httpClient.get<IncidentsLuminairesRoute4Me[]>(`
    ${environment.SERVER_CALLCENTER}/api/Route4/get-incidents-luminaires-route4-me`, {params: httpParams});
  }

  CreateRouteLuminaires_route4_me(values_luminaires_route) {
    console.log(values_luminaires_route);
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    return this.httpClient.post<ResponseRoute4Me[]>(`${environment.SERVER_CALLCENTER}/api/Route4/register-optimization-route4me`, values_luminaires_route, { headers: httpHeaders });
  }

  GetAllRoutes_Route4me(query) {
    const param = new HttpParams()
      .set('query', query);
    return this.httpClient.get<ResponseRoute4Me[]>(`
    ${environment.SERVER_CALLCENTER}/api/route4/get-all-routes`, { params: param });
  }

}
