import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericTable } from '@app/models/generic-table';
import { environment } from '@environments/environment';
import { MarkerColor } from './marker-color';
import { map } from 'rxjs/operators';
import { AuthService } from '@app/shared/auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class MarkerColorService {

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) { }

  getListMarkerColor(page) {
    const httpParams = new HttpParams()
      .set('page', page);
    return this.httpClient.get<GenericTable<MarkerColor>>(
      `${environment.SERVER_CONFIG}/api/MarkerColor/get-list-marker-color`, { params: httpParams }
    ).pipe(map(data => new GenericTable<MarkerColor>(data)));
  }

  registerMakerColor(registerMarkerColor) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    return this.httpClient.post(`${environment.SERVER_CONFIG}/api/MarkerColor/register-marker-color`, registerMarkerColor, { headers: httpHeaders });
  }

  updateMarkerColor(updateMarkerColor) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    return this.httpClient.post(`${environment.SERVER_CONFIG}/api/MarkerColor/update-marker-color`, updateMarkerColor, { headers: httpHeaders });
  }

  

}
