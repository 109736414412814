import { FormGroup } from '@angular/forms';
import { Troop } from '@models/troop';

export class EditUserPayload {
  name: string;
  last_name: string;
  email: string;
  cell_phone_number: string;
  assigned_tools: string;
  address: string;
  role_id: string;
  troops: Troop[];
  troop_id: string;
  password: string;
  password_confirmation: string;
  image: any;

  constructor(editUserForm: FormGroup, troops?: Troop[]) {
    this.name = editUserForm.get('name').value;
    this.last_name = editUserForm.get('last_name').value;
    this.email = editUserForm.get('email').value;
    this.cell_phone_number = editUserForm.get('cell_phone_number').value;
    this.assigned_tools = editUserForm.get('assigned_tools').value;
    this.address = editUserForm.get('address').value;
    this.role_id = editUserForm.get('role_id').value;
    this.troop_id = editUserForm.get('troop_id').value;
    this.image = editUserForm.get('image').value;
    this.troops = troops;

    if (editUserForm.get('password')) {
      this.password = editUserForm.get('password').value;
      this.password_confirmation = editUserForm.get('password_confirmation').value;
    }
  }

   toFormData() {
    let formData = new FormData();

    formData.append('name', this.name);
    formData.append('last_name', this.last_name);
    formData.append('email', this.email);
    formData.append('cell_phone_number', this.cell_phone_number);
    formData.append('assigned_tools', this.assigned_tools);
    formData.append('address', this.address);
    formData.append('roles',this.role_id );
    formData.append('image', this.image);
    var troops_total = this.troops.map(x => x.id);
    if(!troops_total.includes(this.troop_id)) troops_total.push(this.troop_id);
    formData.append('troops', troops_total.toString());

    if(this.password && this.password_confirmation) {
      formData.append('user[password]', this.password);
      formData.append('user[password_confirmation]', this.password_confirmation);
    }
    return formData;
  }

  toJson(): Object {
    let json = {
      user: {
        name: this.name,
        last_name: this.last_name,
        email: this.email,
        cell_phone_number: this.cell_phone_number,
        assigned_tools: this.assigned_tools,
        address: this.address,
        roles: [{ id: this.role_id }],
        troop: { id: this.troop_id }
      }
    };

    if (this.password) {
      json.user["password"] = this.password
      json.user["password_confirmation"] = this.password_confirmation
    }

    return json;
  }
}