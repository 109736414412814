import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenFolio'
})
export class ShortenFolioPipe implements PipeTransform {

  transform(value: string[]): string {
    let str = value.join(', ');
    if(value.length && value.length > 2){
      value = value.slice(0, 3);
      str = value.join(', ')
    }
    return str;
  }

}