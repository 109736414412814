import { Component, AfterViewInit, ViewChild } from '@angular/core';

import { ProjectsModalComponent } from '@shared/projects/projects-modal/projects-modal.component';

@Component({
  selector: 'select-project-component',
  template: `<projects-modal-component></projects-modal-component>`
})

export class SelectProjectComponent implements AfterViewInit {
  @ViewChild(ProjectsModalComponent) projectsModalComponent: ProjectsModalComponent;

  constructor() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.openProjectsModal();
    })

    this.projectsModalComponent.hideCloseButton();
  }

  private openProjectsModal(): void {
    this.projectsModalComponent.openProjectsModal(
      {
        backdrop: 'static',
        keyboard: false
      }
    );
  }
}