import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { LoginService } from 'app/pages/login/login.service';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { AuthService } from '@shared/auth/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  providers: [LoginService]
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  changePasswordForm: FormGroup;
  token: string;

  constructor(
    private _login: LoginService,
    private cookies: CookieService,
    private authService: AuthService,
    private fb: FormBuilder,
    private toastr: Toastr,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {
    this.changePasswordForm = fb.group({
      password: [null, Validators.required],
      password_confirmation: [null, Validators.required]
    });

    route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });
   }

  ngOnInit() {
    this.renderer.addClass(document.body, 'body__login-background');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'body__login-background');
  }

  onLogin() {
    this.router.navigate(['login'], { relativeTo: this.route.parent });
  }

  onSubmit({ value, invalid }: { value: { password: string, password_confirmation: string }, invalid: boolean }) {
    if (invalid ) { return; }
    if (!this.samePassword(value)){ return this.toastr.singleError('Las contraseñas son diferentes');}
    this._login.changePassword(this.token, value).subscribe(token => {
      this.router.navigate(['login'], { relativeTo: this.route.parent });
      this.toastr.success('Contraseña actualizada.');
    }, error => {
      this.toastr.error(error);
      console.error(error);
    });
  }

  samePassword(value: { password: string, password_confirmation: string } ):boolean {
    let password = value.password; 
    let confirmPassword = value.password_confirmation;
    if (password == confirmPassword) {
      return true
    } else {
      return false
    }
  }

}
