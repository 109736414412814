export interface IValuesVirtualCircuit {
    circuit_id: string,
    rpu_id: string,
    rpu: string,
    tariff: string,
    supply: number,
    identifier: string,
    comment: Comment,
    created_at: Date,
    updated_at: Date,
    status: number,
    initial_calculated_kwh: number,
    locationcircuit?: Location,
    luminaires: Luminaires[]
}

export class ValuesVirtualCircuit implements IValuesVirtualCircuit {
    circuit_id: string;
    rpu_id: string;
    rpu: string;
    tariff: string;
    supply: number;
    identifier: string;
    comment: Comment;
    created_at: Date;
    updated_at: Date;
    status: number;
    initial_calculated_kwh: number;
    locationcircuit?: Location;
    luminaires: Luminaires[];
    event: string;
    is_selected: boolean;
    marker: google.maps.Marker;

    readonly markerIcons = {
        normal: 'assets/img/svg/circuit_normal.svg',
        selected: 'assets/img/svg/circuit_selected.svg',
        circuitShowing: 'assets/img/svg/ic_circuit_normal.svg'
    }

    constructor(attrs) {
        this.circuit_id = attrs.circuit_id;
        this.rpu_id = attrs.rpu_id;
        this.rpu = attrs.rpu;
        this.tariff = attrs.tariff;
        this.supply = attrs.supply;
        this.identifier = attrs.identifier;
        this.comment = attrs.comment;
        this.created_at = attrs.created_at;
        this.updated_at = attrs.updated_at;
        this.status = attrs.status;
        this.initial_calculated_kwh = attrs.initial_calculated_kwh;
        this.locationcircuit = attrs.locationcircuit == null ? null : new Location(attrs.locationcircuit);
        this.luminaires = attrs.luminaires;
    }

    paintMarker(map: any): void {
        this.event = this.is_selected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.circuit_id,
            position: new google.maps.LatLng(this.locationcircuit.latitude, this.locationcircuit.longitude),
            icon: this.get_icon(),
            map: map
        });
    }

    get_icon(): object {
        return {
            url: this.markerIcons[this.event === 'normal' ? this.is_selected ? 'selected' : 'normal' : this.event]
        };
    }



}

export interface ILuminaires {
    id: string,
    lamppost_id: string,
    reference_id: string;
    luminaire_type: number,
    is_assignated: boolean,
    locationluminaire: Location
}

export class Luminaires implements ILuminaires {
    id: string;
    lamppost_id: string;
    reference_id: string;
    luminaire_type: number;
    is_assignated: boolean;
    locationluminaire: Location;
    marker: google.maps.Marker;
    event: string;
    isSelected: boolean;

    readonly markerIcon = {
        linked: {
            hover: 'assets/img/svg/ic_link_hover.svg',
            normal: 'assets/img/svg/link.svg',
            selected: 'assets/img/svg/ic_link_circuit.svg',
            circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
        }
    }

    constructor(attrs) {
        this.id = attrs.id;
        this.lamppost_id = attrs.lamppost_id;
        this.luminaire_type = attrs.luminaire_type;
        this.is_assignated = attrs.is_assignated;
        this.locationluminaire = attrs.locationluminaire;
        this.reference_id = attrs.reference_id;
        this.isSelected = false;
    }

    get_icon(): object {
        return {
            url: this.markerIcon['linked'][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]
        };
    }

     upadateIcon(event: string): void {
        this.event = event;
        this.marker.setIcon(this.get_icon());
    }

    paintMarkerLum(map: any): void {
        this.event = this.is_assignated ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.reference_id,
            position: new google.maps.LatLng(this.locationluminaire.latitude, this.locationluminaire.longitude),
            icon: this.get_icon(),
            map: map
        });
    }


    paintMarker(map: any): void {
        this.event = this.is_assignated ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.reference_id,
            position: new google.maps.LatLng(this.locationluminaire.latitude, this.locationluminaire.longitude),
            icon: this.get_icon(),
            map: map
        });
    }

    hideMarker(): void {
        this.marker.setMap(null);
    }


    setMap(map: any): void {
        this.marker.setMap(map);
        // for (let lamppost of this.lampposts) {
        //     lamppost.setMap(map);
        // }
    }

}


export interface IVirtualCircuits {
    id: string,
    rpu_id: string,
    rpu: string,
    tariff: string,
    supply: number,
    identifier: string,
    comment: string,
    status: number,
    initial_calculated_kwh: number,
    is_virtualcircuit: boolean,
    location: Location
}




export class VirtualCircuits implements IVirtualCircuits {
    id: string;
    rpu_id: string;
    rpu: string;
    tariff: string;
    supply: number;
    identifier: string;
    comment: string;
    status: number;
    initial_calculated_kwh: number;
    is_virtualcircuit: boolean;
    location: Location;

    constructor(attrs) {
        this.id = attrs.id;
        this.rpu_id = attrs.rpu_id;
        this.rpu = attrs.rpu;
        this.tariff = attrs.tariff;
        this.supply = attrs.supply;
        this.identifier = attrs.identifier;
        this.comment = attrs.comment;
        this.status = attrs.status;
        this.initial_calculated_kwh = attrs.initial_calculated_kwh;
        this.location = attrs.location;
        this.is_virtualcircuit = attrs.is_virtualcircuit;
    }
}

export interface ILocation {
    latitude: number,
    longitude: number,
}


export class Location implements ILocation {
    latitude: number;
    longitude: number;

    constructor(attrs) {
        this.latitude = attrs.latitude;
        this.longitude = attrs.longitude;
    }

}



