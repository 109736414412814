export interface IGuarantyReport {
  id: string,
  reference_id: string,
  color_svg_photocell: string,
  color_svg_luminaire: string,
  report_type: string,
  status: string,
  repair_number: string,
  created_at: string,
  is_select: boolean
}

export class GuarantyReport implements IGuarantyReport {
  id: string;
  reference_id: string;
  report_type: string;
  status: string;
  repair_number: string;
  color_svg_photocell: string;
  color_svg_luminaire: string;
  created_at: string;
  is_select: boolean;
  constructor(attrs) {
    this.id = attrs.id;
    this.created_at = attrs.created_at;
    this.status = attrs.status;
    this.repair_number = attrs.repair_number;
    this.report_type = attrs.report_type;
    this.color_svg_photocell = attrs.color_svg_photocell;
    this.color_svg_luminaire = attrs.color_svg_luminaire;
    this.reference_id = attrs.reference_id;
    this.is_select = false;
  }
}

export interface IDetailGuarantyReport {
  reference_id: string,
  status: string,
  created_at: string,
  report_type: string,
  repair_number: string,

  luminaire: LuminaireGuarantyReport,

  user_name: string,
  failure_evidence: string,
  is_energized: boolean,
  is_photocell_ok: boolean,
  is_location_ok: boolean,

  third_image: string,
  fourth_image: string,
  fifth_image: string,
  sixth_image: string,
  seventh_image: string,
  eigth_image: string,
  guaranty_evidence: GuarantyEvidence,
  used_materials: GuarantyMaterials[],
  recover_materials: GuarantyMaterials[]
}

export class DetailGuarantyReport implements IDetailGuarantyReport {
  reference_id: string;
  status: string;
  report_type: string;
  created_at: string;
  repair_number: string;

  luminaire: LuminaireGuarantyReport;

  user_name: string;
  failure_evidence: string;
  is_energized: boolean;
  is_photocell_ok: boolean;
  is_location_ok: boolean;

  third_image: string;
  fourth_image: string;
  fifth_image: string;
  sixth_image: string;
  seventh_image: string;
  eigth_image: string;
  guaranty_evidence: GuarantyEvidence;
  used_materials: GuarantyMaterials[];
  recover_materials: GuarantyMaterials[];

  constructor(attrs) {
    this.reference_id = attrs.reference_id;
    this.status = attrs.status;
    this.report_type = attrs.report_type;
    this.created_at = attrs.created_at;

    this.user_name = attrs.user_name;
    this.failure_evidence = attrs.failure_evidence;
    this.is_energized = attrs.is_energized;
    this.is_photocell_ok = attrs.is_photocell_ok;
    this.is_location_ok = attrs.is_location_ok;

    this.luminaire = attrs.luminaire;

    this.third_image = attrs.third_image;
    this.fourth_image = attrs.fourth_image;
    this.fifth_image = attrs.fifth_image;
    this.sixth_image = attrs.sixth_image;
    this.seventh_image = attrs.seventh_image;
    this.eigth_image = attrs.eigth_image;
    this.repair_number = attrs.repair_number;
    this.guaranty_evidence = attrs.guaranty_evidence;
    this.used_materials = attrs.used_materials;
    this.recover_materials = attrs.recover_materials;
  }

}

export interface IGuarantyMaterials {
  guaranty_material_id: string,
  material_id: string,
  material_name: string,
  quantity: number,
  material_image: string
}

export class GuarantyMaterials implements IGuarantyMaterials {
  guaranty_material_id: string;
  material_id: string;
  material_name: string;
  quantity: number;
  material_image: string;

  constructor(attrs) {
    this.guaranty_material_id = attrs.guaranty_material_id;
    this.material_id = attrs.material_id;
    this.material_name = attrs.material_name;
    this.quantity = attrs.quantity;
    this.material_image = attrs.material_image;
  }

}

export interface ILuminaireGuarantyReport {
  reference_id: string,
  latitude: number,
  longitude: number,
  has_photocell: boolean,
  luminaire_type: string;
}

export class LuminaireGuarantyReport implements ILuminaireGuarantyReport {
  reference_id: string;
  latitude: number;
  longitude: number;
  has_photocell: boolean;
  marker: google.maps.Marker;
  luminaire_type: string;

  constructor(attrs) {
    this.reference_id = attrs.reference_id;
    this.latitude = attrs.latitude;
    this.longitude = attrs.longitude;
    this.has_photocell = attrs.has_photocell;
    this.luminaire_type = attrs.luminaire_type;
  }
  paintMarker_(map: any) {
    // tslint:disable-next-line:max-line-length
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
        <g fill="none" fill-rule="evenodd" transform="translate(11 9)">
          <path fill="#F14336" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round"
          d="M1.48662295,5.46992268 C3.38709836,2.19976804 6.92837705,-1.39891394e-14 10.9827787,-1.39891394e-14 C17.0452213,-1.39891394e-14 21.9596967,4.91677835 21.9596967,10.9820619 C21.9596967,12.5032088 21.6499836,13.9512887 21.091959,15.2688402 C20.5326721,16.5863918 19.7238115,17.7720619 18.7256066,18.7655928 C17.8937541,19.6503351 17.1476475,20.5850515 16.4770082,21.5402448 C12.0834098,27.8027191 10.9827787,31.9525515 10.9827787,31.9525515 C10.9827787,31.9525515 9.78747541,27.1894072 4.91519672,20.7553608 C4.39819672,20.0742139 3.84017213,19.407049 3.23868852,18.7655928 C2.56931148,18.0997809 1.98577049,17.3469201 1.50582787,16.5248711 C0.552344262,14.8974613 0.00586065574,13.0037629 0.00586065574,10.9819716 C0.00586065574,8.9730799 0.544680328,7.08957474 1.48662295,5.46992268 Z"/>
          <path fill="#7E2D25" d="M10.9828689,5.13697165 C14.2090246,5.13697165 16.8237787,7.75448454 16.8237787,10.9821521 C16.8237787,14.2098196 14.2090246,16.825799 10.9828689,16.825799 C7.75671311,16.825799 5.14042623,14.2098196 5.14042623,10.9821521 C5.14042623,7.75448454 7.75680328,5.13697165 10.9828689,5.13697165 Z"/>
        </g>
      </svg>`;
    this.marker = new google.maps.Marker({
      title: this.reference_id,
      position: new google.maps.LatLng(this.latitude, this.longitude),
      icon: {
        url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
        // scaledSize: new google.maps.Size(30, 30)
      },
      map: map
    });
  }

}

export interface IGuarantyEvidence {
  luminaire_evidence: GuarantyLuminaireEvidence,
  photocell_evidence: GuarantyPhotocellEvidence
}

export class GuarantyEvidence implements IGuarantyEvidence {
  luminaire_evidence: GuarantyLuminaireEvidence;
  photocell_evidence: GuarantyPhotocellEvidence;
  constructor(attrs) {
    this.luminaire_evidence = attrs.luminaire_evidence;
    this.photocell_evidence = attrs.photocell_evidence;
  }
}

export interface IGuarantyLuminaireEvidence {
  serial_number: string,
  luminaire_type_id: string,
  damage_location: string,
  first_image: string,
  second_image: string,
  comments: string,
  damage_Location_: string,
  accepted: number

}

export class GuarantyLuminaireEvidence implements IGuarantyLuminaireEvidence {
  serial_number: string;
  luminaire_type_id: string;
  damage_location: string;
  first_image: string;
  second_image: string;
  comments: string;
  damage_Location_: string;
  accepted: number;


}

export interface IGuarantyPhotocellEvidence {
  serial_number: string,
  is_on: number,
  damage_location: string,
  first_image: string,
  second_image: string,
  comments: string,
  damage_Location_: string,
  accepted: number
}

export class GuarantyPhotocellEvidence implements IGuarantyPhotocellEvidence {
  serial_number: string;
  is_on: number;
  damage_location: string;
  first_image: string;
  second_image: string;
  comments: string;
  damage_Location_: string;
  accepted: number;
}

export enum LuminaireDamageLocation {
  'Balastro',
  'Carcasa',
  'Foco',
  'Tapa',
  'Modulo Led',
  'Supresor de Picos',
  'Difractor',
}

export enum PhotocellDamageLocation {
  'Daño fisico',
  'Ruptura',
  'Humedad',
  'Descarga eléctrica',
  'No enciende',
  'Otro',

}
