import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusService } from './status.service';
import { InstanceType } from '@app/models/instance-type.model';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'status-component',
    templateUrl: './status.component.html'
})

export class StatusComponent implements OnInit {
    currentInstanceId: string | null = null;
    @ViewChild('confirmationModal') confirmationModal: any;
    instances$: Observable<InstanceType[]>;

    constructor(
        private modalService: NgbModal,
        private statusService: StatusService,
        private toastr: ToastrService
    ) {}

    ngOnInit() {
        this.instances$ = this.statusService.getInstances();
    }

    confirmReboot(instanceId: string) {
        const modalRef = this.modalService.open(this.confirmationModal);
        modalRef.componentInstance.message = `¿Estás seguro que quieres reiniciar la instancia ${instanceId}?`;

        modalRef.result.then((result) => {
            if (result === true) {
                this.rebootInstance(instanceId);
            }
        }, (reason) => {
            this.modalService.dismissAll();
        });
    }
    openConfirmModal(instanceId: string) {
        this.currentInstanceId = instanceId;
        this.modalService.open(this.confirmationModal);
    }
    

    rebootInstance(instanceId: string) {
        if (instanceId) {
            this.toastr.info('Reiniciando instancia...');
            this.statusService.rebootInstanceById(instanceId).subscribe(
                () => {
                    this.toastr.success('Instancia reiniciada con éxito ✅');
                    this.currentInstanceId = null;
                },
                (error) => {
                    this.toastr.error('Falla al reiniciar instancia ❌');
                    this.currentInstanceId = null;
                }
            );
        }
    }
}