import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GenericTable } from '@app/models/generic-table';
import { Alerts } from './alert';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthService } from '@app/shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  getAlerts(form, page) {
    const httpParams = new HttpParams().set('page', page)
    return this.httpClient.post<GenericTable<Alerts>>(
      `${environment.SERVER_ENERGY}/api/RpusAlerts/GetAlerts`, form, { params: httpParams }
    ).pipe(
      map(elements => new GenericTable<Alerts>(elements))
    );
  }

  UpdateAlerts(form) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id)
    return this.httpClient.post(
      `${environment.SERVER_ENERGY}/api/RpusAlerts/UpdateAlert`, form, { headers: httpHeaders }
    );
  }

}
