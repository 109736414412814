import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-count-dowm',
  templateUrl: './count-dowm.component.html',
  styleUrls: ['./count-dowm.component.scss']
})
export class CountDowmComponent implements OnInit {

  @Input() deadLineDate: Date
  countDown;
  timeInHours;
  constructor() { }

  ngOnInit() {
    const daysTimeLeft = this.deadLineDate.getTime() - new Date().getTime();
    let duration: any = moment.duration(daysTimeLeft, 'milliseconds');
    let hours = Math.abs( duration.hours());
    let mins = Math.abs(duration.minutes());
    let days = parseInt(duration.asDays());


    this.countDown = days + ' D ' + ('0' + hours).slice(-2) + ':' + ('0' + mins).slice(-2) ;
  this.timeInHours = daysTimeLeft / 1000 / 3600;
 //  setInterval(() => {
 //   duration = moment.duration(duration -  1000 * 60, 'milliseconds');
 //   this.countDown = duration.days() + ' D ' + Math.abs( duration.hours()) + ':' + Math.abs(duration.minutes());
// },  1000 * 60);
  }

}
