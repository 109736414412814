import { SmartDevicesTable } from './../../../../models/smart-devices';
import { Observable, Subscription } from 'rxjs';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { EditUserService } from '@app/pages/users/edit-user/edit-user.service';

import { GetDevices } from '@app/models/devices';
import { DevicesService } from '../devices.service';
import { GenericTable } from '@app/models/generic-table';
import { OnceEnum } from '@app/models/OnceEnum';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-devices-table',
  templateUrl: './devices-table.component.html',
  styleUrls: ['./devices-table.component.scss'],
})
export class DevicesTableComponent implements OnInit, OnDestroy {

  private eventsSubscription: Subscription;
  @Input() pages;
  devicesTable: GenericTable<SmartDevicesTable>;
  @Input() events: Observable<void>;
  @Input() searchFrom: FormGroup;

  constructor(
    private devicesService: DevicesService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.getDevices()
    this.eventsSubscription = this.events.subscribe(() => {
      this.getDevices()
    })
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  getDevices() {
    this.devicesService.getDevicesTable(
      this.pages,
      this.searchFrom.get('device').value,
      this.searchFrom.get('circuit').value,
      this.searchFrom.get('device_status').value,
      this.searchFrom.get('circuit_status').value,
      this.searchFrom.get('orderBy').value,
      this.searchFrom.get('group').value,
      this.searchFrom.get('delay').value
      ).subscribe(response => {
        this.devicesTable = new GenericTable<SmartDevicesTable>(response);
      })
  }

  goToDevice(device_id) {
    this.router.navigate(['/u/smart/devices/device-details/', device_id])
  }

  pageChange(page) {
    this.pages = page - 1;
    this.router.navigate(['/u/smart/devices'],
      {
        relativeTo: this.route,
        queryParams: {
          page: page - 1,
          device: this.searchFrom.get('device').value,
          circuit: this.searchFrom.get('circuit').value,
          device_status: this.searchFrom.get('device_status').value,
          circuit_status: this.searchFrom.get('circuit_status').value,
          orderBy: this.searchFrom.get('orderBy').value,
          group: this.searchFrom.get('group').value,
          delay: this.searchFrom.get('delay').value
        }
      });
  }

}
