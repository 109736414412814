import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CallCenterDashboard } from '@app/models/call-center-dashboard';

@Injectable({
  providedIn: 'root'
})
export class CallcenterDashboardService {
  constructor(private httpClient: HttpClient) { }



  getCallCenterDashboard(StartDate, EndDate) {
    const httpParams = new HttpParams()
    .set('StartDate', StartDate)
    .set('EndDate', EndDate)
    return this.httpClient.get<CallCenterDashboard>(
      `${environment.SERVER_CALLCENTER}/api/CallCenterDashboard/GetCallCenterDashboard` , {params: httpParams}
    )
  }

}
