import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { EditUserService } from '@app/pages/users/edit-user/edit-user.service';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { Schedules } from '@app/models/schedules';
import { ScheduleService } from './schedule.service';
import { GenericTable } from '@app/models/generic-table';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss'],
  providers: [EditUserService]
})
export class SchedulesComponent implements OnInit {
  @ViewChild('contentMaterialModal') materialModal: any;
  @ViewChild('copyModal') copyModal: any;


  schedules: GenericTable<Schedules>;
  scheduleSearchForm: FormGroup;
  scheduleForm: FormGroup;
  edit = false;
  scheduleType: boolean;
  formData: FormData = new FormData();

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toaster: Toastr,
    private projectService: EditUserService,
    private currentProjectService: CurrentProjectService,
    private scheduleService: ScheduleService
  ) {
    this.scheduleSearchForm = fb.group({
      name: '',
      reference: '',
      am_pm: '',
    });
  }

  ngOnInit() {

    this.scheduleForm = this.fb.group({
      id: [null],
      name: [null, Validators.required],
      type: [null, Validators.required],
      description: [null],
      on_solar_event: [null],
      on_solar_offset: [0],
      on_custom_time: [null],
      off_solar_event: [null],
      off_solar_offset: [0],
      off_custom_time: [null],

    })
    this.paginatedTable(0);
  }

  paginatedTable(page: number) {
    this.scheduleService.getSchedules(this.scheduleSearchForm.getRawValue(), page).subscribe(response => {
      this.schedules = response
    })
  }

  saveSchedule() {
    const data = {
      name: this.scheduleForm.value.name,
      hour: this.scheduleForm.value.hour,
      description: this.scheduleForm.value.description,
      type: this.scheduleForm.value.type,
      schedule: {
        On_Properties: {
          solar_event: this.scheduleForm.value.on_solar_event,
          solar_offset: this.scheduleForm.value.on_solar_offset,
          custome_time: this.scheduleForm.value.on_custom_time
        },
        Off_Properties: {
          solar_event: this.scheduleForm.value.off_solar_event,
          solar_offset: this.scheduleForm.value.off_solar_offset,
          custome_time: this.scheduleForm.value.off_custom_time
        }
      }
    }

    this.scheduleService.addSchedule(data).subscribe(response => {
      this.paginatedTable(0);
      this.toaster.success('Se agrego correctamente');
      this.paginatedTable(0);
    }, error => {
      this.paginatedTable(0);
      this.toaster.error('Error al agregar la tarifa');
    })
    this.modalService.dismissAll();
  }

  onEdit(schedule: Schedules) {
    this.edit = true;

    if (schedule.type == 0) {
      this.scheduleType = true
    } else {
      this.scheduleType = false
    }

    this.scheduleForm.patchValue({
      id: schedule.id,
      name: schedule.name,
      type: schedule.type,
      description: schedule.description,
      on_solar_event: schedule.on_solar_event,
      on_solar_offset: schedule.on_solar_offset,
      on_custom_time: schedule.on_custom_time,
      off_solar_event: schedule.off_solar_event,
      off_solar_offset: schedule.off_solar_offset,
      off_custom_time: schedule.off_custom_time
    });
    this.modalService.open(this.materialModal, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  editSchedule() {

    const data = {
      id: this.scheduleForm.value.id,
      name: this.scheduleForm.value.name,
      hour: this.scheduleForm.value.hour,
      description: this.scheduleForm.value.description,
      type: this.scheduleForm.value.type,
      schedule: {
        On_Properties: {
          solar_event: this.scheduleForm.value.on_solar_event,
          solar_offset: this.scheduleForm.value.on_solar_offset,
          custome_time: this.scheduleForm.value.on_custom_time
        },
        Off_Properties: {
          solar_event: this.scheduleForm.value.off_solar_event,
          solar_offset: this.scheduleForm.value.off_solar_offset,
          custome_time: this.scheduleForm.value.off_custom_time
        }
      }
    }

    this.scheduleService.updateSchedule(data).subscribe(response => {
      this.toaster.success('Se modifico correctamente');
      this.paginatedTable(0);
    }, error => {
      this.paginatedTable(0);
      this.toaster.error('Error al modificar');
    });
    this.modalService.dismissAll();
  }

  onChange(event) {
    if (event.target.value == 0) {
      this.scheduleType = true
    } else {
      this.scheduleType = false
    }

    this.scheduleForm.patchValue({
      on_solar_event: null,
      on_solar_offset: 0,
      on_custom_time: null,
      off_solar_event: null,
      off_solar_offset: 0,
      off_custom_time: null,
    })
  }

  pageChange(page: number) {
    this.paginatedTable(page - 1);
  }

  showModal() {
    this.edit = false;
    this.scheduleForm.reset();
    this.modalService.open(this.materialModal, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  onClose() {
    this.modalService.dismissAll();
  }

}
