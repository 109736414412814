
interface MaterialInterface {
    id: string,
    name: string,
    image_url: string,
    minimum: number,
    is_active: boolean,
    total: number,
    is_check: boolean,
    material_attributes: MaterialAttributes,
    material_code: string,
    cost: number
}

export class Material implements MaterialInterface {
    id: string;
    name: string;
    image_url: string;
    minimum: number;
    is_active: boolean;
    total: number;
    editing: boolean;
    material_attributes: MaterialAttributes;
    is_check: boolean;
    material_code: string;
    cost: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.image_url = attrs.image_url;
    this.minimum = attrs.minimum;
    this.is_active = attrs.is_active;
    this.total = attrs.total || 0;
    this.editing = false;
    this.is_check = attrs.is_check;
    this.material_attributes = attrs.material_attributes ? new MaterialAttributes(attrs.material_attributes) : null;
    this.material_code = attrs.material_code;
    this.cost = attrs.cost;
  }
}

export class MaterialAttributes {
  luminaire_type: number;
  technology: number;

  constructor(attrs: any) {
    this.luminaire_type = parseInt(attrs.luminaire_type);
    this.technology = parseInt(attrs.technology);
  }
}