import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@app/shared/auth/auth.service';
import { environment } from '@environments/environment';
import { GenericTable } from '@app/models/generic-table';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Group, Groups } from '@app/models/groups';


@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }


  getGroups(form, page) {
    const httpParams = new HttpParams().set('page', page)
    return this.httpClient.post<GenericTable<Groups>>(
      `${environment.SERVER_SMART}/api/Groups/GetGroups`, form, { params: httpParams }
    ).pipe(
      map(elements => new GenericTable<Groups>(elements))
    );
  }

  addGroup(form) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id )
    return this.httpClient.post(
      `${environment.SERVER_SMART}/api/Groups/AddGroup`, form, { headers: httpHeaders}
    );
  }

  updateGroup(form) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id )
    return this.httpClient.post(
      `${environment.SERVER_SMART}/api/Groups/UpdateGroup`, form, { headers: httpHeaders}
    );
  }


  getSchedulesList(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.SERVER_SMART}/api/Groups/GetSchedulesList`);
  }

  getUsersList(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.SERVER_SMART}/api/Groups/GetUsersList`);
  }

  getGroup(id: string): Observable<Group> {
    const httpParams = new HttpParams()
    .set('id', id)
    return this.httpClient.get<Group>(
      `${environment.SERVER_SMART}/api/Groups/GetGroup`, {params: httpParams});
  }

}
