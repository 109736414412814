import { Component, OnInit } from '@angular/core';
import { Trooper } from '@app/models/trooper';
import { Sector } from '@app/models/sector';
import { RepairTasksPhaseService } from './repair-tasks-phase.service';
import { TaskRepairsPhase } from '@app/models/task-repairs-phase';
import { GenericTable } from '@app/models/generic-table';
import { IncidentsService } from '../incidents/incidents.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { stringify } from 'querystring';

@Component({
  selector: 'app-repair-tasks-phase',
  templateUrl: './repair-tasks-phase.component.html',
  styleUrls: ['./repair-tasks-phase.component.scss'],
  providers: [IncidentsService]
})
export class RepairTasksPhaseComponent implements OnInit {

  sectors: Sector[] = [];
  troopers: Trooper[] = [];
  pagination: GenericTable<TaskRepairsPhase>;

  filterForm: FormGroup;
  isFiltered = false;

  constructor(
    private repairTaskService: RepairTasksPhaseService,
    private incidentsService: IncidentsService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.filterForm = this.fb.group({
      reference_id: [null],
      lum_num: [null],
      ticket_num: [null],
      isFinished: [0]
    })
    this.getElementsPaginated(0);
  }

  getElementsPaginated(page: number) {
    this.repairTaskService.getCurrentTasks(page).subscribe(
      response => {
        this.pagination = response;
      }
    );
  }

  pageChange(page: number) {
    if (this.isFiltered) {
      this.filter(page - 1);
    } else {
      this.getElementsPaginated(page - 1);
    }
  }

  recoverRepairPrintable(fuuid_taskId: string) {
    this.incidentsService.downloadComplementaryTicket();
    this.incidentsService.recoverPrintable(fuuid_taskId);
  }

  filterElemnts() {

    const reference_id = this.filterForm.get('reference_id').value;
    const lum_num = this.filterForm.get('lum_num').value;
    const ticket_num = this.filterForm.get('ticket_num').value;
    const isFinished = parseInt(this.filterForm.get('isFinished').value, null);


    console.log(reference_id, lum_num, ticket_num);

    if (
      (reference_id == null || reference_id == '') &&
      (lum_num == null || lum_num == '') &&
      (ticket_num == null || ticket_num == '') &&
      isFinished == 0
    ) {
      this.getElementsPaginated(0)
      console.log('Sin filtro')
    } else {
      this.filter(0);
    }

  }

  filter(page: number) {
    const reference_id = this.filterForm.get('reference_id').value;
    const lum_num = this.filterForm.get('lum_num').value;
    const ticket_num = this.filterForm.get('ticket_num').value;
    const isFinished = parseInt(this.filterForm.get('isFinished').value, null);

    this.repairTaskService.getFilteredTasks(
      reference_id,
      lum_num,
      ticket_num,
      isFinished,
      page
    ).subscribe(reponse => {
      this.pagination = reponse;
      this.isFiltered = true;
    })
  }

}
