export interface IGroups {
    id: string
    name: string,
    description: string,
    scheduleOnName: string,
    scheduleOffName: string,
    schedule_on: string,
    schedule_off: string,
    userName: string,
}

export class Groups implements IGroups {

    id: string;
    name: string;
    description: string;
    scheduleOnName: string;
    scheduleOffName: string;
    schedule_on: string;
    schedule_off: string;
    userName: string;

    constructor(attrs: any) {
        this.id = attrs.id;
        this.name = attrs.name;
        this.description = attrs.description;
        this.schedule_on = attrs.schedule_on;
        this.schedule_off = attrs.schedule_off;
        this.scheduleOnName = attrs.scheduleOnName;
        this.scheduleOffName = attrs.scheduleOffName;
        this.userName = attrs.userName;
    }
}

export class Group {
  id: string;
  name: string;
  schedule_id: string;
  devices: GroupDevices[];
  description: string;

  constructor(attrs) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.schedule_id =  attrs.schedule_id;
    this.devices = attrs.devices.map(device => new GroupDevices(device));
    this.description = attrs.description;
  }
}

export class GroupDevices {

    device_id: string;
    circuit_id: string;
    circuit_name: string;
    deviceName: string;
    voltage: number;
    current: number;
    circuitStatus: boolean;
    groupName: string;
    kwh: number;
    device_status: string;
    circuit_status: string;
    last_record_date: string;
    delayInMins: number;
    event: string;
    marker: google.maps.Marker;
    isSelected: Boolean;
    linkedSituation: string;
    location: {
        latitude: number,
        longitude: number
    }

    readonly markerIcons = {
      nomral: 'assets/img/svg/circuit_',
      selected: 'assets/img/svg/circuit_selected.svg',
      }

    constructor(attrs: any) {
        this.device_id = attrs.device_id;
        this.circuit_id = attrs.circuit_id;
        this.circuit_name = attrs.circuit_name;
        this.deviceName = attrs.deviceName;
        this.circuitStatus = attrs.circuitStatus;
        this.voltage = attrs.voltage;
        this.current = attrs.current;
        this.kwh = attrs.kwh;
        this.groupName = attrs.groupName;
        this.last_record_date = attrs.last_record_date;
        this.delayInMins = attrs.delayInMins;
        this.device_status = attrs.device_status;
        this.circuit_status = attrs.circuit_status;
        this.location = attrs.location;
        this.isSelected = false;
    }
    get_icon() {
      return !this.isSelected ?
        {
          url: `${this.markerIcons.nomral}${this.circuit_status}.svg`
        } :
        {
          url: this.markerIcons.selected
        }
    }
      upadeIcon(event: string): void {
        this.event = event;
        this.marker.setIcon(this.get_icon());
      }

      paintMarker(map: any): void {
          const latitude = this.location.latitude;
          const longitude = this.location.longitude;
          this.marker = new google.maps.Marker({
              title: this.deviceName == null ? this.device_id : this.deviceName.toString(),
              position: new google.maps.LatLng(latitude, longitude),
              icon: this.get_icon(),
              map: map
          });
      }

      hideMarker(): void {
        this.marker.setMap(null);
      }

      setMap(map: any): void {
        this.marker.setMap(map);
      }

}
