import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { LuminairesStatus } from '@app/models/luminiare-status';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LuminaireStatusService {

  constructor(private httpClient: HttpClient) { }

  getLuminaireStatus(latitude, longitude) {
    const params = new HttpParams()
    .set('latitude', latitude.toString())
    .set('longitude', longitude.toString());
    return this.httpClient.get<LuminairesStatus>(`${environment.SERVER_CONFIG}/api/LuminariesStatus/GetMapLumStatus`, { params: params })
    .pipe(map(response => new LuminairesStatus(response)));
  }

  assignLuminaires(luminaires: any [], municipality_status: number) {
    const form = { luminaire_id: luminaires, municipality_status: municipality_status}
    return this.httpClient.post(`${environment.SERVER_CONFIG}/api/LuminariesStatus/AssingLumStatus`, form);
  }
}
