import { Component, Input, OnInit } from '@angular/core';
import { RequstedMaterial } from '@app/models/material-requested';

@Component({
  selector: 'app-material-req',
  templateUrl: './material-req.component.html',
  styleUrls: ['./material-req.component.scss']
})
export class MaterialReqComponent implements OnInit {
  @Input() items: [];
  constructor() { }

  ngOnInit() {
  }

}
