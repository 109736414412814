interface LuminaireNewInterface2 {
    id: string,
    reference_id: number;
    municipality_status: number;
    location: {
        latitude: number,
        longitude: number
    }
    type: number;
    technology: number;
    has_photocell: boolean;
    progress: number;
    comment: string;
    selectedTab: number;
    curcuit_id: string;
}

export class LuminaireNew2 implements LuminaireNewInterface2 {
    id: string;
    reference_id: number;
    municipality_status: number;
    location: Location2
    event: string;
    linkedSituation: string;
    marker: google.maps.Marker;
    isSelected: Boolean;
    curcuit_id: string;
    type: number;
    technology: number;
    has_photocell: boolean;
    progress: number;
    comment: string;
    selectedTab: number;
    progressIconEnum: string


    constructor(attrs, linkedSituation: string = 'single', event: string = 'normal') {
        this.id = attrs.id;
        this.reference_id = attrs.reference_id;
        this.municipality_status = attrs.municipality_status;
        this.location = new Location2(attrs.location);
        this.linkedSituation = linkedSituation;
        this.event = event;
        this.isSelected = false;
        this.curcuit_id = attrs.curcuit_id;
        this.type = attrs.type;
        this.technology = attrs.technology;
        this.has_photocell = attrs.has_photocell;
        this.progress = attrs.progress;
        this.comment = attrs.comment;
        this.selectedTab = attrs.selectedTab;
        this.progressIconEnum = this.progress != null ? ProgressIconrEnum2[this.progress] : null;
    }






    paintMarker(map: any): void {
        const latitude = this.location.latitude;
        const longitude = this.location.longitude;
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.reference_id == null ? this.id : this.reference_id.toString(),
            position: new google.maps.LatLng(latitude, longitude),
            map: map,
            draggable: true,

        });
    }

    hideMarker(): void {
        this.marker.setMap(null);
    }

    setMap(map: any): void {
        this.marker.setMap(map);
    }
}


export class LuminaireSW implements LuminaireNewInterface2 {
    id: string;
    reference_id: number;
    municipality_status: number;
    location: Location2
    event: string;
    linkedSituation: string;
    marker: google.maps.Marker;
    isSelected: Boolean;
    curcuit_id: string;
    type: number;
    technology: number;
    has_photocell: boolean;
    progress: number;
    comment: string;
    selectedTab: number;
    progressIconEnum: string


    constructor(attrs, linkedSituation: string = 'single', event: string = 'normal') {
        this.id = attrs.id;
        this.reference_id = attrs.reference_id;
        this.municipality_status = attrs.municipality_status;
        this.location = new Location2(attrs.location);
        this.linkedSituation = linkedSituation;
        this.event = event;
        this.isSelected = false;
        this.curcuit_id = attrs.curcuit_id;
        this.type = attrs.type;
        this.technology = attrs.technology;
        this.has_photocell = attrs.has_photocell;
        this.progress = attrs.progress;
        this.comment = attrs.comment;
        this.selectedTab = attrs.selectedTab;
        this.progressIconEnum = this.progress != null ? ProgressIconrEnum2[this.progress] : null;
    }






    paintMarker(map: any): void {
        const latitude = this.location.latitude;
        const longitude = this.location.longitude;
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
            title: this.reference_id == null ? this.id : this.reference_id.toString(),
            position: new google.maps.LatLng(latitude, longitude),
            map: map,
            draggable: true,

        });
    }

    hideMarker(): void {
        this.marker.setMap(null);
    }

    setMap(map: any): void {
        this.marker.setMap(map);
    }
}

interface LocationInterface2 {
    latitude: number;
    longitude: number;
}
export class Location2 implements LocationInterface2 {
    latitude: number;
    longitude: number;

    constructor(attrs: any) {
        this.latitude = attrs.latitude;
        this.longitude = attrs.longitude;
    }
}


export enum ProgressEnum2 {
    'Censo rápido',
    'Censo infraestructura',
    'Instalación',
}

export enum ProgressIconrEnum2 {
    '485_C',
    '190_C',
    '139_C',
}
