import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ProblemEnum } from '@models/problem-enum';
import { ResponsiblePartyEnum } from '@models/responsible_party-enum';
import { SourceEnum } from '@models/source-enum';
import { Luminaire } from '@models/luminaire';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Toastr } from '@shared/toastr/toastr.service';
import { CurrentProjectService } from '@shared/cookies/current-project.service';
import { ReportLuminaireService } from '@pages/report-luminaire/report-luminaire.service';
import { ProjectResponse } from '@models/project-response';
import { TicketResponse } from '@models/ticket-response';
// import { ReportLuminairePayload } from '@models/report-luminaire-payload';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthService } from '@shared/auth/auth.service';
import { AdminService } from '@pages/admin/admin.service';
import { Project } from '@models/project';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CitizenLuminaire } from '@app/pages/report-luminaire/citizen-luminaire';
import { COUNTRIES } from '@app/pages/report-luminaire/countries'
import { ReportLuminairePayload, ReportLuminaireTicket } from './report-luminaire';

@Component({
  selector: 'app-report-by-user',
  templateUrl: './report-by-user.component.html',
  styleUrls: ['./report-by-user.component.scss'],
  providers: [ReportLuminaireService, AuthService, AdminService]
})
export class ReportByUserComponent implements OnInit, OnDestroy {
  problemEnum = Object.keys(ProblemEnum).filter(key => !isNaN(Number(ProblemEnum[key])));
  responsiblePartyEnum = Object.keys(ResponsiblePartyEnum).filter(key => !isNaN(Number(ResponsiblePartyEnum[key])));
  sourceEnum = Object.keys(SourceEnum).filter(key => !isNaN(Number(SourceEnum[key])));
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('sectionMap') sectionMap: any;
  @ViewChild('modalAviso') modalAviso: any;
  map: google.maps.Map;
  autocomplete: google.maps.places.Autocomplete;
  folio = '';
  project: Project;
  slug: string;
  luminaires: CitizenLuminaire[] = [];
  markers: google.maps.Marker[] = [];
  selectedLuminaires: Luminaire[] = [];
  reportForm: FormGroup = this.formBuilder.group({
    problem: [null, Validators.required],
    name: [null, Validators.required],
    first_last_name: [null, Validators.required],
    specification: [null],
    second_last_name: [null],
    email: [null, [Validators.email]],
    phone_number: [null],
    disclaimer_was_accepted: [true],
    whatsapp_disclaimer_was_accepted: [true],
    comment: [null],
    address: [''],
    responsibleParty: [null, Validators.required],
    source: [null, Validators.required],
    country: [{ value: "MX", disabled: true }],
    dial_code: [{ value: "+521", disabled: true }],
    luminaires: this.formBuilder.array([])
  });
  showLuminaires: boolean = false;
  project_max_page = 0;
  btnSendDisabled = false;
  zoomToShowMarkes = 16;
  countries = COUNTRIES;
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentZoomMap = 0;
  lumRef = null;
  showRef = [];

  constructor(
    private toastr: Toastr,
    private router: Router,
    private adminService: AdminService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private currentProjectService: CurrentProjectService,
    private reportLuminaireService: ReportLuminaireService
  ) { }

  ngOnInit() {
    this.project = this.currentProjectService.getCurrentProject();
    this.reportLuminaireService.getProjectById(this.project.id)
      .subscribe((project: Project) => {
        this.project = project;
        this.slug = project.slug;
        this.paintMap();
        this.getLuminaires();
      }, error => {
        this.toastr.error(error);
      });
    this.reportForm
      .get('country')
      .valueChanges
      .subscribe((val) => {
        this.countryChanged(val);
      });

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get countOfLuminaries() {
    return this.luminaires.filter(luminaire => luminaire.isSelected).length;
  }

  /*modificar para que aparescan las luminarias que pertenecen al proyecto, para que se levante un reporte*/
  getLuminaires() {
    let location = `${this.map.getCenter().lng()},${this.map.getCenter().lat()}`;
    let lumnsSelected = this.luminaires.filter(luminaire => luminaire.isSelected);
    let idsSelected = lumnsSelected.map(luminaire => { return luminaire.id });
    this.reportLuminaireService.getLuminairesOfProject(this.slug, location, idsSelected).pipe(takeUntil(this.destroy$))
      .subscribe((luminaires: CitizenLuminaire[]) => {
        let backLuminaires = this.luminaires.filter(luminaire => !luminaire.isSelected);
        lumnsSelected = this.luminaires.filter(luminaire => luminaire.isSelected);
        luminaires = luminaires.filter(lum => !lumnsSelected.some(_lum => _lum.id === lum.id));

        if (!this.luminaires.length) {
          this.addLuminairesToMap(luminaires);
          this.luminaires = luminaires;
          this.setMarkerRef();
          return;
        }

        const iqualLums = backLuminaires.filter(lum => luminaires.some(_lum => _lum.id === lum.id))
        const diffHideLums = backLuminaires.filter(lum => !iqualLums.some(_lum => _lum.id === lum.id))
        this.setMarkersToMap(diffHideLums, null);

        const diffNewLums = luminaires.filter(lum => !backLuminaires.some(_lum => _lum.id === lum.id));
        this.addLuminairesToMap(diffNewLums);

        this.luminaires = lumnsSelected;

        this.luminaires = this.luminaires.concat(iqualLums);
        this.luminaires = this.luminaires.concat(diffNewLums);
        this.setMarkerRef();

      }, (error) => {
        this.toastr.error(error);
      });
  }

  performFormAction() {
    const user = this.authService.getUser();
    const luminaires = this.luminaires.filter(luminaire => luminaire.isSelected);
    if (!luminaires.length) {
      this.toastr.singleError('Seleccione una luminaria.');
      const element = document.getElementById('sctnMap');
      element.scrollIntoView();
      return;
    }
    if (this.reportForm.get('problem').value != ProblemEnum.Otra) {
      this.reportForm.controls.specification.updateValueAndValidity()
    }
    if (this.reportForm.valid) {
      if (!this.reportForm.get('email').value && !this.reportForm.get('phone_number').value) {
        this.toastr.singleError('Operación fallida. Revisar campos requeridos (Correo ó Whatsapp).');
        return;
      }
      if (this.reportForm.get('problem').value == ProblemEnum.Otra && !this.reportForm.get('specification').value) {
        this.reportForm.controls.specification.setErrors([Validators.required]);
        this.reportForm.controls.specification.markAsTouched();
        this.toastr.singleError('Por favor especifique cual es la falla.');
        return;
      }
      for (const luminaire of luminaires) {
        this.reportForm.get('luminaires').value.push({ id: luminaire.id });
      }
      const requestPayload = new ReportLuminairePayload(this.reportForm, user.id);
      this.btnSendDisabled = true;
      this.reportLuminaireService.createTicketLuminaires(requestPayload).subscribe(res => {
        this.btnSendDisabled = false;
        this.folio = res.reference;
        this.toastr.success('Luminaria reportada.');
        this.reportForm.patchValue({
          problem: null,
          comment: null,
          luminaires: []
        });

        // console.log(this.showRef, 'showRef');
        for (const luminaire of luminaires) {
          luminaire.isSelected = false;
          luminaire.upadeIcon();
        }
        this.modalService.open(this.modalAviso).result.then(result => {
          this.router.navigate([`/u/call-center/incidents`], { queryParams: { q: this.folio } });
        }, reason => { });
        this.reportForm.markAsUntouched();
      },
        (error) => {
          this.toastr.singleError(error);
        });

      // this.reportLuminaireService
      //   .createReportLuminaires(requestPayload, this.slug)
      //   .subscribe(
      //     (response: ReportLuminaireTicket) => {
      //       this.btnSendDisabled = false;
      //       this.folio = response.reference;
      //       this.toastr.success('Luminaria reportada.');
      //       this.reportForm.patchValue({
      //         problem: null,
      //         comment: null,
      //         luminaires: []
      //       });
      //       for (const luminaire of luminaires) {
      //         luminaire.isSelected = false;
      //         luminaire.upadeIcon();
      //       }
      //       this.modalService.open(this.modalAviso).result.then(result => {
      //         this.router.navigate([`/u/call-center/incidents`], { queryParams: { q: this.folio } });
      //       }, reason => { });
      //       this.reportForm.markAsUntouched();
      //     },
      //     (error) => {
      //       this.toastr.singleError(error);
      //     }
      //   );
    } else {
      this.reportForm.controls.problem.markAsTouched();
      this.reportForm.controls.name.markAsTouched();
      this.reportForm.controls.first_last_name.markAsTouched();
      this.reportForm.controls.email.markAsTouched();
      this.reportForm.controls.source.markAsTouched();
      this.reportForm.controls.responsibleParty.markAsTouched();
      this.reportForm.controls.disclaimer_was_accepted.markAsTouched();
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
    }
    this.showRef = [];
  }

  setLuminariesClasses() {
    const classes = {
      'btn-primary': this.showLuminaires,
      'oe--btn--text-white': this.showLuminaires,
      'oe--btn--border': !this.showLuminaires
    }
    return classes
  }

  private changeZoom(): void {
    this.currentZoomMap = this.map.getZoom();
    if (this.map.getZoom() > this.zoomToShowMarkes)
      this.getLuminaires();
    else {
      this.setMarkersToMap(this.luminaires, null);
      this.luminaires = this.luminaires.filter(lum => lum.isSelected);
    }
  }

  private countryChanged(val): void {
    let country = this.countries.find(_country => _country.code == val);
    this.reportForm.patchValue({ dial_code: country.dial_code });
  }

  private paintMap(): void {
    let mexico = {
      center: { lat: 23.6, lng: -102.5 },
      zoom: 4
    }
    let mapProperties: any = {
      center: new google.maps.LatLng(mexico.center.lat, mexico.center.lng),
      zoom: mexico.zoom,
      disableDefaultUI: true,
      zoomControl: true,
      streetViewControl: true,
      fullscreenControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };


    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    this.centerMapOnTheProject();
    this.paintTheSearchBoxOfMap();
    this.map.addListener('zoom_changed', () => this.changeZoom());
    this.map.addListener('dragend', () => {
      if (this.map.getZoom() > this.zoomToShowMarkes) {
        this.getLuminaires();
      }
    })
  }

  private addLuminairesToMap(luminaires: CitizenLuminaire[]) {
    for (let luminaire of luminaires) {
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => this.clickLuminaire(luminaire.id));
    }
  }

  private centerMapOnTheProject() {
    let request = {
      query: `${this.project.municipality}, ${this.project.state}, ${this.project.country}`,
      fields: ['name', 'geometry'],
    };
    let service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    });
  }

  private clickLuminaire(luminaireID: string): void {
    let luminaire = this.luminaires.find(luminaire => luminaire.id == luminaireID);
    if (luminaire.isSelected === false) {
      this.showRef.push(luminaire.reference_id);
      console.log(this.showRef);
    }
    else {
      this.showRef = this.showRef.filter(x => x !== luminaire.reference_id);
    }

    luminaire.marker.setAnimation(null);
    luminaire.toggleSelect();
  }

  private paintTheSearchBoxOfMap() {
    let input: any = document.getElementById('pac-input');
    var searchBox = new google.maps.places.SearchBox(input);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds());
    });


    searchBox.addListener('places_changed', () => {
      let data: any = input.value;
      data = data.trim();

      if (!isNaN(data)) {
        this.searchReferenceId(data);
        return;
      }

      let places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      let bounds = new google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    });

  }

  private setMarkersToMap(luminaires: CitizenLuminaire[], map: google.maps.Map): void {
    for (let luminaire of luminaires) {
      if (!luminaire.isSelected)
        luminaire.setMap(null);
    }
  }

  private searchReferenceId(value) {
    return this.reportLuminaireService.searchLuminaireInProject(this.slug, value)
      .subscribe((luminaires: CitizenLuminaire) => {
        if (luminaires == null) {
          this.toastr.singleError("No se encontro considencias con el texto: '" + value + "'");
        }
        let luminaire = luminaires;
        let position = new google.maps.LatLng(luminaire.location.latitude, luminaire.location.longitude);
        this.map.setCenter(position);
        this.map.setZoom(this.zoomToShowMarkes + 1);
        this.lumRef = luminaire.reference_id;
        this.showRef.push(luminaire.reference_id);
      }, (error) => {
        this.toastr.singleError("No se encontro considencias con el texto: '" + value + "'");
      });
  }

  private setMarkerRef() {
    if (!this.lumRef)
      return;

    let luminaire = this.luminaires.find(lum => lum.reference_id === this.lumRef);
    luminaire.isSelected = true;
    luminaire.marker.setAnimation(google.maps.Animation.BOUNCE);
    luminaire.upadeIcon();
    this.lumRef = null;
  }

}
