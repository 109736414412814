import { FormGroup } from '@angular/forms';

import { Password } from './password';

export class PasswordPayload implements Password {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;

  constructor(PasswordForm: FormGroup) {
    this.current_password = PasswordForm.get('current_password').value;
    this.new_password = PasswordForm.get('new_password').value;
    this.new_password_confirmation = PasswordForm.get('new_password').value;
  }

  toJson(): Object {
    const json = {
        current_password: this.current_password,
        new_password: this.new_password,
        new_password_confirmation: this.new_password_confirmation
    };

    return json;
  }
}
