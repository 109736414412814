import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { GetUsersResponse } from '@models/get-users-response';
import { GenericTable } from '@app/models/generic-table';
import { map } from 'rxjs/operators';
import { Users } from './users';

@Injectable()

export class UsersService {
  constructor(private httpClient: HttpClient) {}

  getUsers(form, page){
    
    const httpParams: HttpParams = new HttpParams().set('page', page);
    console.log(httpParams)
    return this.httpClient.post<GenericTable<Users>>(
      `${environment.SERVER_CONFIG}/api/Users/GetUsers`,form, { params: httpParams }
      ).pipe(
        map(elements => new GenericTable<Users>(elements))
      );
          console.log(httpParams)
  }

  reactiveUser(id: string): Observable<Object> {
    return this.httpClient.post<Object>(
      `${environment.SERVER_URL}/users/${id}/reactivate`, {}
    );
  }

  destroyUser(id: string): Observable<Object> {
    return this.httpClient.delete<Object>(
      `${environment.SERVER_URL}/users/${id}`, {}
    );
  }
}
