import { Component, Input, OnChanges, OnInit , NgZone} from '@angular/core';
import { Schedule } from '@app/models/devices';
import * as FusionCharts from "fusioncharts";


@Component({
  selector: 'app-device-calendar',
  templateUrl: './device-calendar.component.html',
  styleUrls: ['./device-calendar.component.scss']
})
export class DeviceCalendarComponent implements OnInit ,OnChanges{

  @Input() schedule: Schedule;

  // width = 600;
  // height = 400;
  // type = "msstepline";
  // dataFormat = "json";
  // dataSource ;

  Type: string;
  Width: string;
  Height: string;
  calendarDataSource: any;


  constructor() { }

  ngOnChanges(): void {

    this.Type = "timeseries";
    this.Width = "50%";
    this.Height = "400";
    this.calendarDataSource = {
      chart: {},
      extensions: {
        customRangeSelector: {
            enabled : "0"
        }
    },
      caption: {
        text: "Corriente"
      },
      // subcaption: {
      //   text: "Grocery & Footwear"
      // },
      series: "Type",
      yaxis: [
        {
          plot: "Schedule Time",
          title: "Schedule Time",

          format: {
            sufix: " A."
          }
        }
      ]
    }; 

    if(this.schedule)
    {
      this.calendarChart(); 
  // const data = {
  //     chart: {
  //     //  caption: "Postal Rates for First Class Stamp",
  //      // subcaption: "United States",
  //       showhovereffect: "1",
  //       drawverticaljoints: "1",
  //       useforwardsteps: "1",
  //      // numberprefix: "$",
  //       canvaspadding: "5",
  //     //  plottooltext: "Stamp rate in $label was <b>$datavalue</b>",
  //       theme: "fusion"
  //     },
  //     categories: [
  //       {
  //         category: this.schedule.calendarChartLabel
  //       }
  //     ],
  //     dataset: [
  //       {
  //         linethickness: "3",
  //         data: this.schedule.calendarChartValue
  //       }
  //     ]
  //   };
  //   this.dataSource = data;

  } 
  }
  calendarChart() {
    if(this.schedule.calendarCurrentList)
    var dataFetch = this.schedule.calendarCurrentList;
    var schemaFetch = [{
      "name": "Time",
      "type": "date",
      "format": "%b %-d, %Y, %-I:%-M"
    }, {
      "name": "Type",
      "type": "string"
    }, {
      "name": "Schedule Time",
      "type": "number"
    }]

    Promise.all([dataFetch, schemaFetch]).then(res => {
      const [data, schema] = res;
      const fusionDataStore = new FusionCharts.DataStore();
      const fusionTable = fusionDataStore.createDataTable(data, schema);
      this.calendarDataSource.data = fusionTable;
    });
  }
  
  ngOnInit() {}

}
