import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { MaterialPayload } from '@models/material-payload';
import { MaterialCategoriesResponse } from '@models/material_categories-response';
import { MaterialCategoryResponse } from '@models/material_category-response';
import { ShoppingCartItemPayload } from '@models/shopping_cart_item-payload';
import { GetShoppingCartResponse } from '@models/get-shopping-cart-response';
import { GetTroopersResponse } from '@models/get-troopers-response';
import { Trooper } from '@models/trooper';
import { map } from 'rxjs/operators';
import { Requisition } from '@models/requisition';
import { MaterialLogs } from '@app/models/material-logs';
import { GenericTable } from '@app/models/generic-table';
import { MaterialCategory, Material, ProjectMaterial } from './MaterialCategory';
import { AuthService } from '@app/shared/auth/auth.service';
import { Console } from 'console';
import { FormGroup } from '@angular/forms';
import { ShoppingCart } from '@app/models/shopping_cart';
import { TroopShoppingCart } from './cart/Cart';


@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  getCategoriesValues() {
    return this.httpClient
      .get<MaterialCategory[]>(`${environment.SERVER_WAREHOUSE}/api/Inventory/get-material-category`);
  }

  getMaterialsValues(category_id, page) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const params = new HttpParams()
      .set('category_id', category_id)
      .set('page', page);
    return this.httpClient.get<ProjectMaterial[]>(
      `${environment.SERVER_WAREHOUSE}/api/Inventory/get-list-category`, { params: params, headers: httpHeaders }
    );
  }

  // getChargedValue(): Observable<MaterialCategoriesResponse> {
  //   return this.httpClient
  //     .get<MaterialCategoriesResponse>(`${environment.SERVER_URL}/material_categories`);
  // }

  getCategory(id: string): Observable<MaterialCategoryResponse> {
    const params: HttpParams = new HttpParams()
      .set('is_active', 'true');
    return this.httpClient
      .get<MaterialCategoryResponse>(`${environment.SERVER_URL}/material_categories/${id}/project_materials`, { params });
  }

  // consumo de servicio nuevo migrado
  getShoppingCart_(user_id) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const params: HttpParams = new HttpParams()
      .set('user_id', user_id);
    return this.httpClient
      .get<ShoppingCart>(`${environment.SERVER_WAREHOUSE}/api/ShoppingCart/get-list-shopping-cart-items`,
        { params: params, headers: httpHeaders });
  }

  getTroopShoppingCart_(user_id) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const params: HttpParams = new HttpParams()
      .set('user_id', user_id);
    return this.httpClient
      .get<ShoppingCart>(`${environment.SERVER_WAREHOUSE}/api/ShoppingCart/get-troops-shopping-cart`,
        { params: params, headers: httpHeaders });
  }
  // consumo de servicio no migrado
  getShoppingCart(): Observable<GetShoppingCartResponse> {
    return this.httpClient
      .get<GetShoppingCartResponse>(`${environment.SERVER_URL}/shopping_cart`);
  }

  // servicio migrado...
  getTroopers_() {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    return this.httpClient
      .get<TroopShoppingCart[]>(`${environment.SERVER_WAREHOUSE}/api/ShoppingCart/get-troops-shopping-cart`, { headers: httpHeaders })
      .pipe(map(data => data.map(trooper => new TroopShoppingCart(trooper))));
  }

  // servicio no migrado
  getTroopers(): Observable<Trooper[]> {
    return this.httpClient
      .get<GetTroopersResponse>(`${environment.SERVER_URL}/troopers`)
      .pipe(map(data =>
        data.troopers.map(trooper => new Trooper(trooper))));
  }

  createMaterial(materialPayload: MaterialPayload, materialID: string): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_URL}/category/${materialID}/materials`,
      materialPayload.toFormData()
    );
  }
  // servicio a migrar
  createRequisition_(userId: string, destination: number): Observable<any> {
    return this.httpClient.post(
      `${environment.SERVER_URL}/users/${userId}/requisitions`,
      {
        requisition: {
          destination: destination
        }
      }
    );
  }

  // servicio no migrado
  createRequisition(userId: string, destination: number): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_URL}/users/${userId}/requisitions`,
      {
        requisition: {
          destination: destination
        }
      }
    );
  }

  updateRequisition(requesition: Requisition, status: number): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/requisitions/${requesition.id}`,
      {
        requisition: {
          status: status
        }
      }
    );
  }
  // este es el que se debe de utilizar paea actualizar algun material//
  // unificacion de servicios de update material con el registro del log de los materiales
  editMaterialValues(registerLog, updateProjectMat) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);
    const update = {
      valuesUpdateProjMat: updateProjectMat,
      registerLog: registerLog
    };
    return this.httpClient.post(`${environment.SERVER_WAREHOUSE}/api/Inventory/update-project-material`, update, { headers: httpHeaders });
  }




  // este quedara sin usar o no se si lo usen en otro lado se quedara por cualquier cosa
  updateMaterial(materialPayload: MaterialPayload, materialID: string): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/category/${materialID}/materials/${materialPayload.id}`,
      materialPayload.toFormData()
    );
  }

  addShoppingCart_(insert) {
    return this.httpClient.post(
      `${environment.SERVER_WAREHOUSE}/api/Inventory/register-shopping-card-item`, insert
    );
  }


  // servicio migrado de carrito
  updateShoppingCart_(shoppingCartItem_id) {
    const httpParams = new HttpParams()
      .set('shoppingCartItem_id', shoppingCartItem_id);
    return this.httpClient.get(
      `${environment.SERVER_WAREHOUSE}/api/ShoppingCart/delete-shopping-cart-item`, { params: httpParams }
    );
  }

  // consumo de servicio no migrado
  updateShoppingCart(shoppingCartItemPayload: ShoppingCartItemPayload): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/shopping_cart/items/${shoppingCartItemPayload.id}`,
      shoppingCartItemPayload.toJson()
    );
  }




  registerLog(form): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_WAREHOUSE}/api/MaterialLogs/RegisterLog`, form
    );
  }

  getLogs(page, reference_id, user_id, place, startDate, endDate) {
    const httpParams = new HttpParams()
      .set('page', page.toString())
      .set('reference_id', reference_id)
      .set('user_id', user_id)
      .set('place', place.toString())
      .set('startDate', startDate)
      .set('endDate', endDate)
    return this.httpClient.get<GenericTable<MaterialLogs>>(
      `${environment.SERVER_WAREHOUSE}/api/MaterialLogs/GetMaterialLogs`, { params: httpParams }
    ).pipe(
      map(logs => new GenericTable<MaterialLogs>(logs))
    )
  }

  getGuiltyPeople() {
    return this.httpClient.get(`${environment.SERVER_WAREHOUSE}/api/MaterialLogs/GetGuiltyPeople`);
  }
}
