import { Component, OnInit, Input } from '@angular/core';
import { TicketInformation } from '../ticket';

@Component({
  selector: 'app-ticket-table',
  templateUrl: './ticket-table.component.html'
})
export class TicketTableComponent implements OnInit {
  @Input() ticket: TicketInformation;

  constructor() {
  }

  ngOnInit() {
  }

  getClass(value: number) {
    const classes = {
      'oe__status-0': value === 0,
      'oe__status-1': value === 1,
      'oe__status-2': value === 2,
      'oe__status-3': value === 3,
      'oe__status-4': value === 4
    }
    return classes
  }

}
