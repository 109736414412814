import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isTroopSupervisor()) {
      this.router.navigate(['/download-app']);
    } else {
      return true
    }
  }

  isTroopSupervisor(): Boolean {
    const helper = new JwtHelperService();
    const token = this.authService.getToken();
    const user = helper.decodeToken(token);
    const roles = user['role_name_enums']

    return roles.find( data => { return data === 9})
  }
}
