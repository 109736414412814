import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ReportedElements } from '@models/reported_elements';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from '@models/user';
import { NewQuickTaskService } from '../new-quick-task/new-quick-task.service';
import { Toastr } from '@shared/toastr/toastr.service';
import { CurrentProjectService } from '@shared/cookies/current-project.service';
import { MapFuncAuxService } from '@shared/components/map-func-aux';
import { NewRepairTaskService } from './new-repair-task.service';
import { StyleMap } from '@models/styleMap';
import * as MomentTz from 'moment-timezone';
import { NewRepairTaskPayload } from '@models/new-repair-taks-payload';
import { Router } from '@angular/router';
import { Trooper } from '@models/trooper';

@Component({
  selector: 'app-new-repair-task',
  templateUrl: './new-repair-task.component.html',
  styleUrls: ['./new-repair-task.component.scss'],
  providers: [NewQuickTaskService, CurrentProjectService, MapFuncAuxService, NewRepairTaskService]
})
export class NewRepairTaskComponent implements OnInit {
  @Input() reported_elements: ReportedElements;
  @Output() cancelar:  EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('gmap') gmapElement: any;
  newReparationTaskForm: FormGroup = this.fromBuilder.group({
    type: [4],
    sector: [null, Validators.required],
    deadline: [null],
    user_id: [null],
    selected: [null, Validators.required],
    formattedDeadline: [null]
  });
  troopSupervisors: User[] = [];
  troopers: Trooper[] = [];
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  lstInfowindows = [];
  elementsSend = [];
  disabledBtn = false;

  constructor(
    private router: Router,
    private toastr: Toastr,
    private fromBuilder: FormBuilder,
    private mapFuncAux: MapFuncAuxService,
    private newRepairTaskService: NewRepairTaskService,
    private newQuickTaskService: NewQuickTaskService,
    private currentProjectService: CurrentProjectService
  ) { }

  ngOnInit() {
    this.getTroopSupervisors();
    this.paintMap();
    this.getSelected();
  }

  private paintMap(){
    let latitude = 19.4326009;;
    let longitude = -99.1333416;
    let zoom = 5;
    let project = this.currentProjectService.getCurrentProject()
    let mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    let request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    let service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if(status === google.maps.places.PlacesServiceStatus.OK){
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })
    this.map.addListener('click', () => {
      this.hideCircuit();
    });

  }

  private getTroopSupervisors(): void {
    this.newQuickTaskService
      .getTroopers()
      .subscribe(
        (getTroopSupervisorsResponse: Trooper[]) => {
          this.troopers = getTroopSupervisorsResponse.filter(x => x.is_active == true);
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  getSelected(){
    let lampposts = [];
    let luminaires = [];
    let promises = [];
    for(let circuit of this.reported_elements.circuits){
      for(let lamppost of circuit.lampposts){
        let copy = Object.assign({}, lamppost);
        copy.luminaires = copy.luminaires.filter( luminaire => luminaire.isSelected)
        lampposts.push(copy)
      }
    }
    for(let lamppost of this.reported_elements.lampposts){
      let copy = Object.assign({}, lamppost);
      copy.luminaires = copy.luminaires.filter( luminaire => luminaire.isSelected)
      lampposts.push(copy)
    }
    for(let luminaire of this.reported_elements.luminaires){
      let copy = Object.assign({}, luminaire);
      if(luminaire.isSelected)
        luminaires.push(copy)
    }
    Promise.all(promises).then( circuits => {
      let reported = new ReportedElements({ circuits: circuits, lampposts: lampposts, luminaires: luminaires })
      this.newReparationTaskForm.patchValue({ selected: reported})
      this.paintCircuists();
      this.paintLampposts();
      this.paintLuminaires();
    } )
  }

  paintCircuists(){
    let reported_elements = this.newReparationTaskForm.get('selected').value;
    for(let circuit of this.reported_elements.circuits){
      circuit.paintMarker(this.map);
      circuit.marker.addListener('click', () => {
        this.showCircuit(circuit);
        circuit.marker.setAnimation(null);
      });
      for(let lamppost of circuit.lampposts){
        for(let luminaire of lamppost.luminaires){
          luminaire.paintMarker(this.map);
          luminaire.marker.addListener('click', () => {
            this.showCircuit(circuit);
          });
          luminaire.marker.addListener('mouseover', () => {
            lamppost.event = 'hover';
            lamppost.upadeIcon();
          });
          luminaire.marker.addListener('mouseout', () => {
            lamppost.event = circuit.event;
            lamppost.upadeIcon();
          });
        }
      }
    }
  }

  paintLampposts(){
    let reported_elements = this.newReparationTaskForm.get('selected').value;
    for(let lamppost of this.reported_elements.lampposts){
      for(let luminaire of lamppost.luminaires){
        luminaire.paintMarker(this.map);
        luminaire.marker.addListener('click', () => {
          this.hideCircuit();
        });
        luminaire.marker.addListener('mouseover', () => {
          lamppost.event = 'hover';
          lamppost.upadeIcon();
        });
        luminaire.marker.addListener('mouseout', () => {
          lamppost.event = 'normal';
          lamppost.upadeIcon();
        });
      }
    }
  }

  paintLuminaires(){
    let reported_elements = this.newReparationTaskForm.get('selected').value;
    for(let luminaire of this.reported_elements.luminaires){
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        this.hideCircuit();
      });
    }
  }

  private showCircuit(circuit: any): void{
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();
    circuit.event = 'circuitShowing';
    circuit.upadeIcon();
  }

  private hideCircuit(): void{
    for(let circuit of this.reported_elements.circuits){
      circuit.event = circuit.isSelected ? 'selected' : 'normal';
      circuit.upadeIcon();
    }
  }

  cancel (){
    this.cancelar.emit(true);
  }

  changeTypeMap(value){
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  public SaveReparationTask(){
    if(this.newReparationTaskForm.valid) {
      if(this.newReparationTaskForm.get("deadline").value){
        const formDate = this.newReparationTaskForm.get("deadline").value;        
        const date = MomentTz(`${formDate.year}-${formDate.month}-${formDate.day}`).format();
        this.newReparationTaskForm.patchValue({ formattedDeadline: date });
      }
      const requestPayload = new NewRepairTaskPayload(this.newReparationTaskForm);

      console.log(requestPayload)
      this.disabledBtn = true;

      this.newRepairTaskService
        .createReparationTask(requestPayload)
        .subscribe( (response: any) => {
          this.toastr.success("Tarea creada");
          this.router.navigate(['/u/call-center/repair-tasks']);
          this.disabledBtn = false;
        }, (error: any) => {
          this.toastr.error(error);
          this.disabledBtn = false;
        })
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
      this.newReparationTaskForm.controls.deadline.markAsTouched();
      this.newReparationTaskForm.controls.user_id.markAsTouched();
      this.newReparationTaskForm.controls.sector.markAsTouched();
    }
  }

}
