import { PhotocellDamageLocation } from './../guaranty-report-model';
import { LuminaireDamageLocation } from './../../../../models/luminaire-damage-location-enum';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StyleMap } from '@app/models/styleMap';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { DetailGuarantyReport, GuarantyEvidence, LuminaireGuarantyReport } from '../guaranty-report-model';
import { GuarantyReportService } from '../guaranty-report.service';

@Component({
  selector: 'app-detail-guaranty-report',
  templateUrl: './detail-guaranty-report.component.html',
  styleUrls: ['./detail-guaranty-report.component.css']
})
export class DetailGuarantyReportComponent implements OnInit {

  luminaireDamage = Object.keys(LuminaireDamageLocation).filter(key => !isNaN(Number(LuminaireDamageLocation[key])));
  photocellDamage = Object.keys(PhotocellDamageLocation).filter(key => !isNaN(Number(PhotocellDamageLocation[key])));

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  guaranty_report_id;
  detailGuarantyReportFG: FormGroup;
  detailGuarantyReport: DetailGuarantyReport;
  constructor(
    private fb: FormBuilder,
    private currentProjectService: CurrentProjectService,
    private activatedRoute: ActivatedRoute,
    private toastr: Toastr,
    private guarantyReportService: GuarantyReportService
  ) { }

  ngOnInit() {
    this.detailGuarantyReportFG = this.fb.group({
      reference_id: [null],
      status: [null],
      report_type: [null],
      created_at: [null],
      repair_number: [null],
      third_image: [null],
      thirt_image_data: [null],
      fourth_image: [null],
      fourth_image_data: [null],
      fifth_image: [null],
      fifth_image_data: [null],
      sixth_image: [null],
      sixth_image_data: [null],
      seventh_image: [null],
      seventh_image_data: [null],
      eigth_image: [null],
      eigth_image_data: [null],
      // reference_id_lum: [null],
      user_name: [null],
      failure_evidence: [null],
      is_energized: [null],
      is_photocell_ok: [null],
      luminaire: {
        reference_id: null,
        latitude: null,
        longitude: null,
      },
      evidenceLuminaire: {
        comments: null,
        damage_location: null,
        first_image: null,
        luminaire_type_id: null,
        second_image: null,
        serial_number: null,
      },
      evidencePhotocell: {
        comments: null,
        damage_location: null,
        first_image: null,
        is_on: null,
        second_image: null,
        serial_number: null,

      },
      is_location_ok: [null],
    });
    this.guaranty_report_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getDetailGuarantyReports();
  }

  private getDetailGuarantyReports() {
    this.guarantyReportService.detailGuarantyReport(this.guaranty_report_id).subscribe((response: DetailGuarantyReport) => {
      this.detailGuarantyReport = response;
      this.paintMap();
      // this.populateFormGroup(response);
      this.paintMarkerLuminaire();
    });
  }

  changeStatus(event, type: number) {
    const update = {
      guaranty_report_id: this.guaranty_report_id,
      status: event.target.value,
      is_luminaire: false
    };
    if (type === 0) {
      update.is_luminaire = true;
    }
    this.guarantyReportService.changeStatusGuarantyReport(update).subscribe(x => {

    }, error => {
      this.toastr.error(error);
    });

  }


  paintMap() {
    const mapProperties = {
      center: new google.maps.LatLng(21.171642601705347, -86.8438888306971),
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);

    const project = this.currentProjectService.getCurrentProject();
    const service = new google.maps.places.PlacesService(this.map);
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    });
  }

  paintMarkerLuminaire() {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
    <g fill="none" fill-rule="evenodd" transform="translate(11 9)">
      <path fill="#F14336" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round"
      d="M1.48662295,5.46992268 C3.38709836,2.19976804 6.92837705,-1.39891394e-14 10.9827787,-1.39891394e-14 C17.0452213,-1.39891394e-14 21.9596967,4.91677835 21.9596967,10.9820619 C21.9596967,12.5032088 21.6499836,13.9512887 21.091959,15.2688402 C20.5326721,16.5863918 19.7238115,17.7720619 18.7256066,18.7655928 C17.8937541,19.6503351 17.1476475,20.5850515 16.4770082,21.5402448 C12.0834098,27.8027191 10.9827787,31.9525515 10.9827787,31.9525515 C10.9827787,31.9525515 9.78747541,27.1894072 4.91519672,20.7553608 C4.39819672,20.0742139 3.84017213,19.407049 3.23868852,18.7655928 C2.56931148,18.0997809 1.98577049,17.3469201 1.50582787,16.5248711 C0.552344262,14.8974613 0.00586065574,13.0037629 0.00586065574,10.9819716 C0.00586065574,8.9730799 0.544680328,7.08957474 1.48662295,5.46992268 Z"/>
      <path fill="#7E2D25" d="M10.9828689,5.13697165 C14.2090246,5.13697165 16.8237787,7.75448454 16.8237787,10.9821521 C16.8237787,14.2098196 14.2090246,16.825799 10.9828689,16.825799 C7.75671311,16.825799 5.14042623,14.2098196 5.14042623,10.9821521 C5.14042623,7.75448454 7.75680328,5.13697165 10.9828689,5.13697165 Z"/>
    </g>
  </svg>`;

    this.detailGuarantyReport.luminaire.marker = new google.maps.Marker({
      title: this.detailGuarantyReport.luminaire.reference_id,
      position: new google.maps.LatLng(this.detailGuarantyReport.luminaire.latitude, this.detailGuarantyReport.luminaire.longitude),
      icon: {
        url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
      },
      map: this.map
    });
  }


  public changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  populateFormGroup(detailGuarantyReport: DetailGuarantyReport) {
    this.detailGuarantyReportFG.patchValue({
      reference_id: detailGuarantyReport.reference_id,
      status: detailGuarantyReport.status,
      report_type: detailGuarantyReport.report_type,
      created_at: detailGuarantyReport.created_at,
      repair_number: detailGuarantyReport.repair_number,
      third_image: detailGuarantyReport.third_image,
      fourth_image: detailGuarantyReport.fourth_image,
      fifth_image: detailGuarantyReport.fifth_image,
      sixth_image: detailGuarantyReport.sixth_image,
      seventh_image: detailGuarantyReport.seventh_image,
      eigth_image: detailGuarantyReport.eigth_image,
      // reference_id_lum: detailGuarantyReport.reference_id_lum,
      user_name: detailGuarantyReport.user_name,
      failure_evidence: detailGuarantyReport.failure_evidence,
      is_energized: detailGuarantyReport.is_energized,
      is_photocell_ok: detailGuarantyReport.is_photocell_ok,
      is_location_ok: detailGuarantyReport.is_location_ok,
    });

    /* SE POPULA DETALLE DE LUMINARIA REPORTADA */
    // const detailLum = detailGuarantyReport.luminaire;
    // this.detailGuarantyReportFG.get('')

    /* SE POPULA EVIDENCIA DE LA LUMINARIA */
    const evidenceLum = detailGuarantyReport.guaranty_evidence.luminaire_evidence;
    this.detailGuarantyReportFG.get('evidenceLuminaire').value.serial_number = evidenceLum.serial_number;
    this.detailGuarantyReportFG.get('evidenceLuminaire').value.luminaire_type_id = evidenceLum.luminaire_type_id;
    this.detailGuarantyReportFG.get('evidenceLuminaire').value.second_image = evidenceLum.second_image;
    this.detailGuarantyReportFG.get('evidenceLuminaire').value.first_image = evidenceLum.first_image;
    this.detailGuarantyReportFG.get('evidenceLuminaire').value.comments = evidenceLum.comments;
    this.detailGuarantyReportFG.get('evidenceLuminaire').value.damage_location = evidenceLum.damage_location;
    this.detailGuarantyReportFG.get('evidenceLuminaire').value.accepted = evidenceLum.accepted;

    /* SE POPULA EVIDENCIA DE LA FOTOCELDA */
    const evidencePhotocell = detailGuarantyReport.guaranty_evidence.photocell_evidence;
    this.detailGuarantyReportFG.get('evidencePhotocell').value.comments = evidencePhotocell.comments;
    this.detailGuarantyReportFG.get('evidencePhotocell').value.damage_location = evidencePhotocell.damage_location;
    this.detailGuarantyReportFG.get('evidencePhotocell').value.first_image = evidencePhotocell.first_image;
    this.detailGuarantyReportFG.get('evidencePhotocell').value.is_on = evidencePhotocell.is_on;
    this.detailGuarantyReportFG.get('evidencePhotocell').value.second_image = evidencePhotocell.second_image;
    this.detailGuarantyReportFG.get('evidencePhotocell').value.serial_number = evidencePhotocell.serial_number;
    this.detailGuarantyReportFG.get('evidencePhotocell').value.accepted = evidencePhotocell.accepted;
  }

  setImageGuarantyReport(event: any, image_number: number) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        switch (image_number) {
          case 3:
            console.log(image_number);
            // this.detailGuarantyReportFG.value.thirt_image = reader.result;
            break;
        }
      };

    }
  }



}
