import { Component, OnInit } from '@angular/core';
import { RecoveredMaterialsService } from './recovered-materials.service';
import { MaterialCategory } from 'app/models/material_category';
import { MaterialCategoriesResponse } from 'app/models/material_categories-response';
import { Toastr } from 'app/shared/toastr/toastr.service';

@Component({
  selector: 'app-recovered-materials',
  templateUrl: './recovered-materials.component.html'
})
export class RecoveredMaterialsComponent implements OnInit {
  material_categories: MaterialCategory[] = [];

  constructor(
    private recoveredMaterialsService: RecoveredMaterialsService,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.recoveredMaterialsService.getChargedValue()
    .subscribe( (materialCategoriesResponse: MaterialCategoriesResponse) => {
      this.material_categories = materialCategoriesResponse.material_categories;
      this.material_categories = this.material_categories.sort( (a, b) => a.name.localeCompare(b.name) );
    }, error => {
      this.toastr.error(error);
    })
  }

}
