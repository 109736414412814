import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Task } from 'app/models/task';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TaskService } from '@pages/tasks/task/task.service';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ReportedElements } from 'app/models/reported_elements';
import { MapFuncAuxService } from 'app/shared/components/map-func-aux';
import { StyleMap } from 'app/models/styleMap';
import { CurrentProjectService } from 'app/shared/cookies/current-project.service';
import { Trooper } from 'app/models/trooper';
import * as MomentTz from 'moment-timezone';
import { GeneralViewService } from 'app/pages/general-view/general-view.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-repair-survey',
  templateUrl: './repair-survey.component.html',
  styleUrls: ['./repair-survey.component.scss'],
  providers: [TaskService, MapFuncAuxService, CurrentProjectService]
})
export class RepairSurveyComponent implements OnInit {
  @Input() task: Task;
  @ViewChild('gmap') gmapElement: any;
  repairTaskForm: FormGroup = this.formBuilder.group({
    id: [null, Validators.required],
    reference_id: [null, Validators.required],
    type: [null, Validators.required],
    municipality: [null, Validators.required],
    sector: [null, Validators.required],
    created_at: [null, Validators.required],
    deadline: [null, Validators.required],
    user: [null, Validators.required],
    last_interaction_at: [null, Validators.required],
    troop: [null, Validators.required]
  });
  reported_elements: ReportedElements;
  lstInfowindows = [];
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  troopers: Trooper[] = [];
  showAddButtons = true;
  materials_used = [];


  constructor(
    private generalViewService: GeneralViewService,
    private currentProjectService: CurrentProjectService,
    private mapFuncAux: MapFuncAuxService,
    private formBuilder: FormBuilder,
    private taskService: TaskService,
    private toastr: Toastr,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.setButtonVisibility();
    this.reported_elements = new ReportedElements({ circuits: this.task.circuits, lampposts: this.task.lampposts, luminaires: this.task.luminaires });
    this.getTroopSupervisors();
    this.populateForm();
    this.paintMap();
    this.paintCircuists();
    this.paintLampposts();
    this.paintLuminaires();

    this.taskService.getRepairMaterials(this.task.id).subscribe(response => {
      this.materials_used = response;
    });
  }

  setButtonVisibility() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    //console.log(decodeToken["role_name_enums"])
    if (decodeToken["role_name_enums"][0] === 8 || decodeToken["role_name_enums"][0] === 5 || decodeToken["role_name_enums"][0] === 11) {
      this.showAddButtons = false;
    }
  }

  populateForm() {
    let dateDeadline = new Date(this.task.deadline);
    let dCreated = new Date(this.task.created_at);
    let ngbCreated: NgbDate = new NgbDate(dCreated.getFullYear(), (dCreated.getMonth() + 1), dCreated.getDate());

    let ngbDeadline = null;
    if (this.task.deadline) {
      let dateDeadline = new Date(this.task.deadline);
      ngbDeadline = new NgbDate(dateDeadline.getFullYear(), (dateDeadline.getMonth() + 1), dateDeadline.getDate());
    }

    this.repairTaskForm = this.formBuilder.group({
      reference_id: [{ value: this.task.reference_id, disabled: true }, Validators.required],
      type: [{ value: this.task.type.name, disabled: true }, Validators.required],
      municipality: [{ value: this.task.municipality, disabled: true }, Validators.required],
      sector: [{ value: this.task.sector, disabled: true }, Validators.required],
      created_at: [{ value: ngbCreated, disabled: true }, Validators.required],
      deadline: [{ value: ngbDeadline, disabled: !this.showAddButtons }],
      user: [{ value: this.task.user ? this.task.user.id : null, disabled: !this.showAddButtons }],
      last_interaction_at: [{ value: null, disabled: true }, Validators.required],
      troop: [{ value: this.task.user ? this.task.user.troops.map(troop => troop.number).join(', ') : '', disabled: true }, Validators.required],
      comment: [{ value: this.task.comment, disabled: !this.showAddButtons }],
      formattedDeadline: [null]
    });

    if (this.task.user) {
      this.repairTaskForm.patchValue({
        user: this.task.user.id
      });
    }
    this.repairTaskForm
      .get('user')
      .valueChanges
      .subscribe((val) => {
        this.userChanged(val);
      });

    if (this.task.last_interaction_at) {
      let dLast = new Date(this.task.last_interaction_at);
      let ngbLast: NgbDate = new NgbDate(dLast.getFullYear(), (dLast.getMonth() + 1), dLast.getDate());
      this.repairTaskForm.patchValue({ last_interaction_at: ngbLast });
    }
  }

  private getTroopSupervisors(): void {
    this.taskService
      .getTroopers()
      .subscribe(
        (getTroopSupervisorsResponse: Trooper[]) => {
          this.troopers = getTroopSupervisorsResponse.filter(x => x.is_active == true);
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  changeStatusTask(value: boolean) {
    this.taskService.closeTaskReapir(this.task.id).subscribe(response => {
      this.toastr.success('Tarea actualizada.');
    }, error => {
      this.toastr.error('Ok');
    })
  }

  updateTask() {
    const comment = this.repairTaskForm.get('comment').value;
    let user = this.repairTaskForm.get('user').value;
    if (typeof (user) === 'object') {
      user = user.value;
    }

    if (this.repairTaskForm.get("deadline").value) {
      const formDate = this.repairTaskForm.get("deadline").value;
      const date = MomentTz(`${formDate.year}-${formDate.month}-${formDate.day}`).format();
      this.repairTaskForm.patchValue({ formattedDeadline: date });
    }
    const deadline = this.repairTaskForm.get('formattedDeadline').value;

    this.taskService
      .updateTask(this.task, comment, null, user, deadline)
      .subscribe(response => {
        this.toastr.success('Tarea actualizada.');
      },
        error => {
          this.toastr.error(error);
        });
  }

  printFile() {
    this.taskService.downloadMap(this.task.id);
  }

  private paintMap() {
    let latitude = 19.4326009;
    let longitude = -99.1333416;
    let zoom = 5;
    let project = this.currentProjectService.getCurrentProject()
    let mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    let request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    let service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })
    this.map.addListener('click', () => {
      this.hideCircuit();
    });
  }

  paintCircuists() {
    for (let circuit of this.reported_elements.circuits) {
      circuit.paintMarker(this.map);
      circuit.marker.addListener('click', () => {
        this.showCircuit(circuit);
        this.showInfoWindowCir(circuit);
        circuit.marker.setAnimation(null);
      });

      for (let lamppost of circuit.lampposts) {
        for (let luminaire of lamppost.luminaires) {
          if (luminaire.is_in_task == false) {
            continue
          }
          luminaire.paintMarker(this.map);
          luminaire.marker.addListener('click', () => {
            this.showCircuit(circuit);
            this.showInfoWindowLum(luminaire);
          });
          luminaire.marker.addListener('mouseover', () => {
            lamppost.event = 'hover';
            lamppost.upadeIcon();
          });
          luminaire.marker.addListener('mouseout', () => {
            lamppost.event = circuit.event;
            lamppost.upadeIcon();
          });
        }
      }
    }
  }

  paintLampposts() {
    for (let lamppost of this.reported_elements.lampposts) {
      for (let luminaire of lamppost.luminaires) {
        if (luminaire.is_in_task == false) {
          continue
        }
        luminaire.paintMarker(this.map);
        luminaire.marker.addListener('click', () => {
          this.hideCircuit();
          this.showInfoWindowLum(luminaire);
        });
        luminaire.marker.addListener('mouseover', () => {
          lamppost.event = 'hover';
          lamppost.upadeIcon();
        });
        luminaire.marker.addListener('mouseout', () => {
          lamppost.event = 'normal';
          lamppost.upadeIcon();
        });
      }
    }
  }

  paintLuminaires() {
    for (let luminaire of this.reported_elements.luminaires) {
      if (luminaire.is_in_task == false) {
        continue
      }
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        this.hideCircuit();
        this.showInfoWindowLum(luminaire);
      });
    }
  }

  private showCircuit(circuit: any): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();
    circuit.event = 'circuitShowing';
    if (circuit.repair) {
      circuit.upadeIcon(true);
    } else {
      circuit.upadeIcon();
    }
  }

  private hideCircuit(): void {
    let _circuit = this.reported_elements.circuits.find(__circuit => __circuit.event === 'circuitShowing');
    if (_circuit) {
      _circuit.event = _circuit.isSelected ? 'selected' : 'normal';
      if (_circuit.repair) {
        _circuit.upadeIcon(true);
      } else {
        _circuit.upadeIcon();
      }

    }
  }

  private showInfoWindowLum(luminaire: any) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    let strInfo = `
    <div>
      <div class=" my-0">
        <p class="mb-0"> <strong> Estado: </strong> </p>
      </div>
      <div class="">
        <p>${luminaire.statusName}</p>
      </div>
      ${luminaire.problems.length ? `
      <div class="">
        <p class="mb-0"> <strong> Fallas: </strong> </p>
      </div>
      <div class="">
        <p>${luminaire.problemsNames.join(', ')}</p>
      </div>
      `: ''}
      <div class="d-flex justify-content-between">
          <a href="u/luminaires/${luminaire.id}" onclick="return false;">
            <input id="seeMoreLuminaire" type="button" style=" cursor:pointer;" value="Ver mas" class="oe--button--info mr-1">
          </a>
          ${luminaire.repair.id ? `
            <a href="u/repairs/${luminaire.repair.id}" onclick="return false;">
              <input id="seeRepair" type="button" style="cursor:pointer;" value="Ver Reparación" class="oe--button--info">
            </a>
          ` : ''}
      </div>
      
    </div>
    `;
    let infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340
    });
    infowindow.open(this.map, luminaire.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      let btnSeeRepair = document.getElementById('seeRepair');
      let seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
      let btnInitRepair = document.getElementById('initRepair');
      if (btnSeeRepair) {
        btnSeeRepair.addEventListener('click', () => this.router.navigate([`/u/repairs/${luminaire.repair.id}`]));
      }

      if (btnInitRepair) {
        btnInitRepair.addEventListener('click', () => {
          this.taskService.initRepair(luminaire.id, this.task.id, this.task.user.id, this.task.user.troops[0].id).subscribe((response: any) => {
            this.router.navigate([`/u/repairs/${response.id}`]);
          })
        });
      }
      seeMoreLuminaire.addEventListener('click', () => this.router.navigate([`/u/luminaires/${luminaire.id}`]));
    }, 600);
  }

  private showInfoWindowCir(circuit: any) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    let strInfo = `
    <div>
      <div class=" my-0">
        <p class="mb-0"> <strong> Estado: </strong> </p>
      </div>
      <div>
        <p>${circuit.statusName}</p>
      </div>
      <div class="d-flex justify-content-between">
          <input id="seeMoreLuminaire" type="button" style=" cursor:pointer;" value="Ver mas" class="oe--button--info mr-1">
          ${circuit.repair.id ? `
            <input id="seeRepair" type="button" style="cursor:pointer;" value="Ver Reparación" class="oe--button--info">
          ` : ''}
      </div>
    </div>
    `;
    let infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340
    });
    infowindow.open(this.map, circuit.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      let btnSeeRepair = document.getElementById('seeRepair');
      let seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
      if (btnSeeRepair)
        btnSeeRepair.addEventListener('click', () => this.router.navigate([`/u/repairs/${circuit.repair.id}`]));
      seeMoreLuminaire.addEventListener('click', () => this.router.navigate([`/u/circuits/${circuit.id}`]));
    }, 500);
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  private userChanged(val: any) {
    let user = this.troopers.find(troop => troop.id === val);
    this.repairTaskForm.patchValue({
      troop: user.troops.map(troop => troop.number).join(', ')
    });
  }

}
