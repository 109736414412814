import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { GenericTable } from '@app/models/generic-table';
import { map } from 'rxjs/operators';
import { Troop, TroopMaterialItems, TroopRecoveryItems } from './troop';

@Injectable()
export class TroopsService {
  constructor(private httpClient: HttpClient) { }

  getTroops(page, form) {
    const params: HttpParams = new HttpParams().set('page', page);
    return this.httpClient
      .post<GenericTable<Troop>>(`${environment.SERVER_WAREHOUSE}/api/Troops/GetTroops`, form, { params: params }
      ).pipe(
        map(elements => new GenericTable<Troop>(elements))
      );
  }

  // updateTroop(item: Item): Observable<any> {
  //   return this.httpClient.put<any>(
  //     `${environment.SERVER_URL}/troop_inventory_items/${ item.id }`,
  //     {
  //       troop_inventory_item: {
  //         quantity: item.quantity
  //       }
  //     }
  //   );
  // }
  GetTroopRecoveryItems(id: string): Observable<TroopRecoveryItems[]> {
    const httpParam = new HttpParams()
    .set('id',id)
    return this.httpClient.get<TroopRecoveryItems[]>(
      `${environment.SERVER_WAREHOUSE}/api/Troops/GetTroopRecoveryItems`,{params: httpParam}
    );
  }

  GetTroopMaterilasItems(id: string): Observable<TroopMaterialItems[]> {
    const httpParam = new HttpParams()
    .set('id',id)
    return this.httpClient.get<TroopMaterialItems[]>(
      `${environment.SERVER_WAREHOUSE}/api/Troops/GetTroopMaterilasItems`,{params: httpParam}
    );
  }

  updateTroop(item): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_WAREHOUSE}/api/Troops/UpdateMaterialTroop`, item
    );
  }

  updateRecoveryTroop(item): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.SERVER_WAREHOUSE}/api/Troops/UpdateMaterialRecoveryTroop`, item
    );
  }
}
