import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { InstanceType } from '@app/models/instance-type.model';
import { environment } from '@environments/environment';
import { delay } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class StatusService {
    constructor(private httpClient: HttpClient) { }

    getInstances(): Observable<InstanceType[]> {
        const instanceIds = [
            'i-025e7b61077cc9b84',
            'i-00b0eea33e57e8c40',
            'i-0f3b29e9aa67e9e20',
            'i-034903b91caafdd46',
            'i-03431b8b30ba894d0',
            'i-0f5b760c81cb61ae2',
            'i-0cb24e3bc445ae6c9',
            'i-0aa7b24034a539a76',
            'i-0297bada722dcb115',
            'i-01f2ead0afd94908d',
            'i-0890f8026d1bc4628',
            'i-0d98fa41f92a29dfe',
            'i-0ee97edec523bd814',
            'i-0b7279e8f45e30857',
            'i-0a0bfd4cd01f3525f'
        ];
        const requests = instanceIds.map(id =>
            this.httpClient.post<InstanceType>(`${environment.STATUS_LAMBDA_URL + 'pingInstance-dev'}`, { id: id }).pipe(
                delay(10000)
            )
        );
        return forkJoin(requests);
    }

    rebootInstance(instanceId: string): Observable<any> {
        return of({ success: true, instanceId: instanceId}).pipe(
            delay(10000)
        )
    };

    rebootInstanceById(instanceId: string): Observable<any> {
        return this.httpClient.post(`${environment.STATUS_LAMBDA_URL + 'rebootInstance'}`, { id: instanceId })
    };
}
