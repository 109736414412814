import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TicketsStatus } from '@app/models/tickets-status';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { GraphStats } from '@app/models/graph-stats';

@Injectable({
  providedIn: 'root'
})
export class TicketsStatsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getTicketsStatus(filter: number, month: number, year: number, day: string) {
    const params: HttpParams = new HttpParams()
    .set('filter', filter.toString())
    .set('month', month.toString())
    .set('year', year.toString())
    .set('day', day)

    return this.httpClient.get<TicketsStatus[]>(
      `${environment.SERVER_CALLCENTER}/api/TicketsStatistics/GetTicketsStatus`, {params: params}
    ).pipe(map(response => response.map(ticket => new TicketsStatus(ticket))))
  }

  getLuminaresStatus(filter: number, month: number, year: number, day: string) {
    const params: HttpParams = new HttpParams()
    .set('filter', filter.toString())
    .set('month', month.toString())
    .set('year', year.toString())
    .set('day', day)
    return this.httpClient.get<TicketsStatus[]>(
      `${environment.SERVER_CALLCENTER}/api/TicketsStatistics/GetLuminairesStatus`, {params: params}
    ).pipe(map(response => response.map(luminaires  => new TicketsStatus(luminaires))))
  }

  getbarStackedGraph(comparative: boolean, yearOne: number, yearTwo: number) {
    const params: HttpParams = new HttpParams()
    .set('comparative', comparative.toString())
    .set('yearOne', yearOne.toString())
    .set('yearTwo', yearTwo.toString());

    return this.httpClient.get<GraphStats>(
      `${environment.SERVER_CALLCENTER}/api/TicketsStatistics/GetBarStackedGraph`, {params: params}
    ).pipe(map(response => new GraphStats(response)))
  }
}
