import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CurrentProjectService } from 'app/shared/cookies/current-project.service';
import { StyleMap } from 'app/models/styleMap';
import { MapFuncAuxService } from 'app/shared/components/map-func-aux';
import { TicketInformation, LuminairesInfo } from '../ticket';

@Component({
  selector: 'app-ticket-map',
  templateUrl: './ticket-map.component.html',
  providers: [CurrentProjectService, MapFuncAuxService]
})
export class TicketMapComponent implements OnInit {
  @Input() ticket: TicketInformation;
  @ViewChild('gmap') gmapElement: any;
  styleMap: StyleMap = new StyleMap();
  map: google.maps.Map;
  lstInfowindows = [];
  marker: google.maps.Marker[] = [];

  constructor(
    private mapFuncAux: MapFuncAuxService,
    private currentProjectService: CurrentProjectService
  ) { }

  ngOnInit() {
    console.log(this.ticket.luminaires);
    this.paintMap();
    this.paintLuminaires(this.ticket.luminaires.map(lums => new LuminairesInfo(lums)));
  }

  paintMap() {
    const mapProperties = {
      center: new google.maps.LatLng(this.ticket.luminaires[0].location.latitude, this.ticket.luminaires[0].location.longitude),
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const project = this.currentProjectService.getCurrentProject();
    const service = new google.maps.places.PlacesService(this.map);
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(14);
      }
    });

    this.map.addListener('click', () => {
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    });
  }

  paintLuminaires(luminaire: LuminairesInfo[]) {
    for (const item of luminaire) {
      item.paintMarker(this.map);
      item.marker.addListener('click', () => {
        this.showInfoWindowLum(item);
      });
    }
  }



        /*
      ${luminaire.problems.length ? `
            <div>
              <p class="mb-0"> <strong> Fallas: </strong> </p>
            </div>
            <div>
              <p>${luminaire.problemsNames.join(', ')}</p>
            </div>
            `
              : ''}
      */

  private showInfoWindowLum(luminaire: LuminairesInfo) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const strInfo = `
    <div>
      <div>
        <p class="mb-0"> <strong> Estado: </strong> </p>
      </div>
      <div>
        <p>${luminaire.statusName}</p>
      </div>



      <div class="d-flex justify-content-between">
        <div class="mr-1">
          <a href="u/luminaires/${luminaire.id}" onclick="return false;">
            <input id="seeMoreLuminaire" type="button" style=" cursor:pointer;" value="Ver mas" class="oe--button--info">
          </a>
        </div>
      </div>
    </div>
    `;
    const infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340
    });
    infowindow.open(this.map, luminaire.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      const seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
      seeMoreLuminaire.addEventListener('click', () => this.mapFuncAux.seeMoreLuminaire(luminaire.id));
    }, 500);
  }


/*
${luminaire.problems.length ? `
      <div>
        <p class="mb-0"> <strong> Fallas: </strong> </p>
      </div>
      <div>
        <p>${luminaire.problemsNames.join(', ')}</p>
      </div>
      `
        : ''}
*/








  // paintMap() {
  //   const latitude = 19.4326009;
  //   const longitude = -99.1333416;
  //   const zoom = 5;
  //   const mapProperties = {
  //     center: new google.maps.LatLng(latitude, longitude),
  //     zoom: zoom,
  //     mapTypeId: google.maps.MapTypeId.ROADMAP
  //   };

  //   this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
  //   const project = this.currentProjectService.getCurrentProject();
  //   const service = new google.maps.places.PlacesService(this.map);
  //   const request = {
  //     query: `${project.municipality}, ${project.state}, ${project.country}`,
  //     fields: ['name', 'geometry'],
  //   };

  //   service.findPlaceFromQuery(request, (results, status) => {
  //     if (status === google.maps.places.PlacesServiceStatus.OK) {
  //       this.map.setZoom(12);
  //       this.map.setCenter(results[0].geometry.location);
  //     }
  //   });

  //   // const service = new google.maps.places.PlacesService(this.map);
  //   // this.map.addListener('click', () => {
  //   //   this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
  //   // });
  //   this.paintLuminaires();
  // }

  // paintLuminaires() {
  //   for (const luminaire of this.ticket.luminaires) {
  //     console.log(luminaire);
  //     luminaire.paintMarker(this.map);
  //     luminaire.marker.addListener('click', () => {
  //        /// this.showInfoWindowLum(luminaire);
  //        console.log('click ')
  //     });
  //   }
  // }











  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

}
