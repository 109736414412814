import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DashboardDetail, IDashboardDetail } from './dashboard-detail';
import { AuthService } from '@app/shared/auth/auth.service';
import { Rpus } from '@app/models/rpus';

@Injectable({
  providedIn: 'root'
})
export class DashboardCfeService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  GetDashboardEnergy(yearSelectedSavin, filterApbt, filterApmt, filterOthersTariffs, filterLuminaires, filterMonthly, filterBimonthly) {
    // const httpParams = new HttpParams()
    //   .set('yearSelectedSavin', yearSelectedSavin)
    //   .set('filterApbt', filterApbt)
    //   .set('filterApmt', filterApmt)
    //   .set('filterOthersTariffs', filterOthersTariffs)
    //   .set('filterLuminaires', filterLuminaires)
    //   .set('filterMonthly', filterMonthly)
    //   .set('filterBimonthly', filterBimonthly);
    const query = {
      filterApbt: filterApbt,
      filterApmt: filterApmt,
      filterOthersTariffs: filterOthersTariffs,
      filterLuminaires: filterLuminaires,
      filterMonthly: filterMonthly,
      filterBimonthly: filterBimonthly,
      yearSelectedSavin: yearSelectedSavin
    }
    return this.httpClient.post<DashboardDetail>(
      `${environment.SERVER_ENERGY}/api/DashboardRpus/GetDashboardCFE`, query
    );
  }

  GetDashboardEnergyHistoric() {
    return this.httpClient.get<DashboardDetail>(
      `${environment.SERVER_ENERGY}/api/DashboardRpus/GetDashboardEnergyHistoric`, {}
    );
  }

  GetRpuInfo(circuitId) {
    const httpParams = new HttpParams()
      .set('circuitId', circuitId);
    return this.httpClient.get<Rpus>(
      `${environment.SERVER_ENERGY}/api/DashboardRpus/GetRpuInfo`, { params: httpParams }
    );
  }

}
