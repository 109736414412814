export interface ITrackMaterial {
    type: string,
    name: string,
    sum: number,
    date: Date
}

export class TrackMaterial implements ITrackMaterial {

    type: string;
    name: string;
    sum: number;
    date: Date;

    constructor(attrs: any) {
        this.type = attrs.type;
        this.name = attrs.name;
        this.sum = attrs.sum;
        this.date = new Date(attrs.date);
    }


}