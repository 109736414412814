import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { GetRolesResponse } from '@models/get-roles-response';
import { GetTroopsResponse } from '@models/get-troops-response';

@Injectable()

export class UserFormService {
  constructor(private httpClient: HttpClient) {}

  // HACK: This method should be in a shared service.
  getRoles(): Observable<GetRolesResponse> {
    return this.httpClient.get<GetRolesResponse>(
      `${environment.SERVER_URL}/roles`
    );
  }

  getTroops(): Observable<GetTroopsResponse> {
    return this.httpClient.get<GetTroopsResponse>(
      `${environment.SERVER_URL}/troops`
    )
  }
}