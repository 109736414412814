import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Task } from 'app/models/task';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TaskService } from '@pages/tasks/task/task.service';
import { Toastr } from '@shared/toastr/toastr.service';
import { Router } from '@angular/router';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MapFuncAuxService } from '@shared/components/map-func-aux';
import { GeneralViewService } from '@pages/general-view/general-view.service';
import { Trooper } from '@models/trooper';
import * as MomentTz from 'moment-timezone';
import { CurrentProjectService } from '@shared/cookies/current-project.service';
import { StyleMap } from '@models/styleMap';
import { LuminaireProjectWattage, LamppostProjectWattage, CircuitProjectWattage } from '@models/project_wattage';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { InstallationService } from './installation/installation.service';
import { InstallationsService } from '@app/pages/installations/installation.service';
import { TaskInstallationDetail } from '@app/pages/installations/intallation-detail';
import { TasksService } from '@app/pages/tasks/tasks.service';

@Component({
  selector: 'app-installation-survey',
  templateUrl: './installation-survey.component.html',
  providers: [MapFuncAuxService, TaskService, CurrentProjectService]
})
export class InstallationSurveyComponent implements OnInit {
  @Input() task: Task;
  type_task = '';
  taskInformation: TaskInstallationDetail;
  @ViewChild('gmap') gmapElement: any;
  troopers: Trooper[] = [];
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  lstInfowindows = [];
  circuits: CircuitProjectWattage[] = [];
  identifiers: any[] = [];
  installationTaskForm: FormGroup;
  showAddButtons = true;
  task_references = [];
  materials_used;

  constructor(
    private formBuilder: FormBuilder,
    private taskService: TaskService,
    /*Solo se ocupa para moverse a otra instalacion*/
    private tasksService_: TasksService,
    private toastr: Toastr,
    private router: Router,
    private modalService: NgbModal,
    private mapFuncAux: MapFuncAuxService,
    private generalViewService: GeneralViewService,
    private currentProjectService: CurrentProjectService,
    private authService: AuthService,
    private installationService: InstallationsService
  ) { }

  ngOnInit() {
    this.setButtonVisibility();
    console.log(this.task.type);
    this.installationTaskForm = this.formBuilder.group({
      id: [{ value: null, disabled: true }, Validators.required],
      reference_id: [{ value: null, disabled: true }, Validators.required],
      parent_task_id: [{ value: null, disabled: true }, Validators.required],
      type: [{ value: null, disabled: true }, Validators.required],
      municipality: [{ value: null, disabled: true }, Validators.required],
      sector: [{ value: null }, Validators.required],
      created_at: [{ value: null, disabled: true }, Validators.required],
      deadline: [{ value: null, disabled: !this.showAddButtons }, Validators.required],
      user: [{ value: null, disabled: !this.showAddButtons }, Validators.required],
      last_interaction_at: [{ value: null, disabled: true }, Validators.required],
      troop: [{ value: null, disabled: true }, Validators.required],
      comment: [{ value: null, disabled: !this.showAddButtons }],
      formattedDeadline: [null]
    });
    this.getTroopSupervisors();
    this.paintMap();
    this.paintCircuists();
    this.paintLampposts();
    this.paintLuminaires();
    this.getTaskReferences();

    this.installationService.getInstallMaterials(this.task.id).subscribe(response => {
      this.materials_used = response;
    });
  }
  builderInstallationForm() {
    this.installationTaskForm = this.formBuilder.group({
      id: [{ value: null, disabled: true }, Validators.required],
      reference_id: [{ value: null, disabled: true }, Validators.required],
      parent_task_id: [{ value: null, disabled: true }, Validators.required],
      type: [{ value: null, disabled: true }, Validators.required],
      municipality: [{ value: null, disabled: true }, Validators.required],
      sector: [{ value: null }, Validators.required],
      created_at: [{ value: null, disabled: true }, Validators.required],
      deadline: [{ value: null, disabled: !this.showAddButtons }, Validators.required],
      user: [{ value: null, disabled: !this.showAddButtons }, Validators.required],
      last_interaction_at: [{ value: null, disabled: true }, Validators.required],
      troop: [{ value: null, disabled: true }, Validators.required],
      comment: [{ value: null, disabled: !this.showAddButtons }],
      formattedDeadline: [null]
    });
  }


  getMaterialInstalled() {
    this.installationService.getInstallMaterials(this.task.id).subscribe(response => {
      this.materials_used = response;
    });
  }

  // getActionArrow(is_next: boolean) {
  //   this.installationService.getNextInstallation(this.task.id, is_next).subscribe(
  //     response => {
  //       console.log(response);
  //       this.tasksService_.getTaskById(response.task_id).subscribe(response_ => {
  //         this.task = new Task(response_.task);
  //         this.builderInstallationForm();
  //         this.getTroopSupervisors();
  //         this.paintMap();
  //         this.paintCircuists();
  //         this.paintLampposts();
  //         this.paintLuminaires();
  //         this.getTaskReferences();
  //         this.getMaterialInstalled();

  //       });
  //     }
  //   );

  // }

  getTaskReferences() {
    this.installationService.getTaskReferences(this.task.id).subscribe(response => {
      this.task_references = [...response];
    });
  }

  private getTroopSupervisors(): void {
    this.taskService
      .getTroopers()
      .subscribe(
        (getTroopSupervisorsResponse: Trooper[]) => {
          this.troopers = getTroopSupervisorsResponse.filter(x => x.is_active == true);
          this.populateForm();
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  onPressReference(reference: any) {
    if (reference['installation_id'] == null) {
      this.toastr.singleError('Sin instalación');
    } else {
      this.router.navigate(['/u/installation', reference['installation_id']]);
    }
  }

  setButtonVisibility() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    if (decodeToken['role_name_enums'][0] === 8 || decodeToken['role_name_enums'][0] === 5 || decodeToken['role_name_enums'][0] === 11) {
      this.showAddButtons = false;
    }
  }

  private populateForm(): void {

    let ngbDeadline = null;
    if (this.task.deadline) {
      const dateDeadline = new Date(this.task.deadline);
      ngbDeadline = new NgbDate(dateDeadline.getFullYear(), (dateDeadline.getMonth() + 1), dateDeadline.getDate());
    }
    const dCreated = new Date(this.task.created_at);
    let ngbCreated: NgbDate = new NgbDate(dCreated.getFullYear(), (dCreated.getMonth() + 1), dCreated.getDate());

    this.installationTaskForm = this.formBuilder.group({
      id: [{ value: this.task.id, disabled: true }, Validators.required],
      parent_task_id: [{ value: this.task.parent_task_id, disabled: true }, Validators.required],
      reference_id: [{ value: this.task.reference_id, disabled: true }, Validators.required],
      type: [{ value: this.task.type.name, disabled: true }, Validators.required],
      municipality: [{ value: this.task.municipality, disabled: true }, Validators.required],
      sector: [{ value: this.task.sector, disabled: true }, Validators.required],
      created_at: [{ value: ngbCreated, disabled: true }, Validators.required],
      deadline: [{ value: ngbDeadline, disabled: !this.showAddButtons }],
      user: [{ value: this.task.user ? this.task.user.id : null, disabled: true }],
      last_interaction_at: [{ value: null, disabled: true }, Validators.required],
      troop: [{ value: this.task.user ? this.task.user.troops.map(troop => troop.number).join(', ') : '', disabled: true }, Validators.required],
      comment: [{ value: this.task.comment, disabled: !this.showAddButtons }],
      formattedDeadline: [null]
    });

    if (this.task.last_interaction_at) {
      let dLast = new Date(this.task.last_interaction_at);
      let ngbLast: NgbDate = new NgbDate(dLast.getFullYear(), (dLast.getMonth() + 1), dLast.getDate());
      this.installationTaskForm.patchValue({ last_interaction_at: ngbLast });
    }
    if (this.task.user) {
      this.installationTaskForm.patchValue({
        user: this.task.user.id
      });
    }
    this.installationTaskForm
      .get('user')
      .valueChanges
      .subscribe((val) => {
        this.userChanged(val);
      });
  }

  private paintMap(): void {
    let latitude = 19.4326009;
    let longitude = -99.1333416;
    let project = this.currentProjectService.getCurrentProject()
    let zoom = 15;
    let mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    let request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    let service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })
    this.map.addListener('click', () => {
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
      this.hideCircuit();
    });
  }

  paintCircuists() {
    this.circuits = this.task.circuits.map(circuit => new CircuitProjectWattage(circuit));
    for (let circuit of this.circuits) {
      circuit.paintMarker(this.map);
      this.identifiers.push({ id: circuit.id, reference: circuit.identifier })
      circuit.marker.addListener('click', () => {
        this.hideCircuit();
        this.showCircuit(circuit);
        this.showInfoWindowCircuit(circuit);
      });
      for (let lamppost of circuit.lampposts) {
        for (let luminaire of lamppost.luminaires) {
          let map = null;
          if (luminaire.is_in_task) {
            map = this.map;
          }
          luminaire.paintMarker(map);
          this.identifiers.push({ id: luminaire.id, reference: luminaire.reference_id })
          luminaire.marker.addListener('click', () => {
            this.hideCircuit();
            this.showCircuit(circuit);
            this.clickLamppost(lamppost, luminaire);
          });
          luminaire.marker.addListener('mouseover', () => {
            lamppost.upadeIcon('hover');
          });
          luminaire.marker.addListener('mouseout', () => {
            lamppost.upadeIcon(circuit.event);
          });

        }
      }
    }
  }

  clickBaseTask() {
    this.router.navigate([`/u/tasks/${this.installationTaskForm.get('parent_task_id').value}`]);
    setTimeout(() => {
      window.location.reload()
    }, 500);
  }

  paintLampposts() {
    let lampposts = this.task.lampposts.map(lamp => new LamppostProjectWattage(lamp));
    for (let lamppost of lampposts) {
      for (let luminaire of lamppost.luminaires) {
        luminaire.paintMarker(this.map);
        this.identifiers.push({ id: luminaire.id, reference: luminaire.reference_id })
        luminaire.marker.addListener('click', () => {
          this.clickLamppost(lamppost, luminaire);
        });
        luminaire.marker.addListener('mouseover', () => {
          lamppost.upadeIcon('hover');
        });
        luminaire.marker.addListener('mouseout', () => {
          lamppost.upadeIcon('normal');
        });
      }
    }
  }

  private paintLuminaires(): void {
    let luminaires = this.task.luminaires.map(lum => new LuminaireProjectWattage(lum));
    for (let luminaire of luminaires) {
      if (luminaire.is_in_task) {
        this.identifiers.push({ id: luminaire.id, reference: luminaire.reference_id })
        luminaire.paintMarker(this.map);
        luminaire.marker.addListener('click', () => {
          this.hideCircuit();
          this.showInfoWindowLum(luminaire);
        });
      }
    }
  }

  private clickLamppost(lamppost: LamppostProjectWattage, luminaire: LuminaireProjectWattage) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.showInfoWindowLum(luminaire);
  }

  private showCircuit(circuit: CircuitProjectWattage): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();
    circuit.upadeIcon('circuitShowing');
  }

  private hideCircuit(): void {
    let _circuit = this.circuits.find(__circuit => __circuit.event == 'circuitShowing');
    if (_circuit) {
      let event = _circuit.isSelected ? 'selected' : 'normal';
      _circuit.upadeIcon(event);
    }
  }

  private showInfoWindowLum(luminaire: any) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    let strInfo = `
    <div> <div class='d-flex justify-content-between'> <a href='u/luminaires/${luminaire.id}' onclick='return false;'> <input id='seeMoreLuminaire' type='button' style='cursor:pointer;' value='Ver mas' class='oe--button--info mr-1'> </a> ${luminaire.installation.id ? `<a href='u/installation/${luminaire.installation.id}' onclick='return false;'> <input id='seeInstallation' href='u/installation/${luminaire.installation.id}' onclick='return false;' type='button' style='cursor:pointer;' value='Ver Instalación' class='oe--button--info ml-2'> </a> ` : ''} </div> </div>
    `;
    let infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340
    });
    infowindow.open(this.map, luminaire.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      let btnSeeInstallation = document.getElementById('seeInstallation');
      let seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
      if (btnSeeInstallation)
        btnSeeInstallation.addEventListener('click', () => this.router.navigate([`/u/installation/${luminaire.installation.id}`]));
      seeMoreLuminaire.addEventListener('click', () => this.router.navigate([`/u/luminaires/${luminaire.id}`]));
    }, 600);
  }

  private showInfoWindowCircuit(circuit: any) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    let strInfo = `
    <div>
      <div class='d-flex justify-content-between'>
          <a href='u/circuits/${circuit.id}' onclick='return false;'>
            <input id='seeMoreLuminaire' type='button' style='cursor:pointer;' value='Ver mas' class='oe--button--info mr-1'>
          </a>
          ${circuit.installation.id ? `
          <a href='u/installation/${circuit.installation.id}' onclick='return false;'>
            <input id='seeInstallation' href='u/installation/${circuit.installation.id}' onclick='return false;' type='button' style='cursor:pointer;' value='Ver Instalación' class='oe--button--info ml-2'>
          </a>
          ` : ''}
      </div>
    </div>
    `;
    let infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 350
    });
    infowindow.open(this.map, circuit.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      let btnSeeInstallation = document.getElementById('seeInstallation');
      let seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
      if (btnSeeInstallation)
        btnSeeInstallation.addEventListener('click', () => this.router.navigate([`/u/installation/${circuit.installation.id}`]));
      seeMoreLuminaire.addEventListener('click', () => this.router.navigate([`/u/circuits/${circuit.id}`]));
    }, 600);
  }

  private userChanged(val: any) {
    let user = this.troopers.find(troop => troop.id == val);
    this.installationTaskForm.patchValue({
      troop: user.troops.map(troop => troop.number).join(', ')
    });

  }

  updateTask() {
    const comment = this.installationTaskForm.get('comment').value;
    let user = this.installationTaskForm.get('user').value;
    if (typeof (user) == 'object') {
      user = user.value;
    }

    if (this.installationTaskForm.get('deadline').value) {
      const formDate = this.installationTaskForm.get('deadline').value;
      const date = MomentTz(`${formDate.year}-${formDate.month}-${formDate.day}`).format();
      this.installationTaskForm.patchValue({ formattedDeadline: date });
    }
    const deadline = this.installationTaskForm.get('formattedDeadline').value;
    const sector = this.installationTaskForm.get('sector').value;

    this.taskService
      .updateTask(this.task, comment, null, user, deadline, sector)
      .subscribe(response => {
        this.toastr.success('Tarea actualizada.');
      },
        error => {
          this.toastr.error(error);
        });
  }

  changeStatusTask(value: boolean) {
    this.taskService
      .closeTask(this.task.id, value)
      .subscribe(response => {
        this.toastr.success('Tarea actualizada.');
      },
        error => {
          this.toastr.error(error);
        });
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  getExportInstallationTask() {
    this.taskService.getExportInstallation(this.task.id)
      .subscribe((response) => {
        let date = new Date();
        const title = `${this.task.reference_id}_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.pdf`
        let blob = new Blob([response], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, `${title}`);
        } else {
          let a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `${title}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      },
        error => {
          this.toastr.error(error)
        });
  }

}
