import { Component, OnInit } from '@angular/core';
import { Judment } from '@app/models/judment';
import { GenericTable } from '@app/models/generic-table';
import { JudmentService } from './judment.service';
import { FormGroup , FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-judment',
  templateUrl: './judment.component.html',
  styleUrls: ['./judment.component.scss']
})
export class JudmentComponent implements OnInit {

  pagination: GenericTable<Judment>;
  judmentSearchForm: FormGroup;

  constructor(
    private judmentService: JudmentService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private toastr: Toastr
  ) {

    this.judmentSearchForm = fb.group({
      reference:"",
      type:0,
      noLuminaire:null,
      noGuaranty: "",
      startDate: [""],
      endDate: [""],
      status:0
    });
   }

  ngOnInit() {

    this.route.queryParamMap.subscribe(queries => {
      this.getElementsPaginated(0);
    });
  }

  printSelected() {
    this.spinner.show();
    if (this.pagination !== undefined) {
      const reports_luminaire = this.pagination.elements.filter(judment => judment.selected === true && judment.is_luminaire === true);
      const reports_photocell = this.pagination.elements.filter(judment => judment.selected === true && judment.is_luminaire === false);

      if (reports_luminaire.length === 0 && reports_photocell.length === 0) {
        this.toastr.singleError('Se debe seleccionar al menos un registro habilitado para reporte');
        this.spinner.hide();
        return;
      }
      if (reports_luminaire.length > 0) {
        const values = [];
        reports_luminaire.forEach(lum => {
          values.push(lum.guaranty_claims_opinion_id);
        });
        this.judmentService.downloadLuminairesReport(values);
      }

      if (reports_photocell.length > 0) {
        const values_ = [];
        reports_photocell.forEach(photocells => {
          values_.push(photocells.guaranty_claims_opinion_id);
        });
        this.judmentService.downloadPhotocellsReport(values_);
        this.spinner.hide();

      }
      this.spinner.hide();
    } else {
      this.toastr.singleError('No existen datos para generar un reporte');
      this.spinner.hide();
      return;
    }
  }

  getElementsPaginated(page: number) {
    this.judmentService.getList_Judment(this.judmentSearchForm.getRawValue(), page).subscribe(
      response => {
        if (response.elements.length === 0) {
          this.toastr.singleError('No hay datos para mostrar.');
          this.pagination = null;
          return;
        } else {
          this.pagination = response
        }
      }
    );

  }

  pageChange(page: number) {
    this.getElementsPaginated(page - 1);
  }

  onPageChange() {
    this.router.navigate(['/u/guaraties'], { relativeTo: this.route })
  }

  parseDate(date) {
    if (date != null) {
      return `${date.year}/${date.month}/${date.day}`;
    }
    return null;
  }

}
