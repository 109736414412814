import { Material } from "./material";
import { User } from "./user";

interface WarehouseStatsInterface {
    material_due_today: MaterialDueToday[]
    material_due_tomorrow: MaterialDueTomorrow
    users_who_pick_up_today: User[];
}

export class WarehouseStats implements WarehouseStatsInterface {
    material_due_today: MaterialDueToday[];
    material_due_tomorrow: MaterialDueTomorrow;
    users_who_pick_up_today: User[];

  constructor(attrs) {
    this.material_due_today = attrs.material_due_today.map( material => new MaterialDueToday(material));
    this.material_due_tomorrow = attrs.material_due_tomorrow.map( material => new MaterialDueTomorrow(material));
    this.users_who_pick_up_today = attrs.users_who_pick_up_today.map(user => new User(user));
  }
}

export class MaterialDueToday {
    id: string;
    quantity: number;
    total_sent_today: number;
    material: Material;

  constructor(attrs) {
    this.id = attrs.id;
    this.quantity = attrs.quantity;
    this.total_sent_today = attrs.total_sent_today;
    this.material = new Material(attrs.material);
  }
}

class MaterialDueTomorrow {
    quantity: number;
    material: Material;

  constructor(attrs) {
    this.quantity = attrs.quantity;
    this.material = new Material(attrs.material);
  }
}