import { Role } from './role';
import { Troop } from './troop';
import { Project } from './project';

interface UserInterface {
  id: string
  address: string
  assigned_tools: string
  cell_phone_number: string
  email: string
  last_name: string
  name: string
  roles: Role[]
  projects: Project[]
  troops: Troop[]
  is_active: string
  luminaire_count: number
  installed_luminaire_count: number
  finished_infrastructure_survey_count: number
}

export class User implements UserInterface {
  id: string;
  address: string;
  assigned_tools: string;
  cell_phone_number: string;
  email: string
  last_name: string;
  name: string;
  roles: Role[];
  projects: Project[];
  troops: Troop[];
  is_active: string;
  image_url: string;
  luminaire_count: number;
  installed_luminaire_count: number;
  finished_infrastructure_survey_count: number;


  fullName: string;

  constructor(attrs) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.last_name = attrs.last_name;
    this.email = attrs.email;
    this.roles = attrs.roles.map((role: Role) => new Role(role));
    this.troops = attrs.troops != null ? attrs.troops.map( troop => new Troop(troop)) : [];
    this.image_url = attrs.image_url;
    this.cell_phone_number = attrs.cell_phone_number;
    this.address = attrs.address;
    this.assigned_tools = attrs.assigned_tools;
    this.is_active = attrs.is_active;
    this.luminaire_count = attrs.luminaire_count;
    this.installed_luminaire_count = attrs.installed_luminaire_count;
    this.finished_infrastructure_survey_count = attrs.finished_infrastructure_survey_count;

    this.fullName = `${attrs.name} ${attrs.last_name} ${this.troops.length ? '(' + this.troops.map( troop => troop.number ).join(", ") + ')' : ''} `.trim();

    if(attrs.projects) this.projects = attrs.projects.map((project: Project) => new Project(project));
  }
}
