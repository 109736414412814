import { Component, OnInit } from '@angular/core';
import { VirtualCircuits } from './virtualcircuitsmodel';
import { GenericTable } from '@app/models/generic-table';
import { VirtualcircuitsService } from './virtualcircuits.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-virtual-circuits',
  templateUrl: './virtual-circuits.component.html',
  styleUrls: ['./virtual-circuits.component.scss']
})
export class VirtualCircuitsComponent implements OnInit {
  pagination: GenericTable<VirtualCircuits>;
  filterFormGroup: FormGroup;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private virtualCircuitsService: VirtualcircuitsService,
    private filterFormBuilder: FormBuilder,
    private toastr: Toastr
  ) { }

  ngOnInit() {
    this.filterFormGroup = this.filterFormBuilder.group({
      RPU: '',
      tariffType: -2,
      status: -1,
      is_VirtualCircuit: ''
    });
    this.getElementsPaginated(0);
  }

  getElementsPaginated(page: number) {
    this.virtualCircuitsService.getListVirtualCircuits(this.filterFormGroup, page).subscribe(
      response => {
        if (response.elements === null) {
          this.toastr.singleError('No existen elemnetos para mostrar');
          this.pagination = null;
        } else {
          this.toastr.success('Elementos cargados');
          this.pagination = response;
        }
      }
    );

  }

  exportCircuitDetailXLSX() {
    this.virtualCircuitsService.downloadCircuitDetailsCLSX(this.filterFormGroup);
  }

  onSearch() {
    // console.log(this.filterFormGroup.get('is_VirtualCircuit').value);
    this.virtualCircuitsService.getListVirtualCircuits(this.filterFormGroup, 0).subscribe(
      response => {
        if (response.elements === null) {
          this.toastr.singleError('No existen elemnetos para mostrar');
          this.pagination = null;
        } else {
          this.toastr.success('Elementos cargados');
          console.log(response.elements);
          this.pagination = response;
        }
      }
    );
  }


  pageChange(page: number) {
    this.getElementsPaginated(page - 1);
  }

  addCircuit() {
    this.router.navigate(['/u/virtual-circuits/add-circuit'], { relativeTo: this.route })
  }
}
