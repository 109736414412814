import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { ProjectResponse } from '@models/project-response';
import { ReportLuminairePayload } from '@models/report-luminaire-payload';
import { TicketResponse } from '@models/ticket-response';
import { Project } from '@models/project';
import { map } from 'rxjs/operators';
import { GetProjectResponse } from '@models/get-project-response';
import { CitizenLuminaire } from './citizen-luminaire';
import { Luminaire } from '@app/models/luminaire';
import { AuthService } from '@app/shared/auth/auth.service';
import { element } from '@angular/core/src/render3';
import { ReportLuminaireTicket } from '../call-center/report-by-user/report-luminaire';
@Injectable()

export class ReportLuminaireService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  // getLuminairesOfProject(slug: string, location: string, idsSelected: string[]): Observable<CitizenLuminaire[]> {
  //   let params: HttpParams = new HttpParams()
  //     .set('location', location);
  //   for(let id of idsSelected){
  //     params = params.append('excluding[]', id);
  //   }
  //   return this.httpClient.get<ProjectResponse>(
  //     `${environment.SERVER_URL}/projects/${slug}/luminaires`,
  //     { params: params }
  //   ).pipe( map( (projectResponse: ProjectResponse) => projectResponse.project.luminaires.map( luminaire => new CitizenLuminaire(luminaire)) ));
  // }

  getLuminairesOfProject(slug: string, location: string, idsSelected: string[]): Observable<CitizenLuminaire[]> {
    const params: HttpParams = new HttpParams().set('location', location);
    return this.httpClient.get<any>(
      `${environment.SERVER_CALLCENTER}/api/ReportLuminaires/GetLuminairesForCitizens/location=${location}/${slug}`
    ).pipe(map(response => response.map(luminaire => new CitizenLuminaire(luminaire))));
  }

  // searchLuminaireInProject(slug: string, reference: string): Observable<CitizenLuminaire[]> {
  //   let params: HttpParams = new HttpParams()
  //     .set('q_ref', reference);
  //   return this.httpClient.get<ProjectResponse>(
  //     `${environment.SERVER_URL}/projects/${slug}/luminaires`,
  //     { params: params }
  //   ).pipe( map( (projectResponse: ProjectResponse) => projectResponse.project.luminaires.map( luminaire => new CitizenLuminaire(luminaire)) ));
  // }

  searchLuminaireInProject(slug: string, reference: string): Observable<CitizenLuminaire> {
    return this.httpClient.get<any>(
      `${environment.SERVER_CALLCENTER}/api/ReportLuminaires/GetLuminaireForCitizens/${reference}/${slug}`
    ).pipe(
      map(response => new CitizenLuminaire(response)));
  }

  getCurrentProject(slug: string): Observable<Project> {
    return this.httpClient.get<ProjectResponse>(
      `${environment.SERVER_URL}/public_projects/${slug}`
    ).pipe(map(projectResponse => new Project(projectResponse.project)));
  }

  getProjectById(id: string): Observable<Project> {
    return this.httpClient.get<GetProjectResponse>(
      `${environment.SERVER_URL}/projects/${id}`
    ).pipe(map(projectResponse => new Project(projectResponse.project)));
  }


  createTicketLuminaires(form) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id);

    return this.httpClient.post<ReportLuminaireTicket>(
      `${environment.SERVER_CALLCENTER}/api/ReportLuminaires/register_report_luminaires`, form, { headers: httpHeaders }
    ).pipe(
      map(elements => new ReportLuminaireTicket(elements))
    );
  }

  createReportLuminaires(reportLuminairePayload: ReportLuminairePayload, slug: string): Observable<TicketResponse> {
    console.log(reportLuminairePayload, slug);
    return this.httpClient.post<TicketResponse>(
      `${environment.SERVER_URL}/projects/${slug}/tickets`,
      reportLuminairePayload.toJson()
    );
  }
}
