import { Component, OnInit, Input } from '@angular/core';
import { Item } from 'app/models/item';

@Component({
  selector: 'app-requisitions-materials-table',
  templateUrl: './materials-table.component.html'
})
export class MaterialsTableComponent implements OnInit {
  @Input() items: Item[];

  constructor() { }

  ngOnInit() {
  }

}
