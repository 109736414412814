import { Component, OnInit } from '@angular/core';
import { CategoryAlertsService } from './category-alerts.service';
import { ChartOptions } from 'chart.js';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  templateUrl: './category-alerts.component.html',
  styleUrls: ['./category-alerts.component.scss']
})
export class CategoryAlertsComponent implements OnInit {
  alertTypes = [
    { id: 0, name: 'High Volts' },
    { id: 1, name: 'Low Volts' },
    { id: 2, name: 'High Watts' },
    { id: 3, name: 'Low Watts' },
    { id: 4, name: 'High Temperature' },
    { id: 5, name: 'High Humidity' },
    { id: 6, name: 'High Light' },
    { id: 7, name: 'Low Light' },
    { id: 8, name: 'Out Of Service' },
    { id: 9, name: 'Secondary Switch Off' },
    { id: 10, name: 'No Schedule' }
  ];
  chartsConfig: any[] = [];
  // alertsData: any;
  // chartLabels: string[] = [];
  // chartData: any[] = [];
  // chartDatasets: any[] = [];

  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        color: '#FFFFFF',     
      }
    },
    scales: {
      xAxes: [{ // Changed from 'x' to 'xAxes'        
        gridLines: { // Changed from 'grid' to 'gridLines'
          display: false
        } // Hides the x-axis labels
      }],
      yAxes: [{ // Changed from 'y' to 'yAxes'
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  constructor(private categoryAlertsService: CategoryAlertsService) { }

  ngOnInit(): void {
    const observables = this.alertTypes.map(alertType =>
      this.categoryAlertsService.getAlertsCountByTypeOverDays(alertType.id)
        .pipe(map(data => ({ data, typeName: alertType.name })))
    );
  
    // Use forkJoin to wait for all API calls to complete
    forkJoin(observables).subscribe(results => {
      // Sort the results based on the predefined order of alertTypes
      const sortedResults = results.sort((a, b) =>
        this.alertTypes.findIndex(type => type.name === a.typeName) -
        this.alertTypes.findIndex(type => type.name === b.typeName)
      );
  
      // Process the data for each alert type
      sortedResults.forEach(result => {
        this.processAlertData(result.data, result.typeName);
      });
    });
  }


  processAlertData(data: any, typeName: string): void {    
    const chartData = [];
    const rawLabels = Object.keys(data); // Keep the original ISO date strings for data access
    const chartLabels = Object.keys(data).map(date => {
      const dateObj = new Date(date);
      return `${dateObj.getDate()}/${dateObj.getMonth() + 1}`;
    });
    

    rawLabels.forEach((isoDate) => {
      const count = data[isoDate] && data[isoDate][typeName] ? data[isoDate][typeName] : 0;
      chartData.push(count);
    });

    this.chartsConfig.push({
      data: [{
        data: chartData,
        label: typeName,
        backgroundColor: '#0047BB',
        borderColor: 'rgba(0, 123, 255, 1)',
        borderWidth: 1,
        hoverBackgroundColor: '#5C9AFF',
        hoverBorderColor: '#5C9AFF',
      }],
      labels: chartLabels,
      options: this.chartOptions,
      type: 'bar',
      typeName: typeName
    });
  }
}