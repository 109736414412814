import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TasksService } from '../tasks.service'
import { ActivatedRoute, Router } from '@angular/router';
import { Toastr } from '@shared/toastr/toastr.service';
import { Task } from '@models/task';
import { ProjectService } from '@shared/projects/project.service';
import { MapFuncAuxService } from '@shared/components/map-func-aux';
import { NewQuickTaskService } from '../new-quick-task/new-quick-task.service';
import { GetTroopSupervisorsResponse } from '@models/get-troop-supervisors-response';
import { LuminaireEnum } from '@models/luminaire-enum';
import { TechnologyEnum } from '@models/technology-enum';
import { User } from '@models/user';
import { Luminaire } from '@models/luminaire';
import { SurveyArea } from '@models/survey-area';
import { DeleteMenu } from '@shared/components/map-delete-node.component';
import { NewInfrastructureTaskPayload } from '@models/new-infrastructure-task-payload';
import { NewInfrastructureTaskService } from './new-infrastructure-task.service';
import * as MomentTz from 'moment-timezone';
import { StyleMap } from '@models/styleMap';
import { Lamppost } from '@models/lamppost';
import { Trooper } from '@models/trooper';
import { CurrentProjectService } from '@shared/cookies/current-project.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-infrastructure-task',
  templateUrl: './new-infrastructure-task.component.html',
  styleUrls: ['./new-infrastructure-task.component.scss'],
  providers: [TasksService, ProjectService, MapFuncAuxService, NewQuickTaskService, NewInfrastructureTaskService]
})

export class NewInfrastructureTaskComponent implements OnInit, OnDestroy {
  task: Task;
  infrastructureTaskForm: FormGroup = this.formBuilder.group({
    sector: [null, Validators.required],
    parent_task_id: [null],
    deadline:[null],
    formattedDeadline: [null],
    troop: [null],
    survey_areas: this.formBuilder.array([]),
    survey_areas_exist: this.formBuilder.array([])
  });
  troopSupervisors: User[] = [];
  troopers: Trooper[] = [];
  map: google.maps.Map;
  @ViewChild('gmap') gmapElement: any;
  markers = [];
  lstInfowindows: any[] = [];
  luminaireEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));
  styleMap: StyleMap = new StyleMap();
  survey_areas: SurveyArea[];
  polygons = [];
  newPolygons = [];
  lamppostsMarks = [];
  luminairesMarks = [];
  is_check_luminaries = true;
  project: any;
  currentUrl = '/u/tasks/new-infrastructure-task';
  project_max_page = 0;
  disabledBtn = false;
  destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(
    private formBuilder: FormBuilder,
    private tasksService: TasksService,
    private projectService: ProjectService,
    private mapFuncAuxService: MapFuncAuxService,
    private newQuickTaskService: NewQuickTaskService,
    private currentProjectService:CurrentProjectService,
    private newInfrastructureTaskService: NewInfrastructureTaskService,
    private mapFuncAux: MapFuncAuxService,
    private route: ActivatedRoute,
    private toastr: Toastr,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let taskId = params.get('id');
      this.paintMap();
      this.getProjectResources(1);
      this.getSurveyAreas();
      if(taskId){
        this.getTask(taskId);
        this.populateForm();
      } else {
        this.getTroopSupervisors();
      }
    });
  }

  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getSurveyAreas(){
    this.tasksService.getSurveyAreas()
    .subscribe( (surveyAreas: SurveyArea[]) => {
      this.survey_areas = surveyAreas;
      if(!this.task){
        this.addAllPolygon();
      }

    }, error => {
      this.toastr.error(error);
    })
  }

  private populateForm():void {
    let strProject = this.projectService.getProject();
    let project = JSON.parse(strProject);
    this.infrastructureTaskForm.patchValue({ parent_task_id: project.parent_task_id });
    this.getTroopSupervisors();
  }

  private getProjectResources(page: number){
    this.newInfrastructureTaskService.getProjectResources(page)
      .pipe(takeUntil(this.destroy$))
      .subscribe( (response: any) => {
      let project = response.project;

      let luminaires = project.luminaires.map( luminaire => new Luminaire(luminaire));
      this.addLuminaires(luminaires);

      let lampposts = project.lampposts.map( lamppost => new Lamppost(lamppost));
      this.addlampposts(lampposts);

      if(page == 1){
        this.project_max_page = project.max_page;
        this.cycleOfRequest();
      }
      if(page == this.project_max_page){
        this.toastr.success('Carga terminada.');
      }
    }, error => {
      this.toastr.error(error);
    });
  }

  private cycleOfRequest(){
    for(let i = 2; i <= this.project_max_page; i++){
      this.getProjectResources(i);
    }
  }

  private getTroopSupervisors(): void {
    this.newQuickTaskService
      .getTroopers()
      .subscribe(
        (getTroopSupervisorsResponse: Trooper[]) => {
          this.troopers = getTroopSupervisorsResponse.filter(x => x.is_active == true);
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  private getTask(taskId: string):void {
    this.tasksService.getTaskById(taskId).subscribe( response => {
      this.task = new Task(response.task);
      this.infrastructureTaskForm.patchValue({ sector: this.task.sector });
      this.infrastructureTaskForm.patchValue({ parent_task_id: taskId });
      this.addLuminaires(this.task.luminaires);
      this.addlampposts(this.task.lampposts);
      this.addTaskPolygons();
      this.addAllPolygon();
    }, error => {
      this.toastr.error(error);
    });
  }

  private paintMap(): void{
    let project = this.currentProjectService.getCurrentProject();
    let latitude;
    let longitude;
    let zoom = 15;
    let request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };
    if(!longitude){
      latitude = 19.4326009;
      longitude = -99.1333416;
      zoom = 5;
    }
    let mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);

    let service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if(status === google.maps.places.PlacesServiceStatus.OK){
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })

    var drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: {
        editable: true,
        draggable: true,
        fillOpacity: 0.25
      }
    });
    google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
      let deleteMenu = new DeleteMenu();
      let polygonPath = polygon.getPath();

      this.addPointToForm(polygon);

      google.maps.event.addListener(polygon, 'rightclick', function(e) {
        // Check if click was on a vertex control point
        if (e.vertex == undefined) {
          return;
        }

        deleteMenu.open(this.map, polygon.getPath(), e.vertex);
      });

      google.maps.event.addListener(polygonPath, 'set_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'insert_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygonPath, 'remove_at', (_) => {
        this.addPointToForm(polygon);
      });

      google.maps.event.addListener(polygon, 'dragend', (_) => {
        this.addPointToForm(polygon);
      })
    });

    drawingManager.setMap(this.map);
  }

  private addLuminaires(luminaires: Luminaire[]): void{
    for(let luminaire of luminaires){
      luminaire.paintMarker(this.map);
      this.markers.push(luminaire.marker);
      this.luminairesMarks.push(luminaire.marker);
    }
  }

  private addAllPolygon(){
    let polygonCoords = [];
    for(let survey_area of this.survey_areas){
      if(this.task)
      if(this.task.survey_areas.find( element => element['id'] == survey_area.id))
        continue;
      polygonCoords = survey_area.points.map(point => new google.maps.LatLng(point.latitude, point.longitude));
      let myPolygon = new google.maps.Polygon({
        paths: polygonCoords,
        strokeColor: '#4885E6',
        strokeOpacity: 0.8,
        fillColor: '#4885E1',
        fillOpacity: 0.25,
        map: this.map
      });
      myPolygon.addListener('click', (event) => this.clickPolygon(myPolygon, survey_area.id));
      this.polygons.push(myPolygon);
      polygonCoords = [];
    }
  }

  private addTaskPolygons(){
    let polygonCoords = [];
    for(let survey_area of this.task.survey_areas){
      polygonCoords = survey_area.points.map(point => new google.maps.LatLng(point.latitude, point.longitude));
      let myPolygon = new google.maps.Polygon({ paths: polygonCoords, fillOpacity: 0.25 });
      this.infrastructureTaskForm.get('survey_areas_exist').value.push(survey_area.id);
      myPolygon.setMap(this.map);
      polygonCoords = [];
    }
  }

  private addlampposts(lampposts:Lamppost[]): void {
    for(let lamppost of lampposts){
      for(let luminaire of lamppost.luminaires){
        luminaire.paintMarker(this.map);
        luminaire.marker.addListener('mouseover', () => {
          lamppost.upadeIcon('hover');
        });
        luminaire.marker.addListener('mouseout', () => {
          lamppost.upadeIcon('normal');
        });
        this.lamppostsMarks.push(luminaire.marker);
      }
    }
  }

  private addPointToForm(polygon: any) {
    if (this.isExistingPolygon(polygon)) {
      this.replacePolygon(polygon)
    } else {
      this.infrastructureTaskForm.get('survey_areas').value.push(polygon);
    }
  }

  private isExistingPolygon(polygon: any): boolean {
    let polygons = this.infrastructureTaskForm.get('survey_areas').value;
    let existingPolygon = polygons.find(_polygon => _polygon.zIndex === polygon.zIndex);

    return existingPolygon ? true : false;
  }

  private replacePolygon(polygon: any): void {
    let polygons = this.infrastructureTaskForm.get('survey_areas').value;
    let existingPolygonIndex = polygons.findIndex(_polygon => _polygon.zIndex === polygon.zIndex);

    polygons[existingPolygonIndex] = polygon;
  }

  private setMaptoPolygon(map: any):void{
    for(let i = 0; i < this.polygons.length; i++){
      this.polygons[i].setMap(map);
    }
  }

  private setMaptoLampposts(map: any):void{
    for(let i = 0; i < this.lamppostsMarks.length; i++){
      this.lamppostsMarks[i].setMap(map);
    }
  }

  private setMaptoLuminaires(map: any):void{
    for(let i = 0; i < this.luminairesMarks.length; i++){
      this.luminairesMarks[i].setMap(map);
    }
  }

  private setMapOnAll(map:any) {
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }

  private clickPolygon(polygon: google.maps.Polygon, id: string): void{
    if(polygon.get('fillColor') == 'black'){
      var pos = this.infrastructureTaskForm.get('survey_areas_exist').value.indexOf(id);
      this.infrastructureTaskForm.get('survey_areas_exist').value.splice(pos, 1);
      polygon.setOptions({'fillColor':'#4885E1', strokeColor: '#4885E6'});
    } else {
      polygon.setOptions({'fillColor':'black', strokeColor: 'black'});
      this.infrastructureTaskForm.get('survey_areas_exist').value.push(id);
    }
  }

  public SaveInfrastructureTask(){
    if(this.infrastructureTaskForm.valid) {
      if(this.infrastructureTaskForm.get("deadline").value){
        const formDate = this.infrastructureTaskForm.get("deadline").value;
        const date = MomentTz(`${formDate.year}-${formDate.month}-${formDate.day}`).format();
        this.infrastructureTaskForm.patchValue({ formattedDeadline: date });
      }
      const requestPayload = new NewInfrastructureTaskPayload(this.infrastructureTaskForm);
      this.disabledBtn = true;
      this.newInfrastructureTaskService
        .createInfrastructureTask(requestPayload)
        .subscribe( (response: any) => {
          let messages = response.messages;
          this.toastr.success(messages.join(' '));
          this.router.navigate(['/u/tasks']);
          this.disabledBtn = false;
        }, (error: any) => {
          this.toastr.error(error);
          this.disabledBtn = false;
        })
    } else {
      this.toastr.singleError('Operación fallida. Revisar campos requeridos.');
      this.infrastructureTaskForm.controls.deadline.markAsTouched();
      this.infrastructureTaskForm.controls.troop.markAsTouched();
      this.infrastructureTaskForm.controls.sector.markAsTouched();
    }
  }

  setBtnLuminariesClasses(){
    let classes = {
      'btn-primary': this.luminairesMarks.length ? this.luminairesMarks[0].getMap() : false,
      'oe--btn--text-white': this.luminairesMarks.length ? this.luminairesMarks[0].getMap() : false,
      'oe--btn--border': this.luminairesMarks.length ? !this.luminairesMarks[0].getMap() : true
    }
    return classes
  }

  setBtnPoligonosClasses(){
    let classes = {
      'btn-primary': this.polygons.length ? this.polygons[0].getMap() : false,
      'oe--btn--text-white': this.polygons.length ? this.polygons[0].getMap() : false,
      'oe--btn--border': this.polygons.length ? !this.polygons[0].getMap() : true
    }
    return classes
  }

  setBtnLamppostsClasses(){
    let classes = {
      'btn-primary': this.lamppostsMarks.length ? this.lamppostsMarks[0].getMap() : false,
      'oe--btn--text-white': this.lamppostsMarks.length ? this.lamppostsMarks[0].getMap() : false,
      'oe--btn--border': this.lamppostsMarks.length ? !this.lamppostsMarks[0].getMap() : true
    }
    return classes
  }

  changePolyg(){
    if(this.polygons.length && this.polygons[0].getMap()){
      this.setMaptoPolygon(null);
    } else {
      this.setMaptoPolygon(this.map);
    }
  }

  changeLampposts(){
    if(this.lamppostsMarks.length && this.lamppostsMarks[0].getMap()){
      this.setMaptoLampposts(null);
    } else {
      this.setMaptoLampposts(this.map);
    }
  }

  changeLuminaries(){
    if(this.luminairesMarks.length && this.luminairesMarks[0].getMap()){
      this.setMaptoLuminaires(null);
    } else {
      this.setMaptoLuminaires(this.map);
    }
  }

  changeTypeMap(value){
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

}
