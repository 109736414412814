import { Component, OnInit, Input, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MapFuncAuxService } from '@shared/components/map-func-aux';
import { TaskService } from '@pages/tasks/task/task.service';
import { Task } from '@models/task';
import { LuminairePayload } from '@models/luminaire-payload';
import { Luminaire } from '@models/luminaire';
import { Toastr } from '@shared/toastr/toastr.service';
import * as Driver from 'driver.js';
import { LuminaireEnum } from '@models/luminaire-enum';
import { TechnologyEnum } from '@models/technology-enum';
import { Router } from '@angular/router';
import { Circuit } from '@models/circuit';
import { GetLamppostsResponse } from '@models/get-lampposts-response';
import { CreateCircuitPayload } from './create-circuit-payload';
import { Lamppost } from '@models/lamppost';
import { StyleMap } from '@models/styleMap';
import { Trooper } from '@models/trooper';
import * as MomentTz from 'moment-timezone';
import { GeneralViewService } from '@pages/general-view/general-view.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-infrastructure-survey',
  templateUrl: './infrastructure-survey.component.html',
  styleUrls: ['./infrastructure-survey.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MapFuncAuxService, TaskService]
})
export class InfrastructureSurveyComponent implements OnInit, OnDestroy {
  @Input() task: Task;
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('contentAdv') contentAdv: any;
  @ViewChild('contentCreteCircuit') createElement: any;
  @ViewChild('contentAvisoExit') elementAvisoExit: any;
  @ViewChild('contentHasCircuit') elementHasCircuit: any;
  @ViewChild('contentHasLampInCircuit') elementModal: any;
  infrastructureTaskForm: FormGroup;
  circuitForm: FormGroup = this.formBuilder.group(
    {
      latitude: [{ value: null, disabled: true }, Validators.required],
      longitude: [{ value: null, disabled: true }, Validators.required]
    }
  );
  commentForm: FormGroup = this.formBuilder.group({
    comment: [null]
  });
  map: google.maps.Map;
  markers: google.maps.Marker[] = [];
  lstInfowindows = [];
  editMode = '';
  driver: Driver = new Driver({
    animate: true,
    allowClose: false,
    keyboardControl: false
  });
  styleMap: StyleMap = new StyleMap();
  txtModalAdv = '';
  lstCircuitsMarker: google.maps.Marker[] = [];
  luminaireEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));
  selectedMarkers = [];
  lamppostsSelected = [];
  lstMarkToHide = [];
  circuitIDSelect: string;
  messageCircuitTxt = '';
  troopers: Trooper[] = [];
  project_max_page = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();
  showAddButtons = true;


  constructor(
    private formBuilder: FormBuilder,
    private taskService: TaskService,
    private toastr: Toastr,
    private router: Router,
    private modalService: NgbModal,
    private mapFuncAux: MapFuncAuxService,
    private generalViewService: GeneralViewService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.setButtonVisibility();
    this.infrastructureTaskForm = this.formBuilder.group({
      id: [{ value: null, disabled: true }, Validators.required],
      reference_id: [{ value: null, disabled: true }, Validators.required],
      parent_task_id: [{ value: null, disabled: true }, Validators.required],
      type: [{ value: null, disabled: true }, Validators.required],
      municipality: [{ value: null, disabled: true }, Validators.required],
      sector: [{ value: null, disabled: true }, Validators.required],
      created_at: [{ value: null, disabled: true }, Validators.required],
      deadline: [{ value: null, disabled: !this.showAddButtons }, Validators.required],
      user: [{ value: null, disabled: !this.showAddButtons }, Validators.required],
      last_interaction_at: [{ value: null, disabled: true }, Validators.required],
      troop: [{ value: null, disabled: true }, Validators.required],
      comment: [{ value: null, disabled: !this.showAddButtons }],
      formattedDeadline: [null]
    });
    this.setTaskInformation();
    this.getTroopSupervisors();

  }

  setTaskInformation() {
    this.paintMap();
    this.populateForm();
    this.addPolygon();
    this.addLuminaires();
    this.addCircuits();
    this.addlampposts(this.task.lampposts);

  }

  private changeZoom() {
    // const lat_long = { lat: this.map.getCenter().lat(), long: this.map.getCenter().lng() };
    this.getProjectLamppost(this.map.getCenter().lat(), this.map.getCenter().lng());

  }

  setButtonVisibility() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    console.log(decodeToken['role_name_enums'])
    if (decodeToken['role_name_enums'][0] === 8 || decodeToken['role_name_enums'][0] === 5) {
      this.showAddButtons = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private getProjectLamppost(latitude: number, longitude: number) {
    this.taskService.getNewProjectLampposts(latitude, longitude, this.task.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((getLamppostsResponse: GetLamppostsResponse) => {
        const lampposts = getLamppostsResponse.lampposts.map(lamppost => new Lamppost(lamppost));

        const current_lampposts: Lamppost[] = [];
        for (const lamppost of lampposts) {
          if (this.task.lampposts.find(__lampost => __lampost.id == lamppost.id) != undefined) {
            continue;
          }
          if (this.task.circuits.find(circuit => circuit.lampposts.find(__lampost =>
            __lampost.id == lamppost.id) != undefined) != undefined) {
            continue;
          }

          this.task.lampposts.push(lamppost);
          current_lampposts.push(lamppost)
        }


        this.addlampposts(current_lampposts);
        for (const lamppost of current_lampposts) {
          for (const luminaire of lamppost.luminaires) {
            luminaire.linkedSituation = 'linkedOutsideTheTask';
            luminaire.upadeIcon('normal');
          }
        }
        this.mapFuncAux.setMapOnAllMarkers(this.map, this.markers);

      }, error => {
        this.toastr.error(error);
      });
  }


  private populateForm(): void {
    let ngbDeadline = null;
    if (this.task.deadline) {
      const dateDeadline = new Date(this.task.deadline);
      ngbDeadline = new NgbDate(dateDeadline.getFullYear(), (dateDeadline.getMonth() + 1), dateDeadline.getDate());
    }
    const dCreated = new Date(this.task.created_at);
    const ngbCreated: NgbDate = new NgbDate(dCreated.getFullYear(), (dCreated.getMonth() + 1), dCreated.getDate());

    this.infrastructureTaskForm = this.formBuilder.group({
      id: [{ value: this.task.id, disabled: true }, Validators.required],
      parent_task_id: [{ value: this.task.parent_task_id, disabled: true }, Validators.required],
      reference_id: [{ value: this.task.reference_id, disabled: true }, Validators.required],
      type: [{ value: this.task.type.name, disabled: true }, Validators.required],
      municipality: [{ value: this.task.municipality, disabled: true }, Validators.required],
      sector: [{ value: this.task.sector, disabled: true }, Validators.required],
      created_at: [{ value: ngbCreated, disabled: true }, Validators.required],
      deadline: [{ value: ngbDeadline, disabled: false }],
      user: [{ value: this.task.user ? this.task.user.id : null }],
      last_interaction_at: [{ value: null, disabled: true }, Validators.required],
      troop: [{ value: this.task.user ? this.task.user.troops.map(troop => troop.number).join(', ') : '', disabled: true }, Validators.required],
      comment: [this.task.comment],
      formattedDeadline: [null]
    });

    if (this.task.last_interaction_at) {
      const dLast = new Date(this.task.last_interaction_at);
      const ngbLast: NgbDate = new NgbDate(dLast.getFullYear(), (dLast.getMonth() + 1), dLast.getDate());
      this.infrastructureTaskForm.patchValue({ last_interaction_at: ngbLast });
    }
    if (this.task.user) {
      this.infrastructureTaskForm.patchValue({
        user: this.task.user.id
      });
    }
    this.infrastructureTaskForm
      .get('user')
      .valueChanges
      .subscribe((val) => {
        this.userChanged(val);
      });
  }

  private paintMap(): void {
    let latitude = this.task.centroid.latitude;
    let longitude = this.task.centroid.longitude;
    let zoom = 15;
    if (!longitude) {
      latitude = 19.4326009;
      longitude = -99.1333416;
      zoom = 5;
    }
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    this.map.addListener('click', () => {
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
      if (this.editMode != 'join circuit') {
        this.hideCircuit();
      }
    });
    this.map.addListener('zoom_changed', () => this.changeZoom());
    this.map.addListener('dragend', () => this.changeZoom());
    this.map.addListener('rightclick', (event) => this.createCircuit(event));
    this.getProjectLamppost(latitude, longitude);

  }

  private addPolygon(): void {
    let polygonCoords = [];
    for (const survey_area of this.task.survey_areas) {
      polygonCoords = survey_area.points.map(point => new google.maps.LatLng(point.latitude, point.longitude));
      const myPolygon = new google.maps.Polygon({ paths: polygonCoords, fillOpacity: 0.25 });
      myPolygon.addListener('click', () => {
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows)
        this.hideCircuit();
      });
      myPolygon.addListener('rightclick', (event) => this.createCircuit(event));
      myPolygon.setMap(this.map);
      polygonCoords = [];
    }
  }

  private addLuminaires(): void {
    for (const luminaire of this.task.luminaires) {
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.clickLuminaire(luminaire)
      });
      google.maps.event.addListener(luminaire.marker, 'dragend', () => { this.updateLuminairePosition(luminaire) });
      this.markers.push(luminaire.marker);
    }
  }

  private addlampposts(lampposts: Lamppost[]): void {
    for (const lamppost of lampposts) {
      this.addLamppost(lamppost);
    }
  }

  private addLamppost(lamppost: Lamppost) {
    for (const luminaire of lamppost.luminaires) {
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.clickLamppost(lamppost, luminaire);
      });
      luminaire.marker.addListener('mouseover', () => {
        lamppost.upadeIcon('hover');
      });
      luminaire.marker.addListener('mouseout', () => {
        lamppost.upadeIcon('normal');
      });
      google.maps.event.addListener(luminaire.marker, 'dragend', () => { this.updateLuminairePosition(luminaire) });
      this.markers.push(luminaire.marker);
    }
  }

  private addCircuits(): void {

    for (const circuit of this.task.circuits) {
      this.addCircuit(circuit);
    }

  }

  private addCircuit(circuit: Circuit): void {
    circuit.paintMarker(this.map);
    this.markers.push(circuit.marker);
    circuit.marker.addListener('click', () => {
      this.showCircuit(circuit);
      this.clickCircuit(circuit);
    });
    google.maps.event.addListener(circuit.marker, 'dragend', () => { this.updateCircuitPosition(circuit) });

    for (const lamppost of circuit.lampposts) {
      for (const luminaire of lamppost.luminaires) {
        luminaire.paintMarker(this.map);
        luminaire.marker.addListener('click', () => {
          this.showCircuit(circuit);
          this.addLamppostAnimation(lamppost, null);
          this.clickLamppostOfCircuit(circuit, lamppost, luminaire);
        });
        luminaire.marker.addListener('mouseover', () => {
          lamppost.upadeIcon('hover');
        });
        luminaire.marker.addListener('mouseout', () => {
          lamppost.upadeIcon(circuit.event);
        });
        google.maps.event.addListener(luminaire.marker, 'dragend', () => { this.updateLuminairePosition(luminaire) });
        this.markers.push(luminaire.marker);
      }
    }
  }

  private getTroopSupervisors(): void {
    this.taskService
      .getTroopers()
      .subscribe(
        (getTroopSupervisorsResponse: Trooper[]) => {
          this.troopers = getTroopSupervisorsResponse.filter(x => x.is_active == true);
        },
        (error) => {
          this.toastr.error(error);
        }
      )
  }

  private clickLamppostOfCircuit(circuit: Circuit, lamppost: Lamppost, luminaire: Luminaire) {

    switch (this.editMode) {
      case '':
        this.showInfoMarker(luminaire, lamppost);
        break;
      case 'edit':
        this.showOptionEditLamppost(luminaire, lamppost);
        break;
      case 'join circuit':
        this.modalService.open(this.elementHasCircuit).result.then(result => { }, reason => { });
        break;
      default:
        break;
    }

  }

  private clickLamppost(lamppost: Lamppost, luminaire: Luminaire) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();

    switch (this.editMode) {
      case '':
        this.showInfoMarker(luminaire, lamppost);
        break;
      case 'edit':
        break;
      case 'join circuit':
        lamppost.upadeIcon('circuitShowing');
        for (const _luminaire of lamppost.luminaires) {
          _luminaire.linkedSituation = 'linked';
          google.maps.event.clearInstanceListeners(_luminaire.marker);
        }
        break;
      default:
        break;
    }

  }

  lamppostAddToCircuit(luminaireID) {
    const lamppost = this.mapFuncAux.searchLamppostByLumId(luminaireID, this.task);
    const luminaires = lamppost.luminaires;
    let markersToConvert = [];

    markersToConvert = this.markers.filter(marker => {
      return luminaires.find(luminaire => luminaire.id == marker.getTitle())
    })

    for (let i = 0; i < markersToConvert.length; i++) {
      const marker = markersToConvert[i];
      marker.setIcon('assets/img/svg/ic_link_circuit.svg');
      google.maps.event.clearListeners(marker, 'mouseover');
      google.maps.event.clearListeners(marker, 'mouseout');
    }

    this.lamppostsSelected = this.lamppostsSelected.concat(lamppost);

  }

  private clickLuminaire(luminaire: Luminaire) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();

    switch (this.editMode) {
      case '':
        this.showInfoMarker(luminaire);
        break;
      default:
        break;
    }

  }

  private clickCircuit(circuit: Circuit): void {

    switch (this.editMode) {
      case '':
        this.showInfoCircuit(circuit);
        break;
      case 'edit':
        this.showOptionEditCircuit(circuit.id);
        break;
      default:
        break;
    }

  }

  clickPositionLamppost(circuit: Circuit, isFirst: Boolean) {
    if (isFirst) {
      if (circuit.first_lamppost.id == null) {
        this.messageCircuitTxt = 'Primer Poste';
        return this.showModalOfCircuitVoid();
      }
      this.setLamppostAnimation(circuit.first_lamppost.id, circuit);
    } else {
      if (circuit.last_lamppost.id == null) {
        this.messageCircuitTxt = 'Último Poste';
        return this.showModalOfCircuitVoid();
      }
      this.setLamppostAnimation(circuit.last_lamppost.id, circuit);
    }
  }

  setLamppostAnimation(lamppostID: string, circuit: Circuit) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const lamppost = this.mapFuncAux.searchLamppostInCircuit(lamppostID, circuit);
    this.addLamppostAnimation(lamppost, google.maps.Animation.BOUNCE);
  }

  addLamppostAnimation(lamppost: Lamppost, animation: google.maps.Animation) {
    lamppost.luminaires.forEach((luminaire: Luminaire) => {
      const search_reference = luminaire.reference_id == null ? luminaire.id : luminaire.reference_id.toString();
      const marker: google.maps.Marker = this.markers.find(mark => mark.getTitle() === search_reference);
      marker.setAnimation(animation);
    })
  }

  private showModalOfCircuitVoid() {
    this.modalService.open(this.elementModal).result.then(result => {
    }, reason => { });
  }

  private showInfoCircuit(circuit: Circuit): void {
    this.generalViewService.getCircuit(circuit.id)
      .subscribe((_circuit: Circuit) => {
        circuit.identifier = _circuit.identifier;
        circuit.meter = _circuit.meter;
        circuit.last_lamppost = _circuit.last_lamppost;
        circuit.first_lamppost = _circuit.first_lamppost;
        circuit.cabinet = _circuit.cabinet;
        circuit.supply = _circuit.supply;
        circuit.supply_name = _circuit.supply_name;
        circuit.substation = _circuit.substation;
        const strInfo = this.mapFuncAux.getStringInfoCircuit(circuit, true);
        const infowindow = new google.maps.InfoWindow({ content: strInfo });
        infowindow.open(this.map, circuit.marker);
        window.setTimeout(() => {
          const btnEditCircuit = document.getElementById('btnEditCircuit');
          const seeMoreCircuit = document.getElementById('seeMoreCircuit');
          const btnFirstLamppostCircuit = document.getElementById('btnFirstLamppost');
          const btnLastLamppostCircuit = document.getElementById('btnLastLamppost');
          btnEditCircuit.addEventListener('click', () => this.saveEditCircuit(circuit.id));
          seeMoreCircuit.addEventListener('click', () => this.seeMoreCircuit(circuit.id));
          btnFirstLamppostCircuit.addEventListener('click', () => this.clickPositionLamppost(circuit, true));
          btnLastLamppostCircuit.addEventListener('click', () => this.clickPositionLamppost(circuit, false));
        }, 500);
        this.lstInfowindows.push(infowindow);
      }, error => {
        this.toastr.error(error);
      });
  }

  private showInfoMarker(luminaire: Luminaire, lamppost?: Lamppost) {
    this.generalViewService.getLuminaire(luminaire.id)
      .subscribe((_luminaire: Luminaire) => {
        luminaire.reference_id = _luminaire.reference_id;
        luminaire.technology = _luminaire.technology;
        luminaire.type = _luminaire.type;
        luminaire.has_photocell = _luminaire.has_photocell;
        luminaire.comment = _luminaire.comment;
        const strInfo = this.mapFuncAux.getStringInfo(luminaire, lamppost);
        const infowindow = new google.maps.InfoWindow({ content: strInfo });
        infowindow.open(this.map, luminaire.marker);
        window.setTimeout(() => {
          const btnEditLum = document.getElementById('luminaireEdit');
          const seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
          btnEditLum.addEventListener('click', () => this.saveEditLum(luminaire.id));
          seeMoreLuminaire.addEventListener('click', () => this.mapFuncAux.seeMoreLuminaire(luminaire.id));
        }, 500);
        this.lstInfowindows.push(infowindow);
      }, error => {
        this.toastr.error(error);
      });
  }

  private showOptionEditCircuit(circuitID: string) {
    const circuit = this.mapFuncAux.searchCircuit(circuitID, this.task);
    const marker = this.mapFuncAux.searchMarker(circuitID, this.markers);

    if (!circuit || !marker) { return }

    const strInfo = this.mapFuncAux.getStringCircuitOptions();
    const infowindow = new google.maps.InfoWindow({ content: strInfo });
    infowindow.open(this.map, marker);
    window.setTimeout(() => {
      const btnCircuittUnir = document.getElementById('circuittUnir');
      const btnCircuitDelete = document.getElementById('circuitDelete');
      btnCircuittUnir.addEventListener('click', () => {
        this.editMode = 'join circuit';
        this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
        this.circuitsLamppostsToHide(circuit);
      });
      btnCircuitDelete.addEventListener('click', () => this.showModaldeleteCircuit(circuit, marker));
    }, 500);
    this.lstInfowindows.push(infowindow);
  }

  private showOptionEditLamppost(luminaire: Luminaire, lamppost: Lamppost) {
    const strInfo = this.mapFuncAux.getStringLamppostTheCircuitOptions();
    const infowindow = new google.maps.InfoWindow({ content: strInfo });
    infowindow.open(this.map, luminaire.marker);
    window.setTimeout(() => {
      const btnLamppostSeparar = document.getElementById('lamppostSeparar');
      btnLamppostSeparar.addEventListener('click', () => this.showModaldeleteLamppost(luminaire, lamppost));
    }, 500);
    this.lstInfowindows.push(infowindow);
  }

  private showModaldeleteCircuit(circuit: Circuit, marker: google.maps.Marker) {
    this.txtModalAdv = '¿Desea eliminar el Circuito?';
    this.modalService.open(this.contentAdv).result.then(result => {
      this.taskService.deleteCircuit(this.task, circuit).subscribe(response => {
        this.task.circuits = this.task.circuits.filter(_circuit => _circuit.id != circuit.id);
        this.task.lampposts = this.task.lampposts.concat(circuit.lampposts);
        circuit.hideMarker();
        for (const lamppost of circuit.lampposts) {
          lamppost.upadeIcon('normal');
          for (const luminaire of lamppost.luminaires) {
            google.maps.event.clearListeners(luminaire.marker, 'click');
            google.maps.event.clearListeners(luminaire.marker, 'mouseout');
            luminaire.marker.addListener('click', () => {
              this.clickLamppost(lamppost, luminaire);
            });
            luminaire.marker.addListener('mouseout', () => {
              lamppost.upadeIcon('normal');
            });
          }
        }
        this.toastr.success('Circuito eliminada.');
      }, error => {
        this.toastr.error(error);
      });
    }, reason => { });
  }

  private showModaldeleteLamppost(luminaire: Luminaire, lamppost: Lamppost) {
    this.txtModalAdv = '¿Desea separar el Poste del Circuito?';
    this.modalService.open(this.contentAdv).result.then(result => {
      const circuit = this.task.circuits.find(_circuit => _circuit.event == 'circuitShowing')
      this.taskService.deleteLamppostInCircuit(circuit, lamppost)
        .subscribe(response => {
          this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
          circuit.lampposts = circuit.lampposts.filter(_lamppost => _lamppost.id != lamppost.id);
          lamppost.upadeIcon('normal');
          this.task.lampposts.push(lamppost);
          for (const luminaire of lamppost.luminaires) {
            google.maps.event.clearListeners(luminaire.marker, 'click');
            google.maps.event.clearListeners(luminaire.marker, 'mouseover');
            google.maps.event.clearListeners(luminaire.marker, 'mouseout');
            luminaire.marker.addListener('click', () => {
              this.clickLamppost(lamppost, luminaire);
            });
            luminaire.marker.addListener('mouseover', () => {
              lamppost.upadeIcon('hover');
            });
            luminaire.marker.addListener('mouseout', () => {
              lamppost.upadeIcon('normal');
            });
          }
          this.toastr.success('Poste separado del circuito.');
        }, error => {
          this.toastr.error(error);
        });
    }, reason => { });
  }

  private saveEditCircuit(circuitID: string) {
    const circuit = this.mapFuncAux.searchCircuit(circuitID, this.task);
    const txtComment = document.getElementById('textAreaComment');
    if (!circuit || !txtComment) { return }
    circuit.comment = txtComment['value'];
    this.taskService.editCircuit(circuit).subscribe(response => {
      this.toastr.success('Circuito actualizada.');
    },
      error => {
        this.toastr.error(error);
      });

  }

  private saveEditLum(idLum: string) {
    let luminaire = this.mapFuncAux.searchLuminaire(idLum, this.task);
    const requestPayload = new LuminairePayload();
    if (!luminaire) {
      luminaire = this.mapFuncAux.searchLuminaireInCircuit(idLum, this.task);
    }
    requestPayload.populateJavascriptForm(luminaire);
    this.taskService.editLuminaire(requestPayload).subscribe(response => {
      requestPayload.id = luminaire.id;
      const lumTem = new Luminaire(requestPayload);
      this.mapFuncAux.remplaceLum(lumTem, this.task);
      this.toastr.success('Luminaria actualizada.');
    },
      error => {
        this.toastr.error(error);
      });
  }

  private updateLuminairePosition(luminaire: Luminaire) {

    luminaire.location.latitude = luminaire.marker['position'].lat();
    luminaire.location.longitude = luminaire.marker['position'].lng();
    this.taskService
      .updatePositonLuminarie(luminaire)
      .subscribe(response => {
        this.toastr.success('Coordenadas actualizadas.');
      }, error => {
        this.toastr.error(error);
      });
  }

  private updateCircuitPosition(circuit: Circuit) {

    circuit.meter.location.latitude = circuit.marker['position'].lat();
    circuit.meter.location.longitude = circuit.marker['position'].lng();

    this.taskService
      .updatePositonCircuit(circuit)
      .subscribe(response => {
        this.toastr.success('Coordenadas actualizadas.');
      }, error => {
        this.toastr.error(error);
      });
  }

  public addLamppostToCircuit() {
    const circuit = this.task.circuits.find(_circuit => _circuit.event == 'circuitShowing');
    const lampposts = this.task.lampposts.filter(_lamppost => _lamppost.event == 'circuitShowing');
    this.taskService.addLamppostsToCircuit(this.task, circuit, lampposts)
      .subscribe(response => {
        for (const lamppost of lampposts) {
          for (const luminaire of lamppost.luminaires) {
            google.maps.event.clearListeners(luminaire.marker, 'click');
            google.maps.event.clearListeners(luminaire.marker, 'mouseover');
            google.maps.event.clearListeners(luminaire.marker, 'mouseout');
            luminaire.marker.addListener('click', () => {
              this.showCircuit(circuit);
              this.addLamppostAnimation(lamppost, null);
              this.clickLamppostOfCircuit(circuit, lamppost, luminaire);
            });
            luminaire.marker.addListener('mouseover', () => {
              lamppost.upadeIcon('hover');
            });
            luminaire.marker.addListener('mouseout', () => {
              lamppost.upadeIcon(circuit.event);
            });
          }
        }
        circuit.setMap(this.map);
        circuit.lampposts = circuit.lampposts.concat(lampposts);
        this.toastr.success('Postes agregados al circuito.');
        this.editMode = 'edit';
      }, error => {
        this.toastr.error(error);
      });
  }

  updateTask() {
    const comment = this.infrastructureTaskForm.get('comment').value;
    let user = this.infrastructureTaskForm.get('user').value;
    if (typeof (user) == 'object') {
      user = user.value;
    }

    if (this.infrastructureTaskForm.get('deadline').value) {
      const formDate = this.infrastructureTaskForm.get('deadline').value;
      const date = MomentTz(`${formDate.year}-${formDate.month}-${formDate.day}`).format();
      this.infrastructureTaskForm.patchValue({ formattedDeadline: date });
    }
    const deadline = this.infrastructureTaskForm.get('formattedDeadline').value;

    this.taskService
      .updateTask(this.task, comment, null, user, deadline)
      .subscribe(response => {
        this.toastr.success('Tarea actualizada.');
      },
        error => {
          this.toastr.error(error);
        });
  }

  changeStatusTask(value: boolean) {
    const comment = this.infrastructureTaskForm.get('comment').value;
    this.taskService
      .updateTask(this.task, comment, value)
      .subscribe(response => {
        this.toastr.success('Tarea actualizada.');
      },
        error => {
          this.toastr.error(error);
        });
  }

  public createCircuit(event: any) {
    if (this.editMode != 'edit') { return }

    const position = event.latLng;
    this.circuitForm = this.formBuilder.group(
      {
        latitude: [{ value: position.lat(), disabled: true }, Validators.required],
        longitude: [{ value: position.lng(), disabled: true }, Validators.required]
      }
    );
    this.modalService.open(this.createElement).result.then(result => { }, reason => {
      if (reason == 'Form valid') {
        const requestPayload = new CreateCircuitPayload(this.circuitForm);

        this.taskService
          .createCircuit(this.task, requestPayload)
          .subscribe(
            (response: any) => {
              this.toastr.success('Circuito creado.');
              const circuit = new Circuit(response.circuit);
              this.addCircuit(circuit);
              circuit.marker.setDraggable(true);
              this.task.circuits.push(circuit);
            },
            (error: any) => {
              this.toastr.error(error);
            }
          );
      }
    });
  }

  private circuitsLamppostsToHide(circuit: Circuit) {
    for (const lamppost of circuit.lampposts) {
      lamppost.hideLamppost();
    }
  }

  public clickEditMap(): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);

    if (this.editMode == '') {
      this.driver.highlight('#mapLuminaires');
      this.setCenterMap();
      this.mapFuncAux.addDragg(this.markers);
      this.editMode = 'edit';
      this.hideCircuit();
    } else if (this.editMode == 'join circuit') {
      this.modalService.open(this.elementAvisoExit).result.then(result => {
        this.cancelEditJoinCircuit();
        this.editMode = '';
        this.mapFuncAux.removeDragg(this.markers);
        this.driver.reset();
      }, reason => { });

    } else {
      this.mapFuncAux.removeDragg(this.markers);
      this.driver.reset();
      this.editMode = '';
      this.hideCircuit();
    }
  }

  private seeMoreCircuit(circuitID: string): void {
    this.router.navigate([`/u/circuits/${circuitID}`]);
  }

  private setCenterMap() {
    setTimeout(() => {
      const element = document.getElementById('driver-highlighted-element-stage');
      element.scrollIntoView(false);
      element.scrollIntoView({ block: 'center' });
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, 0);
  }

  public cancelEditJoinCircuit() {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.editMode = 'edit';
    const circuit = this.task.circuits.find(_circuit => _circuit.event == 'circuitShowing');
    circuit.setMap(this.map);
    this.hideCircuit();

    const lampposts = this.task.lampposts.filter(_lamppost => _lamppost.event == 'circuitShowing');
    for (const lamppost of lampposts) {
      lamppost.upadeIcon('normal');
      for (const luminaire of lamppost.luminaires) {
        luminaire.marker.addListener('click', () => {
          this.clickLamppost(lamppost, luminaire);
        });
        luminaire.marker.addListener('mouseover', () => {
          lamppost.upadeIcon('hover');
        });
        luminaire.marker.addListener('mouseout', () => {
          lamppost.upadeIcon('normal');
        });
        google.maps.event.addListener(luminaire.marker, 'dragend', () => { this.updateLuminairePosition(luminaire) });
      }
    }
  }

  public clickBaseTask() {
    this.router.navigate([`/u/tasks/${this.task.parent_task_id}`]);
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  private showCircuit(circuit: Circuit): void {
    if (this.editMode == 'join circuit') { return; }
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();
    circuit.upadeIcon('circuitShowing');
  }

  private hideCircuit(): void {
    if (this.editMode == 'join circuit') { return; }
    const _circuit = this.task.circuits.find(__circuit => __circuit.event == 'circuitShowing');
    if (_circuit) {
      const event = _circuit.isSelected ? 'selected' : 'normal';
      _circuit.upadeIcon(event);
    }
  }

  private userChanged(val: any) {
    const user = this.troopers.find(troop => troop.id == val);
    this.infrastructureTaskForm.patchValue({
      troop: user.troops.map(troop => troop.number).join(', ')
    });

  }

}
